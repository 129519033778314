const check = (props, pageName, authorization) => {
  return authorization.includes(pageName);
};

const Control = (props) =>
  check(props, props.pageName, props.authorization) ? props.yes() : props.no();

Control.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Control;
