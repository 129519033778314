import React, { useEffect, useState } from "react";
import { Box, Menu, Typography } from "@mui/material";
import TableComponent from "../BaseComponents/Table";
import SearchComponent from "../BaseComponents/Search";
import { lightGrey } from "../../Styles/theme/colors";
import DownloadIcon from "./DownloadIcon";
import { filterObjectByValue, getColorCodingStatus } from "../../Utils/Tools";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import { useFindEquipmentTypeQuery } from "../../Store/Slices/equipmentTypeSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import { useFindSensorsQuery } from "../../Store/Slices/sensorSlice";
import ToolTipComponent from "../BaseComponents/Tooltip";
import IconButtonComponent from "../BaseComponents/IconButton";
import DeviceFilter from "../BaseComponents/Filter/DeviceFilter";
import "./style.css";

const MapViewTable = (props) => {
  const {
    equipments,
    CustomerID,
    equipmentLive,
    allowedEdit,
    selectedLocationId,
    locationsHierarchy,
    setEquipment,
    equipment,
    edit,
    setEdit,
    devices,
    substations,
    toggleDrawer,
    updatedMappedEquipments,
    setUpdatedMappedEquipments,
    updatedMappedSubstations,
    setUpdatedMappedSubstations,
    currentBreakpoint,
    generateUniqueMapX,
  } = props;

  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [typeFilterCount, setTypeFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [tempEquipments, setTempEquipments] = useState(equipments);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [anchorSearchEl, setAnchorSearchEl] = useState(null);

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: locationsData,
    isFetching: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const { data: sensorsData, isSuccess: sensorsSuccess } = useFindSensorsQuery();

  useEffect(() => {
    if (
      selectedEquipmentTypes.length ||
      selectedLocations.length ||
      selectedAlarm.length ||
      selectedType.length ||
      toDate != null ||
      fromDate != null ||
      searchText.length
    ) {
      let tempFilteredEquipments = devices.filter((device) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = true,
          filter6 = true,
          filter7 = true;
        if (selectedEquipmentTypes.length) {
          filter1 = selectedEquipmentTypes.some((typeId) => {
            return typeId == device.EquipmentTypeID;
          });
        }
        if (selectedAlarm.length) {
          filter2 = selectedAlarm.some((item) => {
            const equipmentSensors = filterObjectByValue(
              equipmentLive,
              "EquipmentID",
              device.EquipmentID
            );

            return (
              (item == "Active" && equipmentSensors[0]?.IsActive) ||
              (item == "Inactive" && !equipmentSensors[0]?.IsActive)
            );
          });
        }

        if (selectedLocations.length) {
          filter3 = selectedLocations.some((locationId) => {
            return locationId == device.LocationID;
          });
        }

        if (fromDate != null) {
          let tempFrom = new Date(fromDate);
          let equipmentDate = new Date(device.updatedAt);
          filter4 = equipmentDate >= tempFrom;
        }

        if (toDate != null) {
          let tempTo = new Date(toDate);
          let equipmentDate = new Date(device.updatedAt);
          filter5 = equipmentDate <= tempTo;
        }
        if (selectedType.length) {
          filter6 = selectedType.some((item) => {
            return (
              (item == "Equipment" && device.EquipmentID) ||
              (item == "Substation" && !device.EquipmentID)
            );
          });
        }
        if (searchText.length) {
          if (device?.SubstationID?.length) {
            filter7 =
              device?.Label?.toLowerCase().includes(searchText.toLowerCase()) ||
              locationsHierarchy[device?.LocationID]
                .toLowerCase()
                .includes(searchText.toLowerCase());
          } else {
            filter7 =
              device?.EquipmentName?.toLowerCase().includes(searchText.toLowerCase()) ||
              device?.EquipmentTypeName.toLowerCase().includes(searchText.toLowerCase()) ||
              locationsHierarchy[device?.LocationID]
                .toLowerCase()
                .includes(searchText.toLowerCase());
          }
        }
        return filter1 && filter2 && filter3 && filter4 && filter5 && filter6 && filter7;
      });

      setTempEquipments(tempFilteredEquipments);
    } else {
      setTempEquipments(devices);
    }
  }, [
    selectedEquipmentTypes,
    selectedLocations,
    selectedAlarm,
    selectedType,
    toDate,
    fromDate,
    searchText,
    devices,
  ]);

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  const isSearchMenuOpen = Boolean(anchorSearchEl);

  const customComponents = {
    icons: {
      DownloadIcon: DownloadIcon,
    },
    TableHead: () => null,
  };

  const clearFilter = () => {
    setSelectedEquipmentTypes([]);
    setSelectedSensors([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedType([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
    setTypeFilterCount(0);
    setFromDate(null);
    setToDate(null);
  };

  const substationsSensors = substations.reduce((result, substation) => {
    const substationSerialNumber = substation.SerialNumber;
    const filteredSensors = sensorsData?.filter(
      (sensor) => sensor.PortSerial.split("-").slice(1).join("-") === substationSerialNumber
    );
    return { ...result, [substation.SubstationID]: filteredSensors };
  }, {});

  const columns = [
    {
      name: "EquipmentID",
      label: "EquipmentID",
      options: {
        display: false,
        searchable: false,
        customHeadRender: () => null,
      },
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
      options: {
        display: true,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "grid" }}>
              <Typography
                variant="caption"
                sx={{ color: "#686E77" }}
              >
                {value?.EquipmentTypeName}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  color: "#222934",
                }}
              >
                {value?.EquipmentName}
              </Typography>
            </div>
          );
        },
        customHeadRender: () => null,
      },
    },

    {
      name: "",
      label: "",
      options: {
        searchable: false,
        customHeadRender: () => null,
      },
    },

    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
      options: {
        display: false,
        searchable: false,
        customHeadRender: () => null,
        download: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        display: false,
        searchable: false,
        customHeadRender: () => null,
        download: false,
      },
    },
  ];

  const customOptions = {
    download: false,

    selectableRows: false,
    customSearch: (searchQuery, currentRow, columns) => {
      if (
        currentRow[2].EquipmentName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[2].EquipmentTypeName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[3].props.children[1]?.toLowerCase().includes(searchQuery.toLowerCase())
      )
        return true;
      return false;
    },
    setRowProps: (row) => {
      if (row[0] == equipment.EquipmentID || row[0] == equipment.SubstationID) {
        return {
          style: { background: "#E0F5FD 0% 0% no-repeat padding-box" },
        };
      }
    },
    onRowClick: (rowData) => {
      if (rowData[5] == "Equipment") {
        setEquipment({
          EquipmentID: rowData[0],
          EquipmentName: rowData[6],
        });
      } else {
        setEquipment({
          SubstationID: rowData[0],
          Label: rowData[6],
        });
      }
    },
  };

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const loadLocations = async () => {
    if (locationsSuccess)
      setAllLocations(
        locationsData.filter(val => val.CustomerID == CustomerID).map((e) => {
          return { value: e.LocationID, label: e.LocationName };
        })
      );
  };

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
  };
  
  const handleTypeSelect = (event) => {
    let updatedList = [...selectedType];
    if (event.target.checked) {
      updatedList = [...selectedType, event.target.value];
      setTypeFilterCount(typeFilterCount + 1);
    } else {
      updatedList.splice(selectedType.indexOf(event.target.value), 1);
      setTypeFilterCount(typeFilterCount - 1);
    }
    setSelectedType(updatedList);
  };
  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const handleFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const handleToDate = (newToDate) => {
    setToDate(newToDate);
  };

  const handleSearchClick = (event) => {
    setAnchorSearchEl(event.currentTarget);
  };

  const handleAddClick = async (item, type) => {
    setEdit(true);
  
    const { mapX, mapY } = generateUniqueMapX();
  
    if (type === "Equipment") {
      const isPlaced = updatedMappedEquipments.some((eq) => {
        return eq.EquipmentID === item.EquipmentID;
      });
  
      if (isPlaced) {
        // Remove the equipment if it's already placed
        setUpdatedMappedEquipments((prevState) =>
          prevState.filter((eq) => eq.EquipmentID !== item.EquipmentID)
        );
      } else {
        // Place the equipment
        setUpdatedMappedEquipments([
          ...updatedMappedEquipments,
          {
            EquipmentID: item.EquipmentID,
            LocationID: selectedLocationId,
            MapX: mapX,
            MapY: mapY,
            IsActive: item.IsActive,
          },
        ]);
      }
    } else if (type === "Substation") {
      const isPlaced = updatedMappedSubstations.some((sb) => {
        return sb.SubstationID === item.SubstationID;
      });
  
      if (isPlaced) {
        // Remove the substation if it's already placed
        setUpdatedMappedSubstations((prevState) =>
          prevState.filter((sb) => sb.SubstationID !== item.SubstationID)
        );
      } else {
        // Place the substation
        setUpdatedMappedSubstations([
          ...updatedMappedSubstations,
          {
            SubstationID: item.SubstationID,
            LocationID: selectedLocationId,
            MapX: mapX,
            MapY: mapY,
            IsActive: item.IsActive,
          },
        ]);
      }
    }
  };

  const handleClick = (equipment) => {
    handleAddClick(equipment, "Equipment");
  };

  const handleSubstationClick = (substation) => {
    handleAddClick(substation, "Substation");
  };

  const handleClose = () => {
    setAnchorSearchEl(null);
  };

  const renderSearchMenu = (
    <Menu
      anchorEl={anchorSearchEl}
      id="equipment-search"
      open={isSearchMenuOpen}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 12,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <div
        style={{
          maxHeight: "500px",
          width: "355px",
          overflow: "auto",
          padding: "16px",
        }}
      >
        <SearchComponent
          width="320px"
          placeholder="Search Devices...."
          searchText={searchText}
          setSearchText={setSearchText}
          currentBreakpoint={currentBreakpoint}
        />
      </div>
    </Menu>
  );

  return (
    <Box>
      {" "}
      <TableComponent
        zebraStyle={false}
        tableLook={"relax"}
        customOptions={customOptions}
        header={true}
        hideToolbar={false}
        mt="0"
        cellPaddingLeft="16px"
        marginBottom="0px"
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                paddingRight: "10px",
                width: "125px",
              }}
            >
              <b>
                {tempEquipments.length} {tempEquipments.length <= 1 ? " device" : " devices"}
              </b>
            </p>

            <DeviceFilter
              datefilter={false}
              locations={allLocations}
              equipments={equipmentTypes}
              handleSelectEquipmentType={handleSelectEquipmentType}
              handleSelectSensorType={handleSelectSensorType}
              handleSelectLocation={handleSelectLocation}
              handleAlarmSelect={handleAlarmSelect}
              handleTypeSelect={handleTypeSelect}
              selectedEquipmentTypes={selectedEquipmentTypes}
              selectedSensors={selectedSensors}
              selectedAlarm={selectedAlarm}
              selectedType={selectedType}
              selectedLocations={selectedLocations}
              equipmentFilterCount={equipmentFilterCount}
              sensorTypeFilterCount={sensorTypeFilterCount}
              locationFilterCount={locationFilterCount}
              alarmFilterCount={alarmFilterCount}
              typeFilterCount={typeFilterCount}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              fromDate={fromDate}
              toDate={toDate}
              clearFilter={clearFilter}
            />
            {currentBreakpoint === 2 || currentBreakpoint === 1 ? (
              <ToolTipComponent
                title="Search"
                placement="bottom-start"
                Component={
                  <Box sx={{ paddingRight: "16px" }}>
                    <IconButtonComponent
                      onClick={handleSearchClick}
                      size="small"
                      aria-controls={isSearchMenuOpen ? "equipment-search" : undefined}
                      aria-expanded={isSearchMenuOpen ? "true" : undefined}
                      Component={
                        <i
                          class="fa-light fa-magnifying-glass"
                          style={{
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#9CA1A6",
                          }}
                        />
                      }
                    />
                  </Box>
                }
              />
            ) : (
              <SearchComponent
                width="320px"
                placeholder="Search Devices...."
                searchText={searchText}
                setSearchText={setSearchText}
                currentBreakpoint={currentBreakpoint}
              />
            )}
          </Box>
        }
        columns={columns}
        components={customComponents}
        searchText={searchText}
        data={tempEquipments?.map((row) => {
          if (row.EquipmentID) {
            const equipmentSensors = filterObjectByValue(
              equipmentLive,
              "EquipmentID",
              row.EquipmentID
            );
            const mappedEquipment = updatedMappedEquipments.find((eq) => {
              return eq.EquipmentID == row.EquipmentID && eq.LocationID == selectedLocationId;
            });

            const status = getColorCodingStatus(equipmentSensors[0]?.Sensors);

            return [
              row.EquipmentID,
              <span style={{ position: "relative" }}>
                {status !== "none" && <StatusDot status={status} />}
                <i
                  style={{
                    fontSize: "30px",
                    background: "#ECEDEE",
                    color: "#9CA1A6",
                    padding: "10px",
                    borderRadius: "5px",
                    fontWeight: "300",
                  }}
                  className="fa-solid fa-flux-capacitor tableRow"
                ></i>
              </span>,
              {
                EquipmentName: row.EquipmentName,
                EquipmentTypeName: row.EquipmentTypeName,
              },
              <span style={{ display: "grid" }}>
                <Typography
                  variant="caption"
                  sx={{ color: lightGrey["900"] }}
                >
                  Location
                </Typography>
                {locationsHierarchy[row?.LocationID]}
              </span>,

              allowedEdit && (
                <button
                  className="mapViewTableButton"
                  style={{
                    width:
                      mappedEquipment && mappedEquipment?.LocationID == selectedLocationId
                        ? "103px"
                        : "88px",
                    float: "right",
                    marginRight: "8px",
                  }}
                 
                
                  onClick={() =>
                   [handleClick(row), setEquipment(row)]
               }
                >
                  {updatedMappedEquipments.find(v=> v.EquipmentID == row.EquipmentID) && mappedEquipment?.LocationID == selectedLocationId ? (
                    <>
                      <i class="fa-solid fa-location-minus"></i>
                      <span style={{ fontFamily: "Roboto", fontWeight: "500" }}>remove</span>
                    </>
                  ) : (
                    <>
                      <i class="fa-solid fa-location-plus"></i>
                      <span style={{ fontFamily: "Roboto", fontWeight: "500" }}>place</span>
                    </>
                  )}
                </button>
              ),
              "Equipment",
              row.EquipmentName,
            ];
          } else {
            const mappedSubstation = updatedMappedSubstations.find((sb) => {
              return sb.SubstationID == row.SubstationID && sb.LocationID == selectedLocationId;
            });

            const substationSensors = substationsSensors[row.SubstationID];
            const status = getColorCodingStatus(substationSensors);

            return [
              row.SubstationID,
              <span style={{ position: "relative" }}>
                {status !== "none" && <StatusDot status={status} />}
                <i
                  style={{
                    fontSize: "28px",
                    background: "#ECEDEE",
                    color: "#9CA1A6",
                    padding: "10px",
                    borderRadius: "5px",
                    fontWeight: "300",
                  }}
                  className="fa-solid fa-router tableRow"
                ></i>
              </span>,
              {
                EquipmentName: row.Label,
                EquipmentTypeName: "Substation",
              },
              <span style={{ display: "grid" }}>
                <Typography
                  variant="caption"
                  sx={{ color: lightGrey["900"] }}
                >
                  Location
                </Typography>
                {locationsHierarchy[row?.LocationID]}
              </span>,
              allowedEdit && (
                <button
                  className="mapViewTableButton"
                  style={{
                    width:
                      mappedSubstation && mappedSubstation.LocationID == selectedLocationId
                        ? "103px"
                        : "88px",
                    float: "right",
                    marginRight: "8px",
                  }}
                  onClick={() =>
                    [handleSubstationClick(row), setEquipment(row)]
                  }
                >
                  {updatedMappedSubstations.find(v=> v.SubstationID == row.SubstationID) && mappedSubstation?.LocationID == selectedLocationId ? (
                    <>
                      <i class="fa-solid fa-location-minus"></i>
                      <span style={{ fontFamily: "Roboto", fontWeight: "500" }}>remove</span>
                    </>
                  ) : (
                    <>
                      <i class="fa-solid fa-location-plus"></i>
                      <span style={{ fontFamily: "Roboto", fontWeight: "500" }}>place</span>
                    </>
                  )}
                </button>
              ),
              "Substation",
              row.Label,
            ];
          }
        })}
      />
      {renderSearchMenu}
    </Box>
  );
};

const StatusDot = ({ status }) => {
  return (
    <Box
      className="statusViewDot"
      sx={{
        background: STATUS_COLOR_CODE_MAP[status],
        borderRadius: "50%",
        position: "absolute",
        height: "13px",
        width: "13px",
      }}
    />
  );
};

export default MapViewTable;
