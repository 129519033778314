import React from "react";
import Test from "@Components/testingRoutes/test";

const index = () => {
  return (
    <div>
      <Test />
    </div>
  );
};

export default index;
