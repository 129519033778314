import React from "react";
import Label from "@Components/BaseComponents/Label";
import { Grid, Box, FormControl } from "@mui/material";
import { findObjectByValue, isNumeric, order } from "../../Utils/Tools";
import TextFieldComponent from "../BaseComponents/TextField";
import DropDownComponent from "../BaseComponents/DropDown";
import IconButtonComponent from "../BaseComponents/IconButton";
import ToolTipComponent from "../BaseComponents/Tooltip";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

import LoadingSpinner from "@Components/LoadingSpinner";

export function SubstationDrawer({
  drawer,
  toggleDrawer,
  substation,
  setSubstation,
  handleClick,
  buttonValidation,
  handleSensorEdit,
  substationConfirmationDrawer,
  sensorTypes,
  handleAddSensor,
  substationSensorsData,
  locations,
  setSubstationUpdated,
  edit,
  clearSubstation,
  customSubstationArchiveDrawer,
  archiveDrawer,
  discardSettingsDrawer,
  confirmationDrawer,
  tempSubstation,
  substationUpdated,
  handleDelete,
  allChangesLoading,
}) {
  const loadSubstation = () => {
    setSubstation(tempSubstation);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    setSubstationUpdated(false);
    loadSubstation();
    toggleDrawer(e);
  };

  return (
    <ResourceDrawerComponent
      drawer={drawer}
      toggleDrawer={substationConfirmationDrawer}
      title={edit ? "Edit substation" : "Add substation"}
      submitButtonTitle={edit ? "UPDATE SUBSTATION" : "ADD SUBSTATION"}
      handleSubmit={handleClick}
      displayFooter={true}
      edit={edit}
      archiveButton={{
        disabled: !!substationSensorsData[substation.SubstationID]?.length,
        tooltipCopy: "This Substation cannot be archived because it has associated sensors",
      }}
      closeToast={clearSubstation}
      deactivateToggleDrawer={customSubstationArchiveDrawer}
      buttonValidation={
        buttonValidation ||
        (edit && !substationUpdated) ||
        substation.MeasurementInterval > 3600 ||
        substation.MeasurementInterval < 2
      }
      deactivateButtonTitle="Archive"
      deleteDrawer={archiveDrawer}
      loadItem={loadSubstation}
      item={substation}
      setUpdated={setSubstationUpdated}
      confirmationDrawer={confirmationDrawer}
      handleDiscard={handleDiscard}
      discardSettingsDrawer={discardSettingsDrawer}
      handleDelete={handleDelete}
      deleteTitle="Archive substation"
      heading={`Are you sure you want to archive the ${substation.Label} substation?`}
    >
      {allChangesLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <LoadingSpinner />
          </Box>
        </>
      ) : (
        <Box sx={{ padding: "24px" }}>
          <FormControl
            margin="none"
            sx={{ width: "100%" }}
          >
            <Grid
              container
              spacing={1}
            >
              <TextFieldComponent
                fullWidth={true}
                padding={false}
                Label="Substation name"
                placeholder="Enter substation name"
                name="SubstationName"
                onChange={(e) => [
                  setSubstation({
                    ...substation,
                    Label: e.target.value,
                  }),
                  setSubstationUpdated(true),
                ]}
                value={substation.Label || ""}
              />
              <TextFieldComponent
                fullWidth={true}
                disabled={substation.Label == "" ? true : false || edit ? true : false}
                padding={true}
                Label="Serial number"
                placeholder="Enter serial number"
                name="SerialNumber"
                onChange={(e) => [
                  setSubstation({
                    ...substation,
                    SerialNumber: e.target.value,
                  }),
                  setSubstationUpdated(true),
                ]}
                value={substation.SerialNumber || ""}
              />
              <DropDownComponent
                padding={true}
                isDisabled={substation.SerialNumber == "" ? true : false}
                label="Location"
                options={order(
                  locations?.map((e) => {
                    return e;
                  })
                )}
                handleChange={(e) => [
                  setSubstation({
                    ...substation,
                    LocationID: e.value,
                  }),
                  setSubstationUpdated(true),
                ]}
                value={locations.find((el) => el.value === substation.LocationID) || ""}
                placeholder="Select a location"
              />
              <TextFieldComponent
                fullWidth={true}
                disabled={substation.LocationID == "" ? true : false}
                padding={true}
                Label="Measurement interval (between 2 to 3,600 seconds)"
                placeholder="Enter measurement interval"
                name="MeasurementInterval"
                type="number"
                customError={
                  substation.MeasurementInterval > 3600 || substation.MeasurementInterval < 2
                    ? "Must be between 2 to 3,600 seconds"
                    : undefined
                }
                onChange={(e) => [
                  isNumeric(e.target.value)
                    ? typeof e.target.value === "string" &&
                      (e.target.value.includes("-") || e.target.value.includes("."))
                      ? null
                      : setSubstation({
                          ...substation,
                          MeasurementInterval: e.target.value,
                        })
                    : null,
                  setSubstationUpdated(true),
                ]}
                value={substation.MeasurementInterval || ""}
              />
            </Grid>
          </FormControl>
          {substation.SerialNumber != "" && substation.SubstationID && (
            <Box
              sx={{
                paddingTop: "24px",
              }}
            >
              <Grid
                item
                xs={12}
              >
                {edit && (
                  <Grid
                    item
                    xs={12}
                  >
                    <Label
                      sx={{
                        color: "#9CA1A6",
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "left",
                        fontWeight: "400",
                      }}
                    >
                      Connected sensors
                    </Label>
                    {substationSensorsData[substation.SubstationID]?.length > 0 ? (
                      <Box sx={{ marginLeft: "8px" }}>
                        {substationSensorsData[substation.SubstationID].map((sensor) => {
                          const sensorType = findObjectByValue(
                            sensorTypes,
                            "value",
                            sensor.SensorTypeID
                          );
                          return (
                            <Box
                              key={sensor.SensorID}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "11px",
                              }}
                            >
                              <Box>
                                <p
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  {sensorType?.label}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    lineHeight: "16px",
                                    color: "#3F3F3F",
                                    textAlign: "left",
                                    marginTop: "3px",
                                  }}
                                >
                                  {sensor.SensorLabel}
                                </p>
                              </Box>
                              <ToolTipComponent
                                title="Edit sensor"
                                Component={
                                  <Box>
                                    <IconButtonComponent
                                      sx={{
                                        paddingRight: "24px",
                                      }}
                                      onClick={() => handleSensorEdit(sensor)}
                                      Component={<i class="fa-light fa-pen-to-square "></i>}
                                    />
                                  </Box>
                                }
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      ""
                    )}
                    <Grid
                      item
                      xs={12}
                      sx={{ marginLeft: "8px" }}
                    >
                      {" "}
                      <button
                        className="addSensorButton"
                        style={{
                          marginTop: edit ? "34px" : 0,
                        }}
                        onClick={() => handleAddSensor("", substation.SerialNumber, "", true)}
                      >
                        ADD ANOTHER SENSOR
                      </button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </ResourceDrawerComponent>
  );
}
