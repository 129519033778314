import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Grid, Button, MenuItem, Checkbox, ListItemText } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import TextFieldComponent from "../BaseComponents/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { primary, blueAction } from "../../Styles/theme/colors";
import { lightGrey } from "../../Styles/theme/colors";
import RadioGroupComponent from "../BaseComponents/RadioGroup";
import RadioComponent from "../BaseComponents/Radio";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import MultipleSelectCheckmarks from "../BaseComponents/MultiSelect";
import AlertProgramStep from "./programStep";
import InputAdornment from "@mui/material/InputAdornment";
import { isNumeric } from "../../Utils/Tools";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function AlertProgramDrawer({
  edit,
  sensorEdit,
  setSensorEdit,
  drawer,
  toggleDrawer,
  sensorToggleDrawer,
  sensorDeleteDrawer,
  customToggleDrawer,
  handleDelete,
  sensors,
  sensor,
  users,
  userGroups,
  handleLimitChange,
  setSensor,
  alertUpdated,
  setAlertUpdated,
  sensorTypes,

  selectedSensorLimitTemplate,
  setSelectedSensorLimitTemplate,
  handleClick,
  setSubstationData,
  programButtonValidation,
  programStepValidation,
  clearAlert,
  confirmationDrawer,
  discardSettingsDrawer,
  tempSensor,
  program,
  setProgram,
  selectedUsers,
  setSelectedUsers,
  selectedSensors,
  setSelectedSensors,
  selectedUserGroups,
  setSelectedUserGroups,
  steps,
  setSteps,
  equipmentDeleteDrawer,
}) {
  const theme = useTheme();

  const [selectedSensorType, setSelectedSensorType] = useState("");
  const [hide, setHide] = useState(false);

  function order(array) {
    if (Array.isArray(array)) {
      return array.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      console.log(array);
    }
  }

  const handleSelectedSensors = (event) => {
    const value = event.target.value;
    setAlertUpdated(true);
    setSelectedSensors(value);
  };

  const handleSelectedUserGroups = (event) => {
    setAlertUpdated(true);
    const value = event.target.value;
    setSelectedUserGroups(value);
  };

  const handleSelectedUsers = (event) => {
    setAlertUpdated(true);
    const value = event.target.value;
    setSelectedUsers(value);
  };

  const loadSensor = () => {
    setSensor(tempSensor);
  };

  const handleDiscard = (e) => {
    setAlertUpdated(false);
    confirmationDrawer(false);
    sensorToggleDrawer(false);
    setSelectedSensorType("");
  };

  const updateFieldChanged = (index, property, value) => {
    setAlertUpdated(true);
    let newArr = [...steps];
    newArr[index][property] = value;
    if (["UserGroups", "Users"].indexOf(property) > -1) {
      newArr[index][property] = value.map((val) => {
        if (property === "UserGroups") {
          return { UserGroupID: val };
        } else if (property === "Users") {
          return { UserID: val };
        }
      });
    }

    setSteps(newArr);
  };

  const handleDeleteStep = (index) => {
    setAlertUpdated(true);
    let newArr = [...steps];
    newArr.splice(index, 1);
    setSteps(newArr);
  };

  return (
    <>
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        disableDelete={false}
        archiveButton={{
          disabled: !!selectedSensors?.length,
          tooltipCopy: "This Alert Program cannot be deleted because it has associated sensors",
        }}
        deactivateToggleDrawer={customToggleDrawer}
        equipmentDeleteDrawer={equipmentDeleteDrawer}
        deactivateButtonTitle="Delete"
        submitButtonTitle={edit ? "UPDATE ALERT PROGRAM" : "ADD ALERT PROGRAM"}
        displayFooter={true}
        deactivateButtonColor="error"
        heading={`Are you sure you want to delete ${program.Name} program?`}
        headingNote="This will permanently delete the program from the system."
        handleDelete={handleDelete}
        edit={edit}
        deleteDrawer={equipmentDeleteDrawer}
        buttonValidation={programButtonValidation || programStepValidation || !alertUpdated}
        itemButtonValidation={programButtonValidation}
        closeToast={(open) => [clearAlert(open), setSelectedSensorType(""), toggleDrawer(open)]}
        handleSubmit={(reason) => [handleClick(reason), setSelectedSensorType("")]}
        setUpdated={setAlertUpdated}
        confirmationDrawer={confirmationDrawer}
        discardSettingsDrawer={discardSettingsDrawer}
        handleDiscard={handleDiscard}
        title={
          edit ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa-solid fa-chevron-left"
                style={{
                  marginRight: "15px",
                  width: "20px",
                  height: "32px",
                  color: "#9CA1A6",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => customToggleDrawer(false)}
              ></i>
              Edit alert program
            </div>
          ) : (
            "New alert program"
          )
        }
      >
        <Box sx={{ padding: "24px" }}>
          <Box>
            <TextFieldComponent
              fullWidth={true}
              padding={false}
              Label="Alert program name"
              placeholder="Enter alarm name"
              name="Alarmname"
              onChange={(e) => [
                setAlertUpdated(true),
                setProgram({
                  ...program,
                  Name: e.target.value.slice(0, 50),
                }),
              ]}
              value={program.Name || ""}
              inputProps={{ maxLength: 50 }}
            />
            {/* Commenting this Dropdown field as of now since its not required */}
            {/* <DropDownComponent
              padding={true}
              label="Sensor type"
              isDisabled={program.Name == "" ? true : false || sensorEdit}
              options={order(sensorTypes)}
              handleChange={(e) => [setSelectedSensorType(e)]}
              value={
                sensorTypes.find(
                  (el) => el.value === selectedSensorType.value
                ) || ""
              }
              placeholder="Select a sensor type"
            /> */}
            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Sensor(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectedSensors}
                placeholder="Select sensor(s)"
                personName={selectedSensors}
                disabled={program.Name == "" ? true : false}
                data={sensors}
              >
                {console.log("sensors, :", sensors)}
                {sensors.map((sensor) => (
                  <MenuItem
                    key={sensor.value}
                    value={sensor.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box !important",
                        color: "#17455E !important",
                      },
                      "&:Mui-selected": {
                        backgroundColor: "#3A6176 !important",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E !important",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedSensors.indexOf(sensor.value) > -1}
                    />
                    <ListItemText primary={sensor.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
            <Grid
              item
              xs={12}
              columns={2}
              spacing={10}
              sx={{
                paddingTop: "24px",
              }}
            >
              <Label
                sx={{
                  color: "#9CA1A6",
                }}
              >
                Alarm state
              </Label>

              <RadioGroupComponent
                row
                value={program.Type || ""}
                name="controlled-radio-buttons-group"
                callBack={(e) => [
                  setAlertUpdated(true),
                  setProgram({
                    ...program,
                    Type: e,
                  }),
                ]}
                FormControlLabels={
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      width: "484px",
                    }}
                  >
                    <FormControlLabel
                      value={"Warning"}
                      control={
                        <RadioComponent
                          disabled={!selectedSensors.length}
                          paddingLeft="0px"
                        />
                      }
                      label="Warning"
                    />
                    <FormControlLabel
                      value={"Alarm"}
                      control={<RadioComponent disabled={!selectedSensors.length} />}
                      label="Alarm"
                    />
                    <FormControlLabel
                      value={"Technical"}
                      control={<RadioComponent disabled={!selectedSensors.length} />}
                      label="Techincal"
                    />
                  </div>
                }
              />
            </Grid>
          </Box>

          <Box sx={{ paddingTop: "24px" }}>
            <p
              style={{
                color: "#222934",
                fontSize: "24px",
                lineHeight: "16px",
                fontWeight: 500,
              }}
            >
              Program steps
            </p>
            <></>
            {program.Type !== "" && (
              <>
                {steps.map((step, i) => {
                  return (
                    <AlertProgramStep
                      updateFieldChanged={updateFieldChanged}
                      step={step}
                      i={i}
                      hide={hide}
                      setHide={setHide}
                      selectedSensors={selectedSensors}
                      handleSelectedUserGroups={handleSelectedUserGroups}
                      userGroups={userGroups}
                      selectedUsers={step.Users.map((user) => user.UserID)}
                      handleSelectedUsers={handleSelectedUsers}
                      users={users}
                      selectedUserGroups={step.UserGroups.map((userGroup) => userGroup.UserGroupID)}
                      handleDeleteStep={handleDeleteStep}
                      steps={steps}
                    />
                  );
                })}
              </>
            )}
            {program.Type !== "" && (
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: "medium",
                  borderColor: primary["500"],
                  borderStyle: "solid",
                  borderWidth: "1px",
                  px: 1,
                  py: 0.3,
                  color: primary["500"],
                  height: "32px",
                  width: "200px",
                  marginTop: "20px",
                  "&:hover": {
                    backgroundColor: primary["700"],
                    color: lightGrey["50"],
                    borderColor: primary["500"],
                    borderStyle: "solid",
                    borderWidth: 1,
                  },
                  "&:active": {
                    color: lightGrey["50"],
                    backgroundColor: primary["700"],
                    borderStyle: "solid",
                    borderWidth: 1,
                  },
                  "&:focus": {
                    color: lightGrey["50"],
                    backgroundColor: primary["700"],
                    borderColor: blueAction["500"],
                    borderStyle: "solid",
                    borderWidth: "3px",
                  },
                  "&:disabled": {
                    color: primary["500"],
                    borderColor: primary["500"],
                    borderStyle: "solid",
                    borderWidth: "1px",
                    opacity: 0.65,
                  },
                }}
                variant="outlined"
                size="small"
                onClick={() => [
                  setSelectedUserGroups([]),
                  setSelectedUsers([]),
                  setSteps((steps) => [
                    ...steps,
                    {
                      ProgramID: "",
                      RecurrenceInterval: "",
                      ActionType: "Notification",
                      Recurrences: "",
                      Delay: "",
                      SendEmail: false,
                      SendSMS: false,
                      OverridePreference: false,
                      Users: [],
                      UserGroups: [],
                    },
                  ]),
                ]}
              >
                Add Another Step
              </Button>
            )}
            <TextFieldComponent
              fullWidth={true}
              padding={true}
              Label="Execute program (times)"
              placeholder="0 times"
              disabled={program.runIndefinitely}
              name="Alarmname"
              onChange={(e) =>
                isNumeric(e.target.value)
                  ? typeof e.target.value === "string" &&
                    (e.target.value.includes("-") || e.target.value.includes("."))
                    ? null
                    : [
                        setProgram({
                          ...program,
                          Recurrences: e.target.value,
                        }),
                        setAlertUpdated(true),
                      ]
                  : null
              }
              value={program.Recurrences || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ display: "grid" }}>
                      <i
                        onClick={() =>
                          setProgram({
                            ...program,
                            Recurrences: ++program.Recurrences,
                          })
                        }
                        class="fa-light fa-angle-up InputFieldOperator"
                      ></i>
                      <i
                        onClick={() => {
                          if (program.Recurrences - 1 < 0) {
                            return;
                          }
                          setProgram({
                            ...program,
                            Recurrences: --program.Recurrences,
                          });
                        }}
                        class="fa-light fa-angle-down InputFieldOperator"
                      ></i>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                marginTop: "10px",
              }}
            >
              {" "}
              <CheckBoxComponent
                // disabled={user.RoleID == "" ? true : false}
                checked={program.runIndefinitely}
                onChange={() =>
                  setProgram({
                    ...program,
                    runIndefinitely: !program.runIndefinitely,
                    Recurrences: program.runIndefinitely ? 0 : "Indefinitely",
                  })
                }
                Label="Execute program indefinitely"
              />{" "}
            </Box>
          </Box>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
}
