import React, { useState, useEffect } from "react";
import MenuItemComponent from "../MenuItem";
import CustomMenu from "@Components/BaseComponents/Menu";
import SearchComponent from "../Search";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import { Box, Divider } from "@mui/material";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import { useSelector } from "react-redux";
import { filterObjectByValue } from "../../../Utils/Tools";
import { useLiveEquipmentQuery } from "../../../Store/Slices/equipmentSlice";
import PillComponent from "../Pills";
import { useFindMappedEquipmentsQuery } from "../../../Store/Slices/mappedEquipmentSlice";
import { useFindMappedSubstationsQuery } from "../../../Store/Slices/mappedSubstationSlice";
import { useGetArchivedItemsQuery } from "../../../Store/Slices/archiveSlice";

import "./style.css";

const LocationsDropdown = (props) => {
  const { setSelectedLocationId, selectedLocationId, substations } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const [locations, setLocations] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [equipmentLive, setEquipmentLive] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [mappedSubstations, setMappedSubstations] = useState([]);
  const { data: archivedData } = useGetArchivedItemsQuery({
    entityTypes: '["Equipment", "Substation"]',
  });

  const isAdmin = auth.isAdmin;

  const pillStyles = (location) => {
    return {
      fontSize: "10px !important",
      padding: "9px !important",
      height: "10px !important",
      width: "10px !important",
      marginLeft: "10px",
      background: location.value == selectedLocationId && "white !important",
      color: location.value == selectedLocationId && "#014361 !important",
    };
  };
  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const loadmappedSubstations = () => {
    if (mappedSubstationLoading) setLoading(true);
    if (mappedSubstationSuccess) setMappedSubstations(mappedSubstationData.success.data);
    if (mappedSubstationIsError) console.log(mappedSubstationError);
    setLoading(false);
  };

  const loadMappedEquipments = () => {
    if (mappedEquipmentLoading) setLoading(true);
    if (mappedEquipmentSuccess) setMappedEquipments(mappedEquipmentData.success.data);
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData]);

  useEffect(() => {
    loadmappedSubstations();
  }, [mappedSubstationData]);

  const { data: equipmentLiveData, isSuccess: equipmentLiveDataSuccess } = useLiveEquipmentQuery();

  useEffect(() => {
    if (equipmentLiveDataSuccess) {
      setEquipmentLive(equipmentLiveData.success.data);
    }
  }, [equipmentLiveData]);

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      if (!isAdmin)
        setLocations(
          filterObjectByValue(locationsData, "CustomerID", auth.CustomerID).map((e) => {
            return {
              value: e.LocationID,
              label: e.LocationName,
              MapFile: e.MapFile,
            };
          })
        );
      else {
        if (auth.SelectedCustomer == "")
          setLocations(
            locationsData.map((e) => {
              return {
                value: e.LocationID,
                label: e.LocationName,
                MapFile: e.MapFile,
              };
            })
          );
        else
          setLocations(
            filterObjectByValue(locationsData, "CustomerID", auth.SelectedCustomer).map((e) => {
              return {
                value: e.LocationID,
                label: e.LocationName,
                MapFile: e.MapFile,
              };
            })
          );
      }
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const filterNonArchivedDevices = (devices, deviceType) => {
    return devices.filter((loc) => {
      return !archivedData?.success.data[deviceType]?.find(
        (data) => data.entityID === loc[`${deviceType}ID`]
      );
    });
  };

  const menuId = "equipment-context-menu";

  const handleMenuClose = (id) => {
    if (typeof id == "string") {
      setSelectedLocationId(id);
    }
    setAnchorEl(null);
    setSearchText("");
  };
  const hanldeEquipmentContextMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    loadLocations();
  }, [locationsData, auth]);

  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2))",
          mt: 0.1,
          width: "416px",
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disablePadding={true}
    >
      <Box>
        <SearchComponent
          width="100%"
          padding="16px"
          placeholder="Search available maps...."
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <Divider />
        <div
          style={{
            height: "fit-content",
            maxHeight: "30vh",
            overflow: "auto",
            borderRadius: "0px 0px 4px 4px",
          }}
        >
          {locations.map((location) => {
            return (
              location.label?.toLowerCase().includes(searchText?.toLowerCase()) &&
              location.MapFile && (
                <MenuItemComponent
                  selected={location.value == selectedLocationId ? true : false}
                  handleChange={() => handleMenuClose(location.value)}
                  Children={
                    <div
                      className="menuItem"
                      style={{
                        color: location.value == selectedLocationId ? "#F7F8F8" : "#17455E",
                      }}
                    >
                      <section
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <i
                          class="fa-regular fa-map"
                          style={{
                            width: "24px",
                          }}
                        ></i>{" "}
                        {location.label}
                        <PillComponent
                          style={pillStyles(location)}
                          label={
                            substations
                              ? filterNonArchivedDevices(
                                  filterObjectByValue(
                                    mappedEquipments,
                                    "LocationID",
                                    location.value
                                  ),
                                  "Equipment"
                                ).length +
                                filterNonArchivedDevices(
                                  filterObjectByValue(
                                    mappedSubstations,
                                    "LocationID",
                                    location.value
                                  ),
                                  "Substation"
                                ).length
                              : filterNonArchivedDevices(
                                  filterObjectByValue(
                                    mappedEquipments,
                                    "LocationID",
                                    location.value
                                  ),
                                  "Equipment"
                                ).length
                          }
                          size="small"
                        />
                      </section>

                      <i
                        class="fa-light fa-chevron-right"
                        style={{
                          width: "24px",
                        }}
                      ></i>
                    </div>
                  }
                />
              )
            );
          })}
        </div>
      </Box>
    </CustomMenu>
  );

  return (
    <>
      <OutlinedButton
        width="165px"
        onClick={hanldeEquipmentContextMenu}
        variant="outlined"
        color="primary"
        size="small"
        display="flex"
        justifyContent="space-between"
        height="32px"
      >
        <i class="fa-solid fa-map"></i>
        Select Map
        <i
          class="fa-light fa-chevron-down"
          style={{ fontSize: "16px" }}
        ></i>
      </OutlinedButton>
      {renderMenu}
    </>
  );
};
export default LocationsDropdown;
