import { useState } from "react";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import Toast from "@Components/BaseComponents/Toast";
import { Box, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import {
  findObjectByValue,
  getColorCodingStatus,
  getSensorLabel,
  convertMsToReadableDate,
  getTimezoneAbbreviation,
} from "../../Utils/Tools";
import { lightGrey } from "../../Styles/theme/colors";
import ToolTipComponent from "@Components/BaseComponents/Tooltip";
import SensorLabel from "@Components/BaseComponents/SensorLabel";
import LabelComponent from "../BaseComponents/LabelComponent";
import { Divider } from "@material-ui/core";
import DropDownComponent from "../BaseComponents/DropDown";
import Label from "../BaseComponents/Label";
import RadioComponent from "../BaseComponents/Radio";
import RadioGroupComponent from "../BaseComponents/RadioGroup";
import DateTimePickerComponent from "../BaseComponents/DateTimePicker";
import moment from "moment";
import { useSuppressSensorsMutation } from "../../Store/Slices/sensorSuppressSlice";
import "./style.css";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
];

const durationTypes = [
  { label: "Time duration", value: 0 },
  { label: "Future date", value: 1 },
  { label: "Indefinitely", value: 2 },
];

const EquipmentAlarmSupressDrawer = (props) => {
  const {
    toggleAlarmSuppressDrawer,
    equipmentAlarmSuppressDrawer,
    sensor,
    sensorTypes,
    sensorLimitTemplates,
    clearSensor,
    equipmentAlarmSuppress,
  } = props;
  const [suppressSensors, { isSuccess: suppressSensorsSuccess, isError: suppressSensorsError }] =
    useSuppressSensorsMutation();

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [durationType, setDurationType] = useState({ value: null, label: "" });
  const [dateDisplay, setDateDisplay] = useState(moment().utc().add(2, "hours").valueOf());
  const [selectedDurationTime, setSelectedDurationTime] = useState(null);

  const confirmationDrawer = (action) => {
    setDiscardSettingsDrawer(action);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    toggleAlarmSuppressDrawer(e);
  };

  const handleDurationType = (e) => {
    if (e.value === 0) setSelectedDurationTime(null);
    else if (e.value === 1) setSelectedDurationTime(1);
    else setSelectedDurationTime(2);

    setDurationType(e);
  };

  const handleDateChange = (newValue) => {
    setDateDisplay(moment(newValue).valueOf());
  };

  const endDateMap = {
    "Time duration": convertMsToReadableDate(
      moment().utc().add(selectedDurationTime, "minutes").valueOf(),
      true
    ),
    "Future date": convertMsToReadableDate(dateDisplay),
    Indefinitely: "Indefinitely",
  };

  const handleSuppress = async () => {
    let suppressDuration = null;
    if (selectedDurationTime === 1) {
      suppressDuration = dateDisplay;
    } else if (selectedDurationTime === 2) {
      suppressDuration = "indefinitely";
    } else {
      suppressDuration = moment().utc().add(selectedDurationTime, "minutes").valueOf();
    }
    
    const sensorIDs = equipmentAlarmSuppress.Sensors.map((item) => item.SensorID);
    let res = await suppressSensors({
      SensorIDs: sensorIDs,
      date: suppressDuration,
    });
    if (res?.data?.success) {
      toggleAlarmSuppressDrawer(false);
    }
  };

  const status = getColorCodingStatus(equipmentAlarmSuppress?.Sensors);
  const first =
    equipmentAlarmSuppress?.Sensors.length > 3 && equipmentAlarmSuppress?.Sensors.slice(0, 2);
  const second =
    equipmentAlarmSuppress?.Sensors.length > 3 && equipmentAlarmSuppress?.Sensors.slice(2);

  return (
    <>
      {suppressSensorsSuccess && (
        <Toast
          message={`${sensor?.SensorLabel} alarm successfully suppressed ${endDateMap[durationType.label] != "Indefinitely" ? "until" : ""} ${
            endDateMap[durationType.label]
          }`}
          severity="success"
          autoHideDuration={5000}
        />
      )}
      <ResourceDrawerComponent
        drawer={equipmentAlarmSuppressDrawer}
        toggleDrawer={toggleAlarmSuppressDrawer}
        title={"Suppress Equipment Alarms"}
        submitButtonTitle={"SUPPRESS"}
        handleSubmit={handleSuppress}
        displayFooter={true}
        closeToast={clearSensor}
        buttonValidation={!selectedDurationTime}
        item={sensor}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
      >
        <Box className="drawerPadding">
          <FormControl
            margin="none"
            className="formControl"
          >
            <Grid
              container
              spacing={1}
            >
              <Box className="suppressDrawerHeader">
                <span className="mainSpan">
                  <span style={{ position: "relative" }}>
                    {status !== "none" && (
                      <Box
                        className="equipmentDotRelaxed"
                        sx={{
                          background: STATUS_COLOR_CODE_MAP[status],
                          borderRadius: "50%",
                          position: "absolute",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                    )}
                    <i
                      className="fa-solid fa-flux-capacitor tableRow"
                      style={{
                        fontSize: "32px",
                        background: "#ECEDEE",
                        color: " #9CA1A6",
                        padding: "10px",
                        borderRadius: "5px",
                        fontWeight: "300",
                      }}
                    ></i>
                  </span>
                  <div className="typographyPadding">
                    <Typography
                      variant="caption"
                      className="typographyLabelType"
                    >
                      {equipmentAlarmSuppress?.EquipmentTypeName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="typographyLabel"
                    >
                      {equipmentAlarmSuppress?.EquipmentName}
                    </Typography>
                  </div>
                </span>

                <span style={{ display: "grid" }}>
                  <Typography
                    variant="caption"
                    sx={{ color: lightGrey["900"] }}
                  >
                    Monitoring
                  </Typography>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {equipmentAlarmSuppress?.Sensors.length > 0 ? (
                      equipmentAlarmSuppress?.Sensors.length > 3 ? (
                        <>
                          {first.map((sensor) => {
                            const sensorType = findObjectByValue(
                              sensorTypes,
                              "value",
                              sensor.SensorTypeID
                            );
                            const sensorLabel = getSensorLabel(
                              sensor,
                              sensorLimitTemplates,
                              sensorTypes
                            );
                            return (
                              <SensorLabel
                                LiveData={sensor.LiveData}
                                sensorType={sensorType}
                                title={sensorLabel}
                              />
                            );
                          })}
                          <ToolTipComponent
                            title={second.map((sensor) => {
                              if (sensorLimitTemplates) {
                                const sensorLabel = getSensorLabel(
                                  sensor,
                                  sensorLimitTemplates,
                                  sensorTypes
                                );
                                return sensorLabel;
                              }
                            })}
                            Component={
                              <Box
                                sx={{
                                  width: "fit-content",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LabelComponent
                                  label={`${second?.length} more`}
                                  state={"success"}
                                />
                              </Box>
                            }
                          />
                        </>
                      ) : (
                        equipmentAlarmSuppress?.Sensors.map((sensor) => {
                          const sensorType = findObjectByValue(
                            sensorTypes,
                            "value",
                            sensor.SensorTypeID
                          );
                          const sensorLabel = getSensorLabel(
                            sensor,
                            sensorLimitTemplates,
                            sensorTypes
                          );
                          return (
                            <SensorLabel
                              LiveData={sensor.LiveData}
                              sensorType={sensorType}
                              title={sensorLabel}
                            />
                          );
                        })
                      )
                    ) : (
                      <ToolTipComponent
                        title="There are no sensors attached to this equipment"
                        placement="bottom-start"
                        Component={
                          <Box
                            sx={{
                              width: "fit-content",
                              cursor: "pointer",
                            }}
                          >
                            <LabelComponent
                              label="No sensors"
                              state="none"
                            />
                          </Box>
                        }
                      />
                    )}
                  </span>
                </span>
              </Box>
            </Grid>
            <Box className="dividerPadding">
              <Divider />
            </Box>
            <Box className="drawerHeaderBox">
              <Grid
                container
                direction="column"
              >
                <Label
                  sx={{
                    color: "#9CA1A6",
                  }}
                >
                  Connected sensors ({equipmentAlarmSuppress?.Sensors.length})
                </Label>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "10px" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    {equipmentAlarmSuppress?.Sensors.length > 0 ? (
                      <Box>
                        {equipmentAlarmSuppress?.Sensors.map((sensor) => {
                          const sensorType = findObjectByValue(
                            sensorTypes,
                            "value",
                            sensor.SensorTypeID
                          );
                          const sensorLabel = getSensorLabel(
                            sensor,
                            sensorLimitTemplates,
                            sensorTypes
                          );
                          return (
                            <Box
                              key={sensor.SensorID}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: "80%",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: lightGrey["900"],
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                  }}
                                >
                                  {sensorType?.label.substr(0, sensorType?.label.indexOf(" "))}
                                </Typography>
                                <Box
                                  component="div"
                                  sx={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    noWrap
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      lineHeight: "16px",
                                    }}
                                  >
                                    {sensorLabel}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className="dividerPadding">
              <Divider />
            </Box>
            <Box className="drawerHeaderBox">
              <p className="drawerHeader">{"Suppression duration"}</p>
            </Box>
            <DropDownComponent
              padding={false}
              label="Duration type"
              options={durationTypes}
              handleChange={(e) => handleDurationType(e)}
              placeholder="Select a duration type"
              value={durationTypes.find((el) => el.value === durationType.value) || ""}
            />
            {durationType.value === 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  columns={2}
                  spacing={10}
                  className="labelPadding"
                >
                  <Label className="labelColor">Duration time</Label>
                </Grid>
                <RadioGroupComponent
                  value={selectedDurationTime || ""}
                  name="controlled-radio-buttons-group"
                  callBack={(e) => setSelectedDurationTime(parseInt(e))}
                  FormControlLabels={
                    <div
                      className="formControlLabelBox"
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        width: "348px",
                        flexDirection: "column",
                      }}
                    >
                      {timeDurations.map((timeDuration) => {
                        return (
                          <FormControlLabel
                            value={timeDuration.value}
                            control={<RadioComponent />}
                            label={timeDuration.label}
                          />
                        );
                      })}
                    </div>
                  }
                />
              </>
            )}
            {durationType.value === 1 && (
              <>
                <Grid
                  item
                  xs={12}
                  columns={2}
                  spacing={10}
                  className="labelPadding"
                >
                  <Label className="labelColor labelPaddingTop">Future date ({getTimezoneAbbreviation(dateDisplay)})</Label>
                </Grid>
                <DateTimePickerComponent
                  label=""
                  value={dateDisplay}
                  onChange={handleDateChange}
                />
              </>
            )}
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
};

export default EquipmentAlarmSupressDrawer;
