import React from "react";

//COMPONENTS IMPORT
import IconButtonComponent from "../../../Components/BaseComponents/IconButton";

const EditSensor = (props) => {
  let { sensor, handleEdit } = props;
  return (
    <>
      <IconButtonComponent
        onClick={() => handleEdit(sensor)}
        Component={<i class="fa-light fa-pen-to-square "></i>}
      />
    </>
  );
};

export default EditSensor;
