import React from "react";

//COMPONENTS IMPORT
import Box from "@mui/material/Box";
import { useEditUserMutation } from "../../../Store/Slices/usersSlice";
import Toast from "@Components/BaseComponents/Toast";
import IconButtonComponent from "../../../Components/BaseComponents/IconButton";
import ToolTipComponent from "../../../Components/BaseComponents/Tooltip";

const EditSubstations = (props) => {
  let { substation, handleEdit, paddingRight } = props;
  const [editUser, { error, isSuccess: editUserSuccess }] = useEditUserMutation();

  const handleStatus = async (id, active) => {
    let data = { IsActive: active };
    console.log("🚀 ~ file: EditUser.js ~ line 24 ~ handleStatus ~ data", data);
    try {
      let res = await editUser({ id, data });
      console.log("🚀 ~ file: UserSetup.js ~ line 76 ~ handleUpdate ~ res", res);
    } catch (err) {
      console.log("ERROR:", err);
      console.log("ERROR:", error);
    }
  };

  return (
    <>
      {editUserSuccess && (
        <Toast
          message="User Deleted successfully"
          severity="success"
        />
      )}
      <ToolTipComponent
        placement="bottom-end"
        title="Edit Substation"
        Component={
          <Box
            sx={{
              width: "fit-content",
              paddingRight: paddingRight ? paddingRight : "none",
              paddingLeft: !paddingRight ? "10px" : "none",
            }}
          >
            <IconButtonComponent
              onClick={() => handleEdit(substation)}
              Component={<i class="fa-light fa-pen-to-square "></i>}
            />
          </Box>
        }
      />
    </>
  );
};

export default EditSubstations;
