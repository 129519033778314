import React, { Fragment, useState, useEffect } from "react";
import AlertProgramTable from "../../../Components/Tables/AlertProgramTable";
import { Grid } from "@mui/material";

//COMPONENTS
import Toast from "@Components/BaseComponents/Toast";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import {
  useFindEquipmentSensorTypesQuery,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useDeleteEquipmentMutation,
} from "../../../Store/Slices/equipmentSlice";
import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";

import { filterObjectByValue, findObjectByValue, validateUIState } from "../../../Utils/Tools";
import { default as AlertProgramDrawer } from "@Components/RightDrawer/AlertProgramDrawer";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { useSelector } from "react-redux";
import {
  useAddSensorConfigMutation,
  useAddSensorMutation,
  useDeleteSensorMutation,
  useEditSensorConfigMutation,
  useEditSensorMutation,
  useFindSensorsWithOutStatusQuery,
} from "../../../Store/Slices/sensorSlice";
import { useFindMappedEquipmentsQuery } from "../../../Store/Slices/mappedEquipmentSlice";
import { useFindMappedSubstationsQuery } from "../../../Store/Slices/mappedSubstationSlice";
import LimitTemplatesDrawer from "../../../Components/RightDrawer/LimitTemplatesDrawer";
import { useFindSensorLimitTemplateQuery } from "../../../Store/Slices/sensorLimitTemplateSlice";
import { useFindSubstationsQuery } from "../../../Store/Slices/substationSlice";
import {
  useFindProgramsQuery,
  useAddProgramMutation,
  useEditProgramMutation,
  useDeleteProgramMutation,
} from "../../../Store/Slices/programSlice";
import { useFindProgramStepsQuery } from "../../../Store/Slices/programStepSlice";
import { useFindUserActionsQuery } from "../../../Store/Slices/userActionSlice";
import { useFindUsersQuery } from "../../../Store/Slices/usersSlice";
import { useFindUserGroupsQuery } from "../../../Store/Slices/userGroupsSlice";
import { useFindUserGroupActionsQuery } from "../../../Store/Slices/userGroupActionSlice";

const AlertProgramSetup = () => {
  const [drawer, setDrawer] = useState(false);
  const [limitsTemplateDrawer, setLimitsTemplateDrawer] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    data: programData,
    isFetching: programLoading,
    isSuccess: programSuccess,
    isError: programIsError,
    error: programError,
  } = useFindProgramsQuery();

  const {
    data: programStepData,
    isFetching: programStepLoading,
    isSuccess: programStepSuccess,
    isError: programStepIsError,
    error: programStepError,
  } = useFindProgramStepsQuery();

  const {
    data: userActionData,
    isFetching: userActionLoading,
    isSuccess: userActionSuccess,
    isError: userActionIsError,
    error: userActionError,
  } = useFindUserActionsQuery();

  const {
    data: userGroupActionData,
    isFetching: userGroupActionLoading,
    isSuccess: userGroupActionSuccess,
    isError: userGroupActionIsError,
    error: userGroupActionError,
  } = useFindUserGroupActionsQuery();

  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: userSuccess,
    isError: usersIsError,
    error: usersError,
  } = useFindUsersQuery();

  const {
    data: userGroupsData,
    isSuccess: userGroupSuccess,
    isLoading: userGroupsLoading,
    isError: userGroupsIsError,
    error: userGroupsError,
  } = useFindUserGroupsQuery();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();
  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();
  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
  } = useFindSensorsWithOutStatusQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const [
    addEquipment,
    { isSuccess: addEquipmentSuccess, isError: addEquipmentIsError, error: addEquipmentError },
  ] = useAddEquipmentMutation();

  const [
    addProgram,
    { isSuccess: addProgramSuccess, isError: addProgramIsError, error: addProgramError },
  ] = useAddProgramMutation();

  const [
    updateAlertProgram,
    {
      isSuccess: editAlertProgramSuccess,
      isError: editAlertProgramIsError,
      error: editAlertProgramError,
    },
  ] = useEditProgramMutation();

  const [
    deleteAlertProgram,
    {
      isSuccess: deleteAlertProgramSuccess,
      isError: deleteAlertProgramIsError,
      error: deleteAlertProgramError,
    },
  ] = useDeleteProgramMutation();

  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [deleteEquipment, { isSuccess: deleteEquipmentSucces }] = useDeleteEquipmentMutation();

  const [
    addSensor,
    { isSuccess: isSensorSuccess, isError: AddSensorIsError, error: AddSensorError },
  ] = useAddSensorMutation();

  const [addSensorConfig] = useAddSensorConfigMutation();

  const [editSensor, { isSuccess: sensorEditSuccess, isError: editSensorError }] =
    useEditSensorMutation();

  const [editSensorConfig, { isSuccess: sensorConfigEditSuccess }] = useEditSensorConfigMutation();

  const [deleteSensor, { isSuccess: deleteSensorSuccess }] = useDeleteSensorMutation();

  const [equipments, setEquipments] = useState([]);
  const [equipment, setEquipment] = useState({
    EquipmentName: "",
    EquipmentTypeID: "",
    LocationID: "",
  });
  const [tempSensor, setTempSensor] = useState({});
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [substationData, setSubstationData] = useState({});
  const [mappedSubstations, setMappedSubstations] = useState([]);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programSteps, setProgramSteps] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [userGroupActions, setUserGroupActions] = useState([]);
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [alertEdit, setAlertEdit] = useState(false);
  const [alertUpdated, setAlertUpdated] = useState(false);
  const [delEquipment, setDelEquipment] = useState(false);
  const [msg, setmsg] = useState("");
  const [delSensor, setDelSensor] = useState("");
  const auth = useSelector((state) => state.auth);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);

  const [selectedSensorLimitTemplate, setSelectedSensorLimitTemplate] = useState({});

  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "setup", "AlertPrograms");

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const [program, setProgram] = useState({
    CustomerID: defaultCustomerID,
    Name: "",
    Type: "",
    Recurrences: "",
    State: "ON",
    runIndefinitely: false,
  });

  const [programStep, setProgramStep] = useState({
    ProgramID: "",
    RecurrenceInterval: "",
    ActionType: "Notifcation",
    Recurrences: "",
    Delay: "",
    SendEmail: false,
    SendSMS: false,
    OverridePreference: false,
  });

  const [programStepSensor, setProgramStepSensor] = useState({
    ProgramStepID: "",
    SensorID: [],
  });

  const [userAction, setUserAction] = useState({
    ProgramStepID: "",
    UserID: [],
  });

  const [userGroupAction, setUserGroupAction] = useState({
    ProgramStepID: "",
    UserGroupID: [],
  });

  const [steps, setSteps] = useState([
    {
      ProgramID: "",
      RecurrenceInterval: "",
      ActionType: "Notification",
      Recurrences: "",
      Delay: "",
      SendEmail: false,
      SendSMS: false,
      OverridePreference: false,
      Users: [],
      UserGroups: [],
    },
  ]);

  const [sensor, setSensor] = useState({
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{Schema}",
  });
  const programButtonValidation = !(
    program.CustomerID !== "" &&
    program.Name !== "" &&
    program.Type !== "" &&
    (program.Recurrences !== "" || program.runIndefinitely == true) &&
    program.State !== ""
  );

  const [programStepValidation, setProgramStepValidation] = useState("");

  useEffect(() => {
    setProgramStepValidation(
      steps.some((step) => {
        return !(
          step.RecurrenceInterval !== "" &&
          step.Recurrences !== "" &&
          step.Delay !== "" &&
          (step.Users?.length != 0 || step.UserGroups?.length != 0)
        );
      })
    );
  }, [steps]);

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess)
      setSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };

  const loadSensors = async () => {
    if (sensorsSuccess) {
      const tempSensors = [];
      const length = sensorsData.length;
      const data = sensorsData;
      for (let i = 0; i != length; i++) {
        tempSensors.push({
          ...data[i],
        });
      }
      const FiltredTempSenors = tempSensors.filter(item => !item.IsArchive)
      if (auth.SelectedCustomer == "") {
        setSensors(FiltredTempSenors);
      } else {
        setSensors(filterObjectByValue(FiltredTempSenors, "CustomerID", auth.SelectedCustomer));
      }
      setLoading(false);
    }

    if (sensorIsError) console.log(sensorError);
  };

  const equipmentButtonValidation = !(
    equipment.EquipmentTypeID !== "" &&
    equipment.EquipmentName !== "" &&
    equipment.LocationID !== ""
  );

  const handleClick = (reason) => {
    alertEdit ? handleUpdateAlertProgram(reason) : handleSaveAll(reason);
  };

  //DRAWER HANDLERS

  const customToggleDrawer = (open) => {
    setDrawer(open);
  };
  const [equipmentDeleteDrawer, setEquipmentDeleteDrawer] = useState(false);

  const customToggleEquipmentDeleteDrawer = (open) => {
    setEquipmentDeleteDrawer(open);
  };

  const [sensorDeleteDrawer, setSensorDeleteDrawer] = useState(false);

  const customToggleSensorDeleteDrawer = (open) => {
    setSensorDeleteDrawer(open);
  };

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  const clearAlert = () => {
    setProgram({
      Name: "",
      Type: "",
      Recurrences: "",
      State: "ON",
    });
    setSelectedUserGroups([]);
    setSelectedUsers([]);
    setSelectedSensors([]);
    setSteps([
      {
        ProgramID: "",
        RecurrenceInterval: "",
        ActionType: "Notification",
        Recurrences: "",
        Delay: "",
        SendEmail: false,
        SendSMS: false,
        OverridePreference: false,
        Users: [],
        UserGroups: [],
      },
    ]);
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (open === false) {
      clearAlert();
      setAlertEdit(false);
    }
  };
  const toggleLimitsTemplateDrawer = (open) => {
    setLimitsTemplateDrawer(open);
  };

  const sensorConfirmationDrawer = (open) => {
    if (alertUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };

  const handleLimitChange = (e, Sensor, edit) => {
    if (e != "111a11b1-a111-4111-aa11-1eb1dbd1a11a") {
      const temp = findObjectByValue(sensorLimitTemplates, "value", e);
      setSelectedSensorLimitTemplate({
        ...temp,
        LimitsJSON: JSON.parse(temp.LimitsJSON),
      });
    } else {
      if (!edit)
        if (sensor.SensorTypeLabel == "Analog")
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              LowLimitWarning: 0.0,
              HighLimitWarning: 0.0,
              LowDelayWarning: 0.0,
              HighDelayWarning: 0.0,
              LowLimitAlarm: 0.0,
              HighLimitAlarm: 0.0,
              LowDelayAlarm: 0.0,
              HighDelayAlarm: 0.0,
            },
          });
        else {
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              InputState: "",
              Delay: 0.0,
            },
          });
        }
      else {
        const tempLimits = JSON.parse(Sensor.SchemaJSON);
        if (Sensor.SensorTypeLabel == "Analog")
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              LowLimitWarning: tempLimits.llw,
              HighLimitWarning: tempLimits.ulw,
              LowDelayWarning: 0.0,
              HighDelayWarning: tempLimits.dw,
              LowLimitAlarm: tempLimits.lln,
              HighLimitAlarm: tempLimits.uln,
              LowDelayAlarm: 0.0,
              HighDelayAlarm: tempLimits.da,
            },
          });
        else {
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              InputState: tempLimits.s,
              Delay: tempLimits.d,
            },
          });
        }
      }
    }
  };

  const handleAlertEdit = (program) => {
    setProgram(program);
    loadSensors();
    setSelectedSensors(program.ProgramSensors.map((s) => s.SensorID));
    setSteps(program.ProgramSteps);
    toggleDrawer(true);
    setAlertEdit(true);
    setEdit(true);

    // const EquipmentID = Sensor.equipmentSensors[0]?.EquipmentID || "";
    // const [port, ...extractedSerial] = Sensor.PortSerial.split("-");
    // const SubstationSerial = extractedSerial.join("-");
    // handleLimitChange(Sensor.SensorLimitTemplateID, Sensor, true);
    // setTempSensor({
    //   ...Sensor,
    //   EquipmentID: EquipmentID || "",
    //   Port: port,
    //   SubstationSerial: SubstationSerial || "",
    // });
    // setSensor({
    //   ...Sensor,
    //   EquipmentID: EquipmentID || "",
    //   Port: port,
    //   SubstationSerial: SubstationSerial || "",
    // });
  };

  const handleDelete = async (item, reason) => {
    setLoading(true);
    const data = {
      reason: reason,
    };

    const res = await deleteAlertProgram({ id: program.ProgramID, data });
    if (res.data?.success) {
      customToggleEquipmentDeleteDrawer(false);
      toggleDrawer(false);
    }

    setLoading(false);
  };

  let date = Date.now();

  const handleSaveAll = async (reason) => {
    let data = {
      program: program,
      programSteps: steps,
      sensors: selectedSensors,
    };

    const res = await addProgram({ ...data, reason: reason });

    if (res.error) return;

    toggleDrawer(false);
    customToggleDrawer(false);
    confirmationDrawer(false);
  };

  const handleUpdateAlertProgram = async (reason) => {
    const data = {
      program: program,
      programSteps: steps,
      sensors: selectedSensors,
      reason: reason,
    };

    const res = await updateAlertProgram({
      id: program.ProgramID,
      program: data,
    });
    toggleDrawer(false);
    customToggleDrawer(false);
    confirmationDrawer(false);
  };
  const handleEditSave = async (reason) => {
    let temp_config = {};
    let sensorConfig = {};
    if (sensor.SensorTypeLabel === "Analog") {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        dw: selectedSensorLimitTemplate.LimitsJSON.HighDelayWarning,
        da: selectedSensorLimitTemplate.LimitsJSON.HighDelayAlarm,
        uln: selectedSensorLimitTemplate.LimitsJSON.HighLimitAlarm,
        lln: selectedSensorLimitTemplate.LimitsJSON.LowLimitAlarm,
        ulw: selectedSensorLimitTemplate.LimitsJSON.HighLimitWarning,
        llw: selectedSensorLimitTemplate.LimitsJSON.LowLimitWarning,
        scs: [],
        sca: [],
        pcs: [],
        pca: [],
      };

      temp_config[`a${sensor.Port}`] = {
        ...sensorConfig,
        t: sensor.SensorTypeID,
      };
    } else {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        d: selectedSensorLimitTemplate.LimitsJSON.Delay,
        s: selectedSensorLimitTemplate.LimitsJSON.InputState,
      };
      temp_config[`d${sensor.Port}`] = {
        ...sensorConfig,
      };
    }

    let sensorConfigs = {
      SerialNumber: sensor.SubstationSerial,
      data: {
        ...substationData?.data,
        ...temp_config,
        t: date,
        i: 0,
      },
    };

    sensor.SchemaJSON = JSON.stringify(sensorConfig);
    let id = sensor.SubstationSerial;
    let SensorID = sensor.SensorID;
    let new_sensor = { ...sensor };
    delete new_sensor["SensorID"];
    delete new_sensor["createdAt"];
    delete new_sensor["updatedAt"];

    let data = {
      sensorData: new_sensor,
      configs: sensorConfigs,
    };

    const res = await editSensor({ id: SensorID, data, reason: reason });
    if (!res.error) await editSensorConfig({ id, sensorConfig: sensorConfigs });
    setAlertEdit(false);
    toggleDrawer(false);
  };

  useEffect(() => {
    if (!delSensor) return;
    const timeout = setTimeout(() => {
      setDelSensor(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [delSensor]);

  const loadPrograms = () => {
    if (programLoading) setLoading(true);
    if (programSuccess) {
      if (!isAdmin) {
        setPrograms(programData);
      } else {
        if (auth.SelectedCustomer == "") setPrograms(programData);
        else {
          setPrograms(programData);
        }
      }
    }
    if (programIsError) console.log(programError);
    setLoading(false);
  };
  const loadProgramSteps = () => {
    if (programStepLoading) setLoading(true);
    if (programStepSuccess) setProgramSteps(programStepData);
    if (programStepIsError) console.log(programError);
    setLoading(false);
  };

  const loadUserActions = () => {
    if (userActionLoading) setLoading(true);
    if (userActionSuccess) setUserActions(userActionData);
    if (userActionIsError) console.log(userActionError);
    setLoading(false);
  };

  const loadUsers = () => {
    if (userSuccess) {
      if (!isAdmin) setUsers(filterObjectByValue(usersData, "CustomerID", auth.CustomerID));
      else {
        if (auth.SelectedCustomer == "") setUsers(usersData);
        else setUsers(filterObjectByValue(usersData, "CustomerID", auth.SelectedCustomer));
      }
    }
    if (usersIsError) console.log(usersError);
  };
  const loadUserGroups = () => {
    if (userGroupSuccess)
      if (!isAdmin) {
        const UG = filterObjectByValue(userGroupsData.success.data, "CustomerID", auth.CustomerID);
        setUserGroups(
          UG.map((ug) => {
            return {
              value: ug.UserGroupID,
              label: ug.GroupName,
            };
          })
        );
      } else {
        if (auth.SelectedCustomer == "")
          setUserGroups(
            userGroupsData.success.data.map((ug) => {
              return {
                value: ug.UserGroupID,
                label: ug.GroupName,
              };
            })
          );
        else {
          const UG = filterObjectByValue(
            userGroupsData.success.data,
            "CustomerID",
            auth.SelectedCustomer
          );
          setUserGroups(
            UG.map((ug) => {
              return {
                value: ug.UserGroupID,
                label: ug.GroupName,
              };
            })
          );
        }
      }
  };

  const loadUserGroupActions = () => {
    if (userGroupActionSuccess) {
      setUserGroupActions(userGroupActionData);
    }
    if (userGroupActionIsError) console.log(userGroupActionError);
  };

  useEffect(() => {
    loadProgramSteps();
  }, [programStepData]);

  useEffect(() => {
    loadPrograms();
  }, [programData, auth]);

  useEffect(() => {
    loadSensors();
  }, [sensorsData]);

  useEffect(() => {
    loadUserActions();
  }, [userActionData]);

  useEffect(() => {
    loadUserGroupActions();
  }, [userGroupActionData]);

  useEffect(() => {
    loadUsers();
  }, [usersData]);

  useEffect(() => {
    loadUserGroups();
  }, [userGroupsData]);
  return (
    <Fragment>
      {addProgramSuccess && (
        <Toast
          message="Program successfully added"
          severity="success"
        />
      )}
      {editAlertProgramSuccess && (
        <Toast
          message="Program successfully updated"
          severity="success"
        />
      )}
      {deleteAlertProgramSuccess && (
        <Toast
          message="Program successfully deleted"
          severity="success"
        />
      )}
      {/* {sensorEditSuccess && delSensor && (
        <Toast message="Sensor successfully deactivated" severity="success" />
      )} */}
      {addProgramError && (
        <Toast
          message="Alert program name is not unique"
          severity="error"
        />
      )}
      {editSensorError && (
        <Toast
          message="Sensor name is not unique"
          severity="error"
        />
      )}

      {programStepLoading || programLoading || userActionLoading || usersLoading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <AlertProgramTable
            drawer={drawer}
            toggleDrawer={toggleDrawer}
            customToggleDrawer={customToggleDrawer}
            setLoading={setLoading}
            loading={loading}
            equipments={equipments}
            locations={locations}
            handleEdit={handleAlertEdit}
            locationsHierarchy={locationsHierarchy}
            sensorTypes={sensorTypes}
            sensors={sensors}
            mappedEquipments={mappedEquipments}
            mappedSubstations={mappedSubstations}
            limitsTemplateDrawer={limitsTemplateDrawer}
            toggleLimitsTemplateDrawer={toggleLimitsTemplateDrawer}
            sensorLimitTemplates={sensorLimitTemplates}
            programs={programs}
            programSteps={programSteps}
            userActions={userActions}
            users={users}
            userGroupActions={userGroupActions}
            userGroups={userGroups}
            allowedEdit={allowedEdit}
          />
          <Grid
            item
            md={12}
          >
            <AlertProgramDrawer
              edit={edit}
              alertEdit={alertEdit}
              clearAlert={clearAlert}
              equipment={equipment}
              tempSensor={tempSensor}
              setTempSensor={setTempSensor}
              setEquipment={setEquipment}
              equipmentTypes={equipmentTypes}
              handleClick={handleClick}
              drawer={drawer}
              sensorToggleDrawer={toggleDrawer}
              toggleDrawer={sensorConfirmationDrawer}
              equipmentDeleteDrawer={equipmentDeleteDrawer}
              customToggleDrawer={customToggleEquipmentDeleteDrawer}
              sensorDeleteDrawer={sensorDeleteDrawer}
              customToggleSensorDeleteDrawer={customToggleSensorDeleteDrawer}
              handleDelete={handleDelete}
              programStepValidation={programStepValidation}
              alertUpdated={alertUpdated}
              setAlertUpdated={setAlertUpdated}
              confirmationDrawer={confirmationDrawer}
              discardSettingsDrawer={discardSettingsDrawer}
              setAlertEdit={setAlertEdit}
              sensor={sensor}
              sensors={sensors.map((sensor) => {
                return {
                  value: sensor.SensorID,
                  label: sensor.SensorLabel,
                };
              })}
              users={users?.filter(val => !val?.IsArchive)?.map((user) => {
                return {
                  value: user.UserID,
                  label: user.UserName,
                };
              })}
              userGroups={userGroups}
              setSensor={setSensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              substations={substations}
              equipments={equipments}
              selectedSensorLimitTemplate={selectedSensorLimitTemplate}
              setSelectedSensorLimitTemplate={setSelectedSensorLimitTemplate}
              setSubstationData={setSubstationData}
              handleLimitChange={handleLimitChange}
              programButtonValidation={programButtonValidation}
              program={program}
              setProgram={setProgram}
              programStep={programStep}
              setProgramStep={setProgramStep}
              programStepSensor={programStepSensor}
              setProgramStepSensor={setProgramStepSensor}
              userAction={userAction}
              setUserAction={setUserAction}
              userGroupAction={userGroupAction}
              setUserGroupAction={setUserGroupAction}
              steps={steps}
              setSteps={setSteps}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              selectedSensors={selectedSensors}
              setSelectedSensors={setSelectedSensors}
              selectedUserGroups={selectedUserGroups}
              setSelectedUserGroups={setSelectedUserGroups}
            />
            <LimitTemplatesDrawer
              drawer={limitsTemplateDrawer}
              toggleDrawer={toggleLimitsTemplateDrawer}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              sensors={sensors}
            />
          </Grid>
        </>
      )}
    </Fragment>
  );
};

export default AlertProgramSetup;
