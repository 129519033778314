import React, { useContext, useState, useEffect, useRef } from "react";
import SplitPane from "@Components/SplitPane/SplitPane";
import Divider from "@Components/SplitPane/PaneDivider";
import { AlarmContext } from "@Context/AlarmContext";
import LineDivider from "@mui/material/Divider";
import Toast from "@Components/BaseComponents/Toast";
import {
  useFindEquipmentSensorTypesWithStatusQuery,
  useLiveEquipmentQuery,
  useEditEquipmentMutation,
} from "../../../Store/Slices/equipmentSlice";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import {
  createLocationCombinations,
  filterObjectByValue,
  findObjectByValue,
  locationsString,
  validateUIState,
} from "../../../Utils/Tools";
import ThemeButton from "@Components/Buttons";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import { useSelector } from "react-redux";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Box } from "@mui/material";
import MapViewPane from "../../../Components/SplitPane/MapViewPane";
import SetupMapPane from "../../../Components/SplitPane/SetupMapPane";
import LocationsDropdown from "../../../Components/BaseComponents/LocationsDropdown";
import { useFindSubstationsQuery } from "../../../Store/Slices/substationSlice";
import DrawerComponent from "../../../Components/BaseComponents/Drawer";
import {
  useFindMappedEquipmentsQuery,
  useDeleteMappedEquipmentMutation,
  useEditLocationMappedEquipmentMutation,
} from "../../../Store/Slices/mappedEquipmentSlice";
import {
  useFindMappedSubstationsQuery,
  useDeleteMappedSubstationMutation,
} from "../../../Store/Slices/mappedSubstationSlice";
import { useLocation } from "react-router-dom";
import ResourceDrawerComponent from "../../../Components/BaseComponents/Drawer/ResourceDrawer";

const Map = () => {
  const navigatedLocation = useLocation();
  const resourceDrawer = useRef();
  const alarmPanel = useContext(AlarmContext);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [equipmentLive, setEquipmentLive] = useState([]);
  const [locations, setLocations] = useState([]);
  const [checkLocations, setCheckLocations] = useState([]);
  const [tempLocations, setTempLocations] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [equipment, setEquipment] = useState(navigatedLocation.state?.device || {});
  const [substation, setSubstation] = useState({});
  const [location, setLocation] = useState({});
  const [selectedLocationId, setSelectedLocationId] = useState(navigatedLocation.state?.ID || "");
  const [substations, setSubstations] = useState([]);
  const [edit, setEdit] = useState(false);
  const [response, setResponse] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [changesDrawer, setChangesDrawer] = useState(false);
  const [clearMapDrawer, setClearMapDrawer] = useState(false);
  const [clearMap, setClearMap] = useState(false);
  const [devices, setDevices] = useState([]);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [updatedMappedEquipments, setUpdatedMappedEquipments] = useState([]);
  const [editedMappedEquipments, setEditedMappedEquipments] = useState([]);
  const [mappedSubstations, setMappedSubstations] = useState([]);
  const [updatedMappedSubstations, setUpdatedMappedSubstations] = useState([]);
  const [editedMappedSubstations, setEditedMappedSubstations] = useState([]);
  const [paneWidth, setPaneWidth] = useState(870);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null);
  const [saveDrawer, setSaveDrawer] = useState(false);
  const [newMapX, setNewMapX] = useState(50);
  const [newMapY, setNewMapY] = useState(20);

  useEffect(() => {
    setLocation(
      findObjectByValue(
        tempLocations,
        "LocationID",
        selectedLocationId || navigatedLocation.state?.ID
      )
    );
  }, [location]);

  const saveToggleDrawer = (open) => {
    setSaveDrawer(open);
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
  };
  const toggleChangesDrawer = (open) => {
    setChangesDrawer(open);
  };
  const toggleClearMapDrawer = (open) => {
    setClearMapDrawer(open);
  };
  const generateUniqueMapX = () => {
    if (newMapX < 300) {
      setNewMapX(newMapX + 50);
      return { mapX: (newMapX + 50).toString(), mapY: newMapY.toString() };
    } else {
      setNewMapY(newMapY + 40);
      setNewMapX(100);
      return { mapX: "100", mapY: (newMapY + 40).toString() };
    }
  };
  const isAdmin = auth.isAdmin;
  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Maps");

  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesWithStatusQuery();

  const {
    data: equipmentLiveData,
    isLoading: equipmentLiveDataLoading,
    isSuccess: equipmentLiveDataSuccess,
    isError: equipmentLiveDataIsError,
    error: equipmentLiveDataError,
  } = useLiveEquipmentQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [deleteMappedEquipment, { isSuccess: deleteMappedEquipmentSuccess }] =
    useDeleteMappedEquipmentMutation();

  const [deleteMappedSubstation, { isSuccess: deleteMappedSubstationSuccess }] =
    useDeleteMappedSubstationMutation();

  const [editLocationMappedEquipment, { isSuccess: editLocationMappedEquipmentSucces }] =
    useEditLocationMappedEquipmentMutation();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      const tLocations = locationsData.map((e) => {
        return {
          value: e.LocationID,
          label: e.LocationName,
          LocationID: e.LocationID,
          ParentID: e.ParentID,
          MapFile: e.MapFile,
        };
      });
      if (!isAdmin) {
        const customerLocations = filterObjectByValue(locationsData, "CustomerID", auth.CustomerID);

        setLocations(
          customerLocations.map((e) => {
            return {
              value: e.LocationID,
              label: e.LocationName,
              LocationID: e.LocationID,
              ParentID: e.ParentID,
              MapFile: e.MapFile,
            };
          })
        );
        setTempLocations(customerLocations);
      } else {
        if (auth.SelectedCustomer == "") {
          setLocations(tLocations);
          setTempLocations(locationsData);
        } else {
          const filteredLocations = filterObjectByValue(
            locationsData,
            "CustomerID",
            auth.SelectedCustomer
          );

          setLocations(
            filteredLocations.map((e) => {
              return {
                value: e.LocationID,
                label: e.LocationName,
                LocationID: e.LocationID,
                ParentID: e.ParentID,
                MapFile: e.MapFile,
              };
            })
          );
          setTempLocations(filteredLocations);
        }
      }
      let locationsInfo = {};
      for (const location of locationsData) {
        locationsInfo[location.LocationID] = locationsString(location, locationsData);
      }
      setLocationsHierarchy(locationsInfo);
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };
  const loadSubstations = () => {
    if (substationsSuccess) {
      setSubstations(substationsData);
    }
    if (isSubstationError) console.log(substationError);
  };

  const loadEquipment = () => {
    if (equipmentLoading) setLoading(true);
    if (equipmentSuccess && locationsSuccess) {
      const tempEquipments = equipmentData.success.data
        .map((val) => {
          return {
            EquipmentID: val.EquipmentID,
            EquipmentName: val.EquipmentName,
            EquipmentTypeName: val.EquipmentType?.EquipmentTypeName,
            EquipmentTypeID: val.EquipmentType?.EquipmentTypeID,
            LocationName: val.Location?.LocationName,
            LocationID: val.LocationID,
            CustomerID: val.Location?.Customer.CustomerID,
            CustomerName: val.Location?.Customer.CustomerName,
            updatedAt: val.updatedAt,
            equipmentSensorTypes: val.equipmentSensorTypes,
            IsActive: !val.IsArchive,
          };
        })
        .filter((val) => {
          return val.IsActive === true;
        });
      if (!isAdmin) {
        setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
      } else {
        if (auth.SelectedCustomer == "") {
          setEquipments(tempEquipments);
        } else {
          const filteredLocations = filterObjectByValue(
            tempEquipments,
            "CustomerID",
            auth.SelectedCustomer
          );

          setEquipments(filteredLocations);
        }
      }
    }
    if (equipmentIsError) console.log(equipmentError);
    setLoading(false);
  };

  const handleRemove = async (item, reason) => {
    if (equipment.EquipmentID) {
      const mappedObj = mappedEquipments.find((eq) => {
        return eq.EquipmentID == equipment.EquipmentID && eq.LocationID == selectedLocationId;
      });
      if (mappedObj) {
        const id = mappedObj.MappedEquipmentID;
        let res = await deleteMappedEquipment({ id, data: { reason: reason } });
      }
    } else if (equipment.SubstationID) {
      const mappedObj = mappedSubstations.find((eq) => {
        return eq.SubstationID == equipment.SubstationID && eq.LocationID == selectedLocationId;
      });
      if (mappedObj) {
        const id = mappedObj.MappedSubstationID;
        let res = await deleteMappedSubstation({ id, data: { reason: reason } });
      }
    }
    toggleDrawer(false);
    setEdit(false);
    setEquipment({});
    loadMappedEquipments();
    loadMappedSubstations();
  };

  const handleSubstationRemove = async () => {
    const mappedObj = findObjectByValue(mappedSubstations, "SubstationID", equipment.EquipmentID);
    const id = mappedObj.MappedSubstationID;
    let res = await deleteMappedSubstation(id);
    toggleDrawer(false);
    setEdit(false);
    setEquipment({});
  };

  const loadMappedEquipments = () => {
    if (mappedEquipmentLoading) setLoading(true);
    if (mappedEquipmentSuccess && equipmentSuccess) {
      setMappedEquipments(
        mappedEquipmentData.success.data
          .map((equipment) => {
            const equipmentObj = findObjectByValue(
              equipmentData.success.data,
              "EquipmentID",
              equipment.EquipmentID
            );
            return {
              ...equipment,
              IsActive: equipmentObj?.IsActive || false,
            };
          })
          .filter((val) => {
            return val.IsActive === true;
          })
      );
    }
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData, deleteMappedEquipmentSuccess, equipmentData]);

  const loadMappedSubstations = () => {
    if (mappedSubstationLoading) setLoading(true);
    if (mappedSubstationSuccess && substationsSuccess) {
      setMappedSubstations(
        mappedSubstationData.success.data
          .map((substation) => {
            const substationObj = findObjectByValue(
              substationsData,
              "SubstationID",
              substation.SubstationID
            );
            return {
              ...substation,
              IsActive: substationObj?.IsActive || false,
            };
          })
          .filter((val) => {
            return val.IsActive === true;
          })
      );
    }
    if (mappedSubstationIsError) console.log(mappedSubstationError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedSubstations();
  }, [mappedSubstationData, deleteMappedSubstationSuccess, substationsData]);

  useEffect(() => {
    if (equipmentLiveDataSuccess) {
      setEquipmentLive(equipmentLiveData.success.data);
    }
  }, [equipmentLiveData, deleteMappedEquipmentSuccess, deleteMappedSubstationSuccess]);

  useEffect(() => {
    loadLocations();
  }, [locationsData, auth]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData, auth, deleteMappedEquipmentSuccess, deleteMappedSubstationSuccess]);

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  useEffect(() => {
    setLocation(
      findObjectByValue(
        tempLocations,
        "LocationID",
        navigatedLocation.state?.ID || selectedLocationId
      )
    );
  }, [selectedLocationId, navigatedLocation]);

  useEffect(() => {
    let locationCombinations = createLocationCombinations(locations);
    const tempdevices = [...equipments, ...substations];
    let filterdTempDevices;
    if (locationCombinations[selectedLocationId]) {
      filterdTempDevices = tempdevices.filter((tempdevice) =>
        locationCombinations[selectedLocationId].includes(tempdevice.LocationID)
      );
      setDevices(filterdTempDevices);
    } else {
      setDevices([]);
    }
  }, [
    substations,
    equipments,
    deleteMappedEquipmentSuccess,
    selectedLocationId,
    navigatedLocation,
  ]);

  const handleSave = () => {
    
    resourceDrawer.current.handleSubmitClick("Save Item");
  };
  const resourceHandleSave = async (reason) => {
    
    const id = selectedLocationId;
    const data = {
      Equipments: filterObjectByValue(updatedMappedEquipments, "LocationID", selectedLocationId),
      Substations:filterObjectByValue(updatedMappedSubstations, "LocationID", selectedLocationId),
      reason: reason,
    };
    const res = editLocationMappedEquipment({
      id,
      data,
    });
    setEditedMappedEquipments([]);
    setEditedMappedSubstations([]);
    setEdit(false);
  };

  const handleClearMap = (equipment = "", reason) => {
    const id = selectedLocationId;
    const data = {
      Equipments: [],
      Substations: [],
      reason: reason,
    };
    const res = editLocationMappedEquipment({
      id,
      data,
    });
    setClearMap(true);

    toggleClearMapDrawer(false);
  };

  useEffect(() => {
    if (!clearMap) return;
    const timeout = setTimeout(() => {
      setClearMap(false);
    }, 9000);

    return () => clearTimeout(timeout);
  }, [clearMap]);

  useEffect(() => {
    setUpdatedMappedEquipments(
      mappedEquipments.map((eq) => {
        return {
          EquipmentID: eq.EquipmentID,
          LocationID: eq.LocationID,
          MapX: eq.MapX,
          MapY: eq.MapY,
          IsActive: eq.IsActive,
        };
      })
    );
  }, [mappedEquipments]);

  useEffect(() => {
    setUpdatedMappedSubstations(
      mappedSubstations.map((eq) => {
        return {
          SubstationID: eq.SubstationID,
          LocationID: eq.LocationID,
          MapX: eq.MapX,
          MapY: eq.MapY,
          IsActive: eq.IsActive,
        };
      })
    );
  }, [mappedSubstations]);

  useEffect(() => {
    setCheckLocations(
      locations.filter((location) => {
        return location.MapFile;
      })
    );
  }, [locations]);

  useEffect(() => {}, [checkLocations]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 6 }}>
      {deleteMappedEquipmentSuccess && (
        <Toast
          message="Device successfully removed from map"
          severity="success"
        />
      )}
      {deleteMappedSubstationSuccess && (
        <Toast
          message="Device successfully removed from map"
          severity="success"
        />
      )}
      {editLocationMappedEquipmentSucces && (
        <Toast
          severity="success"
          message={`Map successfully ${clearMap ? "cleared" : " updated"}`}
        />
      )}

      {equipmentLoading &&
      equipmentLiveDataLoading &&
      locationsLoading &&
      substationsLoading &&
      equipments.length > 0 ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SpinnerContainer>
            <Loader loading={loading} />
          </SpinnerContainer>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          {checkLocations.length == 0 && !locationsLoading ? (
            <Box
              sx={{
                height: "calc(100vh - 150px)",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <section
                  style={{
                    background: "#E0E1E3 0% 0% no-repeat padding-box",
                    width: "216px",
                    height: "216px",
                    borderRadius: "8px",
                  }}
                >
                  <i
                    class="fa-light fa-location-dot-slash"
                    style={{
                      fontSize: "130px",
                      color: "#222934",
                      lineHeigth: "156px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      margin: "auto",
                      height: "inherit",
                    }}
                  ></i>
                </section>
                <p
                  style={{
                    color: "#222934",
                    fontSize: "18px",
                    lineHeight: "25px",
                    marginTop: "20px",
                  }}
                >
                  There are no maps for your organization{" "}
                </p>
                <p
                  style={{
                    color: "#9CA1A6",
                    fontSize: "12px",
                    lineHeight: "22px",
                  }}
                >
                  Contact your administrator to setup your organization’s maps
                </p>
              </div>
            </Box>
          ) : (
            <Box>
              {selectedLocationId == "" ? (
                <Box
                  sx={{
                    height: "calc(100vh - 150px)",
                    width: "100%",
                  }}
                >
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0.8em",
                    }}
                  >
                    <LocationsDropdown
                      setSelectedLocationId={setSelectedLocationId}
                      selectedLocationId={selectedLocationId}
                      substations={true}
                    />
                  </section>
                  <LineDivider />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <section
                      style={{
                        background: "#E0E1E3 0% 0% no-repeat padding-box",
                        width: "216px",
                        height: "216px",
                        borderRadius: "8px",
                      }}
                    >
                      <i
                        class="fa-light fa-map-location"
                        style={{
                          fontSize: "130px",
                          color: "#222934",
                          lineHeigth: "156px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          margin: "auto",
                          height: "inherit",
                        }}
                      ></i>
                    </section>
                    <p
                      style={{
                        color: "#222934",
                        fontSize: "18px",
                        lineHeight: "25px",
                        marginTop: "15px",
                      }}
                    >
                      Select a map from the dropdown list above
                    </p>
                  </div>
                </Box>
              ) : (
                <SplitPane
                  className="split-pane-row"
                  setPaneWidth={setPaneWidth}
                  setCurrentBreakpoint={setCurrentBreakpoint}
                >
                  <MapViewPane
                    partition="split-data"
                    equipments={equipments}
                    equipmentLive={equipmentLive}
                    locations={locations}
                    substations={substations}
                    setTab={setTab}
                    setLocation={setLocation}
                    setSelectedLocationId={setSelectedLocationId}
                    selectedLocationId={selectedLocationId}
                    locationsHierarchy={locationsHierarchy}
                    setEquipment={setEquipment}
                    equipment={equipment}
                    edit={edit}
                    setEdit={setEdit}
                    devices={devices}
                    setDevices={setDevices}
                    toggleDrawer={toggleDrawer}
                    mappedEquipments={mappedEquipments}
                    updatedMappedEquipments={updatedMappedEquipments}
                    setUpdatedMappedEquipments={setUpdatedMappedEquipments}
                    mappedSubstations={mappedSubstations}
                    updatedMappedSubstations={updatedMappedSubstations}
                    setUpdatedMappedSubstations={setUpdatedMappedSubstations}
                    currentBreakpoint={currentBreakpoint}
                    allowedEdit={allowedEdit}
                    generateUniqueMapX={generateUniqueMapX}
                    CustomerID={auth.CustomerID}
                  />
                  <Divider className="separator-col" />

                  <SetupMapPane
                    paneWidth={paneWidth}
                    CustomerID={auth.CustomerID}
                    LocationID={selectedLocationId}
                    location={location}
                    setEquipment={setEquipment}
                    SelectedEquipment={equipment}
                    equipments={equipments}
                    setSelectedEquipment={setEquipment}
                    substations={substations}
                    setSelectedLocationId={setSelectedLocationId}
                    devices={devices}
                    setDevices={setDevices}
                    setEdit={setEdit}
                    equipmentLive={equipmentLive}
                    edit={edit}
                    toggleDrawer={toggleDrawer}
                    toggleClearMapDrawer={toggleClearMapDrawer}
                    mappedEquipments={mappedEquipments}
                    mappedEquipmentLoading={mappedEquipmentLoading}
                    mappedSubstationLoading={mappedSubstationLoading}
                    updatedMappedEquipments={updatedMappedEquipments}
                    setUpdatedMappedEquipments={setUpdatedMappedEquipments}
                    handleSave={handleSave}
                    toggleChangesDrawer={toggleChangesDrawer}
                    selectedLocationId={selectedLocationId}
                    setEditedMappedEquipments={setEditedMappedEquipments}
                    mappedSubstations={mappedSubstations}
                    updatedMappedSubstations={updatedMappedSubstations}
                    editedMappedSubstations={editedMappedSubstations}
                    editedMappedEquipments={editedMappedEquipments}
                    setUpdatedMappedSubstations={setUpdatedMappedSubstations}
                    setEditedMappedSubstations={setEditedMappedSubstations}
                    allowedEdit={allowedEdit}
                    saveToggleDrawer={saveToggleDrawer}
                  />
                </SplitPane>
              )}
            </Box>
          )}
        </Box>
      )}

      <ResourceDrawerComponent
        ref={resourceDrawer}
        deleteDrawer={drawer}
        deactivateToggleDrawer={toggleDrawer}
        toggleDrawer={toggleDrawer}
        title="Remove from map?"
        handleDelete={handleRemove}
        deactivateButtonTitle="Remove"
        deleteTitle="Remove from map?"
        handleSubmit={resourceHandleSave}
        item={equipment}
        heading={`Are you sure you want to remove the${" "}
        ${equipment.EquipmentName || equipment.Label} from the${" "}
        ${location?.LocationName} map?`}
      />
      <ResourceDrawerComponent
        deleteDrawer={clearMapDrawer}
        deactivateToggleDrawer={toggleClearMapDrawer}
        toggleDrawer={toggleClearMapDrawer}
        title="Clear map"
        deleteTitle="Clear map"
        deactivateButtonTitle="Clear map"
        displayFooter={false}
        handleDelete={handleClearMap}
        item={equipment}
        heading={`Are you sure you want to clear all of the equipment from the${" "}
        ${location?.LocationName} map?`}
      />
      <DrawerComponent
        drawer={changesDrawer}
        toggleDrawer={toggleChangesDrawer}
        title="Save changes?"
        displayFooter={false}
      >
        <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
          <Box
            sx={{
              width: "530px",
              textAlign: "left",
              marginBottom: "15px",
              font: "normal normal normal 20px/24px Roboto",
            }}
          >
            You have unsaved changes.
          </Box>
          <Box sx={{ display: "flex" }}>
            <div
              style={{ display: "inline", marginRight: "25px" }}
              onClick={() => [
                setEdit(false),
                setEquipment({}),
                setUpdatedMappedEquipments(mappedEquipments),
                setUpdatedMappedSubstations(mappedSubstations),
                setEditedMappedEquipments([]),
                setEditedMappedSubstations([]),
                toggleChangesDrawer(false),
                setNewMapX(50),
                setNewMapY(20),
              ]}
            >
              <OutlinedButton
                variant="outlined"
                color="primary"
                size="small"
                width="248px"
              >
                Discard
              </OutlinedButton>
            </div>

            <ThemeButton
              variant="contained"
              color="primary"
              size="small"
              width="248px"
              onClick={() => toggleChangesDrawer(false)}
            >
              Continue
            </ThemeButton>
          </Box>
        </Box>
      </DrawerComponent>
    </Box>
  );
};

export default Map;
