import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxComponent from "../Checkbox";
import ToolTipComponent from "../Tooltip";
import MultipleSelectCheckmarks from "../MultiSelect";
import ListItemText from "@mui/material/ListItemText";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import IconButtonComponent from "../IconButton";

export default function AlertProgramFilter(props) {
  const { selectedAlarm, handleAlarmSelect } = props;
  const {
    handleSelectUsers,
    handleSelectUserGroup,
    handleStatusList,
    selectedUsers,
    users,

    selectedStatus,
    selectedUserGroups,
    userGroups,
  } = props;

  const {
    equipmentFilterCount,
    sensorTypeFilterCount,
    locationFilterCount,
    alarmFilterCount,
    statusFilterCount,
    clearFilter,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            equipmentFilterCount +
              sensorTypeFilterCount +
              locationFilterCount +
              alarmFilterCount +
              statusFilterCount >
            0
              ? "Filters (" +
                String(
                  equipmentFilterCount +
                    sensorTypeFilterCount +
                    locationFilterCount +
                    alarmFilterCount +
                    statusFilterCount
                ) +
                " applied)"
              : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {equipmentFilterCount +
                      sensorTypeFilterCount +
                      locationFilterCount +
                      alarmFilterCount +
                      statusFilterCount >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          equipmentFilterCount +
                          sensorTypeFilterCount +
                          locationFilterCount +
                          alarmFilterCount +
                          statusFilterCount
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "500px",
            minWidth: "500px",
            overflow: "auto",
            padding: "16px",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Program type
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={2}
                      sx={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={selectedAlarm.findIndex((item) => item == "Warning") > -1}
                              onChange={handleAlarmSelect}
                              value="Warning"
                              name="Warning"
                              Label="Warning"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleAlarmSelect}
                              value="Alarm"
                              name="Alarm"
                              Label="Alarm"
                              checked={selectedAlarm.findIndex((item) => item == "Alarm") > -1}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>
            </div>

            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Alert type
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={2}
                      sx={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={
                                selectedStatus.findIndex((item) => item == "Notification") > -1
                              }
                              onChange={handleStatusList}
                              value="Notification"
                              name="Notification"
                              Label="Notification"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleStatusList}
                              value="Physical"
                              name="Physical"
                              checked={selectedStatus.findIndex((item) => item == "Physical") > -1}
                              Label="Physical"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>{" "}
            </div>
            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                User(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectUsers}
                placeholder="Select user(s)"
                personName={selectedUsers}
                data={users}
              >
                {users.map((user) => (
                  <MenuItem
                    key={user.value}
                    value={user.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <CheckBoxComponent
                      className="checkBox"
                      size="small"
                      checked={selectedUsers.indexOf(user.value) > -1}
                    />
                    <ListItemText primary={user.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>

          <div style={{ marginTop: "24px" }}>
            <p
              style={{
                textAlign: "left",
                font: "normal normal normal 12px/16px Roboto",
                color: "#9CA1A6",
                marginBottom: "4px",
              }}
            >
              User group(s)
            </p>

            <MultipleSelectCheckmarks
              onChange={handleSelectUserGroup}
              placeholder="Select user group(s)"
              personName={selectedUserGroups}
              data={userGroups}
            >
              {userGroups.map((userGroup) => (
                <MenuItem
                  key={userGroup.value}
                  value={userGroup.value}
                  sx={{
                    font: "normal normal medium 14px/20px Roboto",
                    color: "#222934",
                    "&:hover": {
                      background: "#E3E9EC 0% 0% no-repeat padding-box",
                      color: "#17455E",
                    },
                    "&$selected": {
                      // <-- mixing the two classes
                      backgroundColor: "transparent",
                    },
                  }}
                  className="menuItem"
                >
                  <CheckBoxComponent
                    className="checkBox"
                    size="small"
                    checked={selectedUserGroups.indexOf(userGroup.value) > -1}
                  />
                  <ListItemText primary={userGroup.label} />
                </MenuItem>
              ))}
            </MultipleSelectCheckmarks>
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
