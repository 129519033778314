import { useState } from "react";
import { Box } from "@mui/material";
import { useGetArchivedItemsQuery } from "../../../Store/Slices/archiveSlice";
import LocationTabs from "./LocationTabs";
import ResourceDrawerComponent from "../../BaseComponents/Drawer/ResourceDrawer";
import { useFindEquipmentQuery } from "../../../Store/Slices/equipmentSlice";
import { useFindSubstationsQuery } from "../../../Store/Slices/substationSlice";

export default function RightDrawer({ ...props }) {
  const {
    toggleDrawer,
    drawer,
    locationTree,
    location,
    locations,
    handleChange,
    handleClick,
    handleChangeSelect,
    locationCombinations,
    edit,
    locationDeleteDrawer,
    customToggleLocationtDeleteDrawer,
    handleParentLocation,
    setLocation,
    clearLocation,
    parentLocation,
    locationUpdated,
    setLocationUpdated,
    handleDelete,
    checkBox,
    setCheckBox,
    openedTab,
    setOpenedTab,
  } = props;

  const [resetPasswordDrawer, setResetPasswordDrawer] = useState(false);

  const { data: archivedData, isSuccess: archivedSuccess } = useGetArchivedItemsQuery({
    entityTypes: '["Location"]',
  });

  const { data: equipmentData } = useFindEquipmentQuery();

  const { data: substationsData } = useFindSubstationsQuery();

  const checkCanBeArchived = () => {
    const queue = [location];

    while (queue.length > 0) {
      const currentLocation = queue.shift();

      const equipmentAssigned = equipmentData?.some(
        (equipment) => equipment.LocationID === currentLocation.LocationID
      );
      const substationAssigned = substationsData?.some(
        (substation) => substation.LocationID === currentLocation.LocationID
      );

      if (equipmentAssigned || substationAssigned) {
        return false;
      }

      if (currentLocation.childNodes && currentLocation.childNodes.length > 0) {
        queue.push(...currentLocation.childNodes);
      }
    }

    return true;
  };

  const canBeArchived = checkCanBeArchived();

  const handleDiscard = (e) => {
    setLocationUpdated(false);
    confirmationDrawer(e);
    toggleDrawer(e);
    clearLocation();
    setCheckBox(false);
  };

  const locationConfirmationDrawer = () => {
    if (locationUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };
  const confirmationDrawer = (open) => {
    setResetPasswordDrawer(open);
  };

  const filterLocation = (location, locationTree) => {
    const filteredTree = locationTree.filter((node) => {
      if (node.LocationName === location.LocationName && node.ParentID === location.ParentID) {
        return false;
      }
      if (node.childNodes && node.childNodes.length > 0) {
        node.childNodes = filterLocation(location, node.childNodes);
      }
      return true;
    });

    return filteredTree;
  };

  const checkUniqueSameParentChildLocation = (location, locationTree) => {
    for (const node of locationTree) {
      if (node.LocationName === location.LocationName && node.ParentID === location.ParentID) {
        return true;
      }

      if (node.childNodes.length > 0) {
        if (checkUniqueSameParentChildLocation(location, node.childNodes)) {
          return true;
        }
      }
    }
    return false;
  };

  const checkUniqueParentLocation = () => {
    if (!location.ParentID) {
      // If the location is going to be a parent, check that the name is unique among parent locations
      const isParentLocationNameDuplicate = locations
        .filter((loc) => loc.LocationID !== location.LocationID)
        .some(
          (loc) =>
            loc.ParentID === null &&
            loc.LocationName.toLowerCase() === location.LocationName.toLowerCase()
        );
      return isParentLocationNameDuplicate; // return true if duplicate (error), false if unique
    }
    // If the location is going to have a parent, we don't care about the name, hence no error
    return false;
  };

  const checkIsUniqueLocationName = () => {
    const item =
      archivedSuccess &&
      archivedData?.success?.data?.Location?.find((loc) => location.LocationName === loc.itemName);

    return !!item;
  };

  const uniqueParentLocationError = checkUniqueParentLocation();
  const uniqueLocationNameError = checkIsUniqueLocationName();
  const uniqueSameParentChildLocationError = checkUniqueSameParentChildLocation(
    location,
    edit ? filterLocation(location, locationTree) : locationTree
  );

  const detailValidation =
    location.LocationName === "" ||
    uniqueParentLocationError ||
    uniqueLocationNameError ||
    uniqueSameParentChildLocationError ||
    (edit && !locationUpdated);

  const validationErrorMessage = uniqueParentLocationError
    ? "Parent location name must be unique"
    : uniqueLocationNameError
    ? "Location name is not unique with an archived location"
    : uniqueSameParentChildLocationError
    ? "Child location name must be unique under the same parent location"
    : null;

  const skipSequence = () => {
    if (openedTab !== "2") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={locationConfirmationDrawer}
        deactivateToggleDrawer={customToggleLocationtDeleteDrawer}
        title={
          openedTab !== "1" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fa-solid fa-chevron-left"
                style={{
                  marginRight: "15px",
                  width: "20px",
                  height: "32px",
                  color: "#9CA1A6",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenedTab(String(parseInt(openedTab) - 1))}
              ></i>
              {edit ? "Edit" : "New"} location
            </div>
          ) : edit ? (
            "Edit location"
          ) : (
            "New location"
          )
        }
        submitButtonTitle={openedTab === "2" ? (edit ? "Update Location" : "Add Location") : "Next"}
        handleSubmit={(reason) => {
          openedTab === "2" ? handleClick(reason) : setOpenedTab(String(parseInt(openedTab) + 1));
        }}
        displayFooter={true}
        archiveButton={{
          disabled: !canBeArchived,
          tooltipCopy:
            "This Location cannot be archived because it, or its child location, has associated devices",
        }}
        edit={edit}
        closeToast={() => setLocationUpdated(false)}
        buttonValidation={detailValidation}
        deactivateButtonTitle="Archive"
        discardButtonTitle="Continue"
        deleteDrawer={locationDeleteDrawer}
        item={location}
        loadItem={() => setOpenedTab("1")}
        setUpdated={setLocationUpdated}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={resetPasswordDrawer}
        handleDelete={handleDelete}
        deleteTitle="Archive location"
        heading={`Are you sure you want to remove the ${location.LocationName}?`}
        actionCalled="Tab System"
        skipSequence={skipSequence}
      >
        <Box>
          <LocationTabs
            location={location}
            locations={locations}
            handleChange={handleChange}
            openedTab={openedTab}
            setOpenedTab={setOpenedTab}
            handleChangeSelect={handleChangeSelect}
            locationCombinations={locationCombinations}
            setLocation={setLocation}
            clearLocation={clearLocation}
            handleParentLocation={handleParentLocation}
            locationTree={locationTree}
            parentLocation={parentLocation}
            setLocationUpdated={setLocationUpdated}
            checkBox={checkBox}
            setCheckBox={setCheckBox}
            edit={edit}
            validationErrorMessage={validationErrorMessage}
          />
        </Box>
      </ResourceDrawerComponent>
    </>
  );
}
