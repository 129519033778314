import { apiSlice } from "../API";

export const mappedSubstationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findMappedSubstations: builder.query({
      query: () => "/mappedSubstation",
      providesTags: ["MappedSubstation"],
    }),
    getMappedSubstation: builder.query({
      query: (id) => `/mappedSubstation/${id}`,
      providesTags: ["MappedSubstation"],
    }),
    addMappedSubstation: builder.mutation({
      query: (MappedSubstation) => ({
        url: "/mappedSubstation",
        method: "POST",
        body: MappedSubstation,
      }),
      invalidatesTags: ["MappedSubstation"],
    }),
    editMappedSubstation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedSubstation/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["MappedSubstation"],
    }),
    editLocationMappedSubstation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedSubstation/updateMappedSubstations/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["MappedSubstation"],
    }),
    deleteMappedSubstation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedSubstation/${id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["MappedSubstation"],
    }),
  }),
});

export const {
  useFindMappedSubstationsQuery,
  useGetMappedSubstationQuery,
  useAddMappedSubstationMutation,
  useEditMappedSubstationMutation,
  useEditLocationMappedSubstationMutation,
  useDeleteMappedSubstationMutation,
} = mappedSubstationSlice;
