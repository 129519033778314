import React, { useState, useEffect } from "react";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
//UI COMPONENTS
import { Box } from "@mui/material";
import ArchivedTable from "@Components/Tables/ArchivedTable";
import Toast from "@Components/BaseComponents/Toast";
import {
  useGetArchivedItemsQuery,
  useRemoveArchiveItemsMutation,
} from "../../../Store/Slices/archiveSlice";
import { useFindUsersQuery } from "../../../Store/Slices/usersSlice";
import { formatDate } from "../../../Utils/Tools";
import ResourceDrawerComponent from "../../../Components/BaseComponents/Drawer/ResourceDrawer";
import ThemeButton from "../../../Components/Buttons/TextButton";
import { useTheme } from "@mui/system";

const ArchivedSetup = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [archivedItems, setArchivedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [archiveDrawer, setArchiveDrawer] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const {
    data: archivedData,
    isLoading: archivedLoading,
    isSuccess: archivedSuccess,
    isError: archiveIsError,
    error: archiveError,
  } = useGetArchivedItemsQuery({
    entityTypes: '["User", "Sensor", "Location", "Equipment", "Substation"]',
  });
  const {
    data: usersData,
    isFetching: usersLoading,
    isSuccess: usersSuccess,
    isError: userIsError,
    error: userError,
  } = useFindUsersQuery();

  const [
    removeArchive,
    { isSuccess: removeArchiveSuccess, isError: removeArchiveIsError, error: removeArchiveError },
  ] = useRemoveArchiveItemsMutation();

  const unArchiveSelectedItems = async (reason) => {
    setLoading(true);
    let entityTypes = Array.from(new Set(selectedRows.map((row) => row[1])));
    const data = {};
    let itemsLength = 0;
    entityTypes.map((entityType) => {
      data[entityType] = selectedRows
        .filter((row) => row[1] === entityType)
        .map((row) => {
          itemsLength += 1;
          return row[0];
        });
    });
    setTotalItems(itemsLength);
    const res = await removeArchive({ data: data, reason: reason });
    setRowsSelected([]);
    setLoading(false);
  };

  const handleClick = () => {
    setArchiveDrawer(true);
  };
  const handleSelectedItemType = (e) => {
    setSelectedItemType(e);
    setSelectedRows([]);
  };

  useEffect(() => {
    setLoading(true);
    if (archivedData && usersSuccess) {
      const responseData = archivedData.success.data;
      const entityTypes = [selectedItemType.value];
      let tempData = [];
      entityTypes.map((entityType) => {
        if (!responseData[entityType]) return;
        responseData[entityType].map((data) => {
          const user = usersData.find((user) => user.UserID == data.archivedBy);
          const archivedBy = `${user?.FirstName} ${user?.LastName}`;

          tempData.push({
            entityID: data.entityID,
            itemName: data.itemName,
            itemType: data.itemType,
            archivedOn: formatDate(new Date(data.archivedOn), "MM/dd/yyyy"),
            archivedBy: archivedBy,
            entityType: entityType,
          });
        });
      });
      setArchivedItems(tempData);
    }
    setLoading(false);
  }, [archivedData, usersData, selectedItemType]);

  console.log("ArchiveDrawer:", archiveDrawer);
  const requireActionToggleDrawer = (open) => {
    setArchiveDrawer(open);
  };

  return (
    <>
      {removeArchiveSuccess && (
        <Toast
          message={totalItems >= 2 ? "Items restored successfully" : "Item restored successfully"}
          severity="success"
        />
      )}
      {removeArchiveIsError && (
        <Toast
          message={`There is an error while archiving items error: ${archiveError}`}
          severity="danger"
        />
      )}
      {loading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <ArchivedTable
            archivedItems={archivedItems}
            handleEdit={null}
            setSelectedRows={setSelectedRows}
            setRowsSelected={setRowsSelected}
            rowsSelected={rowsSelected}
            selectedItemType={selectedItemType}
            handleSelectedItemType={handleSelectedItemType}
          />
          <Box
            sx={{
              position: "relative",
              bottom: "55px",
              float: "right",
              marginRight: "5px",
            }}
          >
            <ThemeButton
              color="primary"
              size="small"
              onClick={rowsSelected.length && handleClick}
              disabled={!rowsSelected.length}
              backgroundColor={theme.palette.primary["800"]}
              contrastText={theme.palette.primary.contrastText}
            >
              Restore Selected
            </ThemeButton>
          </Box>
        </>
      )}
      <ResourceDrawerComponent
        setArchiveDrawer={setArchiveDrawer}
        deactivateToggleDrawer={false}
        drawer={false}
        deleteDrawer={false}
        requireActionDrawerCalled={archiveDrawer}
        unArchiveSelectedItems={unArchiveSelectedItems}
        actionLabel="archive"
      />
    </>
  );
};

export default ArchivedSetup;
