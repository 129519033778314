import React, { useState } from "react";
import { Box, InputAdornment } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import DrawerComponent from "../BaseComponents/Drawer";
import TextFieldComponent from "../BaseComponents/TextField";

export default function RequireAuthenticationDrawer({ ...props }) {
  const {
    toggleDrawer,
    drawer,
    closeToast,
    handleClick,
    buttonValidation,
    confirmationDrawer,
    discardSettingsDrawer,
    validateEmail,
  } = props;

  const [customerDrawer, setCustomDrawer] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [requireAuthentication, setRequireAuthentication] = useState({
    Email: "",
    Password: "",
  });

  const useStyles = makeStyles({
    itemPadding: {
      paddingLeft: "24px !important",
    },
    paddingTop: {
      paddingTop: "24px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleDiscard = (e) => {
  //   setTenantUpdated(false);
  //   confirmationDrawer(false);
  //   tenantToggleDrawer(false);
  // };

  const classes = useStyles();

  return (
    <>
      <DrawerComponent
        toggleDrawer={toggleDrawer}
        drawer={drawer}
        title={"Confirm action"}
        buttonTitle={"Confirm"}
        displayFooter={true}
        handleClick={handleClick}
        buttonValidation={buttonValidation}
        closeToast={closeToast}
      >
        <Box sx={{ padding: "24px" }}>
          <Box
            sx={{
              mt: "27px",
              fontWeight: "lighter",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "22px",
                color: "#222934",
                fontFamily: "Roboto",
              }}
            >
              Your credentials are required to perform this action.
            </p>
          </Box>
          <TextFieldComponent
            padding={false}
            placeholder="Enter email here"
            onChange={(e) => [
              setRequireAuthentication({
                ...requireAuthentication,
                Email: e.target.value,
              }),
            ]}
            value={requireAuthentication.Email || ""}
            name="email"
            variant="outlined"
            type="email"
            Label="Email"
          />
          <TextFieldComponent
            padding={true}
            type={showPassword ? "text" : "password"}
            placeholder="Enter password here"
            onChange={(e) => [
              setRequireAuthentication({
                ...requireAuthentication,
                Password: e.target.value,
              }),
            ]}
            value={requireAuthentication.Password || ""}
            name="password"
            variant="outlined"
            Label="Password"
            InputProps={{
              autocomplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <i
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    class="fa-light fa-eye"
                    style={{ cursor: "pointer" }}
                  ></i>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DrawerComponent>
    </>
  );
}
