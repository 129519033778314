import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { findObjectByValue } from "../../Utils/Tools";

function not(a, b) {
  return a.filter((value) => {
    return b.map((group) => group?.AuthorizationID).indexOf(value?.value) === -1;
  });
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  let {
    addUserToGroup,
    deleteUserToGroup,
    filterAuthorizations,
    filterGroup,
    filterGroupUsers,
    users,
  } = props;
  //console.log("🚀 ~ file: UserGroupsTransferList.js ~ line 30 ~ TransferList ~ filterAuthorizations", filterAuthorizations)
  const [customerChecked, setCustomerChecked] = useState([]);
  const [authorizationGroupChecked, setAuthorizationGroupChecked] = useState([]);
  const [left, setLeft] = useState([]);

  useEffect(() => {
    console.log({ filterAuthorizations, filterGroupUsers });
    setLeft(not(filterAuthorizations, filterGroupUsers));
    setCustomerChecked([]);
    setAuthorizationGroupChecked([]);
  }, [filterGroupUsers, filterAuthorizations]);

  const handleToggleLeft = (value) => () => {
    const currentIndex = customerChecked.indexOf(value);
    const newChecked = [...customerChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCustomerChecked(newChecked);
  };

  const handleToggleRight = (value) => () => {
    const currentIndex = authorizationGroupChecked.indexOf(value);
    const newChecked = [...authorizationGroupChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAuthorizationGroupChecked(newChecked);
  };

  const numberOfChecked = (checked) => checked.length;
  const numOfChecked = (array, items) => intersection(array, items).length;

  const handleToggleAll = (items, checked, set) => () => {
    console.log(checked);
    let number = numberOfChecked(checked);
    if (number === items.length) {
      let notArray = not(items, checked);
      set(notArray);
    } else {
      let unionArray = union(items, checked);
      set(unionArray);
    }
  };

  const handleCheckedRight = () => {
    addUserToGroup(
      customerChecked.map((user) => {
        return {
          AuthorizationID: user.value,
          AuthorizationGroupID: filterGroup,
        };
      })
    );
  };

  const handleCheckedLeft = () => {
    console.log("left");
    console.log({ authorizationGroupChecked });
    deleteUserToGroup(
      authorizationGroupChecked.map((user) => user.AuthorizationGroupAuthorizationID)
    );
  };

  const customList = (title, items) => {
    const numberChecked = numberOfChecked(customerChecked);

    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items, customerChecked, setCustomerChecked)}
              checked={numberChecked === items.length && items.length !== 0}
              indeterminate={numberChecked !== items.length && numberChecked !== 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberChecked}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: 400,
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value) => {
            return (
              <ListItem
                key={value.value}
                role="listitem"
                button
                onClick={handleToggleLeft(value)}
                alignItems="center"
              >
                <ListItemIcon>
                  <Checkbox
                    checked={customerChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={`${value.PageName}`} />
                <ListItemText primary={`${value.ElementName}`} />
                <ListItemText primary={`${value.UIState}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  const customList2 = (title, items) => {
    const numberChecked = numberOfChecked(authorizationGroupChecked);
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(
                items,
                authorizationGroupChecked,
                setAuthorizationGroupChecked
              )}
              checked={numberChecked === items.length && items.length !== 0}
              indeterminate={numberChecked !== items.length && numberChecked !== 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberChecked}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: 400,
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value, i) => {
            let userName = "";
            let res = findObjectByValue(users, "value", value.AuthorizationID);

            let pageName = res?.PageName;
            let elementName = res?.ElementName;
            let NavigationName = res?.NavigationName;
            let State = res?.UIState;
            return (
              <ListItem
                key={i}
                role="listitem"
                button
                onClick={handleToggleRight(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={authorizationGroupChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={`${pageName}`} />
                <ListItemText primary={`${elementName}`} />
                <ListItemText primary={`${State}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{customList("Authorizations", left)}</Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={customerChecked.length === 0 || filterGroup === undefined}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={authorizationGroupChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList2("Authorizations Added", filterGroupUsers)}</Grid>
    </Grid>
  );
}
