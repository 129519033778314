import React from "react";
import Button from "@mui/material/Button";

function AddSensorButton({ ...props }) {
  const { handleAddSensor, label, data, index } = props;

  return (
    <Button
      sx={{
        boxShadow: "none",
        width: "104",
        height: "32px",
        minWidth: "0px",
        padding: "12px",
        bgcolor: "#FFFFFF",
        color: "#17455E",
        borderColor: "#17455E",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px",
        marginTop: "0px",
        font: "normal normal medium 16px/19px Roboto",
        "&:hover": {
          bgcolor: "#17455E",
          color: "#f7f8f8",
        },
        "&:focus": {
          bgcolor: "#11364C",
          borderColor: "#00AEEF80",
          borderStyle: "solid",
          borderWidth: "2px",
          borderRadius: "4px",
          color: "#f7f8f8",
        },
        "&:disabled": {
          bgcolor: `#17455E`,
          color: "#f7f8f8",
          opacity: 0.65,
        },
      }}
      variant="contained"
      onClick={() => handleAddSensor(label, data, index, true)}
    >
      <i
        class="fa-light fa-plus "
        style={{ marginRight: "5px" }}
      ></i>
      SENSOR
    </Button>
  );
}

export default AddSensorButton;
