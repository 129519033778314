import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import {
  findObjectByValue,
  filterObjectByValue,
  deepCopy,
  isSensorAlarm,
  isSensorWarning,
  getLimitValue,
  getColorCodingStatus,
} from "../../Utils/Tools";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import IconButtonComponent from "../BaseComponents/IconButton";
import { useScrollToTableRow } from "../../Hooks/useScrollToTableRow";
import TableComponent from "../BaseComponents/Table";
import SearchComponent from "../BaseComponents/Search";
import ToolTipComponent from "../BaseComponents/Tooltip";
import { useNavigate, useLocation } from "react-router-dom";
import { useUnSuppressSensorsMutation } from "../../Store/Slices/sensorSuppressSlice";
import EditSubstations from "../../Routes/Setup/Substations/EditSubstations";
import PowerIcon from "../BaseComponents/PowerIcon";
import SubstationFilter from "../BaseComponents/Filter/SubstationFilter";
import ConnectionIcon from "../BaseComponents/ConnectionIcon";
import EditSensor from "../../Routes/Setup/Sensor/EditSensors";
import AddSensorButton from "../BaseComponents/AddSensorButton";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";
import CustomMenu from "@Components/BaseComponents/Menu";
import MenuItemComponent from "../BaseComponents/MenuItem";
import { Link } from "react-router-dom";
import SensorContextMenu from "../BaseComponents/SensorContextMenu";
import ContextMenuItem from "../BaseComponents/ContextMenuItem";

const SubtationsTable = (props) => {
  const {
    substations,
    handleEdit,
    handleSensorEdit,
    toggleDrawer,
    locationsHierarchy,
    handleAddSensor,
    equipments,
    equipmentTypes,
    substationSensorsData,
    sensorLimitTemplates,
    sensorTypes,
    locations,
    allowedEdit,
    auth,
    mappedSubstations,
    handleAlertSuppression,
    handleSubstationSupressAlarms,
  } = props;
  const [tableLook, setTableLook] = useState("dense");
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [statusFilterCount, setStatusFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [tempSubstations, setTempSubstations] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const { handleSelectRowIndex } = useScrollToTableRow();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const navigate = useNavigate();
  const { state } = useLocation();

  const sensorIDs = substationSensorsData?.[selectedRow.SubstationID]?.map(
    (sensor) => sensor.SensorID
  );
  const selectedSubstationID = substations.find(
    (sub) => sub.SubstationID === state?.SubstationID
  )?.SubstationID;

  const authorizations = auth.Authorizations["setup"];
  const permissionAllowed = filterObjectByValue(authorizations, "element", "Maps");

  const handleSubstationContextMenu = (event, substation) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(substation);
  };

  useEffect(() => {
    setTempSubstations(deepCopy(substations));
  }, [substations]);

  const sensorsCustomOptions = {
    selectableRows: false,
    sortOrder: {
      name: "Sensor",
      direction: "asc",
    },
  };

  useEffect(() => {
    if (
      selectedEquipmentTypes.length ||
      selectedLocations.length ||
      selectedAlarm.length ||
      selectedStatus.length ||
      selectedSensors.length ||
      searchText.length
    ) {
      let tempFilteredSubstations = substations.filter((substation) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = true,
          filter6 = true;
        if (selectedEquipmentTypes.length) {
          const equipmentSensors = [];
          filter1 = selectedEquipmentTypes.some((typeId) => {
            const selectedEquipments = filterObjectByValue(equipments, "EquipmentTypeID", typeId);
            selectedEquipments.map((equipment) => {
              return equipment.Sensors.map((eqSensor) => {
                equipmentSensors.push(eqSensor.SensorID);
              });
            });

            return substationSensorsData[substation.SubstationID].some((sensor) => {
              return equipmentSensors.includes(sensor.SensorID);
            });
          });
        }

        if (selectedAlarm.length) {
          filter2 = selectedAlarm.some((item) => {
            return (
              (item == "1" && isSensorWarning(substationSensorsData[substation.SubstationID])) ||
              (item == "2" && isSensorAlarm(substationSensorsData[substation.SubstationID]))
            );
          });
        }
        if (selectedLocations.length) {
          filter3 = selectedLocations.some((locationId) => {
            return locationId == substation.LocationID;
          });
        }
        if (selectedSensors.length) {
          filter4 = selectedSensors.some((typeId) => {
            return substationSensorsData[substation.SubstationID].some((sensor) => {
              return typeId == sensor.SensorTypeID;
            });
          });
        }
        if (selectedStatus.length) {
          filter5 = selectedStatus.some((item) => {
            return substationSensorsData[substation.SubstationID].some((sensor) => {
              return (
                (item == "Active" && sensor?.IsActive) || (item == "Inactive" && !sensor?.IsActive)
              );
            });
          });
        }
        if (searchText.length) {
          filter6 = substation.Label.toLowerCase().includes(searchText.toLowerCase());
        }

        return filter1 && filter2 && filter3 && filter4 && filter5 && filter6;
      });
      setTempSubstations(tempFilteredSubstations);
    } else {
      setTempSubstations(deepCopy(substations));
    }
  }, [
    selectedEquipmentTypes,
    selectedLocations,
    selectedAlarm,
    selectedStatus,
    selectedSensors,
    searchText,
  ]);

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value?.split(",") : value);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setStatusFilterCount(statusFilterCount + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setStatusFilterCount(statusFilterCount - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value?.split(",") : value);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const clearFilter = () => {
    setSelectedEquipmentTypes([]);
    setSelectedSensors([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedStatus([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setStatusFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
  };

  const columns = [
    {
      name: "SubstationID",
      label: "SubstationID",
      options: {
        display: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
    },
    {
      name: "SubstationName",
      label: "Substation name",
      options: {
        filter: false,
        download: true,
        searchable: true,
        sort: true,
      },
    },
    {
      name: "Monitoring",
      label: "Monitoring",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.props?.children?.props?.label?.split(" ")[0];
            var valueTwo = userListTwo.props?.children?.props?.label?.split(" ")[0];
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "Power",
      label: "Power",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.props.children.props.BatteryLevel;
            var valueTwo = userListTwo.props.children.props.BatteryLevel;
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "Connection",
      label: "Connection",
      options: {
        filter: true,
        download: true,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "MeasurementInterval",
      label: "Measurement interval",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "FirmwareVersion",
      label: "Firmware version",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "SerialNumber",
      label: "Serial number",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "MacAddress",
      label: "MAC address",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "IpAddress",
      label: "IP address",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "SubstationLocation",
      label: "Substation location",
      options: {
        filter: false,
        download: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const analogSensorColumns = [
    {
      name: "SubstationPort",
      label: "Substation port",
      options: {
        display: true,
        searchable: true,
        width: "10%",
      },
    },
    {
      name: "Sensor",
      label: "Sensor",
      options: {
        display: true,
        searchable: true,
        width: "23%",
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        display: true,
        searchable: true,
        width: "45%",
      },
    },
    {
      name: "Equipment",
      label: "Equipment",
      options: {
        display: true,
        searchable: true,
        width: "14%",
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const digitalSensorColumns = [
    {
      name: "SubstationPort",
      label: "Substation port",
      options: {
        display: true,
        searchable: true,
        width: "10%",
      },
    },
    {
      name: "Sensor",
      label: "Sensor",
      options: {
        display: true,
        searchable: true,
        width: "23%",
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        display: true,
        searchable: true,
        width: "20%",
      },
    },
    {
      name: "Delay",
      label: "Delay",
      options: {
        display: true,
        searchable: true,
        width: "25%",
      },
    },
    {
      name: "Equipment",
      label: "Equipment",
      options: {
        display: true,
        searchable: true,
        width: "14%",
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  const getEquipmentNameFromSensor = (equipments, targetSensorID) => {
    const equipmentWithSensor = equipments.find((item) =>
      item.Sensors.some((sensor) => sensor.SensorID === targetSensorID)
    );

    return equipmentWithSensor;
  };

  const customOptions = {
    selectableRows: false,
    download: false,
    downloadAbleFileName: "Substation info",
    expandableRows: true,
    expandableRowsHeader: false,
    rowsExpanded: expandedRows,
    setRowProps: (row, _, rowIndex) => {
      if (row[0] === selectedSubstationID) {
        handleSelectRowIndex(rowIndex);
        return {
          style: { "background-color": "#e0f5fd" },
          "data-rowindex": rowIndex,
        };
      } else {
        return {
          "data-rowindex": rowIndex,
        };
      }
    },
    onRowsExpand: (_currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },
    renderExpandableRow: (rowData, _rowMeta) => {
      const sensorsData = substationSensorsData[rowData[0]];
      const analogSensors = filterObjectByValue(sensorsData, "SensorTypeLabel", "Analog").sort(
        (a, b) => (a.PortSerial?.slice("-")[0] > b.PortSerial?.slice("-")[0] ? 1 : -1)
      );

      const digitalSensors = filterObjectByValue(sensorsData, "SensorTypeLabel", "Digital").sort(
        (a, b) => (a.PortSerial?.slice("-")[0] > b.PortSerial?.slice("-")[0] ? 1 : -1)
      );
      let analogSensorsIndex = 0;
      let digitalSensorsIndex = 0;

      return (
        <>
          <TableRow sx={{ backgroundColor: "#F7F8F8" }}>
            <TableCell
              colSpan={13}
              style={{ borderBottom: "none" }}
            >
              <TableComponent
                cellPaddingLeft="24px"
                boxShadow="none"
                hideToolbar={true}
                tableLook="dense"
                zebraStyle={true}
                columns={analogSensorColumns}
                customOptions={sensorsCustomOptions}
                backgroundColor="#F7F8F8"
                height="none"
                marginRight="24px"
                marginLeft="24px"
                data={["A1", "A2", "A3", "A4"].map((sensorPort, index) => {
                  if (
                    analogSensors[analogSensorsIndex] &&
                    analogSensors[analogSensorsIndex]?.PortSerial.slice("-")[0] == index + 1
                  ) {
                    const currentIndex = analogSensorsIndex;
                    analogSensorsIndex += 1;

                    const sensorType = findObjectByValue(
                      sensorTypes,
                      "value",
                      analogSensors[currentIndex].SensorTypeID
                    );

                    const equipment = getEquipmentNameFromSensor(
                      equipments,
                      analogSensors[currentIndex].SensorID
                    );

                    return [
                      sensorPort,
                      <Link
                        to={"/setup/sensors"}
                        state={{ SensorID: analogSensors[currentIndex].SensorID }}
                        className="device-link"
                      >
                        {analogSensors[currentIndex].SensorLabel}
                      </Link>,
                      <></>,
                      equipment ? (
                        <Link
                          to={"/setup/equipment"}
                          state={{ EquipmentID: equipment.EquipmentID }}
                          className="device-link"
                        >
                          {equipment.EquipmentName}
                        </Link>
                      ) : null,
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            paddingRight: "24px",
                          }}
                        >
                          <ToolTipComponent
                            title="Edit sensor"
                            placement="bottom-end"
                            Component={
                              <Box
                                sx={{
                                  width: "fit-content",
                                }}
                              >
                                <EditSensor
                                  sensor={analogSensors[currentIndex]}
                                  handleEdit={handleSensorEdit}
                                />
                              </Box>
                            }
                          />
                        </Box>

                        <SensorContextMenu
                          sensor={analogSensors[currentIndex]}
                          handleAlertSuppression={handleAlertSuppression}
                          alertSuppression
                          unsuppressAlarm
                          viewReport
                          auditTrail
                        />
                      </span>,
                    ];
                  } else {
                    return [
                      sensorPort,
                      <span>
                        <i
                          class="fa-light fa-sensor-triangle-exclamation"
                          style={{ width: "25px", height: "25px" }}
                        ></i>
                        No sensor attached to this port
                      </span>,
                      <AddSensorButton
                        handleAddSensor={handleAddSensor}
                        label="Analog"
                        data={rowData[8]}
                        index={index + 1}
                      />,
                    ];
                  }
                })}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: "#F7F8F8" }}>
            <TableCell colSpan={13}>
              <TableComponent
                cellPaddingLeft="24px"
                boxShadow="none"
                hideToolbar={true}
                tableLook="dense"
                zebraStyle={true}
                columns={digitalSensorColumns}
                customOptions={sensorsCustomOptions}
                backgroundColor="#F7F8F8"
                height="none"
                marginRight="24px"
                marginLeft="24px"
                mt="20px"
                data={["D1", "D2", "D3", "D4"].map((sensorPort, index) => {
                  if (
                    digitalSensors[digitalSensorsIndex] &&
                    digitalSensors[digitalSensorsIndex]?.PortSerial.slice("-")[0] == index + 1
                  ) {
                    const currentIndex = digitalSensorsIndex;
                    digitalSensorsIndex += 1;
                    if (
                      digitalSensors[currentIndex].SensorLimitTemplateID !==
                      "111a11b1-a111-4111-aa11-1eb1dbd1a11a"
                    ) {
                      var sensorLimitTemplate = findObjectByValue(
                        sensorLimitTemplates,
                        "value",
                        digitalSensors[currentIndex].SensorLimitTemplateID
                      );
                    }
                    const sensorType = findObjectByValue(
                      sensorTypes,
                      "value",
                      digitalSensors[currentIndex]?.SensorTypeID
                    );

                    const limitTemplate = sensorLimitTemplate || digitalSensors[currentIndex];

                    const equipment = getEquipmentNameFromSensor(
                      equipments,
                      digitalSensors[currentIndex].SensorID
                    );

                    return [
                      sensorPort,
                      <Link
                        to={"/setup/sensors"}
                        state={{ SensorID: digitalSensors[currentIndex].SensorID }}
                        className="device-link"
                      >
                        {digitalSensors[currentIndex].SensorLabel}
                      </Link>,
                      <></>,
                      getLimitValue(limitTemplate, sensorLimitTemplate, sensorType, "InputState"),
                      equipment ? (
                        <Link
                          to={"/setup/equipment"}
                          state={{ EquipmentID: equipment.EquipmentID }}
                          className="device-link"
                        >
                          {equipment.EquipmentName}
                        </Link>
                      ) : null,
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            paddingRight: "24px",
                          }}
                        >
                          <ToolTipComponent
                            title="Edit sensor"
                            placement="bottom-end"
                            Component={
                              <Box
                                sx={{
                                  width: "fit-content",
                                }}
                              >
                                <EditSensor
                                  sensor={digitalSensors[currentIndex]}
                                  handleEdit={handleSensorEdit}
                                />
                              </Box>
                            }
                          />
                        </Box>
                        <SensorContextMenu
                          sensor={digitalSensors[currentIndex]}
                          handleAlertSuppression={handleAlertSuppression}
                          alertSuppression
                          unsuppressAlarm
                          viewReport
                          auditTrail
                        />
                      </span>,
                    ];
                  } else {
                    return [
                      sensorPort,
                      <span>
                        <i
                          class="fa-light fa-sensor-triangle-exclamation"
                          style={{ width: "25px", height: "25px" }}
                        ></i>
                        No sensor attached to this port
                      </span>,
                      <AddSensorButton
                        handleAddSensor={handleAddSensor}
                        label="Digital"
                        data={rowData[8]}
                        index={index + 1}
                      />,
                    ];
                  }
                })}
              />
            </TableCell>
          </TableRow>
        </>
      );
    },
    sortOrder: {
      name: "SubstationName",
      direction: "asc",
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      delete columns[0];
      delete columns[1];
      delete columns[3];
      delete columns[12];
      data.map((row) => {
        delete row.data[0];
        delete row.data[1];
        delete row.data[3];
        delete row.data[12];
        let value = `${row.data[4]?.props?.children?.props.PowerType}|${row.data[4]?.props?.children?.props.BatteryLevel}|${row.data[4]?.props?.children?.props.PowerState}`;
        row.data[4] = value;
        value = `${row.data[5]?.props?.children?.props.ConnectionType}|${row.data[4]?.props?.children?.props.ConnectionState}`;
        row.data[5] = value;
        value = row.data[11].props.Component.props.children.props.children.props.children
          ? row.data[11].props.Component.props.children.props.children.props.children
          : typeof row.data[11]?.props?.title === "object"
          ? ""
          : row.data[11]?.props?.title;
        row.data[11] = value;
      });

      return buildHead(columns) + buildBody(data);
    },
  };

  const handleNavigate = (id) => {
    let data = {};
    if (id.SubstationID) {
      data["SubstationID"] = id.SubstationID;
    }
    if (permissionAllowed.length > 0)
      navigate("/setup/maps", {
        state: {
          ID: id?.LocationID,
          device: data,
        },
      });
  };

  const ConnectionTypes = ["Cellular", "Wifi", "Ethernet"];
  const ConnectionStates = ["NoSignal", "WeekSignal", "FairSignal", "StrongSignal"];
  const batteryPercentages = ["10", "50", "99"];

  const downloadableMappedData = () => {
    return tempSubstations.map((row, index) => [
      row.Label,
      `${substationSensorsData[row.SubstationID]?.length} ${
        substationSensorsData[row.SubstationID]?.length > 1 ? "sensors" : "sensor"
      }`,
      `${batteryPercentages[(index + 1) % 3]} %`,
      ConnectionTypes[(index + 1) % 2],
      row.MeasurementInterval,
      row.FirmWare,
      row.SerialNumber,
      row.MAC,
      row.IP,
      locationsHierarchy[row?.LocationID],
    ]);
  };

  const substationMappedData = () => {
    return tempSubstations?.map((row, index) => {
      const substationData = substationSensorsData[row.SubstationID];
      const status = getColorCodingStatus(substationData, row.IsActive);
      const size = tableLook === "dense" ? "10px" : "13px";
      const mappedSubstation = findObjectByValue(
        mappedSubstations,
        "SubstationID",
        row?.SubstationID
      );
      return [
        row.SubstationID,
        <span style={{ position: "relative" }}>
          {status !== "none" && (
            <StatusDot
              status={status}
              size={size}
              tableLook={tableLook}
            />
          )}
          <i
            style={{
              fontSize: tableLook == "dense" ? "15px" : "30px",
              background: "#ECEDEE",
              color: " #9CA1A6",
              padding: "6px",
              borderRadius: "5px",
              fontWeight: "300",
            }}
            className="fa-solid fa-router tableRow"
          ></i>
        </span>,
        row.Label,
        <span>
          {substationSensorsData[row.SubstationID]?.length > 0 ? 
             ` ${substationSensorsData[row.SubstationID]?.length} ${substationSensorsData[row.SubstationID]?.length > 1 ? 'sensors' : 'sensor'}`
           : (
            "No sensors"
          )}
        </span>,
        <span style={{ display: "flex", alignItems: "center" }}>
          <PowerIcon
            PowerType={"Battery"}
            BatteryLevel={batteryPercentages[(index + 1) % 3]}
            PowerState={null}
          />
        </span>,
        <span style={{ display: "flex", alignItems: "center" }}>
          <ConnectionIcon
            ConnectionType={ConnectionTypes[(index + 1) % 2]}
            ConnectionState={ConnectionStates[(index + 2) % 4]}
            time={row.LastConnectionTime}
          />
        </span>,
        row.MeasurementInterval,
        row.FirmWare,
        row.SerialNumber,
        row.MAC,
        row.IP,
        <ToolTipComponent
          placement="bottom-start"
          title={
            mappedSubstation
              ? locationsHierarchy[row?.LocationID]?.split(",").join("\n")
              : "Device not on map"
          }
          Component={
            <Box sx={{ width: "fit-content" }}>
              <div
                onClick={() => {
                  mappedSubstation && handleNavigate(row);
                }}
              >
                <Typography
                  sx={{
                    color:
                      mappedSubstation && permissionAllowed.length > 0
                        ? "#00AEEF"
                        : "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "left",
                    cursor: mappedSubstation && "pointer",
                  }}
                >
                  {locationsHierarchy[row?.LocationID]}
                </Typography>
              </div>
            </Box>
          }
        />,
        <span style={{ display: "flex", alignItems: "center" }}>
          {allowedEdit && (
            <Box
              sx={{
                paddingRight: "24px",
              }}
            >
              <EditSubstations
                substation={row}
                handleEdit={handleEdit}
              />
            </Box>
          )}
          <ToolTipComponent
            title="More options"
            value="More options"
            placement="bottom-end"
            Component={
              <Box
                sx={{
                  width: "fit-content",
                }}
              >
                <IconButtonComponent
                  onClick={(e) => handleSubstationContextMenu(e, row)}
                  Component={
                    <i
                      class="fa-solid fa-ellipsis-vertical"
                      style={{
                        color: "#9CA1A6",
                        fontSize: "20px",
                        lineHeight: "25px",
                        cursor: "pointer",
                      }}
                    ></i>
                  }
                />
              </Box>
            }
          />
        </span>,
      ];
    });
  };
  const getReportColumns = () => {
    let reportCols = columns.map((col) => col.label);
    reportCols.splice(0, 1);
    reportCols.splice(0, 1);
    reportCols.splice(-1, 1);

    return reportCols;
  };

  const getReportData = () => {
    return downloadableMappedData();
  };

  return (
    <>
      <TableComponent
        zebraStyle={true}
        tableLook={tableLook}
        customOptions={customOptions}
        downloadable={true}
        getReportColumns={getReportColumns}
        getReportData={getReportData}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "150px",
                marginRight: "13.2px",
                letterSpacing: "0px",
              }}
            >
              <b style={{ fontWeight: "500" }}>
                {tempSubstations.length}{" "}
                {tempSubstations.length < 1 ? " substation" : " substations"}
              </b>
            </p>
            <Box
              sx={{
                mr: 1,
                ml: 0.5,
              }}
            >
              <SubstationFilter
                datefilter={true}
                locations={locations}
                equipments={equipmentTypes}
                sensorTypes={sensorTypes}
                handleSelectEquipmentType={handleSelectEquipmentType}
                handleSelectSensorType={handleSelectSensorType}
                handleSelectLocation={handleSelectLocation}
                handleAlarmSelect={handleAlarmSelect}
                selectedEquipmentTypes={selectedEquipmentTypes}
                selectedSensors={selectedSensors}
                selectedAlarm={selectedAlarm}
                selectedLocations={selectedLocations}
                equipmentFilterCount={equipmentFilterCount}
                sensorTypeFilterCount={sensorTypeFilterCount}
                locationFilterCount={locationFilterCount}
                alarmFilterCount={alarmFilterCount}
                statusFilterCount={statusFilterCount}
                clearFilter={clearFilter}
                handleStatusList={handleStatusList}
                selectedStatus={selectedStatus}
              />
            </Box>
            <SearchComponent
              width="320px"
              padding={0}
              placeholder="Search substations...."
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Box>
        }
        columns={columns}
        customToolbar={
          allowedEdit && (
            <TableHeaderAddButton
              onClick={() => toggleDrawer(true)}
              label="Substation"
              width="141px"
            />
          )
        }
        searchText={searchText}
        data={substationMappedData()}
      />
      <SubstationContextMenu
        substation={selectedRow}
        sensors={substationSensorsData?.[selectedRow.SubstationID]}
        isMenuOpen={isMenuOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleSubstationSupressAlarms={handleSubstationSupressAlarms}
        selectedRow={selectedRow}
        sensorIDs={sensorIDs}
      />
    </>
  );
};

const StatusDot = ({ status, tableLook, size }) => {
  return (
    <Box
      className={`${tableLook === "dense" ? "equipmentDot" : "equipmentDotRelaxed"}`}
      sx={{
        background: STATUS_COLOR_CODE_MAP[status],
        borderRadius: "50%",
        position: "absolute",
        height: size,
        width: size,
      }}
    />
  );
};

const SubstationContextMenu = ({
  sensors,
  substation,
  anchorEl,
  setAnchorEl,
  isMenuOpen,
  handleSubstationSupressAlarms,
  sensorIDs,
  selectedRow,
}) => {
  const menuId = "substation-context-menu";

  const [unSuppressSensors] = useUnSuppressSensorsMutation();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSuppressSensors = async () => {
    handleSubstationSupressAlarms({ Sensors: sensorIDs, SelectedRow: selectedRow });
  };
  const handleUnSuppressSensors = async () => {
    await unSuppressSensors({ SensorIDs: sensors.map((sensor) => sensor.SensorID) });
  };

  const hasSuppressedSensors = sensors?.some((sensor) => !sensor?.LiveData?.Suppressed);
  const hasUnsuppressedSensors = sensors?.some((sensor) => sensor?.LiveData?.Suppressed);

  return (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: "235px",

          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 0,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 10,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        {!!sensors?.length &&
          (hasSuppressedSensors && hasUnsuppressedSensors ? (
            <>
              <ContextMenuItem
                handleChange={() => {
                  handleUnSuppressSensors();
                  handleMenuClose();
                }}
                className="fa-light fa-bell-on"
                Label="Unsuppress Alarms"
                margin="0px 7px 0px 0px"
              />
              <ToolTipComponent
                title="Suppressing alarms for this substation will suppress alarms for all associated sensors"
                Component={
                  <div>
                    <ContextMenuItem
                      handleChange={() => {
                        handleSuppressSensors();
                        handleMenuClose();
                      }}
                      className="fa-light fa-bell-slash"
                      Label="Suppress Alarms"
                    />
                  </div>
                }
              />
            </>
          ) : hasSuppressedSensors ? (
            <ToolTipComponent
              title="Suppressing alarms for this substation will suppress alarms for all associated sensors"
              Component={
                <div>
                  <ContextMenuItem
                    handleChange={() => {
                      handleSuppressSensors();
                      handleMenuClose();
                    }}
                    className="fa-light fa-bell-slash"
                    Label="Suppress Alarms"
                  />
                </div>
              }
            />
          ) : (
            <ContextMenuItem
              handleChange={() => {
                handleUnSuppressSensors();
                handleMenuClose();
              }}
              className="fa-light fa-bell-on"
              Label="Unsuppress Alarms"
              margin="0px 7px 0px 0px"
            />
          ))}
        {process.env.SHOW_AUDIT_TRAIL === "true" && (
          <MenuItemComponent
            style={{ display: "flex", alignItems: "baseline" }}
            to={`/audittrail/Substation-${substation.Label}`}
            ID={{ Type: "Substation", ID: substation.SubstationID }}
            handleChange={() => console.log("Activity Log")}
            Children={
              <>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <i
                    class="fa-light fa-list-timeline"
                    style={{ color: "#9CA1A6" }}
                  ></i>{" "}
                </div>
                Audit Trail<div>&nbsp;&nbsp;</div>
              </>
            }
          />
        )}
      </Box>
    </CustomMenu>
  );
};

export default SubtationsTable;
