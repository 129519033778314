import { apiSlice } from "../API";

export const sensorLimitTemplateSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorLimitTemplate: builder.query({
      query: () => "/sensorLimitTemplate",
      providesTags: ["SensorLimitTemplate"],
    }),
    getSensorLimitTemplate: builder.query({
      query: (id) => `/sensorLimitTemplate/${id}`,
    }),
    addSensorLimitTemplate: builder.mutation({
      query: (data) => ({
        url: "/sensorLimitTemplate",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SensorLimitTemplate"],
    }),
    editSensorLimitTemplate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/sensorLimitTemplate/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SensorLimitTemplate"],
    }),
    deleteSensorLimitTemplate: builder.mutation({
      query: (id) => ({
        url: `/sensorLimitTemplate/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SensorLimitTemplate"],
    }),
  }),
});

export const {
  useFindSensorLimitTemplateQuery,
  useGetSensorLimitTemplateQuery,
  useAddSensorLimitTemplateMutation,
  useEditSensorLimitTemplateMutation,
  useDeleteSensorLimitTemplateMutation,
} = sensorLimitTemplateSlice;
