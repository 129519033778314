import { apiSlice } from "../API";

export const auditLogSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findLogs: builder.query({
      query: ({ CustomerID, date }) => `/deviceJSON/data/${CustomerID}/${date}`,
    }),
    findEquipmentLogs: builder.query({
      query: ({ CustomerID, EquipmentID }) =>
        `/deviceJSON/data/${CustomerID}/Equipment/${EquipmentID}`,
    }),
  }),
});

export const { useFindLogsQuery, useFindEquipmentLogsQuery } = auditLogSlice;
