import React from "react";

//COMPONENTS IMPORT
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import ToolTipComponent from "../BaseComponents/Tooltip";
import IconButtonComponent from "../BaseComponents/IconButton";

const EditUserGroup = (props) => {
  let { userGroup, handleEdit, paddingRight } = props;
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "10px",
          },
        },
      },
    },
  });

  return (
    <Box style={{ display: "flex", justifyContent: "end" }}>
      <ToolTipComponent
        placement="bottom-end"
        title="Edit user group"
        marginRight="18px"
        Component={
          <Box
            sx={{
              width: "fit-content",
              paddingRight: paddingRight ? paddingRight : "none",
              paddingLeft: !paddingRight ? "10px" : "none",
            }}
          >
            <IconButtonComponent
              onClick={() => handleEdit(userGroup)}
              Component={<i class="fa-light fa-pen-to-square "></i>}
            />
          </Box>
        }
      />
    </Box>
  );
};

export default EditUserGroup;
