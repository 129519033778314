import { ListItemText, MenuItem } from "@mui/material";
import MultipleSelectCheckmarks from "../MultiSelect";
import Checkbox from "@mui/material/Checkbox";

export default function LocationsFilterField(props) {
  const { handleSelectLocation, selectedLocations, locations } = props;
  return (
    <div style={{ marginTop: "24px" }}>
      <p
        style={{
          textAlign: "left",
          font: "normal normal normal 12px/16px Roboto",
          color: "#9CA1A6",
          marginBottom: "4px",
        }}
      >
        Location(s)
      </p>

      <MultipleSelectCheckmarks
        onChange={handleSelectLocation}
        placeholder="Select location(s)"
        personName={selectedLocations}
        data={locations}
      >
        {locations.map((location) => (
          <MenuItem
            key={location.value}
            value={location.value}
            className="menuItem"
            sx={{
              font: "normal normal medium 14px/20px Roboto",
              color: "#222934",
              "&:hover": {
                background: "#E3E9EC 0% 0% no-repeat padding-box",
                color: "#17455E",
              },
              "&$selected": {
                // <-- mixing the two classes
                backgroundColor: "transparent",
              },
            }}
          >
            <Checkbox
              className="checkBox"
              size="small"
              sx={{
                color: "#9CA1A6",
                width: "24px",
                height: "24px",
                "&:hover": {
                  color: "#17455E",
                },
                "&:focus-visible": {
                  outline: "2px auto #7fd3f2",
                },
                "&.Mui-checked": {
                  color: "#0D2E42",
                },
              }}
              checked={selectedLocations.indexOf(location.value) > -1}
            />
            <ListItemText primary={location.label} />
          </MenuItem>
        ))}
      </MultipleSelectCheckmarks>
    </div>
  );
}
