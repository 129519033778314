import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Toast from "@Components/BaseComponents/Toast";
import { FormControl, Grid } from "@mui/material";
import { lightGrey } from "../../Styles/theme/colors";
import Label from "@Components/BaseComponents/Label";
import {
  useAddUserGroupMutation,
  useDeleteUserGroupMutation,
  useEditUserGroupMutation,
} from "../../Store/Slices/userGroupsSlice";
import { useFindSensorGroupUserGroupQuery } from "../../Store/Slices/sensorGroupUserGroupSlice";
import { filterObjectByValue, findObjectByValue } from "../../Utils/Tools";
import CustomAvatar from "../Avatar";
import SearchComponent from "../BaseComponents/Search";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import TextFieldComponent from "../BaseComponents/TextField";

export default function RightDrawer(props) {
  const {
    users,
    toggleDrawer,
    drawer,
    user,
    edit,
    setUserGroup,
    userGroup,
    userGroupUsers,
    auth,
    setEdit,
  } = props;

  const isAdmin = auth.isAdmin;

  const [customDrawer, setCustomDrawer] = useState(false);
  const [userGroupUpdated, setUserGroupUpdated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [groupName, setGroupName] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [groupValue, setGroupValue] = useState(userGroup);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const [addUserGroup, { isSuccess: addUserGroupSuccess, error: addUserGroupError }] =
    useAddUserGroupMutation();

  const { data: SGUGData } = useFindSensorGroupUserGroupQuery();

  const [deleteUserGroup, { isSuccess: deleteUserGroupsSuccess }] = useDeleteUserGroupMutation();

  const [editUserGroup, { isSuccess: editUserGroupSuccess, error: editUserGroupError }] =
    useEditUserGroupMutation();

  const customToggleDrawer = (open) => {
    setCustomDrawer(open);
  };

  const handleDelete = async (UG, reason) => {
    const data = {
      reason: reason,
    };
    const id = UG.value;
    try {
      await deleteUserGroup({ id, data });
    } catch (err) {
      console.log({ err });
    } finally {
      setUserGroup("");
      setUsersData([]);
      toggleDrawer(false);
      setFilteredUsers(filteredUsers);
      customToggleDrawer(false);
      setEdit(false);
    }
  };

  const applySearchFilter = (userGroupUsers) => {
    if (searchText) {
      const filteredUserIDs = users
        .filter(
          (obj) =>
            obj.IsActive == true &&
            (obj.UserName.includes(searchText) || obj.SNSEMail.includes(searchText))
        )
        .map((u) => u.UserID);
      return userGroupUsers.filter((ugu) => filteredUserIDs.includes(ugu.UserID));
    }
    return userGroupUsers;
  };

  const handleUserGroupCreate = async (reason) => {
    let data = {};
    if (isAdmin) {
      data = {
        CustomerID: auth.SelectedCustomer,
        GroupName: groupName.toLowerCase(),
        usersData: usersData,
        reason: reason,
      };
    } else {
      data = {
        CustomerID: auth.CustomerID,
        GroupName: groupName,
        usersData: usersData,
        reason: reason,
      };
    }
    try {
      await addUserGroup(data);
    } catch (error) {
      console.error({ error });
    } finally {
      setUserGroup("");
      setUsersData([]);
      toggleDrawer(false);
      setFilteredUsers(filteredUsers);
    }
  };

  const hanldeUserGroupClick = (reason) => {
    edit ? handleUGEdit(user, reason) : handleUserGroupCreate(reason);
  };

  const handleUGEdit = async (user, reason) => {
    const userGroup = {
      usersData: usersData,
      GroupName: groupName.toLowerCase(),
      reason: reason,
    };
    const id = groupValue.value;
    try {
      await editUserGroup({ id, userGroup });
    } catch (err) {
      console.log({ err });
    } finally {
      setUserGroup("");
      setUsersData([]);
      toggleDrawer(false);
      setFilteredUsers(filteredUsers);
      setEdit(false);
    }
  };

  useEffect(() => {
    setGroupValue(userGroup);

    let result = usersData.map((a) => a.UserID);
    const tempUsers = users;
    setFilteredUsers(tempUsers.filter((user) => result.includes(user.UserID) == false));
  }, [userGroup, usersData]);

  useEffect(() => {
    if (!drawer) {
      setFilteredUsers(users);
    }
  }, [drawer]);

  const clearUserGroup = (open) => {
    setEdit(open);
    setUserGroup({});
  };

  const hasAssociatedSensorGroups = !!SGUGData?.success?.data?.find(
    (sensorGroup) => sensorGroup.UserGroupID === userGroup.value
  );

  useEffect(() => {
    setUsersData(filterObjectByValue(userGroupUsers, "UserGroupID", groupValue.value));
    setGroupName(groupValue.label);
  }, [userGroupUsers, groupValue]);

  return (
    <>
      {addUserGroupSuccess && (
        <Toast
          message="User group successfully created"
          severity="success"
        />
      )}
      {editUserGroupSuccess && (
        <Toast
          message="User group successfully updated"
          severity="success"
        />
      )}
      {deleteUserGroupsSuccess && (
        <Toast
          message="User group successfully deleted"
          severity="success"
        />
      )}

      {addUserGroupError && (
        <Toast
          message="User group name is not unique"
          severity="error"
        />
      )}
      {editUserGroupError && (
        <Toast
          message="User group name is not unique"
          severity="error"
        />
      )}

      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        deactivateToggleDrawer={customToggleDrawer}
        title={edit ? "Edit user group" : "New user group"}
        submitButtonTitle={edit ? "Update user group" : "Add user group"}
        handleSubmit={hanldeUserGroupClick}
        archiveButton={{
          disabled: hasAssociatedSensorGroups,
          tooltipCopy: "This User Group cannot be deleted because it has associated Sensor Groups",
        }}
        displayFooter={true}
        edit={edit}
        closeToast={clearUserGroup}
        buttonValidation={!userGroupUpdated}
        loadUser={() => setGroupName("")}
        setUserUpdated={setUserGroupUpdated}
        deactivateButtonTitle="Delete"
        deleteDrawer={customDrawer}
        handleDelete={handleDelete}
        item={groupValue}
        deleteTitle="Delete user group"
        heading={`Are you sure you want to delete ${groupValue.label} group?`}
        headingNote={`Note: This
        will not delete this group’s users from the system.\n\nDeleting this User Group will remove it from all Alert Programs.`}
      >
        <Box
          width={562}
          sx={{
            height: "84vh",
            p: "4.22%",
            pt: 0,
          }}
        >
          <FormControl
            margin="normal"
            fullWidth
          >
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              />

              <TextFieldComponent
                fullWidth={true}
                padding={false}
                placeholder="Enter User Group Name"
                onChange={(e) => [setGroupName(e.target.value), setUserGroupUpdated(true)]}
                value={groupName || ""}
                name="GroupName"
                variant="outlined"
                Label="User group name"
              />
              <Grid
                item
                xs={12}
                sx={{ mt: 3, marginBottom: "6px" }}
              >
                <Label
                  sx={{
                    font: "normal normal normal 20px/24px Roboto",
                    color: "black",
                  }}
                >
                  Manage users
                </Label>
                <SearchComponent
                  width="100%"
                  padding={"10px 0px 0px 0px"}
                  placeholder="Search"
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Grid>
              {applySearchFilter(usersData).length == 0 &&
              applySearchFilter(filteredUsers).length == 0 ? (
                <Grid sx={{ marginTop: "10.5em", marginX: "auto" }}>
                  <div
                    style={{
                      "justify-content": "center",
                      display: "flex",
                      "align-items": "center",
                    }}
                  >
                    <div
                      style={{
                        background: "#E0E1E3",
                        height: "40px",
                        "justify-content": "center",
                        width: "40px",
                        display: "flex",
                        "align-items": "center",
                        "border-radius": "4px",
                        paddingLeft: "4px",
                      }}
                    >
                      <i class="fal fa-search fa-lg"></i>
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <div
                        style={{
                          font: "normal normal normal 16px/22px Roboto",
                          color: "#222934",
                        }}
                      >
                        No users found by that name
                      </div>
                      <div
                        style={{
                          font: "normal normal normal 12px/16px Roboto",
                          color: "#9CA1A6",
                        }}
                      >
                        Check your spelling or try another name
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : (
                <div>
                  {applySearchFilter(usersData).length > 0 && (
                    <Grid sx={{ marginTop: "0.5em" }}>
                      <Label
                        sx={{
                          font: "normal normal normal 16px/24px Roboto",
                          color: "black",
                          paddingLeft: "3%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Group members{" "}
                      </Label>
                      <Grid>
                        {applySearchFilter(usersData).map((ugu) => {
                          if (ugu != undefined) {
                            const user = findObjectByValue(users, "UserID", ugu.UserID);
                            let FirstName_LastName = `${user?.FirstName} ${user?.LastName}`;

                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "520px",
                                  justifyContent: "space-between",
                                  padding: "0% 0.75% 0% 1.51%",
                                  borderRadius: "4px",
                                  margin: "10px 10px 8px 0%",
                                }}
                                id={`groupMember_${ugu.UserID}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CustomAvatar
                                    sx={{
                                      bgcolor: "#7151A1",
                                      width: "56px",
                                      height: "56px",
                                      fontSize: "14px",
                                    }}
                                    name={FirstName_LastName}
                                    src={
                                      user?.ProfileImage
                                        ? `${process.env.REACT_APP_URL}/asset/download/${user?.ProfileImage}`
                                        : null
                                    }
                                  />
                                  <div style={{ marginLeft: 16 }}>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        fontWeight: 500,
                                        fontSize: "1.125rem",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      {user?.UserName}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        color: lightGrey["900"],
                                        fontSize: "14px",
                                      }}
                                    >
                                      {user?.SNSEMail}
                                    </Typography>
                                  </div>
                                </div>
                                <Button
                                  onClick={() => [
                                    setUsersData(usersData.filter((u) => u.UserID != ugu.UserID)),
                                    setUserGroupUpdated(true),
                                  ]}
                                  style={{
                                    minWidth: "6.3%",
                                    minHeight: "32px",
                                    padding: "0px",
                                    marginRight: ".79%",
                                    fontSize: "20px",
                                  }}
                                >
                                  <i
                                    class="fa-light fa-trash-can"
                                    style={{ color: "#9CA1A6" }}
                                  ></i>
                                </Button>
                              </div>
                            );
                          }
                        })}
                      </Grid>
                    </Grid>
                  )}
                  <Grid>
                    {" "}
                    {
                      <Label
                        sx={{
                          font: "normal normal normal 16px/24px Roboto",
                          color: "black",
                          paddingLeft: "8px",
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "14px",
                        }}
                      >
                        Available users{" "}
                      </Label>
                    }
                    <Grid sx={{ overflow: "auto", marginTop: "10px" }}>
                      {filteredUsers.length > 0 ? (
                        applySearchFilter(filteredUsers).map((user) => {
                          let FirstName_LastName = `${user.FirstName} ${user.LastName}`;

                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "520px",
                                justifyContent: "space-between",
                                padding: "0% 0.75% 0% 1.51%",
                                borderRadius: "4px",
                                margin: "10px 10px 8px 0%",
                              }}
                              id={`groupMember_${user.UserID}`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomAvatar
                                  sx={{
                                    bgcolor: "#7151A1",
                                    width: "56px",
                                    height: "56px",
                                    fontSize: "14px",
                                  }}
                                  name={FirstName_LastName}
                                  src={
                                    user.ProfileImage
                                      ? `${process.env.REACT_APP_URL}/asset/download/${user.ProfileImage}`
                                      : null
                                  }
                                />
                                <div style={{ marginLeft: 16 }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: "1.125rem",
                                      marginBottom: "-10px",
                                    }}
                                  >
                                    {user.UserName}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: lightGrey["900"],
                                      fontSize: "14px",
                                    }}
                                  >
                                    {user.SNSEMail}
                                  </Typography>
                                </div>
                              </div>
                              <Button
                                onClick={() => [
                                  setUsersData([
                                    ...usersData,
                                    {
                                      UserGroupID: userGroup.value,
                                      UserID: user.UserID,
                                    },
                                  ]),
                                  setUserGroupUpdated(true),
                                ]}
                                style={{
                                  minWidth: "6.3%",
                                  minHeight: "32px",
                                  padding: "0px",
                                  marginRight: ".79%",
                                  fontSize: "20px",
                                }}
                              >
                                <i
                                  class="fa-light fa-user-plus"
                                  style={{ color: "#9CA1A6" }}
                                ></i>
                              </Button>
                            </div>
                          );
                        })
                      ) : (
                        <Typography
                          variant="caption"
                          sx={{ color: lightGrey["900"], paddingLeft: "8px" }}
                        >
                          No Users Found
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
}
