import { apiSlice } from "../API";

export const archiveSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArchivedItems: builder.query({
      query: (params) => `/archive?entityTypes=${params.entityTypes}`,
      providesTags: ["Archived"],
    }),

    addArchiveItems: builder.mutation({
      query: (data) => ({
        url: "/archive",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Archived", "User", "Sensor", "Substation", "Location", "Equipment"],
    }),
    removeArchiveItems: builder.mutation({
      query: (data) => ({
        url: "/archive/remove",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Archived", "User", "Sensor", "Substation", "Location", "Equipment"],
    }),
  }),
});

export const {
  useAddArchiveItemsMutation,
  useRemoveArchiveItemsMutation,
  useGetArchivedItemsQuery,
} = archiveSlice;
