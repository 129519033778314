import React, { useEffect, useState } from "react";

//COMPONENTS IMPORT
import Loader from "@Components/LoadingSpinner";

import { useFindLogsQuery } from "../../../Store/Slices/auditLogSlice";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import { useSelector } from "react-redux";
import { useFindUsersQuery } from "../../../Store/Slices/usersSlice";
import { filterObjectByValue, findObjectByValue } from "../../../Utils/Tools";
import { Box } from "@mui/material";
import AuditTrailTable from "../../../Components/Tables/AuditTrailTable";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { useFindEquipmentSensorTypesQuery } from "../../../Store/Slices/equipmentSlice";
import { useFindSensorsWithOutStatusQuery } from "../../../Store/Slices/sensorSlice";
import { useFindActiveSubstationsQuery } from "../../../Store/Slices/substationSlice";

const AuditTrailSetup = () => {
  const location = useLocation();
  const { Name } = useParams();

  const stateData = location.state;
  const auth = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(moment().utc().subtract(24, "hours").valueOf());
  const [equipments, setEquipments] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [timeStampSelected, setTimeStampSelected] = useState("1");
  const [startDateDisplay, setStartDateDisplay] = useState(
    moment().utc().subtract(24, "hours").valueOf()
  );
  const [entityType, setEntityType] = useState({});
  const [endDateDisplay, setEndDateDisplay] = useState(moment().utc().valueOf());
  const [dateFieldsEnabled, setDateFieldsEnabled] = useState(false);
  const [endDate, setEndDate] = useState(moment().utc().valueOf());
  const {
    data: auditLogs,
    isFetching: auditLogsLoading,
    isSuccess: auditLogsSuccess,
    isError: auditLogsIsError,
    error: auditLogsError,
    refetch: auditLogsRefetch,
  } = useFindLogsQuery({
    CustomerID: auth.SelectedCustomer,
    date: `${startDate}&${endDate}`,
  });

  const {
    refetch,
    data: equipmentData,
    isFetching: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
    refetch: sensorsDataRefetch,
  } = useFindSensorsWithOutStatusQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
    refetch: substationsDataRefetch,
  } = useFindActiveSubstationsQuery();

  const {
    data: usersData,
    isFetching: usersLoading,
    isSuccess: usersSuccess,
    isError: userIsError,
    error: userError,
  } = useFindUsersQuery();

  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);

  const loadAuditLogs = () => {
    const temp = [];
    if (auditLogsSuccess && usersSuccess && users.length > 0) {
      let filteredData = auditLogs?.success.data
        .map((logs) => {
          let user = {};
          if (logs.UserID) {
            user = findObjectByValue(users, "UserID", logs.UserID);
          } else {
            user.UserName = "System";
            user.FirstName = "System";
            user.LastName = "";
            user.SNSEMail = "system@system.com";
          }
          if (logs.UpdatedDifferences) {
            return Object.entries(logs?.UpdatedDifferences).map((difference) => {
              let values = difference[1];
              if (difference[0] === "IsActive") {
                difference[0] = "IsArchive";
                values = [...values].reverse();
              }

              return {
                UserName: logs.UserName,
                FirstName: user?.FirstName,
                LastName: user?.LastName,
                Email: user?.SNSEMail,
                Field: difference[0],
                OldValue: values[0],
                NewValue: values[1],
                reason: logs.reason,
                Level: logs.level,
                Time: logs.time,
                Category: logs.Category,
                Type: logs.Type,
                Subtype: logs.Subtype?.replace("Activated", "Restored")
                  .replace("Deactivated", "Archived")
                  .replace("activated", "restored")
                  .replace("deactivated", "archived"),
                IP: logs.IP,
                Name: logs.Name,
                ID: logs.ID,
              };
            });
          } else {
            return {
              UserName: logs.UserName,
              FirstName: user?.FirstName,
              LastName: user?.LastName,
              Email: user?.SNSEMail,
              Field: "",
              OldValue: "",
              NewValue: "",
              reason: logs.reason,
              Level: logs.level,
              Time: logs.time,
              Category: logs.Category,
              Type: logs.Type,
              Subtype: logs.Subtype,
              IP: logs.IP,
              Name: logs.Name,
              ID: logs.ID,
            };
          }
        })
        .flat();

      if (stateData?.ID?.Type && stateData?.ID?.ID) {
        setEntityType({ ID: stateData.ID.ID, Type: stateData?.ID?.Type });
      }
      console.log("audit logs: ", filteredData);
      setLogs(filteredData);
    }
    if (auditLogsIsError) console.log(auditLogsError);
  };

  const loadEquipment = () => {
    if (equipmentSuccess) {
      const tempEquipments = equipmentData.map((val) => {
        return {
          value: val.EquipmentID,
          label: val.EquipmentName,
          EquipmentID: val.EquipmentID,
          EquipmentName: val.EquipmentName,
          EquipmentTypeName: val.EquipmentType?.EquipmentTypeName,
          EquipmentTypeID: val.EquipmentType?.EquipmentTypeID,
          LocationName: val.Location?.LocationName,
          LocationID: val.LocationID,
          CustomerID: val.Location?.Customer.CustomerID,
          CustomerName: val.Location?.Customer.CustomerName,
          updatedAt: val.updatedAt,
          equipmentSensorTypes: val.equipmentSensorTypes,
        };
      });
      setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
    }
  };

  const loadSubstations = () => {
    if (substationsSuccess) {
      const tempSubstations = substationsData.map((substation) => {
        return {
          ...substation,
          value: substation.SubstationID,
          label: substation.Label,
          SerialNumber: substation.SerialNumber,
          MeasurementInterval: substation.MeasurementInterval,
        };
      });
      if (auth.SelectedCustomer == "") {
        setSubstations(tempSubstations);
      } else {
        setSubstations(filterObjectByValue(tempSubstations, "CustomerID", auth.SelectedCustomer));
      }
    }
    if (isSubstationError) console.log(substationError);
  };

  const loadSensors = () => {
    if (sensorsSuccess) {
      const tempSensors = [];
      const length = sensorsData.length;
      const data = sensorsData;
      for (let i = 0; i != length; i++) {
        tempSensors.push({
          ...data[i],
        });
      }
      if (auth.SelectedCustomer == "") {
        setSensors(tempSensors);
      } else {
        setSensors(filterObjectByValue(tempSensors, "CustomerID", auth.SelectedCustomer));
      }
    }

    if (sensorIsError) console.log(sensorError);
  };

  useEffect(() => {
    if (auditLogsSuccess && usersSuccess && users.length) loadAuditLogs();
  }, [auditLogs, usersData, users, Name]);

  useEffect(() => {
    auditLogsRefetch();
  }, []);

  useEffect(() => {
    if (equipmentSuccess) {
      console.log("The equipment data is here ", equipmentData);
      loadEquipment();
    }
  }, [equipmentData]);

  useEffect(() => {
    if (sensorsSuccess) {
      loadSensors();
    }
  }, [sensorsData]);

  useEffect(() => {
    if (substationsSuccess) {
      console.log("The substations data is here ", substationsData);
      loadSubstations();
    }
  }, [substationsData]);

  const updateDates = (hours) => {
    var eDate = new Date();
    setEndDate(eDate.valueOf());
    setEndDateDisplay(eDate.valueOf());
    var sDate = new Date(eDate.setHours(eDate.getHours() - hours));
    setStartDate(sDate.valueOf());
    setStartDateDisplay(eDate.valueOf());
  };

  const updateDurations = {
    1: 24,
    2: 24 * 7,
    3: 24 * 30,
    4: 24 * 3 * 30,
    5: 24 * 365,
  };

  const loadUsers = () => {
    if (usersSuccess) setUsers(usersData);
    if (userIsError) console.log(userError);
  };

  useEffect(() => {
    loadUsers();
  }, [usersData]);

  useEffect(() => {
    if (timeStampSelected in updateDurations && !auditLogsLoading) {
      updateDates(updateDurations[timeStampSelected]);
      setDateFieldsEnabled(false);
    }
  }, [timeStampSelected]);

  const CustomRangeCalled = () => {
    setStartDate(startDateDisplay);
    setEndDate(endDateDisplay);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {auditLogsLoading || usersLoading || equipmentLoading ? (
        <SpinnerContainer>
          <Loader />
        </SpinnerContainer>
      ) : (
        <AuditTrailTable
          logs={logs}
          auth={auth}
          users={users}
          dateFieldsEnabled={dateFieldsEnabled}
          setDateFieldsEnabled={setDateFieldsEnabled}
          startDate={startDate}
          endDate={endDate}
          equipments={equipments}
          sensors={sensors}
          substations={substations}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          timeStampSelected={timeStampSelected}
          setTimeStampSelected={setTimeStampSelected}
          startDateDisplay={startDateDisplay}
          endDateDisplay={endDateDisplay}
          setEndDateDisplay={setEndDateDisplay}
          setStartDateDisplay={setStartDateDisplay}
          CustomRangeCalled={CustomRangeCalled}
          entityType={entityType}
        />
      )}
    </Box>
  );
};

export default AuditTrailSetup;
