import React from "react";
import { Box } from "@mui/material";
import IconButtonComponent from "../../BaseComponents/IconButton";
import EquipmentFilter from "../../BaseComponents/Filter/EquipmentFilter";
import SearchComponent from "../../BaseComponents/Search";

const TitleStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  color: "#222934",
  marginRight: "16px",
  width: "fit-content",
  fontWeight: "500",
};

const TableIconButton = ({ onClick, iconClass }) => (
  <IconButtonComponent
    onClick={onClick}
    Component={
      <i
        style={{ color: "#9CA1A6", fontSize: "20px" }}
        className={iconClass}
      ></i>
    }
  />
);

const TableTitle = ({
  tempEquipments,
  tableLook,
  setTableLook,
  allLocations,
  equipmentTypes,
  handleSelectEquipmentType,
  handleSelectSensorType,
  handleSelectLocation,
  handleAlarmSelect,
  selectedEquipmentTypes,
  selectedSensors,
  selectedAlarm,
  selectedLocations,
  equipmentFilterCount,
  sensorTypeFilterCount,
  locationFilterCount,
  alarmFilterCount,
  statusFilterCount,
  clearFilter,
  handleStatusList,
  handleSelectedEquipmentList,
  selectedEquipementStatus,
  selectedStatus,
  searchText,
  setSearchText,
}) => {
  const relaxedTableLook = {
    title: "Relaxed view",
    value: "relax",
    iconClass: "fa-light fa-table-cells-large",
  };

  const denseTableLook = {
    title: "Condensed view",
    value: "dense",
    iconClass: "fa-light fa-table-cells",
  };

  const tableLookConfig = tableLook === "dense" ? relaxedTableLook : denseTableLook;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <p style={TitleStyle}>
        <b>{tempEquipments.length} equipment</b>
      </p>
      <Box sx={{ mr: 1, ml: 0.5 }}>
        <EquipmentFilter
          locations={allLocations}
          equipments={equipmentTypes}
          handleSelectEquipmentType={handleSelectEquipmentType}
          handleSelectSensorType={handleSelectSensorType}
          handleSelectLocation={handleSelectLocation}
          handleAlarmSelect={handleAlarmSelect}
          selectedEquipmentTypes={selectedEquipmentTypes}
          selectedSensors={selectedSensors}
          selectedAlarm={selectedAlarm}
          selectedLocations={selectedLocations}
          equipmentFilterCount={equipmentFilterCount}
          sensorTypeFilterCount={sensorTypeFilterCount}
          locationFilterCount={locationFilterCount}
          alarmFilterCount={alarmFilterCount}
          statusFilterCount={statusFilterCount}
          clearFilter={clearFilter}
          handleStatusList={handleStatusList}
          handleSelectedEquipmentList={handleSelectedEquipmentList}
          selectedEquipementStatus={selectedEquipementStatus}
          selectedStatus={selectedStatus}
        />
      </Box>
      <SearchComponent
        width="320px"
        placeholder="Search equipment...."
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </Box>
  );
};

export default TableTitle;
