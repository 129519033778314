import { Box } from "@mui/material";
import ToolTipComponent from "../Tooltip";
import IconButtonComponent from "../IconButton";
import DropDownComponent from "../DropDown";
import { checkStateByReports } from "../../../Utils/Tools";
import SensorReportsFilter from "../Filter/SensorReportsFilter";
import DateTimePickerComponent from "../DateTimePicker";
import DownloadDropdown from "../DownloadDropdown";

const ReportHeader = (props) => {
  const {
    timeStamps,
    setTimeStampSelected,
    timeStampSelected,
    handleStartDateChange,
    handleEndDateChange,
    startDate,
    endDate,
    setCustomQeurySubmission,
    tab,
    setTab,
    setTableLook,
    tableLook,
    sensor,
    isDateFieldsEnabled,
    sensorReports,
    handleAlarmSelect,
    selectedAlarm,
    alarmFilterCount,
    clearFilter,
    updateDates,
    setDownload,
    handleDownloadCSV,
    handlePrintPDF,
  } = props;

  var faulted = false;
  if (sensor && sensor.data) faulted = checkStateByReports(sensor?.data);
  const resetTimeStamp = () => {
    updateDates(2)
    setTimeStampSelected("0")
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <span style={{ position: "relative", marginRight: "16px" }}>
        <div
          className={`equipmentDot ${faulted ? "equipmentDotFault" : "equipmentDotSuccess"}`}
          style={{
            height: "10px",
            width: "10px",
            left: "26px",
          }}
        ></div>
        <i
          style={{
            fontSize: "30px",
            background: "rgb(236, 237, 238)",
            color: "rgb(156, 161, 166)",
            padding: "6px",
            borderRadius: "5px",
            fontWeight: "300",
          }}
          className="fa-regular fa-sensor"
        ></i>
      </span>
      <p
        style={{
          fontSize: "20px",
          lineHeight: "28px",
          color: "#222934",
          width: "fit-content",
          marginRight: "8px",
          whiteSpace: "nowrap",
        }}
      >
        <b>
          {sensor?.SensorLabel} {" report"}
        </b>
      </p>

      {tab === 0 ? (
        <Box sx={{ marginRight: "22px" }}>
          <ToolTipComponent
            title="Table view"
            value="relax"
            Component={
              <Box>
                <IconButtonComponent
                  onClick={() => setTab(1)}
                  Component={
                    <i
                      style={{ color: "#9CA1A6", fontSize: "20px" }}
                      className="fa-light fa-table"
                    ></i>
                  }
                />
              </Box>
            }
          />
        </Box>
      ) : (
        <>
          <Box sx={{ marginRight: "10px" }}>
            <ToolTipComponent
              title="Chart view"
              value="relax"
              Component={
                <Box>
                  <IconButtonComponent
                    onClick={() => setTab(0)}
                    Component={
                      <i
                        style={{ color: "#9CA1A6", fontSize: "20px" }}
                        className="fa-light fa-chart-line"
                      ></i>
                    }
                  />
                </Box>
              }
            />
          </Box>
          <Box
            sx={{
              mr: 2.5,
            }}
          >
            <SensorReportsFilter
              sensorReports={sensorReports}
              handleAlarmSelect={handleAlarmSelect}
              selectedAlarm={selectedAlarm}
              alarmFilterCount={alarmFilterCount}
              clearFilter={clearFilter}
            />
          </Box>
        </>
      )}

      <DropDownComponent
        padding={false}
        width="248px"
        options={props.timeStamps?.map((e) => {
          return e;
        })}
        handleChange={(e) => [setTimeStampSelected(e.value)]}
        value={timeStamps.find((el) => el.value === timeStampSelected) || ""}
      />
      <div style={{ display: "flex" }}>
        <div style={{ margin: "0.75em 0.25em 0.75em 1em", width: "50%" }}>
          <DateTimePickerComponent
            label=""
            value={startDate}
            onChange={handleStartDateChange}
            disabled={isDateFieldsEnabled}
          />
        </div>
        <div style={{ margin: "0.75em 0.25em 0.75em 1em", width: "50%" }}>
          <DateTimePickerComponent
            label=""
            value={endDate}
            onChange={handleEndDateChange}
            disabled={isDateFieldsEnabled}
          />
        </div>
      </div>
      {!isDateFieldsEnabled && (
        <div>
          <button
            onClick={() => setCustomQeurySubmission(true)}
            style={{
              color: "#17455E",
              border: "none",
              fontSize: "16px",
              lineHeight: "19px",
              fontWeight: "500",
              background: "transparent",
              cursor: "pointer",
              borderRight: "1px solid",
            }}
          >
            APPLY
          </button>
          <button
            onClick={resetTimeStamp}
            style={{
              color: "#17455E",
              border: "none",
              fontSize: "16px",
              lineHeight: "19px",
              fontWeight: "500",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            RESET
          </button>
        </div>
      )}
      <span style={{ marginLeft: "auto" }}>
        <DownloadDropdown
          handleDownloadCSV={handleDownloadCSV}
          handlePrintPDF={handlePrintPDF}
          downloadable={true}
        />
      </span>
    </Box>
  );
};

export default ReportHeader;
