import React, { useContext, useState } from "react";

import moment from "moment";
import Button from "@mui/material/Button";
import EditCustomers from "./EditCustomers";
//DRAWER CONTEXT
import { DrawerContext } from "../../../Context/DrawerContext";
import { Country } from "country-state-city";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import TableToggleButton from "@Components/Buttons/TableToggleButton";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@mui/material";

const classes = {
  bold: {
    fontWeight: 600,
  },
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1),
    width: "auto",
  },
  display: "inline",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#9CA1A6",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIconx
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "1px",
    borderStyle: "solid",
    borderColor: "#9CA1A6",
    borderRadius: "4px",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const CustomersTable = (props) => {
  let { customers, handleEdit, toggleDrawer, loading } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { open } = useContext(DrawerContext);
  //const tableWidth = open ? 'calc(100vw - 260px)' : 'calc(100vw - 80px)'

  //  const [page, setPage] = useState(0);
  //const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countries, setCountries] = useState(
    Country.getAllCountries().map((country) => {
      return { label: country.name, value: country.isoCode };
    })
  );
  const [searchText, setSearchText] = useState("");
  const [zebra, setZebra] = useState(true);
  const [tableLook, setTableLook] = useState("dense");

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            actions: {
              display: "flex",
              flexDirection: "row",
              flex: "initial",
              alignItems: "center",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            data: {
              fontWeight: "bold",
              color: "#9CA1A6",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: zebra === true ? "#eee" : null,
              },
            },
          },
        },

        MuiIconButton: {
          styleOverrides: {
            root: {
              borderRadius: "4px",
              color: "#9CA1A6",
              "&:hover": {
                color: "#00AEEF",
                backgroundColor: "#E0F5FD",
              },
            },
          },
        },
      },
    });

  const handleChangeZebra = () => {
    setZebra(!zebra);
  };

  const handleTableLook = (event, newLook) => {
    console.log("🚀 ~ file: CustomersTable.js ~ line 159 ~ handleTableLook ~ newLook", newLook);
    if (newLook !== null) {
      setTableLook(newLook);
    }
  };

  const columns = [
    {
      name: "customerName",
      label: "Customer Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableLook == "dense" && (
                <div style={{ paddingLeft: tableLook === "dense" && "2px" }}>{value}</div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "Address",
      label: "Address",
    },
    {
      name: "City",
      label: "City",
    },
    {
      name: "State",
      label: "State or Province",
    },
    {
      name: "Country",
      label: "Country",
    },
    {
      name: "PostalCode",
      label: "Postal Code",
    },
    {
      name: "ContactName",
      label: "Contact Name",
    },
    {
      name: "ContactPhone",
      label: "Contact Phone",
    },
    {
      name: "Email",
      label: "Email",
    },
    {
      name: "CreatedAt",
      label: "Created Date",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "Updated At",
      label: "Updated Date",
      options: {
        filter: true,
        sort: true,
        sortOrder: "desc",
      },
    },
    {
      name: "", //actions
      label: "",
    },
    {
      name: "IsActive",
      label: "Status",
      options: {
        display: false,
        download: false,
      },
    },
  ];

  const options = {
    customToolbar: () => {
      return (
        <Box style={matches ? null : { order: -1 }}>
          {matches ? (
            <Button
              sx={{
                ml: 3,
                bgcolor: "#17455E",
                color: "#f7f8f8",
                "&:hover": {
                  bgcolor: "#11364C",
                  color: "#f7f8f8",
                },
                "&:focus": {
                  bgcolor: "#11364C",
                  borderColor: "#00AEEF80",
                  borderStyle: "solid",
                  borderWidth: "3px",
                  borderRadius: "4px",
                  color: "#f7f8f8",
                },
                "&:disabled": {
                  bgcolor: `#17455E`,
                  color: "#f7f8f8",
                  opacity: 0.65,
                },
              }}
              variant="contained"
              onClick={() => toggleDrawer(true)}
            >
              Add Customer
            </Button>
          ) : (
            <Tooltip title="Add Customer">
              <IconButton onClick={() => toggleDrawer(true)}>
                <i className="fa-solid fa-plus"></i>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
    selectableRows: "none",
    fixedHeader: true,
    responsive: "simple",
    filter: false,
    search: false,
    fixedHeader: true,
    sort: true,
    tableBodyHeight: "calc(100vh - 253px)",
    searchText: searchText,
    customSearchRender: () => null,
    rowsPerPage: tableLook === "dense" ? 50 : 20,
    rowsPerPageOptions: [20, 50, 100, 500],
    sortOrder: {
      name: "customerName",
      direction: "asc",
    },
    setTableProps: () => ({
      padding: tableLook === "dense" ? "none" : "default",
      size: tableLook === "dense" ? "small" : "medium",
    }),
  };

  const useStyles = makeStyles({
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        paddingLeft: "5px",
      },
    },
  });

  const classes = useStyles();

  return (
    <Box sx={{ mt: 4 }}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          classes={{ root: classes.customTable }}
          title={
            <>
              <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    variant="outlined"
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Search>

                <TableToggleButton
                  handleTableLook={handleTableLook}
                  tableLook={tableLook}
                  handleChangeZebra={handleChangeZebra}
                  zebra={zebra}
                  showZebra={true}
                />
              </Box>
            </>
          }
          columns={columns}
          options={options}
          data={customers?.map((row) => {
            let id = row.CustomerID;
            let date = moment(row.createdAt).format("ll");
            //let update = moment(row.updatedAt).format('ll');
            let country = countries.find((country) => country.value === row.Country);

            return [
              row.CustomerName,
              row.Address,
              row.City,
              row.StateProvince,
              country?.label,
              row.PostalCode,
              row.ContactName,
              row.ContactPhone,
              row.ContactEmail,
              new Date(row.createdAt).toLocaleDateString("en-US"),
              new Date(row.updatedAt).toLocaleDateString("en-US"),
              <EditCustomers
                customer={row}
                handleEdit={handleEdit}
                tableLook={tableLook}
              />,
              row.IsActive ? "Active" : "Inactive",
            ];
          })}
        />
      </ThemeProvider>

      {/* <TableContainer sx={{ maxWidth: tableWidth, maxHeight: 'calc(100vh - 200px)', overflowX: 'scroll' }} component={Paper}>
                    <Box sx={{ m: 1, mr: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button color='primary' variant='contained' onClick={() => toggleDrawer(true)} >Add Customer</Button>
                        </Box>
                    </Box>
                    {loading && <Loader />}
                    <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell style={classes.bold} >Customer Name</TableCell>
                                <TableCell style={classes.bold} align="right">Address</TableCell>
                                <TableCell style={classes.bold} align="right">City</TableCell>
                                <TableCell style={classes.bold} align="right">State</TableCell>
                                <TableCell style={classes.bold} align="right">Country</TableCell>
                                <TableCell style={classes.bold} align="right">Postal Code</TableCell>
                                <TableCell style={classes.bold} align="right">Contact Name</TableCell>
                                <TableCell style={classes.bold} align="right">Contact Phone</TableCell>
                                <TableCell style={classes.bold} align="right">Email</TableCell>
                                 <TableCell style={classes.bold} align="right">Date</TableCell>
                                <TableCell style={classes.bold} align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers?.map((row) => {
                                let id = row.CustomerID;
                                // let date = moment(row.createdAt).format('DD-MM-YYYY');
                               let country = countries.find( country => country.value === row.Country )

                                return (
                                    <TableRow
                                        key={id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.CustomerName}
                                        </TableCell>
                                        <TableCell align="right">{row.Address}</TableCell>
                                        <TableCell align="right">{row.City}</TableCell>
                                        <TableCell align="right">{row.StateProvince}</TableCell>
                                        <TableCell align="right">{country?.label}</TableCell>
                                        <TableCell align="right">{row.PostalCode}</TableCell>
                                        <TableCell align="right">{row.ContactName}</TableCell>
                                        <TableCell align="right">{row.ContactPhone}</TableCell>
                                        <TableCell align="right">{row.ContactEmail}</TableCell>
                                         <TableCell align="right">{date}</TableCell>
                                        <TableCell align="right">{<EditCustomers customer={row} handleEdit={handleEdit} />}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer >
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={customers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
    </Box>
  );
};

export default CustomersTable;
