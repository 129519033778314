import { apiSlice } from "../API";

export const sensorTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorType: builder.query({
      query: () => "/sensorType",
      providesTags: ["SensorType"],
    }),
    getSensorType: builder.query({
      query: (id) => `/sensorType/${id}`,
    }),
    addSensorType: builder.mutation({
      query: (sensorType) => ({
        url: "/sensorType",
        method: "POST",
        body: sensorType,
      }),
      invalidatesTags: ["SensorType"],
    }),
    editSensorType: builder.mutation({
      query: ({ id, SensorType }) => ({
        url: `/sensorType/${id}`,
        method: "PUT",
        body: SensorType,
      }),
      invalidatesTags: ["SensorType"],
    }),
    deleteSensorType: builder.mutation({
      query: (id) => ({
        url: `/sensorType/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SensorType"],
    }),
  }),
});

export const {
  useFindSensorTypeQuery,
  useGetSensorTypeQuery,
  useAddSensorTypeMutation,
  useEditSensorTypeMutation,
} = sensorTypeSlice;
