import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DrawerContext } from "../../../Context/DrawerContext";
import { useGetUserQuery, useEditUserMutation } from "../../../Store/Slices/usersSlice";
import ThemeButton from "@Components/Buttons";
import { useUploadImageMutation } from "../../../Store/Slices/imageSlice";
import { useDispatch, useSelector } from "react-redux";
import { cleanPhoneNumber } from "../../../Utils/Tools";
import { useFindSensorAlertCalendarTypeQuery } from "../../../Store/Slices/sensorAlertCalendarTypeSlice";
import { setCurrentUser } from "../../../Store/Slices/authSlice";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import UserPreferencesTab from "../UserPreferences/UserPreferencesTab";
import DrawerComponent from "../../BaseComponents/Drawer";
import { useUpdateUserPassMutation } from "../../../Store/Slices/authApiSlice";
import Toast from "@Components/BaseComponents/Toast";
import { matchIsValidTel } from "mui-tel-input";

export default function RightDrawer({ ...props }) {
  const { toggleDrawer, drawer, setUpdateUserResponce, handleMenuClose } = props;
  const auth = useSelector((state) => state.auth);
  const [resetPasswordDrawer, setResetPasswordDrawer] = useState(false);
  const {
    data: SensorAlertCalendarTypes,
    isLoading: SensorAlertCalendarTypeLoading,
    isSuccess: SensorAlertCalendarTypeSuccess,
    isError: SensorAlertCalendarTypeIsError,
    error: SensorAlertCalendarTypeError,
  } = useFindSensorAlertCalendarTypeQuery();

  const {
    data: userData,
    isSuccess: UserSuccess,
    isLoading: UserLoading,
  } = useGetUserQuery(auth.UserID);
  const [uploadImage, { isSuccess: imageUploadSuccess }] = useUploadImageMutation();

  const [updateUserPass, { isSuccess: passChangeSuccess, isError: passChangeError }] =
    useUpdateUserPassMutation();

  const [editUser, { isSuccess: editUserSuccess }] = useEditUserMutation();
  const [sensorAlertTypes, setSensorAlertTypes] = useState([]);
  const [securityCheck, setSecurityCheck] = useState(false);
  const [securityButtonValidation, setSecurityButtonValidation] = useState(false);
  const [openedTab, setOpenedTab] = useState("1");
  const { open } = useContext(DrawerContext);
  const [user, setUser] = useState({
    UserName: "",
    FirstName: "",
    LastName: "",
    SNSEmail: "",
    SNSPhone: "",
    UserID: "",
    Language: "",
    SensorAlertCalendarType: "",
    PreferSMS: false,
    PreferEmail: false,
    GMTAdjustment: "",
  });
  const [password, setPassword] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);

  const loadSensorAlertTypes = () => {
    if (SensorAlertCalendarTypeSuccess)
      setSensorAlertTypes(
        SensorAlertCalendarTypes.success.data.map((alertType) => {
          return {
            value: alertType.Id,
            label: alertType.CalendarName,
            Description: alertType.Description,
          };
        })
      );
    if (SensorAlertCalendarTypeIsError) console.log(SensorAlertCalendarTypeError);
  };

  useEffect(() => {
    loadSensorAlertTypes();
  }, [SensorAlertCalendarTypes]);

  const loadUser = () => {
    if (UserSuccess) {
      setUser(userData?.success.data);
      setPassword({
        CurrentPassword: "",
        NewPassword: "",
        ConfirmNewPassword: "",
      });
    }
  };

  const handleChange = (e) => {
    setUserUpdated(true);
    let { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleChangePhoneInput = (val) => {
    setUserUpdated(true);

    setUser({
      ...user,
      SNSPhone: val,
    });
  };

  const handleSecurityClick = async (e) => {
    let data = { UserID: user.UserID, Passwords: password };
    let res = await updateUserPass(data);
    setPassword({
      CurrentPassword: "",
      NewPassword: "",
      ConfirmNewPassword: "",
    });
    setUserUpdated(false);
    console.log("🚀 ~ file: ChangePassword.js ~ line 77 ~ passChange ~ res", res);
  };

  const handleButtonClick = async (user) => {
    // console.log(user)
    setLoading(true);
    let id = user.UserID;
    let temp_user = { ...user };
    console.log("🚀 ~ file: index.js ~ line 51 ~ handleButtonClick ~ temp_user", temp_user);

    delete temp_user["createdAt"];
    delete temp_user["updatedAt"];
    delete temp_user["CustomerID"];
    delete temp_user["userPassword"];
    delete temp_user["UserID"];
    delete temp_user["IsActive"];
    let data = temp_user;

    data.SNSPhone = cleanPhoneNumber(temp_user.SNSPhone);

    try {
      console.log("🚀 ~ file: index.js ~ line 50 ~ handleButtonClick ~ id", id);
      console.log("🚀 ~ file: index.js ~ line 57 ~ handleButtonClick ~ data", data);
      let res = await editUser({ id, data });
      dispatch(setCurrentUser(data));
      setUpdateUserResponce(true);
      setUserUpdated(false);
      // toggleDrawer(false);
      console.log("🚀 ~ file: index.js ~ line 63 ~ handleButtonClick ~ res", res);
    } catch (err) {
      console.log("🚀 ~ file: index.js ~ line 49 ~ handleButtonClick ~ res", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userUpdated) {
      confirmationDrawer(true);
    }
  }, [openedTab]);
  const handleClick = (e) => {
    if (openedTab == "1") {
      handleButtonClick(user);
    }
    if (openedTab == "2") {
      handleSecurityClick(e);
    }
  };

  const phoneValueWithSymbol = !user?.SNSPhone.includes("+")
    ? `+${user?.SNSPhone}`
    : user?.SNSPhone;

  const hasValidFields = (user) => {
    return !!(user?.FirstName && user?.LastName && user?.SNSEMail && user?.SNSPhone);
  };

  const buttonValidation = !matchIsValidTel(phoneValueWithSymbol) || !hasValidFields(user);

  const userConfirmationDeawer = () => {
    if (userUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };
  const confirmationDrawer = (open) => {
    setResetPasswordDrawer(open);
  };

  useEffect(() => {
    loadUser();
  }, [userData, SensorAlertCalendarTypes]);

  return (
    <>
      {passChangeSuccess && (
        <Toast
          message={`${user?.FirstName}'s password updated successfully.`}
          severity="success"
        />
      )}
      {passChangeError && (
        <Toast
          message={`${user?.FirstName}'s password didn't changed.`}
          severity="error"
        />
      )}

      <DrawerComponent
        handleMenuClose={handleMenuClose}
        drawer={drawer}
        toggleDrawer={userConfirmationDeawer}
        title={"Preferences"}
        buttonTitle={"Save"}
        handleClick={handleClick}
        buttonValidation={openedTab == "2" ? securityButtonValidation : buttonValidation}
        edit={false}
        displayFooter={true}
        closeToast
        user={user}
      >
        <Box width={568}>
          <UserPreferencesTab
            user={user}
            password={password}
            setPassword={setPassword}
            setSecurityCheck={setSecurityCheck}
            setUser={setUser}
            handleChange={handleChange}
            onChangePhoneInput={handleChangePhoneInput}
            sensorAlertTypes={sensorAlertTypes}
            openedTab={openedTab}
            setOpenedTab={setOpenedTab}
            setUserUpdated={setUserUpdated}
            setSecurityButtonValidation={setSecurityButtonValidation}
          />
        </Box>
        <DrawerComponent
          drawer={resetPasswordDrawer}
          toggleDrawer={confirmationDrawer}
          title="Save changes?"
          edit={false}
          displayFooter={false}
          closeToast={confirmationDrawer}
          loadUser={loadUser}
          setUserUpdated={setUserUpdated}
        >
          <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
            <Box
              sx={{
                width: "530px",
                textAlign: "left",
                marginBottom: "15px",
                font: "normal normal normal 20px/24px Roboto",
              }}
            >
              You have unsaved changes.
            </Box>
            <Box
              sx={{
                width: "530px",
                textAlign: "left",
                marginBottom: "15px",
                font: "normal normal normal 16px/24px Roboto",
                color: "#222934",
              }}
            >
              Would you want to save your changes before closing?
            </Box>
            <Box sx={{ display: "flex" }}>
              <div
                style={{ display: "inline", marginRight: "25px" }}
                onClick={() => {
                  confirmationDrawer(false);
                  // toggleDrawer(false);
                  setUserUpdated(false);
                  loadUser();
                }}
              >
                <OutlinedButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  width="248px"
                >
                  DISCARD
                </OutlinedButton>
              </div>
              <ThemeButton
                variant="contained"
                color="primary"
                size="small"
                width="248px"
                onClick={() => {
                  handleButtonClick(user);
                  // toggleDrawer(false);
                  confirmationDrawer(false);
                  setUserUpdated(false);
                  loadUser();
                }}
              >
                SAVE{" "}
              </ThemeButton>
            </Box>
          </Box>
        </DrawerComponent>
      </DrawerComponent>
    </>
  );
}
