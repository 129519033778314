import React from "react";

//COMPONENTS IMPORT
import IconButtonComponent from "../../../Components/BaseComponents/IconButton";

const EditPorgram = (props) => {
  let { program, handleEdit } = props;
  return (
    <>
      <IconButtonComponent
        onClick={() => handleEdit(program)}
        Component={<i class="fa-light fa-pen-to-square "></i>}
      />
    </>
  );
};

export default EditPorgram;
