import React from "react";
import { TableRow, TableCell, Box, Button } from "@mui/material";
import IconButtonComponent from "../../BaseComponents/IconButton";
import TableComponent from "../../BaseComponents/Table";
import ToolTipComponent from "../../BaseComponents/Tooltip";
import { findObjectByValue, checkHasAuthPermissionsByEntity } from "../../../Utils/Tools";
import { Link } from "react-router-dom";
import SensorContextMenu from "../../BaseComponents/SensorContextMenu";

const ExpandableRow = ({
  data,
  handleSensorEdit,
  notAllowedRoles,
  auth,
  primary,
  lightGrey,
  blueAction,
  locations,
  sensorTypes,
  substations,
  sensorColumns,
  equipmentCustomOptions,
  locationsHierarchy,
  handleTableAdd,
  handleAlertSuppression,
}) => {
  return (
    <TableRow
      sx={{ backgroundColor: "#F7F8F8" }}
      key={data[0].EquipmentID}
    >
      <TableCell colSpan={8}>
        {data[0]?.Sensors.length > 0 ? (
          <TableComponent
            cellPaddingLeft="16px"
            cellPaddingRight="0.96px"
            paddingLeft="16px"
            boxShadow="none"
            hideToolbar={true}
            tableLook="dense"
            zebraStyle={true}
            columns={sensorColumns}
            customOptions={equipmentCustomOptions}
            mt="6px"
            height="auto"
            backgroundColor="#F7F8F8"
            data={data[0].Sensors.map((sensor) => {
              const [port, ...extractedSerial] = sensor.PortSerial?.split("-");
              const serialNumber = extractedSerial.join("-");

              const substation = findObjectByValue(substations, "SerialNumber", serialNumber);
              const location = findObjectByValue(locations, "LocationID", substation?.LocationID);
              const sensorType = findObjectByValue(
                sensorTypes,
                "SensorTypeID",
                sensor.SensorTypeID
              );

              return [
                checkHasAuthPermissionsByEntity(auth.Authorizations.setup, "Sensors") ? (
                  <Link
                    to={"/setup/sensors"}
                    state={{ SensorID: sensor?.SensorID }}
                    className="device-link"
                  >
                    {sensor?.SensorLabel}
                  </Link>
                ) : (
                  sensor?.SensorLabel
                ),
              
                checkHasAuthPermissionsByEntity(auth.Authorizations.setup, "Substations") ? (
                  <Link
                    to={"/setup/substations"}
                    state={{ SubstationID: substation?.SubstationID }}
                    className="device-link"
                  >
                    {substation?.Label}
                  </Link>
                ) : (
                  substation?.Label
                ),
                sensor.SensorTypeLabel == "Analog" ? `A${port}` : `D${port}`,
                locationsHierarchy[location?.LocationID],
                <span style={{ display: "flex", alignItems: "center" }}>
                  {checkHasAuthPermissionsByEntity(
                    auth.Authorizations.setup,
                    "Sensors",
                    "Edit"
                  ) && (
                    <ToolTipComponent
                      placement="bottom-end"
                      title="Edit sensor"
                      Component={
                        <Box
                          sx={{
                            width: "fit-content",
                          }}
                        >
                          <IconButtonComponent
                            onClick={() => handleSensorEdit(sensor)}
                            Component={<i class="fa-light fa-pen-to-square "></i>}
                          />
                        </Box>
                      }
                    />
                  )}
                  <SensorContextMenu
                    sensor={sensor}
                    handleAlertSuppression={handleAlertSuppression}
                    alertSuppression
                    unsuppressAlarm
                    viewReport
                    auditTrail
                  />
                </span>,
              ];
            })}
          />
        ) : (
          <div
            style={{
              padding: "91px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "88px",
                height: "88px",
                background: "#E0E1E3 0% 0% no-repeat padding-box",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: "16px",
              }}
            >
              <i
                className="fa-light fa-sensor-triangle-exclamation"
                style={{
                  color: "#222934",
                  fontSize: "50px",
                  lineHeight: "60px",
                  fontWeight: 300,
                }}
              ></i>
            </Box>
            {notAllowedRoles.includes(auth.Role) ? (
              <p
                style={{
                  color: "#222934",
                  fontSize: "18px",
                  lineHeight: "25px",
                  fontWeight: 400,
                }}
              >
                No sensors attached to this equipment
              </p>
            ) : (
              <div>
                <p
                  style={{
                    color: "#222934",
                    fontSize: "18px",
                    lineHeight: "25px",
                    fontWeight: 400,
                    marginBottom: "19px",
                  }}
                >
                  No sensors attached to this equipment
                </p>
                <Button
                  sx={{
                    fontSize: "16px",
                    fontWeight: "medium",
                    borderColor: primary["500"],
                    borderStyle: "solid",
                    borderWidth: "1px",
                    px: 1,
                    py: 0.3,
                    color: primary["500"],
                    height: "32px",
                    width: "104px",
                    "&:hover": {
                      backgroundColor: primary["700"],
                      color: lightGrey["50"],
                      borderColor: primary["500"],
                      borderStyle: "solid",
                      borderWidth: 1,
                    },
                    "&:active": {
                      color: lightGrey["50"],
                      backgroundColor: primary["700"],
                      borderStyle: "solid",
                      borderWidth: 1,
                    },
                    "&:focus": {
                      color: lightGrey["50"],
                      backgroundColor: primary["700"],
                      borderColor: blueAction["500"],
                      borderStyle: "solid",
                      borderWidth: "3px",
                    },
                    "&:disabled": {
                      color: primary["500"],
                      borderColor: primary["500"],
                      borderStyle: "solid",
                      borderWidth: "1px",
                      opacity: 0.65,
                    },
                  }}
                  variant="outlined"
                  size="small"
                  onClick={() => handleTableAdd(data[0].EquipmentID)}
                >
                  <i
                    className="fa-light fa-plus "
                    style={{ marginRight: "5px" }}
                  ></i>
                  Sensor
                </Button>
              </div>
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ExpandableRow;
