import React from "react";
import SortIcon from "../Icons/SortIcon";
import SortUpIcon from "../Icons/SortUpIcon";
import SortDownIcon from "../Icons/SortDownIcon";

const ColumnHeaderComponent = (props) => {
  const { columnMeta, handleToggleColumn, sortOrder, customStyles, sort, columnWidth } = props;
  return (
    <th
      className="text-uppercase"
      onClick={() => handleToggleColumn(columnMeta?.index)}
      style={{
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0px",
        color: "#9CA1A6",
        textAlign: "left",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        verticalAlign: "middle",
        cursor: "pointer",
        position: "sticky",
        top: " 0px",
        zIndex: "100",
        backgroundColor: "#fff",
        whiteSpace: "nowrap",
        width: columnWidth || "auto",
        minWidth: "100px",
        overflow: "hidden",
        height: "40px",
        ...customStyles,
      }}
    >
      {String(columnMeta?.label)}&ensp;
      {sort ? (
        sortOrder.name == columnMeta?.name ? (
          sortOrder.direction == "asc" ? (
            <SortUpIcon />
          ) : (
            <SortDownIcon />
          )
        ) : (
          <SortIcon />
        )
      ) : (
        ""
      )}
    </th>
  );
};
export default ColumnHeaderComponent;
