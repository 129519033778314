import React from "react";
import MapMainContainer from "../MapView";

const SetupMapPane = (props) => {
  const {
    CustomerID,
    LocationID,
    location,
    SelectedEquipment,
    setSelectedEquipment,
    setSelectedLocationId,
    substations,
    devices,
    edit,
    setEdit,
    equipments,
    toggleDrawer,
    toggleClearMapDrawer,
    equipmentLive,
    mappedEquipments,
    mappedEquipmentLoading,
    updatedMappedEquipments,
    setUpdatedMappedEquipments,
    toggleChangesDrawer,
    handleSave,
    selectedLocationId,
    setEditedMappedEquipments,
    mappedSubstations,
    updatedMappedSubstations,
    setUpdatedMappedSubstations,
    setEditedMappedSubstations,
    editedMappedSubstations,
    editedMappedEquipments,
    mappedSubstationLoading,
    paneWidth,
    allowedEdit,
    saveDrawer,
    saveToggleDrawer,
  } = props;
  return (
    <div
      {...props}
      className="split-pane-right"
    >
      <MapMainContainer
        // handleEquipmentSelect={(id) =>console.log(id)}
        paneWidth={paneWidth}
        CustomerID={CustomerID}
        LocationID={LocationID}
        locationObject={location}
        SelectedEquipment={SelectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
        setSelectedLocationId={setSelectedLocationId}
        substations={substations}
        devices={devices}
        equipments={equipments}
        edit={edit}
        setEdit={setEdit}
        toggleDrawer={toggleDrawer}
        toggleClearMapDrawer={toggleClearMapDrawer}
        equipmentLive={equipmentLive}
        mappedEquipments={mappedEquipments}
        mappedEquipmentLoading={mappedEquipmentLoading}
        mappedSubstationLoading={mappedSubstationLoading}
        updatedMappedEquipments={updatedMappedEquipments}
        setUpdatedMappedEquipments={setUpdatedMappedEquipments}
        handleSave={handleSave}
        toggleChangesDrawer={toggleChangesDrawer}
        selectedLocationId={selectedLocationId}
        setEditedMappedEquipments={setEditedMappedEquipments}
        mappedSubstations={mappedSubstations}
        updatedMappedSubstations={updatedMappedSubstations}
        editedMappedSubstations={editedMappedSubstations}
        editedMappedEquipments={editedMappedEquipments}
        setUpdatedMappedSubstations={setUpdatedMappedSubstations}
        setEditedMappedSubstations={setEditedMappedSubstations}
        allowedEdit={allowedEdit}
        saveToggleDrawer={saveToggleDrawer}
      />{" "}
    </div>
  );
};

export default SetupMapPane;
