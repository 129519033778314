import React, { useEffect, useState, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { AlarmContext } from "@Context/AlarmContext";

import { useTheme } from "@mui/material/styles";
import Toast from "@Components/BaseComponents/Toast";
import { findObjectByValue } from "../../Utils/Tools";
import TableComponent from "../BaseComponents/Table";
import { useNavigate } from "react-router-dom";
import LabelComponent from "../BaseComponents/LabelComponent";
import SearchComponent from "../BaseComponents/Search";
import EditTenant from "../../Routes/Setup/Tenant/EditTenant";
import CustomAvatar from "../Avatar";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";
import { setCurrentCustomer } from "../../Store/Slices/authSlice";
import { useDispatch } from "react-redux";
import ToolTipComponent from "../BaseComponents/Tooltip";

const TenantTable = (props) => {
  let { auth, tenants, handleEdit, toggleDrawer, users, allowedEdit } = props;
  const theme = useTheme();
  const [displayTenantToast, setDisplayTenantToast] = useState(false);
  const [tempTenants, setTempTenants] = useState(tenants);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alarmPanelToggle, setAlarmPanelToggle } = useContext(AlarmContext);

  const columns = [
    {
      name: "TenantID",
      label: "TenantID",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: false,
      },
    },

    {
      name: "TenantName",
      label: "Tenant name",
      options: {
        searchable: true,
        sort: true,
        paddingLeft: "88px",
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = dataListOne.tenant.CustomerName.toUpperCase();
            var valueTwo = dataListTwo.tenant.CustomerName.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ position: "relative", paddingRight: "16px" }}>
                <div
                  style={{
                    height: "48px",
                    width: "48px",
                    position: "absolute",
                  }}
                ></div>
                <i
                  style={{
                    fontSize: "30px",
                    background: "#ECEDEE",
                    color: " #9CA1A6",
                    padding: "9px",
                    borderRadius: "5px",
                    fontWeight: "300",
                    lineHeight: "36px",
                  }}
                  className="fa-light fa-list-timeline"
                ></i>
              </span>

              <Typography
                onClick={() =>
                  value.navigateTenant && handleCustomerChange(value.tenant.CustomerID)
                }
                sx={{
                  color: value.navigateTenant ? "#00AEEF" : "rgba(0, 0, 0, 0.87)",
                  fontSize: "18px",
                  lineHeight: "19px",
                  fontWeight: "medium",
                  textAlign: "left",
                  "&:hover": value.navigateTenant && {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                  pointerEvents: !value.navigateTenant && "none",
                }}
              >
                {value.tenant.CustomerName
                  ? value.tenant.CustomerName
                  : "Tenant name not specified"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "Owner",
      label: "Owner",
      options: {
        searchable: true,
        sort: true,
        paddingLeft: "74px",
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = dataListOne.user.split(" ")[0].toUpperCase();
            var valueTwo = dataListTwo.user.split(" ")[0].toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CustomAvatar
                sx={{
                  bgcolor: "#7151A1",
                  width: "64px",
                  height: "64px",
                  fontSize: "29px",
                }}
                name={value.user}
                src={
                  value.image ? `${process.env.REACT_APP_URL}/asset/download/${value.image}` : null
                }
              />
              <div style={{ marginLeft: 10 }}>
                <Typography
                  sx={{
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: "#222934",
                  }}
                >
                  {value.user}
                </Typography>

                <Typography sx={{ fontSize: "14px", color: "#9CA1A6" }}>{value.email}</Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "RequireActionReason",
      label: "Require action reason",
      options: {
        searchable: true,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = dataListOne.props.Component.props.children.props.label.toUpperCase();
            var valueTwo = dataListTwo.props.Component.props.children.props.label.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "RequireAlarmReason",
      label: "Require alarm reason",
      options: {
        searchable: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = dataListOne.props.Component.props.children.props.label.toUpperCase();
            var valueTwo = dataListTwo.props.Component.props.children.props.label.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "RequireAuthentication",
      label: "Require authentication",
      options: {
        searchable: true,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = dataListOne.props.Component.props.children.props.label.toUpperCase();
            var valueTwo = dataListTwo.props.Component.props.children.props.label.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const customOptions = {
    download: false,
    selectableRows: false,
    expandableRows: false,

    customSearch: (searchQuery, currentRow, columns) => {
      if (
        currentRow[1].tenant.CustomerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[2]?.user.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[2]?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[3].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        currentRow[4].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        currentRow[5].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
        return true;
      return false;
    },

    sortOrder: {
      name: "Role",
      direction: "asc",
    },
  };
  const handleAlarmToggle = () => {
    setAlarmPanelToggle(!alarmPanelToggle);
  };

  const handleCustomerChange = async (id) => {
    console.log(id, auth);
    const res = await dispatch(setCurrentCustomer({ auth: auth, CustomerID: id }));
    if (res) {
      setDisplayTenantToast(true);
      navigate("/statusview", {
        state: {
          tenantUpdatedToast: true,
        },
      });
      if (alarmPanelToggle) handleAlarmToggle();
    }
  };

  useEffect(() => {
    if (!displayTenantToast) return;
    const timeout = setTimeout(() => {
      setDisplayTenantToast(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [displayTenantToast]);

  useEffect(() => {
    setTempTenants(tenants);
  }, [tenants]);

  useEffect(() => {
    if (searchText.length) {
      let tempFilteredTenants = tenants.filter((tenant) => {
        let filter1 = true;
        if (searchText.length) {
          filter1 =
            tenant.ContactEmail.toLowerCase().includes(searchText.toLowerCase()) ||
            tenant.CustomerName.toLowerCase().includes(searchText.toLowerCase());
        }
        return filter1;
      });
      setTempTenants(tempFilteredTenants);
    } else {
      setTempTenants(tenants);
    }
  }, [searchText]);

  return (
    <>
      {displayTenantToast && (
        <Toast
          message={`You are now viewing ${
            findObjectByValue(tenants, "value", auth.SelectedCustomer)?.label || "All"
          }`}
          severity="success"
        />
      )}
      <TableComponent
        zebraStyle={false}
        tableLook={"relaxed"}
        customOptions={customOptions}
        cellPaddingLeft="24px"
        textAlign={true}
        background="white"
        height="89vh"
        borderRadius="4px"
        mt="0"
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "93.5px",
                marginRight: "8px",
              }}
            >
              <b>
                {tempTenants.length} {tempTenants.length <= 1 ? " tenant" : " tenants"}
              </b>
            </p>
            <SearchComponent
              width="320px"
              padding={0}
              placeholder="Search tenants...."
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Box>
        }
        columns={columns}
        searchText={searchText}
        customToolbar={
          allowedEdit && (
            <TableHeaderAddButton
              onClick={() => toggleDrawer(true)}
              label="Tenant"
              width="105px"
            />
          )
        }
        data={tempTenants.map((tenant) => {
          const navigateTenant = true;
          const TenantUser = findObjectByValue(users, "SNSEMail", tenant.ContactEmail);

          return [
            tenant.value,
            {
              tenant: tenant,
              navigateTenant: navigateTenant,
            },
            {
              user: TenantUser ? TenantUser?.FirstName + " " + TenantUser?.LastName : "",
              email: tenant.ContactEmail,
              image: TenantUser ? TenantUser?.ProfileImage : "",
            },
            <ToolTipComponent
              title={`Action reason ${tenant.RequireActionReason ? "" : "not"} required`}
              placement="bottom-start"
              Component={
                <Box
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <LabelComponent
                    label={`${tenant.RequireActionReason ? "Yes" : "No"}`}
                    state={"primary"}
                    marginBottom="16px"
                    marginTop="16px"
                  />
                </Box>
              }
            />,
            <ToolTipComponent
              title={`Alarm reason ${tenant.RequireActionReason ? "" : "not"} required`}
              placement="bottom-start"
              Component={
                <Box
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <LabelComponent
                    label={`${tenant.RequireAlarmReason ? "Yes" : "No"}`}
                    state={"primary"}
                    marginBottom="16px"
                    marginTop="16px"
                  />
                </Box>
              }
            />,
            <ToolTipComponent
              title={`Authentication ${tenant.RequireAuthentication ? "" : "not"} required`}
              placement="bottom-start"
              Component={
                <Box
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <LabelComponent
                    label={`${tenant.RequireAuthentication ? "Yes" : "No"}`}
                    state={"primary"}
                    marginBottom="16px"
                    marginTop="16px"
                  />
                </Box>
              }
            />,
            allowedEdit && (
              <EditTenant
                role={tenant}
                handleEdit={handleEdit}
              />
            ),
          ];
        })}
        tableBodyHeight="calc(100vh - 253px)"
      />
    </>
  );
};

export default TenantTable;
