import React, { createContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const DrawerContext = createContext();

const DrawerProvider = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  let drawerWidth = 240;

  return (
    <DrawerContext.Provider value={{ open, setOpen, drawerWidth }}>
      {props.children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
