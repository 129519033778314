import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableComponent from "../BaseComponents/Table";
import ToolTipComponent from "../BaseComponents/Tooltip";
import SearchComponent from "../BaseComponents/Search";
import LabelComponent from "../BaseComponents/LabelComponent";

import {
  filterObjectByValue,
  findObjectByValue,
  getColorCodingStatusSingleSensor,
  getLimitValue,
} from "../../Utils/Tools";
import { useScrollToTableRow } from "../../Hooks/useScrollToTableRow";
import { useFindSubstationsQuery } from "../../Store/Slices/substationSlice";
import { useFindEquipmentTypeQuery } from "../../Store/Slices/equipmentTypeSlice";
import { useFindProgramsQuery } from "../../Store/Slices/programSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import SensorFilter from "../BaseComponents/Filter/SensorFilter";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyGetSensorConfigQuery } from "../../Store/Slices/sensorSlice";
import EditSensor from "../../Routes/Setup/Sensor/EditSensors";
import SensorLabel from "../BaseComponents/SensorLabel";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";
import SensorContextMenu from "../BaseComponents/SensorContextMenu";

const extractValue = (inputString, selector) => {
  const regex = /([\s\S]+?) \| ([\s\S]+)/;
  const match = inputString.match(regex);

  if (!match) {
    return "Empty";
  }

  const [, leftSide, rightSide] = match;

  if (selector === "limit") {
    return leftSide.trim();
  }

  if (selector === "delay") {
    return rightSide.trim();
  }
};

const SensorTable = (props) => {
  const {
    toggleDrawer,
    equipments,
    toggleLimitsTemplateDrawer,
    equipmentLive,
    locations,
    handleEdit,
    mappedEquipments,
    mappedSubstations,
    sensorTypes,
    sensors,
    sensorLimitTemplates,
    allowedEdit,
    auth,
    setSelectedAction,
    setAllSelectedSensors,
    setUnSuppressAction,
    handleAlertSuppression,
  } = props;

  const theme = useTheme();
  const [tableLook, setTableLook] = useState("dense");
  const [searchText, setSearchText] = useState("");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [substations, setSubstations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(["Active"]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [tempSensors, setTempSensors] = useState(sensors);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [statusFilterCount, setStatusFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const { handleSelectRowIndex } = useScrollToTableRow();

  const authorizations = auth.Authorizations["setup"];
  const permissionAllowed = filterObjectByValue(authorizations, "element", "Maps");

  const selectedSensorID = sensors.find((sensor) => sensor.SensorID === state?.SensorID)?.SensorID;

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const { data: alertProgramsData } = useFindProgramsQuery();

  const {
    data: locationsData,
    isFetching: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const [getSubstationData, { isSuccess: subDataSuccess }] = useLazyGetSensorConfigQuery();

  useEffect(() => {
    setTimeout(() => {
      setTempSensors(
        sensors.filter((sensor) => {
          return selectedStatus.includes(sensor.IsActive ? "Active" : "Inactive");
        })
      );
    }, 0);
  }, [sensors]);

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  useEffect(() => {
    if (
      selectedEquipmentTypes.length ||
      selectedLocations.length ||
      selectedSensors.length ||
      selectedAlarm.length ||
      selectedStatus.length ||
      searchText.length
    ) {
      let tempFilteredEquipments = sensors.filter((sensor) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = true,
          filter6 = true;

        const equipment = findObjectByValue(
          equipments,
          "EquipmentID",
          sensor.equipmentSensors[0]?.EquipmentID
        );

        var sensorLimitTemplateLabel =
          findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)?.label ||
          "Custom";

        if (selectedEquipmentTypes.length) {
          filter1 = selectedEquipmentTypes.some((typeId) => {
            return typeId == equipment?.EquipmentTypeID;
          });
        }
        if (selectedSensors.length) {
          filter2 = selectedSensors.some((typeId) => {
            return typeId == sensor?.SensorTypeID;
          });
        }
        if (selectedStatus.length) {
          filter3 = selectedStatus.some((item) => {
            return (
              (item == "Active" && sensor?.IsActive) || (item == "Inactive" && !sensor?.IsActive)
            );
          });
        }

        if (selectedAlarm.length) {
          filter4 = selectedAlarm.some((item) => {
            return (
              (item == "1" && sensor.LiveData && sensor?.LiveData?.State == 1 && !sensor?.LiveData?.Suppressed) ||
              (item == "2" && sensor?.LiveData?.State == 2 && !sensor?.LiveData?.Suppressed)
            );
          });
        }

        if (selectedLocations.length) {
          filter5 = selectedLocations.some((templateID) => {
            return templateID == sensor.SensorLimitTemplateID;
          });
        }
        if (searchText.length) {
          filter6 =
            sensor.SensorLabel.toLowerCase().includes(searchText.toLowerCase()) ||
            sensorLimitTemplateLabel.toLowerCase().includes(searchText.toLowerCase());
        }

        return filter1 && filter2 && filter3 && filter4 && filter5 && filter6;
      });

      setTempSensors(tempFilteredEquipments);
    } else {
      setTempSensors(sensors);
    }
  }, [
    selectedEquipmentTypes,
    selectedSensors,
    selectedLocations,
    selectedAlarm,
    selectedStatus,
    searchText,
  ]);

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const loadLocations = async () => {
    if (locationsSuccess)
      setAllLocations(
        locationsData.map((e) => {
          return { value: e.LocationID, label: e.LocationName };
        })
      );
  };

  const getAlertProgramsBySensor = (sensor, alertPrograms) => {
    const sensorId = sensor.SensorID;

    const assignedPrograms = alertPrograms
      .filter((program) => program.ProgramSensors.some((ps) => ps.SensorID === sensorId))
      .map((program) => program.Name);

    return assignedPrograms;
  };

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setStatusFilterCount(statusFilterCount + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setStatusFilterCount(statusFilterCount - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    console.log("updatedList: " + updatedList)
    setSelectedAlarm(updatedList);
  };

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const clearFilter = () => {
    setSelectedEquipmentTypes([]);
    setSelectedSensors([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedStatus([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setStatusFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
  };
  const loadSubstations = () => {
    if (substationsSuccess) setSubstations(substationsData);
    if (isSubstationError) console.log(substationError);
  };

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  const columns = [
    {
      name: "SensorID",
      label: "SensorID",
      options: {
        display: false,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
    },
    {
      name: "SensorName",
      label: "Sensor",
      options: {
        display: true,
        searchable: true,
        sort: true,
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        display: true,
        searchable: true,
        sort: false,
      },
    },
    {
      name: "LowGroup",
      label: "Low",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "HighGroup",
      label: "High",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "Limit template",
      label: "Limit template",
      options: {
        display: true,
        searchable: true,
        sort: true,
      },
    },
    {
      name: "AlertProgram",
      label: "Alert program",
      options: {
        display: true,
        searchable: true,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: dataB }, { data: dataA }) => {
            const hasOneAlertProgramA = !!dataA?.props?.children?.props?.children?.[0].props.label;
            const hasOneAlertProgramB = !!dataB?.props?.children?.props?.children?.[0].props.label;
            const moreAlertProgramsLengthA =
              dataA?.props?.children?.props?.children?.[1]?.props?.title?.length;
            const moreAlertProgramsLengthB =
              dataB?.props?.children?.props?.children?.[1]?.props?.title?.length;

            const sortValueA = hasOneAlertProgramA ? -1 : 1;
            const sortValueB = hasOneAlertProgramB ? -1 : 1;

            if (sortValueA !== sortValueB) {
              return order === "asc" ? sortValueA - sortValueB : sortValueB - sortValueA;
            }

            if (!moreAlertProgramsLengthA && !moreAlertProgramsLengthB) {
              return 0;
            }

            if (moreAlertProgramsLengthA && !moreAlertProgramsLengthB) {
              return order === "asc" ? 1 : -1;
            }

            if (moreAlertProgramsLengthB && !moreAlertProgramsLengthA) {
              return order === "asc" ? -1 : 1;
            }

            if (moreAlertProgramsLengthA < moreAlertProgramsLengthB) {
              return order === "asc" ? 1 : -1;
            }

            if (moreAlertProgramsLengthA > moreAlertProgramsLengthB) {
              return order === "asc" ? -1 : 1;
            }
          },
      },
    },
    {
      name: "Equipment",
      label: "Equipment",
      options: {
        display: true,
        searchable: true,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            if (!userListOne || !userListTwo) {
              return 0;
            }

            var valueOne = userListOne.props?.children?.toUpperCase() || "";
            var valueTwo = userListTwo.props?.children?.toUpperCase() || "";

            return order === "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "Substation",
      label: "Substation",
      options: {
        display: true,
        searchable: true,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            if (!userListOne || !userListTwo) {
              return 0;
            }

            var valueOne = userListOne.props?.children?.toUpperCase() || "";
            var valueTwo = userListTwo.props?.children?.toUpperCase() || "";

            return order === "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "Port",
      label: "Port",
      options: {
        display: true,
        searchable: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const customOptions = {
    download: true,
    sortOrder: {
      name: "SensorName",
      direction: "asc",
    },
    selectableRows: "none",
    setRowProps: (row, _, rowIndex) => {
      if (row[0] === selectedSensorID) {
        handleSelectRowIndex(rowIndex);
        return {
          style: { "background-color": "#e0f5fd" },
          "data-rowindex": rowIndex,
        };
      } else {
        return {
          "data-rowindex": rowIndex,
        };
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
     
      delete columns[1];
      delete columns[11];
      data.map((row) => {
        delete row.data[1];
        delete row.data[11];
        let value =
          row.data[3]?.props?.LiveData?.Current || row.data[3]?.props?.LiveData?.CurrentState;
        row.data[3] = value;
        row.data[4] = `${row?.data[4]?.props?.children?.[0]?.props?.title?.replaceAll('\n', '')}, ${row?.data[4]?.props?.children?.[1]?.props?.title?.replaceAll('\n', '')} `
        row.data[5] = `${row?.data[5]?.props?.children?.[0]?.props?.title?.replaceAll('\n', '')}, ${row?.data[5]?.props?.children?.[1]?.props?.title?.replaceAll('\n', '')} `
        value = row?.data?.[7]?.props?.children?.props?.children?.map(val => val?.props?.label || val?.props?.children?.props?.label) || ""
        row.data[7] = value;
        value = row.data[8]?.props?.children?.props?.Component?.props?.children
        ? row.data[8]?.props?.children?.props?.Component?.props?.children
        : typeof row.data[8]?.props?.children === "object"
        ? ""
        : row.data[8]?.props?.children;
        row.data[8] = value
        value = row.data[9]?.props?.children?.props?.Component?.props?.children
          ? row.data[9]?.props?.children?.props?.Component?.props?.children
          : typeof row.data[9]?.props?.children === "object"
          ? ""
          : row.data[9]?.props?.children;
          row.data[9] = value
        
        value = row.data[10]?.props?.children?.props?.Component?.props?.children
          ? row.data[10]?.props?.children?.props?.Component?.props?.children
          : typeof row.data[10]?.props?.children === "object"
          ? ""
          : row.data[10]?.props?.children;
        
      });
      return buildHead(columns) + buildBody(data);
    },
  };

  const handleNavigate = (id) => {
    let data = {};
    if (id.SubstationID) {
      data["SubstationID"] = id.SubstationID;
    } else {
      data["EquipmentID"] = id.EquipmentID;
    }
    if (permissionAllowed.length > 0)
      navigate("/setup/maps", {
        state: {
          ID: id?.LocationID,
          device: data,
        },
      });
  };

  const fetchSubstationsConfigs = async (e) => {
    if (e !== "") {
      await getSubstationData(e)
        .unwrap()
        .then((req) => {
          return req.success.data;
        })
        .catch((err) => {
          console.log("🚀 ~ file: index.js ~ line 406 ~ fetchSubstationsConfigs ~ err", err);
        });
    } else {
      return;
    }
  };

  return (
    <Box>
      {" "}
      <TableComponent
        zebraStyle={true}
        tableLook={tableLook}
        customOptions={customOptions}
        cellPaddingLeft="10px"
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "110px",
              }}
            >
              <b>{tempSensors.length} sensors</b>
            </p>
            <Box sx={{ marginRight: "16px" }}>
              <SensorFilter
                locations={allLocations}
                sensorTypes={sensorTypes}
                equipments={equipmentTypes}
                handleSelectEquipmentType={handleSelectEquipmentType}
                handleSelectSensorType={handleSelectSensorType}
                handleSelectLocation={handleSelectLocation}
                handleAlarmSelect={handleAlarmSelect}
                selectedEquipmentTypes={selectedEquipmentTypes}
                selectedSensors={selectedSensors}
                selectedAlarm={selectedAlarm}
                selectedLocations={selectedLocations}
                equipmentFilterCount={equipmentFilterCount}
                sensorTypeFilterCount={sensorTypeFilterCount}
                locationFilterCount={locationFilterCount}
                alarmFilterCount={alarmFilterCount}
                statusFilterCount={statusFilterCount}
                clearFilter={clearFilter}
                handleStatusList={handleStatusList}
                selectedStatus={selectedStatus}
                sensorLimitTemplates={sensorLimitTemplates}
              />
            </Box>
            <SearchComponent
              width="320px"
              placeholder="Search sensors...."
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Box>
        }
        columns={columns}
        customToolbar={
          <>
            {allowedEdit && (
              <>
                <TableHeaderAddButton
                  onClick={() => toggleLimitsTemplateDrawer(true)}
                  label=" Limit Templates"
                  width="190px"
                />{" "}
                <TableHeaderAddButton
                  onClick={() => toggleDrawer(true)}
                  label="Sensor"
                  width="104px"
                />
              </>
            )}
          </>
        }
        searchText={searchText}
        data={tempSensors?.map((row) => {
          const equipment = findObjectByValue(
            equipments,
            "EquipmentID",
            row?.equipmentSensors[0]?.EquipmentID
          );
          const sensorColor = getColorCodingStatusSingleSensor(row?.LiveData, row.IsActive);
          const [port, ...extractedSerial] = row?.PortSerial.split("-");
          const serialNumber = extractedSerial.join("-");
          const substation = findObjectByValue(substations, "SerialNumber", serialNumber);
          const sensorType = findObjectByValue(sensorTypes, "value", row.SensorTypeID);

          const sensorLimitTemplate =
            row.SensorLimitTemplateID !== "111a11b1-a111-4111-aa11-1eb1dbd1a11a"
              ? findObjectByValue(sensorLimitTemplates, "value", row.SensorLimitTemplateID)
              : undefined;

          const alertPrograms = alertProgramsData
            ? getAlertProgramsBySensor(row, alertProgramsData)
            : [];

          return [
            row.SensorID,
            <span style={{ position: "relative" }}>
              <div
                className={` ${tableLook == "dense" ? "equipmentDot" : "equipmentDotRelaxed"} ${
                  sensorColor === "error"
                    ? "equipmentDotFault"
                    : sensorColor === "inactive"
                    ? "equipmentDotInactive"
                    : sensorColor === "suppressed"
                    ? "equipmentDotSuppressed"
                    : sensorColor === "warning"
                    ? "equipmentDotWarning"
                    : sensorColor === "prior"
                    ? "equipmentDotPrior"
                    : "equipmentDotSuccess"
                }`}
                style={{
                  height: tableLook == "dense" ? "10px" : "13px",
                  width: tableLook == "dense" ? "10px" : "13px",
                }}
              ></div>
              <i
                style={{
                  fontSize: tableLook == "dense" ? "15px" : "30px",
                  background: "#ECEDEE",
                  color: " #9CA1A6",
                  padding: "6px",
                  borderRadius: "5px",
                  fontWeight: "300",
                }}
                className="fa-regular fa-sensor "
              ></i>
            </span>,
            row.SensorLabel,
            <SensorLabel
              LiveData={row.LiveData}
              sensorType={sensorType}
              title={row.SensorLabel}
            />,
            <td>
              <ToolTipComponentWrapper
                title={`Low warning limit: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitWarning"),
                  "limit"
                )}\nDelay: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitWarning"),
                  "delay"
                )}`}
              >
                <p>W: {getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitWarning")}</p>
              </ToolTipComponentWrapper>
              <ToolTipComponentWrapper
                title={`Low alarm limit: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
                  "limit"
                )}\nDelay: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
                  "delay"
                )}`}
              >
                <p>A: {getLimitValue(row, sensorLimitTemplate, sensorType, "LowLimitAlarm")}</p>
              </ToolTipComponentWrapper>
            </td>,
            <td>
              <ToolTipComponentWrapper
                title={`High warning limit: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "HighLimitWarning"),
                  "limit"
                )}\nDelay: ${extractValue(
                  getLimitValue(row, sensorLimitTemplate, sensorType, "HighLimitWarning"),
                  "delay"
                )}`}
              >
                <p>W: {getLimitValue(row, sensorLimitTemplate, sensorType, "HighLimitWarning")}</p>
              </ToolTipComponentWrapper>
              <ToolTipComponentWrapper
                title={`High alarm limit: ${extractValue(
                  getLimitValue(
                    row,
                    sensorLimitTemplate,
                    sensorType,
                    row.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                  ),
                  "limit"
                )}\nDelay: ${extractValue(
                  getLimitValue(
                    row,
                    sensorLimitTemplate,
                    sensorType,
                    row.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                  ),
                  "delay"
                )}`}
              >
                <p>
                  A:{" "}
                  {getLimitValue(
                    row,
                    sensorLimitTemplate,
                    sensorType,
                    row.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                  )}
                </p>
              </ToolTipComponentWrapper>
            </td>,
            sensorLimitTemplate?.label || "Custom",
            !!alertPrograms.length && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <>
                  <LabelComponent
                    label={alertPrograms[0]}
                    state="success"
                  />
                  {!!alertPrograms.slice(1).length && (
                    <ToolTipComponentWrapper title={alertPrograms.slice(1).map((p) => `${p}\n`)}>
                      <LabelComponent
                        label={`${alertPrograms.length - 1} more`}
                        state="success"
                      />
                    </ToolTipComponentWrapper>
                  )}
                </>
              </div>
            ),
            equipment && (
              <Link
                to={"/setup/equipment"}
                state={{ EquipmentID: equipment.EquipmentID }}
                className="device-link"
              >
                {equipment.EquipmentName}
              </Link>
            ),
            substation && (
              <Link
                to={"/setup/substations"}
                state={{ SubstationID: substation.SubstationID }}
                className="device-link"
              >
                {substation.Label}
              </Link>
            ),
            row.SensorTypeLabel == "Analog" ? `A${port}` : `D${port}`,
            <span style={{ display: "flex", alignItems: "center" }}>
              {allowedEdit && (
                <Box
                  sx={{
                    paddingRight: "24px",
                  }}
                >
                  <ToolTipComponent
                    title="Edit sensor"
                    placement="bottom-end"
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                        }}
                      >
                        <EditSensor
                          sensor={row}
                          handleEdit={() => handleEdit(row)}
                        />
                      </Box>
                    }
                  />
                </Box>
              )}
              <SensorContextMenu
                sensor={{ ...row, LiveData: row.LiveData }}
                setAllSelectedSensors={setAllSelectedSensors}
                setUnSuppressAction={setUnSuppressAction}
                handleAlertSuppression={handleAlertSuppression}
                alertSuppression
                unsuppressAlarm
                viewReport
                auditTrail
              />
            </span>,
          ];
        })}
        tableBodyHeight="calc(100vh - 253px)"
      />
    </Box>
  );
};

const ToolTipComponentWrapper = ({ title, children }) => (
  <ToolTipComponent
    title={title}
    Component={
      <Box
        sx={{
          width: "fit-content",
          cursor: "pointer",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </Box>
    }
  />
);

export default SensorTable;
