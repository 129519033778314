import { apiSlice } from "../API";

export const UserActionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserActions: builder.query({
      query: () => "/userAction",
      providesTags: ["UserAction", "Program"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item);
      },
    }),
    addUserAction: builder.mutation({
      query: (userAction) => ({
        url: "/userAction",
        method: "POST",
        body: userAction,
      }),
      invalidatesTags: ["UserAction"],
    }),
    editUserAction: builder.mutation({
      query: ({ id, userAction }) => ({
        url: `/userAction/${id}`,
        method: "PUT",
        body: userAction,
      }),
      invalidatesTags: ["UserAction"],
    }),
    deleteUserAction: builder.mutation({
      query: (id) => ({
        url: `/userAction/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserAction"],
    }),
  }),
});

export const {
  useFindUserActionsQuery,
  useAddUserActionMutation,
  useEditUserActionMutation,
  useDeleteUserActionMutation,
} = UserActionsSlice;
