import React, { useEffect, useState } from "react";
import { deepCopy, filterObjectByValue } from "../../Utils/Tools";

import { Box, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { lightGrey } from "../../Styles/theme/colors";
import TableComponent from "../BaseComponents/Table";
import ToolTipComponent from "../BaseComponents/Tooltip";
//import IconButtonComponent from "../BaseComponents/IconButton";
import AuditTrailFilter from "../BaseComponents/Filter/AuditTrailFilter";
import DateTimePickerComponent from "../BaseComponents/DateTimePicker";
import DropDownComponent from "../BaseComponents/DropDown";
import moment from "moment";
require("moment-timezone");

const AuditTrailTable = (props) => {
  let {
    auth,
    users,
    logs,
    equipments,
    sensors,
    substations,
    allowedEdit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateFieldsEnabled,
    setDateFieldsEnabled,
    timeStampSelected,
    setTimeStampSelected,
    startDateDisplay,
    endDateDisplay,
    setEndDateDisplay,
    setStartDateDisplay,
    CustomRangeCalled,
    entityType,
  } = props;
  const theme = useTheme();

  const [tempLogs, setTempLogs] = useState(logs);
  const [tableLook, setTableLook] = useState("dense");
  const [customQuerySubmission, setCustomQeurySubmission] = useState(true);
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [categoryFilterCount, setCategoryFilterCount] = useState(0);
  const [typeFilterCount, setTypeFilterCount] = useState(0);
  const [subTypeFilterCount, setSubTypeFilterCount] = useState(0);
  const [userFilterCount, setUserFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorFilterCount, setSensorFilterCount] = useState(0);
  const [substationFilterCount, setSubstationFilterCount] = useState(0);
  const [selectedEventSubTypes, setSelectedEventSubTypes] = useState([]);
  const [logUsers, setLogUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedSubstations, setSelectedSubstations] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    setLogUsers(filterObjectByValue(users, "CustomerID", auth.SelectedCustomer));
  };

  const catagories = {
    Authorization: {
      Login: {
        EventSubTypes: [],
      },
      Logout: {
        EventSubTypes: [],
      },
      "Login attempt": {
        EventSubTypes: [],
      },
      "Electronic Signature": {
        EventSubTypes: [],
      },
    },
    Configuration: {
      "State change": {
        EventSubTypes: ["Alert", "Archived", "Restored"],
      },
      Create: {
        EventSubTypes: ["Alert", "User", "Sensor"],
      },
      Update: {
        EventSubTypes: ["Alert", "User", "Sensor"],
      },
      "Graph Created": {
        EventSubTypes: [],
      },
      "Report Created": {
        EventSubTypes: [],
      },
    },
    Alarm: {
      Suppression: {
        EventSubTypes: ["Warning Suppression", "Alarm Suppression"],
      },
      "Suppression Removed": {
        EventSubTypes: ["Warning Suppression Removed", "Alarm Suppression Removed"],
      },
      "Alarm Reset": {
        EventSubTypes: [],
      },
      Notification: {
        EventSubTypes: ["SMS", "Email", "Physical Relay"],
      },
    },
    System: {
      "Substation State Change": {
        EventSubTypes: ["Registered", "Disconnected", "Initialized"],
      },
      "Sensor State Change": {
        EventSubTypes: ["Warning", "Alarm"],
      },
      "Notification failed": {
        EventSubTypes: ["Sensor"],
      },
      "Notification sent": {
        EventSubTypes: ["Sensor"],
      },
    },
  };

  const [categoryTypes, setCategoryTypes] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [filteredEventTypes, setFilteredEventTypes] = useState([]);
  useEffect(() => {
    setFilteredEventTypes(eventTypes);
  }, [eventTypes]);

  const [eventSubTypes, setEventSubTypes] = useState([]);

  const [timeStamps, setTimeStamps] = useState([
    { value: "1", label: "Last 24 hours" },
    { value: "2", label: "Last week" },
    { value: "3", label: "Last month" },
    { value: "4", label: "Last 3 months" },
    { value: "5", label: "Last year" },
    { value: "6", label: "Custom range" },
  ]);

  const [displayTenantToast, setDisplayTenantToast] = useState(false);

  useEffect(() => {
    let eventKeys = [];
    let evetSubTypeKeys = [];
    let catagoryKeys = Object.keys(catagories).map((key) => {
      return { value: key, label: key };
    });

    catagoryKeys.map((key) => {
      eventKeys = eventKeys.concat(
        Object.keys(catagories[key.value]).map((eventKey) => ({
          label: eventKey,
          value: eventKey,
        }))
      );
    });

    catagoryKeys.map((key) => {
      eventKeys.map((eKey) => {
        evetSubTypeKeys = evetSubTypeKeys.concat(
          catagories[key.value][eKey.value]?.EventSubTypes?.map((subKey) => ({
            value: subKey,
            label: subKey,
          })) || []
        );
      });
    });

    setEventTypes(eventKeys);
    setCategoryTypes(catagoryKeys);
    setEventSubTypes(
      evetSubTypeKeys.filter(
        (obj, index, self) => index === self.findIndex((o) => o.value === obj.value)
      )
    );
  }, []);

  useEffect(() => {
    let eventKeys = [];
    let evetSubTypeKeys = [];
    let catagoryKeys = [];
    let filteredEventKeys = [];

    if (selectedCategoryTypes.length) {
      catagoryKeys = selectedCategoryTypes.map((key) => ({
        value: key,
        label: key,
      }));
    } else {
      catagoryKeys = Object.keys(catagories).map((key) => ({
        value: key,
        label: key,
      }));
    }
    catagoryKeys.map((key) => {
      eventKeys = eventKeys.concat(
        Object.keys(catagories[key.value]).map((eventKey) => ({
          label: eventKey,
          value: eventKey,
        }))
      );
    });

    filteredEventKeys = eventKeys;
    if (selectedEventTypes.length > 0) {
      filteredEventKeys = eventKeys.filter((eKey) => selectedEventTypes.includes(eKey.value));
    }

    catagoryKeys.map((key) => {
      filteredEventKeys.map((eKey) => {
        evetSubTypeKeys = evetSubTypeKeys.concat(
          catagories[key.value][eKey.value]?.EventSubTypes?.map((subKey) => ({
            value: subKey,
            label: subKey,
          })) || []
        );
      });
    });
    setEventTypes(eventKeys);
    setEventSubTypes(
      evetSubTypeKeys.filter(
        (obj, index, self) => index === self.findIndex((objTemp) => objTemp.value === obj.value)
      )
    );
  }, [selectedEventTypes, selectedCategoryTypes]);

  const columns = [
    {
      name: "Timestamp",
      label: "Timestamp",
      options: {
        searchable: true,
        sort: true,
        download: true,
        paddingLeft: "88px",
        sortCompare:
          (order) =>
          ({ data: dataListOne }, { data: dataListTwo }) => {
            var valueOne = moment(dataListOne);
            var valueTwo = moment(dataListTwo);
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "User",
      label: "User",
      options: {
        searchable: true,
        sort: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: tableLook === "relax" ? "10px 0px 10px 0px" : "0px 0px 0px 0px",
              }}
            >
              <div>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600 }}
                >
                  {value.user}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ color: lightGrey["900"] }}
                >
                  {value.email}
                </Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "IpAddress",
      label: "IP address",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Category",
      label: "Category",
      options: {
        searchable: false,
        sort: false,
        download: true,
      },
    },
    {
      name: "Type",
      label: "Type",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Subtype",
      label: "Subtype",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Attribute",
      label: "Attribute",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "PreviousValue",
      label: "Previous value",
      options: {
        searchable: true,
        sort: false,
        downaload: true,
      },
    },
    {
      name: "NewValue",
      label: "New value",
      options: {
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Reason",
      label: "Reason",
      options: {
        searchable: true,
        sort: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ToolTipComponent
                title={value}
                value={value}
                placement="bottom-start"
                Component={
                  <p
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "200px",
                    }}
                  >
                    {value}
                  </p>
                }
              />
            </>
          );
        },
      },
    },
  ];

  const customOptions = {
    selectableRows: "none",
    expandableRows: false,
    downloadAbleFileName: "Audit Trail",
    pdfFontSize: 7,
    download: false,
    sortOrder: {
      name: "Timestamp",
      direction: "desc",
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((row) => {
        let value = row.data[1]?.user;
        row.data[1] = value;
      });
      return buildHead(columns) + buildBody(data);
    },
    customSearch: (searchQuery, currentRow, columns) => {
      if (
        currentRow[1].tenant.CustomerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[2]?.user.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[2]?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        currentRow[3].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        currentRow[4].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        currentRow[5].props.Component.props.children.props.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
        return true;
      return false;
    },
  };

  useEffect(() => {
    if (
      selectedCategoryTypes.length ||
      selectedEventTypes.length ||
      selectedEventSubTypes.length ||
      selectedUsers.length ||
      selectedEquipments.length ||
      selectedSubstations.length ||
      selectedSensors.length
    ) {
      let filteredLogs = logs.filter((log) => {
        let filter0 = false,
          filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = false,
          filter6 = false,
          filter7 = false;
        if (selectedCategoryTypes.length) {
          filter1 = selectedCategoryTypes.some((selectedCategoryType) => {
            return selectedCategoryType === log.Category;
          });
          filter0 = filter2;
        }
        if (selectedEventTypes.length) {
          filter2 = selectedEventTypes.some((selectedEventType) => {
            console.log(selectedEventType, log.Type, selectedEventType === log.Type);
            return selectedEventType === log.Type;
          });
          filter0 = filter2;
        }
        if (selectedEventSubTypes.length) {
          filter3 = selectedEventSubTypes.some((selectedEventSubType) => {
            return log?.Subtype?.includes(selectedEventSubType) || false;
          });
          filter0 = filter3;
        }
        if (selectedUsers.length) {
          filter4 = selectedUsers.some((selectedUser) => {
            return selectedUser === log.Email;
          });
          filter0 = filter4;
        }
        if (selectedEquipments.length) {
          filter5 = selectedEquipments.some((selectedEquipment) => {
            return selectedEquipment === log.ID && log.Subtype === "Equipment";
          });
        }
        if (selectedSensors.length) {
          filter6 = selectedSensors.some((selectedSensor) => {
            return selectedSensor === log.ID && log.Subtype === "Sensor";
          });
        }

        if (selectedSubstations.length) {
          filter7 = selectedSubstations.some((selectedSubstation) => {
            return (
              substations.find((sub) => sub.SubstationID == selectedSubstation)?.SubstationSerail ==
                log.SubstationSerail && log.Subtype === "Substation"
            );
          });
        }

        return (
          filter5 || filter6 || filter7 || (filter1 && filter2 && filter3 && filter4 && filter0)
        );
      });

      setTempLogs(filteredLogs);
    } else {
      setTempLogs(deepCopy(logs));
    }
  }, [
    selectedCategoryTypes,
    selectedEventTypes,
    selectedEventSubTypes,
    selectedUsers,
    selectedSubstations,
    selectedEquipments,
    selectedSensors,
  ]);

  const clearFilter = () => {
    setSelectedCategoryTypes([]);
    setSelectedEventSubTypes([]);
    setSelectedEventTypes([]);
    setSelectedUsers([]);
    setSelectedSubstations([]);
    setSelectedEquipments([]);
    setSelectedSensors([]);
    setTypeFilterCount(0);
    setCategoryFilterCount(0);
    setSubTypeFilterCount(0);
    setUserFilterCount(0);
    setSubstationFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorFilterCount(0);
  };

  const handleSelectCategoryType = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryFilterCount(event.target.value.length);
    setSelectedCategoryTypes(typeof value === "string" ? value?.split(",") : value);
    setSelectedEventTypes([]);
  };

  const handleSelectEventSubType = (event) => {
    const {
      target: { value },
    } = event;
    setSubTypeFilterCount(event.target.value.length);
    setSelectedEventSubTypes(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectEventType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEventSubTypes([]);
    setTypeFilterCount(event.target.value.length);
    setSelectedEventTypes(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectedUsers = (event) => {
    const {
      target: { value },
    } = event;
    setUserFilterCount(event.target.value.length);
    setSelectedUsers(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectedEquipments = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipments(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectedSensors = (event) => {
    const {
      target: { value },
    } = event;
    setSensorFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectedSubstations = (event) => {
    const {
      target: { value },
    } = event;
    setSubstationFilterCount(event.target.value.length);
    setSelectedSubstations(typeof value === "string" ? value?.split(",") : value);
  };

  useEffect(() => {
    if (!displayTenantToast) return;
    const timeout = setTimeout(() => {
      setDisplayTenantToast(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [displayTenantToast]);

  useEffect(() => {
    setTempLogs(deepCopy(logs));
  }, [logs]);
  useEffect(() => {
    if (timeStampSelected === "6") {
      setDateFieldsEnabled(true);
      setCustomQeurySubmission(true);
    }
  }, [timeStampSelected]);

  useEffect(() => {
    if (Object.keys(entityType).length) {
      setSelectedEquipments([]);
      setSelectedSensors([]);
      setSelectedSubstations([]);
      if (entityType.Type === "Equipment") {
        setSelectedEquipments([entityType.ID]);
      }
      if (entityType.Type === "Substation") {
        setSelectedSubstations([entityType.ID]);
      }
      if (entityType.Type === "Sensor") {
        setSelectedSensors([entityType.ID]);
      }
    }
  }, [entityType]);
  // useEffect(() => {
  //   if (selectedCategoryTypes.length || selectedEventTypes.length) {
  //     let filteredLogs = tempLogs.filter((log) => {
  //       let filter1 = true,
  //         filter2 = true;
  //       if (selectedCategoryTypes.length) {
  //         filter1 = selectedCategoryTypes.some((selectedCategoryType) => {
  //           return selectedCategoryType === log.Category;
  //         });
  //       }
  //       if (selectedEventTypes.length) {
  //         filter2 = selectedEventTypes.some((selectedEventType) => {
  //           return selectedEventType === log.Type;
  //         });
  //       }
  //       return filter1 && filter2;
  //     });
  //     setTempLogs(filteredLogs);
  //   } else {
  //     setTempLogs(deepCopy(logs));
  //   }
  // }, [selectedCategoryTypes, selectedEventTypes]);

  const handleStartDateChange = (newValue) => {
    setStartDateDisplay(moment(newValue).valueOf());
  };
  const handleEndDateChange = (newValue) => {
    setEndDateDisplay(moment(newValue).valueOf());
  };

  const mappedReportData = () => {
    return tempLogs.map((log) => {
      return [
        moment(log.Time).utc().format("DD-MMM-YYYY, HH:mm [UTC]"),
        {
          user: log.FirstName + " " + log.LastName,
          email: log.Email,
        },
        log.IP,
        log.Category,
        log.Type,
        log.Subtype,
        log.Name,
        log.Field,
        log.OldValue,
        log.NewValue,
        log.reason,
      ];
    });
  };

  const getReportColumns = () => {
    return columns.map((col) => col.label);
  };

  const getReportData = () => {
    return mappedReportData().map((d) => {
      console.log(d);
      if (!d[1]) {
        d[1] = `System\nsystem@system.com`;
      } else {
        d[1] = `${d[1].user}\n${d[1].email}`;
      }
      return d;
    });
  };

  return (
    <>
      <TableComponent
        zebraStyle={false}
        tableLook={tableLook}
        customOptions={customOptions}
        pagination={true}
        rowsPerPage={250}
        rowsPerPageOptions={[
          { label: "1000", value: 0 },
          { label: "500", value: 1 },
          { label: "250", value: 2 },
        ]}
        cellPaddingLeft="24px"
        textAlign={false}
        background="white"
        loading
        height="89vh"
        borderRadius="4px"
        mt="0"
        downloadable={true}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "110px",
                marginRight: "0px",
              }}
            >
              <b>
                {tempLogs.length} {logs.length <= 1 ? " event" : " events"}
              </b>
            </p>
            <Box sx={{ mr: "16px" }}>
              <AuditTrailFilter
                categoryTypes={categoryTypes}
                eventTypes={eventTypes}
                handleSelectCategoryType={handleSelectCategoryType}
                handleSelectEventType={handleSelectEventType}
                selectedCategoryTypes={selectedCategoryTypes}
                selectedEventSubTypes={selectedEventSubTypes}
                eventSubTypes={eventSubTypes}
                handleSelectEventSubType={handleSelectEventSubType}
                selectedEventTypes={selectedEventTypes}
                categoryFilterCount={categoryFilterCount}
                typeFilterCount={typeFilterCount}
                subTypeFilterCount={subTypeFilterCount}
                userFilterCount={userFilterCount}
                equipmentFilterCount={equipmentFilterCount}
                sensorFilterCount={sensorFilterCount}
                substationFilterCount={substationFilterCount}
                users={logUsers.map((user) => ({
                  value: user.SNSEMail,
                  label: `${user.FirstName} ${user.LastName}`,
                }))}
                selectedUsers={selectedUsers}
                handleSelectedUsers={handleSelectedUsers}
                equipments={equipments}
                selectedEquipments={selectedEquipments}
                handleSelectedEquipments={handleSelectedEquipments}
                sensors={sensors.map((sensor) => ({
                  value: sensor.SensorID,
                  label: sensor.SensorLabel,
                }))}
                selectedSensors={selectedSensors}
                handleSelectedSensors={handleSelectedSensors}
                substations={substations}
                selectedSubstations={selectedSubstations}
                handleSelectedSubstations={handleSelectedSubstations}
                clearFilter={clearFilter}
                entityType={entityType}
              />
            </Box>
            <DropDownComponent
              padding={false}
              width="248px"
              options={timeStamps?.map((e) => {
                return e;
              })}
              handleChange={(e) => [setTimeStampSelected(e.value)]}
              value={timeStamps.find((el) => el.value === timeStampSelected) || ""}
            />
            <Box sx={{ display: "flex" }}>
              <Box sx={{ margin: "0.75em 0.25em 0.75em 1em", width: "50%" }}>
                <DateTimePickerComponent
                  label=""
                  value={startDateDisplay}
                  onChange={handleStartDateChange}
                  disabled={!dateFieldsEnabled}
                />
              </Box>
              <Box sx={{ margin: "0.75em 0.25em 0.75em 1em", width: "50%" }}>
                <DateTimePickerComponent
                  label=""
                  value={endDateDisplay}
                  onChange={handleEndDateChange}
                  disabled={!dateFieldsEnabled}
                />
              </Box>
            </Box>
            {dateFieldsEnabled && (
              <div>
                <button
                  onClick={() => CustomRangeCalled()}
                  style={{
                    color: "#17455E",
                    border: "none",
                    fontSize: "16px",
                    lineHeight: "19px",
                    fontWeight: "500",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  APPLY
                </button>
              </div>
            )}
          </Box>
        }
        columns={columns}
        getReportColumns={getReportColumns}
        data={tempLogs.map((log) => {
          return [
            moment(log.Time).utc().format("DD-MMM-YYYY, HH:mm [UTC]"),
            {
              user: log.FirstName + " " + log.LastName,
              email: log.Email,
            },
            log.IP,
            log.Category,
            log.Type,
            log.Subtype,
            log.Name,
            log.Field,
            log.OldValue,
            log.NewValue,
            log.reason,
          ];
        })}
        getReportData={getReportData}
        tableBodyHeight="calc(100vh - 253px)"
      />
    </>
  );
};

export default AuditTrailTable;
