import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxComponent from "../Checkbox";
import ToolTipComponent from "../Tooltip";
import Label from "../Label";
import MultipleSelectCheckmarks from "../MultiSelect";
import ListItemText from "@mui/material/ListItemText";
import { components } from "react-select";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import CustomTimeZone from "../../BaseComponents/TimeZone";
import IconButtonComponent from "../IconButton";

const MultiValueLabel = (props) => {
  return <components.MultiValueLabel {...props}>{props.children},</components.MultiValueLabel>;
};

const MultiValueRemove = (props) => {
  return <components.MultiValueRemove {...props}>,</components.MultiValueRemove>;
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div>{props.children}</div>
    </components.MenuList>
  );
};

export default function UserFilter(props) {
  const {
    authorizationGroups,
    handlePermissionList,
    selectedRoles,
    selectedPrefrences,
    selectedStatus,
    handlePreferenceList,
    handleStatusList,
    sensorAlertTypes,
    // displayCustomer,
  } = props;
  const {
    handleSensorAlertTypes,
    selectedSensorAlertTypes,
    selectedTimezone,
    setSelectedTimezone,
    handleTimezoneChange,
    handleChange,
  } = props;
  const { personName, setPersonName, Languages, selectUserGroups, groups, setGroups, userGroups } =
    props;
  const {
    numberOfFilter,
    userGroupFilterCount,
    languageFilterCount,
    timeZoneFilterCount,
    clearFilter,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [timezone, setTimezone] = React.useState(["Pacific/Niue", "Pacific/Pago_Pago"]);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            numberOfFilter + userGroupFilterCount + languageFilterCount + timeZoneFilterCount > 0
              ? "Filters (" +
                String(
                  numberOfFilter + userGroupFilterCount + languageFilterCount + timeZoneFilterCount
                ) +
                " applied)"
              : "Filter"
          } `}
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {numberOfFilter +
                      userGroupFilterCount +
                      languageFilterCount +
                      timeZoneFilterCount >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          numberOfFilter +
                          userGroupFilterCount +
                          languageFilterCount +
                          timeZoneFilterCount
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            minHeight: "400px",
            overflow: "auto",
            padding: "16px",
          }}
        >
          {/* Disabling this for now as confirmation is required how to execute this accurately  */}
          {/* {displayCustomer && (
            <div>
              <div>
                <p
                  style={{
                    textAlign: "left",
                    font: "normal normal normal 12px/16px Roboto",
                    color: "#9CA1A6",
                  }}
                >
                  User status
                </p>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <Grid
                      item
                      columns={2}
                      sx={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <CheckBoxComponent
                              checked={
                                selectedStatus.findIndex(
                                  (item) => item == "Active"
                                ) > -1
                              }
                              onChange={handleStatusList}
                              value="Active"
                              name="Active"
                              Label="Restore"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleStatusList}
                              value="Inactive"
                              name="Inactive"
                              checked={
                                selectedStatus.findIndex(
                                  (item) => item == "Inactive"
                                ) > -1
                              }
                              Label="Archive"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          )} */}
          <div>
            <div style={{ paddingTop: "0px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Permissions role
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Grid
                    item
                    columns={2}
                    sx={{
                      gridTemplateColumns: "repeat(2, 1fr)",
                      display: "grid",
                      width: "100%",
                    }}
                  >
                    {authorizationGroups.map((group) => {
                      return (
                        <Box
                          sx={{
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <CheckBoxComponent
                                size="small"
                                onChange={handlePermissionList}
                                value={group.value}
                                checked={
                                  selectedRoles.findIndex((item) => item == group.value) > -1
                                }
                                name={group.value}
                                Label={group.label}
                              />
                            }
                          />
                          <ToolTipComponent
                            placement="bottom"
                            title={group.label}
                            Component={
                              <div
                                style={{
                                  marginLeft: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "fit-content",
                                }}
                              >
                                <i
                                  class="fa-regular fa-circle-info fa-xs"
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </div>
                            }
                          />
                        </Box>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
            </div>
          </div>

          <div>
            {" "}
            <div style={{ paddingTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#9CA1A6",
                }}
              >
                Communication preference
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Grid
                    item
                    columns={2}
                    sx={{
                      gridTemplateColumns: "repeat(2, 1fr)",
                      display: "grid",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CheckBoxComponent
                            Label="Email"
                            checked={selectedPrefrences.findIndex((item) => item == "email") > -1}
                            onChange={handlePreferenceList}
                            value="email"
                            name="email"
                          />
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CheckBoxComponent
                            size="small"
                            name="sms"
                            value="sms"
                            onChange={handlePreferenceList}
                            checked={selectedPrefrences.findIndex((item) => item == "sms") > -1}
                            Label="SMS"
                          />
                        }
                      />
                    </Box>
                  </Grid>
                </FormGroup>
              </FormControl>
            </div>
            {/* <div style={{ paddingTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Alarm availability (adjusted for local timezones)
              </p>
            </div> */}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <Grid
                    item
                    columns={2}
                    sx={{
                      gridTemplateColumns: "repeat(2, 1fr)",
                      display: "grid",
                      width: "100%",
                    }}
                  >
                    {sensorAlertTypes.map((alertType) => {
                      return (
                        <Box
                          sx={{
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <CheckBoxComponent
                                size="small"
                                onChange={handleSensorAlertTypes}
                                value={alertType.value}
                                name={alertType.value}
                                checked={
                                  selectedSensorAlertTypes.findIndex(
                                    (item) => item == alertType.value
                                  ) > -1
                                }
                                Label={alertType.label}
                              />
                            }
                          />
                          <ToolTipComponent
                            placement="bottom"
                            title={alertType.Description}
                            Component={
                              <Box
                                sx={{
                                  marginLeft: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  class="fa-regular fa-circle-info fa-xs"
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </Box>
                            }
                          />
                        </Box>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
            </div> */}
            {/* <div style={{ marginBottom: '10px' }}>
              <div
                style={{
                  textAlign: 'left',
                  font: 'normal normal normal 12px/16px Roboto',
                  color: '#9CA1A6',
                  marginBottom: '4px',
                }}
              >
                Language(s)
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px',
                }}
              >
                <MultipleSelectCheckmarks
                  onChange={handleChange}
                  placeholder="Select language(s)"
                  personName={personName}
                  setPersonName={setPersonName}
                  data={Languages}
                >
                  {Languages.map((language) => (
                    <MenuItem key={language.value} value={language.value}>
                      <Checkbox
                        size="small"
                        sx={{
                          color: '#9CA1A6',
                          width: '24px',
                          height: '24px',
                          '&:hover': {
                            color: '#17455E',
                          },
                          '&:focus-visible': {
                            outline: '2px auto #7fd3f2',
                          },
                          '&.Mui-checked': {
                            color: '#0D2E42',
                          },
                        }}
                        checked={personName.indexOf(language.value) > -1}
                      />
                      <ListItemText primary={language.value} />
                    </MenuItem>
                  ))}
                </MultipleSelectCheckmarks>
              </div>
            </div> */}
            <div style={{ marginTop: "24px", width: "520px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                User group(s)
              </p>
            </div>
            <MultipleSelectCheckmarks
              onChange={selectUserGroups}
              placeholder="Select user group(s)"
              personName={groups}
              setPersonName={setGroups}
              Languages={userGroups}
              data={userGroups}
            >
              {userGroups.map((group) => (
                <MenuItem
                  key={group.value}
                  value={group.value}
                  className="menuItem"
                  sx={{
                    font: "normal normal medium 14px/20px Roboto",
                    color: "#222934",
                    "&:hover": {
                      background: "#E3E9EC 0% 0% no-repeat padding-box",
                      color: "#17455E",
                    },
                    "&$selected": {
                      // <-- mixing the two classes
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <CheckBoxComponent
                    className="checkBox"
                    checked={groups.indexOf(group.value) > -1}
                  />
                  <ListItemText primary={group.label} />
                </MenuItem>
              ))}
            </MultipleSelectCheckmarks>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
              >
                <div style={{ marginTop: "24px", width: "520px" }}>
                  <Label
                    sx={{
                      color: "#9CA1A6",
                    }}
                  >
                    Timezone(s)
                  </Label>
                  <CustomTimeZone
                    timezone={selectedTimezone}
                    setTimezone={setSelectedTimezone}
                    onChange={(event) => {
                      console.log(event);
                      setSelectedTimezone(event.target.value);
                      handleTimezoneChange(event.target.value.length);
                    }}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
