import { apiSlice } from "../API";

export const sensorGroupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorGroups: builder.query({
      query: () => "/sensorGroup",
      providesTags: ["SensorGroups"],
    }),
    addSensorGroup: builder.mutation({
      query(sensorGroup) {
        console.log(
          "🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice",
          sensorGroup
        );
        return {
          url: "/sensorGroup",
          method: "POST",
          body: sensorGroup,
        };
      },
      invalidatesTags: ["SensorGroups"],
    }),
    getSensorGroup: builder.query({
      query: (id) => ({
        url: `/sensorGroup/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["SensorGroups"],
    }),
  }),
});

export const { useFindSensorGroupsQuery, useAddSensorGroupMutation, useGetSensorGroupQuery } =
  sensorGroupsSlice;
