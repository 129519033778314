import { apiSlice } from "../API";

export const authorizationGroupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findAuthorizationGroups: builder.query({
      query: () => "/authorizationGroup",
      providesTags: ["AuthorizationGroup"],
    }),
    addAuthorizationGroup: builder.mutation({
      query(authorizationGroup) {
        console.log(
          "🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice",
          authorizationGroup
        );
        return {
          url: "/authorizationGroup",
          method: "POST",
          body: authorizationGroup,
        };
      },
      invalidatesTags: ["AuthorizationGroup"],
    }),
    editAuthorizationGroup: builder.mutation({
      query({ id, data }) {
        return {
          url: `/authorizationGroup/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["AuthorizationGroup"],
    }),
    getAuthorizationGroups: builder.query({
      query: () => "/authorizationGroup",
      providesTags: ["AuthorizationGroup"],
    }),
    getAuthorizationGroup: builder.query({
      query: (id) => ({
        url: "authorizationGroup",
        method: `/authorizationGroup/${id}`,
      }),
      invalidatesTags: ["AuthorizationGroup"],
    }),
  }),
});

export const {
  useFindAuthorizationGroupsQuery,
  useAddAuthorizationGroupMutation,
  useEditAuthorizationGroupMutation,
  useGetAuthorizationGroupsQuery,
  useGetAuthorizationGroupQuery,
} = authorizationGroupsSlice;
