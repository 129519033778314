import React from "react";
import { Box } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import DrawerComponent from "../BaseComponents/Drawer";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import ThemeButton from "@Components/Buttons";

export default function DeactivateDrawer({ ...props }) {
  const {
    drawer,
    toggleDrawer,
    title,
    heading,
    headingNote,
    edit,
    item,
    handleDelete,
    deactivateButtonTitle,
    deactivateButtonColor,
    closeToast,
    actionCalled,
  } = props;

  const useStyles = makeStyles({
    itemPadding: {
      paddingLeft: "24px !important",
    },
    paddingTop: {
      paddingTop: "24px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });

  const classes = useStyles();

  return (
    <DrawerComponent
      drawer={drawer}
      toggleDrawer={toggleDrawer}
      title={title}
      edit={edit}
      displayFooter={false}
      closeToast={closeToast}
      deactivateButtonColor={deactivateButtonColor}
    >
      <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
        <Box
          sx={{
            width: "530px",
            textAlign: "left",
            marginBottom: "15px",
            font: "normal normal normal 20px/24px Roboto",
          }}
        >
          {heading}{" "}
        </Box>
        {headingNote && (
          <p
            style={{
              width: "530px",
              textAlign: "left",
              marginBottom: "37px",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#222934",
            }}
          >
            {headingNote}{" "}
          </p>
        )}
        <Box sx={{ display: "flex" }}>
          <Box>
            <div
              style={{ display: "inline", marginRight: "25px" }}
              onClick={() => toggleDrawer(false)}
            >
              <OutlinedButton
                variant="outlined"
                color="primary"
                size="small"
                width="248px"
              >
                Cancel
              </OutlinedButton>
            </div>
          </Box>
          <Box sx={{ ml: 2 }}>
            <ThemeButton
              variant="contained"
              color={deactivateButtonColor}
              size="small"
              width="248px"
              onClick={handleDelete}
            >
              {deactivateButtonTitle}{" "}
            </ThemeButton>
          </Box>
        </Box>
      </Box>
    </DrawerComponent>
  );
}
