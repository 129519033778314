import { useState, useEffect } from "react";
import { useFindLocationsQuery } from "../Store/Slices/locationsSlice";

export function useCustomerLocations(CustomerID) {
  const { data, isLoading, isSuccess, isError, error } = useFindLocationsQuery();
  const [filterLocations, setFilterLocations] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      let new_locations = [];
      let locations;
      if (isSuccess) locations = data;
      let filtered = locations?.filter((l) => l.CustomerID === CustomerID);
      let filtering = filtered?.map((n) => {
        if (n.IsActive) {
          new_locations.push({ label: n.LocationName, value: n.LocationID, ParentID: n.ParentID });
        }
      });
      setFilterLocations(new_locations);
      if (new_locations.length > 0) {
        return new_locations;
      } else {
        return [];
      }
    }
  }, [isSuccess]);

  return [filterLocations, isLoading];
}
