import React, { useEffect, useState } from "react";
import { Select, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@mui/material/Button";
import moment from "moment";
import { useDataEquipmentQuery, useLiveEquipmentQuery } from "../../Store/Slices/equipmentSlice";
import { useFindCustomersQuery } from "../../Store/Slices/customersSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import { useSelector } from "react-redux";
import Chart from "./chart.js";
import "./style.css";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";

export default function SubstationView() {
  const location = useLocation();
  const [activeEquipment, setActiveEquipment] = useState(location.state?.ID);
  const [customers, setCustomers] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const auth = useSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [startDate, setStartDate] = useState(moment().utc().subtract(2, "hours").valueOf());
  const [endDate, setEndDate] = useState(moment().utc().valueOf());
  const [equipmentWithData, setEquipmentWithData] = useState([]);
  const [filterDataParams, setFilterDataParams] = useState({
    startDate: startDate,
    endDate: endDate,
    equipmentID: [activeEquipment],
  });
  const {
    data: equipmentData,
    isFetching: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useDataEquipmentQuery(filterDataParams);

  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customerError,
  } = useFindCustomersQuery();

  const {
    data: equipmentsData,
    isLoading: equipmentsLoading,
    isSuccess: equipmentsSuccess,
    isError: equipmentsIsError,
    error: equipmentsError,
  } = useLiveEquipmentQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const handleStartDateChange = (newValue) => {
    setStartDate(moment(newValue).valueOf());
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(moment(newValue).valueOf());
  };
  useEffect(() => {
    if (customersSuccess && locationsSuccess && equipmentsSuccess) {
      setCustomers(
        customersData.map((c) => {
          return { value: c.CustomerID, label: c.CustomerName };
        })
      );
      const mapedEquipment = equipmentsData.success.data.map((e) => {
        const new_equipment = { ...e };
        new_equipment["Location"] = locationsData.filter((l) => l.LocationID === e.LocationID)[0];
        new_equipment["Customer"] = customersData.filter(
          (c) => c.CustomerID === new_equipment.Location?.CustomerID
        )[0];
        return new_equipment;
      });
      if (isAdmin) {
        setEquipment(mapedEquipment);
      } else {
        setEquipment(
          mapedEquipment.filter((e) => {
            return e.Location?.CustomerID === auth.CustomerID;
          })
        );
      }
    }
  }, [customersSuccess, locationsSuccess, equipmentsSuccess]);

  useEffect(() => {
    if (equipment.length > 0) {
      const id = equipment.filter((e) => e.EquipmentID == activeEquipment)[0]?.Customer?.CustomerID;
      setSelectedCustomerId(id);
    }
  }, [activeEquipment, equipmentWithData]);

  useEffect(() => {
    if (equipmentLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [equipmentLoading]);

  useEffect(() => {
    if (equipmentSuccess) {
      setLoader(false);
      setEquipmentWithData(equipmentData.success.data);
    }
  }, [equipmentSuccess, equipmentData]);

  const selectedEquipment = () => {
    if (selectedCustomerId !== "") {
      return equipment.filter((e) => {
        return e.Location?.CustomerID === selectedCustomerId;
      });
    }
    return equipment;
  };

  const filterData = () => {
    setFilterDataParams({
      startDate: startDate,
      endDate: endDate,
      equipmentID: [activeEquipment],
    });
    setLoader(true);
  };
  return (
    <Box sx={{ bgcolor: "#fff", width: "100%", overflow: "auto" }}>
      <div className="Substations">
        <Typography
          sx={{ flex: "1 1 100%", margin: "1em" }}
          variant="h6"
          id="tableTitle"
          component="div"
          className="sensorCount"
        >
          Equipment Details
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "1em", width: "25%" }}>
              <DateTimePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div style={{ margin: "1em", width: "25%" }}>
              <DateTimePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </LocalizationProvider>
        {customersSuccess && equipmentsSuccess && (
          <div style={{ display: "flex", backgroundColor: "#fff" }}>
            {isAdmin && (
              <div style={{ margin: "1em", width: "25%" }}>
                <InputLabel id="demo-simple-select-label">Customer</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCustomerId}
                  displayEmpty
                  style={{ width: "100%" }}
                  label={"Customer"}
                  onChange={(event) => [setSelectedCustomerId(event.target.value)]}
                >
                  {selectedCustomerId === "" && (
                    <MenuItem
                      value=""
                      disabled
                    >
                      Please select Customer
                    </MenuItem>
                  )}

                  {customers.map((customer, idx) => {
                    return (
                      <MenuItem
                        value={customer.value}
                        key={idx}
                      >
                        {customer.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}

            <div style={{ margin: "1em", width: "25%" }}>
              <InputLabel id="demo-simple-select-label">Equipment</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                style={{ width: "100%" }}
                label={"Location"}
                onChange={(event) => setActiveEquipment(event.target.value)}
                value={activeEquipment}
              >
                {activeEquipment === "" && (
                  <MenuItem
                    value=""
                    disabled
                  >
                    Please select Equipment
                  </MenuItem>
                )}
                {selectedEquipment().length == 0 && (
                  <MenuItem
                    disabled
                    value={activeEquipment}
                  >
                    No Equipment to select
                  </MenuItem>
                )}
                {selectedEquipment()?.map((e, idx) => {
                  return (
                    <MenuItem
                      value={e.EquipmentID}
                      key={idx}
                    >
                      {e.EquipmentName}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        )}
        <div style={{ "margin-left": "-8px" }}>
          <Button
            sx={{
              ml: 3,
              bgcolor: "#17455E",
              color: "#f7f8f8",
              "&:hover": {
                bgcolor: "#11364C",
                color: "#f7f8f8",
              },
              "&:focus": {
                bgcolor: "#11364C",
                borderColor: "#00AEEF80",
                borderStyle: "solid",
                borderWidth: "3px",
                borderRadius: "4px",
                color: "#f7f8f8",
              },
              "&:disabled": {
                bgcolor: `#17455E`,
                color: "#f7f8f8",
                opacity: 0.65,
              },
            }}
            onClick={(e) => filterData()}
          >
            Search
          </Button>
        </div>
        {loader ? (
          <SpinnerContainer>
            <Loader />
          </SpinnerContainer>
        ) : (
          <div style={{ padding: "1rem" }}>
            {activeEquipment !== "" && (
              <Chart
                loading={loading}
                sensors={
                  equipmentWithData.filter((e) => e.EquipmentID === activeEquipment)[0]?.Sensors ||
                  []
                }
              ></Chart>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}
