import React from "react";
import { Tooltip } from "@mui/material";

function ToolTipComponent(props) {
  const { title, placement, Component, marginRight, styles = {} } = props;
  return (
    <>
      <Tooltip
        arrow
        title={<div style={{ whiteSpace: "pre-line" }}>{title}</div>}
        placement={placement}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#071f31",
              boxShadow: "3px 6px 8px #00000029",
              opacity: "1",
              font: "normal normal normal 16px/19px Roboto",
              color: "#F7F8F8",
              textAlign: "left",
              padding: "0.5em",
              maxWidth: "none",
              marginRight: marginRight ? marginRight : null,
              "& .MuiTooltip-arrow": {
                color: "#071f31",
              },
              ...styles,
            },
          },
        }}
        PopperProps={{
          sx: {
            top: "-5px !important",
            width: "fit-content",
          },
        }}
      >
        {Component}
      </Tooltip>
    </>
  );
}
export default ToolTipComponent;
