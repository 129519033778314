import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { formatDate } from "../../Utils/Tools";
import ReportLabelComponent from "../BaseComponents/ReportLabelComponent";
import "./style.css";

const ChartComponent = (props) => {
  const {
    data,
    chartData,
    setChartData,
    activeSensor,
    sensorType,
    faulted,
    warning,
    chartLinesData,
    date,
    sensorCalculatedData,
    setStartDate,
    setEndDate,
    setIsZoomed,
    setTimeStampSelected,
  } = props;
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [top, setTop] = useState("dataMax+1");
  const [bottom, setBottom] = useState("dataMin-1");
  const [xAxisFormat, setXAxisFormat] = useState(0);

  const X_AXIS_TIPS_FORMATS = {
    0: "HH:mm:ss",
    1: "HH:mm:ss",
    2: "HH:mm dd",
    3: "HH dd MMM",
    4: "MMM yyyy",
  };
  const X_AXIS_LABEL_FORMATS = {
    0: "HH, dd MMM yyyy",
    1: "dd MMM yyyy",
    2: "MMM yyyy",
    3: "yyyy",
  };
  const sensorLabels = {
    "%RH": "Relative Humidity",
    "°C": "Temperature",
    "°F": "Temperature",
    "%CO₂": "Carbon Dioxide",
    Pa: "Pressure",
    "%O₂": "Oxygen",
  };

  const commonSyles = {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "500",
    background: "transparent",
    marginTop: "10px",
  };

  const convertLabelFormat = (label) => {
    return formatDate(new Date(label), "dd-MMM-yyyy - HH:mm:ss");
  };

  const getAxisYDomain = (data, from, to, ref, offset, setChartData) => {
    var tempData = [...data];
    if (from && to)
      tempData = tempData.filter((dPoint) => dPoint.time >= from && dPoint.time <= to);

    let [bottom, top] = [tempData[0][ref], tempData[0][ref]];

    tempData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });

    setChartData(tempData);
    return [(bottom | 0) - offset, (top | 0) + offset];
  };

  const renderCustomXAxisTick = ({ x, y, payload }) => {
    let xPoint = formatDate(new Date(payload?.value), X_AXIS_TIPS_FORMATS[xAxisFormat]);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fill="#9ca1a6"
          font="normal normal normal 16px/19px Roboto"
          y={15}
          x={-10}
        >
          {xPoint}
        </text>
      </g>
    );
  };

  const renderCustomYAxisTick = ({ x, y, payload }) => {
    let yPoint = `${parseFloat(payload?.value || 0).toFixed(2)} ${sensorType?.Unit}`;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          fill="#9ca1a6"
          font="normal normal normal 12px/15px Roboto" // Reduce the font size here
          x={x - 160}
        >
          {yPoint}
        </text>
      </g>
    );
  };

  const zoom = () => {
    let newRefAreaLeft = refAreaLeft;
    let newRefAreaRight = refAreaRight;

    if (newRefAreaLeft === newRefAreaRight || newRefAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }

    if (newRefAreaLeft > newRefAreaRight) {
      [newRefAreaLeft, newRefAreaRight] = [newRefAreaRight, newRefAreaLeft];
    }

    const [newBottom, newTop] = getAxisYDomain(
      data,
      newRefAreaLeft,
      newRefAreaRight,
      activeSensor.SensorLabel,
      1,
      setChartData
    );

    setRefAreaLeft("");
    setRefAreaRight("");
    setLeft(formatDate(new Date(newRefAreaLeft), "dd-MMM-yyyy HH:mm:ss"));
    setRight(formatDate(new Date(newRefAreaRight), "dd-MMM-yyyy HH:mm:ss"));
    setIsZoomed(true);
    setStartDate(newRefAreaLeft);
    setEndDate(newRefAreaRight);
    setTimeStampSelected("6");
  };

  const calculateMinMaxValues = (data) => {
    let lowestValue = Infinity;
    let highestValue = -Infinity;

    data.forEach((dataPoint) => {
      const numericValues = [
        parseFloat(dataPoint.Max),
        parseFloat(dataPoint.Min),
        parseFloat(dataPoint.HightWarning),
        parseFloat(dataPoint.LowWarning),
        parseFloat(dataPoint.Current),
      ];

      numericValues.forEach((value) => {
        if (!isNaN(value)) {
          lowestValue = Math.min(lowestValue, value);
          highestValue = Math.max(highestValue, value);
        }
      });
    });

    return [lowestValue, highestValue];
  };
  useEffect(() => {
    if (!chartData || chartData.length === 0) {
      return;
    }

    const [lowestValue, highestValue] = calculateMinMaxValues(chartData);

    const padding = 1; // You can adjust this as needed
    const newBottom = lowestValue - padding;
    const newTop = highestValue + padding;

    setBottom(newBottom);
    setTop(newTop);
  }, [chartData]);

  useEffect(() => {
    const first = chartData[0];
    if (first) {
      const last = chartData[chartData.length - 1];
      const min = new Date(left === "dataMin" ? first.time : first.time < left ? first.time : left);
      const max = new Date(right === "dataMax" ? last.time : last.time > right ? last.time : right);
      let format = 4;
      if (min.getFullYear() === max.getFullYear()) {
        format = 3;
        if (min.getMonth() === max.getMonth()) {
          format = 2;
          if (min.getDay() === max.getDay()) {
            format = 1;
            if (min.getHours() === max.getHours()) {
              format = 0;
            }
          }
        }
      }
      setXAxisFormat(format);
    }
  }, [chartData, left, right]);

  const customTooltipStyles = {
    width: "180px",
    height: "46px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #E0E1E3",
    padding: "4px",
    borderRadius: "5px",
    position: "relative",
    top: "-30px",
    left: "5px",
  };

  const labelStyles = {
    fontSize: "12px",
    lineHeight: "19px",
    color: "#222934",
    textAlign: "left",
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={customTooltipStyles}
        >
          <p
            className="label"
            style={labelStyles}
          >{`${convertLabelFormat(label)} UTC`}</p>
          <p className="desc">
            <ReportLabelComponent
              label={`${
                typeof payload[0].value === "number" ? payload[0]?.value?.toFixed(2) : null
              } ${sensorType.Unit}`}
              state={"primary"}
            />
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div
        className="highlight-bar-charts"
        style={{ width: "100%", paddingLeft: "10px" }}
      >
        <div style={{ width: "90%" }}>
          <h3
            style={{
              color: "#9ca1a6",
              marginLeft: 25,
              marginBottom: 5,
              font: "normal normal normal 16px/19px Roboto",
            }}
          >
            {sensorLabels[sensorType.Unit]}
          </h3>
        </div>
        <ResponsiveContainer
          width="85%"
          height="67%"
        >
          <LineChart
            id="report-chart"
            margin={{ left: 30, right: 10 }}
            data={chartData}
            onMouseDown={(e) => {
              setRefAreaLeft(e?.activeLabel);
            }}
            onMouseMove={(e) => refAreaLeft && setRefAreaRight(e?.activeLabel)}
            onMouseUp={zoom}
          >
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid />
            <XAxis
              dataKey="time"
              domain={[left, right]}
              type="number"
              scale="time"
              tickFormatter={(unixTime) =>
                formatDate(new Date(unixTime), X_AXIS_TIPS_FORMATS[xAxisFormat])
              }
            />
            <YAxis
              domain={[bottom, top]}
              yAxisId="1"
              tickCount={12}
              interval={"preserveStartEnd"}
              tick={renderCustomYAxisTick}
            />
            <Line
              yAxisId="1"
              type="number"
              dataKey={activeSensor.SensorLabel}
              stroke={faulted ? "red" : warning ? "#f69602" : "green"}
              animationDuration={300}
              strokeWidth={1.5}
              connectNulls={false}
            />

            <Line
              yAxisId="1"
              type="number"
              dataKey={"HighWarning"}
              stroke={"#EB4436"}
              animationDuration={300}
              strokeWidth={1.5}
              dot={false}
              strokeDasharray="3 3"
              connectNulls={false}
            />

            <Line
              yAxisId="1"
              type="number"
              dataKey={"LowWarning"}
              stroke={"#2056C6"}
              animationDuration={300}
              strokeWidth={1.5}
              dot={false}
              strokeDasharray="3 3"
              connectNulls={false}
            />
            <Line
              yAxisId="1"
              type="number"
              dataKey={"Min"}
              stroke={"#2056C6"}
              animationDuration={300}
              strokeWidth={1.5}
              dot={false}
              connectNulls={false}
            />

            <Line
              yAxisId="1"
              type="number"
              dataKey={"Max"}
              stroke={"#EB4436"}
              animationDuration={300}
              strokeWidth={1.5}
              dot={false}
              connectNulls={false}
            />
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          position: "absolute",
          right: "15px",
          bottom: "1.3%",
          maxWidth: "10%",
          textAlign: "end",
        }}
      >
        <div
          style={{
            border: "none",
            font: "16px / 19px Roboto",
            color: "#9ca1a6",
            marginLeft: 25,
            font: "normal normal normal 13px/15px Roboto",
            background: "transparent",
            marginTop: "10px",
          }}
        >
          {chartData[0]?.time &&
            X_AXIS_LABEL_FORMATS[xAxisFormat] &&
            formatDate(new Date(chartData[chartData?.length - 1]?.time), "dd MMM yyyy, HH:mm:ss")}
          {xAxisFormat === 0 && <br />}
          UTC
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
