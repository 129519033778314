import { apiSlice } from "../API";

export const userSensorAlertCalendarExceptionTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserSensorAlertCalendarExceptionType: builder.query({
      query: () => "/userSensorAlertCalendarExceptionType",
      providesTags: ["UserSensorAlertCalendarExceptionType"],
    }),
    getUserSensorAlertCalendarExceptionType: builder.query({
      query: (id) => `/userSensorAlertCalendarExceptionType/${id}`,
    }),
    addUserSensorAlertCalendarExceptionType: builder.mutation({
      query: (userSensorAlertCalendarExceptionType) => ({
        url: "/userSensorAlertCalendarExceptionType",
        method: "POST",
        body: userSensorAlertCalendarExceptionType,
      }),
      invalidatesTags: ["UserSensorAlertCalendarExceptionType"],
    }),
    editUserSensorAlertCalendarExceptionType: builder.mutation({
      query: ({ id, userSensorAlertCalendarExceptionType }) => ({
        url: "/userSensorAlertCalendarExceptionType",
        method: "PUT",
        body: userSensorAlertCalendarExceptionType,
      }),
      invalidatesTags: ["UserSensorAlertCalendarExceptionType"],
    }),
    deleteUserSensorAlertCalendarExceptionType: builder.mutation({
      query: (id) => ({
        url: `/userSensorAlertCalendarExceptionType/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserSensorAlertCalendarExceptionType"],
    }),
  }),
});

export const {
  useFindUserSensorAlertCalendarExceptionTypeQuery,
  useGetUserSensorAlertCalendarExceptionTypeQuery,
  useAddUserSensorAlertCalendarExceptionTypeMutation,
  useEditUserSensorAlertCalendarExceptionTypeMutation,
} = userSensorAlertCalendarExceptionTypeSlice;
