import { apiSlice } from "../API";

export const locationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findLocations: builder.query({
      query: () => "/location",
      providesTags: ["Location"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item.IsArchive === false);
      },
    }),
    getLocation: builder.query({
      query: (id) => `/location/${id}`,
      providesTags: ["Location"],
    }),
    addLocation: builder.mutation({
      query: (location) => ({
        url: "/location",
        method: "POST",
        body: location,
      }),
      invalidatesTags: ["Location"],
    }),
    editLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/location/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Location"],
    }),
    deleteLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/location/${id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Location"],
    }),
  }),
});

export const {
  useFindLocationsQuery,
  useGetLocationQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
} = locationSlice;
