import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";
import {
  findObjectByValue,
  formatDate,
  extractLimitTemplateValue,
  getLimitValue,
} from "../../../Utils/Tools";
import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useFindSensorLimitTemplateQuery } from "../../../Store/Slices/sensorLimitTemplateSlice";
import { yellow, darkGrey, error, purple, orange, lightGrey } from "../../../Styles/theme/colors";
import CheckBoxComponent from "../Checkbox";
import ToolTipComponent from "../Tooltip";
import EquipmentContextMenu from "../EquipmentContextMenu";
import SensorContextMenu from "../SensorContextMenu";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";
import "./style.css";

const stateColors = {
  1: "warning",
  2: "error",
  3: "suppressed",
  4: "prior",
};

const TEXT_COLORS = {
  inactive: darkGrey[300],
  error: error[50],
  suppressed: purple[50],
  warning: orange[50],
  prior: yellow[950],
};

const BG_COLORS = {
  inactive: lightGrey[100],
  error: error[500],
  suppressed: purple[500],
  warning: orange[500],
  prior: yellow[500],
};

const ICON_BG_COLORS = {
  inactive: lightGrey[50],
  error: error[50],
  suppressed: purple[50],
  warning: orange[50],
  prior: yellow[50],
};

const ICON_COLORS = {
  inactive: darkGrey[900],
  error: error[950],
  suppressed: purple[950],
  warning: orange[950],
  prior: yellow[950],
};
function convertMsToReadableDate(ms) {
  const date = moment(ms, "x");
  return date.format("DD-MMM-yyyy, HH:mm:ss");
}
function getTimezoneAbbreviation(date) {
  const timezoneAbbreviation = moment(date).tz('UTC').format('z');
  return timezoneAbbreviation;
}
function EquipmentAlarmCard(props) {
  const {
    state,
    equipment,
    selectedSensors,
    handleSensorSelect,
    handleSelectedAction,
    setUnSuppressAction,
    setAllSelectedSensors,
    handleAlertSuppression,
    handleAlertAcknowledge,
    setAlarmPanelToggle,
    handleEquipmentSupressAlarms,
    handleEquipmentAcknowledgeAlarms,
  } = props;
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [activeEquipmentID, setActiveEquipmentID] = useState("");
  const navigate = useNavigate();

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  const handleSelectedEquipment = (EquipmentID) => {
    setAlarmPanelToggle(false);
    navigate("/statusview", { state: { EquipmentID: EquipmentID } });
  };
  const checkStateTime = (sensor) => {
    if (sensor?.LiveData?.State == 4) {
      return sensor?.LiveData?.OldTime;
    } else if (sensor?.LiveData?.State == 2 || sensor?.LiveData?.State == 1) {
      return sensor?.LiveData?.LastAlertStateTime || sensor?.LiveData?.Time;
    } else return sensor?.LiveData?.Time;
  };
  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom Configurations",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
  };

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const loadSensorTypes = () => {
    if (sensorTypeSuccess) setSensorTypes(sensorTypeData.success.data);
    if (sensorTypeIsError) console.log(sensorTypeError);
  };

  const handleUnSuppressAction = (action) => {
    setUnSuppressAction(action);
  };

  return (
    <Box
      sx={{
        width: "440px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E0E1E3",
        borderRadius: "4px",
        padding: "16px",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
          onClick={() => {
            maxWidthSm ? null : handleSelectedEquipment(equipment.EquipmentID);
          }}
        >
          <i
            style={{
              fontSize: "26px",
              background: BG_COLORS[state],
              color: TEXT_COLORS[state],
              padding: "4px 6px",
              borderRadius: "4px",
              fontWeight: "300",
            }}
            className={
              state === "suppressed"
                ? "fa-solid fa-alarm-clock tableRow"
                : "fa-solid fa-flux-capacitor tableRow"
            }
          ></i>
          <Box
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <Box>
              <p
                style={{
                  color: "#222934",
                  textAlign: "left",
                  font: "normal normal normal 16px/19px Roboto",
                }}
              >
                {equipment.EquipmentName}
              </p>
              <p
                style={{
                  color: "#9CA1A6",
                  textAlign: "left",
                  font: "normal normal normal 12px/19px Roboto",
                }}
              >
                {findObjectByValue(equipmentTypes, "value", equipment?.EquipmentTypeID)?.label ||
                  "EquipmentTypeName"}
              </p>
            </Box>
          </Box>
        </Box>
        {maxWidthSm ? null : (
          <Box>
            <EquipmentContextMenu
              equipment={equipment}
              setActiveEquipmentID={setActiveEquipmentID}
              handleSelectedAction={handleSelectedAction}
              handleUnSuppressAction={handleUnSuppressAction}
              setAllSelectedSensors={setAllSelectedSensors}
              handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
              handleEquipmentAcknowledgeAlarms={handleEquipmentAcknowledgeAlarms}
            />
          </Box>
        )}
      </Box>
      {equipment?.Sensors.map((sensor) => {
        if (sensor.IsActive) {
          if((sensor.LiveData.State !== 4 || !sensor.LiveData.Acknowledged) || sensor.LiveData.Suppressed){
          let sensorState = 0;
          if (sensor.LiveData) {
            if (sensor?.LiveData.State !== 0) sensorState = sensor?.LiveData?.State || 0;
          } else {
            sensorState = 2;
          }

          let colorState = stateColors[String(sensorState) || 2];
          if (sensor?.LiveData?.Suppressed) {
            colorState = "suppressed";
          }

          const sensorType = findObjectByValue(sensorTypes, "SensorTypeID", sensor.SensorTypeID);
          
          const sensorLimitTemplate =
            sensor.SensorLimitTemplateID !== "111a11b1-a111-4111-aa11-1eb1dbd1a11a"
              ? findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
              : undefined;
          const limitType =
            sensor?.LiveData?.State == 2
              ? {
                  low: "LowLimitAlarm",
                  high: "HighLimitAlarm",
                }
              : sensor?.LiveData?.State == 1 ? {
                  low: "LowLimitWarning",
                  high: "HighLimitWarning",
                }
              : {
                low: "LowLimitAlarm",
                high: "HighLimitAlarm",
                };

          const currentValue =
            sensor?.LiveData?.CurrentState ||
            parseFloat(sensor?.LiveData?.Current)
              .toFixed(2)
              .concat(
                ` ${findObjectByValue(sensorTypes, "SensorTypeID", sensor.SensorTypeID)?.Unit}`
              );

          const sensorLabel = sensor.SensorLabel;
          const sensorUpdatedAt = formatDate(
            new Date(checkStateTime(sensor)),
            "dd-MMM-yyyy, HH:mm:ss"
          );
          const suppresedUntil =
            sensor.LiveData.Suppressed === "indefinitely"
              ? "indefinitely"
              : state === "suppressed" || colorState === "suppressed"
              ? formatDate(new Date (moment(sensor.LiveData.Suppressed, "x")), "dd-MMM-yyyy, HH:mm:ss")
              : null;
          return (
            <div
              style={{
                marginTop: "20px",
                maxHeight: "55px",
                background: BG_COLORS[colorState],
                color: TEXT_COLORS[colorState],
                display: "flex",
                alignItems: "center",
                paddingLeft: "4px",
                borderRadius: "4px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {maxWidthSm ? null : (
                  <CheckBoxComponent
                    size="large"
                    checked={selectedSensors.indexOf(sensor.SensorID) > -1}
                    onChange={handleSensorSelect}
                    value={JSON.stringify(sensor)}
                    styles={{
                      backgroundColor: "white",
                    }}
                  />
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                  onClick={() =>
                    maxWidthSm
                      ? handleAlertSuppression(sensor, true)
                      : handleSelectedEquipment(equipment.EquipmentID)
                  }
                >
                  <Box
                    sx={{
                      borderRadius: "4px",
                      width: "34px",
                      height: "34px",
                    }}
                  >
                    <i
                      style={{
                        fontSize: "23px",
                        background: ICON_BG_COLORS[colorState],
                        color: ICON_COLORS[colorState],
                        borderRadius: "4px",
                        fontWeight: "300",
                        padding: "6px 3px 5px 3px",
                      }}
                      class={
                        colorState === "suppressed"
                          ? "fa-solid fa-alarm-clock tableRow"
                          : "fa-light fa-sensor-triangle-exclamation"
                      }
                    ></i>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "6px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        lineHeight: "19px",
                      }}
                    >
                      {currentValue}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                      }}
                      className="sensorName"
                    >
                      <ToolTipComponent
                        title={`${currentValue} | ${sensor?.LiveData?.State == 1 ? "Warning" : "Alarm"} Limits [${extractLimitTemplateValue(
                          getLimitValue(sensor, sensorLimitTemplate, sensorType, limitType.low),
                          "limit"
                        )} to ${extractLimitTemplateValue(
                          getLimitValue(
                            sensor,
                            sensorLimitTemplate,
                            sensorType,
                            sensor.SensorTypeLabel == "Analog" ? limitType.high : "InputState"
                          ),
                          "limit"
                        )}]`}
                        placement="bottom-end"
                        Component={
                          <Box
                            sx={{
                              width: "fit-content",
                            }}
                          >
                            {`${sensorLabel.slice(0, 20)}... | `}
                            {suppresedUntil === "indefinitely"
                              ? "Suppressed indefinitely"
                              : state === "suppressed" || colorState === "suppressed"
                              ? `Suppressed until [${suppresedUntil} ${getTimezoneAbbreviation(suppresedUntil)}]`
                              : `${sensorUpdatedAt} ${getTimezoneAbbreviation(sensorUpdatedAt)}`}
                          </Box>
                        }
                      />
                    </p>
                  </Box>
                </Box>
              </Box>
              {maxWidthSm ? null : (
                <SensorContextMenu
                  sensor={sensor}
                  setUnSuppressAction={setUnSuppressAction}
                  setAllSelectedSensors={setAllSelectedSensors}
                  handleAlertSuppression={handleAlertSuppression}
                  handleAlertAcknowledge={handleAlertAcknowledge}
                  alertSuppression
                  unsuppressAlarm
                  viewReport
                  auditTrail
                />
              )}
            </div>
          );
        }
      }
})}
    </Box>
  );
}

export default EquipmentAlarmCard;
