import React from "react";
import ToolTipComponent from "../Tooltip";

const MapToolTipComponent = (props) => {
  const buttonStyles = {
    background: "white",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    cursor: "pointer",
    color: "#9CA1A6",
    padding: "5px 6px",
    fontSize: "14px",
  };
  const { scaleRelativeToPoint, setStageByHeight, sizes } = props;
  return (
    <div
      style={{
        position: "absolute",
        right: "16px",
        display: "flex",
        flexDirection: "column",
        zIndex: 1,
      }}
    >
      <ToolTipComponent
        title="Zoom in"
        Component={
          <button
            style={{
              border: "1px solid #9CA1A6",
              borderRadius: "2px 2px 0px 0px",
              ...buttonStyles,
            }}
            onClick={() => {
              scaleRelativeToPoint(
                {
                  x: sizes.width / 2,
                  y: sizes.height / 2,
                },
                true
              );
            }}
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        }
      />
      <ToolTipComponent
        title="Reset zoom"
        Component={
          <button
            style={{
              borderBottom: "1px solid #9CA1A6",
              borderLeft: "1px solid #9CA1A6",
              borderRight: "1px solid #9CA1A6",
              borderTop: "none",
              ...buttonStyles,
            }}
            onClick={props.resetZoom}
          >
            <i class="fa-regular fa-expand"></i>
          </button>
        }
      />
      <ToolTipComponent
        title="Zoom out"
        Component={
          <button
            style={{
              borderBottom: "1px solid #9CA1A6",
              borderLeft: "1px solid #9CA1A6",
              borderRight: "1px solid #9CA1A6",
              borderTop: "none",
              borderRadius: "0px 0px 2px 2px",
              ...buttonStyles,
            }}
            onClick={() => {
              scaleRelativeToPoint(
                {
                  x: sizes.width / 2,
                  y: sizes.height / 2,
                },
                false
              );
            }}
          >
            <i class="fa-solid fa-minus"></i>
          </button>
        }
      />
    </div>
  );
};

export default MapToolTipComponent;
