import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
//import moment from 'moment';
//import Loader from "@Components/LoadingSpinner";
import EditUsers from "../../Routes/Setup/Users/EditUsers";
//DRAWER CONTEXT
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { Languages } from "../../Utils/constants";
import { findObjectByValue } from "../../Utils/Tools";
import { UTCTimeZones } from "../../Utils/constants";
import { lightGrey } from "../../Styles/theme/colors";
import { makeStyles } from "@material-ui/core/styles";
import CustomAvatar from "../Avatar";
import TableComponent from "../BaseComponents/Table";
import LabelComponent from "../BaseComponents/LabelComponent";
import { primary } from "../../Styles/theme/colors";
import { TableCell, TableRow } from "@mui/material";
import PillComponent from "../BaseComponents/Pills";
import EditUserGroup from "./EditUserGroup";
import UserGroupsDrawer from "@Components/RightDrawer/UserGroupsDrawer";
import { filterObjectByValue } from "../../Utils/Tools";
import UserFilter from "../BaseComponents/Filter/UserFilter";
import SearchComponent from "../BaseComponents/Search";
import IconButtonComponent from "../BaseComponents/IconButton";
import ToolTipComponent from "../BaseComponents/Tooltip";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";
const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-root": {
    color: primary["500"],
    borderColor: primary["500"],
  },

  "&.Mui-selected": {
    backgroundColor: primary["700"],
    color: "white",
  },
});

const UserGroupsTable = (props) => {
  let {
    users,
    toggleDrawer,
    userGroups,
    userGroupUsers,
    usersGroupedData,
    displayCustomer,
    customers,
    handleEdit,
    setTab,
    sensorAlertTypes,
    authorizationGroups,
    auth,
    allowedEdit,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [zebra, setZebra] = useState(true);
  const [edit, setEdit] = useState(false);
  const [tableLook, setTableLook] = useState("dense");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [userGroupDrawer, setUserGroupDrawer] = useState(false);
  const [userGroup, setUserGroup] = useState({});
  const [userTempData, setUserTempData] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPrefrences, setSelectedPrefrences] = useState([]);
  const [selectedSensorAlertTypes, setSelectedSensorAlertTypes] = useState([]);
  const [tempUsers, setTempUsers] = useState(users);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [numberOfFilter, setNumberOfFilter] = useState(0);
  const [userGroupFilterCount, setUserGroupFilterCount] = useState(0);
  const [languageFilterCount, setLanguageFilterCount] = useState(0);
  const [timeZoneFilterCount, setTimeZoneFilterCount] = useState(0);
  const [localUserGroup, setLocalUserGroup] = useState(userGroups);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isNestedSearch, setIsNestedSearch] = useState({});

  useEffect(() => {
    setLocalUserGroup(userGroups);
    setUserTempData(usersGroupedData);
  }, [usersGroupedData, userGroups]);

  useEffect(() => {
    let SearchedObject = userGroups?.filter(
      (el) =>
        el.label?.search(searchText) > -1 ||
        userTempData[el.value]?.filter(function (element) {
          if (searchText) {
            return (
              element !== undefined &&
              [element?.FirstName, element?.LastName, element?.UserName]
                ?.join("")
                ?.search(searchText) > -1
            );
          }
          return element !== undefined;
        })?.length
    );
    setLocalUserGroup(SearchedObject);
  }, [searchText]);

  useEffect(() => {
    let tempGroups = userGroups;
    if (groups.length) {
      tempGroups = userGroups.filter((group) => groups.some((groupID) => group.value == groupID));
    }
    setLocalUserGroup(tempGroups);
  }, [groups]);

  useEffect(() => {
    if (
      selectedRoles.length ||
      selectedPrefrences.length ||
      selectedSensorAlertTypes.length ||
      personName.length ||
      selectedTimezone.length
    ) {
      let tempFilteredUser;
      let temp = { ...usersGroupedData };
      for (const key in temp) {
        tempFilteredUser = usersGroupedData[key].filter((user) => {
          let filter1 = true,
            filter2 = true,
            filter3 = true,
            filter4 = true,
            filter5 = true,
            filter6 = true;

          if (selectedRoles.length) {
            filter1 = selectedRoles.some((role) => {
              return role == user?.RoleID;
            });
          }
          if (selectedPrefrences.length) {
            filter2 = selectedPrefrences.some((item) => {
              return (item == "email" && user?.PreferEmail) || (item == "sms" && user?.PreferSMS);
            });
          }

          if (selectedSensorAlertTypes.length) {
            filter3 = selectedSensorAlertTypes.some((item) => {
              return item == user?.SensorAlertCalendarType;
            });
          }

          if (personName.length) {
            filter4 = personName.some((language) => {
              return language == user?.Language;
            });
          }

          if (selectedTimezone.length) {
            filter5 = selectedTimezone.some((timezone) => {
              return timezone == user?.GMTAdjustment;
            });
          }
          if (selectedStatus.length) {
            filter6 = selectedStatus.some((item) => {
              return (
                (item == "Active" && user?.IsActive) || (item == "Inactive" && !user?.IsActive)
              );
            });
          }
          return filter1 && filter2 && filter3 && filter4 && filter5 && filter6;
        });
        temp[key] = tempFilteredUser;
      }
      setUserTempData(temp);
    } else {
      setUserTempData(usersGroupedData);
    }
  }, [
    selectedRoles,
    selectedPrefrences,
    selectedSensorAlertTypes,
    personName,
    selectedStatus,
    selectedTimezone,
  ]);

  const userGroupToggleDrawer = (open) => {
    setUserGroupDrawer(open);
  };

  const handlePermissionList = (event) => {
    let updatedList = [...selectedRoles];
    if (event.target.checked) {
      updatedList = [...selectedRoles, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedRoles.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedRoles(updatedList);
  };

  const handlePreferenceList = (event) => {
    let updatedList = [...selectedPrefrences];
    if (event.target.checked) {
      updatedList = [...selectedPrefrences, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedPrefrences.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedPrefrences(updatedList);
  };
  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSensorAlertTypes = (event) => {
    let updatedList = [...selectedSensorAlertTypes];
    if (event.target.checked) {
      updatedList = [...selectedSensorAlertTypes, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      setNumberOfFilter(numberOfFilter - 1);

      updatedList.splice(selectedSensorAlertTypes.indexOf(event.target.value), 1);
    }
    setSelectedSensorAlertTypes(updatedList);
  };

  const handleTimezoneChange = (count) => {
    setTimeZoneFilterCount(count);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageFilterCount(event.target.value.length);

    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const selectUserGroups = (event) => {
    const {
      target: { value },
    } = event;
    setUserGroupFilterCount(event.target.value.length);
    setGroups(typeof value === "string" ? value.split(",") : value);
  };
  const clearFilter = () => {
    setPersonName([]);
    setGroups([]);
    setSelectedRoles([]);
    setSelectedPrefrences([]);
    setSelectedStatus([]);
    setSelectedSensorAlertTypes([]);
    setSelectedTimezone([]);
    setNumberOfFilter(0);
    setUserGroupFilterCount(0);
    setLanguageFilterCount(0);
    setTimeZoneFilterCount(0);
  };
  const handleChangeZebra = () => {
    setZebra(!zebra);
  };

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    removeHeader: {
      "& span": {
        display: "none",
      },
    },
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        paddingLeft: "5px",
      },
    },
    tableCell: {
      padding: "24px",
    },
  }));

  const classes = useStyles();

  const userColumns = [
    {
      name: "user",
      label: "User",
      options: {
        filter: false,
        download: false,
        searchable: false,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.user.toUpperCase();
            var valueTwo = userListTwo.user.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <CustomAvatar
                sx={{
                  bgcolor: "#7151A1",
                  width: "32px",
                  height: "32px",
                  fontSize: "14px",
                }}
                name={value.user}
                src={
                  value.image ? `${process.env.REACT_APP_URL}/asset/download/${value.image}` : null
                }
              />
              <div style={{ marginLeft: 10 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600 }}
                >
                  {value.user}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ color: lightGrey["900"] }}
                >
                  {value.email}
                </Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "FirstName",
      label: "First Name",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableLook == "dense" && (
                <div style={{ paddingLeft: tableLook === "dense" && "2px" }}>{value}</div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "LastName",
      label: "Last Name",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
      },
    },

    {
      name: "Role",
      label: "Role",
      options: {
        download: true,
        searchable: false,

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
      },
    },

    {
      name: "contactPhone",
      label: "Phone",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "preference",
      label: "Preference",
      options: {
        filter: true,
        searchable: false,

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
      },
    },
    {
      name: "Availability",
      label: "Availability",
      options: {
        filter: true,
        searchable: false,
        display: false,
      },
    },
    {
      name: "Timezone",
      label: "Timezone",
      options: {
        filter: true,
        searchable: false,
      },
    },

    {
      name: "AdditionalInformation",
      label: "Additional information",
      options: {
        searchable: false,
      },
    },

    {
      name: "createdAt",
      label: "Created Date",
      options: {
        display: false,
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "updatedAt",
      label: "Updated Date",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
        display: false,
        searchable: false,
      },
    },
    {
      name: "", //actions
      label: "",
    },
    {
      name: "IsActive",
      label: "Status",
      options: {
        display: false,
        download: false,
        filter: false,
        searchable: false,
      },
    },
  ];

  const columns = [
    {
      name: "UserGroupID",
      label: "UserGroupID",
      options: {
        display: false,
        setCellHeaderProps: () => ({
          className: classes.removeHeader,
        }),
        searchable: false,
      },
    },
    {
      name: "Label",
      label: "Label",
      options: {
        display: false,
        setCellHeaderProps: () => ({
          className: classes.removeHeader,
        }),
      },
    },
    {
      name: "",
      label: "",
      options: {
        sort: false,
        sortCompare:
          (order) =>
          ({ rowData: a }, { rowData: b }) => {
            var valueOne = a[1].toUpperCase();
            var valueTwo = b[1].toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "", //actions
      label: "",
      options: {
        setCellHeaderProps: () => ({
          className: classes.removeHeader,
        }),
        download: false,
      },
    },
  ];

  const userOptions = {
    selectableRows: false,
    download: false,
    selectableRows: false,

    toolbar: false,
  };

  const options = {
    download: true,
    selectableRows: false,
    expandableRows: true,
    expandableRowsHeader: false,
    rowsExpanded: expandedRows,
    onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },
    sortOrder: {
      name: "GroupName",
      direction: "asc",
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = usersGroupedData[rowData[0]].length + 2;
      const userData = userTempData[rowData[0]].filter(function (element) {
        if (searchText) {
          return (
            element !== undefined &&
            [element?.FirstName, element?.LastName, element?.UserName]
              ?.join("")
              ?.search(searchText) > -1
          );
        }
        return element !== undefined;
      });
      return (
        <TableRow
          sx={{ backgroundColor: "#F7F8F8" }}
          key={rowData[1]}
        >
          <TableCell
            colSpan={colSpan}
            className={classes.tableCell}
          >
            <TableComponent
              boxShadow="none"
              hideToolbar={true}
              tableLook="relax"
              customOptions={userOptions}
              zebraStyle={true}
              columns={userColumns}
              mt="6px"
              backgroundColor="#F7F8F8"
              height="auto"
              cellPaddingLeft="16px"
              cellPaddingRight="0.96px"
              data={userData?.map((row) => {
                if (row) {
                  const timezone = UTCTimeZones.find(
                    (el) => el.value === row.GMTAdjustment?.split(" ")[0]
                  );
                  const role = findObjectByValue(authorizationGroups, "value", row?.RoleID);

                  const availability = findObjectByValue(
                    sensorAlertTypes,
                    "value",
                    row?.SensorAlertCalendarType
                  );

                  return [
                    {
                      user: row.FirstName + " " + row.LastName,
                      email: row.SNSEMail,
                      image: row.ProfileImage,
                    },
                    row.FirstName,
                    row.LastName,
                    row.SNSEMail,
                    role?.label,
                    row.SNSPhone,
                    <span style={{ display: "flex" }}>
                      <Box sx={{ mr: "2px" }}>
                        {row.PreferEmail && (
                          <LabelComponent
                            label="Email"
                            state="primary"
                          />
                        )}
                      </Box>
                      {row.PreferSMS && (
                        <LabelComponent
                          label="SMS"
                          state="primary"
                        />
                      )}
                    </span>,
                    availability?.label,
                    timezone?.label,
                    row.AdditionalInformation,
                    new Date(row.createdAt).toLocaleDateString("en-US"),
                    new Date(row.updatedAt).toLocaleDateString("en-US"),
                    <EditUsers
                      user={row}
                      handleEdit={handleEdit}
                      paddingRight="0.65px"
                    />,
                    row.IsActive ? "Active" : "Inactive",
                  ];
                }
              })}
            />
          </TableCell>
        </TableRow>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      delete columns[2];
      data.map((row) => {
        delete row.data[2];
      });
      return buildHead(columns) + buildBody(data);
    },
    customSearch: (searchQuery, currentRow, columns) => {
      if (currentRow[1].toLowerCase().includes(searchQuery.toLowerCase())) return true;
      return false;
    },
  };

  const handleEditUserGroup = (userGroup) => {
    userGroupToggleDrawer(true);
    setEdit(true);
    setUserGroup(userGroup);
  };

  const customComponents = {
    TableHead: () => null,
  };

  return (
    <>
      <TableComponent
        zebraStyle={false}
        components={customComponents}
        tableLook={tableLook}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "79px",
                marginRight: "16px",
                letterSpacing: "0px",
              }}
            >
              <b style={{ fontWeight: "500" }}>
                {groups.length > 0 ? groups.length : localUserGroup.length}{" "}
                {users.length <= 1 ? "group" : "groups"}
              </b>
            </p>
            <Box sx={{ mr: 0.5 }}>
              <ToolTipComponent
                title="Users"
                Component={
                  <Box>
                    <IconButtonComponent
                      onClick={() => setTab(0)}
                      Component={
                        <i
                          class="fa-light fa-user"
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            color: "#9CA1A6",
                          }}
                        ></i>
                      }
                    />
                  </Box>
                }
              />
            </Box>
            <Box sx={{ mr: "16px" }}>
              <UserFilter
                authorizationGroups={authorizationGroups}
                handlePermissionList={handlePermissionList}
                selectedRoles={selectedRoles}
                selectedPrefrences={selectedPrefrences}
                selectedStatus={selectedStatus}
                handlePreferenceList={handlePreferenceList}
                handleStatusList={handleStatusList}
                sensorAlertTypes={sensorAlertTypes}
                handleSensorAlertTypes={handleSensorAlertTypes}
                selectedSensorAlertTypes={selectedSensorAlertTypes}
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
                handleTimezoneChange={handleTimezoneChange}
                handleChange={handleChange}
                personName={personName}
                setPersonName={setPersonName}
                Languages={Languages}
                selectUserGroups={selectUserGroups}
                groups={groups}
                setGroups={setGroups}
                userGroups={userGroups}
                numberOfFilter={numberOfFilter}
                userGroupFilterCount={userGroupFilterCount}
                languageFilterCount={languageFilterCount}
                timeZoneFilterCount={timeZoneFilterCount}
                clearFilter={clearFilter}
              />
            </Box>
            <SearchComponent
              width="320px"
              padding={0}
              placeholder="Search users or groups...."
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Box>
        }
        columns={columns}
        searchText={""}
        customToolbar={
          allowedEdit && (
            <>
              <TableHeaderAddButton
                onClick={() => userGroupToggleDrawer(true)}
                label="USER GROUP"
                width="142px"
              />
              <TableHeaderAddButton
                onClick={() => toggleDrawer(true)}
                label="USER"
                width="80px"
              />
            </>
          )
        }
        customOptions={options}
        data={localUserGroup?.map((row) => {
          let userData = filterObjectByValue(userGroupUsers, "UserGroupID", row.value);

          userData = userTempData[row.value]?.filter(function (element) {
            if (searchText) {
              return (
                element !== undefined &&
                [element?.FirstName, element?.LastName, element?.UserName]
                  ?.join("")
                  ?.search(searchText) > -1
              );
            }
            return element !== undefined;
          });
          return [
            row.value,
            row.label,
            <span
              key={row.value}
              style={{ display: "flex", alignItems: "center" }}
            >
              <h1 style={{ marginRight: "10px" }}>{row.label}</h1>
              <PillComponent
                label={userData?.length}
                size="small"
              />
            </span>,
            allowedEdit && (
              <EditUserGroup
                userGroup={row}
                handleEdit={handleEditUserGroup}
                paddingRight="18px"
              />
            ),
          ];
        })}
        tableBodyHeight="calc(100vh - 253px)"
      />

      <UserGroupsDrawer
        userGroups={userGroups}
        userGroupUsers={userGroupUsers}
        drawer={userGroupDrawer}
        toggleDrawer={userGroupToggleDrawer}
        auth={auth}
        users={users}
        setUserGroup={setUserGroup}
        userGroup={userGroup}
        edit={edit}
        setEdit={setEdit}
      />
    </>
  );
};

export default UserGroupsTable;
