import { apiSlice } from "../API";

export const imagesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImage: builder.query({
      query: (id) => ({
        url: `/asset/download/${id}`,
        method: "GET",
      }),
    }),
    uploadImage: builder.mutation({
      query: (data) => ({
        url: "/asset/upload",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Assets"],
    }),
  }),
});

export const { useGetImageQuery, useUploadImageMutation } = imagesSlice;
