import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./API";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "./Slices/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { combineReducers } from "redux";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { logOut } from "./Slices/authSlice";

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
});

const rootReducer = (state, action) => {
  if (logOut.match(action)) {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
  blacklist: [apiSlice.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const sessionExpireMiddleware = (storeAPI) => (next) => (action) => {
  if (action.payload) {
    const { status } = action.payload;

    if (status === 401) {
      console.log("Unauthorized: Redirecting to login page");

      storeAPI.dispatch(logOut());
      window.location.href = "/";
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      storage.remove("root");
    });
  },

  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      apiSlice.middleware,
      sessionExpireMiddleware,
    ];
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
