import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CustomAvatar from "../Avatar";
import { useDispatch, useSelector } from "react-redux";
import logo from "@Assets/images/xil-logo-full-white.png";
import MenuItem from "@mui/material/MenuItem";
import CustomMenu from "@Components/BaseComponents/Menu";
import { logOut } from "../../Store/Slices/authSlice";
import { useLogoutFunction } from "@propelauth/react";
import { useLogoutMutation } from "../../Store/Slices/authApiSlice";

const menuId = "primary-search-account-menu";

const MobileAppBar = () => {
  const dispatch = useDispatch();
  const logoutFunction = useLogoutFunction();
  const auth = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [logout, { isLoading }] = useLogoutMutation();

  let FirstName_LastName = `${auth.FirstName} ${auth.LastName}`;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    const data = {
      UserID: auth.UserID,
      CustomerID: auth.CustomerID,
      UserName: auth.user,
    };

    await logout(data);
    dispatch(logOut());
    logoutFunction(true);
  };
  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: -0.1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        <MenuItem onClick={handleLogout}>
          <div style={{ width: "6em", display: "flex" }}>
            Sign out<div>&nbsp;&nbsp;</div>
          </div>
          <i className="fa-light fa-right-from-bracket"></i>
        </MenuItem>
      </Box>
    </CustomMenu>
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={5}
        sx={{
          backgroundColor: "inherit",
          marginBottom: "8px",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "unset",
            paddingLeft: "unset!important",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, backgroundColor: "#0D2E42" }}
          >
            <img
              src={logo}
              alt="Xiltrix"
              width={131}
              height={40}
            />
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            sx={{
              borderRadius: "4px",
              padding: "0px",
              width: "40px",
              height: "40px",
              "&:hover": { bgcolor: "#E0F5FD" },
            }}
          >
            <CustomAvatar
              sx={{
                bgcolor: "#7151A1",
                width: "32px",
                height: "32px",
                fontSize: "14px",
              }}
              name={FirstName_LastName}
              src={
                auth.ProfileImage
                  ? `${process.env.REACT_APP_URL}/asset/download/${auth.ProfileImage}`
                  : null
              }
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

export default MobileAppBar;
