import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import TableComponent from "../BaseComponents/Table";
import LabelComponent from "../BaseComponents/LabelComponent";
import ToolTipComponent from "../BaseComponents/Tooltip";
import SearchComponent from "../BaseComponents/Search";
import { primary, blueAction, lightGrey } from "../../Styles/theme/colors";
import Button from "@mui/material/Button";
import {
  deepCopy,
  findObjectByValue,
  getLimitValue,
  extractLimitTemplateValue,
  getColorCodingStatus,
  checkHasAuthPermissionsByEntity,
  getSensorLabel,
} from "../../Utils/Tools";
import { notAllowedRoles, STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import IconButtonComponent from "../BaseComponents/IconButton";
import { useFindEquipmentTypeQuery } from "../../Store/Slices/equipmentTypeSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import StatusViewFilter from "../BaseComponents/Filter/StatusViewFilter";
import Menu from "@mui/material/Menu";
import SensorLabel from "../BaseComponents/SensorLabel";
import { useFindSensorLimitTemplateQuery } from "../../Store/Slices/sensorLimitTemplateSlice";
import Toast from "@Components/BaseComponents/Toast";
import { useFindCustomersQuery } from "../../Store/Slices/customersSlice";
import { useLocation } from "react-router-dom";
import EquipmentContextMenu from "../BaseComponents/EquipmentContextMenu";
import SensorContextMenu from "../BaseComponents/SensorContextMenu";

const StatusViewTable = (props) => {
  const {
    equipmentLive,
    sensorTypes,
    onToggleTab,
    setEquipment,
    equipment: selectedEquipment,
    auth,
    currentBreakpoint,
    setUnSuppressAction,
    setAllSelectedSensors,
    handleAlertSuppression,
    handleAlertAcknowledge,
    setSelectedAction,
    locationsHierarchy,
    selectedLocationId,
    handleEquipmentSupressAlarms,
    handleEquipmentAcknowledgeAlarms,
  } = props;

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: locationsData,
    isFetching: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();

  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedEquipmentStatus, setSelectedEquipmentStatus] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [sensorStatusFilterCount, setSensorStatusFilterCount] = useState(0);
  const [equipmentStatusFilterCount, setEquipmentStatusFilterCount] = useState(0);
  const [selectedSensorLimitTemplates, setSelectedSensorLimitTemplates] = useState([]);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [sensorLimitTemplateFilterCount, setSensorLimitTemplateFilterCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [tempEquipments, setTempEquipments] = useState(equipmentLive);
  const [tempEquipmentsLive, setTempEquipmentsLive] = useState(equipmentLive);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeEquipmentID, setActiveEquipmentID] = useState("");
  const [displayTenantToast, setDisplayTenantToast] = useState(false);
  const [anchorSearchEl, setAnchorSearchEl] = React.useState(null);
  const [customers, setCustomers] = useState([]);
  const isSearchMenuOpen = Boolean(anchorSearchEl);
  const navigatedData = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setTempEquipments(deepCopy(equipmentLive));
      setTempEquipmentsLive(deepCopy(equipmentLive));
    }, 0);
  }, [equipmentLive]);

  useEffect(() => {
    let EquipmentID = navigatedData?.state?.EquipmentID;

    if (EquipmentID) {
      let eq = tempEquipments.find((eq) => eq.EquipmentID === EquipmentID);

      setEquipment(eq);
    }
  }, [navigatedData]);

  useEffect(() => {
    if (navigatedData.state?.tenantUpdatedToast) setDisplayTenantToast(true);
  }, [navigatedData]);

  useEffect(() => {
    if (!displayTenantToast) return;
    const timeout = setTimeout(() => {
      setDisplayTenantToast(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [displayTenantToast]);

  const customComponents = {
    TableHead: () => null,
  };
  const handleClose = () => {
    setAnchorSearchEl(null);
  };

  const clearFilter = () => {
    setSelectedEquipmentTypes([]);
    setSelectedSensors([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedEquipmentStatus([]);
    setSelectedStatus([]);
    setSelectedSensorLimitTemplates([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
    setSensorStatusFilterCount(0);
    setEquipmentStatusFilterCount(0);
    setSensorLimitTemplateFilterCount(0);
    setSensorLimitTemplateFilterCount(0);
    setFromDate(null);
    setToDate(null);
  };

  useEffect(() => {
    if (
      selectedEquipmentTypes.length ||
      selectedLocations.length ||
      selectedAlarm.length ||
      selectedStatus.length ||
      selectedEquipmentStatus.length ||
      selectedSensorLimitTemplates.length ||
      selectedSensors.length ||
      toDate != null ||
      fromDate != null ||
      searchText.length
    ) {
      let tempFilteredEquipments = tempEquipmentsLive.filter((equipment) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = true,
          filter6 = true,
          filter7 = true,
          filter8 = true,
          filter9 = true,
          filter10 = true,
          filter11 = true,
          filter12 = true;
        if (selectedEquipmentTypes.length) {
          filter1 = selectedEquipmentTypes.some((typeId) => {
            return typeId === equipment.EquipmentTypeID;
          });
        }
        if (selectedAlarm.length) {
          filter2 = selectedAlarm.some((item) => {
            console.log(item, equipment.Sensors);
            return (
              (item === "1" && isWarning(equipment?.Sensors)) ||
              (item === "2" && isAlarm(equipment?.Sensors))
            );
          });
        }

        if (selectedLocations.length) {
          filter3 = selectedLocations.some((locationId) => {
            return locationId == equipment.LocationID;
          });
        }

        if (fromDate != null) {
          let tempFrom = new Date(fromDate);
          let equipmentDate = new Date(equipment.updatedAt);
          filter5 = equipmentDate >= tempFrom;
        }

        if (toDate != null) {
          let tempTo = new Date(toDate);
          let equipmentDate = new Date(equipment.updatedAt);
          filter6 = equipmentDate <= tempTo;
        }

        if (selectedEquipmentStatus.length) {
          filter7 = selectedEquipmentStatus.some((item) => {
            return (
              (item === "Active" && equipment.IsActive) ||
              (item === "Inactive" && !equipment.IsActive)
            );
          });
        }
        if (selectedStatus.length) {
          filter8 = selectedStatus.some((item) => {
            return (
              (item === "Active" && equipment.IsActive) ||
              (item === "Inactive" && !equipment.IsActive)
            );
          });
        }
        if (selectedSensors.length) {
          filter9 = selectedSensors.some((typeId) => {
            return equipment.Sensors.some((sensor) => {
              return typeId === sensor.SensorTypeID;
            });
          });
        }
        if (selectedSensorLimitTemplates.length) {
          filter11 = selectedSensorLimitTemplates.some((typeId) => {
            return equipment.Sensors.some((sensor) => {
              return typeId === sensor.SensorLimitTemplateID;
            });
          });
        }
        if (searchText.length) {
          filter12 =
            equipment?.EquipmentTypeName.toLowerCase().includes(searchText.toLowerCase()) ||
            equipment.EquipmentName.toLowerCase().includes(searchText.toLowerCase());
        }
        return (
          filter1 &&
          filter2 &&
          filter3 &&
          filter4 &&
          filter5 &&
          filter6 &&
          filter7 &&
          filter8 &&
          filter9 &&
          filter10 &&
          filter11 &&
          filter12
        );
      });

      setTempEquipments(tempFilteredEquipments);
      setTempEquipmentsLive(deepCopy(equipmentLive));
    } else {
      setTempEquipments(deepCopy(equipmentLive));
    }
  }, [
    selectedEquipmentTypes,
    selectedLocations,
    selectedAlarm,
    toDate,
    fromDate,
    selectedStatus,
    selectedEquipmentStatus,
    selectedSensorLimitTemplates,
    selectedSensors,
    searchText,
  ]);

  const sensorLimitTemplates = sensorLimitTemplateData?.success?.data
    ? [
        ...sensorLimitTemplateData.success.data.map((e) => ({
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        })),
        {
          value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
          label: "Custom Configurations",
          sensorTypeValue: "",
          LimitsJSON: {},
        },
      ]
    : [
        {
          value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
          label: "Custom Configurations",
          sensorTypeValue: "",
          LimitsJSON: {},
        },
      ];

  const loadCustomers = () => {
    if (customersSuccess)
      setCustomers(
        customersData.map((c) => {
          return {
            value: c.CustomerID,
            label: c.CustomerName,
          };
        })
      );
    if (customersIsError) console.log(customersError);
  };

  useEffect(() => {
    loadCustomers();
  }, [customersData]);

  const useStyles = makeStyles((theme) => ({
    centeredHeader: {
      "& span": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    removeHeader: {
      "& span": {
        display: "none",
      },
    },
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        paddingLeft: "5px",
      },
    },
    tableCell: {
      padding: "24px",
    },
  }));

  const classes = useStyles();

  const columns = [
    {
      name: "EquipmentID",
      label: "EquipmentID",
      options: {
        display: false,
        searchable: false,
        customHeadRender: () => null,
      },
    },
    {
      name: "",
      label: "",
    },
    {
      name: "Equipmentname",
      label: "",
      options: {
        display: false,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        display: true,
        searchable: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "grid" }}>
              <Typography
                variant="caption"
                sx={{ color: "#686E77" }}
              >
                {value.EquipmentTypeName}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  color: "#222934",
                }}
              >
                {value.EquipmentName}
              </Typography>
            </div>
          );
        },
        customHeadRender: () => null,
      },
    },

    {
      name: "",
      label: "",
      options: {
        display: currentBreakpoint === 1 || currentBreakpoint === 2 ? false : true,
        searchable: true,
        customHeadRender: () => null,
      },
    },
    {
      name: "EquipmentLocation",
      label: "EquipmentLocation",
      options: {
        display: false,
        searchable: false,
        customHeadRender: () => null,
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const sensorColumns = [
    {
      name: "Sensor",
      label: "Sensor",
      options: {
        display: true,
        searchable: true,
        sort: false,
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        display: true,
        searchable: true,
        sort: false,
      },
    },
    {
      name: "Low",
      label: "Low",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "High",
      label: "High",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
    },
  ];
  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setSensorStatusFilterCount(sensorStatusFilterCount + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setSensorStatusFilterCount(sensorStatusFilterCount - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleEquipmentSelect = (event) => {
    let updatedList = [...selectedEquipmentStatus];
    if (event.target.checked) {
      updatedList = [...selectedEquipmentStatus, event.target.value];
      setEquipmentStatusFilterCount(equipmentStatusFilterCount + 1);
    } else {
      updatedList.splice(selectedEquipmentStatus.indexOf(event.target.value), 1);
      setEquipmentStatusFilterCount(equipmentStatusFilterCount - 1);
    }
    setSelectedEquipmentStatus(updatedList);
  };

  const handleSensorLimitTemplate = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedSensorLimitTemplates(typeof value === "string" ? value.split(",") : value);
    setSensorLimitTemplateFilterCount(event.target.value.length);
  };

  function isWarning(data) {
    return data?.some((sensor) => typeof sensor == "object" && sensor?.LiveData?.State == 1);
  }

  function isAlarm(data) {
    return data?.some(
      (sensor) => (typeof sensor == "object" && sensor?.LiveData?.State == 2) || !sensor.LiveData
    );
  }

  const equipmentCustomOptions = {
    selectableRows: false,
    whiteSpace: "normal",
    sortOrder: {
      name: "Sensor",
      direction: "asc",
    },
  };
  const customOptions = {
    download: false,
    selectableRows: false,
    expandableRows: true,
    expandableRowsHeader: false,
    rowsExpanded: expandedRows,
    onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const data = tempEquipments[rowMeta.dataIndex];
      return (
        <TableRow
          sx={{ backgroundColor: "#F7F8F8" }}
          key={rowData[1]}
        >
          <TableCell
            colSpan={5}
            className={classes.tableCell}
          >
            {data?.Sensors?.length > 0 ? (
              <TableComponent
                cellPaddingLeft="16px"
                boxShadow="none"
                hideToolbar={true}
                tableLook="dense"
                zebraStyle={true}
                columns={sensorColumns}
                mt="5px"
                paddingLeft="16px"
                height="auto"
                customOptions={equipmentCustomOptions}
                backgroundColor="#F7F8F8"
                data={data.Sensors.map((sensor) => {
                  const [port, ...extractedSerial] = sensor.PortSerial.split("-");
                  const serialNumber = extractedSerial.join("-");

                  let d = new Date(sensor.updatedAt);

                  let ye = new Intl.DateTimeFormat("en", {
                    year: "numeric",
                  }).format(d);
                  let mo = new Intl.DateTimeFormat("en", {
                    month: "short",
                  }).format(d);
                  let da = new Intl.DateTimeFormat("en", {
                    day: "2-digit",
                  }).format(d);
                  let hours = d.getHours();
                  if (hours < 10) hours = String(hours).padStart(2, "0");
                  const sensorType = findObjectByValue(sensorTypes, "value", sensor.SensorTypeID);
                  if (sensor.SensorLimitTemplateID != "111a11b1-a111-4111-aa11-1eb1dbd1a11a") {
                    var sensorLimitTemplate = findObjectByValue(
                      sensorLimitTemplates,
                      "value",
                      sensor.SensorLimitTemplateID
                    );
                  }
                  return [
                    checkHasAuthPermissionsByEntity(auth.Authorizations.setup, "Sensors") ? (
                      <Link
                        to={"/setup/sensors"}
                        state={{ SensorID: sensor.SensorID }}
                        className="device-link"
                      >
                        {sensor.SensorLabel}
                      </Link>
                    ) : (
                      sensor.SensorLabel
                    ),
                    <SensorLabel
                      LiveData={sensor.LiveData}
                      sensorType={sensorType}
                      title={sensorType?.label}
                    />,
                    <td>
                      <ToolTipComponent
                        title={`Low alarm limit: ${extractLimitTemplateValue(
                          getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
                          "limit"
                        )}\nDelay: ${extractLimitTemplateValue(
                          getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
                          "delay"
                        )}`}
                        Component={
                          <p>
                            A:{" "}
                            {getLimitValue(
                              sensor,
                              sensorLimitTemplate,
                              sensorType,
                              "LowLimitAlarm"
                            )}
                          </p>
                        }
                      />
                      <ToolTipComponent
                        title={`Low warning limit: ${extractLimitTemplateValue(
                          getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitWarning"),
                          "limit"
                        )}\nDelay: ${extractLimitTemplateValue(
                          getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitWarning"),
                          "delay"
                        )}`}
                        Component={
                          <p>
                            W:{" "}
                            {getLimitValue(
                              sensor,
                              sensorLimitTemplate,
                              sensorType,
                              "LowLimitWarning"
                            )}
                          </p>
                        }
                      />
                    </td>,
                    <td>
                      <ToolTipComponent
                        title={`High alarm limit: ${extractLimitTemplateValue(
                          getLimitValue(
                            sensor,
                            sensorLimitTemplate,
                            sensorType,
                            sensor.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                          ),
                          "limit"
                        )}\nDelay: ${extractLimitTemplateValue(
                          getLimitValue(
                            sensor,
                            sensorLimitTemplate,
                            sensorType,
                            sensor.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                          ),
                          "delay"
                        )}`}
                        Component={
                          <p>
                            A:{" "}
                            {getLimitValue(
                              sensor,
                              sensorLimitTemplate,
                              sensorType,
                              sensor.SensorTypeLabel == "Analog" ? "HighLimitAlarm" : "InputState"
                            )}
                          </p>
                        }
                      />
                      <ToolTipComponent
                        title={`High warning limit: ${extractLimitTemplateValue(
                          getLimitValue(
                            sensor,
                            sensorLimitTemplate,
                            sensorType,
                            "HighLimitWarning"
                          ),
                          "limit"
                        )}\nDelay: ${extractLimitTemplateValue(
                          getLimitValue(
                            sensor,
                            sensorLimitTemplate,
                            sensorType,
                            "HighLimitWarning"
                          ),
                          "delay"
                        )}`}
                        Component={
                          <p>
                            W:{" "}
                            {getLimitValue(
                              sensor,
                              sensorLimitTemplate,
                              sensorType,
                              "HighLimitWarning"
                            )}
                          </p>
                        }
                      />
                    </td>,
                    <SensorContextMenu
                      sensor={sensor}
                      setUnSuppressAction={setUnSuppressAction}
                      setAllSelectedSensors={setAllSelectedSensors}
                      handleAlertSuppression={handleAlertSuppression}
                      handleAlertAcknowledge={handleAlertAcknowledge}
                      alertSuppression={true}
                      viewReport={true}
                      auditTrail={true}
                      unsuppressAlarm
                    />,
                  ];
                })}
              />
            ) : (
              <div
                style={{
                  padding: "91px 0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "88px",
                    height: "88px",
                    background: "#E0E1E3 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginRight: "16px",
                  }}
                >
                  <i
                    class="fa-light fa-sensor-triangle-exclamation"
                    style={{
                      color: "#222934",
                      fontSize: "50px",
                      lineHeight: "60px",
                      fontWeight: 300,
                    }}
                  ></i>{" "}
                </Box>
                {notAllowedRoles.includes(auth.Role) ? (
                  <p
                    style={{
                      color: "#222934",
                      fontSize: "18px",
                      lineHeight: "25px",
                      fontWeight: 400,
                    }}
                  >
                    No sensors attached to this equipment
                  </p>
                ) : (
                  <div>
                    <p
                      style={{
                        color: "#222934",
                        fontSize: "18px",
                        lineHeight: "25px",
                        fontWeight: 400,
                        marginBottom: "19px",
                      }}
                    >
                      No sensors attached to this equipment
                    </p>
                    <Button
                      sx={{
                        fontSize: "16px",
                        fontWeight: "medium",
                        borderColor: primary["500"],
                        borderStyle: "solid",
                        borderWidth: "1px",
                        px: 1,
                        py: 0.3,
                        color: primary["500"],
                        height: "32px",
                        width: "104px",
                        "&:hover": {
                          backgroundColor: primary["700"],
                          color: lightGrey["50"],
                          borderColor: primary["500"],
                          borderStyle: "solid",
                          borderWidth: 1,
                        },
                        "&:active": {
                          color: lightGrey["50"],
                          backgroundColor: primary["700"],
                          borderStyle: "solid",
                          borderWidth: 1,
                        },
                        "&:focus": {
                          color: lightGrey["50"],
                          backgroundColor: primary["700"],
                          borderColor: blueAction["500"],
                          borderStyle: "solid",
                          borderWidth: "3px",
                        },
                        "&:disabled": {
                          color: primary["500"],
                          borderColor: primary["500"],
                          borderStyle: "solid",
                          borderWidth: "1px",
                          opacity: 0.65,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      // onClick={() => toggleDrawer(true)}
                    >
                      <i
                        class="fa-light fa-plus "
                        style={{ marginRight: "5px" }}
                      ></i>
                      Sensor
                    </Button>
                  </div>
                )}
              </div>
            )}
          </TableCell>
        </TableRow>
      );
    },
    setRowProps: (row) => {
      if (row[0] == selectedEquipment?.EquipmentID) {
        return {
          style: { background: "#E0F5FD 0% 0% no-repeat padding-box" },
        };
      }
    },
    onRowClick: (rowData) => {
      setEquipment({
        EquipmentID: rowData[0],
        MappedLocationID: rowData[5],
      });
    },
    sortOrder: {
      name: "Equipmentname",
      direction: "asc",
    },
    customSearch: (searchQuery, currentRow, columns) => {
      if (
        currentRow[2]?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        currentRow[2]?.toLowerCase().includes(searchQuery.toLowerCase())
      )
        return true;
      return false;
    },
  };

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const loadLocations = async () => {
    if (locationsSuccess)
      setAllLocations(
        locationsData.map((e) => {
          return { value: e.LocationID, label: e.LocationName };
        })
      );
  };

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const handleFromDate = (newFromDate) => {
    setFromDate(newFromDate);
  };

  const handleToDate = (newToDate) => {
    setToDate(newToDate);
  };

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  const handleClick = (event) => {
    setAnchorSearchEl(event.currentTarget);
  };

  const renderSearchMenu = (
    <Menu
      anchorEl={anchorSearchEl}
      id="equipment-search"
      open={isSearchMenuOpen}
      onClose={handleClose}
      // keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 12,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <div
        style={{
          maxHeight: "500px",
          width: "355px",
          overflow: "auto",
          padding: "16px",
        }}
      >
        <SearchComponent
          width="320px"
          placeholder="Search Devices...."
          searchText={searchText}
          setSearchText={setSearchText}
          currentBreakpoint={currentBreakpoint}
        />
      </div>
    </Menu>
  );

  return (
    <Box>
      {displayTenantToast && (
        <Toast
          message={`You are now viewing ${
            findObjectByValue(customers, "value", auth.SelectedCustomer)?.label
          }`}
          severity="success"
        />
      )}{" "}
      <TableComponent
        hideToolbar={false}
        zebraStyle={true}
        tableLook={"relax"}
        customOptions={customOptions}
        header={true}
        toolbarEnd={true}
        mt="none"
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                paddingRight: "10px",
              }}
            >
              <b>{tempEquipments.length} equipment</b>
            </p>

            <ToolTipComponent
              title="Table view"
              placement="bottom-start"
              Component={
                <Box>
                  <IconButtonComponent
                    onClick={() => onToggleTab()}
                    Component={
                      <i
                        class="fa-light fa-table"
                        style={{ color: "#9CA1A6", fontSize: "20px" }}
                      />
                    }
                  />
                </Box>
              }
            />
            <Box sx={{ paddingRight: "16px", paddingLeft: "16px" }}>
              <StatusViewFilter
                locations={allLocations}
                equipments={equipmentTypes}
                handleSelectEquipmentType={handleSelectEquipmentType}
                handleSelectSensorType={handleSelectSensorType}
                handleSelectLocation={handleSelectLocation}
                handleAlarmSelect={handleAlarmSelect}
                selectedEquipmentTypes={selectedEquipmentTypes}
                selectedSensors={selectedSensors}
                selectedAlarm={selectedAlarm}
                selectedLocations={selectedLocations}
                equipmentFilterCount={equipmentFilterCount}
                sensorTypeFilterCount={sensorTypeFilterCount}
                locationFilterCount={locationFilterCount}
                alarmFilterCount={alarmFilterCount}
                sensorTypes={sensorTypes}
                clearFilter={clearFilter}
                selectedStatus={selectedStatus}
                handleStatusList={handleStatusList}
                sensorLimitTemplates={sensorLimitTemplates}
                handleEquipmentSelect={handleEquipmentSelect}
                selectedEquipmentStatus={selectedEquipmentStatus}
                sensorStatusFilterCount={sensorStatusFilterCount}
                equipmentStatusFilterCount={equipmentStatusFilterCount}
                selectedSensorLimitTemplates={selectedSensorLimitTemplates}
                handleSensorLimitTemplate={handleSensorLimitTemplate}
                sensorLimitTemplateFilterCount={sensorLimitTemplateFilterCount}
              />
            </Box>
            {currentBreakpoint === 2 || currentBreakpoint === 1 ? (
              <ToolTipComponent
                title="Search"
                placement="bottom-start"
                Component={
                  <Box>
                    <IconButtonComponent
                      onClick={handleClick}
                      size="small"
                      aria-controls={isSearchMenuOpen ? "equipment-search" : undefined}
                      aria-expanded={isSearchMenuOpen ? "true" : undefined}
                      Component={
                        <i
                          class="fa-light fa-magnifying-glass"
                          style={{
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#9CA1A6",
                          }}
                        />
                      }
                    />
                  </Box>
                }
              />
            ) : (
              <SearchComponent
                width="320px"
                placeholder="Search Devices...."
                searchText={searchText}
                setSearchText={setSearchText}
                currentBreakpoint={currentBreakpoint}
              />
            )}
          </Box>
        }
        columns={columns}
        components={customComponents}
        searchText={searchText}
        data={tempEquipments?.map((equipment) => {
          const status = getColorCodingStatus(equipment?.Sensors, equipment.IsActive);
          const totalSensors = equipment?.Sensors.length;
          const first = equipment?.Sensors.length > 3 && equipment?.Sensors.slice(0, 2);
          const second = equipment?.Sensors.length > 3 && equipment?.Sensors.slice(2);
          const moreLabelStatus = second
            ? getColorCodingStatus(second, equipment?.IsActive)
            : second;
          const iconColors =
            (status === "none") | (status === "unacknowledged")
              ? { statusColor: "#ECEDEE", color: "#9CA1A6" }
              : { statusColor: STATUS_COLOR_CODE_MAP[status], color: "#FFFFFF" };
          return [
            equipment?.EquipmentID,
            <span style={{ position: "relative" }}>
              {status !== "none" && <StatusDot status={status} />}
              <i
                style={{
                  fontSize: "30px",
                  background: "#ECEDEE",
                  color: "#9CA1A6",
                  padding: "10px",
                  borderRadius: "5px",
                  fontWeight: "300",
                }}
                className="fa-solid fa-flux-capacitor tableRow"
              ></i>
            </span>,
            equipment?.EquipmentName,
            {
              EquipmentName: equipment?.EquipmentName,
              EquipmentTypeName: equipment?.EquipmentTypeName,
            },
            <span style={{ display: "grid" }}>
              <Typography
                variant="caption"
                sx={{ color: lightGrey["900"] }}
              >
                Monitoring
              </Typography>
              <span style={{ display: "flex", alignItems: "center" }}>
                {equipment?.Sensors.length > 0 ? (
                  currentBreakpoint === 3 ? (
                    <ToolTipComponent
                      title={equipment?.Sensors.map((sensor) => {
                        return sensor.SensorLabel + "\n";
                      })}
                      placement="bottom-start"
                      Component={
                        <Box
                          sx={{
                            width: "fit-content",
                            cursor: "pointer",
                          }}
                        >
                          <LabelComponent
                            label={
                              equipment?.Sensors.length === 1
                                ? "1 Sensor"
                                : `${totalSensors} Sensors`
                            }
                            state={status}
                          />
                        </Box>
                      }
                    />
                  ) : equipment?.Sensors.length > 3 ? (
                    <>
                      {first.map((sensor) => {
                        const sensorType = findObjectByValue(
                          sensorTypes,
                          "value",
                          sensor.SensorTypeID
                        );
                        const sensorLabel = getSensorLabel(
                          sensor,
                          sensorLimitTemplates,
                          sensorTypes
                        );
                        return (
                          <SensorLabel
                            LiveData={sensor.LiveData}
                            sensorType={sensorType}
                            title={sensorLabel}
                          />
                        );
                      })}
                      <ToolTipComponent
                        title={second.map((sensor) => {
                          if (sensorLimitTemplates) {
                            const sensorLabel = getSensorLabel(
                              sensor,
                              sensorLimitTemplates,
                              sensorTypes
                            );
                            return sensorLabel;
                          }
                        })}
                        Component={
                          <Box
                            sx={{
                              width: "fit-content",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <LabelComponent
                              label={`${second?.length} more`}
                              state={moreLabelStatus}
                            />
                          </Box>
                        }
                      />
                    </>
                  ) : (
                    equipment?.Sensors.map((sensor) => {
                      const sensorType = findObjectByValue(
                        sensorTypes,
                        "value",
                        sensor.SensorTypeID
                      );
                      const sensorLabel = getSensorLabel(sensor, sensorLimitTemplates, sensorTypes);
                      return (
                        <SensorLabel
                          LiveData={sensor.LiveData}
                          sensorType={sensorType}
                          title={sensorLabel}
                        />
                      );
                    })
                  )
                ) : (
                  <ToolTipComponent
                    title="There are no sensors attached to this equipment"
                    placement="bottom-start"
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                          cursor: "pointer",
                        }}
                      >
                        <LabelComponent
                          label="No sensors"
                          state="none"
                        />
                      </Box>
                    }
                  />
                )}
              </span>
            </span>,
            equipment?.MappedLocationID,
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {equipment.MappedLocationID ? (
                  currentBreakpoint === 5 ? (
                    <ToolTipComponent
                      placement="bottom-start"
                      title={locationsHierarchy[equipment?.LocationID]?.split(",").join("\n")}
                      Component={
                        <Box sx={{ width: "fit-content" }}>
                          <Typography
                            sx={{
                              color: "#00AEEF",
                              fontSize: "14px",
                              lineHeight: "16px",
                              textAlign: "left",
                              cursor: "pointer",
                            }}
                          >
                            {locationsHierarchy[equipment?.LocationID]}
                          </Typography>
                        </Box>
                      }
                    />
                  ) : (
                    <Box
                      sx={{
                        paddingRight: "15px",
                      }}
                    >
                      <ToolTipComponent
                        title={
                          equipment?.LocationID === selectedLocationId
                            ? "View on current map"
                            : "View on corresponding map"
                        }
                        Component={
                          <Box
                            sx={{
                              width: "fit-content",
                            }}
                          >
                            <i
                              onClick={() =>
                                setEquipment({
                                  ...equipment,
                                  MappedLocationID: equipment.MappedLocationID,
                                })
                              }
                              class={
                                equipment?.MappedLocationID == selectedLocationId
                                  ? equipment.EquipmentID == selectedEquipment?.EquipmentID
                                    ? "fa-regular fa-location-check"
                                    : "fa-regular fa-location-dot"
                                  : "fa-light fa-map-location-dot"
                              }
                              style={{
                                color: "#9CA1A6",
                                fontSize: "20px",
                                lineHeight: "25px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </Box>
                        }
                      />
                    </Box>
                  )
                ) : (
                  ""
                )}
              </Box>

              <EquipmentContextMenu
                equipment={equipment}
                setActiveEquipmentID={setActiveEquipmentID}
                handleSelectedAction={setSelectedAction}
                handleUnSuppressAction={setUnSuppressAction}
                setAllSelectedSensors={setAllSelectedSensors}
                handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
                handleEquipmentAcknowledgeAlarms={handleEquipmentAcknowledgeAlarms}
              />
            </span>,
          ];
        })}
      />
      {renderSearchMenu}
    </Box>
  );
};

const StatusDot = ({ status }) => {
  return (
    <Box
      className="statusViewDot"
      sx={{
        background: STATUS_COLOR_CODE_MAP[status],
        borderRadius: "50%",
        position: "absolute",
        height: "13px",
        width: "13px",
      }}
    />
  );
};

export default StatusViewTable;
