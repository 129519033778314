import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { TextField, Grid, Divider } from "@mui/material";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import Label from "@Components/BaseComponents/Label";

//COMPONENTS IMPORT
import TextButton from "@Components/Buttons/TextButton";
import ThemeButton from "@Components/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import IconButtonComponent from "@Components/BaseComponents/IconButton";

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const useStyles = makeStyles({
  root: {
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#00AEEF",
        borderWidth: "3px",
      },
    },
  },
});

export default function RightDrawer({ ...props }) {
  const {
    toggleDrawer,
    errors,
    drawer,
    handleChange,
    customer,
    handleCreate,
    setCustomer,
    edit,
    handleUpdate,
    valid,
  } = props;
  const [countries, setCountries] = useState(
    Country.getAllCountries().map((country) => {
      return { label: country.name, value: country.isoCode };
    })
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const theme = useTheme();

  const handleClick = () => {
    edit ? handleUpdate(customer) : handleCreate(customer);
  };

  const handleChangeSelect = (param, e) => {
    if (!e) {
      e = [];
    }
    setCustomer({
      ...customer,
      [param]: e.value,
    });
  };

  useEffect(() => {
    setStates(
      State.getStatesOfCountry(customer.Country).map((state) => {
        return { value: state.isoCode, label: state.name };
      })
    );
  }, [customer.Country]);

  useEffect(() => {
    setCities(
      City.getCitiesOfState(customer.Country, customer.StateProvince).map((city) => {
        return { value: city.name, label: city.name };
      })
    );
  }, [customer.StateProvince]);

  const classes = useStyles();

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          sx={{ zIndex: theme.zIndex.modal }}
          anchor="right"
          open={drawer}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <Box
            sx={{
              height: "60px",
              m: 2,
              my: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <h1>Customers</h1>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <IconButtonComponent
                size="large"
                onClick={() => toggleDrawer(false)}
                Component={<i className="fa-regular fa-xmark fa-lg"></i>}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            width={380}
            sx={{ p: 1 }}
          >
            <Grid
              container
              spacing={0}
            >
              <Grid
                item
                xs={12}
              >
                <Label className="required">Customer Name</Label>
                <TextField
                  onChange={handleChange}
                  value={customer.CustomerName || ""}
                  error={errors.CustomerName ? true : false}
                  helperText={errors.CustomerName}
                  name="CustomerName"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Address</Label>
                <TextField
                  onChange={handleChange}
                  value={customer.Address || ""}
                  name="Address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Country</Label>
                <Select
                  name="Country"
                  styles={selectStyles}
                  options={countries}
                  onChange={(e) => handleChangeSelect("Country", e)}
                  value={countries.find((country) => country.value === customer.Country)}
                  size="small"
                  isClearable
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">State or Province</Label>
                <Select
                  name="StateProvince"
                  styles={selectStyles}
                  options={states}
                  onChange={(e) => handleChangeSelect("StateProvince", e)}
                  value={
                    customer.StateProvince != ""
                      ? states.find((state) => state.value === customer.StateProvince)
                      : ""
                  }
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">City</Label>
                <Select
                  onChange={(e) => handleChangeSelect("City", e)}
                  value={
                    customer.City != "" ? cities.find((city) => city.value === customer.City) : ""
                  }
                  name="City"
                  styles={selectStyles}
                  options={cities}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Postal Code</Label>
                <TextField
                  onChange={handleChange}
                  value={customer.PostalCode || ""}
                  error={errors.PostalCode ? true : false}
                  helperText={errors.PostalCode}
                  name="PostalCode"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Contact Name</Label>
                <TextField
                  onChange={handleChange}
                  value={customer.ContactName || ""}
                  name="ContactName"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Contact Phone</Label>
                <TextField
                  type="number"
                  onChange={handleChange}
                  value={customer.ContactPhone || ""}
                  error={errors.ContactPhone ? true : false}
                  helperText={errors.ContactPhone}
                  name="ContactPhone"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Label className="required">Contact Email </Label>
                <TextField
                  onChange={handleChange}
                  value={customer.ContactEmail || ""}
                  error={errors.ContactEmail ? true : false}
                  helperText={errors.ContactEmail}
                  name="ContactEmail"
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.root}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box
            sx={{
              mt: 1,
              mr: 2,
              mb: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Box>
              <div style={{ display: "inline" }}>
                <TextButton
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => toggleDrawer(false)}
                >
                  Cancel
                </TextButton>
              </div>
              {"   "}

              <div style={{ display: "inline" }}>
                <ThemeButton
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={valid}
                  onClick={handleClick}
                >
                  {edit ? "Edit Customer" : "Add Customer"}
                </ThemeButton>
              </div>
            </Box>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
