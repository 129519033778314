import { useEffect, useState } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";

import { findObjectByValue, formatDate, getLimitValue } from "../../Utils/Tools";
import SensorLabel from "../BaseComponents/SensorLabel";
import { Divider } from "@material-ui/core";
import moment from "moment";
import "./style.css";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
];

const durationTypes = [
  { label: "Time duration", value: 0 },
  { label: "Future date", value: 1 },
  { label: "Indefinitely", value: 2 },
];

function convertMsToReadableDate(ms) {
  const date = moment(ms);
  return date.format("YYYY-MM-DD HH:mm:ss");
}
function getTimezoneAbbreviation(date) {
  const timezoneAbbreviation = moment(date).tz('UTC').format('z');
  return timezoneAbbreviation;
}
const SensorAcknowledegeDetails = (props) => {
  const { sensor, sensorTypes, sensorLimitTemplates, handleAcknowledgeDrawerEdit, editSensor } =
    props;

  const [sensorType, setSensorType] = useState(
    findObjectByValue(sensorTypes, "value", sensor.SensorTypeID)
  );
  const [liveData, setLiveData] = useState(sensor?.liveData || sensor?.LiveData);
  const [sensorLimitTemplate, setSensorLimitTemplate] = useState(
    findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
  );
  const [limits, setLimits] = useState([]);
  useEffect(() => {
    if (sensor && sensorTypes && sensorLimitTemplates) {
      setSensorLimitTemplate(
        findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
      );
    }
  }, [sensor, sensorType]);

  useEffect(() => {
    const sensorType = findObjectByValue(sensorTypes, "value", sensor.SensorTypeID);
    if (sensorLimitTemplate) {
      setLimits([
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitWarning"),
          label: "Low warning limit | delay",
        },
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "HighLimitWarning"),
          label: "High warning limit | delay",
        },
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
          label: "Low alarm limit | delay",
        },
        {
          value: getLimitValue(
            sensor,
            sensorLimitTemplate,
            sensorType,
            sensor.SensorTypeLabel === "Analog" ? "HighLimitAlarm" : "InputState"
          ),
          label: "High alarm limit | delay",
        },
      ]);
    }
  }, [sensorLimitTemplate, sensorType]);

  useEffect(() => {
    if (sensor.liveData || sensor.LiveData) {
      setLiveData(sensor.liveData || sensor.LiveData);
    }
  }, [sensor]);

  return (
    <>
      <Box className="drawerPadding">
        <FormControl
          margin="none"
          className="formControl"
        >
          <Grid
            container
            spacing={1}
          >
            <Box className="suppressDrawerHeader">
              <span className="mainSpan">
                <span>
                  <div
                    className={` ${"equipmentDotRelaxed"} ${
                      liveData?.Suppressed
                        ? "suppressed"
                        : liveData?.State == 0
                        ? "equipmentDotSuccess"
                        :liveData?.State == 4 
                        ?"equipmentDotPrior"
                        : "equipmentDotFault"
                    }`}
                  ></div>
                  <i className="fa-regular fa-sensor sensorIcon"></i>
                </span>
                <div className="typographyPadding">
                  <Typography
                    variant="caption"
                    className="typographyLabelType"
                  >
                    {sensor.SensorTypeLabel}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="typographyLabel"
                  >
                    {sensor.SensorLabel}
                  </Typography>
                
                  <Typography
                    variant="caption"
                    className="typographyLabelType"
                  >
            {`${formatDate(new Date(sensor?.LiveData?.OldTime), "dd-MMM-yyyy, HH:mm:ss")} ${getTimezoneAbbreviation(sensor?.LiveData?.OldTime)}`}
            </Typography>
         
                </div>
              </span>
              <SensorLabel
                LiveData={sensor.liveData || sensor.LiveData}
                sensorType={findObjectByValue(sensorTypes, "value", sensor.SensorTypeID)}
                title={sensor.SensorTypeLabel}
                options={{ display: "flex", alignItems: "center" }}
              />
            
               
           
            </Box>
           
          </Grid>
         
          <Divider />
        </FormControl>
      </Box>
    </>
  );
};

export default SensorAcknowledegeDetails;
