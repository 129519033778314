import React from "react";

const SortDownIcon = () => {
  return (
    <i
      class="fa-solid fa-sort-down"
      style={{
        font: "normal normal 300 20px/25px Font Awesome 6 Pro",
        color: "#9CA1A6",
        marginRight: "5px",
      }}
    ></i>
  );
};

export default SortDownIcon;
