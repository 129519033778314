import { useEffect, useState } from "react";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import Toast from "@Components/BaseComponents/Toast";
import { Box, FormControl, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";

import { findObjectByValue, getLimitValue, getTimezoneAbbreviation } from "../../Utils/Tools";
import SensorLabel from "../BaseComponents/SensorLabel";
import { Divider } from "@material-ui/core";
import DropDownComponent from "../BaseComponents/DropDown";
import Label from "../BaseComponents/Label";
import RadioComponent from "../BaseComponents/Radio";
import RadioGroupComponent from "../BaseComponents/RadioGroup";
import DateTimePickerComponent from "../BaseComponents/DateTimePicker";
import moment from "moment";
import { useSuppressSensorsMutation } from "../../Store/Slices/sensorSuppressSlice";
import { convertMsToReadableDate } from "../../Utils/Tools";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";
import "./style.css";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
];

const durationTypes = [
  { label: "Time duration", value: 0 },
  { label: "Future date", value: 1 },
  { label: "Indefinitely", value: 2 },
];
const SensorSuppressDrawer = (props) => {
  const {
    toggleSuppressDrawer,
    suppressDrawer,
    sensor,
    sensorTypes,
    sensorLimitTemplates,
    clearSensor,
    handleSuppressDrawerEdit,
    editSensor,
  } = props;
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);

  const [suppressSensors, { isSuccess: suppressSensorsSuccess, isError: suppressSensorsError }] =
    useSuppressSensorsMutation();

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [durationType, setDurationType] = useState({ value: null, label: "" });
  const [sensorType, setSensorType] = useState(
    findObjectByValue(sensorTypes, "value", sensor.SensorTypeID)
  );
  const [dateDisplay, setDateDisplay] = useState(moment().utc().add(2, "hours").valueOf());
  const [selectedDurationTime, setSelectedDurationTime] = useState(null);
  const [liveData, setLiveData] = useState(sensor?.liveData || sensor?.LiveData);
  const [sensorLimitTemplate, setSensorLimitTemplate] = useState(
    findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
  );
  const [limits, setLimits] = useState([]);

  const confirmationDrawer = (action) => {
    setDiscardSettingsDrawer(action);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    toggleSuppressDrawer(e);
  };

  const handleDurationType = (e) => {
    if (e.value === 0) setSelectedDurationTime(null);
    else if (e.value === 1) setSelectedDurationTime(1);
    else setSelectedDurationTime(2);

    setDurationType(e);
  };

  const handleDateChange = (newValue) => {
    setDateDisplay(moment(newValue).valueOf());
  };

  const endDateMap = {
    "Time duration": convertMsToReadableDate(
      moment().utc().add(selectedDurationTime, "minutes").valueOf(),
      true
    ),
    "Future date": convertMsToReadableDate(dateDisplay),
    Indefinitely: "Indefinitely",
  };

  const handleSuppress = async () => {
    let suppressDuration = null;
    if (selectedDurationTime === 1) {
      suppressDuration = dateDisplay;
    } else if (selectedDurationTime === 2) {
      suppressDuration = "indefinitely";
    } else {
      suppressDuration = moment().utc().add(selectedDurationTime, "minutes").valueOf();
    }

    let res = await suppressSensors({
      SensorIDs: [sensor.SensorID],
      date: suppressDuration,
    });
    if (res?.data?.success) {
      toggleSuppressDrawer(false);
    }
  };

  useEffect(() => {
    if (sensor && sensorTypes && sensorLimitTemplates) {
      setSensorLimitTemplate(
        findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
      );
    }
  }, [sensor, sensorType]);

  useEffect(() => {
    const sensorType = findObjectByValue(sensorTypes, "value", sensor.SensorTypeID);
    if (sensorLimitTemplate) {
      setLimits([
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitWarning"),
          label: "Low warning limit | delay",
        },
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "HighLimitWarning"),
          label: "High warning limit | delay",
        },
        {
          value: getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
          label: "Low alarm limit | delay",
        },
        {
          value: getLimitValue(
            sensor,
            sensorLimitTemplate,
            sensorType,
            sensor.SensorTypeLabel === "Analog" ? "HighLimitAlarm" : "InputState"
          ),
          label: "High alarm limit | delay",
        },
      ]);
    }
  }, [sensorLimitTemplate, sensorType]);

  useEffect(() => {
    if (sensor.liveData || sensor.LiveData) {
      setLiveData(sensor.liveData || sensor.LiveData);
    }
  }, [sensor]);
  return (
    <>
      {suppressSensorsSuccess && (
        <Toast
          message={`${sensor?.SensorLabel} alarm successfully suppressed ${endDateMap[durationType.label] != "Indefinitely" ? "until" : ""} ${
            endDateMap[durationType.label]
          }`}
          severity="success"
          autoHideDuration={5000}
        />
      )}
      <ResourceDrawerComponent
        drawer={suppressDrawer}
        toggleDrawer={toggleSuppressDrawer}
        title={"Suppress Alarms"}
        submitButtonTitle={"SUPPRESS"}
        handleSubmit={handleSuppress}
        displayFooter={true}
        closeToast={clearSensor}
        buttonValidation={!selectedDurationTime}
        item={sensor}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
      >
        <Box className="drawerPadding">
          <FormControl
            margin="none"
            className="formControl"
          >
            <Grid
              container
              spacing={1}
            >
              <Box className="suppressDrawerHeader">
                <span className="mainSpan">
                  <span>
                    <div
                      className={` ${"equipmentDotRelaxed"} ${
                        liveData?.Suppressed
                          ? "suppressed"
                          : liveData?.State == 0
                          ? "equipmentDotSuccess"
                          : liveData?.State == 1
                          ? "equipmentDotWarning"
                          : liveData?.State == 4 && !liveData?.Acknowledged
                          ? "equipmentDotPrior"
                          : "equipmentDotFault"
                      }`}
                    ></div>
                    <i className="fa-regular fa-sensor sensorIcon"></i>
                  </span>
                  <div className={maxWidthSm ? "typographyPaddingMobileView" : "typographyPadding"}>
                    <Typography
                      variant="caption"
                      className="typographyLabelType"
                    >
                      {sensor.SensorTypeLabel}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="typographyLabel"
                    >
                      {sensor.SensorLabel}
                    </Typography>
                  </div>
                </span>
                <SensorLabel
                  LiveData={sensor.liveData || sensor.LiveData}
                  sensorType={findObjectByValue(sensorTypes, "value", sensor.SensorTypeID)}
                  title={sensor.SensorTypeLabel}
                  options={{ display: "flex", alignItems: "center" }}
                />
              </Box>
            </Grid>
            <Box className="dividerPadding">
              <Divider />
            </Box>
            {maxWidthSm ? null : (
              <>
                <Box className="drawerHeaderBox">
                  <p className="drawerHeader">{sensorLimitTemplate?.label || "Custom"} Detail</p>
                </Box>
                <FormControl
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <Grid
                      item
                      columns={2}
                      className="gridColumns"
                    >
                      {limits.map((limit) => {
                        return (
                          limit.value != "" && (
                            <div className="typographyPadding">
                              <Typography
                                variant="caption"
                                className="typographyLabelType"
                              >
                                {limit.label}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                className="typographyLabel"
                              >
                                {limit.value}
                              </Typography>
                            </div>
                          )
                        );
                      })}
                    </Grid>
                  </FormGroup>
                </FormControl>
                <Box className="dividerPadding">
                  <Divider />
                </Box>
              </>
            )}

            <Box className="drawerHeaderBox">
              <p className="drawerHeader">{"Suppression duration"}</p>
            </Box>
            <DropDownComponent
              padding={false}
              label="Duration type"
              options={durationTypes}
              handleChange={(e) => handleDurationType(e)}
              placeholder="Select a duration type"
              value={durationTypes.find((el) => el.value === durationType.value) || ""}
            />
            {durationType.value === 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  columns={2}
                  spacing={10}
                  className="labelPadding"
                >
                  <Label className="labelColor">Duration time</Label>
                </Grid>
                <RadioGroupComponent
                  value={selectedDurationTime || ""}
                  name="controlled-radio-buttons-group"
                  callBack={(e) => setSelectedDurationTime(parseInt(e))}
                  FormControlLabels={
                    <div
                      className="formControlLabelBox"
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        width: "348px",
                        flexDirection: "column",
                      }}
                    >
                      {timeDurations.map((timeDuration) => {
                        return (
                          <FormControlLabel
                            value={timeDuration.value}
                            control={<RadioComponent />}
                            label={timeDuration.label}
                          />
                        );
                      })}
                    </div>
                  }
                />
              </>
            )}
            {durationType.value === 1 && (
              <>
                <Grid
                  item
                  xs={12}
                  columns={2}
                  spacing={10}
                  className="labelPadding"
                >
                  <Label className="labelColor labelPaddingTop">Future date ({getTimezoneAbbreviation(dateDisplay)})</Label>
                </Grid>
                <DateTimePickerComponent
                  label=""
                  value={dateDisplay}
                  onChange={handleDateChange}
                />
              </>
            )}
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
};

export default SensorSuppressDrawer;
