import { Box } from "@mui/material";
import React, { Fragment, useState } from "react";
import CheckBoxComponent from "@Components/BaseComponents/Checkbox";
import RadioGroupComponent from "@Components/BaseComponents/RadioGroup";
import RadioComponent from "@Components/BaseComponents/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import PillComponent from "../../Components/BaseComponents/Pills";
import ToolTipComponent from "../../Components/BaseComponents/Tooltip";
import LabelComponent from "../../Components/BaseComponents/LabelComponent";
import SortIcon from "../../Components/BaseComponents/Icons/SortIcon";
import SortDownIcon from "../../Components/BaseComponents/Icons/SortDownIcon";
import SortUpIcon from "../../Components/BaseComponents/Icons/SortUpIcon";
import FilterFooter from "../../Components/BaseComponents/Filter/FilterFooter";
import DrawerComponent from "../../Components/BaseComponents/Drawer";
import ThemeButton from "@Components/Buttons";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import Toast from "@Components/BaseComponents/Toast";
import TextFieldComponent from "@Components/BaseComponents/TextField";
import SearchComponent from "../../Components/BaseComponents/Search";
import TableComponent from "../../Components/BaseComponents/Table";
import ActionButton from "@Components/BaseComponents/ActionIcon";
import SubstationAlarmCard from "../../Components/BaseComponents/SubstationAlarmCard";

const TestScreen = () => {
  const [check, setCheck] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [customDrawer, setCustomDrawer] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const [warning, setWarning] = useState(false);
  const [titleSuccess, setTitleSuccess] = useState(false);

  const toggleDrawer = (open) => {
    setDrawer(open);
  };
  const toggleDeleteDrawer = (open) => {
    setDeleteDrawer(open);
  };
  const customToggleDrawer = (open) => {
    setCustomDrawer(open);
  };

  const handleDelete = () => {
    customToggleDrawer(false);
    toggleDeleteDrawer(false);
  };

  let data = [
    { label: "One", value: "One" },
    { label: "Two", value: "Two" },
    { label: "Three", value: "Three" },
    { label: "Four", value: "Four" },
  ];

  const columns = [
    {
      name: "Col1",
      label: "Col1",
    },
    {
      name: "Col2",
      label: "Col2",
    },
    {
      name: "Col3",
      label: "Col3",
    },
  ];

  const options = {
    expandableRows: true,
    expandableRowsHeader: false,

    renderExpandableRow: (rowData, rowMeta) => {
      return <p>Data</p>;
    },
  };

  return (
    <Box sx={{ p: 2, overflow: "auto", height: "90vh" }}>
      <h2>Component Testing</h2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <SubstationAlarmCard state="danger" />{" "}
      </Box>

      <Box sx={{ mt: 2 }}>
        <h3>1. Checkbox Component</h3>
        <Box sx={{ mt: 2 }}>
          Checked State:
          <CheckBoxComponent
            checked={true}
            onChange={() => console.log("Checked")}
          />{" "}
          UnChecked State:
          <CheckBoxComponent
            checked={false}
            onChange={() => console.log("Checked")}
          />{" "}
          Functional State:
          <CheckBoxComponent
            checked={check}
            onChange={() => setCheck(!check)}
          />{" "}
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>2. Radio Component</h3>
        <RadioGroupComponent
          row
          defaultValue={0}
          value={0}
          name="row-radio-buttons-group"
          callBack={() => console.log("Radio Changed")}
          FormControlLabels={
            <>
              <FormControlLabel
                value={0}
                control={<RadioComponent />}
                label="Value One"
              />
              <FormControlLabel
                value={1}
                control={<RadioComponent />}
                label="Value Two"
              />
            </>
          }
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>3. Pill Component</h3>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
            <p style={{ marginRight: "5px" }}>Pill:</p>
            <PillComponent
              label={"3"}
              size="small"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
            <p style={{ marginRight: "5px" }}>Pill with BlueAction:</p>
            <PillComponent
              label={"3"}
              size="small"
              blueAction={true}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3 style={{ marginBotton: "5px" }}>4. ToolTip Component</h3>
        <ToolTipComponent
          placement="bottom"
          title="Tooltip Description"
          Component={
            <Box>
              <i
                class="fa-regular fa-circle-info"
                style={{ color: "#9CA1A6" }}
              ></i>
            </Box>
          }
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>5. Label Component</h3>{" "}
        <Box sx={{ display: "flex", mt: 1 }}>
          <LabelComponent
            label="Test"
            state="primary"
          />
          <LabelComponent
            label="Test"
            state="neutral"
          />
          <LabelComponent
            label="Test"
            state="error"
          />
          <LabelComponent
            label="Test"
            state="success"
          />
          <LabelComponent
            label="Test"
            state="warning"
          />
          <LabelComponent
            label="Test"
            state="info"
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>6. Icon Button</h3> Functional Action Icon:
        <ActionButton>
          <i class="fa-light fa-pen-to-square"></i>
        </ActionButton>
        Sort Icon <SortIcon /> Sort Down Icon <SortDownIcon /> Sort Up Icon <SortUpIcon />
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>7. Filter Dialog Footer </h3>{" "}
        <Box sx={{ width: "30%", mt: 2 }}>
          <FilterFooter />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3 style={{ marginBottom: "10px" }}>8. Drawer</h3>{" "}
        <OutlinedButton
          variant="outlined"
          color="primary"
          onClick={toggleDrawer}
        >
          Drawer Testing
        </OutlinedButton>
        <DrawerComponent
          toggleDrawer={toggleDrawer}
          displayFooter={true}
          drawer={drawer}
          edit={false}
          title={"Drawer Title Heading"}
          buttonTitle={"Button Title"}
        >
          <Box
            width={528}
            sx={{ p: 2, pt: 0 }}
          >
            Drawer Component
          </Box>
        </DrawerComponent>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3 style={{ marginBottom: "10px" }}>9. Drawer with Delete Option</h3>{" "}
        <OutlinedButton
          variant="outlined"
          color="primary"
          onClick={toggleDeleteDrawer}
        >
          Delete Drawer Testing
        </OutlinedButton>
        <DrawerComponent
          toggleDrawer={toggleDeleteDrawer}
          displayFooter={true}
          drawer={deleteDrawer}
          edit={true}
          customToggleDrawer={customToggleDrawer}
          title={"Drawer Title Heading"}
          buttonTitle={" Button Title"}
        >
          <Box
            width={528}
            sx={{ p: 2, pt: 0 }}
          >
            Drawer Component Testing
            <DrawerComponent
              drawer={customDrawer}
              toggleDrawer={customToggleDrawer}
              edit={true}
              title="Delete User"
              displayFooter={false}
            >
              <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
                <Box
                  sx={{
                    width: "530px",
                    textAlign: "left",
                    marginBottom: "15px",
                    font: "normal normal normal 20px/24px Roboto",
                  }}
                >
                  Are you sure you want to delete this?
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <div
                      style={{ display: "inline", marginRight: "25px" }}
                      onClick={() => customToggleDrawer(false)}
                    >
                      <OutlinedButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        width="248px"
                      >
                        Cancel
                      </OutlinedButton>
                    </div>
                  </Box>
                  <Box>
                    <ThemeButton
                      variant="contained"
                      color="error"
                      size="small"
                      width="248px"
                      onClick={() => handleDelete()}
                    >
                      Delete{" "}
                    </ThemeButton>
                  </Box>
                </Box>
              </Box>
            </DrawerComponent>
          </Box>
        </DrawerComponent>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>10. Contained Buttons</h3>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            <p style={{ width: "7em" }}>PurpleAction:</p>
            <ThemeButton
              variant="contained"
              color="purple"
              size="small"
              width="70px"
              onClick={() => console.log("Containted")}
            >
              Title{" "}
            </ThemeButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            {" "}
            <p style={{ width: "7em" }}>BlueAction:</p>
            <ThemeButton
              variant="contained"
              color="blueAction"
              size="small"
              width="70px"
              onClick={() => console.log("Containted")}
            >
              Title{" "}
            </ThemeButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            {" "}
            <p style={{ width: "7em" }}>Danger:</p>
            <ThemeButton
              variant="contained"
              color="error"
              size="small"
              width="70px"
              onClick={() => console.log("Containted")}
            >
              Title{" "}
            </ThemeButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            <p style={{ width: "7em" }}>Primary:</p>
            <ThemeButton
              variant="contained"
              color="primary"
              size="small"
              width="70px"
              onClick={() => console.log("Containted")}
            >
              Title{" "}
            </ThemeButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>11. OutlinedButton Buttons</h3>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            <p style={{ width: "7em" }}>PurpleAction:</p>
            <OutlinedButton
              variant="outlined"
              color="purple"
              size="small"
              width="70px"
              onClick={() => console.log("outlined")}
            >
              Title{" "}
            </OutlinedButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            {" "}
            <p style={{ width: "7em" }}>BlueAction:</p>
            <OutlinedButton
              variant="outlined"
              color="blueAction"
              size="small"
              width="70px"
              onClick={() => console.log("outlined")}
            >
              Title{" "}
            </OutlinedButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            {" "}
            <p style={{ width: "7em" }}>Danger:</p>
            <OutlinedButton
              variant="outlined"
              color="error"
              size="small"
              width="70px"
              onClick={() => console.log("outlined")}
            >
              Title{" "}
            </OutlinedButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", m: 0.5 }}>
            <p style={{ width: "7em" }}>Primary:</p>
            <OutlinedButton
              variant="outlined"
              color="primary"
              size="small"
              width="70px"
              onClick={() => console.log("outlined")}
            >
              Title{" "}
            </OutlinedButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>12. Toast Notifications</h3>
        <p style={{ margin: "5px" }}>Load by Clicking each of them:</p>
        <Box>
          <ThemeButton
            variant="contained"
            color="success"
            size="small"
            width="100px"
            onClick={() => setSuccess(true)}
          >
            Success{" "}
          </ThemeButton>
          <span style={{ marginLeft: "5px" }}>
            <ThemeButton
              variant="contained"
              color="info"
              size="small"
              width="100px"
              onClick={() => setInfo(true)}
            >
              Info{" "}
            </ThemeButton>
          </span>
          <span style={{ marginLeft: "5px" }}>
            <ThemeButton
              variant="contained"
              color="warning"
              size="small"
              width="100px"
              onClick={() => setWarning(true)}
            >
              Warning{" "}
            </ThemeButton>
          </span>
          <span style={{ marginLeft: "5px" }}>
            <ThemeButton
              variant="contained"
              color="error"
              size="small"
              width="100px"
              onClick={() => setError(true)}
            >
              Error{" "}
            </ThemeButton>
          </span>
          <span style={{ marginLeft: "5px" }}>
            <ThemeButton
              variant="contained"
              color="success"
              size="small"
              width="150px"
              onClick={() => setTitleSuccess(true)}
            >
              Title Toast{" "}
            </ThemeButton>
          </span>
        </Box>
        {titleSuccess && (
          <Toast
            message="Success Message"
            severity="success"
            title="Title"
          />
        )}
        {success && (
          <Toast
            message="Success Message"
            severity="success"
          />
        )}
        {error && (
          <Toast
            message="Error Message"
            severity="error"
          />
        )}
        {info && (
          <Toast
            message="Info Message"
            severity="info"
          />
        )}
        {warning && (
          <Toast
            message="Warning Message"
            severity="warning"
          />
        )}
        <p style={{ margin: "5px" }}>Note: All can be checked once.</p>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>13. Text Field</h3>
        <Box sx={{ width: "520px", mt: 2 }}>
          <TextFieldComponent
            fullWidth={true}
            Label="label"
            placeholder="placeholder"
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3 style={{ marginBottom: "10px" }}>14. Search Component</h3>
        <SearchComponent placeholder="Search..." />
      </Box>
      {/* <MultipleSelectCheckmarks
        onChange={() => console.log("Data")}
        placeholder="Select user group(s)"
        data={data}
      >
        {data.map((group) => (
          <MenuItem key={group.value} value={group.value}>
            <CheckBoxComponent
              size="small"
              sx={{
                color: "#9CA1A6",
                width: "24px",
                height: "24px",
                "&:hover": {
                  color: "#17455E",
                },
                "&:focus-visible": {
                  outline: "2px auto #7fd3f2",
                },
                "&.Mui-checked": {
                  color: "#0D2E42",
                },
              }}
              checked={true}
            />
            <ListItemText primary={group.label} />
          </MenuItem>
        ))}
      </MultipleSelectCheckmarks> */}
      <Box sx={{ mt: 2 }}>
        <h3>15. Table Component</h3>
        <TableComponent
          zebraStyle={true}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Header | Title</p>
            </Box>
          }
          tableLook="relax"
          columns={columns}
          data={data?.map((row) => {
            return [row.value, row.label, row.value];
          })}
        />
        <h3 style={{ marginTop: "15px" }}>Table with Zebra and Relaxed Look</h3>

        <TableComponent
          zebraStyle={true}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Header | Title</p>
            </Box>
          }
          tableLook="dense"
          columns={columns}
          data={data?.map((row) => {
            return [row.value, row.label, row.value];
          })}
        />
        <h3 style={{ marginTop: "15px" }}>Table with Zebra and Dense Look</h3>

        <TableComponent
          zebraStyle={false}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Header | Title</p>
            </Box>
          }
          tableLook="relax"
          columns={columns}
          data={data?.map((row) => {
            return [row.value, row.label, row.value];
          })}
        />
        <h3 style={{ marginTop: "15px" }}>Table without Zebra and Relaxed Look</h3>
        <TableComponent
          zebraStyle={false}
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Header | Title</p>
            </Box>
          }
          tableLook="dense"
          columns={columns}
          data={data?.map((row) => {
            return [row.value, row.label, row.value];
          })}
        />
        <h3 style={{ marginTop: "15px" }}>Table without Zebra and Dense Look</h3>
      </Box>
      <TableComponent
        zebraStyle={false}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p>Header | Title</p>
          </Box>
        }
        tableLook="dense"
        columns={columns}
        data={data?.map((row) => {
          return [row.value, row.label, row.value];
        })}
        customOptions={options}
      />
      <h3 style={{ marginTop: "15px" }}>Expandable Rows table</h3>
    </Box>
  );
};

export default TestScreen;
