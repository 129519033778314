import { withAuthInfo, useRedirectFunctions, useLogoutFunction } from "@propelauth/react";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../Store/Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetUserInfoMutation } from "../../Store/Slices/authApiSlice";
import Loader from "../../Components/LoadingSpinner";
import SetupSpinnerContainer from "../../Containers/SetupSpinnerContainer";
import { Outlet } from "react-router-dom";

const YourApp = withAuthInfo((props) => {
  const dispatch = useDispatch();
  const [getUser, { isLoading }] = useGetUserInfoMutation();
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { redirectToLoginPage, redirectToSignupPage, redirectToAccountPage } =
    useRedirectFunctions();
  const logoutFunction = useLogoutFunction();
  const navigate = useNavigate();

  const me = async () => {
    const result = await getUser().unwrap();
    let userData = result.success.data;
    const Token = props.accessToken;
    let {
      UserName,
      FirstName,
      LastName,
      UserID,
      userGroupUsers,
      CustomerID,
      Authorizations,
      ProfileImage,
      RoleID,
      RequireActionReason,
      RequireAlarmReason,
      RequireAuthentication,
    } = userData;
    dispatch(
      setCredentials({
        ProfileImage,
        Token,
        UserName,
        FirstName,
        LastName,
        UserID,
        userGroupUsers,
        CustomerID,
        Authorizations,
        RoleID,
        RequireActionReason,
        RequireAlarmReason,
        RequireAuthentication,
        loggedOut: false,
      })
    );
  };
  useEffect(() => {
    if (props.isLoggedIn && authState.loggedOut) {
      dispatch(
        setCredentials({
          loggedOut: false,
        })
      );
      setLoading(true);
    } else if (props.isLoggedIn && !authState.UserID && !isLoading) {
      dispatch(
        setCredentials({
          Token: props.accessToken,
          loggedOut: false,
        })
      );
      me();
      setLoading(false);
    } else if (!props.isLoggedIn) {
      redirectToLoginPage();
      setLoading(false);
    }
  }, [props, authState]);

  // isLoggedIn and user are injected automatically from withAuthInfo

  return !authState.token || !authState.UserID || loading ? (
    <SetupSpinnerContainer>
      {" "}
      <Loader />{" "}
    </SetupSpinnerContainer>
  ) : (
    <Outlet />
  );
});

export default YourApp;
