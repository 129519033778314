import React from "react";
import Stack from "@mui/material/Stack";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { primary } from "../../Styles/theme/colors";
import { Tooltip } from "@mui/material";

const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-root": {
    color: primary["500"],
    borderColor: primary["500"],
  },
  "&.Mui-selected": {
    backgroundColor: primary["700"],
    color: "white",
  },
});

export default function TableToggleButton(props) {
  let { tableLook, handleTableLook, style, zebra, handleChangeZebra, showZebra } = props;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ ml: 1 }}
    >
      {showZebra && (
        <ToggleButtonGroup
          value={zebra}
          exclusive
          onChange={handleChangeZebra}
          aria-label="text alignment"
          sx={style}
        >
          <Tooltip
            title="Alternating Colors"
            value="Alternate Color"
          >
            <ToggleButton
              value="zebra"
              selected={zebra}
              aria-label="left aligned"
            >
              <i className="fa-regular fa-diagram-cells"></i>
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      )}
      <ToggleButtonGroup
        value={tableLook}
        exclusive
        onChange={handleTableLook}
        aria-label="text alignment"
        sx={{ whiteSpace: "nowrap", ...style }}
      >
        {tableLook == "dense" && (
          <Tooltip
            title="Relax"
            value="relax"
          >
            <ToggleButton
              aria-label="left aligned"
              sx={{
                height: "20px",
                width: "20px",
                px: "8px",
                py: "1px",
                border: "none",
              }}
            >
              <i className="fa-light fa-table-cells-large fa-lg"></i>
            </ToggleButton>
          </Tooltip>
        )}
        {tableLook == "relax" && (
          <Tooltip
            title="Dense"
            value="dense"
          >
            <ToggleButton
              aria-label="centered"
              sx={{
                height: "20px",
                width: "20px",
                px: "8px",
                py: "1px",
                border: "none",
              }}
            >
              <i className="fa-light fa-table-cells fa-lg"></i>
            </ToggleButton>
          </Tooltip>
        )}
      </ToggleButtonGroup>
    </Stack>
  );
}
