import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

function SubstationAlarmCard(props) {
  const { state, label, count, leadingIcon, icon, height } = props;

  let theme = useTheme();

  const textColors = {
    danger: "#F7F8F8",
    teal: "#F7F8F8",
    cyan: "#222934",
    yellow: "#222934",
    orange: "#F7F8F8",
    brown: "#F7F8F8",
    magenta: "#F7F8F8",
    purple: "#F7F8F8",
    blue: "#F7F8F8",
    lightBlue: "#F7F8F8",
  };
  const bgColors = {
    danger: "#EB4436",
    teal: "#1C9F91",
    cyan: "#26D6C6",
    yellow: "#FBD146",
    orange: "#F18C32",
    brown: "#986423",
    magenta: "#B439A1",
    purple: "#5E1D9F",
    blue: "#2056C6",
    lightBlue: "#2991F5",
  };

  const iconBgColors = {
    danger: "#FDE9E7",
    teal: "#E4F3F2",
    cyan: "#E5FAF8",
    yellow: "#FFF9E9",
    orange: "#FDF1E6",
    brown: "#F3ECE5",
    magenta: "#F6E7F4",
    purple: "#ECE4F3",
    blue: "#E4EBF8",
    lightBlue: "#E5F2FE",
  };

  const iconColors = {
    danger: "#5F2120",
    teal: "#065B4E",
    cyan: "#135D57",
    yellow: "#655314",
    orange: "#7E4223",
    brown: "#422208",
    magenta: "#5F0F4F",
    purple: "#341F54",
    blue: "#081E6C",
    lightBlue: "#0A42A2",
  };

  return (
    <Box
      sx={{
        width: "440px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E0E1E3",
        borderRadius: "4px",
        color: textColors[state],
        padding: "16px",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <i
          style={{
            fontSize: "26px",
            background: bgColors[state],
            color: textColors[state],
            padding: "6px",
            borderRadius: "4px",
            fontWeight: "300",
          }}
          className="fa-solid fa-router tableRow"
        ></i>
        <i
          class="fa-solid fa-ellipsis-vertical"
          style={{
            color: "#9CA1A6",
            fontSize: "20px",
            lineHeight: "25px",
            cursor: "pointer",
            paddingRight: "21px",
          }}
        ></i>
      </Box>

      <p
        style={{
          paddingTop: "14px",
          color: "#222934",
          fontSize: "16px",
          lineHeight: "19px",
          textAlign: "left",
          fontWeight: "300",
        }}
      >
        SubstationName
      </p>

      <Box
        sx={{
          marginTop: "20px",
          height: "42px",
          background: bgColors[state],
          display: "flex",
          alignItems: "center",
          paddingLeft: "4px",
          borderRadius: "4px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "4px",
              width: "34px",
              height: "34px",
            }}
          >
            <i
              style={{
                fontSize: "23px",
                background: iconBgColors[state],
                color: iconColors[state],
                padding: "6px",
                borderRadius: "4px",
                fontWeight: "300",
                padding: "6px 7px 5px 7px",
              }}
              class="fa-light fa-square-exclamation"
            ></i>
          </Box>
          <Box
            sx={{
              paddingLeft: "6px",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                lineHeight: "19px",
              }}
            >
              Connection lost
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SubstationAlarmCard;
