import { apiSlice } from "../API";

export const ProgramsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findPrograms: builder.query({
      query: () => "/program",
      providesTags: ["Program"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item);
      },
    }),
    addProgram: builder.mutation({
      query: (data) => ({
        url: "/program",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Program"],
    }),
    editProgram: builder.mutation({
      query: ({ id, program }) => ({
        url: `/program/${id}`,
        method: "PUT",
        body: program,
      }),
      invalidatesTags: ["Program"],
    }),
    //DELETE ENDPOINT USE NOT RECOMMENDED
    deleteProgram: builder.mutation({
      query: ({ id, body }) => ({
        url: `/program/${id}`,
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["Program"],
    }),
  }),
});

export const {
  useFindProgramsQuery,
  useAddProgramMutation,
  useEditProgramMutation,
  useDeleteProgramMutation,
} = ProgramsSlice;
