import React, { useState } from "react";
import Box from "@mui/material/Box";

import { Grid, FormControl, Typography } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import TextFieldComponent from "../BaseComponents/TextField";
import DropDownComponent from "../BaseComponents/DropDown";
import IconButtonComponent from "../BaseComponents/IconButton";
import ToolTipComponent from "../BaseComponents/Tooltip";
import { filterObjectByValue, findObjectByValue } from "../../Utils/Tools";
import { lightGrey } from "../../Styles/theme/colors";

import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function EquipmentDrawer({
  drawer,
  toggleDrawer,
  setEquipment,
  edit,
  equipment,
  tempEquipment,
  handleClick,
  locationsEquipment,
  sensorTypes,
  clearEquipment,
  equipmentTypes,
  equipmentToggleDrawer,
  customToggleDrawer,
  deleteDrawer,
  customToggleEquipmentDeleteDrawer,
  handleDelete,
  equipmentButtonValidation,
  equipmentLive,
  discardSettingsDrawer,
  confirmationDrawer,
  equipmentUpdated,
  setEquipmentUpdated,
  handleSensorEdit,
}) {
  const maxLength = 36;
  const [customError, setCustomError] = useState(false);
  function order(array) {
    if (Array.isArray(array)) {
      return array.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      console.log(array);
    }
  }
console.log("equipment type order order", equipment, equipmentTypes)
  const loadEquipment = () => {
    setEquipment(tempEquipment);
  };

  const data = filterObjectByValue(equipmentLive, "EquipmentID", equipment?.EquipmentID);
  const handleDiscard = (e) => {
    confirmationDrawer(e);
    setEquipmentUpdated(false);
    loadEquipment();
    equipmentToggleDrawer(e);
  };

  return (
    <ResourceDrawerComponent
      drawer={drawer}
      toggleDrawer={toggleDrawer}
      deactivateToggleDrawer={customToggleEquipmentDeleteDrawer}
      title={edit ? "Edit equipment" : "Add equipment"}
      submitButtonTitle={edit ? "UPDATE EQUIPMENT" : "ADD EQUIPMENT"}
      handleSubmit={handleClick}
      archiveButton={{
        disabled: !!data?.[0]?.Sensors?.length,
        tooltipCopy: "This Equipment cannot be archived because it has associated sensors",
      }}
      displayFooter={true}
      edit={edit}
      closeToast={clearEquipment}
      buttonValidation={equipmentButtonValidation || (edit && !equipmentUpdated)}
      deactivateButtonTitle={!equipment.IsArchive ? "Archive" : "Restore"}
      deleteDrawer={deleteDrawer}
      loadItem={loadEquipment}
      item={equipment}
      setUpdated={setEquipmentUpdated}
      confirmationDrawer={confirmationDrawer}
      handleDiscard={handleDiscard}
      discardSettingsDrawer={discardSettingsDrawer}
      handleDelete={handleDelete}
      deactivateButtonColor={!equipment.IsArchive ? "error" : "success"}
      deleteTitle={!equipment.IsArchive ? "Archive equipment" : "Restore equipment"}
      heading={`Are you sure you want to ${!equipment.IsArchive ? "archive" : "restore"} the ${
        equipment.EquipmentName
      }?`}
    >
      <Box sx={{ padding: "24px" }}>
        <FormControl
          margin="none"
          sx={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <TextFieldComponent
                fullWidth={true}
                padding={false}
                Label="Equipment name"
                placeholder="Enter equipment name"
                name="EquipmentName"
                onChange={(e) => {
                  if (e.target.value.length >= maxLength) {
                    setCustomError(`Equipment name should not exceed ${maxLength} characters.`);
                  }
                  if (customError && e.target.value.length < maxLength) {
                    setCustomError(false);
                  }
                  return [
                    setEquipment({
                      ...equipment,
                      EquipmentName: e.target.value,
                    }),
                    setEquipmentUpdated(true),
                  ];
                }}
                value={equipment.EquipmentName || ""}
                maxLength={maxLength}
                customError={customError}
              />
            </Grid>

            <DropDownComponent
              padding={true}
              label="Equipment type"
              options={order(
                equipmentTypes?.map((e) => {
                  return e;
                })
              )}
              handleChange={(e) => [
                setEquipment({
                  ...equipment,
                  EquipmentTypeID: e.value,
                }),
                setEquipmentUpdated(true),
              ]}
              value={equipmentTypes.find((el) => el.value === equipment.EquipmentTypeID) || ""}
              placeholder="Select an equipment type"
            />
            <DropDownComponent
              padding={true}
              placeholder="Select location"
              label="Location"
              options={order(
                locationsEquipment?.map((e) => {
                  return e;
                })
              )}
              handleChange={(e) => [
                setEquipment({
                  ...equipment,
                  LocationID: e.value,
                }),
                setEquipmentUpdated(true),
              ]}
              value={
                locationsEquipment.find((location) => location.value === equipment.LocationID) || ""
              }
            />
          </Grid>
        </FormControl>
        {equipment.LocationID !== "" && equipment.EquipmentID && (
          <>
            <Label
              sx={{
                color: "#9CA1A6",
              }}
            >
              Connected sensors
            </Label>
            <Grid
              item
              xs={12}
              sx={{ marginLeft: "10px" }}
            >
              {edit && (
                <Grid
                  item
                  xs={12}
                >
                  {data[0]?.Sensors.length > 0 ? (
                    <Box>
                      {data[0]?.Sensors.map((sensor) => {
                        const sensorType = findObjectByValue(
                          sensorTypes,
                          "SensorTypeID",
                          sensor.SensorTypeID
                        );
                        return (
                          <Box
                            key={sensor.SensorID}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "5px",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: lightGrey["900"],
                                  fontSize: "14px",
                                  lineHeight: "16px",
                                }}
                              >
                                {sensorType.SensorTypeName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "18px",
                                  lineHeight: "16px",
                                }}
                              >
                                {sensor.SensorLabel}
                              </Typography>
                            </Box>
                            <ToolTipComponent
                              title="Edit sensor"
                              Component={
                                <Box>
                                  <IconButtonComponent
                                    sx={{
                                      paddingRight: "24px",
                                    }}
                                    onClick={() => handleSensorEdit(sensor)}
                                    Component={<i class="fa-light fa-pen-to-square "></i>}
                                  />
                                </Box>
                              }
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              )}

              <Grid
                item
                xs={12}
              >
                {" "}
                <div onClick={() => customToggleDrawer(true, equipment.EquipmentID)}>
                  <button
                    style={{
                      color: "#17455E",
                      fontSize: "16px",
                      lineHeight: "19px",
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                      fontWeight: "600",
                      padding: 0,
                      marginTop: edit ? "20px" : 0,
                    }}
                  >
                    ADD A SENSOR
                  </button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </ResourceDrawerComponent>
  );
}
