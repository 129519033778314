import React, { Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import { lightGrey } from "@Styles/theme/colors";
import { AlarmContext } from "@Context/AlarmContext";
import { DrawerContext } from "../Context/DrawerContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MainContainer = (props) => {
  const alarmPanel = useContext(AlarmContext);
  const { open } = useContext(DrawerContext);
  const tableWidth = open ? "calc(100vw - 260px)" : "calc(100vw - 100px)";
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Fragment>
      <Box
        display="flex"
        sx={{
          flexGrow: 1,
          mt: alarmPanel.alarmPanelToggle ? 0 : 1,
          mx: 1,
          mb: 1,
          backgroundColor: lightGrey["100"],
          minWidth: tableWidth,
          height: !matches && "calc(100vh - 85px)",
          //overflow:'scroll',
        }}
      >
        {props.children}
      </Box>
    </Fragment>
  );
};

export default MainContainer;
