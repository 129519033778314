import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import TextFieldComponent from "../BaseComponents/TextField";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import { useTheme } from "@mui/system";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function RightDrawer({ ...props }) {
  const {
    auth,
    userRoleCount,
    toggleDrawer,
    drawer,
    edit,
    userRole,
    setUserRole,
    handleClick,
    buttonValidation,
    clearUserRole,
    handleNavigate,
    permissions,
    setPermissions,
    setUserRoleUpdated,
    userRoleUpdated,
    discardSettingsDrawer,
    confirmationDrawer,
    UserRoleConfirmationDrawer,
  } = props;

  const [customerDrawer, setCustomDrawer] = useState(false);
  const [tempPermissions, setTempPermissions] = useState([]);

  const customToggleDrawer = (open) => {
    setCustomDrawer(open);
  };

  useEffect(() => {
    if (auth.Authorizations.hasOwnProperty("tenant")) {
      setTempPermissions(permissions);
    } else {
      setTempPermissions(
        permissions.filter((permission) => {
          return permission.label != "Tenants";
        })
      );
    }
  }, [auth, permissions]);

  const theme = useTheme();

  const handleChange = (index, property, value, permission) => {
    setUserRoleUpdated(true);
    let tempPermission = [...permissions];
    tempPermission[index][property] = value;
    setPermissions(tempPermission);
    const filteredAuthorzations = userRole.Authorizations.filter(
      (authorization) => authorization.ElementName != permission.ElementName
    );
    if (property == "Edit" && value) {
      setUserRole({
        ...userRole,
        Authorizations: [
          ...filteredAuthorzations,
          {
            PageName: permission.PageName,
            ElementName: permission.ElementName,
            UIState: "Edit",
          },
        ],
      });
    } else if (property == "Read" && value == true) {
      setUserRole({
        ...userRole,
        Authorizations: [
          ...filteredAuthorzations,
          {
            PageName: permission.PageName,
            ElementName: permission.ElementName,
            UIState: "ReadOnly",
          },
        ],
      });
    } else {
      setUserRole({
        ...userRole,
        Authorizations: [...filteredAuthorzations],
      });
    }
  };

  useEffect(() => {
    console.log(userRole);
  }, [userRole]);
  const handleActivate = (user) => {
    UserRoleConfirmationDrawer(false);
    customToggleDrawer(false);
  };

  const handleDelete = (user) => {
    UserRoleConfirmationDrawer(false);
    customToggleDrawer(false);
  };

  const handleDiscard = (e) => {
    setUserRoleUpdated(false);
    confirmationDrawer(false);
    toggleDrawer(false);
  };

  return (
    <>
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={UserRoleConfirmationDrawer}
        deactivateToggleDrawer={customToggleDrawer}
        title={edit ? "Edit user role" : "New user role"}
        submitButtonTitle={edit ? "Update user role" : "Add user role"}
        edit={edit}
        disableDelete={true}
        displayFooter={true}
        handleSubmit={handleClick}
        closeToast={clearUserRole}
        buttonValidation={buttonValidation || (edit && !userRoleUpdated)}
        deactivateButtonTitle={"ARCHIVE"}
        item={auth}
        setUpdated={setUserRoleUpdated}
        deleteDrawer={customerDrawer}
        handleDiscard={handleDiscard}
        confirmationDrawer={confirmationDrawer}
        discardSettingsDrawer={discardSettingsDrawer}
        handleDelete={
          () => {
            customToggleDrawer(false);
            UserRoleConfirmationDrawer(false);
          }
          // !user.IsActive ? handleActivate(user) : handleDelete(user)
        }
        deleteTitle="Archive user role"
        heading={`Are you sure you want to arhive${" "}
        ${userRole.AuthorizationGroupName}?`}
        // deactivateButtonColor={!user.IsActive ? "primary" : "error"}
      >
        <Box sx={{ padding: "24px", overflowX: "hidden" }}>
          <TextFieldComponent
            padding={false}
            placeholder="Enter user role name"
            onChange={(e) => {
              setUserRoleUpdated(true);
              setUserRole({
                ...userRole,
                AuthorizationGroupName: e.target.value,
              });
            }}
            value={userRole.AuthorizationGroupName || ""}
            name="UserRoleName"
            variant="outlined"
            Label="User role name"
          />
          <Box
            sx={{
              mt: "27px",
              fontWeight: "medium",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                lineHeight: "22px",
                color: "#222934",
                fontFamily: "Roboto",
              }}
            >
              Permissions
            </p>
          </Box>
          <Grid
            container
            spacing={4}
            columns={25}
            marginLeft={0}
            marginTop={"13px"}
          >
            <Grid xs={8}>
              <Box sx={{ marginTop: "40px" }}>
                {tempPermissions.map((permission) => {
                  return (
                    <p
                      style={{
                        fontSize: "16px",
                        lineHeight: "23px",
                        color: "#222934",
                        marginBottom: "17px",
                      }}
                    >
                      {permission.label}
                    </p>
                  );
                })}
              </Box>
            </Grid>
            <Grid
              xs={8}
              display="flex"
              flexDirection="column"
            >
              {" "}
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#222934",
                  marginBottom: "20px",
                }}
              >
                Read
              </p>
              {tempPermissions.map((permission, index) => {
                return (
                  <CheckBoxComponent
                    styles={{
                      marginBottom: "16px",
                    }}
                    size="small"
                    checked={permission.Read || permission.Edit}
                    disabled={
                      userRole.AuthorizationGroupName == ""
                        ? true
                        : permission.Edit || userRole.AuthorizationGroupName == ""
                        ? true
                        : false
                    }
                    onChange={() =>
                      permission.label != "Status View" &&
                      handleChange(index, "Read", !permission.Read, permission)
                    }
                    value={permission.label}
                    name={permission.label}
                  />
                );
              })}
            </Grid>
            <Grid
              xs={8}
              display="flex"
              flexDirection="column"
            >
              {" "}
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#222934",
                  marginBottom: "20px",
                }}
              >
                Edit
              </p>
              {tempPermissions.map((permission, index) => {
                return index == 0 || index == 1 || index == 2 ? (
                  <p
                    style={{
                      marginBottom: "41px",
                    }}
                  >
                    {""}
                  </p>
                ) : (
                  <CheckBoxComponent
                    styles={{
                      marginBottom: "16px",
                    }}
                    size="small"
                    disabled={userRole.AuthorizationGroupName == "" ? true : false}
                    checked={permission.Edit}
                    onChange={() => [handleChange(index, "Edit", !permission.Edit, permission)]}
                    value={permission.label}
                    name={permission.label}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
}
