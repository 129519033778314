import React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import { FormControl, Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxComponent from "../Checkbox";
import ToolTipComponent from "../Tooltip";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import IconButtonComponent from "../IconButton";
import { order } from "../../../Utils/Tools";
import StatusViewMultiSelectBox from "./StatusViewMultiSelectBox";

export default function StatusViewFilter(props) {
  const { selectedAlarm, handleAlarmSelect } = props;
  const {
    handleSelectEquipmentType,
    handleSelectLocation,
    handleSelectSensorType,
    handleStatusList,
    selectedEquipmentTypes,
    equipments,
    selectedLocations,
    locations,
    selectedSensors,
    selectedStatus,
    sensorTypes,
    handleEquipmentSelect,
    sensorLimitTemplates,
    selectedEquipmentStatus,
    handleSensorLimitTemplate,
    selectedSensorLimitTemplates,
    equipmentFilterCount,
    sensorTypeFilterCount,
    locationFilterCount,
    alarmFilterCount,
    sensorStatusFilterCount,
    equipmentStatusFilterCount,
    sensorLimitTemplateFilterCount,
    clearFilter,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            equipmentFilterCount +
              sensorTypeFilterCount +
              locationFilterCount +
              alarmFilterCount +
              sensorStatusFilterCount +
              equipmentStatusFilterCount +
              sensorLimitTemplateFilterCount >
            0
              ? "Filters (" +
                String(
                  equipmentFilterCount +
                    sensorTypeFilterCount +
                    locationFilterCount +
                    alarmFilterCount +
                    sensorStatusFilterCount +
                    equipmentStatusFilterCount +
                    sensorLimitTemplateFilterCount
                ) +
                " applied)"
              : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {equipmentFilterCount +
                      sensorTypeFilterCount +
                      locationFilterCount +
                      alarmFilterCount +
                      sensorStatusFilterCount +
                      equipmentStatusFilterCount +
                      sensorLimitTemplateFilterCount >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          equipmentFilterCount +
                          sensorTypeFilterCount +
                          locationFilterCount +
                          alarmFilterCount +
                          sensorStatusFilterCount +
                          equipmentStatusFilterCount +
                          sensorLimitTemplateFilterCount
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "800px",
            width: "520px",
            overflow: "auto",
            padding: "16px",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Alarm state
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={3}
                      sx={{
                        gridTemplateColumns: "repeat(3, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={selectedAlarm.findIndex((item) => item == "1") > -1}
                              onChange={handleAlarmSelect}
                              value="1"
                              name="warning"
                              Label="Warning"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleAlarmSelect}
                              value="2"
                              name="alarm"
                              checked={selectedAlarm.findIndex((item) => item == "2") > -1}
                              Label="Alarm"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={selectedAlarm.findIndex((item) => item == "3") > -1}
                              onChange={handleAlarmSelect}
                              value="3"
                              name="technicalAlarm"
                              Label="Technical Alarm"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>
            </div>
            {/* <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Equipment status
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={3}
                      sx={{
                        gridTemplateColumns: "repeat(3, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={
                                selectedEquipmentStatus.findIndex(
                                  (item) => item === "Active"
                                ) > -1
                              }
                              onChange={handleEquipmentSelect}
                              value="Active"
                              name="Active"
                              Label="Active"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleEquipmentSelect}
                              value="Inactive"
                              name="Inactive"
                              checked={
                                selectedEquipmentStatus.findIndex(
                                  (item) => item === "Inactive"
                                ) > -1
                              }
                              Label="Inactive"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>
            </div> */}
            <StatusViewMultiSelectBox
              data={equipments}
              selectedItems={selectedEquipmentTypes}
              title="Equipment type(s)"
              placeholder="Select equipment type(s)"
              onChangeHandler={handleSelectEquipmentType}
            />
            {/* <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Sensor status
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={3}
                      sx={{
                        gridTemplateColumns: "repeat(3, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={
                                selectedStatus.findIndex(
                                  (item) => item == "Active"
                                ) > -1
                              }
                              onChange={handleStatusList}
                              value="Active"
                              name="Active"
                              Label="Active"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleStatusList}
                              value="Inactive"
                              name="Inactive"
                              checked={
                                selectedStatus.findIndex(
                                  (item) => item == "Inactive"
                                ) > -1
                              }
                              Label="Inactive"
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>{" "}
            </div> */}

            <StatusViewMultiSelectBox
              data={sensorTypes}
              selectedItems={selectedSensors}
              title="Sensor type(s)"
              placeholder="Select sensor type(s)"
              onChangeHandler={handleSelectSensorType}
            />
            <StatusViewMultiSelectBox
              data={order(sensorLimitTemplates)}
              selectedItems={selectedSensorLimitTemplates}
              title="Sensor limit template"
              placeholder="Select a sensor limit template"
              onChangeHandler={(e) => handleSensorLimitTemplate(e)}
            />
            <StatusViewMultiSelectBox
              data={locations}
              selectedItems={selectedLocations}
              title="Location(s)"
              placeholder="Select location(s)"
              onChangeHandler={handleSelectLocation}
            />
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
