import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useFindCustomersQuery } from "../../../Store/Slices/customersSlice";
import {
  useFindSensorGroupsQuery,
  useAddSensorGroupMutation,
} from "../../../Store/Slices/sensorGroupsSlice";
import {
  useFindSensorGroupSensorsQuery,
  useAddSensorGroupSensorsMutation,
} from "../../../Store/Slices/sensorGroupSensorsSlice";
import { useFindSensorsQuery } from "../../../Store/Slices/sensorSlice";
import {
  useFindSensorGroupUserGroupQuery,
  useAddSensorGroupUserGroupMutation,
} from "../../../Store/Slices/sensorGroupUserGroupSlice";
import { filterObjectByValue, findObjectByValue } from "../../../Utils/Tools";
import { useFindUserGroupsQuery } from "../../../Store/Slices/userGroupsSlice";

import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import ThemeButton from "@Components/Buttons";
import { useSelector } from "react-redux";

const selectStyles = {
  control: (base, { data, isDisabled, isFocused, isSelected }) => ({
    height: "40px",
    ...base,
    "&:hover": {
      border: "1px solid #17455E",
      borderRadius: "4px",
    },
    boxShadow: isFocused ? "#00AEEF80 0px 0px 0px 4px" : "none",
    background: isDisabled
      ? "#F1F1F1 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "4px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    "&:hover": {
      backgroundColor: "#E3E9EC !important",
      color: "#17455E !important",
    },
    "&:active": {
      color: "#FFFFFF !important",
      backgroundColor: "#11364C !important",
    },
    backgroundColor: isDisabled
      ? "#F1F1F1 !important"
      : isSelected
      ? "#3A6176 !important"
      : undefined,
    color: isDisabled ? "#C0C3C7 !important" : isSelected ? "#fff !important" : undefined,
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const classes = {
  bold: {
    fontWeight: 600,
  },
};

const SensorGroups = () => {
  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();
  const {
    data: sensorGroupsData,
    isLoading: sensorGroupsLoading,
    isSuccess: sensorGroupsSuccess,
    isError: sensorGroupsIsError,
    error: sensorGroupsError,
  } = useFindSensorGroupsQuery();
  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
  } = useFindSensorsQuery();
  const {
    data: sensorGroupsSensorsData,
    isLoading: sensorGroupsSensorsLoading,
    isSuccess: sensorGroupsSensorsSuccess,
    isError: sensorGroupsSensorsIsError,
    error: sensorGroupsSensorsError,
  } = useFindSensorGroupSensorsQuery();
  const {
    data: userGroupsData,
    isLoading: userGroupsLoading,
    isSuccess: userGroupSuccess,
    isError: userGroupIsError,
    error: userGroupError,
  } = useFindUserGroupsQuery();
  const {
    data: SGUGData,
    isLoading: SGUGLoading,
    isSuccess: SGUGSuccess,
    isError: SGUGisError,
    error: SGUGError,
  } = useFindSensorGroupUserGroupQuery();
  const [
    addSensorGroup,
    {
      isSuccess: addSensorGroupSuccess,
      isError: addSensorGroupsIsError,
      isLoading: addSensorGroupLoading,
      error: addSensorGroupError,
    },
  ] = useAddSensorGroupMutation();
  const [
    addSensorGroupSensors,
    {
      isSuccess: addSensorGroupSensorsSuccess,
      isError: addSensorGroupsSensorsIsError,
      isLoading: addSensorGroupSensorsLoading,
      error: addSensorGroupSensorsError,
    },
  ] = useAddSensorGroupSensorsMutation();
  const [
    addSensorGroupUserGroup,
    { isSuccess: addSGUG, isLoading: loadingSGUG, isError: isESGUG, error: addSGUGError },
  ] = useAddSensorGroupUserGroupMutation();
  const [customer, setCustomer] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [sensorGroups, setSensorGroups] = useState([]);
  const [sensorGroupSensors, setSensorGroupSensors] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [filterGroupSensors, setfilterGroupSensors] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [sensor, setSensor] = useState("");
  const [sensors, setSensors] = useState([]);
  const [groupValue, setGroupValue] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [SGUG, setSGUG] = useState([]);
  const [filterSGUG, setFilterSGUG] = useState([]);
  const auth = useSelector((state) => state.auth);

  const loadSensorGroups = () => {
    if (sensorGroupsSuccess) {
      setFilterGroup(
        sensorGroupsData.success.data
          .filter((sensorGroup) => sensorGroup.CustomerID === auth.CustomerID)
          .map((u) => {
            return {
              value: u.SensorGroupID,
              label: u.SensorGroupName,
              CustomerID: u.CustomerID,
            };
          })
      );
    }
    if (sensorGroupsIsError) console.log(sensorGroupsError);
  };

  const loadSensors = () => {
    if (sensorsSuccess)
      setSensors(
        sensorsData
          .filter((u) => u.CustomerID === auth.CustomerID)
          .map((u) => {
            return {
              value: u.SensorID,
              label: u.SensorLabel,
              SensorTypeID: u.SensorTypeID,
              CustomerID: u.CustomerID,
            };
          })
      );
    if (sensorIsError) console.log(sensorError);
  };

  const loadSensorGroupSensors = () => {
    if (sensorGroupsSensorsSuccess) setSensorGroupSensors(sensorGroupsSensorsData.success.data);
    if (sensorGroupsSensorsIsError) console.log(sensorGroupsSensorsError);
  };

  const loadUserGroups = () => {
    if (userGroupSuccess)
      setUserGroups(
        userGroupsData.success.data
          .filter((u) => u.CustomerID === auth.CustomerID)
          .map((u) => {
            return { value: u.UserGroupID, label: u.GroupName };
          })
      );
    if (userGroupIsError) console.log(userGroupError);
  };

  const loadSGUG = () => {
    if (SGUGSuccess) setSGUG(SGUGData.success.data);
    if (SGUGisError) console.log(SGUGError);
  };

  const handleChange = (newValue, actionMeta) => {
    // console.group('Value Changed');
    setGroupValue(newValue);
    //console.log(`action: ${actionMeta.action}`);
    //console.groupEnd();
  };
  const handleInputChange = (inputValue, actionMeta) => {
    //console.group('Input Changed');
    setAddGroup(inputValue);
    // console.log(`action: ${actionMeta.action}`);
    //console.groupEnd();
  };

  const handleCreate = async () => {
    let data = { CustomerID: auth.CustomerID, SensorGroupName: addGroup };
    try {
      let res = await addSensorGroup(data);
      console.log("🚀 ~ file: index.js ~ line 122 ~ handleCreate ~ res", res);
      let { CustomerID, SensorGroupName, SensorGroupID } = res.data.success.data;
      setSensorGroups([
        ...sensorGroups,
        {
          value: SensorGroupID,
          label: SensorGroupName,
          CustomerID: CustomerID,
        },
      ]);
      setGroupValue({
        value: SensorGroupID,
        label: SensorGroupName,
        CustomerID: CustomerID,
      });
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 60 ~ handleCreate ~ error", error);
    }
  };

  const addSensorToGroup = async (SensorGroupID, SensorID) => {
    let data = { SensorGroupID: SensorGroupID, SensorID: SensorID };
    try {
      let res = await addSensorGroupSensors(data);
      let { SensorGroupID, SensorID } = res.data.success.data;
      setfilterGroupSensors([
        ...filterGroupSensors,
        { SensorGroupID: SensorGroupID, SensorID: SensorID },
      ]);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 126 ~ addSensorToGroup ~ error", error);
      if (addSensorGroupsSensorsIsError)
        console.log(
          "🚀 ~ file: index.js ~ line 61 ~ SensorGroups ~ addSensorGroupSensorsError",
          addSensorGroupSensorsError
        );
    }
  };

  const handleAddSenorGroupToUserGroup = (SensorGroupID, UserGroupID) => {
    let data = { SensorGroupID: SensorGroupID, UserGroupID: UserGroupID };
    try {
      let res = addSensorGroupUserGroup(data);
      console.log("🚀 ~ file: index.js ~ line 166 ~ handleAddSenorGroupToUserGroup ~ res", res);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 167 ~ handleAddSenorGroupToUserGroup ~ error", error);
      if (userGroupIsError) console.log(userGroupError);
    }
  };

  const handleCancel = () => {
    setCustomer("");
    setGroupValue("");
    setSensor("");
  };

  const handleUserGroupCancel = () => {
    setUserGroup([]);
  };

  const findLabel = (array, label) => {
    let res = array.find((c) => c.label === label.label);
    return res;
  };

  const buttonValidation = !(sensor != "" && Object.values(groupValue) != "");
  const UGSGbuttonValidation = !(Object.values(groupValue) != "" && !userGroup.length);
  console.log(
    "🚀 ~ file: index.js ~ line 204 ~ SensorGroups ~ UGSGbuttonValidation",
    UGSGbuttonValidation
  );

  useEffect(() => {
    loadSensorGroups();
  }, [sensorGroupsData]);

  useEffect(() => {
    loadSensors();
  }, [sensorsData]);

  useEffect(() => {
    loadUserGroups();
  }, [userGroupsData]);

  useEffect(() => {
    loadSensorGroupSensors();
  }, [sensorGroupsSensorsData]);

  useEffect(() => {
    loadSGUG();
  }, [SGUGData]);

  useEffect(() => {
    let res = filterObjectByValue(sensorGroupSensors, "SensorGroupID", groupValue.value);
    setfilterGroupSensors(res);
  }, [groupValue, sensorGroupSensors]);

  useEffect(() => {
    let res = filterObjectByValue(SGUG, "UserGroupID", userGroup.value);
    setFilterSGUG(res);
  }, [userGroup, SGUG]);

  return (
    <Box sx={{ m: 2, height: "83vh", p: 1, mt: 6 }}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          sm={12}
          md={5}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={12}
            >
              <CreatableSelect
                isDisabled={addSensorGroupLoading}
                isLoading={addSensorGroupLoading}
                placeholder="Sensor Group"
                onChange={handleChange}
                onInputChange={handleInputChange}
                onCreateOption={handleCreate}
                options={filterGroup}
                value={findLabel(filterGroup, groupValue) || ""}
                styles={selectStyles}
              />
            </Grid>
            <Grid
              item
              sm={12}
            >
              <Select
                placeholder="Sensor"
                options={sensors}
                onChange={(e) => setSensor(e)}
                styles={selectStyles}
                value={sensor || ""}
              />
            </Grid>
            <Grid
              item
              sm={12}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <div onClick={handleCancel}>
                  <ThemeButton
                    sx={{ mr: 2 }}
                    variant="outlined"
                    fullWidth
                    color="secondary"
                  >
                    Cancel
                  </ThemeButton>
                </div>
                <Box sx={{ mx: 1 }} />
                <div onClick={() => addSensorToGroup(groupValue.value, sensor.value)}>
                  <ThemeButton
                    color="primary"
                    disabled={buttonValidation}
                  >
                    Add Sensor to Group
                  </ThemeButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* TABLE SPACE FOR USERS */}
        <Grid
          item
          md={7}
        >
          <TableContainer
            sx={{ height: "200px", maxHeight: "200px" }}
            component={Paper}
          >
            <Table
              stickyHeader
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={classes.bold}>
                    {groupValue.label ? groupValue.label : "Select a Sensor Group"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterGroupSensors?.map((row, i) => {
                  let sensorName = findObjectByValue(sensors, "value", row.SensorID);
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{sensorName?.label || "no sensor"}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Divider sx={{ m: 1, mb: 3, mt: 3 }} />
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          md={5}
        >
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
            >
              <Select
                placeholder="User Group"
                styles={selectStyles}
                options={userGroups}
                onChange={(e) => setUserGroup(e)}
                value={userGroup || ""}
              />
            </Grid>
            <Grid
              item
              sm={12}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <div onClick={() => handleUserGroupCancel()}>
                  <ThemeButton
                    sx={{ mr: 2 }}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </ThemeButton>
                </div>
                <Box sx={{ mx: 1 }} />
                <div
                  onClick={() => handleAddSenorGroupToUserGroup(groupValue.value, userGroup.value)}
                >
                  <ThemeButton
                    color="primary"
                    disabled={UGSGbuttonValidation}
                  >
                    User Group to Sensor Group
                  </ThemeButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={7}
        >
          <Grid
            item
            md={12}
          >
            <TableContainer
              sx={{ height: "200px", maxHeight: "200px" }}
              component={Paper}
            >
              <Table
                stickyHeader
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={classes.bold}>
                      {userGroup.label ? userGroup.label : "Select a User Group"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterSGUG?.map((row, i) => {
                    let SensorGroupName = findObjectByValue(
                      filterGroup,
                      "value",
                      row.SensorGroupID
                    );
                    console.log(
                      "🚀 ~ file: index.js ~ line 376 ~ {filterSGUG?.map ~ SensorGroupName",
                      SensorGroupName
                    );

                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{SensorGroupName?.label || "no sensor group"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SensorGroups;
