import React from "react";
import Box from "@mui/material/Box";

const SetupSpinnerContainer = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "stretch",
          alignItems: "center",
          height: "calc(100vh - 150px)",
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

export default SetupSpinnerContainer;
