import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";

//COMPONENTS
import CustomersSetup from "@Routes/Setup/Customers/CustomersSetup";
import EquipmentSetup from "@Routes/Setup/Equipment/EquipmentSetup";
import SubstationSetup from "../../Routes/Setup/Substations";
import Users from "@Routes/Setup/Users/UsersSetup";
import SensorGroups from "@Routes/Setup/SensorGroupSetup";
import ArchivedSetup from "@Routes/Setup/Archived";
import Map from "../../Routes/Setup/Maps/MapSetup";
import LocationSetup from "../../Routes/Setup/Location/LocationSetup";
import SensorSetup from "../../Routes/Setup/Sensor/SensorSetup";
import AlertProgramSetup from "../../Routes/Setup/AlertProgram/AlertProgramSetup";
import Roles from "../../Routes/Setup/Roles/RolesSetup";

const pageName = "setup";
//pageName/ElementName UI
const TABS = [
  {
    value: `/${pageName}/customers`,
    label: "Customers",
    component: <CustomersSetup />,
  },
  {
    value: `/${pageName}/locations`,
    label: "Locations",
    component: <LocationSetup />,
  },
  {
    value: `/${pageName}/maps`,
    label: "Maps",
    component: <Map />,
  },
  {
    value: `/${pageName}/equipment`,
    label: "Equipment",
    component: <EquipmentSetup />,
  },
  {
    value: `/${pageName}/substations`,
    label: "Substations",
    component: <SubstationSetup />,
  },
  {
    value: `/${pageName}/sensors`,
    label: "Sensors",
    component: <SensorSetup />,
  },
  {
    value: `/${pageName}/alertprograms`,
    label: "Alert Programs",
    component: <AlertProgramSetup />,
  },
  { value: `/${pageName}/users`, label: "Users", component: <Users /> },
  { value: `/${pageName}/roles`, label: "Roles", component: <Roles /> },

  {
    value: `/${pageName}/sensorgroups`,
    label: "Sensor Groups",
    component: <SensorGroups />,
  },
  // {
  //   value: `/${pageName}/sensoralerts`,
  //   label: "SensorAlerts",
  //   component: <SensorAlerts />,
  // },
  {
    value: `/${pageName}/archived`,
    label: "Archived",
    component: <ArchivedSetup />,
  },
];

//if(aut.page==tabs.value.split("/"))

function TabPanel(props) {
  const { children, value, index, val, currentPanel, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentPanel !== value}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {currentPanel === value && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function TabComponents() {
  const routeMatch = useRouteMatch(TABS.map((t) => t.value));
  const [currentTab, setCurrentTab] = React.useState(routeMatch?.pattern?.path);
  const [tabs, setTabs] = useState([]);

  // const [selectedTab, setSelectedTab] = React.useState(currentTab);
  const auth = useSelector((state) => state.auth);
  const Authorizations = auth.Authorizations;

  useEffect(() => {
    setTabs(checkAuthorization());
  }, [auth]);

  useEffect(() => {
    if (tabs.length > 0 && !currentTab) {
      setCurrentTab(tabs[0].value);
    }
    if (routeMatch?.pattern?.path) {
      setCurrentTab(routeMatch?.pattern?.path);
    }
  }, [tabs, routeMatch]);

  const checkAuthorization = () => {
    if (typeof Authorizations !== "undefined") {
      return TABS.filter((tab) => {
        return Authorizations[pageName]?.some(
          (authorization) =>
            authorization.element.replace(/\s+/g, "") === tab.label.replace(/\s+/g, "")
        );
      });
    }
    return [];
  };

  return (
    <Box sx={{ bgcolor: "background.paper", borderRadius: "4px", width: "100%" }}>
      {tabs.map((tab, key) => {
        return (
          <TabPanel
            key={tab.value}
            currentPanel={currentTab}
            value={tab.value}
            index={key}
          >
            {tab.component}
          </TabPanel>
        );
      })}
    </Box>
  );
}
