import React from "react";
import IconButton from "@mui/material/IconButton";
import { primary, blueAction, lightGrey } from "../../../Styles/theme/colors";

function ActionButton(props) {
  return (
    <IconButton
      {...props}
      sx={{
        ...props.sx,
        ml: 1,
        fontSize: "16px",
        fontWeight: "medium",
        height: 32,
        width: 32,
        px: 1,
        py: 0.3,
        color: "#9CA1A6",
        "&:hover": {
          backgroundColor: "#ECEDEE",
          color: "#686E77",
        },
        "&:active": {
          color: lightGrey["50"],
          backgroundColor: primary["700"],
          borderStyle: "solid",
          borderWidth: 1,
        },
        "&:focus": {
          backgroundColor: "#ECEDEE",
          color: "#686E77",
          borderColor: blueAction["500"],
          borderStyle: "solid",
          borderWidth: "1px",
        },
        "&:disabled": {
          color: primary["500"],
          borderColor: primary["500"],
          borderStyle: "solid",
          borderWidth: "1px",
          opacity: 0.65,
        },
      }}
    >
      {props.children}
    </IconButton>
  );
}

export default ActionButton;
