import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UTCTimeZones } from "../../../Utils/constants";
import ListItemText from "@mui/material/ListItemText";
import "./style.css";
import CheckBoxComponent from "../Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CustomTimeZone(props) {
  const { timezone, setTimezone, onChange } = props;

  const [inFocus, setInFocus] = React.useState(false);
  // const [timezone, setTimezone] = React.useState([
  //   'Pacific/Niue',
  //   'Pacific/Pago_Pago',
  // ]);
  const handleChange = (event) => {
    setTimezone(event.target.value);
  };

  const DropdownIndicator = () => {
    if (inFocus)
      return (
        <i
          class="fa-light fa-chevron-up"
          style={{ fontSize: "16px", marginRight: "12px", color: "#9CA1A6" }}
        ></i>
      );
    else
      return (
        <i
          class="fa-light fa-chevron-down"
          style={{ fontSize: "16px", marginRight: "12px", color: "#9CA1A6" }}
        ></i>
      );
  };
  return (
    <div>
      <FormControl
        variant="outlined"
        size="small"
        margin="dense"
        sx={{ height: 40, width: "520px", marginTop: "0px" }}
      >
        <Select
          sx={{
            marginTop: "10px",
            border: "1px solid #9CA1A6",
            "& .MuiInputBase-input.Mui-disabled": {
              background: "#F1F1F1 0% 0% no-repeat padding-box",
              color: "#C0C3C7",
              border: "1px solid #C0C3C7",
              borderRadius: "4px",
            },
            "&:hover": {
              border: "1px solid #17455E",
              borderRadius: "4px",
            },
            "&.Mui-focused fieldset": {
              boxShadow: "#00AEEF 0px 0px 0px 3px",
            },
          }}
          multiple
          displayEmpty
          value={timezone}
          onChange={onChange}
          input={<OutlinedInput />}
          IconComponent={DropdownIndicator}
          onClose={() => setInFocus(false)}
          onOpen={() => setInFocus(true)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <p
                  style={{
                    font: "16px/24px Roboto",
                    color: "#9CA1A6",
                  }}
                >
                  Select timezone(s)
                </p>
              );
            }

            return selected
              .map((item) => {
                return UTCTimeZones.find((obj) => obj.value == item).label;
              })
              .join(", ");
          }}
          MenuProps={MenuProps}
        >
          {UTCTimeZones.map((zone) => {
            return (
              <MenuItem
                className="menuItem"
                key={zone.value}
                value={zone.value}
                sx={{
                  font: "normal normal medium 14px/20px Roboto",
                  color: "#222934",
                  "&:hover": {
                    background: "#E3E9EC 0% 0% no-repeat padding-box",
                    color: "#17455E",
                  },
                  "&$selected": {
                    // <-- mixing the two classes
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CheckBoxComponent
                  className="checkBox"
                  checked={timezone.indexOf(zone.value) > -1}
                />
                <ListItemText primary={zone.label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
