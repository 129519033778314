import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Label from "@Components/BaseComponents/Label";
import { TextField, Grid, Box } from "@mui/material";
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        boxShadow: "#00AEEF 0px 0px 0px 3px",
        border: "1px solid #17455E",
      },
    },
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
});
const TextFieldComponent = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sx={{ paddingTop: props.padding ? "24px !important" : "0px !important" }}
    >
      <Label
        sx={{
          color: "#9CA1A6",
        }}
      >
        {props.Label}
      </Label>
      <Box sx={{ paddingTop: props.paddingTop ? props.paddingTop : "0px" }}>
        <TextField
          id={props.Label}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value || ""}
          name={props.name}
          error={props.error || props.customError}
          helperText={
            props.helperText || props.customError
              ? props.customError
              : props.error && `${props.name} cannot be empty`
          }
          variant={props.variant}
          fullWidth={props.fullWidth}
          size="small"
          className={classes.root}
          inputProps={{ ...props.InputProps, maxLength: props.maxLength }}
          autoComplete="new-password"
          disabled={props.disabled}
          multiline={props.multiline}
          rows={props.rows}
          maxRows={Infinity}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#222934",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              fontSize: "16px",
              border: "1px solid #9CA1A6",
              borderRadius: "4px",
              ":hover": { border: "1px solid #17455E", borderRadius: "4px" },
              height: props.height,
            },
            "&.Mui-focused": {
              boxShadow: "#00AEEF 0px 0px 0px 4px",
              border: "1px solid #17455E",
            },
            "& .MuiInputBase-root.Mui-disabled": {
              background: "#F1F1F1 0% 0% no-repeat padding-box",
              color: "#C0C3C7",
              border: "1px solid #C0C3C7 !important",
              borderRadius: "4px",
            },
          }}
          maxLength={props.maxLength}
        />
      </Box>
    </Grid>
  );
};

export default TextFieldComponent;
