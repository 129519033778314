import React, { useContext } from "react";
import { DrawerContext } from "../Context/DrawerContext";

const SetupContainer = (props) => {
  const { open } = useContext(DrawerContext);

  const tableWidth = open ? "calc(100vw - 260px)" : "calc(100vw - 80px)";

  return (
    <div style={{ minWidth: tableWidth, minHeight: "calc(100vh - 180px)" }}>{props.children}</div>
  );
};

export default SetupContainer;
