import React from "react";
import { Box } from "@mui/material";

import DrawerComponent from "../BaseComponents/Drawer";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import ThemeButton from "@Components/Buttons";

function DiscardDrawer({
  discardSettingsDrawer,
  confirmationDrawer,
  edit,
  loadItem,
  setUpdated,
  handleDiscard,
  handleClick,
  buttonValidation,
  itemButtonValidation,
  discardButtonTitle,
}) {
  return (
    <DrawerComponent
      drawer={discardSettingsDrawer}
      toggleDrawer={confirmationDrawer}
      title="Save changes?"
      edit={false}
      displayFooter={false}
      closeToast={confirmationDrawer}
      loadUser={() => edit && loadItem()}
      setUserUpdated={setUpdated}
    >
      <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
        <Box
          sx={{
            width: "530px",
            textAlign: "left",
            marginBottom: "15px",
            font: "normal normal normal 20px/24px Roboto",
          }}
        >
          You have unsaved changes.
        </Box>
        <Box
          sx={{
            width: "530px",
            textAlign: "left",
            marginBottom: "15px",
            font: "normal normal normal 16px/24px Roboto",
            color: "#222934",
          }}
        >
          Would you want to save your changes before closing?
        </Box>
        <Box sx={{ display: "flex" }}>
          <div
            style={{ display: "inline", marginRight: "25px" }}
            onClick={(e) => handleDiscard(false)}
          >
            <OutlinedButton
              variant="outlined"
              color="primary"
              size="small"
              width="248px"
            >
              DISCARD
            </OutlinedButton>
          </div>
          <ThemeButton
            variant="contained"
            color="primary"
            size="small"
            width="248px"
            disabled={itemButtonValidation}
            onClick={() => {
              setUpdated(false);
              handleClick("Save Item");
              confirmationDrawer(false);
            }}
          >
            {discardButtonTitle ? discardButtonTitle : "SAVE"}{" "}
          </ThemeButton>
        </Box>
      </Box>
    </DrawerComponent>
  );
}

export default DiscardDrawer;
