import { useState } from "react";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import Toast from "@Components/BaseComponents/Toast";
import { Box } from "@mui/material";

import moment from "moment";
import "./style.css";
import { useAcknowledgeSensorsMutation } from "../../Store/Slices/sensorAcknowledgeSlice";
import SensorAcknowledegeDetails from "./SensorAcknowledgeDetails";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
];

const durationTypes = [
  { label: "Time duration", value: 0 },
  { label: "Future date", value: 1 },
  { label: "Indefinitely", value: 2 },
];

function convertMsToReadableDate(ms) {
  const date = moment(ms);
  return date.format("YYYY-MM-DD HH:mm:ss");
}

const SensorAcknowledegeDrawer = (props) => {
  const {
    toggleAcknowledgeDrawer,
    suppressDrawer,
    sensors,
    sensorTypes,
    sensorLimitTemplates,
    clearSensor,
    handleAcknowledgeDrawerEdit,
    editSensor,
  } = props;
  const [
    acknowledgeSensors,
    { isSuccess: acknowledgeSensorsSuccess, isError: acknowledgeSensorsError },
  ] = useAcknowledgeSensorsMutation();
  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [durationType, setDurationType] = useState({ value: null, label: "" });
  const [dateDisplay, setDateDisplay] = useState(moment().utc().add(2, "hours").valueOf());
  const [selectedDurationTime, setSelectedDurationTime] = useState(null);
  const [limits, setLimits] = useState([]);
  const confirmationDrawer = (action) => {
    setDiscardSettingsDrawer(action);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    toggleAcknowledgeDrawer(e);
  };

  const handleAcknowledge = async (reason) => {
    let res = await acknowledgeSensors({
      Sensors: sensors,
      reason,
    });
    if (res?.data?.success) {
      toggleAcknowledgeDrawer(false);
    }
  };
  return (
    <>
      {acknowledgeSensorsSuccess &&
        sensors.map((sensor, key) => (
          <Toast
            message={`${sensor?.SensorLabel} alarm successfully Acknowledged`}
            severity="success"
            autoHideDuration={5000}
          />
        ))}
      <ResourceDrawerComponent
        drawer={suppressDrawer}
        toggleDrawer={toggleAcknowledgeDrawer}
        title={"Acknowledge Alarms"}
        submitButtonTitle={"ACKNOWLEDGE"}
        handleSubmit={handleAcknowledge}
        displayFooter={true}
        closeToast={clearSensor}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
      >
        <Box className="drawerPadding">
          {sensors.length && sensors.map((val) =>
            val?.LiveData?.State === 4 && !val?.LiveData?.Acknowledged ? (
              <SensorAcknowledegeDetails
                sensor={val}
                sensorLimitTemplates={sensorLimitTemplates}
                sensorTypes={sensorTypes}
              />
            ) : null
          )}
        </Box>
      </ResourceDrawerComponent>
    </>
  );
};

export default SensorAcknowledegeDrawer;
