import { apiSlice } from "../API";

export const sensorAlertCalendarTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorAlertCalendarType: builder.query({
      query: () => "/sensorAlertCalendarType",
      providesTags: ["sensorAlertCalendarType"],
    }),
    getSensorAlertCalendarType: builder.query({
      query: (id) => `/sensorAlertCalendarType/${id}`,
    }),
    addSensorAlertCalendarType: builder.mutation({
      query: (sensorAlertCalendarType) => ({
        url: "/sensorAlertCalendarType",
        method: "POST",
        body: sensorAlertCalendarType,
      }),
      invalidatesTags: ["sensorAlertCalendarType"],
    }),
    editSensorAlertCalendarType: builder.mutation({
      query: ({ id, sensorAlertCalendarType }) => ({
        url: "/sensorAlertCalendarType",
        method: "PUT",
        body: sensorAlertCalendarType,
      }),
      invalidatesTags: ["sensorAlertCalendarType"],
    }),
    deleteSensorAlertCalendarType: builder.mutation({
      query: (id) => ({
        url: `/sensorAlertCalendarType/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sensorAlertCalendarType"],
    }),
  }),
});

export const {
  useFindSensorAlertCalendarTypeQuery,
  useGetSensorAlertCalendarTypeQuery,
  useAddSensorAlertCalendarTypeMutation,
  useEditSensorAlertCalendarTypeMutation,
} = sensorAlertCalendarTypeSlice;
