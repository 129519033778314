import React, { useEffect, useState, useRef } from "react";
import { Grid, FormControl, Box, Typography } from "@mui/material";
import ThemeButton from "@Components/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import { useUploadImageMutation } from "../../../Store/Slices/imageSlice";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import { lightGrey } from "../../../Styles/theme/colors";
import Loader from "@Components/LoadingSpinner";

const Map = (props) => {
  const { location, handleChange, validation } = props;
  const [response, setResponse] = useState("");

  const inputRef = useRef(null);
  const inputNewRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleClickReplace = () => {
    // 👇️ open file input box on click of other element
    inputNewRef.current.click();
  };

  const useStyles = makeStyles({
    userItemPadding: {
      paddingTop: "16px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });
  const classes = useStyles();

  const [uploadImage, { isSuccess: imageUploadSuccess, isLoading: imageUploadLoading }] =
    useUploadImageMutation();

  const onSelectFile = async (event) => {
    console.log(event);
    let formData = new FormData();
    formData.append("myFile", event, event.name);
    var res = await uploadImage(formData);
    setResponse(res.data.success.data.fileId);
  };

  useEffect(() => {
    if (response !== "") {
      const data = { name: "MapFile", value: response };

      handleChange({ target: data });
    }
  }, [response]);

  const handleRemoveMenu = () => {
    const data = { name: "MapFile", value: "" };

    handleChange({ target: data });
  };

  console.log(imageUploadLoading);
  return (
    <>
      {imageUploadLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Loader />
        </Box>
      )}
      {location.MapFile && validation ? (
        <>
          <Typography
            variant="caption"
            sx={{ color: lightGrey["900"] }}
          >
            Map preview
          </Typography>
          <img
            src={`${process.env.REACT_APP_URL}/asset/download/${location.MapFile}`}
            alt="Image loading error"
            style={{ border: "1px solid #9CA1A6", borderRadius: "4px" }}
            width={520}
          />
          <FormControl
            margin="none"
            fullWidth
          >
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                paddingTop: "24px",
                marginLeft: 0,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <OutlinedButton
                onClick={handleRemoveMenu}
                width="48%"
                variant="outlined"
                color="error"
                size="small"
              >
                <i
                  class="fa-solid fa-upload"
                  style={{ marginRight: "10px" }}
                ></i>
                Remove map{" "}
              </OutlinedButton>
              <ThemeButton
                disabled={!validation && location.ParentID != ""}
                variant="contained"
                color="primary"
                size="small"
                width="48%"
                onClick={() => handleClickReplace()}
              >
                <input
                  hidden
                  placeholder=""
                  ref={inputNewRef}
                  type="file"
                  accept="image/*"
                  onChange={(event) => onSelectFile(event.target.files[0])}
                />
                <i
                  class="fa-solid fa-upload"
                  style={{ marginRight: "10px" }}
                ></i>
                Replace map{" "}
              </ThemeButton>
            </Grid>
          </FormControl>
        </>
      ) : (
        <FormControl
          margin="none"
          fullWidth
        >
          <Grid
            container
            spacing={1}
            sx={{ mt: 1 }}
          >
            <ThemeButton
              disabled={!validation && location.ParentID != ""}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={() => handleClick()}
            >
              <input
                hidden
                placeholder=""
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={(event) => onSelectFile(event.target.files[0])}
              />
              <p>
                <i
                  class="fa-solid fa-upload"
                  style={{ marginRight: "10px" }}
                ></i>
                Upload map{" "}
              </p>
            </ThemeButton>
          </Grid>
        </FormControl>
      )}
    </>
  );
};

export default Map;
