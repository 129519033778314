import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

function IconButtonComponent(props) {
  const { Component, onClick, sx, size, color } = props;
  return (
    <Box sx={sx}>
      <IconButton
        size={size}
        sx={{
          color: { color },
          width: "32px",
          height: "32px",
          borderRadius: "4px",
          fontSize: "20px",
          lineHeight: "25px",
          fontWeight: "300",
          "&:hover": {
            background: "#ECEDEE 0% 0% no-repeat padding-box",
            color: color || "#686E77",
          },
          "&:focus-visible": {
            border: "3px solid #00AEEF80",
            background: "#ECEDEE 0% 0% no-repeat padding-box",
          },
          "&:active": {
            background: "#11364C 0% 0% no-repeat padding-box",
            color: "#FFFFFF",
          },
        }}
        onClick={onClick}
      >
        {Component}
      </IconButton>
    </Box>
  );
}

export default IconButtonComponent;
