import React, { Fragment, useState, useEffect, useMemo } from "react";
import EquipmentTable from "../../../Components/Tables/EquipmentTable";
import Toast from "@Components/BaseComponents/Toast";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import {
  useFindEquipmentSensorTypesQuery,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useDeleteEquipmentMutation,
  useLiveEquipmentQuery,
} from "../../../Store/Slices/equipmentSlice";

import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";
import { useCustomerLocations } from "../../../Hooks/useCustomerLocation";
import { AuthorizedEquipmentTypes } from "../../../Utils/constants";
import {
  filterObjectByValue,
  findObjectByValue,
  locationsString,
  validateUIState,
} from "../../../Utils/Tools";
import EquipmentDrawer from "@Components/RightDrawer/EquipmentDrawer";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { useSelector } from "react-redux";
import {
  useAddSensorConfigMutation,
  useAddSensorMutation,
  useEditSensorConfigMutation,
  useEditSensorMutation,
  useFindSensorsQuery,
} from "../../../Store/Slices/sensorSlice";
import {
  useFindMappedEquipmentsQuery,
  useDeleteMappedEquipmentMutation,
} from "../../../Store/Slices/mappedEquipmentSlice";
import { useFindSensorLimitTemplateQuery } from "../../../Store/Slices/sensorLimitTemplateSlice";
import { useAddArchiveItemsMutation } from "../../../Store/Slices/archiveSlice";

import SensorDrawer from "@Components/RightDrawer/SensorDrawer";

import { useFindActiveSubstationsQuery } from "../../../Store/Slices/substationSlice";

import { handleLimitChange, useSensorEdit, handleSensorSaveOrEdit } from "../Sensor/sensorUtils";

import moment from "moment";
import SensorSuppressDrawer from "../../../Components/RightDrawer/SensorSuppressDrawer";
import EquipmentAlarmSupressDrawer from "../../../Components/RightDrawer/EquipmentAlarmSupressDrawer";

const EquipmentTypeSetup = () => {
  const [drawer, setDrawer] = useState(false);
  const [suppressDrawer, setSuppressDrawer] = useState(false);
  const [equipmentAlarmSuppressDrawer, setEquipmentAlarmSuppressDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationsEquipment, setLocationsEquipment] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentSensors, setEquipmentSensors] = useState([]);
  const [equipmentLive, setEquipmentLive] = useState([]);
  const [equipment, setEquipment] = useState({
    EquipmentName: "",
    EquipmentTypeID: "",
    LocationID: "",
  });
  const [sensorUpdated, setSensorUpdated] = useState(false);
  const [tempEquipment, setTempEquipment] = useState({});
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [suppressSensorTypes, setSuppressSensorTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [edit, setEdit] = useState(false);
  const [sensorEdit, setSensorEdit] = useState(false);
  const [equipmentUpdated, setEquipmentUpdated] = useState(false);
  const [equipmentActivated, setEquipmentActivated] = useState(false);
  const [sensorDrawer, setSensorDrawer] = useState(false);
  const [tempSensor, setTempSensor] = useState({});
  const [selectedSensorLimitTemplate, setSelectedSensorLimitTemplate] = useState({});
  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [requireActionDrawer, setRequireActionDrawer] = useState(false);
  const [requireAuthenticationDrawer, setRequireAuthenticationDrawer] = useState(false);
  const [sensorStates, setSensorStates] = useState([]);
  const [selectedSensorIds, setSelectedSensorIds] = useState([]);
  const [delSensor, setDelSensor] = useState("");
  const [activateSensor, setActivateSensor] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [equipmentAlarmSuppress, setEquipmentAlarmSuppress] = useState({ Sensors: [] });
  //substation states
  const [substationData, setSubstationData] = useState({});
  const [substations, setSubstations] = useState([]);
  const auth = useSelector((state) => state.auth);

  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Equipment");

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const initialSensor = {
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{}",
  };

  const [sensor, setSensor] = useState(initialSensor);

  const [msg, setmsg] = useState("");
  const [tempEquipmentID, setTempEquipmentID] = useState("");

  const menuItems = {
    1: "suppress",
    2: "suppress",
    3: "unsuppress",
  };

  const { data: mappedEquipmentData, isSuccess: mappedEquipmentSuccess } =
    useFindMappedEquipmentsQuery();

  const [deleteMappedEquipment, { isSuccess: deleteMappedEquipmentSuccess }] =
    useDeleteMappedEquipmentMutation();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const {
    data: equipmentLiveData,
    isLoading: equipmentLiveDataLoading,
    isSuccess: equipmentLiveDataSuccess,
    isError: equipmentLiveDataIsError,
    error: equipmentLiveDataError,
  } = useLiveEquipmentQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
    refetch: substationsDataRefetch,
  } = useFindActiveSubstationsQuery();

  let data = {};
  if (!isAdmin) {
    data = { value: auth.CustomerID };
  } else {
    data = { value: auth.SelectedCustomer };
  }
  const [filterLocations, locationsEquipmentLoading] = useCustomerLocations(data.value);
  const [
    addEquipment,
    { isSuccess: addEquipmentSuccess, isError: addEquipmentIsError, error: addEquipmentError },
  ] = useAddEquipmentMutation();

  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [deleteEquipment, { isSuccess: deleteEquipmentSucces }] = useDeleteEquipmentMutation();

  const { data: sensors } = useFindSensorsQuery();

  const [
    addSensor,
    { isSuccess: isSensorSuccess, isError: AddSensorIsError, error: AddSensorError },
  ] = useAddSensorMutation();
  const [
    addArchive,
    { isSuccess: addArchiveSuccess, isError: addArchiveIsError, error: addArchiveError },
  ] = useAddArchiveItemsMutation();

  const [editSensor, { isSuccess: isSensorEditSuccess }] = useEditSensorMutation();
  const [editSensorConfig] = useEditSensorConfigMutation();
  const [addSensorConfig] = useAddSensorConfigMutation();

  const loadEquipmentTypes = async () => {
    let uniqueLabels = new Set();
    if (equipmentTypesLoading) setLoading(true);
    if (equipmentTypesSuccess) {
      const equipmentTypes = equipmentTypesData.success.data
        .map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
        .filter((equipmentType) => {
          if (
            AuthorizedEquipmentTypes?.includes(equipmentType.label) &&
            !uniqueLabels.has(equipmentType.label)
          ) {
            uniqueLabels.add(equipmentType.label);
            return true;
          }
          return false;
        });
      setEquipmentTypes(equipmentTypes);
    }

    if (equipmentTypesIsError) console.log(equipmentTypesError);
    setLoading(false);
  };

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      let locationsInfo = {};
      setLocations(locationsData);

      for (const location of locationsData) {
        locationsInfo[location.LocationID] = locationsString(location, locationsData);
      }
      setLocationsHierarchy(locationsInfo);
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateLoading) setLoading(true);
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
    setLoading(false);
  };

  const loadEquipment = () => {
    let filterMenuItems = [];
    if (equipmentLoading) setLoading(true);
    if (equipmentSuccess && equipmentLiveDataSuccess) {
      const tempEquipments = equipmentLiveData.success.data
        .map((val) => {
          let filteredSensors = val.Sensors.filter(
            (sensor) => sensor.LiveData && sensor.IsActive && sensor.LiveData.State
          ).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf());
          let sensorStates = Array.from(
            new Set(
              filteredSensors.map((sensor) =>
                sensor.LiveData.Suppressed ? 3 : sensor.LiveData.State
              )
            )
          );
          filterMenuItems = filterMenuItems.concat(sensorStates);
          return {
            EquipmentID: val.EquipmentID,
            EquipmentName: val.EquipmentName,
            EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
            EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
            LocationName: val.Location?.LocationName,
            LocationID: val.LocationID,
            CustomerID: val.Location?.Customer.CustomerID,
            CustomerName: val.Location?.Customer.CustomerName,
            updatedAt: val.updatedAt,
            Sensors: val?.Sensors || [],
            MenuOptions: sensorStates.map((state) => menuItems[state]),
            equipmentSensorTypes: val.equipmentSensorTypes,
            IsActive: !val.IsArchive,
          };
        })
        .filter((row) => row.CustomerID);
      if (auth.SelectedCustomer == "") setEquipments(tempEquipments);
      else {
        setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.SelectedCustomer));
      }
      setSensorStates(Array.from(new Set(filterMenuItems)));
      setSelectedSensorIds(
        tempEquipments.map((eq) => eq.Sensors.map((sensor) => sensor.SensorID)).flat()
      );
    }
    if (equipmentIsError) console.log(equipmentError);
    setLoading(false);
  };

  const loadSubstations = () => {
    if (substationsSuccess) {
      const tempSubstations = substationsData.map((substation) => {
        return {
          ...substation,
          value: substation.SubstationID,
          label: substation.Label,
          SerialNumber: substation.SerialNumber,
          MeasurementInterval: substation.MeasurementInterval,
        };
      });
      if (auth.SelectedCustomer == "") {
        setSubstations(tempSubstations);
      } else {
        setSubstations(filterObjectByValue(tempSubstations, "CustomerID", auth.SelectedCustomer));
      }
      setLoading(false);
    }
    if (isSubstationError) console.log(substationError);
  };

  const mappedEquipments = useMemo(() => {
    return (
      equipmentSuccess &&
      mappedEquipmentSuccess &&
      mappedEquipmentData?.success?.data
        .map((equipment) => {
          const equipmentObj = findObjectByValue(
            equipmentData,
            "EquipmentID",
            equipment?.EquipmentID
          );
          return {
            ...equipment,
            IsActive: equipmentObj?.IsActive || false,
          };
        })
        .filter((val) => {
          return val.IsActive === true;
        })
    );
  }, [mappedEquipmentData?.success?.data, equipmentSuccess, mappedEquipmentSuccess, equipmentData]);

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess) {
      setSensorTypes(sensorTypeData.success.data);
      setSuppressSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    }
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };

  const handleClick = (reason) => {
    const updatedEquipment = { ...equipment, reason };
    edit ? handleEditSave(updatedEquipment) : handleEquipmentSave(updatedEquipment);
    setEquipmentUpdated(false);
  };

  const handleSensorDelete = async (Sensor, reason) => {
    const status = !Sensor.IsActive;
    setDelSensor(!status);
    setActivateSensor(status);
    await addArchive({ data: { Sensor: [Sensor.SensorID] }, reason: reason });
    setSensorEdit(false);
    customToggleSensorDeleteDrawer(false);
    sensorToggleDrawer(false);
  };

  const handleAlertSuppression = (sensor, action) => {
    handleSensorEdit(sensor);
    toggleSuppressDrawer(action);
    setSensorDrawer(!action);
    setDrawer(!action);
  };

  const handleEquipmentSupressAlarms = (equipment) => {
    setEquipmentAlarmSuppress(equipment);
    toggleAlarmSuppressDrawer(true);
  };

  const handleSuppressDrawerEdit = (action) => {
    toggleSuppressDrawer(!action);
    setDrawer(!action);
    setSensorDrawer(action);
  };

  //DRAWER HANDLERS
  const [customerDrawer, setCustomDrawer] = useState(false);

  const customToggleDrawer = (open) => {
    setCustomDrawer(open);
  };
  const [equipmentDeleteDrawer, setEquipmentDeleteDrawer] = useState(false);

  const customToggleEquipmentDeleteDrawer = (open) => {
    setEquipmentDeleteDrawer(open);
  };

  const [sensorDeleteDrawer, setSensorDeleteDrawer] = useState(false);

  const customToggleSensorDeleteDrawer = (open) => {
    setSensorDeleteDrawer(open);
  };

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  useEffect(() => {
    if (equipmentLiveDataSuccess) {
      setEquipmentLive(equipmentLiveData.success.data);
    }
  }, [equipmentLiveData]);

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData, auth, equipmentLiveData]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  useEffect(() => {
    setLocationsEquipment(filterLocations);
  }, [filterLocations]);

  useEffect(() => {
    if (!delSensor) return;
    const timeout = setTimeout(() => {
      setDelSensor(false);
      setActivateSensor(false);
    }, 8000);

    return () => clearTimeout(timeout);
  }, [delSensor]);

  const clearEquipment = () => {
    setEquipment({
      EquipmentName: "",
      EquipmentTypeID: "",
      LocationID: "",
    });
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (open === false) {
      clearEquipment();
      setEdit(false);
    }
  };

  const toggleSuppressDrawer = (action) => {
    setSuppressDrawer(action);
  };
  const toggleAlarmSuppressDrawer = (action) => {
    setEquipmentAlarmSuppressDrawer(action);
  };
  const sensorToggleDrawer = (open, equipmentId) => {
    setSensorDrawer(open);
    setSensor({...initialSensor, EquipmentID: equipmentId});
    if (edit) {
      setDrawer(!open);
    }
    if (open === false) {
      clearSensor();
      setSensorEdit(false);
      setTempEquipmentID("");
    }
  };

  const equipmentConfirmationDrawer = (open) => {
    if (equipmentUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };

  const clearAuthenticationDrawer = () => {};

  const clearSensor = () => {
    setSensor(initialSensor);
    setSensorEdit(false);
    setSelectedSensorLimitTemplate({});
    if (edit) {
      toggleDrawer(true);
    }
  };

  const handleEdit = (Equipment) => {
    toggleDrawer(true);
    setEdit(true);
    setTempEquipment(Equipment);
    setEquipment(Equipment);
    setEquipmentSensors(filterObjectByValue(equipmentLive, "EquipmentID", Equipment.EquipmentID));
  };

  const sensorButtonValidation = !(
    sensor.SensorLabel !== "" &&
    sensor.SensorTypeID !== "" &&
    sensor.EquipmentID !== "" &&
    sensor.SensorLimitTemplateID !== "" &&
    sensor.Port !== "" &&
    sensor.SubstationSerial !== "" &&
    sensor.CustomerID !== ""
  );

  const handleEquipmentSave = async (equipmentEdit) => {
    let data = equipmentEdit;
    let res = await addEquipment(data).unwrap();
    const savedEquipment = res.success.data;

    customToggleDrawer(false);
    toggleDrawer(false);
    confirmationDrawer(false);

    if (res.error) {
      setmsg(res.error.data.error.message);
    }
    if (savedEquipment) {
      setEquipment({
        label: savedEquipment.EquipmentName,
        value: savedEquipment.EquipmentID,
        LocationID: savedEquipment.LocationID,
      });
    }
  };

  const equipmentButtonValidation = !(
    equipment.EquipmentTypeID !== "" &&
    equipment.EquipmentName !== "" &&
    equipment.LocationID !== ""
  );

  const handleEditSave = async (equipmentEdit) => {
    toggleDrawer(false);
    const id = equipment.EquipmentID;
    let data = equipmentEdit;
    let res = await editEquipment({ id, data });
    toggleDrawer(false);
    confirmationDrawer(false);
    setEdit(false);
  };

  const handleDelete = async (equipment, reason) => {
    const id = equipment.EquipmentID;
    await addArchive({ data: { Equipment: [id] }, reason: reason });

    const mappedEquipment = mappedEquipments?.find((eq) => {
      return eq.EquipmentID === equipment.EquipmentID;
    });

    if (mappedEquipment) {
      await deleteMappedEquipment({
        id: mappedEquipment.MappedEquipmentID,
        data: { reason: reason },
      });
    }
    let status = !equipment.IsActive;
    setEquipmentActivated(status);
    customToggleEquipmentDeleteDrawer(false);
    toggleDrawer(false);
  };

  const editEquipmentSuccesMessage = () => {
    if (equipmentActivated) {
      return "activated";
    } else {
      return "updated";
    }
  };

  const postExecution = () => {
    sensorToggleDrawer(false);
    confirmationDrawer(false);
    setSensorEdit(false);
    setSensorUpdated(false);
    setEquipmentUpdated(false);
    setEquipmentSensors([]);
    setTempSensor({});
    clearSensor();
    setTempEquipment("");
  };

  // SENSOR HANDLERS
  const handleSensorClick = async (reason) => {
    const sensorMutation = sensorEdit ? editSensor : addSensor;
    const sensorConfigMutation = sensorEdit ? editSensorConfig : addSensorConfig;

    await handleSensorSaveOrEdit(
      sensor,
      sensors,
      selectedSensorLimitTemplate,
      sensorMutation, // saveOrEditSensor parameter
      sensorConfigMutation, // saveOrEditSensorConfig parameter
      postExecution,
      sensorEdit, // isEdit parameter
      substationData,
      reason,
      tempEquipmentID
    );
    if (edit) {
      setDrawer(true);
    } else {
      setDrawer(false);
    }
  };

  const handleSensorEdit = useSensorEdit(
    sensorToggleDrawer,
    setSensorEdit,
    handleLimitChange,
    sensor,
    sensorLimitTemplates,
    setSelectedSensorLimitTemplate,
    setTempSensor,
    setSensor
  );

  const handleTableAdd = (EquipmentID) => {
    setTempEquipmentID(EquipmentID);
    sensorToggleDrawer(true);
  };

  return (
    <Fragment>
      {addArchiveSuccess && (
        <Toast
          message="Equipment successfully archived"
          severity="success"
        />
      )}
      {addEquipmentSuccess && (
        <Toast
          message="Equipment successfully added"
          severity="success"
        />
      )}
      {editEquipmentSucces && (
        <Toast
          message={`Equipment successfully ${editEquipmentSuccesMessage()}`}
          severity="success"
        />
      )}
      {addEquipmentError && (
        <Toast
          message="Equipment name is not unique"
          severity="error"
        />
      )}
      {deleteEquipmentSucces && (
        <Toast
          message="Equipment successfully removed"
          severity="success"
        />
      )}

      {isSensorSuccess && (
        <Toast
          message="Sensor successfully added"
          severity="success"
        />
      )}
      {isSensorEditSuccess && (
        <Toast
          message="Sensor successfully updated"
          severity="success"
        />
      )}
      {equipmentLoading ||
      equipmentLiveDataLoading ||
      sensorLimitTemplateLoading ||
      loading ||
      locationsEquipmentLoading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <EquipmentTable
            drawer={drawer}
            toggleDrawer={toggleDrawer}
            sensorToggleDrawer={sensorToggleDrawer}
            setLoading={setLoading}
            loading={loading}
            equipments={equipments}
            locations={locations}
            equipmentLive={equipmentLive}
            handleEdit={handleEdit}
            handleSensorEdit={handleSensorEdit}
            locationsHierarchy={locationsHierarchy}
            sensorTypes={sensorTypes}
            auth={auth}
            sensorLimitTemplates={sensorLimitTemplates}
            allowedEdit={allowedEdit}
            handleTableAdd={handleTableAdd}
            setSelectedAction={setSelectedAction}
            handleAlertSuppression={handleAlertSuppression}
            handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
          />

          <EquipmentDrawer
            edit={edit}
            sensorEdit={sensorEdit}
            clearEquipment={clearEquipment}
            equipment={equipment}
            tempEquipment={tempEquipment}
            setEquipment={setEquipment}
            equipmentSensors={equipmentSensors}
            equipmentTypes={equipmentTypes}
            locationsEquipment={locationsEquipment}
            handleClick={handleClick}
            equipmentLive={equipmentLive}
            sensorTypes={sensorTypes}
            drawer={drawer}
            toggleDrawer={equipmentConfirmationDrawer}
            equipmentToggleDrawer={toggleDrawer}
            customerDrawer={customerDrawer}
            customToggleDrawer={sensorToggleDrawer}
            deleteDrawer={equipmentDeleteDrawer}
            handleSensorEdit={handleSensorEdit}
            customToggleEquipmentDeleteDrawer={customToggleEquipmentDeleteDrawer}
            sensorDeleteDrawer={sensorDeleteDrawer}
            customToggleSensorDeleteDrawer={customToggleSensorDeleteDrawer}
            handleDelete={handleDelete}
            equipmentButtonValidation={equipmentButtonValidation}
            equipmentUpdated={equipmentUpdated}
            setEquipmentUpdated={setEquipmentUpdated}
            confirmationDrawer={confirmationDrawer}
            discardSettingsDrawer={discardSettingsDrawer}
            requireActionDrawer={requireActionDrawer}
            requireAuthenticationDrawer={requireAuthenticationDrawer}
            clearAuthenticationDrawer={clearAuthenticationDrawer}
          />
        </>
      )}
      <SensorDrawer
        sensorEdit={sensorEdit}
        customerDrawer={sensorDrawer}
        customToggleDrawer={sensorToggleDrawer}
        sensor={sensor}
        tempSensor={tempSensor}
        setSensor={setSensor}
        equipments={equipments.map((eq) => ({
          label: eq.EquipmentName,
          value: eq.EquipmentID,
          LocationID: eq.LocationID,
        }))}
        substations={substations}
        sensorLimitTemplates={sensorLimitTemplates}
        setSubstationData={setSubstationData}
        sensorUpdated={sensorUpdated}
        setSensorUpdated={setSensorUpdated}
        handleClick={handleSensorClick}
        clearSensor={clearSensor}
        selectedSensorLimitTemplate={selectedSensorLimitTemplate}
        setSelectedSensorLimitTemplate={setSelectedSensorLimitTemplate}
        handleLimitChange={handleLimitChange}
        sensorTypes={sensorTypeData?.success?.data?.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })}
        equipmentDrawer={true}
        handleDelete={handleSensorDelete}
        tempEquipmentID={tempEquipmentID}
        equipment={equipment}
        sensorButtonValidation={sensorButtonValidation}
        deleteDrawer={sensorDeleteDrawer}
        customToggleSensorDeleteDrawer={customToggleSensorDeleteDrawer}
      />

      <SensorSuppressDrawer
        suppressDrawer={suppressDrawer}
        toggleSuppressDrawer={toggleSuppressDrawer}
        sensor={sensor}
        sensorTypes={suppressSensorTypes}
        sensorLimitTemplates={sensorLimitTemplates}
        clearSensor={clearSensor}
        handleSuppressDrawerEdit={handleSuppressDrawerEdit}
      />

      <EquipmentAlarmSupressDrawer
        equipmentAlarmSuppress={equipmentAlarmSuppress}
        equipmentAlarmSuppressDrawer={equipmentAlarmSuppressDrawer}
        toggleAlarmSuppressDrawer={toggleAlarmSuppressDrawer}
        sensor={sensor}
        sensorTypes={suppressSensorTypes}
        sensorLimitTemplates={sensorLimitTemplates}
        clearSensor={clearSensor}
        handleSuppressDrawerEdit={handleSuppressDrawerEdit}
      />
    </Fragment>
  );
};

export default EquipmentTypeSetup;
