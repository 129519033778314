import React from "react";
import Button from "@mui/material/Button";
import { blueAction, lightGrey, primary } from "../../../Styles/theme/colors";
import ToolTipComponent from "../Tooltip";
import ActionIcon from "../ActionIcon";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function TableHeaderAddButton({ ...props }) {
  const { label, onClick, width } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box style={(matches ? null : { order: -1 }, { mr: 2, display: "flex" })}>
      {matches ? (
        <Button
          sx={{
            ml: 2,
            fontSize: "16px",
            fontWeight: "medium",
            borderColor: primary["500"],
            borderStyle: "solid",
            borderWidth: "1px",
            px: 1,
            py: 0.3,
            color: primary["500"],
            height: "32px",
            width: { width },
            "&:hover": {
              backgroundColor: primary["700"],
              color: lightGrey["50"],
              borderColor: primary["500"],
              borderStyle: "solid",
              borderWidth: 1,
            },
            "&:active": {
              color: lightGrey["50"],
              backgroundColor: primary["700"],
              borderStyle: "solid",
              borderWidth: 1,
            },
            "&:focus": {
              color: lightGrey["50"],
              backgroundColor: primary["700"],
              borderColor: blueAction["500"],
              borderStyle: "solid",
              borderWidth: "3px",
            },
            "&:disabled": {
              color: primary["500"],
              borderColor: primary["500"],
              borderStyle: "solid",
              borderWidth: "1px",
              opacity: 0.65,
            },
          }}
          variant="outlined"
          size="small"
          onClick={onClick}
        >
          <i
            class="fa-light fa-plus "
            style={{ marginRight: "5px" }}
          ></i>
          {label}
        </Button>
      ) : (
        <ToolTipComponent
          title={`Add ${label}`}
          Component={
            <ActionIcon onClick={onClick}>
              <i className="fa-solid fa-plus"></i>
            </ActionIcon>
          }
        />
      )}
    </Box>
  );
}

export default TableHeaderAddButton;
