import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Divider } from "@mui/material";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import ThemeButton from "@Components/Buttons";
import { useTheme } from "@mui/material/styles";
import TextButton from "@Components/Buttons/TextButton";
import IconButtonComponent from "@Components/BaseComponents/IconButton";
import ToolTipComponent from "../Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";

function DrawerComponent({ ...props }) {
  const {
    drawer,
    toggleDrawer,
    title,
    buttonTitle,
    handleClick,
    buttonValidation,
    edit,
    disableDelete,
    deactivateToggleDrawer,
    displayFooter,
    closeToast,
    user,
    loadUser,
    deactivateButtonTitle,
    deactivateButtonColor,
    actionLabel,
    archiveButton,
  } = props;
  const theme = useTheme();
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  const dynamicMobileStyle = maxWidthSm
    ? {
        zIndex: theme.zIndex.modal,
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      }
    : { zIndex: theme.zIndex.modal };
  return (
    <SwipeableDrawer
      sx={dynamicMobileStyle}
      anchor="right"
      open={drawer}
      onClose={() => {
        toggleDrawer(false);
        closeToast(false);
      }}
      onOpen={() => toggleDrawer(true)}
    >
      <Box
        sx={{
          height: "80px",
          padding: "24px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h2 className="drawer-title">{title}</h2>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <IconButtonComponent
            size="large"
            onClick={() => {
              toggleDrawer(false);
              if (loadUser) {
                loadUser();
              }
            }}
            Component={
              <i
                className="fa-regular fa-xmark fa-lg"
                style={{
                  fontSize: "32px",
                  lineHeight: "38px",
                }}
              ></i>
            }
          />
        </Box>
      </Box>
      <Divider
        sx={{
          boxShadow: "-3px 0px 6px #00000029",
        }}
      />
      <Box
        width={568}
        sx={{
          overflowY: "auto",
          height: "100%",
          zIndex: theme.zIndex.drawer + 1,
          width: maxWidthSm ? "100%" : "inherit",
        }}
      >
        {props.children}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      {displayFooter && (
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            justifyContent: edit == true ? "space-between" : "flex-end",
            alignContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Box>
            {edit && !disableDelete && archiveButton?.disabled && (
              <ToolTipComponent
                title={archiveButton.tooltipCopy}
                placement="left"
                Component={
                  <span>
                    <OutlinedButton
                      disabled={archiveButton.disabled}
                      onClick={() => deactivateToggleDrawer(true)}
                      width="fit-content"
                      variant="outlined"
                      color={deactivateButtonColor || "error"}
                      size="small"
                    >
                      {deactivateButtonTitle || "DELETE"}
                    </OutlinedButton>
                  </span>
                }
              />
            )}
            {edit && !disableDelete && !archiveButton?.disabled && (
              <OutlinedButton
                onClick={() => deactivateToggleDrawer(true)}
                width="fit-content"
                variant="outlined"
                color={deactivateButtonColor || "error"}
                size="small"
              >
                {deactivateButtonTitle || "DELETE"}
              </OutlinedButton>
            )}
          </Box>

          <Box>
            <div
              style={{ display: "inline", marginRight: "10px" }}
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              <TextButton
                width="80px"
                variant="text"
                color="primary"
                size="small"
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ display: "inline" }}>
              <ThemeButton
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  handleClick(actionLabel || user);
                }}
                disabled={buttonValidation}
              >
                {buttonTitle}
              </ThemeButton>
            </div>
          </Box>
        </Box>
      )}
    </SwipeableDrawer>
  );
}

export default DrawerComponent;
