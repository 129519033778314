import React, { useState } from "react";
import { filterObjectByValue } from "../../Utils/Tools";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { findObjectByValue } from "../../Utils/Tools";
import TableComponent from "../BaseComponents/Table";
import EditRole from "../../Routes/Setup/Roles/EditRoles";
import LabelComponent from "../BaseComponents/LabelComponent";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";

const RolesTable = (props) => {
  let {
    auth,
    handleNavigate,
    users,
    handleEdit,
    toggleDrawer,
    authorizations,
    authorizationGroups,
    authorizationGroupAuthorizations,
    defaultUserRoles,
    authorizationLabels,
    allowedEdit,
  } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [tempUsers, setTempUsers] = useState(users);
  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      name: "RoleID",
      label: "RoleID",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: false,
      },
    },

    {
      name: "Role",
      label: "Role",
      options: {
        searchable: false,
        sort: true,
        paddingLeft: "70px",
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.label.toUpperCase();
            var valueTwo = userListTwo.label.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ position: "relative", marginRight: "24px" }}>
                <div
                  style={{
                    height: "48px",
                    width: "48px",
                    position: "absolute",
                  }}
                ></div>
                <i
                  style={{
                    fontSize: "30px",
                    background: "#ECEDEE",
                    color: " #9CA1A6",
                    padding: "9px",
                    borderRadius: "5px",
                    fontWeight: "300",
                    lineHeight: "36px",
                  }}
                  className="fa-light fa-shield-keyhole"
                ></i>
              </span>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                {value.label}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "Users",
      label: "Users",
      options: {
        searchable: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
    {
      name: "",
      label: "",
    },
  ];

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  const customOptions = {
    download: false,
    selectableRows: false,
    expandableRows: true,
    expandableRowsHeader: false,
    rowsExpanded: expandedRows,
    onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const authorizationsData = filterObjectByValue(
        authorizationGroupAuthorizations,
        "AuthorizationGroupID",
        rowData[0]
      )?.map((authorizationGroup) => {
        return findObjectByValue(authorizations, "value", authorizationGroup.AuthorizationID);
      });
      if (authorizationsData[0]) {
        const ReadOnlyAuthorizations = filterObjectByValue(
          authorizationsData,
          "UIState",
          "ReadOnly"
        );
        const EditAllowedAuthorizations = filterObjectByValue(
          authorizationsData,
          "UIState",
          "Edit"
        );

        return (
          <TableRow
            sx={{ backgroundColor: "#F7F8F8" }}
            key={rowData[1]}
          >
            <TableCell colSpan={26}>
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "24px",
                  marginLeft: "50px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#9CA1A6",
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "left",
                      marginBotton: "11px",
                    }}
                  >
                    Read permissions
                  </Typography>
                  <Box
                    sx={{
                      mt: "11px",
                    }}
                  >
                    {ReadOnlyAuthorizations.map((authorization) => {
                      return (
                        <LabelComponent
                          label={
                            authorizationLabels[authorization.ElementName] ||
                            authorization.ElementName
                          }
                          state="primary"
                          marginBottom="6px"
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Box sx={{ marginLeft: "50px" }}>
                  <Typography
                    sx={{
                      color: "#9CA1A6",
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    Edit permissions
                  </Typography>
                  <Box
                    sx={{
                      mt: "11px",
                    }}
                  >
                    {EditAllowedAuthorizations.map((authorization) => {
                      return (
                        <LabelComponent
                          marginBottom="6px"
                          label={
                            authorizationLabels[authorization.ElementName] ||
                            authorization.ElementName
                          }
                          state="primary"
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        );
      }
    },
    sortOrder: {
      name: "Role",
      direction: "asc",
    },
  };

  return (
    <Box>
      <TableComponent
        zebraStyle={false}
        tableLook={"relaxed"}
        customOptions={customOptions}
        cellPaddingLeft="24px"
        paddingLeft="70px"
        cellPadding={true}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "131px",
                marginRight: "8px",
              }}
            >
              <b>
                {authorizationGroups.length}{" "}
                {authorizationGroups.length <= 1 ? "user role" : "user roles"}
              </b>
            </p>
          </Box>
        }
        columns={columns}
        customToolbar={
          allowedEdit && (
            <TableHeaderAddButton
              onClick={() => toggleDrawer(true)}
              label="User Role"
              width="135px"
            />
          )
        }
        data={authorizationGroups.map((authorization) => {
          const roleUsers = filterObjectByValue(users, "RoleID", authorization.value)?.length;

          return [
            authorization.value,
            { label: authorization.label },

            <Typography
              onClick={() => roleUsers > 0 && handleNavigate(authorization)}
              sx={{
                color: roleUsers > 0 ? "#00AEEF" : "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "16px",
                textAlign: "left",
                "&:hover": roleUsers > 0 && {
                  textDecoration: "underline",
                  cursor: "pointer",
                },
                pointerEvents: !roleUsers > 0 && "none",
              }}
            >
              {roleUsers > 0 ? "View users" : " There are no users attached to this role"}
            </Typography>,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            ,
            !defaultUserRoles.includes(authorization.value) && allowedEdit && (
              <EditRole
                role={authorization}
                handleEdit={handleEdit}
              />
            ),
          ];
        })}
        tableBodyHeight="calc(100vh - 253px)"
      />
    </Box>
  );
};

export default RolesTable;
