import React from "react";
import Map from "../Map";

const MapPane = (props) => {
  const {
    selectedLocationId,
    setSelectedLocationId,
    setSelectedEquipment,
    SelectedEquipment,
    location,
    LocationID,
    CustomerID,
    mappedEquipments,
    mappedEquipmentLoading,
    paneWidth,
    equipmentLive,
  } = props;
  return (
    <div
      style={{
        width: "100px",
      }}
      {...props}
      className="split-pane-right"
    >
      <Map
        paneWidth={paneWidth}
        CustomerID={CustomerID}
        LocationID={LocationID}
        locationObject={location}
        equipmentLive={equipmentLive}
        SelectedEquipment={SelectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
        setSelectedLocationId={setSelectedLocationId}
        selectedLocationId={selectedLocationId}
        mappedEquipments={mappedEquipments}
        mappedEquipmentLoading={mappedEquipmentLoading}
      />{" "}
    </div>
  );
};

export default MapPane;
