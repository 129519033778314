import { apiSlice } from "../API";

export const equipmentTypeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findEquipmentType: builder.query({
      query: () => "/equipmentType",
      providesTags: ["EquipmentType"],
    }),
    addEquipmentType: builder.mutation({
      query: (equipmentType) => ({
        url: "/equipmentType",
        method: "POST",
        body: equipmentType,
      }),
      invalidatesTags: ["EquipmentType"],
    }),
    editEquipmentType: builder.mutation({
      query: ({ id, data }) => ({
        url: `/equipmentType/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["EquipmentType"],
    }),
    deleteEquipmentType: builder.mutation({
      query: (id) => ({
        url: `/equipmentTypes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EquipmentType"],
    }),
  }),
});

export const {
  useFindEquipmentTypeQuery,
  useAddEquipmentTypeMutation,
  useEditEquipmentTypeMutation,
} = equipmentTypeSlice;
