// PRIMARY COLORS
const primary = {
  50: "#E3E9EC",
  100: "#B9C7CF",
  200: "#8BA2AF",
  300: "#5D7D8E",
  400: "#3A6176",
  500: "#17455E",
  600: "#143E56",
  700: "#11364C",
  800: "#0D2E42",
  900: "#071F31",
};

const blueAction = {
  50: "#E0F5FD",
  100: "#B3E7FA",
  200: "#80D7F7",
  300: "#4DC6F4",
  400: "#26BAF1",
  500: "#00AEEF",
  600: "#00A7ED",
  700: "#009DEB",
  800: "#0094E8",
  900: "#0084E4",
};

const purpleAction = {
  50: "#EEEAF4",
  100: "#D4CBE3",
  200: "#B8A8D0",
  300: "#9C85BD",
  400: "#866BAF",
  500: "#7151A1",
  600: "#694A99",
  700: "#5E408F",
  800: "#543785",
  900: "#422774",
};

const darkGrey = {
  50: "#686E77",
  100: "#4D545F",
  200: "#464D57",
  300: "#3D434D",
  400: "#343A43",
  500: "#2D3643",
  600: "#28303D",
  700: "#222934",
  800: "#1C222C",
  900: "#11161E",
};

const lightGrey = {
  50: "#F7F8F8",
  100: "#ECEDEE",
  200: "#E0E1E3",
  300: "#D3D5D8",
  400: "#C9CCCF",
  500: "#C0C3C7",
  600: "#BABDC1",
  700: "#B2B5BA",
  800: "#AAAEB3",
  900: "#9CA1A6",
};

// SEMANTIC COLORS
const success = {
  50: "#E7F5EA",
  100: "#C2E5CB",
  200: "#9AD4A9",
  300: "#71C286",
  400: "#52B56C",
  500: "#34A852",
  600: "#2FA04B",
  700: "#279741",
  800: "#218D38",
  900: "#157D28",
  950: "#1E4620",
};

const warning = {
  50: "RGB(253, 241, 230)",
  100: "#FEEBB5",
  200: "#FDDD83",
  300: "#FCCF51",
  400: "#FBC52C",
  500: "#FABB07",
  600: "#F9B506",
  700: "#F9AC05",
  800: "#F8A404",
  900: "#F69602",
  950: "#663C00",
};

const info = {
  50: "#E8F1FE",
  100: "#C6DBFC",
  200: "#A1C3FA",
  300: "#7BABF8",
  400: "#5E99F7",
  500: "#4287F5",
  600: "#3C7FF4",
  700: "#3374F2",
  800: "#2B6AF0",
  900: "#1D57EE",
  950: "#014361",
};

const error = {
  50: "#FDE9E7",
  100: "#F9C7C3",
  200: "#F5A29B",
  300: "#F17C72",
  400: "#EE6054",
  500: "#EB4436",
  600: "#E93E30",
  700: "#E53529",
  800: "#E22D22",
  900: "#DD1F16",
  950: "#5F2120",
};

// CHARTS AND ALARMS
const orange = {
  50: "#FDF1E6",
  100: "#FBDDC2",
  200: "#F8C699",
  300: "#F5AF70",
  400: "#F39D51",
  500: "#F18C32",
  600: "#EF842D",
  700: "#ED7926",
  800: "#EB6F1F",
  900: "#E75C13",
  950: "#7E4223",
};

const magenta = {
  50: "#F6E7F4",
  100: "#E9C4E3",
  200: "#DA9CD0",
  300: "#CB74BD",
  400: "#BF57AF",
  500: "#B439A1",
  600: "#AD3399",
  700: "#A42C8F",
  800: "#9C2485",
  900: "#8C1774",
  950: "#5F0F4F",
};

const lightBlue = {
  50: "#E5F2FE",
  100: "#BFDEFC",
  200: "#94C8FA",
  300: "#69B2F8",
  400: "#49A2F7",
  500: "#2991F5",
  600: "#2489F4",
  700: "#1F7EF2",
  800: "#1974F0",
  900: "#0F62EE",
  950: "#0A42A2",
};

const cyan = {
  50: "#E5FAF8",
  100: "#BEF3EE",
  200: "#93EBE3",
  300: "#67E2D7",
  400: "#47DCCF",
  500: "#26D6C6",
  600: "#22D1C0",
  700: "#1CCCB9",
  800: "#17C6B1",
  900: "#0DBCA4",
  950: "#135D57",
};

const blue = {
  50: "#E4EBF8",
  100: "#BCCCEE",
  200: "#90ABE3",
  300: "#6389D7",
  400: "#416FCF",
  500: "#2056C6",
  600: "#1C4FC0",
  700: "#1845B9",
  800: "#133CB1",
  900: "#0B2BA4",
  950: "#081E6C",
};

const yellow = {
  50: "#FFF9E9",
  100: "#FEF1C8",
  200: "#FDE8A3",
  300: "#FCDF7E",
  400: "#FCD862",
  500: "#FBD146",
  600: "#FACC3F",
  700: "#FAC637",
  800: "#F9C02F",
  900: "#F8B520",
  950: "#655314",
};

const purple = {
  50: "#ECE4F3",
  100: "#CFBBE2",
  200: "#AF8ECF",
  300: "#8E61BC",
  400: "#763FAD",
  500: "#5E1D9F",
  600: "#561A97",
  700: "#4C158D",
  800: "#421183",
  900: "#310A72",
  950: "#341F54",
};

const teal = {
  50: "#E4F3F2",
  100: "#BBE2DE",
  200: "#8ECFC8",
  300: "#60BCB2",
  400: "#3EADA2",
  500: "#1C9F91",
  600: "#199789",
  700: "#148D7E",
  800: "#118374",
  900: "#097262",
  950: "#065B4E",
};

const brown = {
  50: "#F3ECE5",
  100: "#E0D1BD",
  200: "#CCB291",
  300: "#B79365",
  400: "#A77B44",
  500: "#986423",
  600: "#905C1F",
  700: "#85521A",
  800: "#7B4815",
  900: "#6A360C",
  950: "#422208",
};

export {
  primary,
  blueAction,
  purpleAction,
  darkGrey,
  lightGrey,
  success,
  info,
  warning,
  error,
  orange,
  magenta,
  lightBlue,
  cyan,
  blue,
  yellow,
  purple,
  teal,
  brown,
};
