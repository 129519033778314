import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/system";
import { createLocationCombinations } from "../../../Utils/Tools";
import { tabStyles } from "../../../Utils/constants";

import Detail from "./Detail";
import Map from "./Map";

export default function LocationTabs(props) {
  const {
    location,
    openedTab,
    setOpenedTab,
    locations,
    handleChange,
    handleChangeSelect,
    locationCombinations,
    handleParentLocation,
    edit,
    validationErrorMessage,
  } = props;

  const [validation, setValidation] = useState(false);

  const handleTabChange = (event, newValue) => {
    setOpenedTab(newValue);
  };

  const theme = useTheme();

  function isValidForUpload(ParentID, locations, parentChild) {
    const parent = locations.find((loc) => loc.LocationID === ParentID);

    if (parent?.MapFile) {
      return false;
    }

    if (!parent || !parent.ParentID) {
      return true;
    }

    return isValidForUpload(parent.ParentID, locations, parentChild);
  }

  useEffect(() => {
    location &&
      locations &&
      setValidation(
        isValidForUpload(
          location.ParentID,
          locations,
          createLocationCombinations(locations, location.ParentID)
        )
      );
  }, [locations, location]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={openedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box sx={{ marginX: 2 }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Detail"
                value="1"
                sx={tabStyles}
              />
              <Tab
                label="Map"
                value="2"
                sx={tabStyles}
              />
            </TabList>
          </Box>
        </Box>
        {openedTab === "2" && !validation ? (
          <Box>
            {" "}
            <p
              style={{
                background: "#FDE9E7 0% 0% no-repeat padding-box",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#5F2120",
                padding: "15px",
                display: "flex",
                fontWeight: "500",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <i
                  className="fa-solid fa-info-circle fa-xl"
                  style={{
                    color: `${theme.palette["error"][900]}`,
                    marginRight: "5px",
                  }}
                ></i>
              </Box>
              Remove parent location map to upload a map to this location
              <CloseIcon
                sx={{
                  color: `${theme.palette["error"][800]}`,
                }}
                fontSize="medium"
              />
            </p>
          </Box>
        ) : (
          openedTab === "2" && (
            <p
              style={{
                background: "#E8F1FE 0% 0% no-repeat padding-box",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#014361",
                padding: "15px",
                display: "flex",
                fontWeight: "500",
              }}
            >
              <Box>
                <i
                  className="fa-solid fa-info-circle fa-xl"
                  style={{
                    color: `${theme.palette["info"][900]}`,
                    marginRight: "5px",
                  }}
                ></i>
              </Box>
              Uploading a map to this location will prevent map uploads to child locations
              <CloseIcon
                sx={{
                  color: `${theme.palette["info"][800]}`,
                }}
                fontSize="medium"
              />
            </p>
          )
        )}
        <TabPanel value="1">
          <Detail
            location={location}
            locations={locations}
            handleChange={handleChange}
            handleChangeSelect={handleChangeSelect}
            locationCombinations={locationCombinations}
            handleParentLocation={handleParentLocation}
            edit={edit}
            validationErrorMessage={validationErrorMessage}
          />
        </TabPanel>
        <TabPanel value="2">
          <Map
            validation={validation}
            location={location}
            handleChange={handleChange}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
