import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";

const FilterFooter = (props) => {
  const { clearFilter } = props;

  return (
    <div>
      <Divider />

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "16px",
          height: "64px",
        }}
      >
        <Button
          sx={{
            border: "1px solid #17455E",
            color: "#17455E",
            padding: "2px 4px",
            "text-align": "center",
          }}
          variant="text"
          onClick={clearFilter}
        >
          CLEAR FILTERS
        </Button>
      </div>
    </div>
  );
};

export default FilterFooter;
