import { apiSlice } from "../API";

export const ProgramStepsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findProgramSteps: builder.query({
      query: () => "/programStep",
      providesTags: ["ProgramStep", "Program"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item);
      },
    }),
    addProgramStep: builder.mutation({
      query: (programStep) => ({
        url: "/programStep",
        method: "POST",
        body: programStep,
      }),
      invalidatesTags: ["ProgramStep"],
    }),
    editProgramStep: builder.mutation({
      query: ({ id, programStep }) => ({
        url: `/programStep/${id}`,
        method: "PUT",
        body: programStep,
      }),
      invalidatesTags: ["ProgramStep"],
    }),
    deleteProgramStep: builder.mutation({
      query: (id) => ({
        url: `/programStep/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProgramStep"],
    }),
  }),
});

export const {
  useFindProgramStepsQuery,
  useAddProgramStepMutation,
  useEditProgramStepMutation,
  useDeleteProgramStepMutation,
} = ProgramStepsSlice;
