import { ListItemText, MenuItem } from "@mui/material";
import MultipleSelectCheckmarks from "../MultiSelect";
import CheckBoxComponent from "../Checkbox";

export default function SensorTypeFilterField(props) {
  const { handleSelectSensorType, sensorTypes, selectedSensors } = props;

  return (
    <div style={{ marginTop: "24px" }}>
      <p
        style={{
          textAlign: "left",
          font: "normal normal normal 12px/16px Roboto",
          color: "#9CA1A6",
          marginBottom: "4px",
        }}
      >
        Sensor type(s)
      </p>

      <MultipleSelectCheckmarks
        onChange={handleSelectSensorType}
        placeholder="Select sensor type(s)"
        personName={selectedSensors}
        data={sensorTypes}
      >
        {sensorTypes.map((sensorType) => (
          <MenuItem
            key={sensorType.value}
            value={sensorType.value}
            sx={{
              font: "normal normal medium 14px/20px Roboto",
              color: "#222934",
              "&:hover": {
                background: "#E3E9EC 0% 0% no-repeat padding-box",
                color: "#17455E",
              },
              "&$selected": {
                // <-- mixing the two classes
                backgroundColor: "transparent",
              },
            }}
            className="menuItem"
          >
            <CheckBoxComponent
              className="checkBox"
              size="small"
              checked={selectedSensors.indexOf(sensorType.value) > -1}
            />
            <ListItemText primary={sensorType.label} />
          </MenuItem>
        ))}
      </MultipleSelectCheckmarks>
    </div>
  );
}
