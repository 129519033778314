import React, { useEffect, useRef, useState } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";

const SplitPane = ({ tab, children, setPaneWidth, setCurrentBreakpoint, mobileView, ...props }) => {
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  const [clientHeight, setClientHeight] = useState(mobileView ? "100%" : 550);
  const [clientWidth, setClientWidth] = useState(
    tab === 1 ? window.innerWidth - 100 : window.screen.width * 0.45
  );
  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);

  const onMouseHoldDown = (e) => {
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  };

  useEffect(() => {
    if (tab === 0) {
      setClientWidth(window.screen.width * 0.45);
    } else if (tab === 1) {
      // 100 value is calculated based on side menu and splitter element itself
      setClientWidth(window.innerWidth - 100);
      setCurrentBreakpoint(1);
    } else if (tab === 2) {
      // 250 so that parts of the table is visible enough to render header elements and table view icon button
      setClientWidth(250);
      setCurrentBreakpoint(1);
    }
  }, [tab]);

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = (e) => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }
    if (setPaneWidth && setCurrentBreakpoint) {
      let screenRes = (clientWidth / window.screen.width) * 100;
      if (screenRes + 2 <= 25) {
        setClientWidth(window.screen.width * 0.25);
        setCurrentBreakpoint(1);
        return;
      }
      if (screenRes > 25 && screenRes < 40) {
        setCurrentBreakpoint(2);
      }
      if (screenRes >= 40 && screenRes < 50) {
        setCurrentBreakpoint(3);
      }
      if (screenRes >= 50 && screenRes < 72) {
        setCurrentBreakpoint(4);
      }

      if (screenRes - 2 >= 72) {
        setClientWidth(window.screen.width * 0.72);
        setCurrentBreakpoint(5);
        return;
      }

      setClientWidth(clientWidth + e.clientX - xDividerPos.current);
      setPaneWidth(clientWidth + 100);
    } else {
      setClientWidth(clientWidth + e.clientX - xDividerPos.current);
    }
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp);
      document.removeEventListener("mousemove", onMouseHoldMove);
    };
  });

  return (
    <div {...props}>
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

export default SplitPane;
