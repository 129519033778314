import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pillButton: {
    backgroundColor: "#17455E",
    borderRadius: "55%",
    minWidth: "0px",
    minHeight: "0px",
    height: "16px !important",
    width: "16px !important",
    color: "#FFFFFF",
    padding: "12px",
    "&:hover": {
      backgroundColor: "#17455E",
    },
  },
}));

function PillComponent(props) {
  const { size, label, blueAction, style, divStyle } = props;
  const classes = useStyles();
  return (
    <Box sx={{ ...divStyle }}>
      <Button
        size={size}
        className={classes.pillButton}
        sx={{
          backgroundColor: blueAction == true ? "#00AEEF !important" : "",
          ...style,
        }}
      >
        {label}
      </Button>
    </Box>
  );
}

export default PillComponent;
