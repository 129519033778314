import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import TableComponent from "../../BaseComponents/Table";
import ToolTipComponent from "../../BaseComponents/Tooltip";
import { primary, blueAction } from "../../../Styles/theme/colors";
import { lightGrey } from "../../../Styles/theme/colors";
import {
  deepCopy,
  filterObjectByValue,
  findObjectByValue,
  getColorCodingStatus,
} from "../../../Utils/Tools";
import { STATUS_COLOR_CODE_MAP } from "../../../Utils/constants";
import { notAllowedRoles } from "../../../Utils/constants";
import { useFindSubstationsQuery } from "../../../Store/Slices/substationSlice";
import EditEquipment from "../../../Routes/Setup/Equipment/EditEquipments";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import { useFindMappedEquipmentsQuery } from "../../../Store/Slices/mappedEquipmentSlice";
import SensorLabel from "../../BaseComponents/SensorLabel";
import TableHeaderAddButton from "../../BaseComponents/TableHeaderAddButton";
import TableTitle from "./TableTitle";
import { EquipmentLocationLabel } from "./EquipmentLocationLabel";
import ExpandableRow from "./ExpandableRow";
import EquipmentContextMenu from "../../BaseComponents/EquipmentContextMenu";
import { useScrollToTableRow } from "../../../Hooks/useScrollToTableRow";

const EquipmentTable = (props) => {
  const {
    toggleDrawer,
    equipments,
    equipmentLive,
    locations,
    handleEdit,
    handleSensorEdit,
    sensorLimitTemplates,
    locationsHierarchy,
    sensorTypes,
    auth,
    allowedEdit,
    sensorToggleDrawer,
    handleTableAdd,
    setSelectedAction,
    handleAlertSuppression,
    handleEquipmentSupressAlarms,
  } = props;

  const [tableLook, setTableLook] = useState("dense");
  const [searchText, setSearchText] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedEquipementStatus, setSelectedEquipementStatus] = useState(["Active"]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [tempEquipments, setTempEquipments] = useState(equipments);
  const [activeSensor, setActiveSensor] = useState({});
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [statusFilterCount, setStatusFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [activeEquipmentID, setActiveEquipmentID] = useState("");
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [activeSensorID, setActiveSensorID] = useState(null);
  const { state } = useLocation();
  const { handleSelectRowIndex } = useScrollToTableRow();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: locationsData,
    isFetching: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  useEffect(() => {
    if (
      selectedEquipmentTypes.length ||
      selectedLocations.length ||
      selectedAlarm.length ||
      selectedStatus.length ||
      selectedEquipementStatus.length ||
      searchText.length
    ) {
      let tempFilteredEquipments = equipments.filter((equipment) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter7 = true;
        if (selectedEquipmentTypes.length) {
          filter1 = selectedEquipmentTypes.some((typeId) => {
            return typeId == equipment.EquipmentTypeID;
          });
        }
        if (selectedAlarm.length) {
          filter2 = selectedAlarm.some((item) => {
            const equipmentSensors = filterObjectByValue(
              equipmentLive,
              "EquipmentID",
              equipment.EquipmentID
            );

            return (
              (item == "Warning" && isWarning(equipmentSensors[0]?.Sensors)) ||
              (item == "Alarm" && isAlarm(equipmentSensors[0]?.Sensors))
            );
          });
        }

        if (selectedLocations.length) {
          filter3 = selectedLocations.some((locationId) => {
            return locationId == equipment.LocationID;
          });
        }

        if (selectedStatus.length) {
          equipment.Sensors = equipment.Sensors.filter((sensor) => {
            return selectedStatus.some((item) => {
              return (
                (item == "Active" && sensor?.IsActive) || (item == "Inactive" && !sensor?.IsActive)
              );
            });
          });
        }
        if (selectedEquipementStatus.length) {
          filter4 = selectedEquipementStatus.includes(equipment.IsActive ? "Active" : "Inactive");
        }
        if (searchText.length) {
          filter7 =
            equipment.EquipmentName.toLowerCase().includes(searchText.toLowerCase()) ||
            equipment.EquipmentTypeName.toLowerCase().includes(searchText.toLowerCase()) ||
            locationsHierarchy[equipment?.LocationID]
              .toLowerCase()
              .includes(searchText.toLowerCase());
        }

        return filter1 && filter2 && filter3 && filter4 && filter7;
      });

      setTempEquipments(tempFilteredEquipments);
    } else {
      setTempEquipments(deepCopy(equipments));
    }
  }, [
    selectedEquipmentTypes,
    selectedLocations,
    selectedAlarm,
    selectedStatus,
    selectedEquipementStatus,
    equipments,
    searchText,
  ]);

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData]);

  const selectedEquipmentID = equipments.find(
    (e) => e.EquipmentID === state?.EquipmentID
  )?.EquipmentID;

  const permissionAllowed = auth.Authorizations["statusView"];

  const loadMappedEquipments = () => {
    if (mappedEquipmentSuccess) setMappedEquipments(mappedEquipmentData.success.data);
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
  };

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const loadLocations = async () => {
    if (locationsSuccess)
      setAllLocations(
        locationsData.map((e) => {
          return { value: e.LocationID, label: e.LocationName };
        })
      );
  };

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectedEquipmentList = (event) => {
    let updatedList = [...selectedEquipementStatus];
    if (event.target.checked) {
      updatedList = [...selectedEquipementStatus, event.target.value];
      setStatusFilterCount(statusFilterCount + 1);
    } else {
      updatedList.splice(selectedEquipementStatus.indexOf(event.target.value), 1);
      setStatusFilterCount(statusFilterCount - 1);
    }
    setSelectedEquipementStatus(updatedList);
  };

  const handleSelectedAction = (action) => {
    setSelectedAction(action);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setStatusFilterCount(statusFilterCount + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setStatusFilterCount(statusFilterCount - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value?.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value?.split(",") : value);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const clearFilter = () => {
    setSelectedEquipmentTypes([]);
    setSelectedSensors([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedStatus([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setStatusFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
  };
  const loadSubstations = () => {
    if (substationsSuccess) setSubstations(substationsData);
    if (isSubstationError) console.log(substationError);
  };

  const columns = [
    {
      name: "EquipmentID",
      label: "EquipmentID",
      options: {
        display: false,
        searchable: false,
        sort: true,
        download: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        download: false,
      },
    },
    {
      name: "Equipmentname",
      label: "Equipment name",
      options: {
        display: true,
        searchable: true,
        sort: true,
        download: true,
      },
    },
    {
      name: "Monitoring",
      label: "Monitoring",
      options: {
        display: true,
        searchable: true,
        sort: false,
        download: true,
      },
    },
    {
      name: "Equipment type",
      label: "Equipment type",
      options: {
        display: true,
        searchable: true,
        sort: true,
        download: true,
      },
    },

    {
      name: "Equipment location",
      label: "Equipment location",
      options: {
        display: true,
        searchable: true,
        sort: true,
        download: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            const valueOne = userListOne.props?.title || userListOne;
            const valueTwo = userListTwo.props?.title || userListTwo;

            const comparison = valueOne.localeCompare(valueTwo);

            return order === "asc" ? comparison : -comparison;
          },
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return (
            <EquipmentLocationLabel
              row={tableMeta.rowData[7]}
              mappedEquipments={mappedEquipments}
              permissionAllowed={permissionAllowed}
              locationsHierarchy={locationsHierarchy}
            />
          );
        },
      },
    },

    {
      name: "",
      label: "",
      options: {
        download: false,
      },
    },
    {
      name: "row",
      label: "row",
      options: {
        display: false,
        searchable: false,
        sort: false,
        download: false,
      },
    },
  ];

  const sensorColumns = [
    {
      name: "Sensor",
      label: "Sensor",
      options: {
        display: true,
        searchable: true,
      },
    },
    {
      name: "Substation",
      label: "Substation",
      options: {
        display: true,
        searchable: true,
      },
    },
    {
      name: "Substation port",
      label: "Substation port",
      options: {
        display: true,
        searchable: true,
      },
    },
    {
      name: "Substation location",
      label: "Substation location",
      options: {
        display: true,
        searchable: true,
      },
    },
    {
      name: "",
      label: "",
    },
  ];

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  function isWarning(data) {
    return data.some((sensor) => typeof sensor == "object" && sensor.LiveData?.State == 1 && !sensor.LiveData?.Suppressed);
  }

  function isAlarm(data) {
    return data.some(
      (sensor) => (typeof sensor == "object" && sensor.LiveData?.State == 2 && !sensor.LiveData?.Suppressed)
    );
  }

  const equipmentCustomOptions = {
    selectableRows: false,
    sortOrder: {
      name: "Sensor",
      direction: "asc",
    },
  };

  const customOptions = {
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRows: "none",
    rowsExpanded: expandedRows,
    download: true,
    setRowProps: (row, _, rowIndex) => {
      if (row[0] === selectedEquipmentID) {
        handleSelectRowIndex(rowIndex);
        return {
          style: { "background-color": "#e0f5fd" },
          "data-rowindex": rowIndex,
        };
      } else {
        return {
          "data-rowindex": rowIndex,
        };
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      data.map((row) => {
        row.data[3] = row?.data?.[3]?.props?.children?.props?.children || "";
      });
      console.log(data);
      return buildHead(columns) + buildBody(data);
    },

    onRowsExpand: (_currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },

    renderExpandableRow: (rowData, _rowMeta) => {
      const data = filterObjectByValue(tempEquipments, "EquipmentID", rowData[0]);

      return (
        <ExpandableRow
          data={data}
          handleSensorEdit={handleSensorEdit}
          activeEquipmentID={activeEquipmentID}
          setActiveEquipmentID={setActiveEquipmentID}
          activeSensor={activeSensor}
          setActiveSensor={setActiveSensor}
          activeSensorID={activeSensorID}
          setActiveSensorID={setActiveSensorID}
          notAllowedRoles={notAllowedRoles}
          auth={auth}
          sensorToggleDrawer={sensorToggleDrawer}
          primary={primary}
          lightGrey={lightGrey}
          blueAction={blueAction}
          locations={locations}
          sensorTypes={sensorTypes}
          substations={substations}
          sensorColumns={sensorColumns}
          equipmentCustomOptions={equipmentCustomOptions}
          locationsHierarchy={locationsHierarchy}
          sensorLimitTemplates={sensorLimitTemplates}
          handleTableAdd={handleTableAdd}
          setSelectedAction={handleSelectedAction}
          handleAlertSuppression={handleAlertSuppression}
        />
      );
    },

    sortOrder: {
      name: "Equipmentname",
      direction: "asc",
    },
  };

  return (
    <Box>
      {" "}
      <TableComponent
        zebraStyle={true}
        tableLook={tableLook}
        customOptions={customOptions}
        title={
          <TableTitle
            tempEquipments={tempEquipments}
            tableLook={tableLook}
            setTableLook={setTableLook}
            allLocations={allLocations}
            equipmentTypes={equipmentTypes}
            handleSelectEquipmentType={handleSelectEquipmentType}
            handleSelectSensorType={handleSelectSensorType}
            handleSelectLocation={handleSelectLocation}
            handleAlarmSelect={handleAlarmSelect}
            selectedEquipmentTypes={selectedEquipmentTypes}
            selectedSensors={selectedSensors}
            selectedAlarm={selectedAlarm}
            selectedLocations={selectedLocations}
            equipmentFilterCount={equipmentFilterCount}
            sensorTypeFilterCount={sensorTypeFilterCount}
            locationFilterCount={locationFilterCount}
            alarmFilterCount={alarmFilterCount}
            statusFilterCount={statusFilterCount}
            clearFilter={clearFilter}
            handleStatusList={handleStatusList}
            handleSelectedEquipmentList={handleSelectedEquipmentList}
            selectedEquipementStatus={selectedEquipementStatus}
            selectedStatus={selectedStatus}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        }
        columns={columns}
        customToolbar={
          allowedEdit && (
            <TableHeaderAddButton
              onClick={() => toggleDrawer(true)}
              label="Equipment"
              width="134px"
            />
          )
        }
        searchText={searchText}
        tableBodyHeight="calc(100vh - 253px)"
        data={tempEquipments?.map((row) => {
          const status = getColorCodingStatus(row?.Sensors, row.IsActive);
          const size = tableLook === "dense" ? "10px" : "13px";
          const sensorsSlice = row?.Sensors.length > 3;
          const first = sensorsSlice ? row?.Sensors.slice(0, 2) : row?.Sensors;
          const second = sensorsSlice && row?.Sensors.slice(2);
          const moreLabelStatus = second
            ? getColorCodingStatus(second, row.IsActive)
            : second;
          return [
            row.EquipmentID,
            <span style={{ position: "relative" }}>
              {status !== "none" && (
                <StatusDot
                  status={status}
                  size={size}
                  tableLook={tableLook}
                />
              )}
              <IconComponent tableLook={tableLook} />
            </span>,
            row.EquipmentName,
            <span style={{ display: "flex", alignItems: "center" }}>
            {row?.Sensors.length > 0 ? (
              <>
             {`${row?.Sensors.length} Sensors`}
              </>
            ) : (
              "No sensors"
            )}
          </span>,
            row.EquipmentTypeName,
         
            locationsHierarchy[row?.LocationID],
            <span style={{ display: "flex", alignItems: "center" }}>
              {allowedEdit && (
                <Box
                  sx={{
                    paddingRight: "24px",
                  }}
                >
                  <ToolTipComponent
                    title="Edit equipment"
                    placement="bottom-end"
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                        }}
                      >
                        <EditEquipment
                          equipment={row}
                          handleEdit={handleEdit}
                        />
                      </Box>
                    }
                  />
                </Box>
              )}
              <EquipmentContextMenu
                equipment={row}
                setActiveEquipmentID={setActiveEquipmentID}
                handleSelectedAction={handleSelectedAction}
                handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
              />
            </span>,
            row,
          ];
        })}
      />
    </Box>
  );
};

const IconComponent = ({ tableLook }) => (
  <i
    className="fa-solid fa-flux-capacitor tableRow"
    style={{
      fontSize: tableLook === "dense" ? "15px" : "30px",
      background: "#ECEDEE",
      color: " #9CA1A6",
      padding: "6px",
      borderRadius: "5px",
      fontWeight: "300",
    }}
  ></i>
);

export const SensorMapComponent = ({ sensors, sensorTypes }) => (
  <>
    {sensors.map((sensor) => {
      const sensorType = findObjectByValue(sensorTypes, "value", sensor.SensorTypeID);
      return (
        <SensorLabel
          LiveData={sensor.LiveData}
          sensorType={sensorType}
          title={sensor.SensorLabel}
        />
      );
    })}
  </>
);

export const ToolTipComponentWrapper = ({ title, component, placement }) => (
  <ToolTipComponent
    title={title}
    placement={placement}
    Component={
      <Box
        sx={{
          width: "fit-content",
          cursor: "pointer",
          whiteSpace: "nowrap",
        }}
      >
        {component}
      </Box>
    }
  />
);

const StatusDot = ({ status, tableLook, size }) => {
  return (
    <Box
      className={`${tableLook === "dense" ? "equipmentDot" : "equipmentDotRelaxed"}`}
      sx={{
        background: STATUS_COLOR_CODE_MAP[status],
        borderRadius: "50%",
        position: "absolute",
        height: size,
        width: size,
      }}
    />
  );
};
export default EquipmentTable;
