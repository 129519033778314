import TextFieldComponent from "../BaseComponents/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid, MenuItem, ListItemText, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import LabelComponent from "../BaseComponents/LabelComponent";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import MultipleSelectCheckmarks from "../BaseComponents/MultiSelect";
import Label from "@Components/BaseComponents/Label";
import RadioGroupComponent from "../BaseComponents/RadioGroup";
import RadioComponent from "../BaseComponents/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import "./style.css";
import { isNumeric } from "../../Utils/Tools";

export default function AlertProgramStep({
  step,
  i,
  hide,
  setHide,
  selectedSensors,
  handleSelectedUserGroups,
  userGroups,
  selectedUsers,
  handleSelectedUsers,
  users,
  selectedUserGroups,
  updateFieldChanged,
  handleDeleteStep,
  steps,
}) {
  const checkValue = (value) => {
    if (value < 0) {
      value = 0;
    }
  };

  return (
    <Box
      sx={{
        padding: "0px 16px",
      }}
    >
      <Box
        sx={{
          paddingTop: "35px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "#222934",
              fontSize: "20px",
              lineHeight: "16px",
              fontWeight: 500,
              paddingRight: "16px",
            }}
          >
            Step {i + 1}{" "}
          </p>
          {step.ActionType != "" && (
            <LabelComponent
              label={step.ActionType}
              state="primary"
            />
          )}
        </Box>
        <Box>
          {steps.length > 1 && (
            <i
              class="fa-light fa-trash-can"
              style={{
                fontSize: "20px",
                color: "#C0C3C7",
                fontWeight: "normal",
                lineHeight: "25px",
                paddingRight: "24px",
                cursor: "pointer",
              }}
              onClick={() => handleDeleteStep(i)}
            ></i>
          )}
          <i
            class={`fa-light fa-chevron-${!hide ? "up" : "down"}`}
            style={{
              fontSize: "26px",
              color: "#C0C3C7",
              fontWeight: "normal",
              lineHeight: "31px",
              paddingRight: "25px",
              cursor: "pointer",
            }}
            onClick={() => {
              setHide(!hide);
            }}
          ></i>
        </Box>
      </Box>

      <Divider style={{ padding: "10px 0px" }} />
      {!hide && (
        <Box>
          <p
            style={{
              color: "#222934",
              fontSize: "18px",
              lineHeight: "16px",
              fontWeight: "normal",
              paddingTop: "16px",
            }}
          >
            Action
          </p>
          <Grid
            item
            xs={12}
            columns={2}
            spacing={10}
            sx={{
              paddingTop: "24px",
            }}
          >
            <Label
              sx={{
                color: "#9CA1A6",
              }}
            >
              Alert type
            </Label>

            <RadioGroupComponent
              row
              value={step.ActionType || ""}
              name="controlled-radio-buttons-group"
              callBack={(e) => updateFieldChanged(i, "ActionType", e)}
              FormControlLabels={
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    width: "380px",
                  }}
                >
                  <FormControlLabel
                    value={"Notification"}
                    control={
                      <RadioComponent
                        disabled={!selectedSensors.length}
                        paddingLeft="0px"
                      />
                    }
                    label="Notification"
                  />
                </div>
              }
            />
          </Grid>

          <div style={{ marginTop: "24px" }}>
            <p
              style={{
                textAlign: "left",
                font: "normal normal normal 12px/16px Roboto",
                color: "#9CA1A6",
                marginBottom: "4px",
              }}
            >
              User group(s)
            </p>

            <MultipleSelectCheckmarks
              disabled={step.ActionType == "" ? true : false}
              onChange={(e) => [
                handleSelectedUserGroups(e),
                updateFieldChanged(i, "UserGroups", e.target.value),
              ]}
              placeholder="Select user group(s)"
              personName={step.UserGroups.map((userGroup) => userGroup.UserGroupID)}
              data={userGroups}
            >
              {userGroups.map((userGroup) => (
                <MenuItem
                  key={userGroup.value}
                  value={userGroup.value}
                  sx={{
                    font: "normal normal medium 14px/20px Roboto",
                    color: "#222934",
                    "&:hover": {
                      background: "#E3E9EC 0% 0% no-repeat padding-box",
                      color: "#17455E",
                    },
                    "&$selected": {
                      // <-- mixing the two classes
                      backgroundColor: "transparent",
                    },
                  }}
                  className="menuItem"
                >
                  <CheckBoxComponent
                    className="checkBox"
                    size="small"
                    checked={selectedUserGroups.indexOf(userGroup.value) > -1}
                  />
                  <ListItemText primary={userGroup.label} />
                </MenuItem>
              ))}
            </MultipleSelectCheckmarks>
          </div>
          <div style={{ marginTop: "24px" }}>
            <p
              style={{
                textAlign: "left",
                font: "normal normal normal 12px/16px Roboto",
                color: "#9CA1A6",
                marginBottom: "4px",
              }}
            >
              Individual user(s)
            </p>

            <MultipleSelectCheckmarks
              disabled={step.ActionType == "" ? true : false}
              onChange={(e) => [
                handleSelectedUsers(e),
                updateFieldChanged(i, "Users", e.target.value),
              ]}
              placeholder="Select individual user(s)"
              personName={step.Users.map((user) => user.UserID)}
              data={users}
            >
              {users.map((user) => (
                <MenuItem
                  key={user.value}
                  value={user.value}
                  sx={{
                    font: "normal normal medium 14px/20px Roboto",
                    color: "#222934",
                    "&:hover": {
                      background: "#E3E9EC 0% 0% no-repeat padding-box",
                      color: "#17455E",
                    },
                    "&$selected": {
                      // <-- mixing the two classes
                      backgroundColor: "transparent",
                    },
                  }}
                  className="menuItem"
                >
                  <CheckBoxComponent
                    className="checkBox"
                    size="small"
                    checked={selectedUsers.indexOf(user.value) > -1}
                  />
                  <ListItemText primary={user.label} />
                </MenuItem>
              ))}
            </MultipleSelectCheckmarks>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              width: "400px",
            }}
          >
            <div
              style={{
                marginTop: "24px",
                whiteSpace: "nowrap",
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Communication method
              </p>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                {" "}
                <CheckBoxComponent
                  disabled={step.ActionType == "" ? true : false}
                  checked={step.OverridePreference}
                  onChange={() => [
                    updateFieldChanged(i, "OverridePreference", !step.OverridePreference),
                    !step.OverridePreference && [
                      updateFieldChanged(i, "SendEmail", false),
                      updateFieldChanged(i, "SendSMS", false),
                    ],
                  ]}
                  Label="Override user preferences"
                />{" "}
              </Box>
            </div>
            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Send alert to
              </p>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                {" "}
                <CheckBoxComponent
                  disabled={step.ActionType == "" || !step.OverridePreference ? true : false}
                  checked={step.SendEmail}
                  onChange={() => updateFieldChanged(i, "SendEmail", !step.SendEmail)}
                  Label="Email"
                />{" "}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                {" "}
                <CheckBoxComponent
                  disabled={step.ActionType == "" || !step.OverridePreference ? true : false}
                  checked={step.SendSMS}
                  onChange={() => updateFieldChanged(i, "SendSMS", !step.SendSMS)}
                  Label="SMS"
                />{" "}
              </Box>
            </div>
          </div>

          <Divider style={{ padding: "10px 0px" }} />
          <Box sx={{ paddingTop: "16px" }}>
            <p
              style={{
                color: "#222934",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: "normal",
              }}
            >
              Recurrence
            </p>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  width: "45%",
                }}
              >
                <TextFieldComponent
                  padding={false}
                  Label="Execute step (times)"
                  placeholder="5 times"
                  name="Recurrences"
                  onChange={(e) =>
                    isNumeric(e.target.value)
                      ? typeof e.target.value === "string" &&
                        (e.target.value.includes("-") || e.target.value.includes("."))
                        ? null
                        : updateFieldChanged(i, "Recurrences", e.target.value)
                      : null
                  }
                  value={step.Recurrences || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ display: "grid" }}>
                          <i
                            onClick={() => updateFieldChanged(i, "Recurrences", ++step.Recurrences)}
                            class="fa-light fa-angle-up InputFieldOperator"
                          ></i>
                          <i
                            onClick={() => {
                              if (step.Recurrences - 1 < 0) {
                                return;
                              }
                              updateFieldChanged(i, "Recurrences", --step.Recurrences);
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            class="fa-light fa-angle-down InputFieldOperator"
                          ></i>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  width: "45%",
                }}
              >
                <TextFieldComponent
                  padding={false}
                  Label="Every"
                  placeholder="10 minutes"
                  name="RecurrenceInterval"
                  onChange={(e) =>
                    isNumeric(e.target.value)
                      ? typeof e.target.value === "string" &&
                        (e.target.value.includes("-") || e.target.value.includes("."))
                        ? null
                        : updateFieldChanged(i, "RecurrenceInterval", e.target.value)
                      : null
                  }
                  value={step.RecurrenceInterval || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ display: "grid" }}>
                          <i
                            onClick={() =>
                              updateFieldChanged(i, "RecurrenceInterval", ++step.RecurrenceInterval)
                            }
                            // onMouseDown={handleMouseDownPassword}
                            class="fa-light fa-angle-up InputFieldOperator"
                          ></i>
                          <i
                            onClick={() => {
                              if (step.RecurrenceInterval - 1 < 0) {
                                return;
                              }
                              updateFieldChanged(
                                i,
                                "RecurrenceInterval",
                                --step.RecurrenceInterval
                              );
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            class="fa-light fa-angle-down InputFieldOperator"
                          ></i>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Box>

          <Divider style={{ padding: "10px 0px" }} />
          <Box sx={{ paddingTop: "16px" }}>
            <p
              style={{
                color: "#222934",
                fontSize: "18px",
                lineHeight: "16px",
                fontWeight: "normal",
              }}
            >
              Step delay
            </p>
            <div
              style={{
                paddingTop: "20px",
              }}
            >
              <TextFieldComponent
                fullWidth={true}
                padding={false}
                Label="Time until next step (min)"
                placeholder="10 minutes"
                name="Delay"
                onChange={(e) =>
                  isNumeric(e.target.value)
                    ? typeof e.target.value === "string" &&
                      (e.target.value.includes("-") || e.target.value.includes("."))
                      ? null
                      : updateFieldChanged(i, "Delay", e.target.value)
                    : null
                }
                value={step.Delay || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ display: "grid" }}>
                        <i
                          onClick={() => {
                            updateFieldChanged(i, "Delay", ++step.Delay);
                          }}
                          // onMouseDown={handleMouseDownPassword}
                          class="fa-light fa-angle-up InputFieldOperator"
                        ></i>
                        <i
                          onClick={() => {
                            if (step.Delay - 1 < 0) {
                              return;
                            }
                            updateFieldChanged(i, "Delay", --step.Delay);
                          }}
                          // onMouseDown={handleMouseDownPassword}
                          class="fa-light fa-angle-down InputFieldOperator"
                        ></i>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>
          <Divider style={{ padding: "10px 0px" }} />
        </Box>
      )}
    </Box>
  );
}
