import React from "react";

//COMPONENTS IMPORT
import IconButtonComponent from "../../../Components/BaseComponents/IconButton";

const EditLocation = (props) => {
  let { location, handleEdit } = props;
  return (
    <>
      <IconButtonComponent
        onClick={() => handleEdit(location)}
        Component={<i class="fa-light fa-pen-to-square "></i>}
      />
    </>
  );
};

export default EditLocation;
