import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    UserID: null,
    FirstName: null,
    LastName: null,
    userGroupUsers: null,
    CustomerID: null,
    Authorizations: null,
    Role: null,
    SelectedCustomer: null,
    RequireActionReason: null,
    RequireAlarmReason: null,
    RequireAuthentication: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const {
        UserName,
        FirstName,
        LastName,
        Token,
        UserID,
        userGroupUsers,
        CustomerID,
        Authorizations,
        ProfileImage,
        RoleID,
        RequireActionReason,
        RequireAlarmReason,
        RequireAuthentication,
        loggedOut,
      } = action.payload;
      let authorizationsObject = {};
      Authorizations?.forEach((authorization) => {
        const mappedObject = {
          element: authorization.ElementName,
          state: authorization.UIState,
        };
        if (authorization.UIState !== "Hidden") {
          if (authorizationsObject[authorization.PageName]) {
            authorizationsObject[authorization.PageName].push(mappedObject);
          } else {
            authorizationsObject[authorization.PageName] = [mappedObject];
          }
        }
      });

      let isAdmin = false;
      if (
        authorizationsObject.hasOwnProperty("App") ||
        authorizationsObject.hasOwnProperty("tenant")
      ) {
        isAdmin =
          authorizationsObject["tenant"].some((val) => val.element === "tenant") ||
          authorizationsObject["App"].some((val) => val.element === "Admin Authorization");
      }

      state.user = UserName;
      state.token = Token;
      state.UserID = UserID;
      state.userGroupUsers = userGroupUsers;
      state.CustomerID = CustomerID;
      state.Authorizations = authorizationsObject;
      state.LastName = LastName;
      state.FirstName = FirstName;
      state.ProfileImage = ProfileImage;
      state.Role = RoleID;
      state.SelectedCustomer = CustomerID;
      state.RequireActionReason = RequireActionReason;
      state.RequireAlarmReason = RequireAlarmReason;
      state.RequireAuthentication = RequireAuthentication;
      state.isAdmin = isAdmin;
      state.loggedOut = loggedOut;
    },
    setCurrentUser: (state, action) => {
      Object.keys(action.payload).forEach((k) => (state[k] = action.payload[k]));
    },
    logOut: (state, action) => {
      state.ProfileImage = null;
      state.user = null;
      state.token = null;
      state.UserID = null;
      state.userGroupUsers = null;
      state.CustomerID = null;
      state.Authorizations = {};
      state.LastName = null;
      state.FirstName = null;
      state.Role = null;
      state.SelectedCustomer = "";
      state.isAdmin = false;
      state.loggedOut = true;
      action.type = "SIGNOUT_REQUEST";
    },
    setCurrentCustomer: (state, action) => {
      state.SelectedCustomer = action.payload.CustomerID;
    },
  },
});

export const { setCredentials, logOut, setCurrentUser, setCurrentCustomer } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentAuth = (state) => state.auth;
