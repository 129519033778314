import React, { createContext, useState } from "react";

export const LocationContext = createContext();

const LocationProvider = (props) => {
  const [locationData, setLocationData] = useState([]);

  return (
    <LocationContext.Provider value={{ locationData, setLocationData }}>
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
