import React, { useState } from "react";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import TextFieldComponent from "../BaseComponents/TextField";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import Label from "../BaseComponents/Label";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function RightDrawer({ ...props }) {
  const {
    toggleDrawer,
    drawer,
    edit,
    tenant,
    setTenant,
    handleClick,
    buttonValidation,
    clearTenant,
    setTenantUpdated,
    confirmationDrawer,
    discardSettingsDrawer,
    tenantToggleDrawer,
    validateEmail,
    tenantUpdated,
  } = props;

  const [customerDrawer, setCustomDrawer] = useState(false);

  const useStyles = makeStyles({
    itemPadding: {
      paddingLeft: "24px !important",
    },
    paddingTop: {
      paddingTop: "24px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });

  const handleDiscard = (e) => {
    setTenantUpdated(false);
    confirmationDrawer(false);
    tenantToggleDrawer(false);
  };

  const classes = useStyles();

  return (
    <>
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        title={edit ? "Edit tenant" : "New tenant"}
        submitButtonTitle={edit ? "Update tenant" : "Add tenant"}
        handleSubmit={handleClick}
        displayFooter={true}
        closeToast={clearTenant}
        buttonValidation={buttonValidation || (edit && !tenantUpdated)}
        discardSettingsDrawer={discardSettingsDrawer}
        confirmationDrawer={confirmationDrawer}
        setUpdated={setTenantUpdated}
        handleDiscard={handleDiscard}
      >
        <Box sx={{ padding: "24px" }}>
          <TextFieldComponent
            padding={false}
            placeholder="Enter tenant name"
            onChange={(e) => [
              setTenantUpdated(true),
              setTenant({
                ...tenant,
                TenantName: e.target.value,
              }),
            ]}
            value={tenant.TenantName || ""}
            name="UserRoleName"
            variant="outlined"
            Label="Tenant name"
          />
          <TextFieldComponent
            padding={true}
            type="email"
            placeholder="Enter owner email"
            disabled={tenant.TenantName === ""}
            onChange={(e) => [
              setTenantUpdated(true),
              setTenant({
                ...tenant,
                OwnerEmail: e.target.value,
              }),
            ]}
            error={
              tenant.OwnerEmail !== "" ? (validateEmail(tenant.OwnerEmail) ? false : true) : false
            }
            value={tenant.OwnerEmail || ""}
            helperText={
              tenant.OwnerEmail !== ""
                ? validateEmail(tenant.OwnerEmail)
                  ? ""
                  : "Email is not valid."
                : false
            }
            name="UserRoleName"
            variant="outlined"
            Label="Owner email"
          />
        </Box>
        <Grid
          className={classes.itemPadding}
          item
          xs={12}
        >
          <Label
            sx={{
              color: "#9CA1A6",
            }}
          >
            Require reason
          </Label>
          <Box
            sx={{
              display: "flex",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "30%",
              }}
            >
              <CheckBoxComponent
                disabled={tenant.OwnerEmail == "" ? true : false}
                checked={tenant.RequireActionReason}
                onChange={() => [
                  setTenantUpdated(true),
                  setTenant({
                    ...tenant,
                    RequireActionReason: !tenant.RequireActionReason,
                  }),
                ]}
                Label="Actions"
              />{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckBoxComponent
                disabled={tenant.OwnerEmail == "" ? true : false}
                checked={tenant.RequireAlarmReason}
                onChange={() => [
                  setTenantUpdated(true),
                  setTenant({
                    ...tenant,
                    RequireAlarmReason: !tenant.RequireAlarmReason,
                  }),
                ]}
                Label="Alarms"
              />{" "}
            </Box>
          </Box>
        </Grid>
        <Grid
          className={`${classes.itemPadding} ${classes.paddingTop}`}
          item
          xs={12}
        >
          <Label
            sx={{
              color: "#9CA1A6",
            }}
          >
            Require authentication
          </Label>
          <Box
            sx={{
              display: "flex",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
              }}
            >
              <CheckBoxComponent
                disabled={tenant.OwnerEmail == "" ? true : false}
                checked={tenant.RequireAuthentication}
                onChange={() => [
                  setTenantUpdated(true),
                  setTenant({
                    ...tenant,
                    RequireAuthentication: !tenant.RequireAuthentication,
                  }),
                ]}
                Label="Authentication required"
              />{" "}
            </Box>
          </Box>
        </Grid>
      </ResourceDrawerComponent>
    </>
  );
}
