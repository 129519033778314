import { apiSlice } from "../API";

export const sensorSuppressSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    suppressSensors: builder.mutation({
      query: (data) => ({
        url: `/sensorSuppress/cache`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Equipment", "LiveData", "Sensor", "Substation", "Suppress"],
    }),

    unSuppressSensors: builder.mutation({
      query: (data) => ({
        url: `/sensorSuppress/cache/remove`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Equipment", "LiveData", "Sensor", "Substation", "Suppress"],
    }),

    findSupressedSensors: builder.mutation({
      query: (data) => ({
        url: `/suppressSensors/cache/all`,
        method: "POST",
        body: data,
      }),

      providesTags: ["Suppress"],
    }),
  }),
});

export const {
  useSuppressSensorsMutation,
  useUnSuppressSensorsMutation,
  useFindSupressedSensorsMutation,
} = sensorSuppressSlice;
