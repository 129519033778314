import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import { Languages } from "../../Utils/constants";
import { filterObjectByValue } from "../../Utils/Tools";
import { UTCTimeZones } from "../../Utils/constants";
import { components } from "react-select";
import Box from "@mui/material/Box";
import DownloadIcon from "./DownloadIcon";
import EditUsers from "../../Routes/Setup/Users/EditUsers";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { findObjectByValue } from "../../Utils/Tools";
import { lightGrey } from "../../Styles/theme/colors";
import CustomAvatar from "../Avatar";
import TableComponent from "../BaseComponents/Table";
import LabelComponent from "../BaseComponents/LabelComponent";
import UserGroupsTable from "./UserGroupsTable";
import { makeStyles } from "@material-ui/core/styles";
import UserFilter from "../BaseComponents/Filter/UserFilter";
import SearchComponent from "../BaseComponents/Search";
import ToolTipComponent from "../BaseComponents/Tooltip";
import IconButtonComponent from "../BaseComponents/IconButton";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
const convertDataToCSV = (data) => {
  const csvContent = data
    .map((item) => {
      const rowData = item.data.slice(1).reduce((accumulator, value) => {
        if (value === null) {
          return accumulator;
        }
        if (typeof value === "object") {
          return [...accumulator, `"${value.user}"`];
        }
        return [...accumulator, `"${value}"`];
      }, []);

      return rowData.join(",");
    })
    .join("\n");

  return csvContent;
};

const UsersTable = (props) => {
  let {
    auth,
    sensorAlertTypes,
    userGroups,
    userGroupUsers,
    authorizationGroups,
    customers,
    displayCustomer,
    allowedEdit,
  } = props;
  let { users, handleEdit, toggleDrawer } = props;
  const [usersGroupedData, setUsersGroupedData] = useState({});
  const [tab, setTab] = useState(0);
  const [tableLook, setTableLook] = useState("dense");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [personName, setPersonName] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPrefrences, setSelectedPrefrences] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedSensorAlertTypes, setSelectedSensorAlertTypes] = useState([]);
  const [tempUsers, setTempUsers] = useState(users);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [numberOfFilter, setNumberOfFilter] = useState(0);
  const [userGroupFilterCount, setUserGroupFilterCount] = useState(0);
  const [languageFilterCount, setLanguageFilterCount] = useState(0);
  const [timeZoneFilterCount, setTimeZoneFilterCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [invisible, setInvisible] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageFilterCount(event.target.value.length);

    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const selectUserGroups = (event) => {
    const {
      target: { value },
    } = event;
    setUserGroupFilterCount(event.target.value.length);
    setGroups(typeof value === "string" ? value.split(",") : value);
  };

  const clearFilter = () => {
    setPersonName([]);
    setGroups([]);
    setSelectedRoles([]);
    setSelectedPrefrences([]);
    setSelectedStatus([]);
    setSelectedSensorAlertTypes([]);
    setSelectedTimezone([]);
    setNumberOfFilter(0);
    setUserGroupFilterCount(0);
    setLanguageFilterCount(0);
    setTimeZoneFilterCount(0);
  };

  const handleUserGroups = () => {
    let data = [],
      groups = {};
    if (userGroups.length > 0 && userGroupUsers.length > 0) {
      userGroups.map((UG) => {
        userGroupUsers.map((UGU) => {
          if (UGU.UserGroupID === UG.value) {
            data.push(findObjectByValue(users, "UserID", UGU.UserID));
          }
        });
        groups[UG.value] = data;
        data = [];
      });
      setUsersGroupedData(groups);
    }
  };

  useEffect(() => {
    handleUserGroups();
  }, [users, userGroups, userGroupUsers, auth]);

  useEffect(() => {
    setTempUsers(users);
  }, [users]);

  useEffect(() => {
    if (
      selectedRoles.length ||
      selectedPrefrences.length ||
      selectedStatus.length ||
      selectedSensorAlertTypes.length ||
      personName.length ||
      selectedTimezone.length ||
      groups.length ||
      searchText.length
    ) {
      let tempFilteredUser = users.filter((user) => {
        let filter1 = true,
          filter2 = true,
          filter3 = true,
          filter4 = true,
          filter5 = true,
          filter6 = true,
          filter7 = true,
          filter8 = true;

        if (selectedRoles.length) {
          filter1 = selectedRoles.some((role) => {
            return role == user.RoleID;
          });
        }
        if (selectedPrefrences.length) {
          filter2 = selectedPrefrences.some((item) => {
            return (item == "email" && user?.PreferEmail) || (item == "sms" && user?.PreferSMS);
          });
        }

        if (selectedSensorAlertTypes.length) {
          filter3 = selectedSensorAlertTypes.some((item) => {
            return item == user.SensorAlertCalendarType;
          });
        }

        if (personName.length) {
          filter4 = personName.some((language) => {
            return language == user.Language;
          });
        }

        if (selectedTimezone.length) {
          filter5 = selectedTimezone.some((timezone) => {
            return timezone == user.GMTAdjustment;
          });
        }
        if (groups.length) {
          filter6 = groups.some((group) => {
            const temp = filterObjectByValue(userGroupUsers, "UserGroupID", group);
            return temp.some((innerGroup) => innerGroup.UserID == user.UserID);
          });
        }
        if (selectedStatus.length) {
          filter7 = selectedStatus.some((item) => {
            return (item == "Active" && user?.IsActive) || (item == "Inactive" && !user?.IsActive);
          });
        }
        if (searchText.length) {
          filter8 =
            user.FirstName?.toLowerCase().includes(searchText.toLowerCase()) ||
            user.LastName?.toLowerCase().includes(searchText.toLowerCase()) ||
            user.UserName?.toLowerCase().includes(searchText.toLowerCase()) ||
            user.SNSPhone?.toLowerCase().includes(searchText.toLowerCase()) ||
            user.AdditionalInformation?.toLowerCase().includes(searchText.toLowerCase());
        }

        return filter1 && filter2 && filter3 && filter4 && filter5 && filter6 && filter7 && filter8;
      });

      setTempUsers(tempFilteredUser);
    } else {
      setTempUsers(users);
    }
  }, [
    selectedRoles,
    selectedPrefrences,
    selectedStatus,
    selectedSensorAlertTypes,
    personName,
    selectedTimezone,
    groups,
    searchText,
  ]);

  const MultiValueLabel = (props) => {
    return <components.MultiValueLabel {...props}>{props.children},</components.MultiValueLabel>;
  };

  const MultiValueRemove = (props) => {
    return <components.MultiValueRemove {...props}>,</components.MultiValueRemove>;
  };

  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <div>{props.children}</div>
      </components.MenuList>
    );
  };
  useEffect(() => {
    numberOfFilter < 1 ? setInvisible(true) : setInvisible(false);
  }, [numberOfFilter]);

  const handleTimezoneChange = (count) => {
    setTimeZoneFilterCount(count);
  };

  const handlePermissionList = (event) => {
    let updatedList = [...selectedRoles];
    if (event.target.checked) {
      updatedList = [...selectedRoles, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedRoles.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedRoles(updatedList);
  };

  const handlePreferenceList = (event) => {
    let updatedList = [...selectedPrefrences];
    if (event.target.checked) {
      updatedList = [...selectedPrefrences, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedPrefrences.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedPrefrences(updatedList);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setNumberOfFilter(numberOfFilter - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSensorAlertTypes = (event) => {
    let updatedList = [...selectedSensorAlertTypes];
    if (event.target.checked) {
      updatedList = [...selectedSensorAlertTypes, event.target.value];
      setNumberOfFilter(numberOfFilter + 1);
    } else {
      setNumberOfFilter(numberOfFilter - 1);

      updatedList.splice(selectedSensorAlertTypes.indexOf(event.target.value), 1);
    }
    setSelectedSensorAlertTypes(updatedList);
  };

  const classes = useStyles();

  const columns = [
    {
      name: "user",
      label: "User",
      options: {
        filter: false,
        download: false,
        searchable: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.user.split(" ")[0].toUpperCase();
            var valueTwo = userListTwo.user.split(" ")[0].toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: tableLook == "relax" ? "10px 0px 10px 16px" : "0px 0px 0px 16px",
              }}
            >
              <CustomAvatar
                sx={{
                  bgcolor: "#7151A1",
                  width: tableLook == "relax" ? "64px" : "32px",
                  height: tableLook == "relax" ? "64px" : "32px",
                  fontSize: tableLook == "relax" ? "29px" : "14px",
                }}
                name={value.user}
                src={
                  value.image ? `${process.env.REACT_APP_URL}/asset/download/${value.image}` : null
                }
              />
              <div style={{ marginLeft: 10 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600 }}
                >
                  {value.user}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ color: lightGrey["900"] }}
                >
                  {value.email}
                </Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "FirstName",
      label: "First Name",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableLook == "dense" && (
                <div style={{ paddingLeft: tableLook === "dense" && "2px" }}>{value}</div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "LastName",
      label: "Last Name",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: true,
      },
    },

    {
      name: "Role",
      label: "Role",
      options: {
        download: true,
        searchable: false,
        sort: true,
      },
    },

    {
      name: "contactPhone",
      label: "Phone",
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "preference",
      label: "Preference",
      options: {
        filter: true,
        searchable: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            const label_1 = userListTwo?.props?.children[0]?.props?.children?.props?.label;
            const label_2 = userListTwo?.props?.children[1]?.props?.label;
            const label_3 = userListOne?.props?.children[0]?.props?.children?.props?.label;
            const label_4 = userListOne?.props?.children[1]?.props?.label;
            // Utility function to get weight based on the label array
            const getWeight = (labels) => {
              const emailCount = labels.filter((label) => label === "Email").length;
              const smsCount = labels.filter((label) => label === "SMS").length;
              if (emailCount > 0 && smsCount > 0) {
                return 1; // Both "Email" and "SMS" present
              } else if (emailCount > 0) {
                return 2; // Only "Email" present
              } else if (smsCount > 0) {
                return 3; // Only "SMS" present
              } else {
                return 4; // All are empty strings
              }
            };

            // Get weights for both user lists
            const weightOne = getWeight([label_3, label_4]);
            const weightTwo = getWeight([label_1, label_2]);

            // Compare based on weights and order
            if (order === "asc") {
              return weightOne - weightTwo;
            } else {
              return weightTwo - weightOne;
            }
          },
      },
    },
    {
      name: "Availability",
      label: "Availability",
      options: {
        filter: true,
        searchable: false,
        sort: true,
        display: false,
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.props.children.toUpperCase();
            var valueTwo = userListTwo.props.children.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
    {
      name: "Timezone",
      label: "Timezone",
      options: {
        filter: true,
        searchable: false,
        sort: true,
      },
    },

    {
      name: "AdditionalInformation",
      label: "Additional information",
      options: {
        sort: false,
        searchable: false,
      },
    },

    {
      name: "createdAt",
      label: "Created Date",
      options: {
        display: false,
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "updatedAt",
      label: "Updated Date",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
        display: false,
        searchable: false,
      },
    },
    {
      name: "", //actions
      label: "",
    },
    {
      name: "IsActive",
      label: "Status",
      options: {
        display: false,
        download: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: "PreferEmail",
      label: "PreferEmail",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "PreferSMS",
      label: "PreferSMS",
      options: {
        display: false,
        filter: false,
      },
    },
  ];

  const customOptions = {
    selectableRows: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      delete columns[8];
      delete columns[11];
      delete columns[12];
      delete columns[13];
      delete columns[14];
      delete columns[15];
      delete columns[16];
      data.map((row) => {
        delete row.data[7];
        delete row.data[11];
        delete row.data[10];
        delete row.data[12];
        delete row.data[13];
        delete row.data[14];
        delete row.data[15];
        delete row.data[16];
        let preferenceString = "";
        row?.data[6]?.props?.children?.map((item) => {
          let tempItem = item?.props?.children?.props?.label || item?.props?.label || "";
          preferenceString = preferenceString + tempItem;
        });
        if (preferenceString.includes("Email") && preferenceString.includes("SMS")) {
          preferenceString = preferenceString.replace("Email", "Email, ");
        }
        row.data[6] = preferenceString;
        const preferenceData = row?.data[9]?.props?.children;
        row.data[9] = preferenceData;
      });
      return buildHead(columns) + convertDataToCSV(data);
    },
    customSearch: (searchQuery, currentRow, columns) => {
      try {
        if (
          currentRow[0]?.user?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          currentRow[4]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          currentRow[6]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          currentRow[9]?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          currentRow[10]?.props?.children?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          currentRow[12]?.props?.children?.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
      } catch (error) {
        console.log(`Error while processing currentRow: ${error}`);
      }

      return false;
    },
    sortOrder: {
      name: "user",
      direction: "asc",
    },
  };

  const customComponents = {
    icons: {
      DownloadIcon: DownloadIcon,
    },
  };

  return (
    <Box>
      {tab == 0 && (
        <TableComponent
          zebraStyle={true}
          tableLook={tableLook}
          customOptions={customOptions}
          paddingLeft="16px"
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#222934",
                  width: "80px",
                  marginRight: "8px",
                }}
              >
                <b>
                  {tempUsers.length} {tempUsers.length <= 1 ? "user" : "users"}
                </b>
              </p>
              <Box sx={{ mr: 0.5 }}>
                <ToolTipComponent
                  title="View by user groups"
                  placement="bottom-start"
                  Component={
                    <Badge
                      badgeContent={numberOfFilter}
                      color="primary"
                      invisible={true}
                    >
                      <IconButtonComponent
                        Component={
                          <i
                            class="fa-light fa-users"
                            style={{ fontSize: "16px", lineHeight: "16px" }}
                            onClick={() => [setTab(1), setSearchText("")]}
                          ></i>
                        }
                      />
                    </Badge>
                  }
                />
              </Box>
              <Box sx={{ mr: "16px" }}>
                <UserFilter
                  authorizationGroups={authorizationGroups}
                  handlePermissionList={handlePermissionList}
                  selectedRoles={selectedRoles}
                  selectedPrefrences={selectedPrefrences}
                  selectedStatus={selectedStatus}
                  handlePreferenceList={handlePreferenceList}
                  handleStatusList={handleStatusList}
                  sensorAlertTypes={sensorAlertTypes}
                  handleSensorAlertTypes={handleSensorAlertTypes}
                  selectedSensorAlertTypes={selectedSensorAlertTypes}
                  selectedTimezone={selectedTimezone}
                  setSelectedTimezone={setSelectedTimezone}
                  handleTimezoneChange={handleTimezoneChange}
                  handleChange={handleChange}
                  personName={personName}
                  setPersonName={setPersonName}
                  Languages={Languages}
                  selectUserGroups={selectUserGroups}
                  groups={groups}
                  setGroups={setGroups}
                  userGroups={userGroups}
                  numberOfFilter={numberOfFilter}
                  userGroupFilterCount={userGroupFilterCount}
                  languageFilterCount={languageFilterCount}
                  timeZoneFilterCount={timeZoneFilterCount}
                  clearFilter={clearFilter}
                  displayCustomer={displayCustomer}
                />
              </Box>
              <SearchComponent
                placeholder="Search users...."
                searchText={searchText}
                setSearchText={setSearchText}
                width="320px"
              />
            </Box>
          }
          columns={columns}
          customToolbar={
            allowedEdit && (
              <TableHeaderAddButton
                onClick={() => toggleDrawer(true)}
                label="USER"
                width="80px"
              />
            )
          }
          components={customComponents}
          searchText={searchText}
          data={tempUsers?.map((row) => {
            const timezone = UTCTimeZones.find(
              (el) => el.value === row.GMTAdjustment?.split(" ")[0]
            );
            const role = findObjectByValue(authorizationGroups, "value", row?.RoleID);

            const availability = findObjectByValue(
              sensorAlertTypes,
              "value",
              row.SensorAlertCalendarType
            );

            return [
              {
                user: row.FirstName + " " + row.LastName,
                email: row.SNSEMail,
                image: row.ProfileImage,
              },
              row.FirstName,
              row.LastName,
              row.SNSEMail,

              role?.label,
              row.SNSPhone,
              <span
                style={{
                  display: "flex",
                }}
              >
                <Box sx={{ mr: "2px" }}>
                  {row.PreferEmail && (
                    <LabelComponent
                      label="Email"
                      state="primary"
                    />
                  )}
                </Box>

                {row.PreferSMS && (
                  <LabelComponent
                    label="SMS"
                    state="primary"
                  />
                )}
              </span>,
              <p>{availability?.label}</p>,

              timezone?.label,
              <p>{row.AdditionalInformation}</p>,
              new Date(row.createdAt).toLocaleDateString("en-US"),
              new Date(row.updatedAt).toLocaleDateString("en-US"),
              allowedEdit && (
                <EditUsers
                  user={row}
                  handleEdit={handleEdit}
                  paddingRight="18.55px"
                />
              ),
              row.IsActive ? "Active" : "Inactive",
            ];
          })}
          tableBodyHeight="calc(100vh - 253px)"
        />
      )}
      {tab == 1 && (
        <UserGroupsTable
          users={users}
          toggleDrawer={toggleDrawer}
          userGroups={userGroups}
          userGroupUsers={userGroupUsers}
          usersGroupedData={usersGroupedData}
          displayCustomer={displayCustomer}
          customers={customers}
          handleEdit={handleEdit}
          setTab={setTab}
          sensorAlertTypes={sensorAlertTypes}
          authorizationGroups={authorizationGroups}
          auth={auth}
          allowedEdit={allowedEdit}
        />
      )}
    </Box>
  );
};

export default UsersTable;
