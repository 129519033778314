import React from "react";
import TabComponents from "../../Components/Tabs/TabComponent";
import Box from "@mui/material/Box";

function Setup() {
  return (
    <Box sx={{ width: "100%" }}>
      <TabComponents />
    </Box>
  );
}

export default Setup;
