import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@Components/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Theme from "@Styles/theme/ThemeProvider";
import { BrowserRouter, Routes, Route, Outlet, useLocation } from "react-router-dom";
import "./App.css";
//CONTEXT PROVIDERS
import DrawerProvider from "@Context/DrawerContext";
import AlarmProvider from "@Context/AlarmContext";
import AuthProvider from "@Context/AuthContext";
import SnackbarProvider from "@Context/SnackbarContext";
//COMPONENTS
import MainContainer from "@Containers/MainContainer";
import Login from "@Routes/Login";
import Overview from "@Routes/Overview";
import Setup from "@Routes/Setup";
import Reports from "@Routes/Reports";
import Unauthorized from "@Routes/Unauthorized";
import Tenant from "./Routes/Setup/Tenant/TenantSetup";
import MobileAppBar from "@Components/AppBar/MobileAppBar";
import SplitPane from "@Components/SplitPane/SplitPane";
import AlarmPane from "@Components/SplitPane/AlarmPane";
import MainPane from "@Components/SplitPane/MainPane";
import { lightGrey } from "@Styles/theme/colors";
import { AlarmContext } from "@Context/AlarmContext";
import { Typography } from "@mui/material";
import RequireAuth from "@Components/Login/RequireAuth";
import LocationProvider from "./Context/LocationContext";
import AuthorizationGroups from "./Components/AuthorizationView";
import AlertScreen from "./Routes/AlertScreen";
import AuditTrailSetup from "./Routes/Setup/AuditTrail/AuditTrailSetup";
import SupportScreen from "./Routes/Support";
import EquipmentScreen from "./Routes/EquipmentScreen";
import FakeSensorOOBScreen from "./Routes/FakeSensorOOBScreen";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { apiSlice } from "./Store/API";
import TestScreen from "./Routes/TestScreen";
import ReportSetup from "./Routes/Setup/Report/ReportSetup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";
export default function App() {
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  const [INTERVAL, setINTERVAL] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setINTERVAL(
      setInterval(() => {
        dispatch(apiSlice.util.invalidateTags(["LiveData", "fakeSensorOOB"]));
      }, 15000)
    );
    return () => {
      clearInterval(INTERVAL);
    };
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <DrawerProvider>
          <AlarmProvider>
            <AuthProvider>
              <LocationProvider>
                <SnackbarProvider>
                  <Theme>
                    <BrowserRouter>
                      {maxWidthSm ? (
                        <Routes>
                          <Route element={<Login />}>
                            <Route
                              path="/"
                              exact
                              element={
                                <SplitPane mobileView={maxWidthSm}>
                                  <MobileAppBar />
                                  <AlarmPane setAlarmPanelToggle={true} />
                                </SplitPane>
                              }
                            />
                          </Route>
                        </Routes>
                      ) : (
                        <Routes>
                          <Route
                            path="/unauthorized"
                            exact
                            element={<Unauthorized />}
                          />
                          <Route
                            path="*"
                            element={<NotFoundPage />}
                          />
                          <Route
                            path="/testing"
                            exact
                            element={<TestScreen />}
                          />
                          <Route element={<Login />}>
                            <Route element={<MainRoutes />}>
                              <Route
                                path="/"
                                exact
                                element={<Overview />}
                              />
                              <Route
                                path="/statusview/equipmentDetails"
                                exact
                                element={<EquipmentScreen />}
                              />
                              <Route
                                path="/report"
                                exact
                                element={<ReportSetup />}
                              />
                              <Route
                                path="/authorization"
                                exact
                                element={<AuthorizationGroups />}
                              />
                              <Route
                                path="/Notifications"
                                exact
                                element={<AlertScreen />}
                              />
                              <Route element={<RequireAuth />}>
                                <Route
                                  path="/audittrail"
                                  exact
                                  element={<AuditTrailSetup />}
                                />
                              </Route>
                              <Route
                                path="/audittrail/:Name"
                                exact
                                element={<AuditTrailSetup />}
                              />
                              <Route
                                path="/fakeSensorOOB"
                                exact
                                element={<FakeSensorOOBScreen />}
                              />
                              <Route
                                path="/support"
                                exact
                                element={<SupportScreen />}
                              />
                              <Route
                                path="/statusview"
                                exact
                                element={<Overview />}
                              />
                              <Route
                                path="/tenant"
                                exact
                                element={<Tenant />}
                              />
                              <Route
                                path="/reports"
                                exact
                                element={<Reports />}
                              />
                              <Route element={<RequireAuth />}>
                                <Route
                                  path="setup"
                                  index
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/alertprograms"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/users"
                                  index
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/roles"
                                  index
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/customers"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/locations"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/equipment"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/substations"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/usergroups"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/sensorgroups"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/sensoralerts"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/archived"
                                  index
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/maps"
                                  element={<Setup />}
                                />
                                <Route
                                  path="setup/sensors"
                                  element={<Setup />}
                                />
                              </Route>
                            </Route>
                          </Route>
                        </Routes>
                      )}
                    </BrowserRouter>
                  </Theme>
                </SnackbarProvider>
              </LocationProvider>
            </AuthProvider>
          </AlarmProvider>
        </DrawerProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export const MainRoutes = () => {
  const alarmPanel = useContext(AlarmContext);
  const location = useLocation();


  useEffect(() => {
    if (!location.state && alarmPanel.alarmPanelToggle) {
      alarmPanel.setAlarmPanelToggle(false);
    }
  }, [location]);
  return (
    <div>
      <CssBaseline />
      <Box sx={{ display: "flex", backgroundColor: lightGrey["100"] }}>
        <AppBar />
        {
          <SplitPane
            style={{
              marginTop: "65px",
              backgroundColor: lightGrey["100"],
            }}
            className="split-pane-row"
          >
            <MainPane>
              <SplitPane className="split-pane-col">
                <MainContainer>
                  <Outlet />
                </MainContainer>
                {alarmPanel.alarmPanelToggle && (
                  <AlarmPane setAlarmPanelToggle={alarmPanel.setAlarmPanelToggle} />
                )}
              </SplitPane>
            </MainPane>
          </SplitPane>
        }
      </Box>
    </div>
  );
};

// A custom 404 page
const NotFoundPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100wv",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "stretch",
        alignItems: "center",
        bgcolor: theme.palette.primary["900"],
      }}
    >
      <Typography
        sx={{ color: "white" }}
        variant="h2"
      >
        Page Not Found
      </Typography>
      <Typography sx={{ color: "white", fontSize: 200 }}>404</Typography>
    </Box>
  );
};
