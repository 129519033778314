import React, { Fragment, useState, useEffect } from "react";
import SensorTable from "../../../Components/Tables/SensorTable";
import { Grid } from "@mui/material";

//COMPONENTS
import Toast from "@Components/BaseComponents/Toast";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import {
  useFindEquipmentSensorTypesQuery,
  useAddEquipmentMutation,
  useLiveEquipmentQuery,
} from "../../../Store/Slices/equipmentSlice";
import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";
import { useAddArchiveItemsMutation } from "../../../Store/Slices/archiveSlice";

import { filterObjectByValue, locationsString, validateUIState } from "../../../Utils/Tools";

import { default as SensorDrawer } from "@Components/RightDrawer/SensorDrawer";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { useSelector } from "react-redux";
import {
  useAddSensorConfigMutation,
  useAddSensorMutation,
  useEditSensorConfigMutation,
  useEditSensorMutation,
  useFindSensorsWithOutStatusQuery,
} from "../../../Store/Slices/sensorSlice";
import { useFindMappedEquipmentsQuery } from "../../../Store/Slices/mappedEquipmentSlice";
import { useFindMappedSubstationsQuery } from "../../../Store/Slices/mappedSubstationSlice";
import LimitTemplatesDrawer from "../../../Components/RightDrawer/LimitTemplatesDrawer";
import { useFindSensorLimitTemplateQuery } from "../../../Store/Slices/sensorLimitTemplateSlice";
import { useFindSubstationsQuery } from "../../../Store/Slices/substationSlice";

// Import the functions from the external module
import { handleLimitChange, handleSensorSaveOrEdit, useSensorEdit } from "./sensorUtils";
import {
  useSuppressSensorsMutation,
  useUnSuppressSensorsMutation,
} from "../../../Store/Slices/sensorSuppressSlice";
import SensorSuppressDrawer from "../../../Components/RightDrawer/SensorSuppressDrawer";
import { useFindProgramsQuery } from "../../../Store/Slices/programSlice";
import { useFindProgramStepsQuery } from "../../../Store/Slices/programStepSlice";

const SensorSetup = () => {
  const [drawer, setDrawer] = useState(false);
  const [limitsTemplateDrawer, setLimitsTemplateDrawer] = useState(false);
  const [suppressDrawer, setSuppressDrawer] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();
  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();
  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
    refetch: sensorsDataRefetch,
  } = useFindSensorsWithOutStatusQuery();
  const {
    data: equipmentLiveData,
    isLoading: equipmentLiveDataLoading,
    isSuccess: equipmentLiveDataSuccess,
    isError: equipmentLiveDataIsError,
    error: equipmentLiveDataError,
  } = useLiveEquipmentQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();
  const {
    data: programData,
    isFetching: programLoading,
    isSuccess: programSuccess,
    isError: programIsError,
    error: programError,
    refetch: AlertProgramDataRefetch,
  } = useFindProgramsQuery();

  const {
    data: programStepData,
    isFetching: programStepLoading,
    isSuccess: programStepSuccess,
    isError: programStepIsError,
    error: programStepError,
    refetch: AlertProgramStepDataRefetch,
  } = useFindProgramStepsQuery();

  const [
    addArchive,
    { isSuccess: addArchiveSuccess, isError: addArchiveIsError, error: addArchiveError },
  ] = useAddArchiveItemsMutation();

  const [addEquipment] = useAddEquipmentMutation();

  const [addSensor, { isSuccess: isSensorSuccess, error: AddSensorError }] = useAddSensorMutation();

  const [addSensorConfig] = useAddSensorConfigMutation();

  const [
    editSensor,
    { isSuccess: sensorEditSuccess, isError: editSensorError, error: sensorEditError },
  ] = useEditSensorMutation();
  const [editSensorConfig, { isSuccess: sensorConfigEditSuccess }] = useEditSensorConfigMutation();

  const [suppressSensors, { isSuccess: suppressSensorsSuccess, isError: suppressSensorsError }] =
    useSuppressSensorsMutation();
  const [
    unSuppressSensors,
    { isSuccess: unSuppressSensorsSuccess, isError: unSuppressSensorsError },
  ] = useUnSuppressSensorsMutation();
  const [equipments, setEquipments] = useState([]);
  const [equipment, setEquipment] = useState({
    EquipmentName: "",
    EquipmentTypeID: "",
    LocationID: "",
  });
  const [tempSensor, setTempSensor] = useState({});
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [substationData, setSubstationData] = useState({});
  const [mappedSubstations, setMappedSubstations] = useState([]);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [sensorEdit, setSensorEdit] = useState(false);
  const [sensorUpdated, setSensorUpdated] = useState(false);
  const [delEquipment, setDelEquipment] = useState(false);
  const [msg, setmsg] = useState("");
  const [delSensor, setDelSensor] = useState("");
  const [activateSensor, setActivateSensor] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [allSelectedSensors, setAllSelectedSensors] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [unSuppressAction, setUnSuppressAction] = useState("");
  const [selectedSensorIds, setSelectedSensorIds] = useState([]);

  const [selectedSensorLimitTemplate, setSelectedSensorLimitTemplate] = useState({});

  const isAdmin = auth.isAdmin;
  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Sensors");

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const initialSensor = {
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{}",
  };

  const [sensor, setSensor] = useState(initialSensor);

  const sensorButtonValidation = !(
    sensor.SensorLabel !== "" &&
    sensor.SensorTypeID !== "" &&
    sensor.EquipmentID !== "" &&
    sensor.Port !== "" &&
    sensor.SubstationSerial !== "" &&
    sensor.CustomerID !== ""
  );

  const loadEquipmentTypes = async () => {
    if (equipmentTypesLoading) setLoading(true);
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
    setLoading(false);
  };

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      let locationsInfo = {};
      setLocations(locationsData);

      for (const location of locationsData) {
        locationsInfo[location?.LocationID] = locationsString(location, locationsData);
      }
      setLocationsHierarchy(locationsInfo);
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const loadSubstations = () => {
    if (substationsSuccess) {
      const tempSubstations = substationsData.map((substation) => {
        return {
          ...substation,
          value: substation.SubstationID,
          label: substation.Label,
          SerialNumber: substation.SerialNumber,
          MeasurementInterval: substation.MeasurementInterval,
        };
      });
      if (auth.SelectedCustomer == "") {
        setSubstations(tempSubstations);
      } else {
        setSubstations(filterObjectByValue(tempSubstations, "CustomerID", auth.SelectedCustomer));
      }
      setLoading(false);
    }
    if (isSubstationError) console.log(substationError);
  };

  const loadEquipment = () => {
    if (equipmentLoading) setLoading(true);
    if (equipmentSuccess) {
      const tempEquipments = equipmentData.map((val) => {
        return {
          value: val.EquipmentID,
          label: val.EquipmentName,
          EquipmentID: val.EquipmentID,
          EquipmentName: val.EquipmentName,
          EquipmentTypeName: val.EquipmentType?.EquipmentTypeName,
          EquipmentTypeID: val.EquipmentType?.EquipmentTypeID,
          LocationName: val.Location?.LocationName,
          LocationID: val.LocationID,
          CustomerID: val.Location?.Customer.CustomerID,
          CustomerName: val.Location?.Customer.CustomerName,
          updatedAt: val.updatedAt,
          equipmentSensorTypes: val.equipmentSensorTypes,
        };
      });
      if (!isAdmin) {
        setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
      } else {
        if (auth.SelectedCustomer == "") setEquipments(tempEquipments);
        else {
          setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.SelectedCustomer));
        }
      }
    }
    if (equipmentIsError) console.log(equipmentError);
    setLoading(false);
  };

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess)
      setSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };

  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateLoading) setLoading(true);
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
    setLoading(false);
  };

  const loadSensors = () => {
    if (sensorsSuccess && equipmentLiveDataSuccess) {
      let tempSensors = [];
      let AllLiveSensors = [];
      equipmentLiveData?.success?.data.forEach((equipment) => {
        AllLiveSensors = [...AllLiveSensors, ...equipment.Sensors];
      });

      const length = sensorsData.length;
      const data = sensorsData;
      const liveDataMap = new Map(AllLiveSensors.map((sensor) => [sensor.SensorID, sensor]));

      const mergedSensors = data.map((sensor) => ({
        ...sensor,
        ...(liveDataMap.get(sensor.SensorID) || {}),
      }));
      for (let i = 0; i != length; i++) {
        tempSensors.push({
          ...mergedSensors[i],
        });
      }
      if (auth.SelectedCustomer == "") {
        setSensors(tempSensors);
      } else {
        setSensors(filterObjectByValue(tempSensors, "CustomerID", auth.SelectedCustomer));
      }
      setLoading(false);
    }

    if (sensorIsError) console.log(sensorError);
  };

  const loadMappedEquipments = () => {
    if (mappedEquipmentLoading) setLoading(true);
    if (mappedEquipmentSuccess) setMappedEquipments(mappedEquipmentData.success.data);
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData]);

  const loadMappedSubstations = () => {
    if (mappedSubstationLoading) setLoading(true);
    if (mappedSubstationSuccess) setMappedSubstations(mappedSubstationData.success.data);
    if (mappedSubstationIsError) console.log(mappedSubstationError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedSubstations();
  }, [mappedSubstationData]);

  const handleEquipmentSave = async (e) => {
    let data = equipment;
    let res = await addEquipment(data);

    if (res.data) {
      customToggleDrawer(false);
      toggleDrawer(false);
    }
    if (res.error) {
      setmsg(res.error.data.error.message);
    }
  };

  const equipmentButtonValidation = !(
    equipment.EquipmentTypeID !== "" &&
    equipment.EquipmentName !== "" &&
    equipment.LocationID !== ""
  );

  const toggleSuppressDrawer = (action) => {
    setSuppressDrawer(action);
  };

  const handleAlertSuppression = (sensor, action) => {
    handleSensorEdit(sensor);
    toggleSuppressDrawer(action);
    setDrawer(!action);
  };

  const handleSuppressDrawerEdit = (action) => {
    toggleSuppressDrawer(!action);
    setDrawer(action);
  };

  //DRAWER HANDLERS

  const customToggleDrawer = (open) => {
    setDrawer(open);
  };
  const [equipmentDeleteDrawer, setEquipmentDeleteDrawer] = useState(false);

  const customToggleEquipmentDeleteDrawer = (open) => {
    setEquipmentDeleteDrawer(open);
  };

  const [sensorDeleteDrawer, setSensorDeleteDrawer] = useState(false);

  const customToggleSensorDeleteDrawer = (open) => {
    setSensorDeleteDrawer(open);
  };

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData, auth]);

  useEffect(() => {
    loadSensors();
  }, [sensorsData, auth]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    loadSubstations();
  }, [substationsData, auth]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  const clearSensor = () => {
    setSensor(initialSensor);
    setSensorEdit(false);
    setSelectedSensorLimitTemplate({});
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (drawer === false) {
      clearSensor();
      setEdit(false);
    }
  };
  const toggleLimitsTemplateDrawer = (open) => {
    setLimitsTemplateDrawer(open);
  };

  const sensorConfirmationDrawer = (open) => {
    if (sensorUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };

  const handleSensorEdit = useSensorEdit(
    toggleDrawer,
    setSensorEdit,
    handleLimitChange,
    sensor,
    sensorLimitTemplates,
    setSelectedSensorLimitTemplate,
    setTempSensor,
    setSensor
  );

  const handleDelete = async (Sensor, reason) => {
    const status = !Sensor.IsActive;
    setDelSensor(!status);
    setActivateSensor(status);
    await addArchive({ data: { Sensor: [Sensor.SensorID] }, reason: reason });
    setSensorEdit(false);
    customToggleSensorDeleteDrawer(false);
    toggleDrawer(false);
    AlertProgramStepDataRefetch();
    AlertProgramDataRefetch();
  };

  useEffect(async () => {
    if (selectedAction.length > 0) {
      setLoading(true);
      let res = await suppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setSelectedAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [selectedAction]);

  useEffect(async () => {
    if (unSuppressAction.length > 0) {
      setLoading(true);
      let res = await unSuppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setUnSuppressAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [unSuppressAction]);

  const postExecution = () => {
    setSensorEdit(false);
    toggleDrawer(false);
    confirmationDrawer(false);
    sensorsDataRefetch();
  };

  const handleClick = (reason) => {
    const sensorMutation = sensorEdit ? editSensor : addSensor;
    const sensorConfigMutation = sensorEdit ? editSensorConfig : addSensorConfig;

    handleSensorSaveOrEdit(
      sensor,
      sensors,
      selectedSensorLimitTemplate,
      sensorMutation, // saveOrEditSensor parameter
      sensorConfigMutation, // saveOrEditSensorConfig parameter
      postExecution,
      sensorEdit, // isEdit parameter
      substationData,
      reason
    );
  };

  const editSensorSuccesMessage = () => {
    if (delSensor) {
      return "archived";
    } else if (activateSensor) {
      return "restored";
    } else {
      return "updated";
    }
  };

  useEffect(() => {
    if (!delSensor) return;
    const timeout = setTimeout(() => {
      setDelSensor(false);
      setActivateSensor(false);
    }, 8000);

    return () => clearTimeout(timeout);
  }, [delSensor]);

  useEffect(() => {});

  return (
    <Fragment>
      {isSensorSuccess && (
        <Toast
          message="Sensor successfully added"
          severity="success"
        />
      )}
      {sensorEditSuccess && (
        <Toast
          message={`Sensor successfully ${editSensorSuccesMessage()}`}
          severity="success"
        />
      )}
      {addArchiveSuccess && (
        <Toast
          message={`Sensor successfully archived`}
          severity="success"
        />
      )}
      {/* {sensorEditSuccess && delSensor && (
        <Toast message="Sensor successfully deactivated" severity="success" />
      )} */}
      {AddSensorError && (
        <Toast
          message="Sensor name is not unique"
          severity="error"
        />
      )}
      {editSensorError && (
        <Toast
          message={sensorEditError.data.error.message}
          severity="error"
        />
      )}

      {equipmentLoading || sensorsLoading || sensorLimitTemplateLoading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <SensorTable
            auth={auth}
            drawer={drawer}
            toggleDrawer={toggleDrawer}
            customToggleDrawer={customToggleDrawer}
            setLoading={setLoading}
            loading={loading}
            equipments={equipments}
            locations={locations}
            handleEdit={handleSensorEdit}
            locationsHierarchy={locationsHierarchy}
            sensorTypes={sensorTypes}
            sensors={sensors}
            mappedEquipments={mappedEquipments}
            mappedSubstations={mappedSubstations}
            limitsTemplateDrawer={limitsTemplateDrawer}
            toggleLimitsTemplateDrawer={toggleLimitsTemplateDrawer}
            sensorLimitTemplates={sensorLimitTemplates}
            allowedEdit={allowedEdit}
            setSelectedAction={setSelectedAction}
            setAllSelectedSensors={setAllSelectedSensors}
            setUnSuppressAction={setUnSuppressAction}
            handleAlertSuppression={handleAlertSuppression}
          />
          <Grid
            item
            md={12}
          >
            <SensorDrawer
              sensorEdit={sensorEdit}
              clearSensor={clearSensor}
              tempSensor={tempSensor}
              handleClick={handleClick}
              customerDrawer={drawer}
              sensorToggleDrawer={toggleDrawer}
              customToggleDrawer={sensorConfirmationDrawer}
              deleteDrawer={sensorDeleteDrawer}
              customToggleSensorDeleteDrawer={customToggleSensorDeleteDrawer}
              handleDelete={handleDelete}
              sensorUpdated={sensorUpdated}
              setSensorUpdated={setSensorUpdated}
              confirmationDrawer={confirmationDrawer}
              discardSettingsDrawer={discardSettingsDrawer}
              sensor={sensor}
              setSensor={setSensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              substations={substations}
              equipments={equipments}
              selectedSensorLimitTemplate={selectedSensorLimitTemplate}
              setSelectedSensorLimitTemplate={setSelectedSensorLimitTemplate}
              setSubstationData={setSubstationData}
              handleLimitChange={handleLimitChange}
              sensorButtonValidation={sensorButtonValidation}
            />
            <LimitTemplatesDrawer
              drawer={limitsTemplateDrawer}
              toggleDrawer={toggleLimitsTemplateDrawer}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              sensors={sensors}
            />
            <SensorSuppressDrawer
              suppressDrawer={suppressDrawer}
              toggleSuppressDrawer={toggleSuppressDrawer}
              sensor={sensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleSuppressDrawerEdit={handleSuppressDrawerEdit}
            />
          </Grid>
        </>
      )}
    </Fragment>
  );
};

export default SensorSetup;
