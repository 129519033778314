import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Group } from "react-konva";
import { Html } from "react-konva-utils";
import DropTarget from "../Drag/DropTarget";
import BackgroundImage from "../BaseComponents/BackgroundImage/BackgroundImage";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Box, Typography } from "@mui/material";
import { useFindSensorLimitTemplateQuery } from "../../Store/Slices/sensorLimitTemplateSlice";
import {
  findObjectByValue,
  MapDragBoundFunc,
  extractLimitTemplateValue,
  getLimitValue,
  getColorCodingStatus,
} from "../../Utils/Tools";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import "./style.css";
import LabelComponent from "../BaseComponents/LabelComponent";
import ToolTipComponent from "../BaseComponents/Tooltip";
import MapToolTipComponent from "../BaseComponents/mapToolTip";
import SensorLabel from "../BaseComponents/SensorLabel";
import { useFindSensorTypeQuery } from "../../Store/Slices/sensorTypeSlice";

const MapContainer = ({
  getAllequipments,
  setNewEquipment,
  items,
  editEquipment,
  locationMap,
  renderMap,
  SelectedEquipment,
  setSelectedEquipment,
  paneWidth,
}) => {
  const [loading, setLoading] = useState("loaded");
  const [imageHeight, setImageHeight] = useState("");
  const [mapedItems, setMapedItems] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [sizes, setSizes] = useState({
    height: null,
    width: null,
  });
  const { data: sensorLimitTemplateData } = useFindSensorLimitTemplateQuery();

  const sensorLimitTemplates = sensorLimitTemplateData?.success?.data
    ? [
        ...sensorLimitTemplateData.success.data.map((e) => {
          return {
            value: e.SensorLimitTemplateID,
            label: e.SensorLimitTemplateName,
            sensorTypeValue: e.SensorTypeID,
            LimitsJSON: e.LimitsJSON,
          };
        }),
        {
          value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
          label: "Custom Configurations",
          sensorTypeValue: "",
          LimitsJSON: {},
        },
      ]
    : [
        {
          value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
          label: "Custom Configurations",
          sensorTypeValue: "",
          LimitsJSON: {},
        },
      ];

  const equipmentRef = useRef();
  const stageRef = useRef();
  const [itemsTemp, setItemsTemp] = React.useState([]);

  const itemDropped = (item) => {
    setItemsTemp([...itemsTemp, item]);
  };

  useEffect(() => {
    setMapedItems(
      items.filter((equipment) => equipment.MapX !== null && equipment.MapX !== undefined)
    );
  }, [items]);

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess) setSensorTypes(sensorTypeData.success.data);
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };
  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    setSizes({
      width: window.innerWidth - paneWidth,
      height: window.innerHeight - 171,
    });
  }, [paneWidth]);

  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });
  useEffect(() => {
    if (SelectedEquipment) {
      const divSelected = document.getElementById(SelectedEquipment.EquipmentID);
      if (divSelected) {
        divSelected.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [SelectedEquipment]);
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale > 0.3 && newScale < 2)
      setStage({
        scale: newScale,
        x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
        y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
      });
  };

  function checkState(data) {
    return data.some((sensor) => typeof sensor == "object" && sensor.LiveData?.State != 0);
  }
  const resetZoom = () => {
    setStage({
      scale: 1,
      x: 0,
      y: 0,
    });
  };
  const setStageByHeight = () => {
    if (imageHeight > sizes.height) {
      const newScale = sizes.height / imageHeight;
      setStage({
        scale: newScale,
        x: sizes.width * 0.2,
        y: 0,
      });
    } else if (imageHeight < sizes.height) {
      const newScale = imageHeight / sizes.height;
      setStage({
        scale: newScale,
        x: sizes.width * 0.2,
        y: sizes.height * 0.2,
      });
    }
  };

  useEffect(() => {
    setStageByHeight();
  }, [imageHeight]);

  const scaleRelativeToPoint = (point, increaseScale) => {
    const scaleBy = 1.5;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: point.x / oldScale - stage.x() / oldScale,
      y: point.y / oldScale - stage.y() / oldScale,
    };

    let newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy;

    newScale = newScale < 0.3 ? 0.3 : newScale > 2 ? 2 : newScale;
    setStage({
      scale: newScale,
      x: (point.x / newScale - mousePointTo.x) * newScale,
      y: (point.y / newScale - mousePointTo.y) * newScale,
    });
  };

  return (
    <>
      {loading == "loading" && (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      )}
      <div>
        {renderMap && (
          <DropTarget
            editEquipment={editEquipment}
            onItemDropped={itemDropped}
            getAllequipments={getAllequipments}
            dropEffect="link"
            setNewEquipment={setNewEquipment}
            items={items}
          >
            <div style={{ display: "flex", position: "relative" }}>
              <MapToolTipComponent
                scaleRelativeToPoint={scaleRelativeToPoint}
                setStageByHeight={setStageByHeight}
                resetZoom={resetZoom}
                sizes={sizes}
              />
              <Stage
                draggable
                dragDistance={3}
                ref={stageRef}
                style={{
                  cursor: "grab",
                }}
                width={sizes.width}
                height={sizes.height}
                scaleX={stage.scale}
                scaleY={stage.scale}
                onWheel={handleWheel}
                x={stage.x}
                y={stage.y}
                dragBoundFunc={(pos) => MapDragBoundFunc(pos)}
              >
                <Layer
                  width={sizes.width}
                  height={sizes.height}
                >
                  <div>
                    <BackgroundImage
                      src={`${process.env.REACT_APP_URL}/asset/download/${locationMap}`}
                      width={sizes.width}
                      height={sizes.height}
                      setLoading={setLoading}
                      setImageHeight={setImageHeight}
                    />
                    {mapedItems.map((equipment, i) => {
                      let x = Number(equipment?.MapX);
                      let y = Number(equipment?.MapY);
                      const status = getColorCodingStatus(equipment?.Sensors, equipment?.IsActive);
                      const first =
                        equipment?.Sensors?.length > 3 && equipment?.Sensors.slice(0, 2);
                      const second = equipment?.Sensors?.length > 3 && equipment?.Sensors.slice(2);
                      const moreLabelStatus = second
                        ? getColorCodingStatus(second, equipment?.IsActive)
                        : second;
                      const iconColors =
                        (status === "none") | (status === "unacknowledged")
                          ? { statusColor: "#ECEDEE", color: "#9CA1A6" }
                          : { statusColor: STATUS_COLOR_CODE_MAP[status], color: "#FFFFFF" };

                      return (
                        <Group
                          x={x}
                          y={y}
                          id={equipment?.id}
                          equipmentRef={equipmentRef}
                          onClick={() => [setSelectedEquipment(equipment)]}
                        >
                          <Html
                            transform={true}
                            transformFunc={(attr) => ({
                              ...attr,
                              scaleX: 1,
                              scaleY: 1,
                            })}
                            divProps={{
                              style: {
                                zIndex: 1,
                              },
                            }}
                          >
                            <div
                              onClick={() => {
                                return [setSelectedEquipment(equipment)];
                              }}
                              id={equipment?.EquipmentID}
                              style={{
                                marginTop: "-37px",
                                marginLeft: "-15px",
                                display:
                                  SelectedEquipment?.EquipmentID != equipment?.EquipmentID
                                    ? "block"
                                    : "none",
                                backgroundColor: "white",
                                padding: "0.35em",
                                fontSize: "15px",
                                color: "black",
                                borderRadius: "10px",
                                border: "1px solid #e0e1e3",
                              }}
                              className="nonActiveEquipmentStatusView"
                            >
                              <div style={{ display: "flex" }}>
                                <span style={{ position: "relative" }}>
                                  <Box
                                    sx={{
                                      fontSize: "18px",
                                      backgroundColor: iconColors.statusColor,
                                      color: iconColors.color,
                                      padding: "3px 4px",
                                      borderRadius: "4px",
                                      fontWeight: "300",
                                    }}
                                    className="fa-solid fa-flux-capacitor tableRow"
                                  />
                                </span>
                              </div>
                            </div>
                          </Html>
                          <Html
                            transform={true}
                            transformFunc={(attr) => ({
                              ...attr,
                              scaleX: 1,
                              scaleY: 1,
                            })}
                          >
                            <div
                              id={equipment?.EquipmentID}
                              style={{
                                marginTop: "-50px",
                                marginLeft: "-40px",
                                display:
                                  SelectedEquipment?.EquipmentID == equipment?.EquipmentID
                                    ? "block"
                                    : "none",
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                                color: "black",
                                borderRadius: "10px",
                                border: "1px solid #e0e1e3",
                                boxShadow: "0px 3px 6px #00000029",
                              }}
                              className="nonActiveEquipment"
                            >
                              <div style={{ display: "flex" }}>
                                <span style={{ position: "relative" }}>
                                  <Box
                                    sx={{
                                      fontSize: "26px",
                                      backgroundColor: iconColors.statusColor,
                                      color: iconColors.color,
                                      padding: "1px 4px",
                                      borderRadius: "4px",
                                      lineHeight: "31px",
                                      fontWeight: "300",
                                    }}
                                    className="fa-solid fa-flux-capacitor tableRow"
                                  />
                                </span>
                                <div
                                  style={{
                                    display: "grid",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: " #222934",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {equipment.EquipmentName}
                                  </Typography>
                                  <div style={{ display: "flex" }}>
                                    {equipment.Sensors?.length > 0 ? (
                                      <>
                                        {equipment.Sensors?.length > 3 ? (
                                          <>
                                            {first.map((sensor) => {
                                              const sensorType = findObjectByValue(
                                                sensorTypes,
                                                "SensorTypeID",
                                                sensor.SensorTypeID
                                              );
                                              return (
                                                <SensorLabel
                                                  LiveData={sensor.LiveData}
                                                  sensorType={sensorType}
                                                  title={sensor.SensorLabel}
                                                />
                                              );
                                            })}
                                            <ToolTipComponent
                                              styles={{ maxWidth: "max-content" }}
                                              placement="bottom-start"
                                              title={second.map((sensor) => {
                                                const sensorLimitTemplate =
                                                  sensor.SensorLimitTemplateID !==
                                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a"
                                                    ? findObjectByValue(
                                                        sensorLimitTemplates,
                                                        "value",
                                                        sensor.SensorLimitTemplateID
                                                      )
                                                    : undefined;

                                                const sensorType = findObjectByValue(
                                                  sensorTypes,
                                                  "SensorTypeID",
                                                  sensor.SensorTypeID
                                                );

                                                const lowLimit = extractLimitTemplateValue(
                                                  getLimitValue(
                                                    sensor,
                                                    sensorLimitTemplate,
                                                    sensorType,
                                                    "LowLimitAlarm"
                                                  ),
                                                  "limit"
                                                );

                                                const highLimit = extractLimitTemplateValue(
                                                  getLimitValue(
                                                    sensor,
                                                    sensorLimitTemplate,
                                                    sensorType,
                                                    sensor.SensorTypeLabel === "Analog"
                                                      ? "HighLimitAlarm"
                                                      : "InputState"
                                                  ),
                                                  "limit"
                                                );

                                                return `${sensor.SensorLabel} | ${
                                                  sensor?.LiveData?.CurrentState ||
                                                  parseFloat(sensor?.LiveData?.Current)
                                                    .toFixed(2)
                                                    .concat(` ${sensorType?.Unit || "unit"}`) ||
                                                  "test"
                                                } | Alarm Limits [${lowLimit} to ${highLimit}] \n`;
                                              })}
                                              Component={
                                                <Box
                                                  sx={{
                                                    width: "fit-content",
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <LabelComponent
                                                    label={`${second?.length} more`}
                                                    state={moreLabelStatus}
                                                  />
                                                </Box>
                                              }
                                            />
                                          </>
                                        ) : (
                                          equipment.Sensors?.map((sensor) => {
                                            const sensorType = findObjectByValue(
                                              sensorTypes,
                                              "SensorTypeID",
                                              sensor.SensorTypeID
                                            );
                                            return (
                                              <SensorLabel
                                                LiveData={sensor.LiveData}
                                                sensorType={sensorType}
                                                title={sensor.SensorLabel}
                                              />
                                            );
                                          })
                                        )}
                                      </>
                                    ) : (
                                      <ToolTipComponent
                                        title={"There are no sensors attached to this equipment"}
                                        placement="bottom-start"
                                        Component={
                                          <Box
                                            sx={{
                                              width: "fit-content",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <LabelComponent
                                              label={"No sensors"}
                                              state={"neutral"}
                                            />
                                          </Box>
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Html>
                        </Group>
                      );
                    })}
                  </div>
                </Layer>
              </Stage>
            </div>
          </DropTarget>
        )}
      </div>
    </>
  );
};

export default MapContainer;
