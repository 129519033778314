import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

const MenuItemComponent = (props) => {
  const {
    handleChange,
    Children,
    style,
    component,
    to,
    ID,
    sensorID,
    startDate,
    endDate,
    selected,
    paddingTop,
    paddingBottom,
  } = props;

  const useStyles = makeStyles((theme) => ({
    menuItem: {
      "&:hover": {
        backgroundColor: "#E3E9EC !important",
        color: "#17455E !important",
      },
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: "#014361 !important",
        color: "#FFFFFF",
      },
    },
  }));

  const classes = useStyles();
  return (
    <MenuItem
      component={component || Link}
      to={to || window.location.href}
      state={{ ID: ID }}
      selected={props.selected}
      classes={{ selected: classes.selected }}
      sx={{
        color: "#222934",
        fontSize: "14px",
        fontWeight: "500",
        "&:focus": {
          borderColor: "#00AEEF",
          bgcolor: "#E3E9EC",
          color: "#17455E",
        },
        paddingTop: paddingTop ? paddingTop : null,
        paddingBottom: paddingBottom ? paddingBottom : null,
      }}
      style={style}
      onClick={handleChange}
    >
      {Children}
    </MenuItem>
  );
};
export default MenuItemComponent;
