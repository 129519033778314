import { Box, FormControl, FormControlLabel, FormGroup, Menu } from "@mui/material";
import React from "react";
import PillComponent from "../Pills";
import IconButtonComponent from "../IconButton";
import ToolTipComponent from "../Tooltip";
import CheckBoxComponent from "../Checkbox";
import FilterFooter from "./FilterFooter";

const SensorReportsFilter = (props) => {
  const { handleAlarmSelect, selectedAlarm, alarmFilterCount, clearFilter } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            alarmFilterCount > 0 ? "Filters (" + String(alarmFilterCount) + " applied)" : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <Box>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {alarmFilterCount > 0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={alarmFilterCount}
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            minHeight: "88px",
            overflow: "auto",
            padding: "16px",
            width: "552px",
          }}
        >
          <div>
            <p
              style={{
                textAlign: "left",
                font: "normal normal normal 12px/16px Roboto",
                color: "#9CA1A6",
              }}
            >
              Alarm state(s)
            </p>
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <FormControl
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <CheckBoxComponent
                        size="small"
                        checked={selectedAlarm.findIndex((item) => item == "1") > -1}
                        onChange={handleAlarmSelect}
                        value="1"
                        name="normal"
                        Label="Normal"
                      />
                    }
                  />

                  <FormControlLabel
                    control={
                      <CheckBoxComponent
                        size="small"
                        onChange={handleAlarmSelect}
                        value="2"
                        name="warning"
                        checked={selectedAlarm.findIndex((item) => item == "2") > -1}
                        Label="Warning"
                      />
                    }
                  />
                  <Box
                    sx={{
                      paddingRight: "20px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CheckBoxComponent
                          size="small"
                          onChange={handleAlarmSelect}
                          value="3"
                          name="alarm"
                          checked={selectedAlarm.findIndex((item) => item == "3") > -1}
                          Label="Alarm"
                        />
                      }
                    />
                  </Box>
                </Box>
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
};
export default SensorReportsFilter;
