import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import MapContainer from "./map";
import { useFindLocationsQuery, useEditLocationMutation } from "../../Store/Slices/locationsSlice";
import { useUploadImageMutation } from "../../Store/Slices/imageSlice";
import { useEditEquipmentMutation } from "../../Store/Slices/equipmentSlice";
import { useTheme } from "@mui/material/styles";

import { filterObjectByValue, findObjectByValue } from "../../Utils/Tools";
import Toast from "@Components/BaseComponents/Toast";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Divider } from "@mui/material";
import LocationsDropdown from "../BaseComponents/LocationsDropdown";
const MainContainer = (props) => {
  const { mappedEquipments, mappedEquipmentLoading, paneWidth, equipmentLive } = props;
  const theme = useTheme();

  const [uploadImage, { isSuccess: imageUploadSuccess }] = useUploadImageMutation();
  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [editLocation, { isSuccess: editLocationSucces, error: editLocationError }] =
    useEditLocationMutation();

  const [selectedId, selectShape] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [newEquipment, setNewEquipment] = useState(false);
  const [items, setItems] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [tempEquipments, setTempEquipments] = useState([]);
  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  // UPLOAD IMAGE FUNCTION
  const onSelectFile = async (event) => {
    let formData = new FormData();
    console.log(event, "File");
    formData.append("myFile", event, event.name);
    console.log(formData, "FormData");
    var res = await uploadImage(formData);
    setResponse(res.data.success.data.fileId);
    console.log("🚀 ~ file: index.js ~ line 105 ~ onSelectFile ~ res", res);
  };

  const getAllequipments = () => {
    setTempEquipments(
      mappedEquipments.map((equipment) => {
        const equipmentObj = findObjectByValue(equipmentLive, "EquipmentID", equipment.EquipmentID);
        let picked = {};
        if (equipmentObj)
          picked = (({ EquipmentName, Sensors }) => ({
            EquipmentName,
            Sensors,
          }))(equipmentObj);
        return {
          ...picked,
          ...equipment,
          isDragging: false,
          id: equipment.EquipmentID,
        };
      })
    );
    setEquipment(props.equipment);
  };

  useEffect(() => {
    getAllequipments();
  }, [equipmentLive, mappedEquipments, props.equipment, props.LocationID, props.locationObject]);

  useEffect(() => {
    if (response !== "") {
      const data = { MapFile: response };
      let res = editLocation({ id: props.LocationID, data });
    }
  }, [response]);

  useEffect(() => {
    setLocationItems(filterObjectByValue(tempEquipments, "LocationID", props.LocationID));
  }, [tempEquipments]);

  const validator = props.CustomerID === "" && props.LocationID == "";

  return (
    <Box
      component="span"
      sx={{ bgcolor: "#fff" }}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        {" "}
        {imageUploadSuccess && (
          <Toast
            message="Image was uploaded successfully."
            severity="success"
            autoHideDuration={3000}
          />
        )}
        {editEquipmentSucces && (
          <Toast
            message="Equipment was added successfully."
            severity="success"
            autoHideDuration={3000}
          />
        )}
        {validator && (
          <Toast
            message="Please Select Customer and Location"
            severity="error"
            autoHideDuration={3000}
          />
        )}
        {props.LocationID !== "" ? (
          <>
            {mappedEquipmentLoading ? (
              <SpinnerContainer>
                <Loader loading={loading} />
              </SpinnerContainer>
            ) : (
              <div>
                <div
                  style={{
                    height: "64px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      lineHeight: "28px",
                      color: "#222934",
                      padding: "0.8em",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <b> {props.locationObject?.LocationName}</b>
                    <LocationsDropdown
                      setSelectedLocationId={props.setSelectedLocationId}
                      selectedLocationId={props.LocationID}
                      substations={false}
                    />
                  </p>

                  <Divider />
                  {locationItems.length == 0 && (
                    <p
                      style={{
                        background: "#E8F1FE 0% 0% no-repeat padding-box",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "#014361",
                        padding: "0.6em",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        height: "64px",
                      }}
                    >
                      <i
                        className="fa-solid fa-info-circle fa-xl"
                        style={{
                          color: `${theme.palette["info"][900]}`,
                          marginRight: "5px",
                        }}
                      ></i>
                      There is no equipment or substations placed on this map. Add an item to the
                      map from the list to the right.
                    </p>
                  )}
                </div>
                <div style={{ overflow: "auto", marginTop: "16px" }}>
                  {props.locationObject?.MapFile !== "" ? (
                    <MapContainer
                      paneWidth={paneWidth}
                      selectedId={selectedId}
                      selectShape={selectShape}
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      equipment={equipment}
                      getAllequipments={getAllequipments}
                      setEquipment={setEquipment}
                      items={locationItems}
                      setItems={setItems}
                      editEquipment={editEquipment}
                      setNewEquipment={setNewEquipment}
                      locationMap={props.locationObject?.MapFile}
                      renderMap={true}
                      handleEquipmentSelect={props.handleEquipmentSelect}
                      SelectedEquipment={props.SelectedEquipment}
                      setSelectedEquipment={props.setSelectedEquipment}
                    />
                  ) : (
                    <>
                      <MapContainer
                        paneWidth={paneWidth}
                        selectedId={selectedId}
                        selectShape={selectShape}
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        equipment={equipment}
                        getAllequipments={getAllequipments}
                        setEquipment={setEquipment}
                        items={items}
                        setItems={setItems}
                        editEquipment={editEquipment}
                        setNewEquipment={setNewEquipment}
                        renderMap={false}
                        SelectedEquipment={props.SelectedEquipment}
                        setSelectedEquipment={props.setSelectedEquipment}
                      />
                      <div style={{ margin: "1em" }}>No Map found</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <p
            style={{
              background: "#E8F1FE 0% 0% no-repeat padding-box",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#014361",
              padding: "0.6em",
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
            }}
          >
            <i
              className="fa-solid fa-info-circle fa-xl"
              style={{
                color: `${theme.palette["info"][900]}`,
                marginRight: "5px",
              }}
            ></i>
            The equipment is not placed on any map
          </p>
        )}
      </div>
    </Box>
  );
};

export default MainContainer;
