import { apiSlice } from "../API";

export const userGroupsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserGroups: builder.query({
      query: () => "/userGroup",
      providesTags: ["UserGroup"],
    }),
    addUserGroup: builder.mutation({
      query(userGroup) {
        console.log(
          "🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice",
          userGroup
        );
        return {
          url: "/userGroup",
          method: "POST",
          body: userGroup,
        };
      },
      invalidatesTags: ["UserGroup", "UserGroupUsers"],
    }),
    getUserGroups: builder.query({
      query: () => "/userGroup",
      providesTags: ["UserGroup"],
    }),
    getUserGroup: builder.query({
      query: (id) => ({
        url: "userGroup",
        method: `/userGroup/${id}`,
      }),
      invalidatesTags: ["UserGroup"],
    }),
    deleteUserGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `/userGroup/${id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["UserGroup"],
    }),
    editUserGroup: builder.mutation({
      query: ({ id, userGroup }) => ({
        url: `/userGroup/${id}`,
        method: "PUT",
        body: userGroup,
      }),
      invalidatesTags: ["UserGroup", "User", "UserGroupUsers"],
    }),
  }),
});

export const {
  useFindUserGroupsQuery,
  useAddUserGroupMutation,
  useGetUserGroupsQuery,
  useGetUserGroupQuery,
  useDeleteUserGroupMutation,
  useEditUserGroupMutation,
} = userGroupsSlice;
