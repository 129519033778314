import { Box, Checkbox, ListItemText, Menu, MenuItem } from "@mui/material";
import IconButtonComponent from "../IconButton";
import React from "react";
import PillComponent from "../Pills";
import ToolTipComponent from "../Tooltip";
import MultipleSelectCheckmarks from "../MultiSelect";
import FilterFooter from "./FilterFooter";

export default function AuditTrailFilter(props) {
  const {
    categoryTypes,
    eventTypes,
    handleSelectCategoryType,
    handleSelectEventType,
    selectedCategoryTypes,
    selectedEventTypes,
    categoryFilterCount,
    typeFilterCount,
    subTypeFilterCount,
    userFilterCount,
    equipmentFilterCount,
    sensorFilterCount,
    substationFilterCount,
    eventSubTypes,
    selectedEventSubTypes,
    handleSelectEventSubType,
    users,
    handleSelectedUsers,
    selectedUsers,
    clearFilter,
    equipments,
    handleSelectedEquipments,
    selectedEquipments,
    sensors,
    handleSelectedSensors,
    selectedSensors,
    substations,
    handleSelectedSubstations,
    selectedSubstations,
    entityType,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            categoryFilterCount +
              typeFilterCount +
              subTypeFilterCount +
              userFilterCount +
              equipmentFilterCount +
              sensorFilterCount +
              substationFilterCount >
            0
              ? "Filters (" +
                String(
                  categoryFilterCount +
                    typeFilterCount +
                    subTypeFilterCount +
                    userFilterCount +
                    equipmentFilterCount +
                    sensorFilterCount +
                    substationFilterCount
                ) +
                " applied)"
              : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <Box>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {categoryFilterCount +
                      typeFilterCount +
                      subTypeFilterCount +
                      userFilterCount +
                      equipmentFilterCount +
                      sensorFilterCount +
                      substationFilterCount >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          categoryFilterCount +
                          typeFilterCount +
                          subTypeFilterCount +
                          userFilterCount +
                          equipmentFilterCount +
                          sensorFilterCount +
                          substationFilterCount
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            overflowY: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "700px",
            minHeight: "341px",
            overflow: "auto",
            padding: "16px",
            width: "520px",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Event category(-ies)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectCategoryType}
                placeholder="Select event category(-ies)"
                personName={selectedCategoryTypes}
                data={categoryTypes}
                marginTop="0px"
              >
                {categoryTypes.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedCategoryTypes.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Event type(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectEventType}
                placeholder="Select event type(s)"
                personName={selectedEventTypes}
                data={eventTypes}
                marginTop="0px"
              >
                {eventTypes.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedEventTypes.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Event subtype(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectEventSubType}
                placeholder="Select event subtype(s)"
                personName={selectedEventSubTypes}
                data={eventSubTypes}
                marginTop="0px"
              >
                {eventSubTypes.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedEventSubTypes.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                User(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectedUsers}
                placeholder="Select user(s)"
                personName={selectedUsers}
                data={users}
                marginTop="0px"
              >
                {users.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedUsers.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Substation(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectedSubstations}
                placeholder="Select substation(s)"
                personName={selectedSubstations}
                data={substations}
                marginTop="0px"
                disabled={entityType.Type === "Substation"}
              >
                {substations.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedSubstations.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Equipment(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectedEquipments}
                placeholder="Select equipment(s)"
                personName={selectedEquipments}
                data={equipments}
                marginTop="0px"
                disabled={entityType.Type === "Equipment"}
              >
                {equipments.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedEquipments.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "10px",
                }}
              >
                Sensor(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectedSensors}
                placeholder="Select sensor(s)"
                personName={selectedSensors}
                data={sensors}
                marginTop="0px"
                disabled={entityType.Type === "Sensor"}
              >
                {sensors.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedSensors.indexOf(item.value) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
