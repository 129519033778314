import React from "react";
import { formatDate } from "../../Utils/Tools";
import TableComponent from "../BaseComponents/Table";
import SensorLabel from "../BaseComponents/SensorLabel";

const ReportsTable = (props) => {
  let { tableLook, sensorReports, currentSensorType, columns } = props;

  const customOptions = {
    selectableRows: false,
    expandableRows: false,
    download: false,
  };
  sensorReports.sort((a, b) => b.Time - a.Time);

  return (
    <TableComponent
      zebraStyle={false}
      pagination={true}
      tableLook={tableLook}
      customOptions={customOptions}
      cellPaddingLeft="24px"
      paddingLeft="24px"
      rowsPerPage={250}
      rowsPerPageOptions={[
        { label: "1000", value: 0 },
        { label: "500", value: 1 },
        { label: "250", value: 2 },
      ]}
      cellPadding={true}
      borderRadius="0px"
      mt={"0px"}
      tableBodyMaxHeight="calc(90vh - 230px)"
      boxShadow="none"
      zeroHeight={true}
      columns={columns}
      data={sensorReports.map((row) => {
        return [
          formatDate(new Date(row.Time), "dd-MMM-yyyy - HH:mm:ss"),
          (!row.State || row.State == 0 || row.State == 4) ? "Normal" : row.State == 1 ? "Warning" : "Alarm",
          <SensorLabel
            LiveData={row}
            sensorType={currentSensorType}
            title={currentSensorType.label}
          />,
          <span>{row?.Raw}</span>,
          <span>
            {row?.Min} {currentSensorType.Unit}
          </span>,
          <span>
            {row?.LowerWarning} {currentSensorType.Unit}
          </span>,
          <span>
            {row?.UpperWarning} {currentSensorType.Unit}
          </span>,
          <span>
            {row?.Max} {currentSensorType.Unit}
          </span>,
        ];
      })}
    />
  );
};

export default ReportsTable;
