import React, { useEffect, useState } from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import DropDownComponent from "../DropDown";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles((theme) => ({}));

function CustomFooter(props) {
  let { count, page, rowsPerPage, changeRowsPerPage, changePage, rowsPerPageOptions } = props;

  const totalPages = Math.ceil(count / rowsPerPage);
  const startingRow = page * rowsPerPage;
  const endingRow = Math.min((page + 1) * rowsPerPage, count);

  const [rowsPerPageOption, setRowsPerPageOption] = useState(rowsPerPageOptions[2]);

  const customOptions = {
    minWidth: "10px",
  };

  useEffect(() => {
    changeRowsPerPage(parseInt(rowsPerPageOption.label));
  }, [rowsPerPageOption]);

  const handlePageChange = (event, currentPage) => {
    if (currentPage >= 0 && currentPage <= totalPages) {
      changePage(currentPage - 1);
    }
  };

  return (
    <TableFooter>
      <TableRow>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#3F3F3F",
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            <p style={{ margin: "0px 8px 0px 0px" }}>View</p>
            <DropDownComponent
              padding={false}
              menuPlacement={"top"}
              width="88px"
              options={rowsPerPageOptions}
              value={rowsPerPageOptions.find((el) => el.value === rowsPerPageOption.value) || ""}
              handleChange={(e) => setRowsPerPageOption(e)}
            />
            <p style={{ margin: "0px 0px 0px 8px" }}>
              entries per page | Showing {startingRow} to {endingRow} of {count} entries
            </p>
            <Box sx={{ flex: "1" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Pagination
                count={totalPages}
                shape="rounded"
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default CustomFooter;
