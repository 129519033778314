import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_URL,
  mode: "cors",
  credentials: "include",
  prepareHeaders: async (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
  tagTypes: ["fakeSensorOOB"],
});

export const apiSlice = createApi({
  baseQuery: baseQuery,

  // extractRehydrationInfo(action, { reducerPath }) {
  //     if (action.type === REHYDRATE) {
  //       return action.payload[reducerPath]
  //     }
  //   },

  endpoints: (builder) => ({}),
});
