import { apiSlice } from "../API";

export const authorizationGroupAuthorizationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findAuthorizationGroupAuthorizations: builder.query({
      query: () => "/authorizationGroupAuthorization",
      providesTags: ["AuthorizationGroupAuthorizations", "AuthorizationGroup"],
    }),
    addAuthorizationGroupAuthorizations: builder.mutation({
      query(user) {
        console.log("🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice", user);
        return {
          url: "/authorizationGroupAuthorization",
          method: "POST",
          body: user,
        };
      },
      invalidatesTags: ["AuthorizationGroupAuthorizations"],
    }),
    getAuthorizationGroupAuthorizations: builder.query({
      query: (id) => ({
        url: `/authorizationGroupAuthorization/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["AuthorizationGroupAuthorizations"],
    }),
    deleteAuthorizationGroupAuthorizations: builder.mutation({
      query: (id) => ({
        url: `/authorizationGroupAuthorization/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AuthorizationGroupAuthorizations"],
    }),
  }),
});

export const {
  useFindAuthorizationGroupAuthorizationsQuery,
  useAddAuthorizationGroupAuthorizationsMutation,
  useGetAuthorizationGroupAuthorizationsQuery,
  useDeleteAuthorizationGroupAuthorizationsMutation,
} = authorizationGroupAuthorizationsSlice;
