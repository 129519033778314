import React from "react";
import PropTypes from "prop-types";
import * as dropEffects from "./dropEffects";

const insideStyle = {
  backgroundColor: "#cccccc",
  opacity: 0.5,
};

const DropTarget = (props) => {
  let { items } = props;
  const [isOver, setIsOver] = React.useState(false);

  const dragOver = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = props.dropEffect;
  };

  const drop = async (ev) => {
    console.log("🚀 ~ file: DropTarget.js ~ line 24 ~ drop ~ ev", ev.dataTransfer);

    const droppedItem = ev.dataTransfer.getData("drag-item");
    console.log("🚀 ~ file: DropTarget.js ~ line 24 ~ drop ~ droppedItem", droppedItem);

    const parsedDroppedItem = JSON.parse(droppedItem);
    let id = parsedDroppedItem.EquipmentID;
    let data = { MapX: Number(ev.clientX), MapY: Number(ev.clientY) };
    ev.dataTransfer.dropEffect = "none";
    if (droppedItem) {
      props.onItemDropped(droppedItem);
    }
    await saveEquipmentLocation(id, data);
    setIsOver(false);
    // await props.getAllequipments()
    props.setNewEquipment(true);
  };

  const saveEquipmentLocation = async (id, data) => {
    console.log("🚀 ~ file: DropTarget.js ~ line 37 ~ saveEquipmentLocation ~ locationObj", data);
    console.log("🚀 ~ file: DropTarget.js ~ line 37 ~ saveEquipmentLocation ~ id", id);

    let res = await props.editEquipment({ id, data });
    console.log("🚀 ~ file: DropTarget.js ~ line 40 ~ saveEquipmentLocation ~ res", res);
  };

  const dragEnter = (ev) => {
    ev.dataTransfer.dropEffect = props.dropEffect;
    setIsOver(true);
  };

  const dragLeave = () => setIsOver(false);

  return (
    <div
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      style={{ width: "100%", height: "100%", ...(isOver ? insideStyle : {}) }}
    >
      {props.children}
    </div>
  );
};

DropTarget.propTypes = {
  onItemDropped: PropTypes.func.isRequired,
  dropEffect: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

DropTarget.defaultProps = {
  dropEffect: dropEffects.All,
};

export default DropTarget;
