import React from "react";
import { styled, alpha } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";
import { Box } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "#9CA1A6",
  width: "100%",
  height: "40px",
  border: "1px solid #9CA1A6",
  borderRadius: "4px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    border: "1px solid #17455E",
    borderRadius: theme.shape.borderRadius,
  },
  "&:focus-within": {
    boxShadow: "#00AEEF 0px 0px 0px 3px",
  },
  marginLeft: 8,

  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1),
    width: "100%",
    marginLeft: 0,
  },
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  color: "#9CA1A6",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  height: "40px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIconx
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const SearchComponent = (props) => {
  return (
    <Box sx={{ width: props.width || "100%", padding: props.padding }}>
      <Search>
        <SearchIconWrapper>
          <i
            class="fa-light fa-magnifying-glass"
            style={{ fontSize: "16px", lineHeight: "19px", color: "#9CA1A6" }}
          ></i>{" "}
        </SearchIconWrapper>
        <StyledInputBase
          variant="outlined"
          placeholder={props.placeholder}
          inputProps={{ "aria-label": "search" }}
          value={props.searchText}
          onChange={(e) => props.setSearchText(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Search>
    </Box>
  );
};
export default SearchComponent;
