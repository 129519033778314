import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  primary,
  lightGrey,
  blueAction,
  darkGrey,
  success,
  warning,
  info,
  error,
  orange,
  magenta,
  lightBlue,
  blue,
  brown,
  cyan,
  purple,
  purpleAction,
  teal,
  yellow,
} from "./colors";

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          color: "#9CA1A6",
          "&:hover": {
            color: "#00AEEF",
            backgroundColor: "#E0F5FD",
          },
        },
      },
    },
  },
  palette: {
    blueAction,
    darkGrey,
    success,
    warning,
    info,
    error,
    orange,
    magenta,
    lightBlue,
    cyan,
    blue,
    yellow,
    purple,
    teal,
    brown,
    purpleAction,
    white: "#fff",
    primary: {
      ...primary,
      main: primary["900"],
    },
    lightGrey: {
      ...lightGrey,
      main: lightGrey["500"],
    },
  },
});

const Theme = (props) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </Fragment>
  );
};

export default Theme;
