import { apiSlice } from "../API";

export const customersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findCustomers: builder.query({
      query: () => "/customer",
      providesTags: ["Customer"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item.IsActive === true);
      },
    }),
    addCustomer: builder.mutation({
      query: (customer) => ({
        url: "/customer",
        method: "POST",
        body: customer,
      }),
      invalidatesTags: ["Customer"],
    }),
    editCustomer: builder.mutation({
      query({ id, data }) {
        return {
          url: `/customer/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Customer"],
    }),
  }),
});

export const { useFindCustomersQuery, useAddCustomerMutation, useEditCustomerMutation } =
  customersSlice;
