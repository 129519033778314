import { makeStyles } from "@material-ui/core/styles";
import Label from "@Components/BaseComponents/Label";
import { Grid, Box } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        boxShadow: "#00AEEF 0px 0px 0px 3px",
        border: "1px solid #17455E",
      },
    },
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
});
const PhoneInputField = (props) => {
  const classes = useStyles();

  const {
    disableDropdown = true,
    defaultCountry = "US",
    size = "small",
    error,
    label,
    padding,
    paddingTop,
    height,
    ...restProps
  } = props;

  return (
    <Grid
      item
      xs={12}
      sx={{ paddingTop: padding ? "24px !important" : "0px !important" }}
    >
      <Label
        sx={{
          color: "#9CA1A6",
        }}
      >
        {label}
      </Label>
      <Box sx={{ paddingTop: paddingTop || "10px" }}>
        <MuiTelInput
          {...restProps}
          className={classes.root}
          disableDropdown={disableDropdown}
          error={error}
          size={size}
          defaultCountry={defaultCountry}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#222934",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              fontSize: "16px",
              border: "1px solid #9CA1A6",
              borderRadius: "4px",
              ":hover": { border: "1px solid #17455E", borderRadius: "4px" },
              height: height,
            },
            "&.Mui-focused": {
              boxShadow: "#00AEEF 0px 0px 0px 4px",
              border: "1px solid #17455E",
            },
            "& .MuiInputBase-root.Mui-disabled": {
              background: "#F1F1F1 0% 0% no-repeat padding-box",
              color: "#C0C3C7",
              border: "1px solid #C0C3C7 !important",
              borderRadius: "4px",
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default PhoneInputField;
