import React, { useEffect, useState } from "react";

//DRAWER CONTEXT
import { Box, Divider, Grid, TableCell, TableRow } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableComponent from "../BaseComponents/Table";
import LabelComponent from "../BaseComponents/LabelComponent";
import ToolTipComponent from "../BaseComponents/Tooltip";
import { deepCopy, filterObjectByValue, filterProgramStepsByProgramId, findObjectByValue } from "../../Utils/Tools";
import { useFindSubstationsQuery } from "../../Store/Slices/substationSlice";
import { useNavigate } from "react-router-dom";
import AlertProgramFilter from "../BaseComponents/Filter/AlertProgramFilter";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import EditPorgram from "../../Routes/Setup/AlertProgram/EditProgram";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";

const classes = {
  bold: {
    fontWeight: 600,
  },
};

const AlertProgramTable = (props) => {
  const theme = useTheme();
  const {
    toggleDrawer,
    equipments,
    userActions,
    mappedEquipments,
    mappedSubstations,
    sensorTypes,
    sensors,
    sensorLimitTemplates,
    programs,
    programSteps,
    users,
    userGroupActions,
    userGroups,
    allowedEdit,
    handleEdit,
  } = props;

  const [tableLook, setTableLook] = useState("dense");
  const [searchText, setSearchText] = useState("");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [expandedRows, setExpandedRows] = useState([]);
  const [substations, setSubstations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sensorAnchorEl, setSensorAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [tempPrograms, setTempPrograms] = useState(programs);
  const [tempProgramSteps, setTempProgramSteps] = useState(programSteps);
  const [tempUserActions, setTempUserActions] = useState(userActions);
  const [tempUserGroupActions, setTempUserGroupActions] = useState(userGroupActions);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setTempPrograms(deepCopy(programs));
      setTempProgramSteps(deepCopy(programSteps));
      setTempUserActions(deepCopy(userActions));
      setTempUserGroupActions(deepCopy(userGroupActions));
    }, 0);
  }, [programs, programSteps, userActions, userGroupActions]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [statusFilterCount, setStatusFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);

  useEffect(() => {
    if (
      selectedUsers.length ||
      selectedLocations.length ||
      selectedUserGroups.length ||
      selectedAlarm.length ||
      selectedStatus.length ||
      toDate != null ||
      fromDate != null
    ) {
      var filter1 = true,
        filter2 = true,
        filter3 = true,
        filter4 = true,
        filter5 = true,
        filter6 = true;
      let filteredPrograms = programs.filter((program) => {
        if (selectedAlarm.length) {
          filter2 = selectedAlarm.some((item) => {
            return (
              (item == "Alarm" && program?.Type == "Alarm") ||
              (item == "Warning" && program?.Type == "Warning")
            );
          });
        }

        return filter1 && filter2;
      });
      let filteredProgramSteps = programSteps.filter((programStep) => {
        const filteredUserActions = filterObjectByValue(
          userActions,
          "ProgramStepID",
          programStep.ProgramStepID
        );
        if (selectedStatus.length) {
          filter3 = selectedStatus.some((item) => {
            return (
              (item == "Notification" && programStep?.ActionType == "Notification") ||
              (item == "Physical" && programStep?.ActionType == "Physical")
            );
          });
        }

        return filter3 && filter4;
      });
      if (selectedUsers.length) {
        let userActionsData = [],
          programStepsData = [];
        tempUserActions.filter((UA) => {
          if (selectedUsers.includes(UA.UserID)) userActionsData.push(UA.ProgramStepID);
        });
        filteredProgramSteps = filteredProgramSteps.filter((programStep) => {
          if (userActionsData.includes(programStep.ProgramStepID)) return programStep;
        });

        filteredProgramSteps.map((programStep) => {
          programStepsData.push(programStep.ProgramID);
        });
        filteredPrograms = filteredPrograms.filter((program) => {
          if (programStepsData.includes(program.ProgramID)) return program;
        });
      }

      if (selectedUsers.length) {
        let userActionsData = [],
          programStepsData = [];
        tempUserActions.filter((UA) => {
          if (selectedUsers.includes(UA.UserID)) userActionsData.push(UA.ProgramStepID);
        });
        filteredProgramSteps = filteredProgramSteps.filter((programStep) => {
          if (userActionsData.includes(programStep.ProgramStepID)) return programStep;
        });

        filteredProgramSteps.map((programStep) => {
          programStepsData.push(programStep.ProgramID);
        });
        filteredPrograms = filteredPrograms.filter((program) => {
          if (programStepsData.includes(program.ProgramID)) return program;
        });
      }

      if (selectedUserGroups.length) {
        let userGroupActionsData = [],
          programStepsData = [];
        tempUserGroupActions.filter((UA) => {
          if (selectedUserGroups.includes(UA.UserGroupID))
            userGroupActionsData.push(UA.ProgramStepID);
        });
        filteredProgramSteps = filteredProgramSteps.filter((programStep) => {
          if (userGroupActionsData.includes(programStep.ProgramStepID)) return programStep;
        });

        filteredProgramSteps.map((programStep) => {
          programStepsData.push(programStep.ProgramID);
        });
        filteredPrograms = filteredPrograms.filter((program) => {
          if (programStepsData.includes(program.ProgramID)) return program;
        });
      }

      setTempPrograms(filteredPrograms);
      setTempProgramSteps(filteredProgramSteps);
    } else {
      setTempPrograms(programs);
      setTempProgramSteps(programSteps);
    }
  }, [
    selectedUsers,
    selectedUserGroups,
    selectedLocations,
    selectedAlarm,
    selectedStatus,
    toDate,
    fromDate,
  ]);

  const handleSelectUsers = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedUsers(typeof value === "string" ? value.split(",") : value);
  };

  const handleStatusList = (event) => {
    let updatedList = [...selectedStatus];
    if (event.target.checked) {
      updatedList = [...selectedStatus, event.target.value];
      setStatusFilterCount(statusFilterCount + 1);
    } else {
      updatedList.splice(selectedStatus.indexOf(event.target.value), 1);
      setStatusFilterCount(statusFilterCount - 1);
    }
    setSelectedStatus(updatedList);
  };

  const handleSelectUserGroup = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedUserGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value.split(",") : value);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const clearFilter = () => {
    setSelectedUsers([]);
    setSelectedUserGroups([]);
    setSelectedLocations([]);
    setSelectedAlarm([]);
    setSelectedStatus([]);
    setLocationFilterCount(0);
    setAlarmFilterCount(0);
    setStatusFilterCount(0);
    setEquipmentFilterCount(0);
    setSensorTypeFilterCount(0);
    setFromDate(null);
    setToDate(null);
  };
  const loadSubstations = () => {
    if (substationsSuccess) setSubstations(substationsData);
    if (isSubstationError) console.log(substationError);
  };

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  const columns = [
    {
      name: "AlertProgramID",
      label: "AlertProgramID",
      options: {
        display: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "AlertProgramName",
      label: "Alert program",
      options: {
        display: true,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "Type",
      label: "Type",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "Steps",
      label: "Steps",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: "Recurrence",
      label: "Recurrence",
      options: {
        display: true,
        searchable: false,
        sort: false,
      },
    },

    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {value}
          </div>
        ),
      },
    },
  ];

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = allRowsExpanded.map((item) => {
      return item.dataIndex;
    });

    setExpandedRows(rowsExpanded);
  };

  const customOptions = {
    selectableRows: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRows: false,
    rowsExpanded: expandedRows,
    onRowsExpand: (currentRowsExpanded, allRowsExpanded) => {
      updateRowsExpanded(allRowsExpanded);
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const programStepsData = filterProgramStepsByProgramId(programs, rowData[0]);
      return (
        <TableRow
          sx={{ backgroundColor: "#F7F8F8" }}
          key={rowData[1]}
        >
          <TableCell colSpan={7}>
            <Stepper
              orientation="vertical"
              style={{
                padding: "24px 28px",
              }}
            >
              {programStepsData.length > 0 ? (
                programStepsData.map((step, index) => {
                  const userActionsData = filterObjectByValue(
                    userActions,
                    "ProgramStepID",
                    step.ProgramStepID
                  );
                  const userGroupActionsData = filterObjectByValue(
                    userGroupActions,
                    "ProgramStepID",
                    step.ProgramStepID
                  );
                  return (
                    <Step
                      key={index}
                      active={true}
                    >
                      <StepLabel> </StepLabel>
                      <StepContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "65%",
                            }}
                          >
                            <p
                              style={{
                                color: "#222934",
                                fontSize: "18px",
                                lineHeight: "16px",
                                fontWeight: "normal",
                              }}
                            >
                              Action
                            </p>
                            <Grid
                              sx={{
                                display: "flex",
                                marginTop: "11px",
                              }}
                            >
                              <Box sx={{ width: "20%" }}>
                                <p
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    fontWeight: "normal",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Alert type
                                </p>
                                <LabelComponent
                                  label={step.ActionType}
                                  state="primary"
                                />
                              </Box>

                              {step.ActionType == "Notification" && (
                                <Box sx={{ display: "flex", width: "80%" }}>
                                  <Box sx={{ width: "65%" }}>
                                    <p
                                      style={{
                                        color: "#9CA1A6",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        fontWeight: "normal",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Users/Groups
                                    </p>
                                    <Box sx={{ display: "flex", flexFlow: "wrap" }}>
                                      {userActionsData.length == 0 &&
                                        userGroupActionsData.length == 0 && (
                                          <p
                                            style={{
                                              color: "#222934",
                                              fontSize: "12px",
                                              lineHeight: "14px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            No Users/Groups Found
                                          </p>
                                        )}
                                      {userActionsData.map((user) => {
                                        return (
                                          <LabelComponent
                                            label={
                                              findObjectByValue(users, "UserID", user.UserID)
                                                ?.UserName
                                            }
                                            marginRight="4px"
                                            marginBottom="4px"
                                            state="primary"
                                          />
                                        );
                                      })}
                                      {userGroupActionsData.map((userGroup) => {
                                        return (
                                          <LabelComponent
                                            label={
                                              findObjectByValue(
                                                userGroups,
                                                "value",
                                                userGroup.UserGroupID
                                              )?.label
                                            }
                                            marginRight="4px"
                                            marginBottom="4px"
                                            state="primary"
                                          />
                                        );
                                      })}
                                    </Box>
                                  </Box>

                                  <Box sx={{ width: "45%" }}>
                                    <p
                                      style={{
                                        color: "#9CA1A6",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        fontWeight: "normal",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Communication method
                                    </p>
                                    <Box sx={{ display: "flex", flexFlow: "wrap" }}>
                                      {!step.OverridePreference &&
                                        !step.SendSMS &&
                                        !step.SendEmail && (
                                          <p
                                            style={{
                                              color: "#222934",
                                              fontSize: "12px",
                                              lineHeight: "14px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            No Communication Method Selected
                                          </p>
                                        )}
                                      {step.OverridePreference && (
                                        <LabelComponent
                                          label="User Preference"
                                          state="primary"
                                          marginRight="4px"
                                          marginBottom="4px"
                                        />
                                      )}
                                      {step.SendSMS && (
                                        <LabelComponent
                                          label="SMS"
                                          state="primary"
                                          marginRight="4px"
                                          marginBottom="4px"
                                        />
                                      )}
                                      {step.SendEmail && (
                                        <LabelComponent
                                          label="Email"
                                          state="primary"
                                          marginRight="4px"
                                          marginBottom="4px"
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                              {step.ActionType == "Physical" && (
                                <Box sx={{ width: "50%" }}>
                                  <p
                                    style={{
                                      color: "#9CA1A6",
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      fontWeight: "normal",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Physical alarm
                                  </p>
                                  <LabelComponent
                                    label="Buzzer"
                                    state="primary"
                                    marginRight="4px"
                                  />
                                </Box>
                              )}
                            </Grid>{" "}
                          </Box>{" "}
                          <Box
                            sx={{
                              width: "18%",
                            }}
                          >
                            <p
                              style={{
                                color: "#222934",
                                fontSize: "18px",
                                lineHeight: "16px",
                                fontWeight: "normal",
                              }}
                            >
                              Recurrence
                            </p>
                            <Grid
                              sx={{
                                display: "flex",
                                flexFlow: "column",
                                justifyContent: "space-between",
                                marginTop: "11px",
                              }}
                            >
                              <Box>
                                <p
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    fontWeight: "normal",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Execute step (times)
                                </p>{" "}
                                {step.Recurrences == "" ? (
                                  <p
                                    style={{
                                      color: "#222934",
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    No Data Found
                                  </p>
                                ) : (
                                  <LabelComponent
                                    label={
                                      step.Recurrences > 1
                                        ? `${step.Recurrences} times`
                                        : `${step.Recurrences} time`
                                    }
                                    state="primary"
                                    marginRight="4px"
                                    marginBottom="4px"
                                  />
                                )}
                              </Box>
                              <Box>
                                <p
                                  style={{
                                    color: "#9CA1A6",
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    fontWeight: "normal",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Every
                                </p>
                                {step.RecurrenceInterval == "" ? (
                                  <p
                                    style={{
                                      color: "#222934",
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    No Data Found
                                  </p>
                                ) : (
                                  <LabelComponent
                                    label={
                                      step.RecurrenceInterval > 1
                                        ? `${step.RecurrenceInterval} minutes`
                                        : `${step.RecurrenceInterval} minute`
                                    }
                                    state="primary"
                                  />
                                )}
                              </Box>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              width: "10%",
                            }}
                          >
                            <p
                              style={{
                                color: "#222934",
                                fontSize: "18px",
                                lineHeight: "16px",
                                fontWeight: "normal",
                              }}
                            >
                              Step delay
                            </p>

                            <p
                              style={{
                                color: "#9CA1A6",
                                fontSize: "12px",
                                lineHeight: "14px",
                                fontWeight: "normal",
                                marginBottom: "10px",
                                marginTop: "11px",
                              }}
                            >
                              Time until next step (min)
                            </p>
                            {step.Delay == "" ? (
                              <p
                                style={{
                                  color: "#222934",
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  fontWeight: "normal",
                                }}
                              >
                                No Data Found
                              </p>
                            ) : (
                              <LabelComponent
                                label={
                                  step.Delay > 1 ? `${step.Delay} minutes` : `${step.Delay} minute`
                                }
                                state="primary"
                              />
                            )}
                          </Box>
                        </Box>
                        {index != programStepsData.length - 1 && (
                          <Divider style={{ padding: "24px 0px" }} />
                        )}
                      </StepContent>
                    </Step>
                  );
                })
              ) : (
                <p
                  style={{
                    color: "#222934",
                    fontSize: "18px",
                    lineHeight: "16px",
                    fontWeight: "normal",
                  }}
                >
                  No Steps found for this program.
                </p>
              )}
            </Stepper>
          </TableCell>
        </TableRow>
      );
    },
    sortOrder: {
      name: "AlertProgramName",
      direction: "asc",
    },
  };

  const useStyles = makeStyles((theme) => ({
    toggle: {
      "& .Mui-checked": {
        color: "#fff",
      },
      "& .MuiSwitch-track": {
        opacity: 1,
        background: "#17455e 0% 0% no-repeat padding-box !important",
      },
    },
    uncheckedToogle: {
      "& .MuiSwitch-track": {
        opacity: 1,
        background: "#C0C3C7 0% 0% no-repeat padding-box !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      {" "}
      <TableComponent
        zebraStyle={false}
        tableLook={tableLook}
        customOptions={customOptions}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "170px",
              }}
            >
              <b>{tempPrograms.length} alert programs</b>
            </p>

            <Box sx={{ marginRight: "16px" }}>
              <AlertProgramFilter
                datefilter={true}
                locations={allLocations}
                sensorTypes={sensorTypes}
                equipments={equipmentTypes}
                handleSelectUsers={handleSelectUsers}
                handleSelectUserGroup={handleSelectUserGroup}
                handleSelectLocation={handleSelectLocation}
                handleAlarmSelect={handleAlarmSelect}
                selectedUsers={selectedUsers}
                selectedUserGroups={selectedUserGroups}
                selectedAlarm={selectedAlarm}
                selectedLocations={selectedLocations}
                equipmentFilterCount={equipmentFilterCount}
                sensorTypeFilterCount={sensorTypeFilterCount}
                locationFilterCount={locationFilterCount}
                alarmFilterCount={alarmFilterCount}
                statusFilterCount={statusFilterCount}
                users={users.map((user) => {
                  return {
                    value: user.UserID,
                    label: user.UserName,
                  };
                })}
                userGroups={userGroups}
                clearFilter={clearFilter}
                handleStatusList={handleStatusList}
                selectedStatus={selectedStatus}
                sensorLimitTemplates={sensorLimitTemplates}
              />
            </Box>
          </Box>
        }
        columns={columns}
        customToolbar={
          allowedEdit && (
            <TableHeaderAddButton
              onClick={() => toggleDrawer(true)}
              label="Alert Program"
              width="176px"
            />
          )
        }
        searchText={searchText}
        data={tempPrograms?.map((program) => {
          const programStepsData = filterObjectByValue(
            tempProgramSteps,
            "ProgramID",
            program.ProgramID
          );

          return [
            program.ProgramID,
            program.Name,
            <LabelComponent
              label={program.Type}
              state={
                program.Type == "Alarm"
                  ? "error"
                  : program.Type == "Warning"
                  ? "warning"
                  : "primary"
              }
              marginBottom="16px"
              marginTop="16px"
            />,
            <LabelComponent
              label={`${
                programStepsData.length > 1
                  ? String(programStepsData.length).concat(` steps`)
                  : String(programStepsData.length).concat(` step`)
              } `}
              state={"primary"}
              marginBottom="16px"
              marginTop="16px"
            />,
            <LabelComponent
              label={`${
                program.Recurrences == "Indefinitely"
                  ? program.Recurrences
                  : program.Recurrences > 1
                  ? String(program.Recurrences).concat(` times`)
                  : String(program.Recurrences).concat(` time`)
              } `}
              state={"primary"}
              marginBottom="16px"
              marginTop="16px"
            />,
            allowedEdit && (
              <ToolTipComponent
                title="Edit program"
                placement="bottom-end"
                Component={
                  <Box
                    sx={{
                      width: "fit-content",
                      position: "absolute",
                      right: "40px",
                    }}
                  >
                    <EditPorgram
                      program={program}
                      handleEdit={handleEdit}
                    />
                  </Box>
                }
              />
            ),
          ];
        })}
        tableBodyHeight="calc(100vh - 253px)"
      />
    </Box>
  );
};

export default AlertProgramTable;
