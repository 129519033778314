import React, { useState, useEffect } from "react";

//COMPONENTS IMPORT
import { Box, Button } from "@mui/material";
import { useEditCustomerMutation } from "../../../Store/Slices/customersSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFindUsersQuery, useEditUserMutation } from "../../../Store/Slices/usersSlice";
import {
  useFindLocationsQuery,
  useEditLocationMutation,
} from "../../../Store/Slices/locationsSlice";
import {
  useFindEquipmentQuery,
  useEditEquipmentMutation,
} from "../../../Store/Slices/equipmentSlice";
import { useFindSensorsQuery, useEditSensorMutation } from "../../../Store/Slices/sensorSlice";
import {
  useFindSubstationsQuery,
  useEditSubstationMutation,
} from "../../../Store/Slices/substationSlice";
import { useSelector } from "react-redux";
import { filterObjectByValue } from "../../../Utils/Tools";
import Toast from "@Components/BaseComponents/Toast";

const EditCustomers = (props) => {
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [substations, setSubstations] = useState([]);
  let { CustomerID, IsActive } = props.customer;
  let { customer, handleEdit, tableLook } = props;
  const auth = useSelector((state) => state.auth);

  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: usersSuccess,
    isError: userIsError,
    error: userError,
  } = useFindUsersQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentQuery();

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
  } = useFindSensorsQuery();

  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
  } = useFindSubstationsQuery();

  const [editLocation, { isSuccess: editLocationSucces, error: editLocationError }] =
    useEditLocationMutation();

  const [editUser, { isSuccess: editUserSuccess }] = useEditUserMutation();

  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [editSensor, { isSuccess: sensorEditSuccess }] = useEditSensorMutation();

  const [editSubstation, { isSuccess: substationEditSuccess }] = useEditSubstationMutation();

  const loadUsers = () => {
    if (usersSuccess) setUsers(usersData);
    if (userIsError) console.log(userError);
  };

  const loadLocations = () => {
    if (locationsSuccess) setLocations(locationsData);
    if (locationsIsError) console.log(locationsError);
  };

  const loadEquipment = () => {
    if (equipmentSuccess)
      setEquipments(
        equipmentData.map((c) => {
          return {
            value: c.EquipmentID,
            label: c.EquipmentName,
            LocationID: c.LocationID,
          };
        })
      );
    if (equipmentIsError) console.log(equipmentError);
  };

  const loadSensors = () => {
    if (sensorsSuccess)
      setSensors(
        sensorsData.map((u) => {
          return {
            value: u.SensorID,
            label: u.SensorLabel,
            SensorTypeID: u.SensorTypeID,
            CustomerID: u.CustomerID,
          };
        })
      );
    if (sensorIsError) console.log(sensorError);
  };

  const loadSubstations = () => {
    if (substationsSuccess) setSubstations(substationsData);
    if (isSubstationError) console.log(substationError);
  };

  useEffect(() => {
    loadUsers();
  }, [usersData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData]);

  useEffect(() => {
    loadSensors();
  }, [sensorsData]);

  useEffect(() => {
    loadSubstations();
  }, [substationsData]);

  console.log("🚀 ~ file: EditCustomers.js ~ line 12 ~ EditCustomers ~ tableLook", tableLook);
  const [editCustomer, { error, isSuccess: customerEditSuccess }] = useEditCustomerMutation();

  const handleStatus = async (id, active) => {
    let data = { IsActive: active };
    console.log("🚀 ~ file: EditCustomers.js ~ line 24 ~ handleStatus ~ data", data);
    try {
      let res = await editCustomer({ id, data });
      console.log("🚀 ~ file: CustomersSetup.js ~ line 76 ~ handleUpdate ~ res", res);
    } catch (err) {
      console.log("ERROR:", err);
      console.log("ERROR:", error);
    }
  };

  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "10px",
          },
        },
      },
    },
  });

  const handleDelete = async (customer) => {
    const data = {
      IsActive: false,
    };
    const id = customer.CustomerID;
    let res = await editCustomer({ id, data });

    const customerUsers = filterObjectByValue(users, "CustomerID", customer.CustomerID);
    const customerLocations = filterObjectByValue(locations, "CustomerID", customer.CustomerID);
    const customerEquipments = [];
    equipments.map((equipment) => {
      customerLocations.map((location) => {
        if (location.LocationID === equipment.LocationID) {
          customerEquipments.push(equipment);
        }
      });
    });

    const customerSensors = filterObjectByValue(sensors, "CustomerID", customer.CustomerID);
    const customerSubstations = [];
    substations.map((substation) => {
      customerLocations.map((location) => {
        if (location.LocationID === substation.LocationID) {
          customerSubstations.push(substation);
        }
      });
    });

    for (let i = 0; i < customerUsers.length; i++) {
      const id = customerUsers[i].UserID;
      let res = await editUser({ id, data });
    }
    for (let i = 0; i < customerLocations.length; i++) {
      const id = customerLocations[i].LocationID;
      let res = await editLocation({ id, data });
    }

    for (let i = 0; i < customerEquipments.length; i++) {
      const id = customerEquipments[i].EquipmentID;
      let res = await editEquipment({ id, data });
    }
    for (let i = 0; i < customerSensors.length; i++) {
      const id = customerSensors[i].value;
      let res = await editSensor({ id, data });
    }

    for (let i = 0; i < customerSubstations.length; i++) {
      const id = customerSubstations[i].SubstationID;
      let res = await editSubstation({ id, substation: data });
    }
  };

  return (
    <Box style={{ display: "flex" }}>
      {customerEditSuccess && (
        <Toast
          message="Customer Deleted successfully"
          severity="success"
        />
      )}
      <>
        <ThemeProvider theme={theme}>
          {/* <Button variant="text" sx={{ padding: '0px' }} onClick={(e) => handleStatus(CustomerID, !IsActive)}>{IsActive ? "Active" : "Inactive"}</Button> */}
          <Button
            variant="text"
            sx={{ padding: "0px" }}
            onClick={() => handleEdit(customer)}
          >
            Edit
          </Button>
          <Button
            variant="text"
            sx={{ padding: "0px" }}
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this Customer?")) {
                handleDelete(customer);
              }
            }}
          >
            Delete
          </Button>
        </ThemeProvider>
      </>
    </Box>
  );
};

export default EditCustomers;
