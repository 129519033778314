import { apiSlice } from "../API";
import { createEntityAdapter } from "@reduxjs/toolkit";

const UserAdapter = createEntityAdapter();

const initialState = UserAdapter.getInitialState();

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUsers: builder.query({
      query: () => "/user",
      providesTags: ["User"],
      transformResponse: (response, meta, arg) => {
        return response.success.data;
      },
    }),
    getUser: builder.query({
      query: (id) => `/user/${id}`,
      providesTags: ["User"],
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    editUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["User", "UserGroupUsers"],
    }),
    getUserWithGroups: builder.query({
      query: () => ({
        url: "/user/groups",
      }),
    }),
  }),
});

export const {
  useFindUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useGetUserWithGroupsQuery,
} = usersSlice;
