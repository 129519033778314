import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LoadingSpinner from "@Components/LoadingSpinner";
import { Grid } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import TextFieldComponent from "../BaseComponents/TextField";
import DropDownComponent from "../BaseComponents/DropDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import { filterObjectByValue } from "../../Utils/Tools";
import RadioGroupComponent from "../BaseComponents/RadioGroup";
import RadioComponent from "../BaseComponents/Radio";
import { useLazyGetSensorConfigQuery } from "../../Store/Slices/sensorSlice";
import ToolTipComponent from "../BaseComponents/Tooltip";
import FormControlLabelComponent from "../BaseComponents/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function SensorDrawer({
  sensorEdit,
  customerDrawer,
  customToggleDrawer,
  customToggleSensorDeleteDrawer,
  handleDelete,
  sensor,
  handleLimitChange,
  setSensor,
  sensorUpdated,
  setSensorUpdated,
  sensorTypes,
  substations,
  equipments,
  deleteDrawer,
  sensorLimitTemplates,
  selectedSensorLimitTemplate,
  setSelectedSensorLimitTemplate,
  handleClick,
  setSubstationData,
  sensorButtonValidation,
  clearSensor,
  confirmationDrawer,
  discardSettingsDrawer,
  tempSensor,
  substationcheck,
  sensorToggleDrawer,
  allChangesLoading,
  equipmentDrawer,
  tempEquipmentID,
  equipment,
}) {
  let sensorJSON;

  if (sensorEdit && sensor?.SchemaJSON) {
    try {
      sensorJSON = JSON.parse(sensor.SchemaJSON);
    } catch (e) {
      console.log("Invalid JSON:", e);
      sensorJSON = sensor.SchemaJSON;
    }
  }
  const theme = useTheme();
  const [selectSensorType, setSelectSensorType] = useState();
  const [port, selectPort] = useState();
  const [selectedSensorType, setSelectedSensorType] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const maxLength = 36;
  const [customError, setCustomError] = useState(false);
  const [getSubstationData, { isSuccess: subDataSuccess }] = useLazyGetSensorConfigQuery();
  const [isFormValid, setIsFormValid] = useState(true);
  function order(array) {
    if (Array.isArray(array)) {
      return array.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      console.log(array);
    }
  }
  const [ports, setPorts] = useState([1, 2, 3, 4]);
  const [totalPorts, setTotalPorts] = useState([1, 2, 3, 4]);
  const updateMeasurement = () => {
    const selectedSubstation =
      substations.find((el) => {
        return el.SerialNumber === sensor.SubstationSerial;
      }) || "";
    setSensor({ ...sensor, MeasurementInterval: selectedSubstation.MeasurementInterval });
  };
  useEffect(() => {
    selectPort(1);
  }, [selectSensorType]);
  useEffect(() => {
    updateMeasurement();
  }, [sensor?.SubstationSerial]);

  useEffect(() => {
    fetchSubstationsConfigs(sensor.SubstationSerial);
  }, [sensor.SubstationSerial]);

  const fetchSubstationsConfigs = async (e) => {
    if (e !== "") {
      await getSubstationData(e)
        .unwrap()
        .then((req) => {
          handlePortFilter(req.success.data);
          setSubstationData(req.success.data);
        })
        .catch((err) => {
          console.log("🚀 ~ file: index.js ~ line 406 ~ fetchSubstationsConfigs ~ err", err);
        });
    } else {
      return;
    }
  };

  const handlePortFilter = async (configData) => {
    let defaultPorts = [1, 2, 3, 4];
    if (configData?.data !== null && configData?.data !== undefined) {
      const substationPorts = Object.keys(configData.data).filter((val) => {
        if (val.substring(1, 2) != "" && val.substring(1, 2) != undefined) {
          return val.substring(1, 2);
        }
      });

      setPorts(substationPorts);
    } else {
      setPorts(defaultPorts);
    }
  };

  const customLimits = [
    {
      value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
      label: "Custom",
    },
  ];

  const handleUpdate = (e) => {
    let { name, value } = e.target;
    setSelectedSensorLimitTemplate({
      LimitsJSON: {
        ...selectedSensorLimitTemplate?.LimitsJSON,
        [name]: value,
      },
    });
  };

  const loadSensor = () => {
    setSensor(tempSensor);
  };

  const handleDiscard = (e) => {
    setSensorUpdated(false);
    confirmationDrawer(false);
    sensorToggleDrawer(false);
  };

  const handleIncrementByIcon = (e) => {
    let { name, value } = e;
    setSelectedSensorLimitTemplate({
      LimitsJSON: {
        ...selectedSensorLimitTemplate?.LimitsJSON,
        [name]: ++value,
      },
    });
  };

  const handleDecrementByIcon = (e) => {
    let { name, value } = e;
    setSelectedSensorLimitTemplate({
      LimitsJSON: {
        ...selectedSensorLimitTemplate?.LimitsJSON,
        [name]: --value,
      },
    });
  };
  const checkLowHighLimits = () => {
    const { LimitsJSON } = selectedSensorLimitTemplate;
    if (!LimitsJSON?.InputState) {
      const {
        LowLimitWarning,
        HighLimitWarning,
        LowLimitAlarm,
        HighLimitAlarm,
        LowDelayAlarm,
        LowDelayWarning,
        HighDelayAlarm,
        HighDelayWarning,
      } = LimitsJSON || {};

      if ((LowLimitWarning && !LowDelayWarning) || (HighLimitWarning && !HighDelayWarning)) {
        return true;
      }
    } else {
      if (!LimitsJSON?.InputState || !LimitsJSON?.Delay) {
        return true;
      }
    }
    return false;
  };
  const validateCustomLimits = () => {
    const errors = {};

    const alarmLow = parseFloat(selectedSensorLimitTemplate?.LimitsJSON?.LowLimitAlarm || "");
    const alarmHigh = parseFloat(selectedSensorLimitTemplate?.LimitsJSON?.HighLimitAlarm || "");
    const warningLow = parseFloat(selectedSensorLimitTemplate?.LimitsJSON?.LowLimitWarning || "");
    const warningHigh = parseFloat(selectedSensorLimitTemplate?.LimitsJSON?.HighLimitWarning || "");
    const lowDelayAlarm = parseFloat(selectedSensorLimitTemplate?.LimitsJSON?.LowDelayAlarm || "");
    const lowDelayWarning = parseFloat(
      selectedSensorLimitTemplate?.LimitsJSON?.LowDelayWarning || ""
    );
    const highDelayAlarm = parseFloat(
      selectedSensorLimitTemplate?.LimitsJSON?.HighDelayAlarm || ""
    );
    const highDelayWarning = parseFloat(
      selectedSensorLimitTemplate?.LimitsJSON?.HighDelayWarning || ""
    );

    if (!isNaN(alarmLow) && !isNaN(alarmHigh) && alarmLow >= alarmHigh) {
      errors.AlarmLow = "Alarm Low limit cannot be greater than Alarm High limit.";
    }

    if (!isNaN(warningLow) && !isNaN(warningHigh)) {
      if (warningLow >= warningHigh) {
        errors.WarningLow =
          "Warning Low limit cannot be greater than or equal to Warning High limit.";
      }

      if (!isNaN(alarmLow) && warningLow <= alarmLow) {
        errors.WarningLow = "Warning Low limit cannot be lower than or equal to Alarm Low limit.";
      }

      if (!isNaN(alarmHigh) && warningHigh >= alarmHigh) {
        errors.WarningHigh =
          "Warning High limit cannot be greater than or equal to Alarm High limit.";
      }
    }

    if (!isNaN(alarmLow) && !isNaN(warningLow) && alarmLow >= warningLow) {
      errors.AlarmLow = "Alarm Low limit cannot be greater than or equal to Warning Low limit.";
    }

    if (!isNaN(alarmHigh) && !isNaN(warningHigh) && alarmHigh <= warningHigh) {
      errors.AlarmHigh = "Alarm High limit cannot be lower than or equal to Warning High limit.";
    }
    // Check LowDelayAlarm
    if (isNaN(alarmLow) && !isNaN(lowDelayAlarm)) {
      errors.LowDelayAlarm = "Low Delay field cannot be populated if limit is empty.";
    }

    // Check HighDelayAlarm
    if (isNaN(alarmHigh) && !isNaN(highDelayAlarm)) {
      errors.HighDelayAlarm = "High Delay field cannot be populated if limit is empty.";
    }

    // Check LowDelayWarning
    if (isNaN(warningLow) && !isNaN(lowDelayWarning)) {
      errors.LowDelayWarning = "Low Delay field cannot be populated if limit is empty.";
    }

    // Check HighDelayWarning
    if (isNaN(warningHigh) && !isNaN(highDelayWarning)) {
      errors.HighDelayWarning = "High Delay field cannot be populated if limit is empty.";
    }

    setValidationErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  useEffect(() => {
    validateCustomLimits();
  }, [
    selectedSensorLimitTemplate?.LimitsJSON?.LowLimitAlarm,
    selectedSensorLimitTemplate?.LimitsJSON?.HighLimitAlarm,
    selectedSensorLimitTemplate?.LimitsJSON?.LowLimitWarning,
    selectedSensorLimitTemplate?.LimitsJSON?.HighLimitWarning,
    selectedSensorLimitTemplate?.LimitsJSON?.HighDelayAlarm,
    selectedSensorLimitTemplate?.LimitsJSON?.LowDelayAlarm,
    selectedSensorLimitTemplate?.LimitsJSON?.HighDelayWarning,
    selectedSensorLimitTemplate?.LimitsJSON?.LowDelayWarning,
  ]);
  return (
    <>
      <ResourceDrawerComponent
        drawer={customerDrawer}
        toggleDrawer={customToggleDrawer}
        deactivateToggleDrawer={customToggleSensorDeleteDrawer}
        title={
          sensorEdit ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fa-solid fa-chevron-left"
                style={{
                  marginRight: "15px",
                  width: "20px",
                  height: "32px",
                  color: "#9CA1A6",
                  fontSize: "32px",
                  cursor: "pointer",
                }}
                onClick={() => customToggleDrawer(false)}
              ></i>
              Edit sensor
            </div>
          ) : (
            "Add sensor"
          )
        }
        submitButtonTitle={sensorEdit ? "UPDATE SENSOR" : "ADD SENSOR"}
        handleSubmit={handleClick}
        displayFooter={true}
        edit={sensorEdit}
        closeToast={clearSensor}
        buttonValidation={
          sensorButtonValidation ||
          (sensorEdit && !sensorUpdated) ||
          checkLowHighLimits() ||
          !isFormValid
        }
        deactivateButtonTitle={sensor.IsActive ? "Archive" : "Restore"}
        deleteDrawer={deleteDrawer}
        loadItem={loadSensor}
        item={sensor}
        setUpdated={setSensorUpdated}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
        handleDelete={handleDelete}
        deactivateButtonColor={sensor.IsActive ? "error" : "success"}
        deleteTitle={sensor.IsActive ? "Archive sensor" : "Restore sensor"}
        heading={`Are you sure you want to ${sensor.IsActive ? "Archive" : "Restore"} the ${
          sensor.SensorLabel
        }${" "}
        Sensor?`}
        allChangesLoading={allChangesLoading}
      >
        {allChangesLoading ? (
          <LoadingSpinner />
        ) : (
          <Box sx={{ padding: "24px" }}>
            <Box>
              <p
                style={{
                  color: "#222934",
                  fontSize: "18px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                Sensor
              </p>
              <TextFieldComponent
                fullWidth={true}
                padding={true}
                Label="Sensor name"
                placeholder="Enter sensor name"
                name="Sensorname"
                onChange={(e) => {
                  if (e.target.value.length >= maxLength) {
                    setCustomError(`Sensor name should not exceed ${maxLength} characters.`);
                  }
                  if (customError && e.target.value.length < maxLength) {
                    setCustomError(false);
                  }
                  return [
                    setSensor({
                      ...sensor,
                      SensorLabel: e.target.value,
                    }),
                    setSensorUpdated(true),
                  ];
                }}
                value={sensor.SensorLabel || ""}
                maxLength={maxLength}
                customError={customError}
              />
              <Grid
                item
                xs={12}
                columns={2}
                spacing={10}
                sx={{
                  paddingTop: "24px",
                }}
              >
                <Label
                  sx={{
                    color: "#9CA1A6",
                  }}
                >
                  Sensor signal type
                </Label>

                <RadioGroupComponent
                  row
                  value={sensor.SensorTypeLabel || ""}
                  name="controlled-radio-buttons-group"
                  callBack={(e) => [
                    setSelectSensorType(e),
                    setSensor({
                      ...sensor,
                      SensorTypeLabel: e,
                      SensorTypeID: "",
                      LocationID: "",
                      EquipmentID: "",
                      SensorLimitTemplateID: "",
                      Port: "",
                      SubstationSerial: substationcheck ? sensor.SubstationSerial : "",
                      SchemaJSON: "",
                    }),
                  ]}
                  FormControlLabels={
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        width: "348px",
                      }}
                    >
                      <FormControlLabel
                        value={"Analog"}
                        control={<RadioComponent disabled={sensor.SensorLabel === ""} />}
                        label="Analog"
                      />
                      <FormControlLabel
                        value={"Digital"}
                        control={<RadioComponent disabled={sensor.SensorLabel === ""} />}
                        label="Digital"
                      />
                    </div>
                  }
                />
              </Grid>
              <DropDownComponent
                padding={true}
                label="Sensor type"
                isDisabled={sensor.SensorTypeLabel === ""}
                options={
                  sensorTypes &&
                  order(
                    filterObjectByValue(sensorTypes, "sensorType", sensor.SensorTypeLabel)?.map(
                      (e) => {
                        return e;
                      }
                    )
                  )
                }
                handleChange={(e) => [
                  setSelectedSensorType(e),
                  !substationcheck
                    ? setSensor({
                        ...sensor,
                        SensorTypeID: e.value,
                        LocationID: "",
                        EquipmentID: "",
                        SensorLimitTemplateID: "",
                        Port: "",
                        SubstationSerial: "",
                        SchemaJSON: "",
                      })
                    : setSensor({
                        ...sensor,
                        SensorTypeID: e.value,
                        LocationID: "",
                        EquipmentID: "",
                        SensorLimitTemplateID: "",
                        SchemaJSON: "",
                      }),

                  setSensorUpdated(true),
                ]}
                value={sensorTypes?.find((el) => el.value === sensor.SensorTypeID) || ""}
                placeholder="Select a sensor type"
              />
            </Box>
            {sensorEdit && sensorJSON?.d && sensorJSON?.s && (
              <>
                <Box
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#222934",
                      fontSize: "18px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      marginRight: "10px",
                    }}
                  >
                    Evaluation parameters
                  </p>
                  <ToolTipComponent
                    placement="bottom-start"
                    title={"Managed by sensor type"}
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                        }}
                      >
                        <i
                          class="fa-regular fa-circle-info"
                          style={{ color: "#9CA1A6" }}
                        ></i>
                      </Box>
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <Box width={248}>
                    <TextFieldComponent
                      fullWidth={true}
                      sx={{ width: "248px" }}
                      padding={false}
                      disabled={!sensorEdit}
                      Label="Evaluation formula"
                      placeholder="Entered value"
                      name="Evaluationformula"
                      value={sensorJSON.s || ""}
                    />
                  </Box>
                  <Box width={248}>
                    <TextFieldComponent
                      fullWidth={true}
                      padding={false}
                      disabled={!sensorEdit}
                      Label="Evaluation formula parameters"
                      placeholder="Entered value"
                      name="Evaluationformulaparameters"
                      value={sensorJSON.d || ""}
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box sx={{ paddingTop: "24px" }}>
              <p
                style={{
                  color: "#222934",
                  fontSize: "18px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                Substation
              </p>
              <DropDownComponent
                padding={true}
                isDisabled={sensor.SensorTypeID === "" || substationcheck}
                label="Substation"
                options={order(
                  substations?.filter(val => !val.IsArchive)?.map((e) => {
                    return e;
                  })
                )}
                handleChange={(e) => [
                  fetchSubstationsConfigs(e.SerialNumber),
                  sensorEdit
                    ? setSensor({
                        ...sensor,
                        SubstationSerial: e.SerialNumber,
                        MeasurementLimit: e.MeasurementInterval,
                      })
                    : setSensor({
                        ...sensor,
                        SubstationSerial: e.SerialNumber,
                        LocationID: e.LocationID,
                        SensorLimitTemplateID: "",
                        Port: "",
                        SchemaJSON: "",
                        MeasurementLimit: e.MeasurementInterval,
                      }),
                  setSensorUpdated(true),
                ]}
                value={
                  substations.find((el) => {
                    return el.SerialNumber === sensor.SubstationSerial;
                  }) || ""
                }
                placeholder="Select substation"
              />
              <Grid
                container
                sx={{
                  paddingTop: "5px",
                  justifyContent: "space-between",
                  width: "100%",
                  display: "flex",
                  maxWidth: "100% !important",
                  flexDirection: "column",
                }}
              ></Grid>
              {sensor.SubstationSerial && (
                <Label
                  sx={{
                    color: "#222934",
                  }}
                >
                  Measurement Interval -{" "}
                  {sensor.MeasurementLimit
                    ? sensor.MeasurementLimit
                    : sensor.MeasurementInterval
                    ? sensor.MeasurementInterval
                    : "N/A"}{" "}
                  seconds
                </Label>
              )}
              <Grid
                container
                sx={{
                  paddingTop: "24px",
                  justifyContent: "space-between",
                  width: "100%",
                  display: "flex",
                  maxWidth: "100% !important",
                  flexDirection: "column",
                }}
              >
                <Label
                  sx={{
                    color: "#9CA1A6",
                  }}
                >
                  Substation port
                </Label>

                <RadioGroupComponent
                  row
                  value={sensor?.SubstationSerial ? sensor.Port : ""}
                  name="row-radio-buttons-group"
                  callBack={(e) => {
                    selectPort(e);
                    sensorEdit
                      ? setSensor({
                          ...sensor,
                          Port: e,
                        })
                      : setSensor({
                          ...sensor,
                          Port: e,
                          EquipmentID: equipmentDrawer ? equipment.value : "",
                          LocationID: "",
                          SensorLimitTemplateID: "",
                          SchemaJSON: "",
                        });
                    setSensorUpdated(true);
                  }}
                  FormControlLabels={
                    <FormControlLabelComponent
                      width="494px"
                      items={totalPorts}
                      control={(item) => (
                        <RadioComponent
                          disabled={
                            sensor.SubstationSerial === "" ||
                            (substationcheck && sensor.SensorTypeID === "") ||
                            ports.includes(
                              sensor.SensorTypeLabel === "Analog" ? `a${item}` : `d${item}`
                            )
                          }
                        />
                      )}
                      label={(item) =>
                        `Port ${sensor.SensorTypeLabel === "Analog" ? "A" : "D"}${item}`
                      }
                      type="Radio"
                    />
                  }
                />
              </Grid>
            </Box>
            <Box sx={{ paddingTop: "24px" }}>
              <p
                style={{
                  color: "#222934",
                  fontSize: "18px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                Equipment
              </p>
              <DropDownComponent
                padding={true}
                isDisabled={sensor.Port === "" || equipmentDrawer}
                label="Equipment"
                options={order(equipments?.map((e) => e))}
                handleChange={(e) => {
                  const updatedSensor = sensorEdit
                    ? { ...sensor, EquipmentID: e.value }
                    : {
                        ...sensor,
                        EquipmentID: e.value,
                        LocationID: "",
                        SensorLimitTemplateID: "",
                        SchemaJSON: "",
                      };

                  setSensor(updatedSensor);
                  setSensorUpdated(true);
                  console.log("Selected Equipment:", e);
                }}
                value={
                  (equipmentDrawer
                    ? equipment.EquipmentID? equipments.find((el) => equipment.EquipmentID === el.value)  : sensor.EquipmentID
                      ? equipments.find((el) => sensor.EquipmentID === el.value)
                      : equipments.find((el) => sensor?.EquipmentSensor?.EquipmentID === el.value)
                    : equipments.find((el) => el.value === sensor?.EquipmentID)) || ""
                }
                placeholder="Select equipment"
              />
            </Box>
            <Box sx={{ paddingTop: "24px" }}>
              <p
                style={{
                  color: "#222934",
                  fontSize: "24px",
                  lineHeight: "16px",
                  fontWeight: 500,
                }}
              >
                Alarm Parameters
              </p>
              <DropDownComponent
                padding={true}
                isDisabled={sensor.EquipmentID === ""}
                menuPlacement={sensor.SensorLimitTemplateID === "" ? "top" : "bottom"}
                label="Sensor limit template"
                options={order(
                  filterObjectByValue(sensorLimitTemplates, "sensorTypeValue", sensor.SensorTypeID)
                    ?.map((e) => {
                      return e;
                    })
                    .concat(customLimits)
                )}
                handleChange={(e) => [
                  handleLimitChange(
                    e.value,
                    null,
                    sensorEdit,
                    sensor,
                    sensorLimitTemplates,
                    setSelectedSensorLimitTemplate
                  ),
                  setSensor({
                    ...sensor,
                    SensorLimitTemplateID: e.value,
                  }),
                  setSensorUpdated(true),
                ]}
                value={
                  sensorLimitTemplates.find((el) => el.value === sensor.SensorLimitTemplateID) || ""
                }
                placeholder="Select a sensor limit template"
              />
              {sensor.SensorLimitTemplateID !== "" && (
                <>
                  {sensor.SensorTypeLabel == "Analog" ? (
                    <>
                      <Box sx={{ paddingTop: "24px" }}>
                        <p
                          style={{
                            color: "#222934",
                            fontSize: "18px",
                            lineHeight: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Warning parameters
                        </p>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                          }}
                        >
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              sx={{ width: "248px" }}
                              padding={false}
                              Label="Low limit (unit)"
                              placeholder="value"
                              name="LowLimitWarning"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.LowLimitWarning || ""}
                              customError={validationErrors.WarningLow}
                              type="number"
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "LowLimitWarning",
                                            value:
                                              selectedSensorLimitTemplate?.LimitsJSON
                                                ?.LowLimitWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "LowLimitWarning",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.LowLimitWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={false}
                              Label="High limit (unit)"
                              placeholder="value"
                              name="HighLimitWarning"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={
                                selectedSensorLimitTemplate?.LimitsJSON?.HighLimitWarning || ""
                              }
                              customError={validationErrors.WarningHigh}
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "HighLimitWarning",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighLimitWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "HighLimitWarning",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighLimitWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={true}
                              Label="Low delay (minutes; 0 = no delay)"
                              placeholder="value"
                              name="LowDelayWarning"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.LowDelayWarning || ""}
                              customError={
                                selectedSensorLimitTemplate.LimitsJSON?.LowLimitWarning &&
                                !selectedSensorLimitTemplate.LimitsJSON?.LowDelayWarning
                                  ? "Low Delay field cannot be empty"
                                  : validationErrors.LowDelayWarning
                                  ? validationErrors.LowDelayWarning
                                  : undefined
                              }
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "LowDelayWarning",
                                            value:
                                              selectedSensorLimitTemplate?.LimitsJSON
                                                ?.LowDelayWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "LowDelayWarning",
                                            value:
                                              selectedSensorLimitTemplate?.LimitsJSON
                                                ?.LowDelayWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={true}
                              Label="High delay (minutes; 0 = no delay)"
                              placeholder="value"
                              name="HighDelayWarning"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              type="number"
                              value={
                                selectedSensorLimitTemplate?.LimitsJSON?.HighDelayWarning || ""
                              }
                              customError={
                                validationErrors.HighDelayWarning
                                  ? validationErrors.HighDelayWarning
                                  : selectedSensorLimitTemplate.LimitsJSON?.HighLimitWarning &&
                                    !selectedSensorLimitTemplate.LimitsJSON?.HighDelayWarning
                                  ? "High Delay field cannot be empty"
                                  : undefined
                              }
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "HighDelayWarning",
                                            value:
                                              selectedSensorLimitTemplate?.LimitsJSON
                                                ?.HighDelayWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "HighDelayWarning",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighDelayWarning,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ paddingTop: "24px" }}>
                        <p
                          style={{
                            color: "#222934",
                            fontSize: "18px",
                            lineHeight: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Alarm parameters
                        </p>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                          }}
                        >
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              sx={{ width: "248px" }}
                              padding={false}
                              Label="Low limit (unit)"
                              placeholder="value"
                              name="LowLimitAlarm"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.LowLimitAlarm || ""}
                              customError={validationErrors.AlarmLow}
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "LowLimitAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON?.LowLimitAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "LowLimitAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON?.LowLimitAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={false}
                              Label="High limit (unit)"
                              placeholder="value"
                              name="HighLimitAlarm"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.HighLimitAlarm || ""}
                              customError={validationErrors.AlarmHigh}
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "HighLimitAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighLimitAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "HighLimitAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighLimitAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={true}
                              Label="Low delay (minutes; 0 = no delay)"
                              placeholder="value"
                              name="LowDelayAlarm"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.LowDelayAlarm || ""}
                              customError={
                                selectedSensorLimitTemplate?.LimitsJSON?.LowLimitAlarm &&
                                !selectedSensorLimitTemplate.LimitsJSON?.LowDelayAlarm
                                  ? "Low Delay field cannot be empty"
                                  : validationErrors.LowDelayAlarm
                                  ? validationErrors.LowDelayAlarm
                                  : undefined
                              }
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "LowDelayAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON?.LowDelayAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "LowDelayAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON?.LowDelayAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={true}
                              Label="High delay (minutes; 0 = no delay)"
                              placeholder="value"
                              name="HighDelayAlarm"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.HighDelayAlarm || ""}
                              customError={
                                selectedSensorLimitTemplate?.LimitsJSON?.HighLimitAlarm &&
                                !selectedSensorLimitTemplate.LimitsJSON?.HighDelayAlarm
                                  ? "Low Delay field cannot be empty"
                                  : validationErrors.HighDelayAlarm
                                  ? validationErrors.HighDelayAlarm
                                  : undefined
                              }
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ display: "grid" }}>
                                      <i
                                        onClick={() =>
                                          handleIncrementByIcon({
                                            name: "HighDelayAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighDelayAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-up"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                      <i
                                        onClick={() =>
                                          handleDecrementByIcon({
                                            name: "HighDelayAlarm",
                                            value:
                                              selectedSensorLimitTemplate.LimitsJSON
                                                ?.HighDelayAlarm,
                                          })
                                        }
                                        class="fa-light fa-angle-down"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "16px",
                                          color: "#9CA1A6",
                                          fontWeight: "light",
                                        }}
                                      ></i>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {sensorEdit && (
                        <Box sx={{ paddingTop: "24px" }}>
                          <p
                            style={{
                              color: "#222934",
                              fontSize: "18px",
                              lineHeight: "16px",
                              fontWeight: 500,
                            }}
                          >
                            Calibration parameters
                          </p>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "10px",
                            }}
                          >
                            <Box width={248}>
                              <TextFieldComponent
                                fullWidth={true}
                                sx={{ width: "248px" }}
                                padding={false}
                                Label="Sensor calibration offsets"
                                placeholder="value"
                                name="SensorCalibrationOffsets"
                              />
                            </Box>
                            <Box width={248}>
                              <TextFieldComponent
                                fullWidth={true}
                                padding={false}
                                Label="Sensor calibration setpoints"
                                placeholder="value"
                                name="SensorCalibrationSetpoints"
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box width={248}>
                              <TextFieldComponent
                                fullWidth={true}
                                padding={true}
                                Label="AFE calibration offsets"
                                placeholder="value"
                                name="AFECalibrationOffsets"
                                onChange={(e) => [
                                  setSensor({
                                    ...sensor,
                                    CalibrationParameters: {
                                      pcs: e.target.value,
                                    },
                                  }),
                                  setSensorUpdated(true),
                                ]}
                              />
                            </Box>
                            <Box width={248}>
                              <TextFieldComponent
                                fullWidth={true}
                                padding={true}
                                Label="AFE calibration setpoints"
                                placeholder="value"
                                name="AFECalibrationSetpoints"
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Box sx={{ paddingTop: "24px" }}>
                        <p
                          style={{
                            color: "#222934",
                            fontSize: "18px",
                            lineHeight: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Alarm parameters
                        </p>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "10px",
                          }}
                        >
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              sx={{ width: "248px" }}
                              padding={false}
                              Label="Input state (units)"
                              placeholder="value"
                              name="InputState"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.InputState || ""}
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="text"
                            />
                          </Box>
                          <Box width={248}>
                            <TextFieldComponent
                              fullWidth={true}
                              padding={false}
                              Label="Delay (minutes; 0 = no delay)"
                              placeholder="value"
                              name="Delay"
                              disabled={
                                sensor.SensorLimitTemplateID !=
                                  "111a11b1-a111-4111-aa11-1eb1dbd1a11a" && true
                              }
                              value={selectedSensorLimitTemplate?.LimitsJSON?.Delay || ""}
                              onChange={(e) => [handleUpdate(e), setSensorUpdated(true)]}
                              type="number"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </ResourceDrawerComponent>
    </>
  );
}
