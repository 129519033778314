import StatusFilter from "./StatusFilter";

export function AlarmStateFilter(props) {
  const { selectedAlarm, handleAlarmSelect, data } = props;
  return (
    <StatusFilter
      title="Alarm state(s)"
      data={data}
      selectedStatus={selectedAlarm}
      handleOnChange={handleAlarmSelect}
    />
  );
}
