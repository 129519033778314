import React, { useState, useEffect } from "react";
import Toast from "@Components/BaseComponents/Toast";
import SplitPane from "@Components/SplitPane/SplitPane";
import Divider from "@Components/SplitPane/PaneDivider";
import SensorPane from "@Components/SplitPane/SensorPane";
import MapPane from "@Components/SplitPane/MapPane";
import EquipmentAlarmSupressDrawer from "../../Components/RightDrawer/EquipmentAlarmSupressDrawer";
import {
  useFindEquipmentSensorTypesQuery,
  useLiveEquipmentQuery,
} from "../../Store/Slices/equipmentSlice";
import {
  useSuppressSensorsMutation,
  useUnSuppressSensorsMutation,
} from "../../Store/Slices/sensorSuppressSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import { filterObjectByValue, findObjectByValue, locationsString } from "../../Utils/Tools";
import { useSelector } from "react-redux";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Box } from "@mui/material";
import { useFindMappedEquipmentsQuery } from "../../Store/Slices/mappedEquipmentSlice";
import { useLocation } from "react-router-dom";
import { useFindSensorTypeQuery } from "../../Store/Slices/sensorTypeSlice";
import moment from "moment";
import SensorSuppressDrawer from "../RightDrawer/SensorSuppressDrawer";
import { useFindSensorLimitTemplateQuery } from "../../Store/Slices/sensorLimitTemplateSlice";
import { useEditSensorMutation } from "../../Store/Slices/sensorSlice";
import EquipmentAlarmAcknowledgeDrawer from "../RightDrawer/EquipmentAlarmAcknowledgeDrawer";
import SensorAcknowledegeDrawer from "../RightDrawer/SensorAcknowledgeDrawer";
const Panel = () => {
  const navigatedLocation = useLocation();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [equipments, setEquipments] = useState([]);
  const [equipmentLive, setEquipmentLive] = useState([]);
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [locations, setLocations] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [equipment, setEquipment] = useState(navigatedLocation.state?.EquipmentID || {});
  const [sensorTypes, setSensorTypes] = useState([]);
  const [location, setLocation] = useState({});
  const [selectedLocationId, setSelectedLocationId] = useState(
    navigatedLocation.state?.LocationID || ""
  );
  const [sensorStates, setSensorStates] = useState([]);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [paneWidth, setPaneWidth] = useState(870);
  const [currentBreakpoint, setCurrentBreakpoint] = useState();
  const [allSelectedSensors, setAllSelectedSensors] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [unSuppressAction, setUnSuppressAction] = useState("");
  const [selectedSensorIds, setSelectedSensorIds] = useState([]);
  const [suppressDrawer, setSuppressDrawer] = useState(false);
  const [equipmentAlarmSuppress, setEquipmentAlarmSuppress] = useState({ Sensors: [] });
  const [equipmentAlarmSuppressDrawer, setEquipmentAlarmSuppressDrawer] = useState(false);
  const [equipmentAlarmAcknowledgeDrawer, setEquipmentAlarmAcknowledgeDrawer] = useState(false);
  const [equipmentAlarmAcknowledge, setEquipmentAlarmAcknowledge] = useState({ Sensors: [] });
  const [AcknoweldgeSensorTypes, setAcknoweldgeSensorTypes] = useState([]);
  const [acknowledgeDrawer, setAcknowledgeDrawer] = useState(false);
  const [suppressSensorTypes, setSuppressSensorTypes] = useState([]);
  const [AcknowlegedSensor, setAcknowlegedSensor] = useState([]);
  const [isBulk, setIsBulk] = useState([]);
  const [editSensor, { isSuccess: isSensorEditSuccess }] = useEditSensorMutation();

  const isAdmin = auth.isAdmin;

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const initialSensor = {
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{}",
  };

  const menuItems = {
    1: "warning",
    2: "alarm",
    3: "suppressed",
  };

  const [sensor, setSensor] = useState(initialSensor);

  const [suppressSensors, { isSuccess: suppressSensorsSuccess, isError: suppressSensorsError }] =
    useSuppressSensorsMutation();
  const [
    unSuppressSensors,
    { isSuccess: unSuppressSensorsSuccess, isError: unSuppressSensorsError },
  ] = useUnSuppressSensorsMutation();

  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const {
    data: equipmentLiveData,
    isLoading: equipmentLiveDataLoading,
    isSuccess: equipmentLiveDataSuccess,
    isError: equipmentLiveDataIsError,
    error: equipmentLiveDataError,
  } = useLiveEquipmentQuery();

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();
  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess) {
      setSensorTypes(
        sensorTypeData.success.data.map((sensorType) => {
          return {
            value: sensorType.SensorTypeID,
            label: sensorType.SensorTypeName,
            Unit: sensorType.Unit,
            configType: sensorType.ConfigType,
          };
        })
      );
      setSuppressSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    }

    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      setLocations(locationsData);
      if (!isAdmin) {
        setLocations(filterObjectByValue(locationsData, "CustomerID", auth.CustomerID));
      } else {
        if (auth.SelectedCustomer == "") {
          setLocations(locationsData);
        } else {
          const filteredLocations = filterObjectByValue(
            locationsData,
            "CustomerID",
            auth.SelectedCustomer
          );

          setLocations(filteredLocations);
        }
      }
      let locationsInfo = {};
      for (const location of locationsData) {
        locationsInfo[location.LocationID] = locationsString(location, locationsData);
      }
      setLocationsHierarchy(locationsInfo);
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const loadEquipment = () => {
    if (equipmentLoading || locationsLoading || mappedEquipmentLoading) setLoading(true);
    if (equipmentSuccess && locationsSuccess && mappedEquipmentSuccess) {
      const tempEquipments = equipmentData
        .filter((e) => e.IsActive)
        .map((val) => {
          const mappedLocation = findObjectByValue(
            mappedEquipmentData.success.data,
            "EquipmentID",
            val.EquipmentID
          );

          return {
            EquipmentID: val.EquipmentID,
            EquipmentName: val.EquipmentName,
            EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
            EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
            LocationName: val.Location?.LocationName,
            LocationID: val.LocationID,
            MappedLocationID: mappedLocation?.LocationID,
            CustomerID: val.Location?.Customer.CustomerID,
            CustomerName: val.Location?.Customer.CustomerName,
            updatedAt: val.updatedAt,
            equipmentSensorTypes: val.equipmentSensorTypes,
          };
        })
        .sort((a, b) => a.EquipmentName.localeCompare(b.EquipmentName));
      if (navigatedLocation.state?.tenantUpdatedToast || navigatedLocation.state == null) {
        if (!isAdmin) {
          setEquipment(tempEquipments[0]);

          setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
        } else {
          if (auth.SelectedCustomer == "") {
            setEquipment(tempEquipments[0]);

            setEquipments(tempEquipments);
          } else {
            const filteredLocations = filterObjectByValue(
              tempEquipments,
              "CustomerID",
              auth.SelectedCustomer
            );

            setEquipments(filteredLocations);
            setEquipment(filteredLocations[0]);
          }
        }
      } else {
        if (!isAdmin) {
          setEquipment(
            findObjectByValue(tempEquipments, "EquipmentID", navigatedLocation?.state?.EquipmentID)
          );
          setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
        } else {
          if (auth.SelectedCustomer == "") {
            setEquipment(
              findObjectByValue(
                tempEquipments,
                "EquipmentID",
                navigatedLocation?.state?.EquipmentID
              )
            );

            setEquipments(tempEquipments);
          } else {
            const filteredLocations = filterObjectByValue(
              tempEquipments,
              "CustomerID",
              auth.SelectedCustomer
            );

            setEquipments(filteredLocations);
            setEquipment(
              findObjectByValue(
                filteredLocations,
                "EquipmentID",
                navigatedLocation?.state?.EquipmentID
              ) || filteredLocations[0]
            );
          }
        }
      }
    }
    if (equipmentIsError) console.log(equipmentError);
    setLoading(false);
  };

  const loadMappedEquipments = () => {
    if (mappedEquipmentLoading) setLoading(true);
    if (mappedEquipmentSuccess) setMappedEquipments(mappedEquipmentData.success.data);
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
    setLoading(false);
  };

  const loadEquipmentLive = () => {
    let filterMenuItems = [];
    if (equipmentLoading && mappedEquipmentLoading) setLoading(true);
    if (equipmentLiveDataSuccess && mappedEquipmentSuccess) {
      const tempLiveEquipments = equipmentLiveData.success.data
        .filter((e) => !e.IsArchive)
        .map((val) => {
          let filteredSensors = val.Sensors.filter(
            (sensor) => sensor.LiveData && sensor.IsActive && sensor.LiveData.State
          ).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf());
          let sensorStates = Array.from(
            new Set(
              filteredSensors.map((sensor) =>
                sensor.LiveData.Suppressed ? 3 : sensor.LiveData.State
              )
            )
          );
          filterMenuItems = filterMenuItems.concat(sensorStates);
          const mappedLocation = findObjectByValue(
            mappedEquipmentData.success.data,
            "EquipmentID",
            val.EquipmentID
          );
          return {
            EquipmentID: val.EquipmentID,
            EquipmentName: val.EquipmentName,
            EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
            EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
            LocationName: val.Location?.LocationName,
            LocationID: val.LocationID,
            MappedLocationID: mappedLocation?.LocationID,
            CustomerID: val.Location?.Customer.CustomerID,
            CustomerName: val.Location?.Customer.CustomerName,
            updatedAt: val.updatedAt,
            Sensors: val?.Sensors,
            MenuOptions: sensorStates.map((state) => menuItems[state]),
            equipmentSensorTypes: val.equipmentSensorTypes,
          };
        });
      if (!isAdmin) {
        setEquipmentLive(filterObjectByValue(tempLiveEquipments, "CustomerID", auth.CustomerID));
      } else {
        if (auth.SelectedCustomer == "") {
          setEquipmentLive(tempLiveEquipments);
        } else {
          setEquipmentLive(
            filterObjectByValue(tempLiveEquipments, "CustomerID", auth.SelectedCustomer)
          );
        }
      }
      setSensorStates(Array.from(new Set(filterMenuItems)));
      setSelectedSensorIds(
        tempLiveEquipments.map((eq) => eq.Sensors.map((sensor) => sensor.SensorID)).flat()
      );
    }
  };

  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateLoading) setLoading(true);
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom Configurations",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
    setLoading(false);
  };

  const clearSensor = () => {
    setSensor(initialSensor);
  };

  const toggleSuppressDrawer = (action) => {
    setSuppressDrawer(action);
  };
  const toggleAcknowledgeDrawer = (action) => {
    setAcknowledgeDrawer(action);
  };
  const toggleIsBulkAcknowledgeDrawer = (action) => {
    setIsBulk(true);
    setAcknowledgeDrawer(action);
  };
  const handleAcknowledgeDrawerEdit = (action) => {
    toggleAcknowledgeDrawer(!action);
  };
  const handleAlertSuppression = (sensor, action) => {
    toggleSuppressDrawer(action);
    setSensor(sensor);
  };

  const handleSuppressDrawerEdit = (action) => {
    toggleSuppressDrawer(!action);
  };
  const handleEquipmentSupressAlarms = (equipment) => {
    setEquipmentAlarmSuppress(equipment);
    toggleAlarmSuppressDrawer(true);
  };
  const toggleAlarmSuppressDrawer = (action) => {
    setEquipmentAlarmSuppressDrawer(action);
  };
  const handleAlertAcknowledge = (sensor, action) => {
    setIsBulk(false);
    toggleAcknowledgeDrawer(action);
    setAcknowlegedSensor([sensor]);
  };
  const handleEquipmentAcknowledgeAlarms = (equipment) => {
    setEquipmentAlarmAcknowledge(equipment);
    toggleAlarmAcknowledgeDrawer(true);
  };
  const toggleAlarmAcknowledgeDrawer = (action) => {
    setEquipmentAlarmAcknowledgeDrawer(action);
  };

  useEffect(async () => {
    if (selectedAction.length > 0) {
      setLoading(true);
      let res = await suppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setSelectedAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [selectedAction]);

  useEffect(async () => {
    if (unSuppressAction.length > 0) {
      setLoading(true);
      let res = await unSuppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setUnSuppressAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [unSuppressAction]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData]);

  useEffect(() => {
    loadEquipmentLive();
  }, [equipmentLiveData, auth, mappedEquipmentData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData, auth]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData, auth, locationsData, mappedEquipmentData]);

  useEffect(() => {
    if (equipment?.MappedLocationID) {
      setSelectedLocationId(equipment.MappedLocationID);
      return;
    }
    if (equipment?.LocationID) {
      setSelectedLocationId(equipment.LocationID);
      return;
    }
    setSelectedLocationId("");
  }, [equipment]);

  useEffect(() => {
    setLocation(findObjectByValue(locations, "LocationID", selectedLocationId));
  }, [selectedLocationId, locations]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      {isSensorEditSuccess && (
        <Toast
          message="Sensor successfully updated"
          severity="success"
        />
      )}
      {equipmentLoading ||
      equipmentLiveDataLoading ||
      locationsLoading ||
      sensorTypeLoading ||
      mappedEquipmentLoading ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SpinnerContainer>
            <Loader loading={loading} />
          </SpinnerContainer>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <SplitPane
            className="split-pane-row"
            setPaneWidth={setPaneWidth}
            paneWidth={paneWidth}
            setCurrentBreakpoint={setCurrentBreakpoint}
            tab={tab}
          >
            <SensorPane
              partition="split-data"
              equipments={equipments}
              equipmentLive={equipmentLive}
              locations={locations}
              tab={tab}
              setLocation={setLocation}
              setSelectedLocationId={setSelectedLocationId}
              selectedLocationId={selectedLocationId}
              locationsHierarchy={locationsHierarchy}
              setEquipment={setEquipment}
              equipment={equipment}
              setTab={setTab}
              paneWidth={paneWidth}
              setPaneWidth={setPaneWidth}
              mappedEquipments={mappedEquipments}
              auth={auth}
              currentBreakpoint={currentBreakpoint}
              sensorTypes={sensorTypes}
              setSelectedAction={setSelectedAction}
              setUnSuppressAction={setUnSuppressAction}
              setAllSelectedSensors={setAllSelectedSensors}
              handleAlertSuppression={handleAlertSuppression}
              handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
              handleEquipmentAcknowledgeAlarms={handleEquipmentAcknowledgeAlarms}
              handleAlertAcknowledge={handleAlertAcknowledge}
              defaultCustomerID={defaultCustomerID}
            />
            <Divider className="separator-col" />
            <MapPane
              paneWidth={paneWidth}
              CustomerID={auth.CustomerID}
              LocationID={selectedLocationId}
              location={location}
              equipmentLive={equipmentLive}
              setEquipment={setEquipment}
              SelectedEquipment={equipment}
              setSelectedEquipment={setEquipment}
              setSelectedLocationId={setSelectedLocationId}
              selectedLocationId={selectedLocationId}
              mappedEquipments={mappedEquipments}
              mappedEquipmentLoading={mappedEquipmentLoading}
            />
          </SplitPane>
          <SensorSuppressDrawer
            suppressDrawer={suppressDrawer}
            toggleSuppressDrawer={toggleSuppressDrawer}
            sensor={sensor}
            sensorTypes={suppressSensorTypes}
            sensorLimitTemplates={sensorLimitTemplates}
            clearSensor={clearSensor}
            handleSuppressDrawerEdit={handleSuppressDrawerEdit}
            editSensor={true}
          />
          <EquipmentAlarmSupressDrawer
            equipmentAlarmSuppress={equipmentAlarmSuppress}
            equipmentAlarmSuppressDrawer={equipmentAlarmSuppressDrawer}
            toggleAlarmSuppressDrawer={toggleAlarmSuppressDrawer}
            sensor={sensor}
            sensorTypes={suppressSensorTypes}
            sensorLimitTemplates={sensorLimitTemplates}
            clearSensor={clearSensor}
            handleSuppressDrawerEdit={handleSuppressDrawerEdit}
          />
           <EquipmentAlarmAcknowledgeDrawer
              equipmentAlarmAcknowledge={equipmentAlarmAcknowledge}
              equipmentAlarmAcknowledgeDrawer={equipmentAlarmAcknowledgeDrawer}
              toggleAlarmAcknowledgeDrawer={toggleAlarmAcknowledgeDrawer}
              sensor={sensor}
              sensorTypes={AcknoweldgeSensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleAcknowledgeDrawerEdit={handleAcknowledgeDrawerEdit}
            />
              <SensorAcknowledegeDrawer
              suppressDrawer={acknowledgeDrawer}
              toggleAcknowledgeDrawer={toggleAcknowledgeDrawer}
              sensors={AcknowlegedSensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleAcknowledgeDrawerEdit={handleAcknowledgeDrawerEdit}
              editSensor={true}
              isBulk={false}
            />
        </Box>
      )}
    </Box>
  );
};

export default Panel;
