import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomMenu from "@Components/BaseComponents/Menu";
import ToolTipComponent from "../Tooltip";
import IconButtonComponent from "../IconButton";
import ContextMenuItem from "../ContextMenuItem";
import {
  useSuppressSensorsMutation,
  useUnSuppressSensorsMutation,
} from "../../../Store/Slices/sensorSuppressSlice";

const EquipmentContextMenu = (props) => {
  const { equipment, setActiveEquipmentID } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [suppressSensors, { isLoading: suppressSensorsLoading }] = useSuppressSensorsMutation();
  const [unSuppressSensors, { isSuccess: unSuppressSensorsSuccess }] =
    useUnSuppressSensorsMutation();

  const menuId = "equipment-context-menu";
  const isMenuOpen = Boolean(anchorEl);

  const handleEquipmentContextMenu = (event, id) => {
    setActiveEquipmentID(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSuppressSensors = async (sensorIDs) => {
    await suppressSensors({ SensorIDs: sensorIDs });
  };

  const handleUnSuppressSensors = async (sensorIDs) => {
    await unSuppressSensors({ SensorIDs: sensorIDs });
  };

  const hasSuppressedSensors = equipment?.Sensors?.some((sensor) => !sensor?.LiveData?.Suppressed);
  const hasUnsuppressedSensors = equipment?.Sensors?.some((sensor) => sensor?.LiveData?.Suppressed);
  const hasPriorSensors = equipment?.Sensors?.some((sensor) => !sensor?.LiveData?.Acknowledged && sensor?.LiveData?.State == 4);

  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: "180px",

          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        {!!equipment?.Sensors.length &&
          (hasSuppressedSensors && hasUnsuppressedSensors ? (
            <>
              <ContextMenuItem
                handleChange={() => {
                  handleUnSuppressSensors(equipment.Sensors.map((item) => item.SensorID));
                  handleMenuClose();
                }}
                className="fa-light fa-bell-on"
                Label="Unsuppress Alarms"
                margin="0px 7px 0px 0px"
              />
              <ToolTipComponent
                title="Suppressing alarms for this equipment will suppress alarms for all associated sensors"
                Component={
                  <div>
                    <ContextMenuItem
                      handleChange={() => {
                        props.handleEquipmentSupressAlarms(equipment);
                        handleMenuClose();
                      }}
                      className="fa-light fa-bell-slash"
                      Label="Suppress Alarms"
                    />
                  </div>
                }
              />
            </>
          ) : hasSuppressedSensors ? (
            <ToolTipComponent
              title="Suppressing alarms for this equipment will suppress alarms for all associated sensors"
              Component={
                <div>
                  <ContextMenuItem
                    handleChange={() => {
                      props.handleEquipmentSupressAlarms(equipment);
                      handleMenuClose();
                    }}
                    className="fa-light fa-bell-slash"
                    Label="Suppress Alarms"
                  />
                </div>
              }
            />
          ) : (
            <ContextMenuItem
              handleChange={() => {
                handleUnSuppressSensors(equipment.Sensors.map((item) => item.SensorID));
                handleMenuClose();
              }}
              className="fa-light fa-bell-on"
              Label="Unsuppress Alarms"
              margin="0px 7px 0px 0px"
            />
          ))}
          {hasPriorSensors && (
            <ToolTipComponent
              title="Suppressing alarms for this equipment will suppress alarms for all associated sensors"
              Component={
                <div>
                  <ContextMenuItem
                    handleChange={() => {
                      props.handleEquipmentAcknowledgeAlarms({... equipment, Sensors:equipment?.Sensors.filter(sensor => sensor.LiveData.State == 4 && !sensor.LiveData.Acknowledged), equipmentAllSensors: equipment.Sensors});
                      handleMenuClose();
                    }}
                    className="fa-light fa-circle-exclamation-check"
                    Label=" Acknoweldge Alarms"
                    margin="0px 7px 0px 0px"
                  />
                </div>
              }
            />
          )}
        {process.env.SHOW_AUDIT_TRAIL === "true" && (
          <ContextMenuItem
            to={`/audittrail/Equipment-${equipment.EquipmentName}`}
            className="fa-light fa-list-timeline"
            Label="Audit Trail"
            ID={{ Type: "Equipment", ID: equipment.EquipmentID }}
          />
        )}
        
      </Box>
    </CustomMenu>
  );
  return (
    <>
      <ToolTipComponent
        title="More options"
        value="More options"
        placement="bottom-end"
        Component={
          <Box
            sx={{
              width: "fit-content",
            }}
          >
            <IconButtonComponent
              onClick={(e) => handleEquipmentContextMenu(e, equipment.EquipmentID)}
              Component={
                <i
                  class="fa-solid fa-ellipsis-vertical"
                  style={{
                    color: "#9CA1A6",
                    fontSize: "20px",
                    lineHeight: "25px",
                    cursor: "pointer",
                  }}
                ></i>
              }
            />
          </Box>
        }
      />

      {renderMenu}
    </>
  );
};

export default EquipmentContextMenu;
