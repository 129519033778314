import { Box } from "@mui/material";
import DateTimePickerComponent from "../DateTimePicker";

export default function LabeledDateField(props) {
  const { date, handleDateChange, isDateFieldsEnabled, Label, Width } = props;
  return (
    <Box>
      <div>
        <p
          style={{
            textAlign: "left",
            font: "normal normal normal 12px/16px Roboto",
            color: "#9CA1A6",
            marginBottom: "10px",
          }}
        >
          {Label}
        </p>
      </div>
      <div style={{ width: Width }}>
        <DateTimePickerComponent
          label=""
          value={date}
          onChange={handleDateChange}
          disabled={isDateFieldsEnabled}
        />
      </div>
    </Box>
  );
}
