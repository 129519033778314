import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Profile from "../Profile";
import React from "react";
import { Box } from "@mui/material";
import { tabStyles } from "../../../Utils/constants";
import Security from "./Security";

export default function LabTabs(props) {
  const {
    password,
    setPassword,
    setSecurityCheck,
    user,
    setUser,
    handleChange,
    removeProfilePicture,
    sensorAlertTypes,
    onChangePhoneInput,
    openedTab,
    setOpenedTab,
    setUserUpdated,
    setSecurityButtonValidation,
  } = props;

  const handleTabChange = (event, newValue) => {
    setOpenedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={openedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box sx={{ marginX: 2 }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label="PROFILE"
                value="1"
                sx={tabStyles}
              />
              <Tab
                label="SECURITY"
                value="2"
                sx={tabStyles}
              />
            </TabList>
          </Box>
        </Box>
        <TabPanel value="1">
          <Profile
            user={user}
            setUser={setUser}
            handleChange={handleChange}
            removeProfilePicture={removeProfilePicture}
            onChangePhoneInput={onChangePhoneInput}
          />
        </TabPanel>
        <TabPanel value="2">
          <Security
            user={user}
            setUser={setUser}
            handleChange={handleChange}
            removeProfilePicture={removeProfilePicture}
            setSecurityCheck={setSecurityCheck}
            password={password}
            setPassword={setPassword}
            setUserUpdated={setUserUpdated}
            setSecurityButtonValidation={setSecurityButtonValidation}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
