import { apiSlice } from "../API";

export const sensorGroupSensorsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorGroupSensors: builder.query({
      query: () => "/sensorGroupSensor",
      providesTags: ["SensorGroupSensors"],
    }),
    addSensorGroupSensors: builder.mutation({
      query(sensorGroupSensor) {
        console.log(
          "🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice",
          sensorGroupSensor
        );
        return {
          url: "/sensorGroupSensor",
          method: "POST",
          body: sensorGroupSensor,
        };
      },
      invalidatesTags: ["SensorGroupSensors"],
    }),
    getSensorGroupSensors: builder.query({
      query: (id) => ({
        url: `/sensorGroupSensor/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["SensorGroupSensors"],
    }),
  }),
});

export const {
  useFindSensorGroupSensorsQuery,
  useAddSensorGroupSensorsMutation,
  useGetSensorGroupSensorsQuery,
} = sensorGroupSensorsSlice;
