import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBoxComponent from "../Checkbox";
import ToolTipComponent from "../Tooltip";
import Checkbox from "@mui/material/Checkbox";
import MultipleSelectCheckmarks from "../MultiSelect";
import ListItemText from "@mui/material/ListItemText";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import DatePickerComponent from "../DatePicker";
import IconButtonComponent from "../IconButton";

export default function DeviceFilter(props) {
  const { selectedAlarm, handleAlarmSelect } = props;
  const {
    handleSelectEquipmentType,
    handleSelectLocation,
    selectedType,
    handleTypeSelect,
    selectedEquipmentTypes,
    equipments,
    datefilter,
    selectedLocations,
    locations,
    fromDate,
    toDate,
    handleFromDate,
    handleToDate,
  } = props;

  const {
    equipmentFilterCount,
    sensorTypeFilterCount,
    locationFilterCount,
    alarmFilterCount,
    typeFilterCount,
    clearFilter,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            equipmentFilterCount +
              sensorTypeFilterCount +
              locationFilterCount +
              alarmFilterCount +
              typeFilterCount +
              (fromDate == null ? 0 : 1) +
              (toDate == null ? 0 : 1) >
            0
              ? "Filters (" +
                String(
                  equipmentFilterCount +
                    sensorTypeFilterCount +
                    locationFilterCount +
                    alarmFilterCount +
                    typeFilterCount +
                    (fromDate == null ? 0 : 1) +
                    (toDate == null ? 0 : 1)
                ) +
                " applied)"
              : "Filter"
          } `}
          value="Filter"
          Component={
            <Box
              sx={{
                width: "fit-content",
              }}
            >
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                aria-expanded={open ? "true" : undefined}
                sx={{ mr: 2 }}
                Component={
                  <Box
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {equipmentFilterCount +
                      sensorTypeFilterCount +
                      locationFilterCount +
                      alarmFilterCount +
                      typeFilterCount +
                      (fromDate == null ? 0 : 1) +
                      (toDate == null ? 0 : 1) >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          equipmentFilterCount +
                          sensorTypeFilterCount +
                          locationFilterCount +
                          alarmFilterCount +
                          typeFilterCount +
                          (fromDate == null ? 0 : 1) +
                          (toDate == null ? 0 : 1)
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "500px",
            overflow: "auto",
            width: "520px",
            padding: "16px",
          }}
        >
          <div>
            <div>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                }}
              >
                Device type
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      item
                      columns={2}
                      sx={{
                        gridTemplateColumns: "repeat(2, 1fr)",
                        display: "grid",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              checked={selectedType.findIndex((item) => item == "Equipment") > -1}
                              onChange={handleTypeSelect}
                              value="Equipment"
                              name="Equipment"
                              Label="Equipment"
                            />
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CheckBoxComponent
                              size="small"
                              onChange={handleTypeSelect}
                              value="Substation"
                              name="Substation"
                              checked={selectedType.findIndex((item) => item == "Substation") > -1}
                              Label="Substation"
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#222934",
                              }}
                            ></p>
                          }
                        />
                      </Box>
                    </Grid>
                  </Box>
                </FormGroup>
              </FormControl>
            </div>
            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Equipment type(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectEquipmentType}
                placeholder="Select equipment type(s)"
                personName={selectedEquipmentTypes}
                data={equipments}
              >
                {equipments.map((equipment) => (
                  <MenuItem
                    key={equipment.value}
                    value={equipment.value}
                    className="menuItem"
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <CheckBoxComponent
                      className="checkBox"
                      checked={selectedEquipmentTypes.indexOf(equipment.value) > -1}
                    />
                    <ListItemText primary={equipment.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>

            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Location(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectLocation}
                placeholder="Select location(s)"
                personName={selectedLocations}
                data={locations}
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location.value}
                    value={location.value}
                    className="menuItem"
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedLocations.indexOf(location.value) > -1}
                    />
                    <ListItemText primary={location.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>
            <div
              style={{
                marginTop: "24px",
                marginBottom: "5px",
                display: datefilter ? "flex" : "none",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <div>
                  <p
                    style={{
                      textAlign: "left",
                      font: "normal normal normal 12px/16px Roboto",
                      color: "#9CA1A6",
                      marginBottom: "4px",
                    }}
                  >
                    Updated after
                  </p>
                </div>
                <DatePickerComponent
                  label="From Date"
                  value={fromDate}
                  onChange={handleFromDate}
                />
              </div>
              <div>
                <div>
                  <p
                    style={{
                      textAlign: "left",
                      font: "normal normal normal 12px/16px Roboto",
                      color: "#9CA1A6",
                      marginBottom: "4px",
                    }}
                  >
                    Updated before
                  </p>
                </div>
                <DatePickerComponent
                  label="From Date"
                  value={toDate}
                  onChange={handleToDate}
                />
              </div>
            </div>
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
