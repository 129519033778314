import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ToolTipComponent from "../Tooltip";
import Checkbox from "@mui/material/Checkbox";
import MultipleSelectCheckmarks from "../MultiSelect";
import ListItemText from "@mui/material/ListItemText";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import IconButtonComponent from "../IconButton";
import LocationsFilterField from "./LocationsFilterField";
import { AlarmStateFilter } from "./AlarmStateFilter";

export default function EquipmentFilter(props) {
  const { selectedAlarm, handleAlarmSelect } = props;
  const {
    handleSelectEquipmentType,
    handleSelectLocation,
    handleStatusList,
    handleSelectedEquipmentList,
    selectedEquipementStatus,
    selectedEquipmentTypes,
    equipments,
    selectedLocations,
    locations,
    selectedStatus,
  } = props;

  const {
    equipmentFilterCount,
    sensorTypeFilterCount,
    locationFilterCount,
    alarmFilterCount,
    statusFilterCount,
    clearFilter,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            equipmentFilterCount +
              sensorTypeFilterCount +
              locationFilterCount +
              alarmFilterCount +
              statusFilterCount >
            0
              ? "Filters (" +
                String(
                  equipmentFilterCount +
                    sensorTypeFilterCount +
                    locationFilterCount +
                    alarmFilterCount +
                    statusFilterCount
                ) +
                " applied)"
              : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <Box>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {equipmentFilterCount +
                      sensorTypeFilterCount +
                      locationFilterCount +
                      alarmFilterCount +
                      statusFilterCount >
                      0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={
                          equipmentFilterCount +
                          sensorTypeFilterCount +
                          locationFilterCount +
                          alarmFilterCount +
                          statusFilterCount
                        }
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </Box>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "500px",
            minHeight: "341px",
            overflow: "auto",
            padding: "16px",
            width: "520px",
          }}
        >
          <div>
            <AlarmStateFilter
              selectedAlarm={selectedAlarm}
              handleAlarmSelect={handleAlarmSelect}
              data={["Warning", "Alarm"]}
            />
            {/* <div style={{ marginTop: "24px" }}>
              <StatusFilter
                title="Equipment status"
                data={["Active", "Inactive"]}
                selectedStatus={selectedEquipementStatus}
                handleOnChange={handleSelectedEquipmentList}
              />
            </div>
            <div style={{ marginTop: "24px" }}>
              <StatusFilter
                title="Sensor status"
                data={["Active", "Inactive"]}
                selectedStatus={selectedStatus}
                handleOnChange={handleStatusList}
              />
            </div> */}

            <div style={{ marginTop: "24px" }}>
              <p
                style={{
                  textAlign: "left",
                  font: "normal normal normal 12px/16px Roboto",
                  color: "#9CA1A6",
                  marginBottom: "4px",
                }}
              >
                Equipment type(s)
              </p>

              <MultipleSelectCheckmarks
                onChange={handleSelectEquipmentType}
                placeholder="Select equipment type(s)"
                personName={selectedEquipmentTypes}
                data={equipments}
              >
                {equipments.map((equipment) => (
                  <MenuItem
                    key={equipment.value}
                    value={equipment.value}
                    sx={{
                      font: "normal normal medium 14px/20px Roboto",
                      color: "#222934",
                      "&:hover": {
                        background: "#E3E9EC 0% 0% no-repeat padding-box",
                        color: "#17455E",
                      },
                      "&$selected": {
                        // <-- mixing the two classes
                        backgroundColor: "transparent",
                      },
                    }}
                    className="menuItem"
                  >
                    <Checkbox
                      className="checkBox"
                      size="small"
                      sx={{
                        color: "#9CA1A6",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#17455E",
                        },
                        "&:focus-visible": {
                          outline: "2px auto #7fd3f2",
                        },
                        "&.Mui-checked": {
                          color: "#0D2E42",
                        },
                      }}
                      checked={selectedEquipmentTypes.indexOf(equipment.value) > -1}
                    />
                    <ListItemText primary={equipment.label} />
                  </MenuItem>
                ))}
              </MultipleSelectCheckmarks>
            </div>

            <LocationsFilterField
              handleSelectLocation={handleSelectLocation}
              selectedLocations={selectedLocations}
              locations={locations}
            />
          </div>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
