import * as React from "react";

const styles = {
  font: "roboto",
  fontSize: 12,
};
export default function Label(props) {
  return (
    <label
      for={props.children}
      style={styles}
    >
      {props.children}
    </label>
  );
}
