import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import moment from "moment";

const DateTimePickerComponent = (props) => {
  const { label, value, onChange, disabled } = props;

  // Function to format the date to UTC without time zone offset
  const formatToUTC = (date) => {
    return moment.utc(date).format("DD-MMM-yyyy HH:mm [UTC]");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        inputFormat="dd-MMM-yyyy HH:mm (xxx)"
        value={value}
        timezone="UTC"
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => { 
          delete params?.inputProps?.value ; 
          return (
          <TextField
            size="small"
            {...params}
            value={value ? formatToUTC(value) : ""}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#222934",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                fontSize: "16px",
                border: "1px solid #9CA1A6",
                borderRadius: "4px",
                ":hover": { border: "1px solid #17455E", borderRadius: "4px" },
                height: props.height,
              },
              "&.Mui-focused": {
                boxShadow: "#00AEEF 0px 0px 0px 4px",
                border: "1px solid #17455E",
              },
              "& .MuiInputBase-root.Mui-disabled": {
                background: "#F1F1F1 0% 0% no-repeat padding-box",
                color: "#C0C3C7",
                border: "1px solid #C0C3C7 !important",
                borderRadius: "4px",
              },
            }}
          />
        )}}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerComponent;
