import React, { Fragment, useState, useEffect } from "react";
import { Grid } from "@mui/material";

//COMPONENTS
import Toast from "@Components/BaseComponents/Toast";
import {
  useAddLocationMutation,
  useDeleteLocationMutation,
  useEditLocationMutation,
  useFindLocationsQuery,
} from "../../../Store/Slices/locationsSlice";

import {
  createLocationTree,
  filterObjectByValue,
  findObjectByValue,
  validateUIState,
} from "../../../Utils/Tools";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { useSelector } from "react-redux";
import LocationDrawer from "../../../Components/RightDrawer/Locations";
import LocationTable from "../../../Components/Tables/LocationTable";

import { useFindMappedEquipmentsQuery } from "../../../Store/Slices/mappedEquipmentSlice";
import { useFindMappedSubstationsQuery } from "../../../Store/Slices/mappedSubstationSlice";
import { useAddArchiveItemsMutation } from "../../../Store/Slices/archiveSlice";

const LocationSetup = () => {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [openedTab, setOpenedTab] = useState("1");

  const [edit, setEdit] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [mappedSubstations, setMappedSubstations] = useState([]);
  const [mappedEquipments, setMappedEquipments] = useState([]);
  const [locationDeleteDrawer, setLocationDeleteDrawer] = useState(false);
  const [msg, setMsg] = useState("");
  const [parentLocation, setParentLocation] = useState({});
  const [locationUpdated, setLocationUpdated] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const [addLocation, { isSuccess: addLocationSuccess, error: addLocationError }] =
    useAddLocationMutation();

  const [editLocation, { isSuccess: editLocationSucces, error: editLocationError }] =
    useEditLocationMutation();

  const [deleteLocation, { isSuccess: deleteLocationSuccess }] = useDeleteLocationMutation();

  const [
    addArchive,
    { isSuccess: addArchiveSuccess, isError: addArchiveIsError, error: addArchiveError },
  ] = useAddArchiveItemsMutation();
  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Locations");

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: mappedEquipmentData,
    isFetching: mappedEquipmentLoading,
    isSuccess: mappedEquipmentSuccess,
    isError: mappedEquipmentIsError,
    error: mappedEquipmentError,
  } = useFindMappedEquipmentsQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer === ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const [location, setLocation] = useState({
    CustomerID: defaultCustomerID,
    ParentID: "",
    LocationName: "",
  });

  const clearLocation = () => {
    setLocation({
      CustomerID: defaultCustomerID,
      ParentID: "",
      LocationName: "",
    });
  };

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      if (!isAdmin)
        setLocations(
          filterObjectByValue(locationsData, "CustomerID", auth.CustomerID).map((e) => {
            return e;
          })
        );
      else {
        if (auth.SelectedCustomer == "")
          setLocations(
            locationsData.map((e) => {
              return e;
            })
          );
        else
          setLocations(
            filterObjectByValue(locationsData, "CustomerID", auth.SelectedCustomer).map((e) => {
              return e;
            })
          );
      }
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const loadMappedEquipments = () => {
    if (mappedEquipmentLoading) setLoading(true);
    if (mappedEquipmentSuccess) setMappedEquipments(mappedEquipmentData.success.data);
    if (mappedEquipmentIsError) console.log(mappedEquipmentError);
    setLoading(false);
  };

  const loadMappedSubstations = () => {
    if (mappedSubstationLoading) setLoading(true);
    if (mappedSubstationSuccess) setMappedSubstations(mappedSubstationData.success.data);
    if (mappedSubstationIsError) console.log(mappedSubstationError);
    setLoading(false);
  };

  useEffect(() => {
    loadMappedSubstations();
  }, [mappedSubstationData]);

  useEffect(() => {
    loadMappedEquipments();
  }, [mappedEquipmentData]);

  useEffect(() => {
    loadLocations();
  }, [locationsData, auth]);

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (drawer === false) {
      clearLocation();
      setEdit(false);
    }
  };

  const customToggleLocationtDeleteDrawer = (open) => {
    setLocationDeleteDrawer(open);
  };

  const handleEdit = (Location) => {
    toggleDrawer(true);
    setEdit(true);
    setLocation(Location);
    handleParentLocation(Location.ParentID);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setLocationUpdated(true);
    setLocation({
      ...location,
      [name]: value,
    });
  };
  const handleChangeSelect = (param, e) => {
    if (!e) {
      e = [];
    }
    if (param == "Country") {
      setLocation({
        ...location,
        [param]: e.value,
        StateProvince: "",
      });
    } else
      setLocation({
        ...location,
        [param]: e.value,
      });

    setLocationUpdated(true);
  };

  const handleCreate = (reason) => {
    const data = { location: location, reason: reason };
    let res = addLocation(data);

    if (res.error) setMsg(res.error.data.error.message);
    setCheckBox(false);
    setDrawer(false);
    clearLocation();
  };

  const handleUpdate = async (reason) => {
    let id = location.LocationID;

    let new_location = { ...location };
    delete new_location["LocationID"];
    delete new_location["createdAt"];
    delete new_location["updatedAt"];
    delete new_location["IsActive"];
    delete new_location["Customer"];

    let data = { ...new_location, reason };

    let res = await editLocation({ id, data });
    if (res.data) setMsg({ severity: "success", message: res.data.success.message });
    if (res.error) setMsg({ severity: "error", message: res.error.data.error.message });

    toggleDrawer(false);
    setCheckBox(false);
  };

  const handleDelete = async (location, reason) => {
    const extractLocationIDs = (location) => {
      const locationIDs = [location.LocationID];

      if (location.childNodes && location.childNodes.length > 0) {
        location.childNodes.forEach((childNode) => {
          const childLocationIDs = extractLocationIDs(childNode);
          locationIDs.push(...childLocationIDs);
        });
      }

      return locationIDs;
    };

    const locationIDs = extractLocationIDs(location);

    await addArchive({
      data: { Location: locationIDs },
      reason: reason,
    });

    customToggleLocationtDeleteDrawer(false);
    toggleDrawer(false);
  };
  const handleClick = (reason) => {
    edit ? handleUpdate(reason) : handleCreate(reason);
    setOpenedTab("1");
  };

  const handleParentLocation = (e) => {
    const parentLocation = findObjectByValue(locations, "LocationID", e);
    let new_location = {};
    if (parentLocation) {
      new_location = { ...parentLocation };
      delete new_location["CustomerID"];
      delete new_location["LocationName"];
      delete new_location["ParentID"];
      delete new_location["LocationID"];
      delete new_location["createdAt"];
      delete new_location["updatedAt"];
      delete new_location["IsActive"];
      delete new_location["Customer"];
      delete new_location["MapFile"];
    }
    setParentLocation(new_location);
  };

  return (
    <Fragment>
      {addLocationSuccess && (
        <Toast
          message="Location successfully added"
          severity="success"
        />
      )}
      {editLocationSucces && (
        <Toast
          message="Location successfully updated"
          severity="success"
        />
      )}
      {addArchiveSuccess && (
        <Toast
          message="Location successfully archived"
          severity="success"
        />
      )}
      {addLocationError && (
        <Toast
          message="Location name is not unique"
          severity="error"
        />
      )}
      {editLocationError && (
        <Toast
          message="Location name is not unique"
          severity="error"
        />
      )}

      {locationsLoading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <LocationTable
            toggleDrawer={toggleDrawer}
            locations={createLocationTree(locations)}
            allLocations={locations}
            handleEdit={handleEdit}
            location={location}
            mappedEquipments={mappedEquipments}
            mappedSubstations={mappedSubstations}
            allowedEdit={allowedEdit}
          />
          <Grid
            item
            md={12}
          >
            <LocationDrawer
              edit={edit}
              drawer={drawer}
              toggleDrawer={toggleDrawer}
              location={location}
              locations={locations}
              locationCombinations={locations.map((loca) => {
                return {
                  value: loca.LocationID,
                  label: loca.LocationName,
                  ParentID: loca.ParentID,
                };
              })}
              locationTree={createLocationTree(locations)}
              handleClick={handleClick}
              handleChange={handleChange}
              handleChangeSelect={handleChangeSelect}
              locationDeleteDrawer={locationDeleteDrawer}
              customToggleLocationtDeleteDrawer={customToggleLocationtDeleteDrawer}
              setLocation={setLocation}
              clearLocation={clearLocation}
              handleParentLocation={handleParentLocation}
              parentLocation={parentLocation}
              locationUpdated={locationUpdated}
              setLocationUpdated={setLocationUpdated}
              handleDelete={handleDelete}
              checkBox={checkBox}
              setCheckBox={setCheckBox}
              openedTab={openedTab}
              setOpenedTab={setOpenedTab}
            />
          </Grid>
        </>
      )}
    </Fragment>
  );
};

export default LocationSetup;
