import { apiSlice } from "../API";

export const sensorSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensors: builder.query({
      query: () => "/sensor",
      providesTags: ["Sensor"],
      transformResponse: (response, meta, arg) => {
        const customerID = localStorage.getItem("customerID");
        return response.success.data.filter((item) => {
          return item.IsArchive === false && item.CustomerID === customerID;
        });
      },
    }),
    findSensorsWithOutStatus: builder.query({
      query: () => "/sensor",
      providesTags: ["Sensor"],
      transformResponse: (response, meta, arg) => {
        const customerID = localStorage.getItem("customerID");
        return response.success.data.filter((item) => {
          return item.IsArchive === false && item.CustomerID === customerID;
        });
      },
    }),
    getSensorsData: builder.query({
      query: (id) => ({
        url: `/sensor/sensorData/${id}`,
      }),
    }),
    addSensor: builder.mutation({
      query: (sensor) => ({
        url: "/sensor",
        method: "POST",
        body: sensor,
      }),
      invalidatesTags: ["Sensor", "Substation", "Equipment"],
    }),
    editSensor: builder.mutation({
      query: ({ id, data }) => {
        console.log("🚀 ~ file: sensorSlice.js:46 ~ id:", id);
        return {
          url: `/sensor/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Sensor"],
    }),
    deleteSensor: builder.mutation({
      query: (id) => ({
        url: `/sensor/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sensor"],
    }),
    addSensorConfig: builder.mutation({
      query(sensorConfig) {
        return {
          url: "/sensorConfig/cache",
          method: "POST",
          body: sensorConfig,
        };
      },
      invalidatesTags: ["Sensor"],
    }),
    getSensorConfig: builder.query({
      query: (id) => {
        return {
          url: `/sensorConfig/cache/${id}`,
        };
      },
    }),
    editSensorConfig: builder.mutation({
      query({ id, sensorConfig }) {
        console.log("🚀 ~ file: sensorSlice.js:73 ~ query ~ sensorConfig:", sensorConfig);
        console.log("🚀 ~ file: sensorSlice.js:73 ~ query ~ id:", id);
        return {
          url: `/sensorConfig/cache/${id}`,
          method: "PUT",
          body: sensorConfig,
        };
      },
      invalidatesTags: ["Sensor"],
    }),
    sensorDataByDataTime: builder.query({
      query: (filterDataParams) =>
        `/sensor/sensorData?startRangeDate=${filterDataParams.startDate}&endRangeDate=${filterDataParams.endDate}&querySensorIDs=${filterDataParams.querySensorIDs}`,
      providesTags: ["Equipment", "SensorData"],
    }),
  }),
});

export const {
  useFindSensorsQuery,
  useFindSensorsWithOutStatusQuery,
  useGetSensorsDataQuery,
  useAddSensorMutation,
  useEditSensorMutation,
  useAddSensorConfigMutation,
  useLazyGetSensorConfigQuery,
  useEditSensorConfigMutation,
  useDeleteSensorMutation,
  useSensorDataByDataTimeQuery,
} = sensorSlice;
