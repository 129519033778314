import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomMenu from "@Components/BaseComponents/Menu";
import MenuItemComponent from "../MenuItem";
import ToolTipComponent from "../Tooltip";
import IconButtonComponent from "../IconButton";
import SensorReportMenuItem from "../SensorReportMenuItem";
import ContextMenuItem from "../ContextMenuItem";
import { useUnSuppressSensorsMutation } from "../../../Store/Slices/sensorSuppressSlice";

const SensorContextMenu = (props) => {
  const {
    sensor,
    handleAlertSuppression,
    handleAlertAcknowledge,
    handleSuppressDrawerEdit,
    alertSuppression,
    unsuppressAlarm,
    viewReport,
    auditTrail,
    editSensor,
  } = props;
  const [activeSensor, setActiveSensor] = useState({});
  const [unSuppressSensors, { isSuccess: unSuppressSensorsSuccess }] =
    useUnSuppressSensorsMutation();

  const LiveData = activeSensor?.LiveData || activeSensor?.liveData;
  const menuId = "sensor-context-menu";

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleContextMenu = (event, sensor = {}) => {
    setActiveSensor(sensor);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: "fit-content",

          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        {LiveData && !LiveData?.Suppressed && (
          <ContextMenuItem
            handleChange={() => {
              handleAlertSuppression(sensor, true);
              handleMenuClose();
            }}
            className="fa-light fa-bell-slash"
            Label="Suppress Alarm"
          />
        )}
        {LiveData?.State === 4 && !LiveData?.Acknowledged && (
          <ContextMenuItem
            handleChange={() => {
              handleAlertAcknowledge(sensor, true);
              handleMenuClose();
            }}
            className="fa-light fa-circle-exclamation-check"
            Label="Acknowledge Alarm"
            margin="0px 7px 0px 0px"
          />
        )}
        {unsuppressAlarm && LiveData?.Suppressed && (
          <ContextMenuItem
            handleChange={() => {
              unSuppressSensors({ SensorIDs: [sensor.SensorID] });
              handleMenuClose();
            }}
            className="fa-light fa-bell-on"
            Label="Unsuppress alarm"
            margin="0px 7px 0px 0px"
          />
        )}
        {viewReport && <SensorReportMenuItem activeSensor={activeSensor} />}
        {process.env.SHOW_AUDIT_TRAIL === "true" && auditTrail && (
          <MenuItemComponent
            to={`/audittrail/Sensor-${sensor.SensorLabel}`}
            handleChange={() => console.log("Shortcuts")}
            ID={{ Type: "Sensor", ID: sensor.SensorID }}
            Children={
              <>
                <i
                  class="fa-light fa-list-timeline"
                  style={{ width: "24px", color: "#9CA1A6" }}
                ></i>{" "}
                Audit Trail<div>&nbsp;&nbsp;</div>
              </>
            }
          />
        )}
        {editSensor && !LiveData?.Suppressed && (
          <ContextMenuItem
            handleChange={() => [handleSuppressDrawerEdit(true), handleMenuClose()]}
            className="fa-light fa-pen-to-square"
            Label="Edit sensor"
          />
        )}
      </Box>
    </CustomMenu>
  );
  return (
    <>
      <ToolTipComponent
        title="More options"
        value="More options"
        placement="bottom-end"
        Component={
          <Box
            sx={{
              width: "fit-content",
            }}
          >
            <IconButtonComponent
              onClick={(e) => handleContextMenu(e, sensor)}
              Component={
                <i
                  class="fa-solid fa-ellipsis-vertical"
                  style={{
                    color: "#9CA1A6",
                    fontSize: "20px",
                    lineHeight: "25px",
                    cursor: "pointer",
                  }}
                ></i>
              }
            />
          </Box>
        }
      />

      {renderMenu}
    </>
  );
};

export default SensorContextMenu;
