import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import "./style.css";
import IconButtonComponent from "../IconButton";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";
const useStyles = makeStyles((theme) => ({
  IconButton: {
    color: `#fff`,
    transform: "rotate(-40deg)",
    "&:hover": {
      backgroundColor: "transparent",
      color: `#fff`,
    },
  },
  undoButton: {
    backgroundColor: "#34A852",
    width: "93px",
    height: "35px",
    color: "#F7F8F8",
    font: "normal normal medium 16px/19px Roboto",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#34A852",
      color: "#F7F8F8",
    },
  },
}));

export default function Toast(props) {
  let theme = useTheme();
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  let { message, severity, title, handleClick, undo, autoHideDuration } = props;
  let icon, iconClass;

  const [open, setOpen] = useState(true);

  if (severity) {
    if (severity === "success") {
      iconClass = "fa-square-check";
    } else if (severity === "info") {
      iconClass = "fa-info-circle";
    } else if (severity === "warning") {
      iconClass = "fa-exclamation-triangle";
    } else if (severity === "error") {
      iconClass = "fa-times-hexagon";
    }
    icon = (
      <Box
        sx={{
          height: "48px",
          width: "48px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          className={`fa-solid ${iconClass} fa-xl`}
          style={{ color: `${theme.palette[severity][900]}`, fontSize: "34px" }}
        ></i>
      </Box>
    );
  } else {
    icon = "";
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  const undoAction = (
    <Fragment>
      <Button
        onClick={handleClick}
        className={classes.undoButton}
      >
        <IconButtonComponent
          size="small"
          aria-label="close"
          onClick={handleClose}
          className={classes.IconButton}
          Component={<ReplaySharpIcon fontSize="small" />}
        />
        UNDO
      </Button>
    </Fragment>
  );

  const action = (
    <Box
      sx={{
        height: "40px",
        width: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButtonComponent
        size="small"
        aria-label="close"
        onClick={handleClose}
        Component={
          <i
            className="fa-regular fa-xmark fa-xl"
            style={{
              color: `${theme.palette[severity][800]}`,
              fontSize: "32px",
            }}
          ></i>
        }
      />
    </Box>
  );

  return (
    <div>
      <Snackbar
        open={open}
        // sx={{ mt: "8px" }}
        autoHideDuration={autoHideDuration || 3000}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Alert
          action={undo ? [undoAction, action] : action}
          severity={severity}
          icon={icon}
          sx={{
            width: maxWidthSm ? "100%" : "432px",
          }}
        >
          {title != " " && <AlertTitle>{title}</AlertTitle>}
          <p
            style={{
              font: "18px/21px Roboto",
              color: "#1E4620",
              fontWeight: "medium",
            }}
          >
            {message}
          </p>
        </Alert>
      </Snackbar>
    </div>
  );
}
