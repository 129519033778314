import React, { useContext, useEffect, createRef, useState } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";
import { DrawerContext } from "@Context/DrawerContext";

import MapViewTable from "../Tables/MapViewTable";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const MapViewPane = (props) => {
  const {
    CustomerID,
    equipments,
    equipmentLive,
    locations,
    setTab,
    setLocation,
    setSelectedLocationId,
    selectedLocationId,
    locationsHierarchy,
    setEquipment,
    equipment,
    substations,
    edit,
    setEdit,
    devices,
    setDevices,
    toggleDrawer,
    mappedEquipments,
    updatedMappedEquipments,
    setUpdatedMappedEquipments,
    mappedSubstations,
    updatedMappedSubstations,
    setUpdatedMappedSubstations,
    currentBreakpoint,
    allowedEdit,
    generateUniqueMapX,
  } = props;

  const { open } = useContext(DrawerContext);

  const SensorRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const windowResize = open
    ? `${windowDimensions.width - 240}px`
    : `${windowDimensions.width - 80}px`;

  useEffect(() => {
    if (!clientWidth) {
      console.log(SensorRef.current.clientWidth);
      let client = SensorRef.current.clientWidth / 1;
      setClientWidth(client);
      return;
    }
    SensorRef.current.style.minWidth = clientWidth + "px";
    SensorRef.current.style.maxWidth = clientWidth + "px";
  }, [clientWidth]);

  useEffect(() => {
    open
      ? (SensorRef.current.style.transition = "400ms")
      : (SensorRef.current.style.transition = "270ms");
    SensorRef.current.style.maxWidth = windowResize / 1.5;
  }, [windowDimensions.width, open]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      {...props}
      className="split-pane-left"
      ref={SensorRef}
    >
      <MapViewTable
        equipments={equipments}
        equipmentLive={equipmentLive}
        locations={locations}
        substations={substations}
        setTab={setTab}
        setLocation={setLocation}
        setSelectedLocationId={setSelectedLocationId}
        selectedLocationId={selectedLocationId}
        locationsHierarchy={locationsHierarchy}
        setEquipment={setEquipment}
        equipment={equipment}
        edit={edit}
        setEdit={setEdit}
        devices={devices}
        setDevices={setDevices}
        toggleDrawer={toggleDrawer}
        mappedEquipments={mappedEquipments}
        updatedMappedEquipments={updatedMappedEquipments}
        setUpdatedMappedEquipments={setUpdatedMappedEquipments}
        mappedSubstations={mappedSubstations}
        updatedMappedSubstations={updatedMappedSubstations}
        setUpdatedMappedSubstations={setUpdatedMappedSubstations}
        currentBreakpoint={currentBreakpoint}
        allowedEdit={allowedEdit}
        generateUniqueMapX={generateUniqueMapX}
        CustomerID={CustomerID}
      />
    </div>
  );
};

export default MapViewPane;
