import { apiSlice } from "../API";

export const equipmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findEquipment: builder.query({
      query: () => "/equipment",
      providesTags: ["Equipment", "Sensor"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item.IsArchive === false);
      },
    }),
    findEquipmentSensorTypes: builder.query({
      query: () => "/equipment/equipmentSensors",
      providesTags: ["Equipment", "SensorType"],
      transformResponse: (response, meta, arg) => {
        const customerID = localStorage.getItem("customerID");
        return response.success.data.filter(
          (item) => item.IsArchive === false && item.Location.Customer.CustomerID === customerID
        );
      },
    }),
    findEquipmentSensorTypesWithStatus: builder.query({
      query: () => "/equipment/equipmentSensors",
      providesTags: ["Equipment", "SensorType"],
    }),
    liveEquipment: builder.query({
      query: () => "/equipment/live",
      providesTags: ["Equipment", "LiveData", "Sensor"],
      transformResponse: (response) => {
        const customerID = localStorage.getItem("customerID");
        return {
          success: {
            data: response.success.data
              .filter((e) => !e.IsArchive && e.Location.Customer.CustomerID === customerID)
              .map((e) => {
                e.Sensors = e.Sensors.filter((s) => !s.IsArchive);
                return e;
              }),
          },
        };
      },
    }),
    dataEquipment: builder.query({
      query: (filterDataParams) =>
        `/equipment/equipmentData?startRangeDate=${filterDataParams.startDate}&endRangeDate=${filterDataParams.endDate}&equipmentIDs=${filterDataParams.equipmentID}`,
      providesTags: ["Equipment", "SensorData"],
    }),
    addEquipment: builder.mutation({
      query(equipment) {
        return {
          url: "/equipment",
          method: "POST",
          body: equipment,
        };
      },
      invalidatesTags: ["Equipment"],
    }),
    editEquipment: builder.mutation({
      query({ id, data }) {
        console.log("🚀 ~ file: equipmentSlice.js ~ line 21 ~ query ~ data", data);
        console.log("🚀 ~ file: equipmentSlice.js ~ line 21 ~ query ~ id:", id);

        return {
          url: `/equipment/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Equipment"],
    }),
    deleteEquipment: builder.mutation({
      query: (id) => ({
        url: `/equipment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Equipment"],
    }),
  }),
});

export const {
  useFindEquipmentQuery,
  useFindEquipmentSensorTypesQuery,
  useFindEquipmentSensorTypesWithStatusQuery,
  useLiveEquipmentQuery,
  useDataEquipmentQuery,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useDeleteEquipmentMutation,
} = equipmentSlice;
