import React, { Fragment, useEffect, useState } from "react";

//COMPONENTS IMPORT
import UsersTable from "@Components/Tables/UsersTable";
import UsersDrawer from "@Components/RightDrawer/UsersDrawer";
import Loader from "@Components/LoadingSpinner";
import Toast from "@Components/BaseComponents/Toast";
import {
  useFindUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
} from "../../../Store/Slices/usersSlice";
import { useFindCustomersQuery } from "../../../Store/Slices/customersSlice";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import { useSelector } from "react-redux";
import { filterObjectByValue, validateUIState } from "../../../Utils/Tools";
import { useFindUserGroupsQuery } from "../../../Store/Slices/userGroupsSlice";
import { useFindAuthorizationGroupsQuery } from "../../../Store/Slices/authorizationGroupsSlice";
import {
  useAddArchiveItemsMutation,
  useGetArchivedItemsQuery,
  useRemoveArchiveItemsMutation,
} from "../../../Store/Slices/archiveSlice";
import { useFindUserGroupUsersQuery } from "../../../Store/Slices/userGroupUsersSlice";
import { useFindSensorAlertCalendarTypeQuery } from "../../../Store/Slices/sensorAlertCalendarTypeSlice";
import { useLocation } from "react-router-dom";

const User = () => {
  const {
    data: customersData,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();
  const {
    data: archivedData,
    isLoading: archivedLoading,
    isSuccess: archivedSuccess,
    isError: archiveIsError,
    error: archiveError,
  } = useGetArchivedItemsQuery({
    entityTypes: '["User"]',
  });
  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: userSuccess,
    isError: usersIsError,
    error: usersError,
  } = useFindUsersQuery();

  const {
    data: authorizationGroupsData,
    isSuccess: authorizationGroupsSuccess,
    isError: authorizationGroupsIsError,
    error: authorizationGroupsError,
  } = useFindAuthorizationGroupsQuery();

  const {
    data: userGroupUsersData,
    isLoading: userGroupUsersLoading,
    isSuccess: userGroupUsersSuccess,
    isError: userGroupUsersIsError,
    error: userGroupUsersError,
  } = useFindUserGroupUsersQuery();

  const {
    data: SensorAlertCalendarTypes,
    isSuccess: SensorAlertCalendarTypeSuccess,
    isError: SensorAlertCalendarTypeIsError,
    error: SensorAlertCalendarTypeError,
  } = useFindSensorAlertCalendarTypeQuery();

  const {
    data: userGroupsData,
    isSuccess: userGroupSuccess,
    isLoading: userGroupsLoading,
  } = useFindUserGroupsQuery();

  const [addUser, { isSuccess: addUserSuccess }] = useAddUserMutation();
  const [
    addArchive,
    { isSuccess: addArchiveSuccess, isError: addArchiveIsError, error: addArchiveError },
  ] = useAddArchiveItemsMutation();
  const [
    removeArchive,
    { isSuccess: removeArchiveSuccess, isError: removeArchiveIsError, error: removeArchiveError },
  ] = useRemoveArchiveItemsMutation();
  const [editUser, { isSuccess: editUserSuccess, error: editError }] = useEditUserMutation();

  const auth = useSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Users");

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [authorizationGroups, setAuthorizationGroups] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [UserGroupIDs, setUserGroupIDs] = useState([]);
  const [tempUserGroupIDs, setTempUserGroupIDs] = useState(...UserGroupIDs);

  const [edit, setEdit] = useState(false);
  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer === ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const [userGroups, setUserGroups] = useState([]);
  const [filterUserGroups, setFilterUserGroups] = useState([]);
  const [msg, setMsg] = useState("");
  const [userGroupUsers, setUserGroupUsers] = useState([]);
  const [sensorAlertTypes, setSensorAlertTypes] = useState([]);
  const [toastData, setToastData] = useState({});
  const navigatedUsersData = useLocation();

  const [user, setUser] = useState({
    CustomerID: defaultCustomerID,
    UserName: "",
    FirstName: "",
    LastName: "",
    SNSPhone: "",
    SNSEMail: "",
    Language: "EN",
    GMTAdjustment: "",
    RoleID: "",
    AdditionalInformation: "",
    PreferSMS: false,
    PreferEmail: false,
    UserGroupIDs: [],
  });
  const [userLastNametemp, setUserLastNametemp] = useState("");
  const [isPasswordChangedState, setIsPasswordChangedState] = useState(false);

  // const XiltrixRoles = [
  //   "c87ce71a-b44f-4376-ba18-7bcc0a4102d5",
  //   "e39c0dba-bb9f-4eab-92b8-c002d7fe89bf",
  //   "cc8a39b6-3602-454a-b034-18e6453727cb",
  //   "3b5dfa74-47ed-4b40-b4cd-a00785e6cae1",
  // ];

  const loadCustomers = () => {
    if (customersSuccess) {
      setCustomers(
        customersData.map((c) => {
          return { value: c.CustomerID, label: c.CustomerName };
        })
      );
    }
    if (customersIsError) console.log(customersError);
  };
  const AlertUserPasswordChange = (userFirstName, isPasswordChanged) => {
    setUserLastNametemp(userFirstName);
    setIsPasswordChangedState(true);
    setTimeout(() => {
      setUserLastNametemp("");
      setIsPasswordChangedState(false);
    }, 1000);
  };
  const loadAuthorizationGroups = () => {
    if (authorizationGroupsSuccess) {
      const AGS = authorizationGroupsData.success.data.map((u) => {
        return {
          value: u.AuthorizationGroupID,
          label: u.AuthorizationGroupName,
          CustomerID: u.CustomerID,
        };
      });
      const defaultRoles = filterObjectByValue(AGS, "CustomerID", null);
      if (!isAdmin)
        setAuthorizationGroups([
          ...filterObjectByValue(AGS, "CustomerID", auth.CustomerID),
          ...defaultRoles,
        ]);
      else {
        if (auth.SelectedCustomer === "") setAuthorizationGroups(AGS);
        else
          setAuthorizationGroups([
            ...filterObjectByValue(AGS, "CustomerID", auth.SelectedCustomer),
            ...defaultRoles,
          ]);
      }
    }
    if (authorizationGroupsIsError) console.log(authorizationGroupsError);
  };

  const loadUsers = () => {
    if (archivedSuccess && userSuccess) {
      const isActiveUser = (user) => user.IsActive;
      const isNotArchivedUser = (user, archivedData) =>
        !archivedData?.success?.data?.User?.some((value) => value.entityID === user.UserID);
      const filteredUsersByRole = usersData.filter(
        (user) => isActiveUser(user) && isNotArchivedUser(user, archivedData)
      );
      if (navigatedUsersData.state !== null) {
        filteredUsersByRole = filterObjectByValue(
          usersData,
          "RoleID",
          navigatedUsersData.state.RoleID
        );
      }
      if (!isAdmin)
        setUsers(filterObjectByValue(filteredUsersByRole, "CustomerID", auth.CustomerID));
      else {
        if (auth.SelectedCustomer === "") setUsers(filteredUsersByRole);
        else
          setUsers(filterObjectByValue(filteredUsersByRole, "CustomerID", auth.SelectedCustomer));
      }
    }
    if (usersIsError) console.log(usersError);
  };

  const loadUserGroups = () => {
    if (userGroupSuccess)
      if (!isAdmin) {
        const UG = filterObjectByValue(userGroupsData.success.data, "CustomerID", auth.CustomerID);
        setUserGroups(
          UG.map((ug) => {
            return {
              value: ug.UserGroupID,
              label: ug.GroupName,
            };
          })
        );
      } else {
        if (auth.SelectedCustomer === "")
          setUserGroups(
            userGroupsData.success.data.map((ug) => {
              return {
                value: ug.UserGroupID,
                label: ug.GroupName,
              };
            })
          );
        else {
          const UG = filterObjectByValue(
            userGroupsData.success.data,
            "CustomerID",
            auth.SelectedCustomer
          );
          setUserGroups(
            UG.map((ug) => {
              return {
                value: ug.UserGroupID,
                label: ug.GroupName,
              };
            })
          );
        }
      }
  };

  const loadUserGroupUsers = () => {
    if (userGroupUsersSuccess) {
      setUserGroupUsers(userGroupUsersData.success.data);
    }
    if (userGroupUsersIsError) console.log(userGroupUsersError);
  };

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  const loadSensorAlertTypes = () => {
    if (SensorAlertCalendarTypeSuccess)
      setSensorAlertTypes(
        SensorAlertCalendarTypes.success.data.map((alertType) => {
          return {
            value: alertType.Id,
            label: alertType.CalendarName,
            Description: alertType.Description,
          };
        })
      );
    if (SensorAlertCalendarTypeIsError) console.log(SensorAlertCalendarTypeError);
  };
  useEffect(() => {
    loadSensorAlertTypes();
  }, [SensorAlertCalendarTypes]);

  const clearUser = () => {
    setUser({
      CustomerID: defaultCustomerID,
      UserName: "",
      FirstName: "",
      LastName: "",
      SNSPhone: "",
      SNSEMail: "",
      Language: "EN",
      GMTAdjustment: "",
      RoleID: "",
      AdditionalInformation: "",
      PreferSMS: false,
      PreferEmail: false,
      UserGroupIDs: [],
    });
  };

  const handleChangePhoneInput = (val) => {
    setUserUpdated(true);
    setUser({
      ...user,
      SNSPhone: val,
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "SNSEMail") {
      let new_errors = { ...errors };
      setErrors(new_errors);
      new_errors.SNSEMail = "";
    }
    if (name === "SNSPhone") {
      let new_errors = { ...errors };
      new_errors.SNSPhone = "";
      setErrors(new_errors);
    }
    setUser({
      ...user,
      [name]: value,
    });
    setUserUpdated(true);
  };

  const handleCreate = async (userCalled, reason) => {
    if (validate(user)) {
      try {
        let res = await addUser({ ...user, reason: reason });

        if (res.error) {
          setMsg(res.error.data.error.message);
        }
      } catch (err) {
        console.log("ERROR", err);
      }
      toggleDrawer(false);
    } else {
      console.log("elsing", user);
      console.log("erros", errors);
    }
  };

  const handleEdit = (User) => {
    toggleDrawer(true);
    setEdit(true);
    setUser(User);
  };

  const handleEditSave = async (UserEdit, reason) => {
    let { UserID } = UserEdit;
    let id = UserID;
    let userData = JSON.parse(JSON.stringify(UserEdit));
    userData.UserGroupIDs = UserGroupIDs;
    let data = { ...userData, reason: reason };

    if (validate(data)) {
      try {
        await editUser({ id, data });
        toggleDrawer(false);
        setEdit(false);
        clearUser();
      } catch (err) {
        console.error(editError);
        console.error("ERROR:", err);
      }
    }
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (open === false) {
      clearUser();
      setErrors({});
      setEdit(false);
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("UserName" in fieldValues)
      temp.UserName = fieldValues.UserName ? "" : "A user name is required.";
    if ("FirstName" in fieldValues)
      temp.FirstName = fieldValues.FirstName ? "" : "A first name is required.";
    if ("LastName" in fieldValues)
      temp.LastName = fieldValues.LastName ? "" : "A last name is required.";
    if ("SNSEMail" in fieldValues)
      temp.SNSEMail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          fieldValues.SNSEMail
        )
          ? ""
          : "Email is not valid.";
    if ("SNSPhone" in fieldValues)
      temp.SNSPhone =
        /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g.test(
          fieldValues.SNSPhone
        )
          ? ""
          : "Not a valid phone number.";
    // /$^|.+@.+..+/
    setErrors({
      ...temp,
    });
    if (fieldValues) return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    loadCustomers();
  }, [customersData]);

  useEffect(() => {
    loadUsers();
  }, [usersData, auth, archivedData]);

  useEffect(() => {
    loadUserGroups();
  }, [userGroupsData, auth]);

  useEffect(() => {
    loadAuthorizationGroups();
  }, [authorizationGroupsData, auth]);

  useEffect(() => {
    loadUserGroupUsers();
  }, [userGroupUsersData]);

  useEffect(() => {
    if (users.length > 0 && navigatedUsersData.state) {
      console.log(navigatedUsersData);
    }
  }, [users, auth, navigatedUsersData]);

  useEffect(() => {
    if (msg === "") return;
    const timeout = setTimeout(() => {
      setMsg("");
    }, 2000);

    return () => clearTimeout(timeout);
  }, [msg]);

  useEffect(() => {
    if (Object.keys(toastData).length == 0) return;
    const timeout = setTimeout(() => {
      setToastData({});
    }, 8000);

    return () => clearTimeout(timeout);
  }, [toastData]);

  useEffect(() => {
    if (edit) {
      let data = filterObjectByValue(userGroupUsers, "UserID", user.UserID);
      if (data.length > 0) {
        const results = [];
        data
          .map((UGU) => {
            return userGroups.map((UG) => {
              return UG.value === UGU.UserGroupID && results.push(UG);
            });
          })
          .flat();
        setTempUserGroupIDs(results);
        setUserGroupIDs(results);
      }
    }
  }, [drawer]);

  return (
    <Fragment>
      {isPasswordChangedState && (
        <Toast
          message={`${userLastNametemp}'s password updated successfully`}
          severity="success"
        />
      )}
      {addUserSuccess && (
        <Toast
          message="User successfully added"
          severity="success"
        />
      )}
      {addArchiveSuccess && (
        <Toast
          message="User successfully archived"
          severity="success"
        />
      )}
      {editUserSuccess && (
        <Toast
          message={`User successfully ${
            toastData.IsActive === true
              ? "activated"
              : toastData.IsActive === false
              ? "archived"
              : "updated"
          }`}
          autoHideDuration={7000}
          severity="success"
        />
      )}
      {/* {deleteUser && (
        <Toast message="User successfully deleted" severity="success" />
      )} */}
      {msg !== "" && (
        <Toast
          message={msg}
          severity="error"
        />
      )}
      {usersLoading || userGroupUsersLoading || userGroupsLoading ? (
        <SpinnerContainer>
          <Loader />
        </SpinnerContainer>
      ) : (
        <UsersTable
          user={user}
          users={users}
          toggleDrawer={toggleDrawer}
          handleEdit={handleEdit}
          errors={errors}
          customers={customers}
          displayCustomer={isAdmin}
          userGroups={userGroups}
          userGroupUsers={userGroupUsers}
          authorizationGroups={authorizationGroups}
          sensorAlertTypes={sensorAlertTypes}
          auth={auth}
          allowedEdit={allowedEdit}
        />
      )}
      <UsersDrawer
        displayCustomer={isAdmin}
        customers={customers}
        toggleDrawer={toggleDrawer}
        handleChange={handleChange}
        onChangePhoneInput={handleChangePhoneInput}
        handleCreate={handleCreate}
        loadCustomers={loadCustomers}
        user={user}
        drawer={drawer}
        checked={checked}
        setDrawer={setDrawer}
        setUser={setUser}
        errors={errors}
        edit={edit}
        handleEditSave={handleEditSave}
        userGroups={userGroups}
        filterUserGroups={filterUserGroups}
        setFilterUserGroups={setFilterUserGroups}
        authorizationGroups={authorizationGroups}
        setUserGroupIDs={setUserGroupIDs}
        UserGroupIDs={UserGroupIDs}
        editUser={editUser}
        addArchive={addArchive}
        removeArchive={removeArchive}
        discardSettingsDrawer={discardSettingsDrawer}
        confirmationDrawer={confirmationDrawer}
        userUpdated={userUpdated}
        setUserUpdated={setUserUpdated}
        userGroupUsers={userGroupUsers}
        sensorAlertTypes={sensorAlertTypes}
        setToastData={setToastData}
        AlertUserPasswordChange={AlertUserPasswordChange}
      />
    </Fragment>
  );
};

export default User;
