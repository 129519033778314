import React, { Fragment } from "react";
import FakeSensorOOB from "../../Components/FakeSensorOOBView";

const FakeSensorOOBScreen = () => {
  return (
    <Fragment>
      <FakeSensorOOB />
    </Fragment>
  );
};

export default FakeSensorOOBScreen;
