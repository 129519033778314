import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  useFindAuthorizationGroupsQuery,
  useAddAuthorizationGroupMutation,
} from "../../Store/Slices/authorizationGroupsSlice";
import { useFindAuthorizationsQuery } from "../../Store/Slices/authorizationSlice";
import { useFindUserGroupsQuery } from "../../Store/Slices/userGroupsSlice";
import {
  useAddUserGroupAuthorizationGroupMutation,
  useFindUserGroupAuthorizationGroupsQuery,
} from "../../Store/Slices/userGroupAuthorizationGroupSlice";
import {
  useFindAuthorizationGroupAuthorizationsQuery,
  useAddAuthorizationGroupAuthorizationsMutation,
  useDeleteAuthorizationGroupAuthorizationsMutation,
} from "../../Store/Slices/authorizationGroupAuthorizationsSlice";
import { filterObjectByValue } from "../../Utils/Tools";
import TransferList from "../../Components/TransferList/AuthorizationGroupsTransferList";
import InputLabel from "@mui/material/InputLabel";
import Toast from "@Components/BaseComponents/Toast";

import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useFindCustomersQuery } from "../../Store/Slices/customersSlice";

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const AuthorizationGroups = () => {
  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();
  const {
    data: userGroupAuthorizationGroupsData,
    isLoading: userGroupAuthorizationGroupsLoading,
    isSuccess: userGroupAuthorizationGroupsSuccess,
    isError: userGroupAuthorizationGroupsIsError,
    error: userGroupAuthorizationGroupsError,
  } = useFindUserGroupAuthorizationGroupsQuery();
  const {
    data: userGroupsData,
    isLoading: userGroupsLoading,
    isSuccess: userGroupsSuccess,
    isError: userGroupsIsError,
    error: userGroupsError,
  } = useFindUserGroupsQuery();
  const {
    data: authorizationGroupsData,
    isLoading: authorizationGroupsLoading,
    isSuccess: authorizationGroupsSuccess,
    isError: authorizationGroupsIsError,
    error: authorizationGroupsError,
  } = useFindAuthorizationGroupsQuery();
  const {
    data: AuthorizationsData,
    isLoading: AuthorizationsLoading,
    isSuccess: AuthorizationsSuccess,
    isError: AuthorizationIsError,
    error: AuthorizationsError,
  } = useFindAuthorizationsQuery();

  const {
    data: authorizationGroupsAuthorizationsData,
    isLoading: authorizationGroupsAuthorizationsLoading,
    isSuccess: authorizationGroupsAuthorizationsSuccess,
    isError: authorizationGroupsAuthorizationsIsError,
    error: authorizationGroupsAuthorizationsError,
  } = useFindAuthorizationGroupAuthorizationsQuery();

  const [
    adduserGroupAuthorizationGroup,
    {
      isSuccess: adduserGroupAuthorizationGroupSuccess,
      isError: adduserGroupAuthorizationGroupIsError,
      isLoading: adduserGroupAuthorizationGroupLoading,
      error: adduserGroupAuthorizationGroupError,
    },
  ] = useAddUserGroupAuthorizationGroupMutation();
  const [
    addAuthorizationGroup,
    {
      isSuccess: addAuthorizationGroupSuccess,
      isError: addAuthorizationGroupsIsError,
      isLoading: addAuthorizationGroupLoading,
      error: addAuthorizationGroupError,
    },
  ] = useAddAuthorizationGroupMutation();
  const [
    addauthorizationGroupsAuthorizations,
    {
      isSuccess: addauthorizationGroupsAuthorizationsSuccess,
      isError: addauthorizationGroupsAuthorizationsIsError,
      isLoading: addauthorizationGroupsAuthorizationsLoading,
      error: addauthorizationGroupsAuthorizationsError,
    },
  ] = useAddAuthorizationGroupAuthorizationsMutation();
  const [
    deleteAuthorizationGroupsAuthorizations,
    {
      isSuccess: deleteAuthorizationGroupsAuthorizationsSuccess,
      isError: deleteAuthorizationGroupsAuthorizationsIsError,
      isLoading: deleteAuthorizationGroupsAuthorizationsLoading,
      error: deleteAuthorizationGroupsAuthorizationsError,
    },
  ] = useDeleteAuthorizationGroupAuthorizationsMutation();

  const [authorizationGroupAuthorizations, setauthorizationGroupAuthorizations] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [filterGroupUsers, setfilterGroupUsers] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [groupValue, setGroupValue] = useState({});
  const [groupID, setgroupID] = useState(null);
  const [filterAuthorizations, setFilterAuthorizations] = useState([]);
  const [userGroupID, setUserGroupID] = useState("");
  const [authorizationGroupID, setAuthorizationGroupID] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customerID, setCustomerID] = useState("");
  const [userGroupAuthorizationGroups, setUserGroupAuthorizationGroups] = useState([]);
  const [selectedUserGroupAuthorizationGroups, setSelectedUserGroupAuthorizationGroups] = useState(
    []
  );
  const auth = useSelector((state) => state.auth);
  const authorizationGroupButtonValidation = !(userGroupID != "" && authorizationGroupID != "");

  const loadUserGroupAuthorizationGroups = () => {
    if (userGroupAuthorizationGroupsSuccess)
      setUserGroupAuthorizationGroups(userGroupAuthorizationGroupsData.success.data);
    if (userGroupAuthorizationGroupsIsError) console.log(userGroupAuthorizationGroupsError);
  };

  const loadCustomers = () => {
    if (customersSuccess)
      setCustomers(
        customersData.map((c) => {
          return { value: c.CustomerID, label: c.CustomerName };
        })
      );
    if (customersIsError) console.log(customersError);
  };

  const loadUserGroups = () => {
    if (userGroupsSuccess) {
      setUserGroups(
        userGroupsData.success.data.map((u) => {
          return {
            value: u.UserGroupID,
            label: u.GroupName,
            CustomerID: u.CustomerID,
          };
        })
      );
    }
    if (userGroupsIsError) console.log(userGroupsError);
  };

  const loadAuthorizationGroups = () => {
    if (authorizationGroupsSuccess) {
      let temp = authorizationGroupsData.success.data.map((u) => {
        return {
          value: u.AuthorizationGroupID,
          label: u.AuthorizationGroupName,
        };
      });

      if (auth.Role == "ecc992b4-28cb-4a6b-8b98-66bb0c862947") {
        let res = temp.filter((itemX) => !XiltrixRoles.includes(itemX.value));
        console.log(res);
        setFilterGroup(res);
      } else setFilterGroup(temp);
    }
    if (authorizationGroupsIsError) console.log(authorizationGroupsError);
  };

  const loadAuthorizations = () => {
    if (AuthorizationsSuccess)
      setFilterAuthorizations(
        AuthorizationsData.success.data.map((u) => {
          return {
            value: u.AuthorizationID,
            PageName: u.PageName,
            NavigationName: u.NavigationName,
            ElementName: u.ElementName,
            UIState: u.UIState,
          };
        })
      );

    if (AuthorizationIsError) console.log(AuthorizationsError);
  };

  const loadauthorizationGroupsAuthorizations = () => {
    if (authorizationGroupsAuthorizationsSuccess)
      setauthorizationGroupAuthorizations(authorizationGroupsAuthorizationsData.success.data);
    if (authorizationGroupsAuthorizationsIsError)
      console.log(authorizationGroupsAuthorizationsError);
  };

  const handleChange = (newValue, actionMeta) => {
    setGroupValue(newValue);
  };

  const handleInputChange = (inputValue, actionMeta) => {
    setAddGroup(inputValue);
  };

  const handleAuthorizationCreate = async () => {
    let data = { AuthorizationGroupName: addGroup };
    try {
      let res = await addAuthorizationGroup(data);
      let { AuthorizationGroupID, AuthorizationGroupName } = res.data.success.data;
      setGroupValue({
        value: AuthorizationGroupID,
        label: AuthorizationGroupName,
      });
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 60 ~ handleCreate ~ error", error);
    }
  };

  const addAuthorizationToGroup = async (data) => {
    try {
      let newauthorizationGroupAuthorizations = [];
      for await (const res of await Promise.all(
        data.map((d) => addauthorizationGroupsAuthorizations(d))
      )) {
        newauthorizationGroupAuthorizations.push({ ...res.data.success.data });
      }
      setauthorizationGroupAuthorizations([
        ...authorizationGroupAuthorizations,
        ...newauthorizationGroupAuthorizations,
      ]);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 126 ~ addUserToGroup ~ error", error);
      if (addauthorizationGroupsAuthorizationsIsError)
        console.log(
          "🚀 ~ file: index.js ~ line 61 ~ UserGroups ~ addUserGroupUsersError",
          addauthorizationGroupsAuthorizationsError
        );
    }
  };

  const deleteAuthorizationToGroup = async (data) => {
    try {
      await Promise.all(data.map((d) => deleteAuthorizationGroupsAuthorizations(d)));
      setauthorizationGroupAuthorizations([
        ...authorizationGroupAuthorizations.filter((value) => !data.includes(value?.id)),
      ]);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 153 ~ deleteUserToGroup ~ error", error);
      if (deleteAuthorizationGroupsAuthorizationsIsError)
        console.log(
          "🚀 ~ file: index.js ~ line 61 ~ UserGroups ~ addUserGroupUsersError",
          addauthorizationGroupsAuthorizationsError
        );
    }
  };

  const selectedUserGroupAuthorizationGroupsIDS = () => {
    return userGroupAuthorizationGroups.filter((userGroupAuthorizationGroup) => {
      return userGroupAuthorizationGroup.UserGroupID === userGroupID;
    });
  };

  const filteredUserGroups = () => {
    if (customerID !== "") {
      return userGroups.filter((u) => u.CustomerID === customerID);
    }
    return userGroups;
  };

  const findLabel = (array, label) => {
    let res = array.find((c) => c.label === label.label);
    return res ? res : "";
  };
  const handleAddAuthorizationGroup = async () => {
    await adduserGroupAuthorizationGroup({
      UserGroupID: userGroupID,
      AuthorizationGroupID: authorizationGroupID,
    });
  };

  useEffect(() => {
    loadCustomers();
  }, [customersData]);

  useEffect(() => {
    loadUserGroupAuthorizationGroups();
  }, [userGroupAuthorizationGroupsData]);

  useEffect(() => {
    loadUserGroups();
  }, [userGroupsData]);

  useEffect(() => {
    loadAuthorizationGroups();
  }, [authorizationGroupsData]);

  useEffect(() => {
    loadAuthorizations();
  }, [AuthorizationsData]);

  useEffect(() => {
    loadauthorizationGroupsAuthorizations();
  }, [authorizationGroupsAuthorizationsData]);

  useEffect(() => {
    let res = filterObjectByValue(
      authorizationGroupAuthorizations,
      "AuthorizationGroupID",
      groupValue.value
    );
    setfilterGroupUsers(res);
    setgroupID(groupValue.value);
  }, [groupValue, authorizationGroupAuthorizations]);

  useEffect(() => {
    setSelectedUserGroupAuthorizationGroups(
      filterGroup.filter((authorization) => {
        return selectedUserGroupAuthorizationGroupsIDS().some(
          (a) => a.AuthorizationGroupID === authorization.value
        );
      })
    );
  }, [userGroupID, userGroupAuthorizationGroups]);

  const XiltrixRoles = [
    "c87ce71a-b44f-4376-ba18-7bcc0a4102d5",
    "e39c0dba-bb9f-4eab-92b8-c002d7fe89bf",
    "cc8a39b6-3602-454a-b034-18e6453727cb",
    "3b5dfa74-47ed-4b40-b4cd-a00785e6cae1",
  ];

  return (
    <Box sx={{ m: 2 }}>
      {adduserGroupAuthorizationGroupSuccess && (
        <Toast
          message="Authorization Group successfully added into User Group"
          severity="success"
          autoHideDuration={6000}
        />
      )}
      <InputLabel id="demo-simple-select-label">User Roles</InputLabel>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          sm={6}
        >
          <CreatableSelect
            isDisabled={addAuthorizationGroupLoading}
            isLoading={addAuthorizationGroupLoading}
            placeholder="User Roles"
            onChange={handleChange}
            onInputChange={handleInputChange}
            onCreateOption={handleAuthorizationCreate}
            options={filterGroup}
            value={findLabel(filterGroup, groupValue) || ""}
            styles={selectStyles}
          />
        </Grid>

        <Grid
          sx={{ mt: 3 }}
          item
          md={12}
        >
          <TransferList
            users={filterAuthorizations}
            filterAuthorizations={filterAuthorizations}
            filterGroup={groupID}
            filterGroupUsers={filterGroupUsers}
            addUserToGroup={addAuthorizationToGroup}
            deleteUserToGroup={deleteAuthorizationToGroup}
          />
        </Grid>
        {/* <Grid sx={{ mt: 3 }} item md={12}>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "1em", width: "25%" }}>
              <InputLabel id="demo-simple-select-label">Customer</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customerID}
                displayEmpty
                style={{ width: "100%" }}
                label={"Customers"}
                onChange={(event) => [setCustomerID(event.target.value)]}
                placeholder="Select Customer"
              >
                {customerID === "" && (
                  <MenuItem value="" disabled>
                    Select Customer
                  </MenuItem>
                )}
                {customers.map((customer, idx) => {
                  return (
                    <MenuItem value={customer.value} key={idx} selected>
                      {customer.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div style={{ margin: "1em", width: "25%" }}>
              <InputLabel id="demo-simple-select-label">User Groups</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userGroupID}
                displayEmpty
                style={{ width: "100%" }}
                label={"Authorizations"}
                onChange={(event) => [setUserGroupID(event.target.value)]}
                placeholder="Page Name"
              >
                {userGroupID === "" && (
                  <MenuItem value="" disabled>
                    Select UserGroups
                  </MenuItem>
                )}
                {filteredUserGroups().map((user, idx) => {
                  return (
                    <MenuItem value={user.value} key={idx} selected>
                      {user.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div style={{ margin: "1em", width: "25%" }}>
              <InputLabel id="demo-simple-select-label">
                Authorization Groups
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={authorizationGroupID}
                displayEmpty
                style={{ width: "100%" }}
                label={"Authorizations"}
                onChange={(event) => [
                  setAuthorizationGroupID(event.target.value),
                ]}
                placeholder="Page Name"
              >
                {authorizationGroupID === "" && (
                  <MenuItem value="" disabled>
                    Select AuthorizationGroup
                  </MenuItem>
                )}
                {filterGroup.map((authorizationGroup, idx) => {
                  return (
                    <MenuItem
                      value={authorizationGroup.value}
                      key={idx}
                      selected
                    >
                      {authorizationGroup.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div>
            <div style={{ margin: "1em", width: "25%" }}>
              <ThemeButton
                fullWidth
                onClick={handleAddAuthorizationGroup}
                color="primary"
                size="small"
                disabled={authorizationGroupButtonValidation}
              >
                {" "}
                Add Authorization Group
              </ThemeButton>
            </div>
          </div>
          <div>
            {userGroupID !== "" && (
              <TableContainer
                component={Paper}
                id="mytable"
                style={{ overflow: "scroll", height: "90vh" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        {" "}
                        <div className="Location">
                          <p className="tableHeading">
                            Authorization Group Name
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                    {selectedUserGroupAuthorizationGroups?.map(
                      (arrayData, idx) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={idx}
                          >
                            <TableCell align="left">
                              {" "}
                              <div className="Location">
                                <p className="Title">{arrayData.label}</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default AuthorizationGroups;
