import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
  const auth = useSelector((state) => state.auth.Authorizations);
  let userRoutes = Object.keys(auth);
  let allRoutes = [];
  const location = useLocation();

  const cleaner = (subRoute) => {
    return subRoute.element.toLowerCase().replace(/\s/g, "");
  };

  for (const routeSet in auth) {
    if (Object.hasOwnProperty.call(auth, routeSet)) {
      const subRoutes = auth[routeSet];
      for (let index = 0; index < subRoutes.length; index++) {
        const subRoute = subRoutes[index];
        if (
          cleaner(subRoute) !== "allcustomers" &&
          cleaner(subRoute) !== "usersallcustomers" &&
          cleaner(subRoute) !== "alllocations"
        ) {
          allRoutes.push(`/${routeSet.toLowerCase()}/${cleaner(subRoute)}`);
        }
      }
    }
  }

  userRoutes.map((mainRoute) => allRoutes.push(`/${mainRoute.toLowerCase()}`));

  let currentLocation = location.pathname;

  const accesable = allRoutes?.some((userRoute) => userRoute.toLowerCase() == currentLocation);

  return accesable ? (
    <Outlet />
  ) : (
    <Navigate
      to="/unauthorized"
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
