import { apiSlice } from "../API";

export const UserGroupActionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserGroupActions: builder.query({
      query: () => "/userGroupAction",
      providesTags: ["UserGroupAction", "Program"],
      transformResponse: (response, meta, arg) => {
        return response.success.data.filter((item) => item);
      },
    }),
    addUserGroupAction: builder.mutation({
      query: (userGroupAction) => ({
        url: "/userGroupAction",
        method: "POST",
        body: userGroupAction,
      }),
      invalidatesTags: ["UserGroupAction"],
    }),
    editUserGroupAction: builder.mutation({
      query: ({ id, userGroupAction }) => ({
        url: `/userGroupAction/${id}`,
        method: "PUT",
        body: userGroupAction,
      }),
      invalidatesTags: ["UserGroupAction"],
    }),
    deleteUserGroupAction: builder.mutation({
      query: (id) => ({
        url: `/userGroupAction/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserGroupAction"],
    }),
  }),
});

export const {
  useFindUserGroupActionsQuery,
  useAddUserGroupActionMutation,
  useEditUserGroupActionMutation,
  useDeleteUserGroupActionMutation,
} = UserGroupActionsSlice;
