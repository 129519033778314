import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ThemeButton from "@Components/Buttons";
import Box from "@mui/material/Box";
import { Select, Tooltip, Typography, Grid, Divider, Button } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Toast from "@Components/BaseComponents/Toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFindSensorsQuery } from "../../Store/Slices/sensorSlice";
import {
  useAddfakeSensorOOBMutation,
  useFindfakeSensorOOBsQuery,
  useDeletefakeSensorOOBMutation,
} from "../../Store/Slices/fakeSensorOOBSlice";
import "./style.css";
import { useFindUsersQuery } from "../../Store/Slices/usersSlice";
import { findObjectByValue, formatDate } from "../../Utils/Tools";
import { makeStyles } from "@material-ui/core/styles";

function FakeSensorOOB() {
  // const SubmitTimeValidation = !(selectedExceptionType != "");
  const minutes = [1, 2, 5, 10, 15, 30, 60];
  const [minute, setMinute] = useState(1);
  const [repeatedInterval, setRepeatedInterval] = useState(false);
  const [runNow, setRunNow] = useState(true);
  const [numOfRepeats, setNumOfRepeats] = useState(1);
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [tempDate, setTempDate] = useState(new Date());
  const [sensors, setSensors] = useState([]);
  const [fakeSensorOOBs, setFakeSensorOOBs] = useState([]);
  const [users, setUsers] = useState([]);
  const [sensor, setSensor] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(false);
  const handleClick = (data) => {
    console.log(data);
    setStartDate(
      `${data.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })} - ${data.toLocaleTimeString()}`
    );
    setTempDate(data);
  };

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
  } = useFindSensorsQuery();

  const {
    data: fakeSensorOOBData,
    isLoading: fakeSensorOOBLoading,
    isSuccess: fakeSensorOOBSuccess,
    isError: fakeSensorOOBIsError,
    error: fakeSensorOOBError,
  } = useFindfakeSensorOOBsQuery();

  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: usersSuccess,
    isError: userIsError,
    error: userError,
  } = useFindUsersQuery();

  const [
    deletefakeSensorOOB,
    {
      isSuccess: deletefakeSensorOOBSuccess,
      isError: deletefakeSensorOOBIsError,
      isLoading: deletefakeSensorOOBLoading,
      error: deletefakeSensorOOBError,
    },
  ] = useDeletefakeSensorOOBMutation();

  const [
    addfakeSensorOOB,
    {
      isSuccess: AddfakeSensorOOBSuccess,
      isError: AddfakeSensorOOBIsError,
      error: AddfakeSensorOOBError,
    },
  ] = useAddfakeSensorOOBMutation();
  const handleClear = () => {
    setTempDate("");
    setStartDate("");
    setValue("");
    setNumOfRepeats(1);
    setMinute(1);
    setSensor([]);
    setRepeatedInterval(false);
    setState(false);
  };
  const loadFakeSenors = () => {
    if (fakeSensorOOBSuccess) {
      setFakeSensorOOBs(
        fakeSensorOOBData?.success.data
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    }
    if (fakeSensorOOBIsError) console.log(fakeSensorOOBError);
  };

  const loadSensors = async () => {
    if (sensorsSuccess) {
      const tempSensors = [];
      console.log(sensorsData);
      const length = sensorsData.length;
      const data = sensorsData;
      for (let i = 0; i != length; i++) {
        tempSensors.push({
          value: data[i].SensorID,
          label: data[i].SensorLabel,
          SensorTypeID: data[i].SensorTypeID,
          CustomerID: data[i].CustomerID,
          configData: JSON.parse(data[i].SchemaJSON),
        });
      }
      setSensors(tempSensors);
      setLoading(false);
    }

    if (sensorIsError) console.log(sensorError);
  };

  const handlefakeSensorOOBCreate = () => {
    sensor.forEach(async (sensor) => {
      const data = {
        SensorID: sensor,
        Value: value,
        MaxRepeat: numOfRepeats,
        Interval: minute,
        Remaining: numOfRepeats,
        StartDateTime: startDate,
        CreatedBy: auth.UserID,
      };
      console.log(data);
      const response = await addfakeSensorOOB(data);
      console.log(response);
    });
  };

  const handleDeleteExceptions = async (id) => {
    const res = await deletefakeSensorOOB(id);
    console.log(res);
  };

  const loadUsers = () => {
    if (usersSuccess) setUsers(usersData);
    if (userIsError) console.log(userError);
  };

  useEffect(() => {
    loadSensors();
  }, [sensorsData]);

  useEffect(() => {
    loadUsers();
  }, [usersData]);

  useEffect(() => {
    loadFakeSenors();
  }, [fakeSensorOOBData]);

  useEffect(() => {
    console.log(runNow);
    if (runNow) {
      setStartDate("");
    }
  }, [runNow]);

  const createButtonValidation = !(
    value != "" &&
    sensor.length != 0 &&
    numOfRepeats > 0 &&
    minute != 0
  );

  const useStyles = makeStyles({
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {AddfakeSensorOOBSuccess && (
          <Toast
            message="FakeOOB added successfully"
            severity="success"
          />
        )}

        {deletefakeSensorOOBSuccess && (
          <Toast
            message="Fake Sensor Record Deleted Successfully"
            severity="success"
          />
        )}
        {loading ? (
          <SpinnerContainer>
            <Loader loading={false} />
          </SpinnerContainer>
        ) : (
          <div className="mainContainer">
            <div className="tableContainer">
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                  ml: "1em",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
                className="sensorCount"
              >
                Sensor Data List{" "}
              </Typography>
              <Divider />
              <TableContainer
                component={Paper}
                id="mytable"
                style={{
                  overflow: "scroll",
                  height: "82vh",
                  width: "100%",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ position: "relative" }}
                    >
                      <TableCell align="left">
                        {" "}
                        <p style={{ color: "#949ba0" }}>Sensor Label</p>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <p style={{ color: "#949ba0" }}>Value</p>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <p style={{ color: "#949ba0" }}>MaxRepeat</p>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <p style={{ color: "#949ba0" }}>Interval</p>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <p style={{ color: "#949ba0" }}>Remaining</p>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <p style={{ color: "#949ba0" }}>StartDateTime</p>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <p style={{ color: "#949ba0" }}>LastRun</p>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <p style={{ color: "#949ba0" }}>CreatedBy</p>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <p style={{ color: "#949ba0" }}></p>
                      </TableCell>
                    </TableRow>
                    {fakeSensorOOBs.map((fakeSensor, idx) => {
                      const user = findObjectByValue(users, "UserID", fakeSensor.CreatedBy);
                      const sensor = findObjectByValue(sensors, "value", fakeSensor.SensorID);
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          style={{ position: "relative", color: "#fff" }}
                          key={idx}
                        >
                          <TableCell
                            align="left"
                            color="#323232"
                          >
                            {" "}
                            <p>{sensor?.label}</p>
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <p>{fakeSensor.Value}</p>
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <p>{fakeSensor.MaxRepeat}</p>
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <p>{fakeSensor.Interval}</p>
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            <p>{fakeSensor.Remaining}</p>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <p>
                              {fakeSensor.StartDateTime &&
                                formatDate(
                                  new Date(fakeSensor.StartDateTime),
                                  "MM/dd/yyyy - HH:mm:ss"
                                )}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <p>
                              {formatDate(new Date(fakeSensor.LastRun), "MM/dd/yyyy - HH:mm:ss")}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            <p>{user?.UserName}</p>
                          </TableCell>
                          <Tooltip
                            title="delete"
                            placement="right"
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell onClick={() => handleDeleteExceptions(fakeSensor.ID)}>
                              <i class="fa-solid fa-trash-can"></i>{" "}
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="sensorsInputContainer">
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                  ml: "1em",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
                className="sensorCount"
              >
                Create Sensor Data{" "}
              </Typography>
              <Divider />
              <div
                style={{
                  display: "flex",
                  height: "93%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <div>
                  <Grid style={{ padding: "1em" }}>
                    <Grid
                      item
                      sm={12}
                      sx={{ mb: "22px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          className="required"
                          style={{
                            fontSize: state == true ? "1.1rem" : "1rem",
                          }}
                        >
                          Sensors
                        </InputLabel>
                        <Label
                          id="demo-simple-select-label"
                          onClick={() => setState(!state)}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {state == true ? (
                            <i class="fa-sharp fa-solid fa-caret-up"></i>
                          ) : (
                            <i class="fa-sharp fa-solid fa-caret-down"></i>
                          )}{" "}
                          More Info
                        </Label>
                      </div>
                      {state && (
                        <div
                          style={{
                            background: "rgb(247,247,247)",
                            borderRadius: "10px",
                            color: "#222934",
                            padding: "1em",
                            marginBottom: "1em",
                            display: "grid",
                          }}
                        >
                          <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                            <i
                              class="fa-regular fa-timer successIcon"
                              style={{ width: "1em" }}
                            ></i>{" "}
                            Desired Delay Time (s)
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              marginBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                marginRight: "5px",
                              }}
                            >
                              <i
                                class="fa-regular fa-timer warningIcon"
                                style={{ width: "1em" }}
                              ></i>{" "}
                              <i class="fa-solid fa-exclamation exclamaion"></i>
                            </div>
                            Desired Delay Time Warning(s)
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                            <i
                              class="fa-light fa-lock "
                              style={{ width: "1.3em" }}
                            ></i>
                            Input State
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                            <i
                              class="fa-regular fa-arrow-up successIcon"
                              style={{ width: "1em" }}
                            ></i>{" "}
                            Upper Limit of Normal State
                          </p>
                          <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                            <i
                              class="fa-regular fa-arrow-down successIcon"
                              style={{ width: "1em" }}
                            ></i>{" "}
                            Lower Limit of Normal State
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              marginBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                marginRight: "5px",
                              }}
                            >
                              <i
                                class="fa-regular fa-arrow-up warningIcon"
                                style={{ width: "1em" }}
                              ></i>{" "}
                              <i class="fa-solid fa-exclamation exclamaion"></i>
                            </div>
                            Upper Limit of Warning State
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              marginBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                marginRight: "5px",
                              }}
                            >
                              <i
                                class="fa-regular fa-arrow-down warningIcon"
                                style={{ width: "1em" }}
                              ></i>{" "}
                              <i class="fa-solid fa-exclamation exclamaion"></i>
                            </div>
                            Lower Limit of Warning State
                          </p>
                        </div>
                      )}

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sensor.map((sensor) => {
                          return sensor;
                        })}
                        displayEmpty
                        style={{
                          width: "100%",
                          color: "#A8B0AB",
                          fontSize: "12px",
                        }}
                        label={"Pages"}
                        onChange={(event) => [setSensor(event.target.value)]}
                        size="small"
                        multiple
                      >
                        {sensor.length == 0 && (
                          <MenuItem
                            value=""
                            disabled
                          >
                            Select Sensors
                          </MenuItem>
                        )}
                        {sensors.map((sensor, idx) => {
                          return (
                            <MenuItem
                              value={sensor.value}
                              key={idx}
                            >
                              <section
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  color: "#A8B0AB",
                                }}
                              >
                                <p style={{ width: "18em" }}>{sensor.label}</p>
                                <div style={{ width: "20%" }}>
                                  {Object.keys(sensor.configData).map((sensorConfigs) => {
                                    const data = sensor.configData[sensorConfigs];
                                    if (sensorConfigs == "sensorConfig") {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                            }}
                                          >
                                            <i class="fa-regular fa-timer successIcon"></i>{" "}
                                            {data["da"] || "-"}
                                          </p>{" "}
                                          |{" "}
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <i class="fa-regular fa-timer warningIcon"></i>{" "}
                                              <i class="fa-solid fa-exclamation exclamaion"></i>
                                            </div>
                                            {data["dw"] || "-"}
                                          </p>{" "}
                                          |
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                            }}
                                          >
                                            <i class="fa-regular fa-arrow-up successIcon"></i>{" "}
                                            {data["uln"] || "-"}
                                          </p>
                                          |
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                            }}
                                          >
                                            <i class="fa-regular fa-arrow-down successIcon"></i>{" "}
                                            {data["lln"] || "-"}
                                          </p>{" "}
                                          |
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <i class="fa-regular fa-arrow-up warningIcon"></i>{" "}
                                              <i class="fa-solid fa-exclamation exclamaion"></i>
                                            </div>
                                            {data["ulw"] || "-"}
                                          </p>{" "}
                                          |
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <i class="fa-regular fa-arrow-down warningIcon"></i>{" "}
                                              <i class="fa-solid fa-exclamation exclamaion"></i>
                                            </div>
                                            {data["llw"] || "-"}
                                          </p>
                                        </div>
                                      );
                                    } else if (sensorConfigs == "digitalSensorConfig") {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                            }}
                                          >
                                            <i class="fa-regular fa-timer"></i> {data["d"] || "-"}
                                          </p>{" "}
                                          |{" "}
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              margin: "0px 5px",
                                            }}
                                          >
                                            <i class="fa-light fa-lock"></i> {data["s"] || "-"}
                                          </p>{" "}
                                        </div>
                                      );

                                      console.log(sensorConfigs, data["d"]);
                                    }
                                  })}
                                </div>
                              </section>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      sx={{ mb: "22px" }}
                    >
                      <InputLabel className="required">Value</InputLabel>
                      <TextField
                        onChange={(event) => setValue(event.target.value)}
                        name="Value"
                        value={value || ""}
                        variant="outlined"
                        defaultValue=""
                        fullWidth
                        type="text"
                        size="small"
                        style={{ color: "#A8B0AB" }}
                        className={classes.root}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      sx={{ mb: "22px" }}
                    >
                      <InputLabel className="required">Number of Repeats</InputLabel>
                      <TextField
                        onChange={(event) => setNumOfRepeats(event.target.value)}
                        name="Number of Repeats"
                        value={numOfRepeats || ""}
                        variant="outlined"
                        defaultValue={1}
                        fullWidth
                        type="number"
                        size="small"
                        inputProps={{ min: 1 }}
                        style={{ color: "#A8B0AB" }}
                        className={classes.root}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ mb: "22px" }}
                    >
                      <InputLabel
                        className="required"
                        id="demo-simple-select-label"
                      >
                        Interval Duration (minutes)
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={minute}
                        displayEmpty
                        style={{ width: "100%" }}
                        label={"Pages"}
                        onChange={(event) => [setMinute(event.target.value)]}
                        size="small"
                      >
                        {minute === 0 && (
                          <MenuItem
                            value=""
                            disabled
                          >
                            Select Minutes
                          </MenuItem>
                        )}
                        {minutes.map((minute, idx) => {
                          return (
                            <MenuItem
                              value={minute}
                              key={idx}
                            >
                              {minute}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <FormGroup style={{ width: "fit-content", marginBottom: "22px" }}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Run Now"
                        size="small"
                        value={runNow}
                        style={{ color: "#A8B0AB" }}
                        onChange={(event) => setRunNow(event.target.checked)}
                      />
                    </FormGroup>
                    {!runNow && (
                      <div style={{ marginBottom: "5px" }}>
                        <DateTimePicker
                          label="Start Date"
                          size="small"
                          value={tempDate || ""}
                          onChange={handleClick}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </div>
                    )}
                  </Grid>
                </div>
                <div>
                  <Grid
                    item
                    className="footerGrid"
                  >
                    <Divider
                      style={{
                        width: "100%",
                      }}
                    />
                    <Grid
                      item
                      sm={12}
                      sx={{ mt: "7px", float: "right", mr: "20px" }}
                    >
                      <Button
                        onClick={handleClear}
                        sx={{
                          mr: 2,
                          color: "#17455E",
                        }}
                      >
                        {" "}
                        Clear
                      </Button>
                      <ThemeButton
                        color="primary"
                        size="small"
                        disabled={createButtonValidation}
                        onClick={handlefakeSensorOOBCreate}
                      >
                        {" "}
                        Save
                      </ThemeButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}
      </LocalizationProvider>
    </Box>
  );
}

export default FakeSensorOOB;
