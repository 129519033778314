import React from "react";
import { Avatar } from "@mui/material";

const hRange = [200, 260];
const sRange = [35, 65];
const lRange = [20, 50];

const generateHSL = (name) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (userName) => {
  return HSLtoString(generateHSL(userName));
};

function stringAvatar(name) {
  if (name?.trim().length > 1) {
    const nameParts = name.trim().split(/\s+/);
    const firstNameInitial = nameParts[0][0]?.toUpperCase();
    const lastNameInitial = nameParts.length > 1 ? nameParts[1][0]?.toUpperCase() : "";
    return {
      children: `${firstNameInitial}${lastNameInitial}`,
    };
  } else {
    return {
      children: `A`,
    };
  }
}

function CustomAvatar(props) {
  const { sx } = props;
  return (
    <Avatar
      sx={sx}
      src={props.src}
      {...(props.src ? {} : stringAvatar(props.name))}
    />
  );
}

export default CustomAvatar;
