import React, { Fragment } from "react";
import AlertView from "../../Components/AlertView";

const AlertScreen = () => {
  return (
    <Fragment>
      <AlertView />
    </Fragment>
  );
};

export default AlertScreen;
