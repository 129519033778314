import React from "react";

//COMPONENTS IMPORT
import Box from "@mui/material/Box";
import { useEditUserMutation } from "../../../Store/Slices/usersSlice";
import Toast from "@Components/BaseComponents/Toast";
import IconButtonComponent from "../../../Components/BaseComponents/IconButton";
import ToolTipComponent from "../../../Components/BaseComponents/Tooltip";

const EditRole = (props) => {
  let { role, handleEdit, paddingRight } = props;
  const [editUser, { error, isSuccess: editUserSuccess }] = useEditUserMutation();

  return (
    <>
      {editUserSuccess && (
        <Toast
          message="User Deleted successfully"
          severity="success"
        />
      )}
      <ToolTipComponent
        placement="bottom-end"
        title="Edit user"
        Component={
          <Box
            sx={{
              width: "fit-content",
              paddingRight: paddingRight ? paddingRight : "none",
              paddingLeft: !paddingRight ? "10px" : "none",
            }}
          >
            <IconButtonComponent
              onClick={() => handleEdit(role)}
              Component={<i class="fa-light fa-pen-to-square "></i>}
            />
          </Box>
        }
      />
    </>
  );
};

export default EditRole;
