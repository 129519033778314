import React from "react";
import moment from "moment";
import MenuItemComponent from "../MenuItem";

const SensorReportMenuItem = (props) => {
  const { activeSensor } = props;

  const getMaxDate = () =>
    moment.max(
      moment(activeSensor?.createdAt),
      moment(activeSensor?.LiveData?.Time || activeSensor?.liveData?.Time)
    );

  return (
    <MenuItemComponent
      to={`/report`}
      ID={{
        sensorID: activeSensor?.SensorID,
        startDate: getMaxDate().subtract(1, "hours").valueOf(),
        endDate: getMaxDate().valueOf(),
      }}
      Children={
        <>
          <i
            className="fa-light fa-chart-line fa-sm"
            style={{ width: "24px", color: "#9CA1A6" }}
          ></i>{" "}
          View report<div>&nbsp;&nbsp;</div>
        </>
      }
    />
  );
};

export default SensorReportMenuItem;
