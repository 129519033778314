import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

const ThemeButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <Button
        variant={props.variant}
        disabled={props.disabled}
        onClick={props.onClick ? (e) => props.onClick(e) : null}
        fullWidth={props.fullWidth}
        sx={{
          width: `${props.width}`,
          height: `${props.height}`,
          fontSize: "16px",
          fontWeight: "medium",
          font: "roboto",
          px: 1,
          py: 0.3,
          "&:focus": {
            borderColor: theme.palette.blueAction["500"],
            borderStyle: "solid",
            borderWidth: "3px",
          },
          "&:disabled": {
            opacity: 0.65,
            color: theme.palette.primary["50"],
          },
          "&:hover": {
            color: props.backgroundColor ? props.backgroundColor : "inherit",
          },
          color: props.contrastText ? props.contrastText : "inherit",
          backgroundColor: props.backgroundColor ? props.backgroundColor : "inherit",
        }}
      >
        {props.icon && (
          <Box
            mt={0.5}
            ml={0.6}
            mr={0.8}
          >
            <i className={props.icon} />
          </Box>
        )}
        {props.children}
      </Button>
    </>
  );
};

export default ThemeButton;
