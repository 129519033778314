import React from "react";
import Box from "@mui/material/Box";
import ToolTipComponent from "../Tooltip";
import LabelComponent from "../LabelComponent";

function SensorLabel(props) {
  const { LiveData, sensorType, title, options } = props;

  return (
    <ToolTipComponent
      title={title}
      Component={
        <Box
          sx={{
            ...options,
            width: "fit-content",
            cursor: "pointer",
            whiteSpace: "nowrap",
            marginRight: "2px",
          }}
        >
          <LabelComponent
            label={
              LiveData?.CurrentState ||
              parseFloat(LiveData?.Current)
                .toFixed(2)
                .concat(` ${sensorType?.Unit || "unit"}`) ||
              "test"
            }
            state={
              LiveData?.Suppressed
                ? "suppressed"
                : LiveData?.State === 0 || (LiveData?.State === 4 && LiveData?.Acknowledged)
                ? "success"
                : LiveData?.State === 1
                ? "warning"
                : LiveData?.State === 2
                ? "error"
                : LiveData?.State === 4 && LiveData?.Acknowledged === null
                ? "prior"
                : "neutral"
            }
          />
        </Box>
      }
    />
  );
}

export default SensorLabel;
