import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as htmlToImage from "html-to-image";
//COMPONENTS IMPORT
import Loader from "@Components/LoadingSpinner";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import { useSelector } from "react-redux";
import {
  NormalizeUnit,
  calculateMeanKineticTemperature,
  checkStateByReports,
  checkWarningStateByReports,
  deepCopy,
  findObjectByValue,
  formatDate,
  getMax,
  getMin,
  getStandardDeviation,
  validateUIState,
} from "../../../Utils/Tools";
import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useLocation } from "react-router-dom";
import ReportsTable from "../../../Components/Tables/ReportsTable";
import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import ReportHeader from "../../../Components/BaseComponents/ReportHeader";
import ReportDataHeader from "../../../Components/BaseComponents/ReportHeader/ReportDataHeader";
import { useSensorDataByDataTimeQuery } from "../../../Store/Slices/sensorSlice";
import ChartComponent from "../../../Components/SensorView/Chart";

const ReportSetup = () => {
  const location = useLocation();
  const locationState = location.state;

  const auth = useSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;
  const allowedEdit = validateUIState(auth.Authorizations, "report", "");
  const calculateGapThreshold = (hours) => {
    const gapThresholds = {
      0: 5 * 60 * 1000, // 5 minutes for "last 2 hours"
      1: 5 * 60 * 1000, // 5 minutes for "last 24 hours"
      2: 5 * 60 * 1000, // 5 minutes for "last 7 days"
      3: 5 * 60 * 1000, // 5 minutes for  "last 30 days"
      4: 5 * 60 * 1000, // 5 minutes for  "last 3 months"
      5: 5 * 60 * 1000, // 5 minutes for  "last year"
    };
  
    return gapThresholds[timeStampSelected] || 5 * 60 * 1000; // Default to 5 minutes if choice not found
  };
  const [loading, setLoading] = useState(false);
  const [activeSensor, setActiveSensor] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [customQuerySubmission, setCustomQeurySubmission] = useState(true);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [isZoomed, setIsZoomed] = useState(true);
  const [activeSensorID, setActiveSensorID] = useState(location.state?.ID?.sensorID);
  const [timeStampSelected, setTimeStampSelected] = useState("0");
  const [startDate, setStartDate] = useState(location?.state?.ID?.startDate);
  const [tab, setTab] = useState(0);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [endDate, setEndDate] = useState(location?.state?.ID?.endDate);
  const [timeStamps, setTimeStamps] = useState([
    { value: "0", label: "Last 2 hours" },
    { value: "1", label: "Last 24 hours" },
    { value: "2", label: "Last week" },
    { value: "3", label: "Last month" },
    { value: "4", label: "Last 3 months" },
    { value: "5", label: "Last year" },
    { value: "6", label: "Custom range" },
  ]);
  const [tableLook, setTableLook] = useState("dense");
  const [sensorReports, setSensorReports] = useState([]);
  const [loadedSensorReport, setLoadedSensorReport] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [currentSensorType, setCurrentSensorType] = useState({});
  const [currentSensorData, setCurrentSensorData] = useState({});
  const [chartLinesData, setChartLinesData] = useState({});
  const [dateFieldsEnabled, setDateFieldsEnabled] = useState(true);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [tempSensorReports, setTempSensorReports] = useState([]);
  const [tempSensorsData, setTempSensorsData] = useState([]);
  const [download, setDownload] = useState(false);
  const [faulted, setFaulted] = useState(false);
  const [warning, setWarning] = useState(false);
  const [filterDataParams, setFilterDataParams] = useState({
    startDate: startDate,
    endDate: endDate,
    querySensorIDs: [location?.state?.ID?.sensorID],
  });
  const [gapThreshold, setGapThreshold] = useState(calculateGapThreshold(2));
  const {
    data: sensorData,
    isSuccess: sensorDataSuccess,
    isLoading: sensorDataLoading,
  } = useSensorDataByDataTimeQuery(filterDataParams);
  const getReportColumns = () => {
    return columns.map((col) => col.label);
  };
  function getTimezoneAbbreviation(date) {
    const timezoneAbbreviation = moment(date).tz('UTC').format('z');
    return timezoneAbbreviation;
  }
  const getReportData = () => {
    let tempLoadedSensorReport = [...loadedSensorReport.data];
    return tempLoadedSensorReport?.sort((a, b) => b.Time - a.Time)?.map((row) => {
      return [
        formatDate(new Date(row.Time), "dd-MMM-yyyy - HH:mm:ss"),
        row.State == 0 ? "Normal" : row.State == 1 ? "Warning" : "Alarm",
        `${
          row?.CurrentState ||
          parseFloat(row?.Current)
            .toFixed(2)
            .concat(` ${NormalizeUnit(currentSensorType?.Unit) || "unit"}`) ||
          "test"
        }`,
        row?.Raw || '',
        `${row?.Min || ''} ${NormalizeUnit(currentSensorType?.Unit)}`,
        `${row?.LowerWarning || ''} ${NormalizeUnit(currentSensorType?.Unit)}`,
        `${row?.UpperWarning || ''} ${NormalizeUnit(currentSensorType?.Unit)}`,
        `${row?.Max || ''} ${NormalizeUnit(currentSensorType?.Unit)}`,
      ];
    });
  };
  const getReportDataCSV = () => {
    let tempLoadedSensorReport = [...loadedSensorReport.data];
    return tempLoadedSensorReport?.sort((a, b) => b.Time - a.Time).map((row) => {
      return [
        formatDate(new Date(row.Time), "dd-MMM-yyyy - HH:mm:ss"),
        row.State == 0 ? "Normal" : row.State == 1 ? "Warning" : "Alarm",
        `${
          row?.CurrentState ||
          parseFloat(row?.Current)
            .toFixed(2)
        }`,
        row?.Raw || '',
        `${row?.Min || ''}`,
        `${row?.LowerWarning || ''}`,
        `${row?.UpperWarning || ''}`,
        `${row?.Max || ''}`,
      ];
    });
  };
  const handleDownloadCSV = () => {
    const csvString = getCsvStringFromArrayOfStrings(getReportColumns(), getReportDataCSV());
    saveCsvStringAsFile(csvString, `${activeSensor?.SensorLabel} report`);
  };

  let handlePrintPDF = async () => {
    const doc = new jsPDF();
    doc.text(`${activeSensor?.SensorLabel} report`, 10, 10); // params (text, x, y)
    var node = document.getElementById("report-chart");
    if (node) {
      const dataUrl = await htmlToImage.toJpeg(node, {
        backgroundColor: "#FFFFFF",
      });
      doc.addImage(dataUrl, "JPEG", 10, 15, 190, 100);
    }

    autoTable(doc, {
      styles: {
        cellPadding: "auto",
        fontSize: 7,
        lineColor: 10,
        cellWidth: "wrap",
        overflow: "linebreak",
      },
      startX: 0,
      startY: node ? 117 : null,
      showHead: "firstPage",
      margin: { left: 1, right: 1 },
      tableWidth: "auto",
      head: [getReportColumns()],
      body: getReportData(),
    });
    doc.save(`${activeSensor?.SensorLabel} report`);
  };
  function generateNullDataPoints(startDate, endDate, data ) {
    if (data.length){
    const nullDataPoints = [];
    
      if(!data.filter(val => val.Time == startDate.getTime()).length){
      nullDataPoints.push({
        Time: startDate.getTime(),
        Min: null,
          Max: null,
          UpperWarning: null,
          LowerWarning: null,
          Current: null,
      });
    }
    if(!data.filter(val => val.Time == endDate.getTime()).length){
      nullDataPoints.push({
        Time: endDate.getTime(),
        Min: null,
          Max: null,
          UpperWarning: null,
          LowerWarning: null,
          Current: null,
      });
    }
    
  
    return nullDataPoints;
  }else{
    return [];
  }
  }
  useEffect(() => {
    if (sensorDataSuccess && sensorTypes.length > 0) {
      const LoadedSensor = sensorData.success.data.sensorData[filterDataParams.querySensorIDs]

      const sensor = LoadedSensor?.details;
      const data = chartData.length ? chartData : LoadedSensor?.data;
  
      // Replace empty strings with null in the data
      const cleanedData = data.map((dataPoint) => {
        return {
          ...dataPoint,
          Min: dataPoint.Min === "" ? null : dataPoint.Min,
          Max: dataPoint.Max === "" ? null : dataPoint.Max,
          UpperWarning: dataPoint.UpperWarning === "" ? null : dataPoint.UpperWarning,
          LowerWarning: dataPoint.LowerWarning === "" ? null : dataPoint.LowerWarning,
          Current: dataPoint.Current === "" ? null : dataPoint.Current,
        };
      });
  
      const sensortype = findObjectByValue(sensorTypes, "value", sensor?.SensorTypeID);
      setCurrentSensorType(sensortype);
      setLoadedSensorReport(LoadedSensor);
      const tempData = [];
  
      const arrayForSort = cleanedData || []; // Use the cleaned data
        const nullDataPoints =generateNullDataPoints(new Date(startDate), new Date(endDate), arrayForSort);
      
      const allDataPoints = [...nullDataPoints, ...arrayForSort];
      var sortedData = allDataPoints.sort((a, b) => a.Time - b.Time);
      sortedData.forEach((dataPoint, index) => {
        var tempDataPoint = {};
        tempDataPoint[sensor.SensorLabel] = dataPoint.Current;
        tempDataPoint["time"] = dataPoint.Time;
        tempDataPoint["Time"] = dataPoint.Time;
        tempDataPoint["Raw"] = (dataPoint.Raw === "" || !dataPoint.Raw) ? null : dataPoint.Raw;
        tempDataPoint["Min"] = (dataPoint.Min === "" || !dataPoint.Min) ? null : dataPoint.Min;
        tempDataPoint["Max"] = (dataPoint.Max === "" || !dataPoint.Max) ? null : dataPoint.Max;
        tempDataPoint["Current"] = dataPoint.Current;
        tempDataPoint["HighWarning"] = (dataPoint.UpperWarning === "" || !dataPoint.UpperWarning) ? null : dataPoint.UpperWarning;
        tempDataPoint["LowWarning"] = (dataPoint.LowerWarning === "" || !dataPoint.LowerWarning) ? null : dataPoint.LowerWarning;
        tempData.push(tempDataPoint);
      
        // Check if the time difference between this data point and the next one exceeds the gapThreshold
      if (index < sortedData.length - 1) {
        const timeDifference = sortedData[index + 1].Time - dataPoint.Time;
        if (timeDifference > gapThreshold) {
          // Insert a gap by adding a placeholder data point
          const gapDataPoint = {
            [sensor.SensorLabel]: null,
            time: dataPoint.Time + gapThreshold,
            Time: dataPoint.Time + gapThreshold,
            Raw: null,
            Min: null,
            Max: null,
            Current: null,
            HighWarning: null,
            LowWarning: null,
          };
          tempData.push(gapDataPoint);
        }
      }
    });
  
      setData(tempData);
      setChartData(tempData);
      setActiveSensor(sensor);
      setSensorReports(sortedData);
      setFaulted(checkStateByReports(sortedData));
      setWarning(checkWarningStateByReports(sortedData));
      setLoading(false);
    }
  }, [sensorData, sensorTypes]);
  

  useEffect(() => {
    if (chartData.length && currentSensorType) {
      const tempSensorConfigs = [];
      let counter = 0;
      let meanKineticTemperature = "-";

      if (currentSensorType?.label?.includes("Temperature")) {
        const temperatureReadings = [];
        const timeInterval = [];
        for (const data of chartData) {
          const date = new Date(data.Time);
          let current = data.Current;
          if (currentSensorType?.label?.includes("F")) {
            current = (current - 32) * (5 / 9);
          }
          temperatureReadings.push(current);

          timeInterval.push(date.getHours());
        }
        meanKineticTemperature = calculateMeanKineticTemperature(temperatureReadings, timeInterval)
          .toFixed(2)
          .concat(` ${currentSensorType?.Unit}`);
      }

      for (let i = 0; i < chartData.length; i++) {
        const dataObject = {};
        dataObject.Stats = chartData[i].Current;
        dataObject.Min = chartData[i].Min;
        dataObject.Max = chartData[i].Max;
        dataObject.Time = new Date();
        tempSensorConfigs.push(dataObject);
        if (chartData[i].State != 0) counter++;
      }
      let firstDataPoint = chartData[0];
      setChartLinesData({
        LowWarning: firstDataPoint?.LowerWarning,
        HighWarning: firstDataPoint?.UpperWarning,
        LowAlarm: firstDataPoint?.Min,
        HighAlarm: firstDataPoint?.Max,
      });
      setCurrentSensorData({
        Mean: (
          tempSensorConfigs.reduce((total, next) => total + next.Stats, 0) /
            tempSensorConfigs.length || 0
        ).toFixed(2),
        Min: getMin(tempSensorConfigs).toFixed(2),
        Max: getMax(tempSensorConfigs).toFixed(2),
        SD: getStandardDeviation(
          tempSensorConfigs.map((sensorConfig) => {
            return sensorConfig.Stats;
          })
        ).toFixed(2),
        AlarmCount: counter,
        MKT: meanKineticTemperature,
      });
    }
  }, [chartData, currentSensorType]);

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const options = {
    options: {
      sort: false,
    },
  };
  const columns = [
    {
      name: "TimeStamp",
      label: `Timestamp ${getTimezoneAbbreviation(chartData?.[0]?.Time) || ''}`,
      options: {
        sort: true,
      },
    },
    {
      name: "AlarmState",
      label: "Alarm state",
      ...options,
    },
    {
      name: "CurrentValue",
      label: `Current value`,
      ...options,
    },
    {
      name: "Raw",
      label: "Raw value",
      ...options,
    },
    {
      name: "LowAlarmLimit",
      label: `Low alarm limit`,
      ...options,
    },
    {
      name: "LowWarningLimit",
      label: `Low warning limit`,
      ...options,
    },
    {
      name: "HighWarningLimit",
      label: `High warning limit`,
      ...options,
    },
    {
      name: "HighAlarmLimit",
      label: `High alarm limit`,
      ...options,
    },
  ];

  const CSVColumns = [
    {
      name: "TimeStamp",
      label: "Timestamp",
    },
    {
      name: "AlarmState",
      label: "Alarm state",
    },
    {
      name: "CurrentValue",
      label: `CurrentValue ${currentSensorType?.Unit}`,
    },
    {
      name: "RawValue",
      label: `RawValue ${currentSensorType?.Unit}`,
    },
    {
      name: "LowAlarmLimit",
      label: `LowAlarmLimit ${currentSensorType?.Unit}`,
    },
    {
      name: "LowWarningLimit",
      label: `LowWarningLimit ${currentSensorType?.Unit}`,
    },
    {
      name: "HighWarningLimit",
      label: `HighWarningLimit ${currentSensorType?.Unit}`,
    },
    {
      name: "HighAlarmLimit",
      label: `HighAlarmLimit ${currentSensorType?.Unit}`,
    },
  ];
  useEffect(() => {
    if (sensorDataSuccess) {
      setLoader(false);
    }
  }, [sensorDataSuccess]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  useEffect(() => {
    if (startDate && endDate && customQuerySubmission && !isZoomed) {
      const FilteredDataParams = {
        startDate: startDate,
        endDate: endDate,
        querySensorIDs: filterDataParams.querySensorIDs,
      };
      setLoading(true);
      setFilterDataParams(FilteredDataParams);
    }
    setCustomQeurySubmission(timeStampSelected !== "6");
  }, [startDate, endDate, customQuerySubmission]);

  useEffect(() => {
    if (customQuerySubmission) {
      setLoading(true);
    }
  }, [customQuerySubmission]);

  useEffect(() => {
    setTempSensorReports(deepCopy(sensorReports));
  }, [sensorReports]);

  const findActiveSensor = (sensors) => {
    return sensors.filter((sensor) => sensor.SensorID == activeSensorID)[0];
  };

  const handleStartDateChange = (newValue) => {
    setIsZoomed(false);
    setStartDate(moment(newValue).valueOf());
    setChartData([]);
  };
  const handleEndDateChange = (newValue) => {
    setIsZoomed(false);
    setEndDate(moment(newValue).valueOf());
    setChartData([]);
  };

  const updateDates = (hours, gapThreshold) => {
    var eDate = new Date();
    var sDate = new Date();

    sDate.setHours(eDate.getHours() - hours);
    setEndDate(eDate.valueOf());
    setStartDate(sDate.valueOf());
    setIsZoomed(false);
    setChartData([]);
    setGapThreshold(gapThreshold);
  };

  const updateDurations = {
    0: 2,
    1: 24,
    2: 24 * 7,
    3: 24 * 30,
    4: 24 * 3 * 30,
    5: 24 * 365,
  };


  useEffect(() => {
    if (timeStampSelected in updateDurations) {
      const hours = updateDurations[timeStampSelected];
      const gapThreshold = calculateGapThreshold(hours);
      updateDates(hours, gapThreshold);
      setDateFieldsEnabled(true);
      setCustomQeurySubmission(true);
    } else {
      setDateFieldsEnabled(false);
      setCustomQeurySubmission(false);
    }
  }, [timeStampSelected]);

  useEffect(() => {
    if (currentSensorType && Object.keys(currentSensorType).length > 0) {
      setTempSensorsData(
        sensorReports.map((row) => {
          const formattedCurrent = typeof row.Current === 'number' ? row.Current.toFixed(2) : null;
          const formattedCurrentFourDecimal = typeof row.Current === 'number' ? row.Current.toFixed(4) : null;
  
          return [
            formatDate(new Date(row.Time), "dd-MMM-yyyy - HH:mm"),
            row.State == 0 ? "Normal" : row.State == 1 ? "Warning" : "Alarm",
            formattedCurrent,
            formattedCurrentFourDecimal,
            row.Min,
            row.LowerWarning,
            row.UpperWarning,
            row.Max,
          ];
        })
      );
    }
  }, [currentSensorType]);

  const loadSensorTypes = () => {
    if (sensorTypeSuccess)
      setSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    if (sensorTypeIsError) console.log(sensorTypeError);
  };

  useEffect(() => {
    if (selectedAlarm.length) {
      let tempFilteredReports = sensorReports.filter((report) => {
        let filter = true;
        if (selectedAlarm.length) {
          filter = selectedAlarm.some((item) => {
            return (
              (item === "1" && report.State === 0) ||
              (item === "2" && report.State === 1) ||
              (item === "3" && report.State === 2)
            );
          });
        }
        return filter;
      });
      setTempSensorReports(tempFilteredReports);
    } else {
      setTempSensorReports(deepCopy(sensorReports));
    }
  }, [selectedAlarm]);

  const clearFilter = () => {
    setSelectedAlarm([]);
    setAlarmFilterCount(0);
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const getCsvStringFromArrayOfStrings = (columns, data) => {
    const csvHeader = CSVColumns.map((column) => `"${column.label}"`).join();
    const csvBody = data
      .map((row) => row.map((cell) => (cell !== null ? `"${cell}"` : '""')).join())
      .join("\n");
    return `${csvHeader}\n${csvBody}`;
  };

  const saveCsvStringAsFile = (csvString, fileName) => {
    const url = window.URL.createObjectURL(new Blob([csvString]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName?.replace(" ", "")}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadRowsAsCSV = (rows, columns, fileName) => {
    const csvString = getCsvStringFromArrayOfStrings(columns, rows);
    saveCsvStringAsFile(csvString, fileName);
  };

  useEffect(() => {
    if (download && tempSensorsData.length > 0) {
      downloadRowsAsCSV(tempSensorsData, columns, `${activeSensor?.SensorLabel}-SensorReport`);
    }
    setDownload(false);
  }, [download]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff" }}>
      <Box sx={{ padding: "0 16px" }}>
        <ReportHeader
          timeStamps={timeStamps}
          timeStampSelected={timeStampSelected}
          setTimeStampSelected={setTimeStampSelected}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          startDate={startDate}
          endDate={endDate}
          setCustomQeurySubmission={setCustomQeurySubmission}
          tab={tab}
          setTab={setTab}
          setTableLook={setTableLook}
          tableLook={tableLook}
          sensor={activeSensor}
          isDateFieldsEnabled={dateFieldsEnabled}
          sensorReports={sensorReports}
          handleAlarmSelect={handleAlarmSelect}
          alarmFilterCount={alarmFilterCount}
          selectedAlarm={selectedAlarm}
          clearFilter={clearFilter}
          updateDates={updateDates}
          setDownload={setDownload}
          download={download}
          handleDownloadCSV={handleDownloadCSV}
          handlePrintPDF={handlePrintPDF}
        />
      </Box>
      {loading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: "100px",
              borderRadius: "4px 4px 0 0 ",
            }}
          >
            <Divider />
            <Box sx={{ margin: "16px 0px 0px 0px", padding: "0 16px" }}>
              <ReportDataHeader
                sensorData={currentSensorData}
                sensorType={currentSensorType}
              />
            </Box>
          </Box>
          {tab === 1 ? (
            <Box sx={{ margin: "5px 0" }}>
              <ReportsTable
                tableLook={tableLook}
                sensorReports={tempSensorReports}
                currentSensorType={currentSensorType}
                download={download}
                sensor={activeSensor}
                columns={columns}
              />
            </Box>
          ) : currentSensorType?.sensorType == "Analog" ? (
            <Box>
              {chartData.length > 0 ? (
                <ChartComponent
                  data={data}
                  chartData={chartData}
                  sensorCalculatedData={currentSensorData}
                  date={endDate}
                  setChartData={setChartData}
                  activeSensor={activeSensor}
                  sensorType={currentSensorType}
                  faulted={faulted}
                  warning={warning}
                  chartLinesData={chartLinesData}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setIsZoomed={setIsZoomed}
                  setTimeStampSelected={setTimeStampSelected}
                />
              ) : (
                <Typography
                  variant="caption"
                  sx={{ color: "rgba(0, 0, 0, 0.87)", padding: "0px 16px" }}
                >
                  No data found for the specified range.
                </Typography>
              )}
            </Box>
          ) : (
            <Typography
              variant="caption"
              sx={{ color: "rgba(0, 0, 0, 0.87)", padding: "0px 16px", margin: "auto" }}
            >
              <Typography
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.87)", padding: "0px 16px" }}
              >
                Graph Not Available
              </Typography>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ReportSetup;
