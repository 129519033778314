import React, { useContext, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { mainRoutes, supportRoutes, setupRoutes } from "@Utils/_routes";
import { primary } from "@Styles/theme/colors";
import { DrawerContext } from "@Context/DrawerContext";
import { AlarmContext } from "@Context/AlarmContext";
import logo from "@Assets/images/xil-logo-full-white.png";
import logoFull from "@Assets/images/xil-logo-x-white.png";
import NavButton from "@Components/Buttons/NavButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import CustomMenu from "@Components/BaseComponents/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@Components/Breadcrumbs";
import { logOut, setCurrentCustomer } from "../../Store/Slices/authSlice";
import { useUnSuppressSensorsMutation } from "../../Store/Slices/sensorSuppressSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Control from "../Access/SidebarControl";
import { Link } from "react-router-dom";
import { useFindCustomersQuery } from "../../Store/Slices/customersSlice";
import { Typography } from "@mui/material";
import { useLogoutMutation } from "../../Store/Slices/authApiSlice";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useLiveEquipmentQuery } from "../../Store/Slices/equipmentSlice";
import CustomAvatar from "../Avatar";
import Tabs from "@Components/Tabs";
import { findObjectByValue } from "../../Utils/Tools";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import UserPreferences from "../RightDrawer/UserPreferences";
import Toast from "@Components/BaseComponents/Toast";
import "./style.css";
import Modal from "@mui/material/Modal";
import SearchComponent from "../BaseComponents/Search";
import TableComponent from "../BaseComponents/Table";
import ToolTipComponent from "../BaseComponents/Tooltip";
import { useLogoutFunction } from "@propelauth/react";

const drawerWidth = 240;

const menuItems = {
  1: "warning",
  2: "alarm",
  3: "suppressed",
};

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 560,
  bgcolor: "background.paper",
  borderRadius: "4px",
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const MiniDrawer = () => {
  const [searchText, setSearchText] = useState("");
  const [preferenceDrawer, setPreferenceDrawer] = useState(false);
  const [displayTenantToast, setDisplayTenantToast] = useState(false);
  const [updateUserResponce, setUpdateUserResponce] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { open } = useContext(DrawerContext);
  const { alarmPanelToggle, setAlarmPanelToggle } = useContext(AlarmContext);
  const dispatch = useDispatch();
  const logoutFunction = useLogoutFunction();
  const theme = useTheme();

  const [logout, { isLoading }] = useLogoutMutation();

  const {
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
    requestId,
  } = useLiveEquipmentQuery();

  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();

  const [
    unSuppressSensors,
    { isSuccess: unSuppressSensorsSuccess, isError: unSuppressSensorsError },
  ] = useUnSuppressSensorsMutation();

  useEffect(() => {
    if (updateUserResponce == true) {
      setTimeout(() => {
        setUpdateUserResponce(false);
      }, 2000);
    }
  }, [updateUserResponce]);

  let tempLiveEquipments = equipmentData?.success?.data
    .map((val) => {
      let filteredSensors = val.Sensors.filter((sensor) => sensor.LiveData && sensor.IsActive).sort(
        (a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
      );
      let sensorStates = Array.from(
        new Set(
          filteredSensors.map((sensor) => (sensor.LiveData.Suppressed ? 3 : sensor.LiveData.State))
        )
      );
      return {
        EquipmentID: val.EquipmentID,
        EquipmentName: val.EquipmentName,
        EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
        EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
        LocationName: val.Location?.LocationName,
        LocationID: val.LocationID,
        CustomerID: val.Location?.Customer.CustomerID,
        CustomerName: val.Location?.Customer.CustomerName,
        updatedAt: val.updatedAt,
        Sensors: filteredSensors,
        MenuOptions: sensorStates.map((state) => menuItems[state]),
        equipmentSensorTypes: val.equipmentSensorTypes,
      };
    })
    .filter((equipmentData) => equipmentData.Sensors.length > 0);

  const alarmEquipments = tempLiveEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) => sensor?.LiveData?.State === 2 && !sensor?.LiveData?.Suppressed
    )
  );

  const warningEquipments = tempLiveEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) => sensor?.LiveData?.State === 1 && !sensor?.LiveData?.Suppressed
    )
  );

  const suppressedEquipments = tempLiveEquipments?.filter((equipment) =>
    equipment?.Sensors.some((sensor) => sensor?.LiveData?.Suppressed)
  );
  const UnAcknowlegedEquipments = tempLiveEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) =>
        sensor?.LiveData?.State === 4 &&
        !sensor?.LiveData?.Acknowledged &&
        !sensor?.LiveData.Suppressed
    )
  );

  // conditionally post to unsuppress endpoint with all sensors that have expired suppression time
  useEffect(() => {
    const sensorsWithExpiredSuppression = equipmentData?.success?.data
      ?.map((equipment) => {
        const currentTimeMs = Date.now();
        return equipment.Sensors.filter(
          (sensor) =>
            sensor?.LiveData?.Suppressed !== null && sensor?.LiveData?.Suppressed < currentTimeMs
        ).map((sensor) => sensor.SensorID);
      })
      .flat();

    const postUnsuppressSensors = async () => {
      await unSuppressSensors({ SensorIDs: sensorsWithExpiredSuppression });
    };

    if (sensorsWithExpiredSuppression?.length) {
      postUnsuppressSensors();
    }
  }, [requestId]);

  useEffect(() => {
    loadCustomers();
  }, [customersData]);

  useEffect(() => {
    if (!displayTenantToast) return;
    const timeout = setTimeout(() => {
      setDisplayTenantToast(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [displayTenantToast]);

  useEffect(() => {
    const customerID = findObjectByValue(customers, "value", auth.SelectedCustomer)?.value;
    if (customerID) {
      localStorage.setItem("customerID", customerID);
    }
  }, [customers]);
  const menuItemPadding = open ? "auto" : 1;
  const isAdmin = auth.isAdmin;
  let FirstName_LastName = `${auth.FirstName} ${auth.LastName}`;
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const toggleDrawer = (open) => {
    handleMenuClose();
    setPreferenceDrawer(open);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleAlarmToggle = () => {
    setAlarmPanelToggle(!alarmPanelToggle);
  };

  const handleCustomerChange = async (id) => {
    localStorage.setItem("customerID", id);
    const res = await dispatch(setCurrentCustomer({ auth: auth, CustomerID: id }));
    if (res) {
      handleClose();
      navigate("/statusview");
      setDisplayTenantToast(true);
      setSearchText("");
      if (alarmPanelToggle) handleAlarmToggle();
    }
  };

  const loadCustomers = () => {
    if (customersSuccess)
      setCustomers(
        customersData.map((c) => {
          return {
            value: c.CustomerID,
            label: c.CustomerName,
          };
        })
      );
    if (customersIsError) console.log(customersError);
  };

  const handleLogout = async () => {
    const data = {
      UserID: auth.UserID,
      CustomerID: auth.CustomerID,
      UserName: auth.user,
    };

    await logout(data);
    dispatch(logOut());
    logoutFunction(true);
  };

  const filterTenants = (tenants) => {
    if (searchText) {
      return tenants.filter((obj) => obj.label.toLowerCase().includes(searchText.toLowerCase()));
    }
    return tenants;
  };

  const columns = [
    {
      name: "TenantID",
      label: "TenantID",
      options: {
        display: false,
        download: true,
        filter: false,
        searchable: false,
        sort: false,
      },
    },

    {
      name: "TenantName",
      label: "Tenant name",
      options: {
        searchable: true,
        sort: true,
        paddingLeft: "16px",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              onClick={() => handleCustomerChange(value.TenantID)}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                lineHeight: "19px",
                fontWeight: "500",
                color: "#222934",
                fontFamily: "Roboto",
              }}
            >
              {value.TenantName}
            </div>
          );
        },
        sortCompare:
          (order) =>
          ({ data: userListOne }, { data: userListTwo }) => {
            var valueOne = userListOne.TenantName.toUpperCase();
            var valueTwo = userListTwo.TenantName.toUpperCase();
            return order == "asc"
              ? valueOne < valueTwo
                ? -1
                : valueOne > valueTwo
                ? 1
                : 0
              : valueOne < valueTwo
              ? 1
              : valueOne > valueTwo
              ? -1
              : 0;
          },
      },
    },
  ];

  const customOptions = {
    download: false,
    selectableRows: false,
    expandableRows: false,
    sortOrder: {
      name: "TenantName",
      direction: "asc",
    },
    customSearch: (searchQuery, currentRow, columns) => {
      if (currentRow[1].TenantName.toLowerCase().includes(searchQuery.toLowerCase())) return true;
      return false;
    },
  };

  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: -0.1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box>
        <MenuItem
          onClick={() => toggleDrawer(true)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "6em", display: "flex" }}>
            Preferences<div>&nbsp;&nbsp;</div>
          </div>
          <i class="fa-light fa-angle-right"></i>
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to="/"
        >
          <div style={{ width: "6em", display: "flex" }}>
            Shortcuts<div>&nbsp;&nbsp;</div>
          </div>
          <i class="fa-light fa-angle-right"></i>
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          <div style={{ width: "6em", display: "flex" }}>
            Sign out<div>&nbsp;&nbsp;</div>
          </div>
          <i className="fa-light fa-right-from-bracket"></i>
        </MenuItem>
      </Box>
    </CustomMenu>
  );

  const renderMobileMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        component={Link}
        to="/myaccount"
      >
        My Account
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        Sign out<div>&nbsp;&nbsp;</div>
        <i className="fa-light fa-right-from-bracket"></i>
      </MenuItem>
    </CustomMenu>
  );

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const CustomToolbar = (
    <Toolbar sx={{ ml: open ? 0 : 5 }}>
      <Stack spacing={2}>
        <Breadcrumbs />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}>
        <ThemeProvider
          theme={createTheme({
            components: {
              MuiIconButton: {
                styleOverrides: {
                  root: {
                    borderRadius: "100",
                  },
                },
              },
            },
          })}
        >
          {alarmEquipments?.length && (
            <ToolTipComponent
              title="Equipment in alarm state"
              Component={
                <Box
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: STATUS_COLOR_CODE_MAP.error,
                      color: "white",
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                      "&:hover": { backgroundColor: STATUS_COLOR_CODE_MAP.error },
                    }}
                    onClick={handleAlarmToggle}
                    size="large"
                    aria-label="show 4 new mails"
                  >
                    <Badge
                      badgeContent={alarmEquipments.length}
                      color="error"
                    >
                      <i className="fa-solid fa-triangle-exclamation"></i>
                    </Badge>
                  </IconButton>
                </Box>
              }
            />
          )}
          {!alarmEquipments?.length && warningEquipments?.length && (
            <ToolTipComponent
              title="Equipment in warning state"
              Component={
                <Box
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "4px",
                      backgroundColor: STATUS_COLOR_CODE_MAP.warning,
                      color: "white",
                      width: "40px",
                      height: "40px",
                      marginRight: "8px",
                      "&:hover": { backgroundColor: STATUS_COLOR_CODE_MAP.warning },
                    }}
                    onClick={warningEquipments?.length != 0 && handleAlarmToggle}
                    size="large"
                    aria-label="show 4 new mails"
                  >
                    <Badge
                      badgeContent={warningEquipments.length}
                      color="warning"
                    >
                      <i className="fa-solid fa-triangle-exclamation"></i>
                    </Badge>
                  </IconButton>
                </Box>
              }
            />
          )}

          {!alarmEquipments?.length &&
            !warningEquipments?.length &&
            UnAcknowlegedEquipments?.length && (
              <ToolTipComponent
                title="Equipment in unacknowledged state"
                Component={
                  <Box
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: STATUS_COLOR_CODE_MAP.prior,
                        color: "white",
                        width: "40px",
                        height: "40px",
                        marginRight: "8px",
                        "&:hover": { backgroundColor: STATUS_COLOR_CODE_MAP.prior },
                      }}
                      onClick={handleAlarmToggle}
                      size="large"
                      aria-label="show 4 new mails"
                    >
                      <Badge
                        badgeContent={UnAcknowlegedEquipments.length}
                        color="error"
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </Badge>
                    </IconButton>
                  </Box>
                }
              />
            )}
          {!alarmEquipments?.length &&
            !warningEquipments?.length &&
            !UnAcknowlegedEquipments?.length &&
            suppressedEquipments?.length && (
              <ToolTipComponent
                title="Equipment in Supressed state"
                Component={
                  <Box
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: STATUS_COLOR_CODE_MAP.suppressed,
                        color: "white",
                        width: "40px",
                        height: "40px",
                        marginRight: "8px",
                        "&:hover": { backgroundColor: STATUS_COLOR_CODE_MAP.suppressed },
                      }}
                      onClick={handleAlarmToggle}
                      size="large"
                      aria-label="show 4 new mails"
                    >
                      <Badge
                        badgeContent={suppressedEquipments.length}
                        color="error"
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </Badge>
                    </IconButton>
                  </Box>
                }
              />
            )}
          {!warningEquipments?.length &&
            !alarmEquipments?.length &&
            !UnAcknowlegedEquipments?.length &&
            !suppressedEquipments?.length && (
              <ToolTipComponent
                title="No active alarms"
                Component={
                  <Box
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: STATUS_COLOR_CODE_MAP.success,
                        color: "white",
                        width: "40px",
                        height: "40px",
                        marginRight: "8px",
                        "&:hover": { backgroundColor: STATUS_COLOR_CODE_MAP.success },
                      }}
                      size="large"
                      aria-label="show 4 new mails"
                    >
                      <i className="fa-solid fa-triangle-exclamation"></i>
                    </IconButton>
                  </Box>
                }
              />
            )}
        </ThemeProvider>

        {isAdmin && (
          <div
            onClick={() => handleOpen()}
            style={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              padding: "0px 5px",
              marginRight: "8px",
            }}
            className="tenantSelectionMenuButton"
          >
            <p
              style={{
                lineHeight: "21px",
                textAlign: "left",
                fontSize: "16px",
                color: "#222934",
              }}
            >
              {findObjectByValue(customers, "value", auth.SelectedCustomer)?.label || "All"}
            </p>

            <i
              className="fa-light fa-buildings"
              sx={{ backgroundColor: theme.palette.lightGrey["900"] }}
            ></i>
          </div>
        )}
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          sx={{
            borderRadius: "4px",
            padding: "0px",
            width: "40px",
            height: "40px",
            "&:hover": { bgcolor: "#E0F5FD" },
          }}
        >
          <CustomAvatar
            sx={{
              bgcolor: "#7151A1",
              width: "32px",
              height: "32px",
              fontSize: "14px",
            }}
            name={FirstName_LastName}
            src={
              auth.ProfileImage
                ? `${process.env.REACT_APP_URL}/asset/download/${auth.ProfileImage}`
                : null
            }
          />
        </IconButton>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="lightGrey"
        >
          <MoreIcon />
        </IconButton>
      </Box>
    </Toolbar>
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {displayTenantToast && (
          <Toast
            message={`You are now viewing ${
              findObjectByValue(customers, "value", auth.SelectedCustomer)?.label || "All"
            }`}
            severity="success"
          />
        )}
        {updateUserResponce && (
          <Toast
            message="User preferences successfully updated"
            severity="success"
          />
        )}
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ backgroundColor: theme.palette.white, padding: 0 }}
          open={open}
        >
          {CustomToolbar}
          {window?.location?.pathname.includes("setup") && (
            <Box sx={{ ml: open ? 0 : 8 }}>
              <Tabs />
            </Box>
          )}
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          color="primary"
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              zIndex: theme.zIndex.drawer + 1,
              backgroundColor: theme.palette.primary["800"],
              color: primary["100"],
            },
          }}
        >
          <DrawerHeader sx={{ mr: open ? 11.1 : 0, mt: 1 }}>
            {open ? (
              <img
                src={logo}
                alt="Xiltrix"
                width={131}
                height={48}
              />
            ) : (
              <img
                src={logoFull}
                alt="Xiltrix"
                width={48}
                height={48}
              />
            )}
          </DrawerHeader>
          <MenuList>
            {mainRoutes.map((route, key) => {
              return (
                <Control
                  key={key}
                  authorization={Object.keys(auth.Authorizations)}
                  pageName={route.pageName}
                  yes={() => (
                    <Tooltip
                      title={route.sidebarName}
                      placement="right"
                    >
                      <StyledMenuItem
                        sx={{ pl: menuItemPadding }}
                        open={open}
                        disableRipple={true}
                        disableTouchRipple={true}
                      >
                        <NavButton
                          route={route}
                          open={open}
                        />
                      </StyledMenuItem>
                    </Tooltip>
                  )}
                />
              );
            })}
          </MenuList>
          <Box sx={{ flexGrow: 1 }} />
          <MenuList>
            {setupRoutes.map((route, key) => (
              <Control
                key={key}
                authorization={Object.keys(auth.Authorizations)}
                pageName={route.pageName}
                yes={() => (
                  <Tooltip
                    title={route.sidebarName}
                    placement="right"
                  >
                    <StyledMenuItem
                      sx={{ pl: menuItemPadding }}
                      disableRipple={true}
                      disableTouchRipple={true}
                    >
                      <NavButton
                        route={route}
                        open={open}
                      />
                    </StyledMenuItem>
                  </Tooltip>
                )}
              />
            ))}

            {supportRoutes.map((route, key) => (
              <Tooltip
                key={key}
                title={route.sidebarName}
                placement="right"
              >
                <StyledMenuItem
                  sx={{ pl: menuItemPadding }}
                  disableRipple={true}
                  disableTouchRipple={true}
                >
                  <NavButton
                    route={route}
                    open={open}
                  />
                </StyledMenuItem>
              </Tooltip>
            ))}
            <Tooltip
              title={open ? "Collapse" : "Expand"}
              placement="right"
            >
              <StyledMenuItem
                sx={{ pl: menuItemPadding, width: "50px" }}
                disableRipple={true}
                disableTouchRipple={true}
              >
                <NavButton />
              </StyledMenuItem>
            </Tooltip>
          </MenuList>
        </Drawer>
        <UserPreferences
          updateUserResponce={updateUserResponce}
          setUpdateUserResponce={setUpdateUserResponce}
          drawer={preferenceDrawer}
          toggleDrawer={setPreferenceDrawer}
          handleMenuClose={handleMenuClose}
        />
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles}>
          <Box
            sx={{
              padding: "24px 24px 24px 24px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "34px",
                color: "#222934",
                textAlign: "left",
                fontWeight: "medium",
              }}
            >
              Select tenant
            </Typography>
            <i
              className="fa-regular fa-xmark fa-lg"
              style={{
                marginRight: "10px",
                width: "20px",
                height: "32px",
                color: "#9CA1A6",
                fontSize: "32px",
                cursor: "pointer",
                lineHeight: "38px",
              }}
              onClick={(e) => handleClose(e)}
            ></i>
          </Box>

          <Divider />
          <Box sx={{ padding: "24px 24px" }}>
            <Box sx={{ paddingBottom: "20px " }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "34px",
                  color: "#222934",
                  textAlign: "left",
                }}
              >
                Tenant list
              </Typography>
              <SearchComponent
                width="100%"
                padding={"10px 0px 0px 0px"}
                placeholder="Search tenants"
                searchText={searchText}
                setSearchText={setSearchText}
              />
            </Box>

            <TableComponent
              cellPaddingLeft="18px"
              height="200px"
              boxShadow="none"
              mt="0px"
              tableLook="default"
              rowheight="40px"
              customOptions={customOptions}
              tableBodyMaxHeight="200px"
              columns={columns}
              CellPaddingLeft="24px"
              hideToolbar={true}
              searchText={searchText}
              data={filterTenants(customers)?.map((customer) => {
                return [customer.value, { TenantID: customer.value, TenantName: customer.label }];
              })}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MiniDrawer;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "&:hover": { backgroundColor: theme.palette.primary["800"] },
  "&:active": { backgroundColor: theme.palette.primary["800"] },
  pl: open ? "auto" : 1,
}));
