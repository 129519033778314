import { orange, error, success, purple, lightGrey, yellow } from "../Styles/theme/colors";

const breakPoints = {
  sm : 768
}
const notAllowedRoles = [
  "22b8577f-aee4-49d9-83a1-b33b0e34a8ba",
  "3b5dfa74-47ed-4b40-b4cd-a00785e6cae1",
  "81c4a6a5-9e03-4f41-bcd4-ae0db4f24e74",
];

const tabStyles = {
  color: "#9CA1A6",
  fontSize: "16px",
  fontWeight: "500",
  borderBottom: "0.5px solid #9CA1A6",
  "&:hover": { bgcolor: "#E3E9EC", color: "#17455E" },
  "&:focus": {
    border: "2px solid #00AEEF",
    bgcolor: "#E3E9EC",
    color: "#17455E",
  },
  "&:active": { backgroundColor: "#E3E9EC", color: "#17455E" },
  "&.Mui-selected": {
    color: "#17455E",
  },
};

/** array to filter Equipment Types, this array should be removed after adding delete equipment types delete logic */
const AuthorizedEquipmentTypes = [
  "Freezer",
  "Fridge",
  "Cryogenic tank / Dewar",
  "Incubator",
  "Centrifuge",
  "Bioreactor",
  "Flow Hood",
  "Room",
];

const limitDelayMappings = {
  LowLimitWarning: ["llw", "LowDelayWarning", "ldw"],
  HighLimitWarning: ["ulw", "HighDelayWarning", "hdw"],
  LowLimitAlarm: ["lln", "LowDelayAlarm", "lda"],
  HighLimitAlarm: ["uln", "HighDelayAlarm", "hda"],
  InputState: ["s", "d", "Delay"],
};

const Languages = [
  { value: "SQ", label: "Albanian" },
  { value: "AF", label: "Afrikaans" },
  { value: "AR", label: "Arabic" },
  { value: "HY", label: "Armenian" },
  { value: "EU", label: "Basque" },
  { value: "BN", label: "Bengali" },
  { value: "BG", label: "Bulgarian" },
  { value: "CA", label: "Catalan" },
  { value: "KM", label: "Cambodian" },
  { value: "ZH", label: "Chinese (Mandarin)" },
  { value: "HR", label: "Croatian" },
  { value: "CS", label: "Czech" },
  { value: "DA", label: "Danish" },
  { value: "NL", label: "Dutch" },
  { value: "EN", label: "English" },
  { value: "ET", label: "Estonian" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finnish" },
  { value: "FR", label: "French" },
  { value: "KA", label: "Georgian" },
  { value: "DE", label: "German" },
  { value: "EL", label: "Greek" },
  { value: "GU", label: "Gujarati" },
  { value: "HE", label: "Hebrew" },
  { value: "HI", label: "Hindi" },
  { value: "HU", label: "Hungarian" },
  { value: "IS", label: "Icelandic" },
  { value: "ID", label: "Indonesian" },
  { value: "GA", label: "Irish" },
  { value: "IT", label: "Italian" },
  { value: "JA", label: "Japanese" },
  { value: "JW", label: "Javanese" },
  { value: "KO", label: "Korean" },
  { value: "LA", label: "Latin" },
  { value: "LV", label: "Latvian" },
  { value: "LT", label: "Lithuanian" },
  { value: "MK", label: "Macedonian" },
  { value: "MS", label: "Malay" },
  { value: "ML", label: "Malayalam" },
  { value: "MT", label: "Maltese" },
  { value: "MI", label: "Maori" },
  { value: "MR", label: "Marathi" },
  { value: "MN", label: "Mongolian" },
  { value: "NE", label: "Nepali" },
  { value: "NO", label: "Norwegian" },
  { value: "FA", label: "Persian" },
  { value: "PL", label: "Polish" },
  { value: "PT", label: "Portuguese" },
  { value: "PA", label: "Punjabi" },
  { value: "QU", label: "Quechua" },
  { value: "RO", label: "Romanian" },
  { value: "RU", label: "Russian" },
  { value: "SM", label: "Samoan" },
  { value: "SR", label: "Serbian" },
  { value: "SK", label: "Slovak" },
  { value: "SL", label: "Slovenian" },
  { value: "ES", label: "Spanish" },
  { value: "SW", label: "Swahili" },
  { value: "SV", label: "Swedish " },
  { value: "TA", label: "Tamil" },
  { value: "TT", label: "Tatar" },
  { value: "TE", label: "Telugu" },
  { value: "TH", label: "Thai" },
  { value: "BO", label: "Tibetan" },
  { value: "TO", label: "Tonga" },
  { value: "TR", label: "Turkish" },
  { value: "UK", label: "Ukrainian" },
  { value: "UR", label: "Urdu" },
  { value: "UZ", label: "Uzbek" },
  { value: "VI", label: "Vietnamese" },
  { value: "CY", label: "Welsh" },
  { value: "XH", label: "Xhosa" },
];

const UTCTimeZones = [
  { value: "Pacific/Niue", label: "(UTC-11:00) Niue" },
  { value: "Pacific/Pago_Pago", label: "(UTC-11:00) Pago Pago" },
  { value: "Pacific/Honolulu", label: "(UTC-10:00) Honolulu" },
  { value: "Pacific/Rarotonga", label: "(UTC-10:00) Rarotonga" },
  { value: "Pacific/Tahiti", label: "(UTC-10:00) Tahiti" },
  { value: "Pacific/Marquesas", label: "(UTC-09:30) Marquesas" },
  { value: "America/Anchorage", label: "(UTC-09:00) Alaska Time" },
  { value: "Pacific/Gambier", label: "(UTC-09:00) Gambier" },
  { value: "America/Los_Angeles", label: "(UTC-08:00) Los Angeles" },
  { value: "America/Tijuana", label: "(UTC-08:00) Tijuana" },
  { value: "America/Vancouver", label: "(UTC-08:00) Vancouver" },
  {
    value: "America/Whitehorse",
    label: "(UTC-08:00) Whitehorse",
  },
  { value: "Pacific/Pitcairn", label: "(UTC-08:00) Pitcairn" },
  {
    value: "America/Dawson_Creek",
    label: "(UTC-07:00) Dawson Creek",
  },
  { value: "America/Denver", label: "(UTC-07:00) Denver" },
  { value: "America/Edmonton", label: "(UTC-07:00) Edmonton" },
  {
    value: "America/Hermosillo",
    label: "(UTC-07:00) Hermosillo",
  },
  {
    value: "America/Mazatlan",
    label: "(UTC-07:00) Chihuahua, Mazatlan",
  },
  { value: "America/Phoenix", label: "(UTC-07:00) Phoenix, Arizona" },
  {
    value: "America/Yellowknife",
    label: "(UTC-07:00) Yellowknife",
  },
  { value: "America/Belize", label: "(UTC-06:00) Belize" },
  { value: "America/Chicago", label: "(UTC-06:00) Chicago" },
  { value: "America/Costa_Rica", label: "(UTC-06:00) Costa Rica" },
  { value: "America/El_Salvador", label: "(UTC-06:00) El Salvador" },
  { value: "America/Guatemala", label: "(UTC-06:00) Guatemala" },
  { value: "America/Managua", label: "(UTC-06:00) Managua" },
  {
    value: "America/Mexico_City",
    label: "(UTC-06:00) Mexico City",
  },
  { value: "America/Regina", label: "(UTC-06:00) Regina" },
  {
    value: "America/Tegucigalpa",
    label: "(UTC-06:00) Tegucigalpa",
  },
  { value: "America/Winnipeg", label: "(UTC-06:00) Winnipeg" },
  { value: "Pacific/Galapagos", label: "(UTC-06:00) Galapagos" },
  { value: "America/Bogota", label: "(UTC-05:00) Bogota" },
  { value: "America/Cancun", label: "(UTC-05:00) America Cancun" },
  { value: "America/Cayman", label: "(UTC-05:00) Cayman" },
  { value: "America/Guayaquil", label: "(UTC-05:00) Guayaquil" },
  { value: "America/Havana", label: "(UTC-05:00) Havana" },
  { value: "America/Iqaluit", label: "(UTC-05:00) Iqaluit" },
  { value: "America/Jamaica", label: "(UTC-05:00) Jamaica" },
  { value: "America/Lima", label: "(UTC-05:00) Lima" },
  { value: "America/Nassau", label: "(UTC-05:00) Nassau" },
  { value: "America/New_York", label: "(UTC-05:00) Eastern Time" },
  { value: "America/Panama", label: "(UTC-05:00) Panama" },
  { value: "America/Port-au-Prince", label: "(UTC-05:00) Port-au-Prince" },
  { value: "America/Rio_Branco", label: "(UTC-05:00) Rio Branco" },
  { value: "America/Toronto", label: "(UTC-05:00) Toronto" },
  { value: "Pacific/Easter", label: "(UTC-05:00) Easter Island" },
  { value: "America/Caracas", label: "(UTC-04:30) Caracas" },
  { value: "America/Asuncion", label: "(UTC-03:00) Asuncion" },
  { value: "America/Barbados", label: "(UTC-04:00) Barbados" },
  { value: "America/Boa_Vista", label: "(UTC-04:00) Boa Vista" },
  { value: "America/Campo_Grande", label: "(UTC-03:00) Campo Grande" },
  { value: "America/Cuiaba", label: "(UTC-03:00) Cuiaba" },
  { value: "America/Curacao", label: "(UTC-04:00) Curacao" },
  { value: "America/Grand_Turk", label: "(UTC-04:00) Grand Turk" },
  { value: "America/Guyana", label: "(UTC-04:00) Guyana" },
  { value: "America/Halifax", label: "(UTC-04:00) Halifax" },
  { value: "America/La_Paz", label: "(UTC-04:00) La Paz" },
  { value: "America/Manaus", label: "(UTC-04:00) Manaus" },
  { value: "America/Martinique", label: "(UTC-04:00) Martinique" },
  { value: "America/Port_of_Spain", label: "(UTC-04:00) Port of Spain" },
  { value: "America/Porto_Velho", label: "(UTC-04:00) Porto Velho" },
  { value: "America/Puerto_Rico", label: "(UTC-04:00) Puerto Rico" },
  { value: "America/Santo_Domingo", label: "(UTC-04:00) Santo Domingo" },
  { value: "America/Thule", label: "(UTC-04:00) Thule" },
  { value: "Atlantic/Bermuda", label: "(UTC-04:00) Bermuda" },
  {
    value: "America/St_Johns",
    label: "(UTC-03:30) Newfoundland Time - St. Johns",
  },
  { value: "America/Araguaina", label: "(UTC-03:00) Araguaina" },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "(UTC-03:00) Buenos Aires",
  },
  { value: "America/Bahia", label: "(UTC-03:00) Salvador" },
  { value: "America/Belem", label: "(UTC-03:00) Belem" },
  { value: "America/Cayenne", label: "(UTC-03:00) Cayenne" },
  { value: "America/Fortaleza", label: "(UTC-03:00) Fortaleza" },
  { value: "America/Godthab", label: "(UTC-03:00) Godthab" },
  { value: "America/Maceio", label: "(UTC-03:00) Maceio" },
  { value: "America/Miquelon", label: "(UTC-03:00) Miquelon" },
  { value: "America/Montevideo", label: "(UTC-03:00) Montevideo" },
  { value: "America/Paramaribo", label: "(UTC-03:00) Paramaribo" },
  { value: "America/Recife", label: "(UTC-03:00) Recife" },
  { value: "America/Santiago", label: "(UTC-03:00) Santiago" },
  { value: "America/Sao_Paulo", label: "(UTC-02:00) Sao Paulo" },
  { value: "Antarctica/Palmer", label: "(UTC-03:00) Palmer" },
  { value: "Antarctica/Rothera", label: "(UTC-03:00) Rothera" },
  { value: "Atlantic/Stanley", label: "(UTC-03:00) Stanley" },
  { value: "America/Noronha", label: "(UTC-02:00) Noronha" },
  { value: "Atlantic/South_Georgia", label: "(UTC-02:00) South Georgia" },
  { value: "America/Scoresbysund", label: "(UTC-01:00) Scoresbysund" },
  { value: "Atlantic/Azores", label: "(UTC-01:00) Azores" },
  { value: "Atlantic/Cape_Verde", label: "(UTC-01:00) Cape Verde" },
  { value: "Africa/Abidjan", label: "(UTC+00:00) Abidjan" },
  { value: "Africa/Accra", label: "(UTC+00:00) Accra" },
  { value: "Africa/Bissau", label: "(UTC+00:00) Bissau" },
  { value: "Africa/Casablanca", label: "(UTC+00:00) Casablanca" },
  { value: "Africa/El_Aaiun", label: "(UTC+00:00) El Aaiun" },
  { value: "Africa/Monrovia", label: "(UTC+00:00) Monrovia" },
  { value: "America/Danmarkshavn", label: "(UTC+00:00) Danmarkshavn" },
  { value: "Atlantic/Canary", label: "(UTC+00:00) Canary Islands" },
  { value: "Atlantic/Faroe", label: "(UTC+00:00) Faeroe" },
  { value: "Atlantic/Reykjavik", label: "(UTC+00:00) Reykjavik" },
  { value: "Etc/UTC", label: "(UTC+00:00) UTC (no daylight saving)" },
  { value: "Europe/Dublin", label: "(UTC+00:00) Dublin" },
  { value: "Europe/Lisbon", label: "(UTC+00:00) Lisbon" },
  { value: "Europe/London", label: "(UTC+00:00) London" },
  { value: "Africa/Algiers", label: "(UTC+01:00) Algiers" },
  { value: "Africa/Ceuta", label: "(UTC+01:00) Ceuta" },
  { value: "Africa/Lagos", label: "(UTC+01:00) Lagos" },
  { value: "Africa/Ndjamena", label: "(UTC+01:00) Ndjamena" },
  { value: "Africa/Tunis", label: "(UTC+01:00) Tunis" },
  { value: "Africa/Windhoek", label: "(UTC+02:00) Windhoek" },
  { value: "Europe/Amsterdam", label: "(UTC+01:00) Amsterdam" },
  { value: "Europe/Andorra", label: "(UTC+01:00) Andorra" },
  {
    value: "Europe/Belgrade",
    label: "(UTC+01:00) Belgrade",
  },
  { value: "Europe/Berlin", label: "(UTC+01:00) Berlin" },
  { value: "Europe/Brussels", label: "(UTC+01:00) Brussels" },
  { value: "Europe/Budapest", label: "(UTC+01:00) Budapest" },
  { value: "Europe/Copenhagen", label: "(UTC+01:00) Copenhagen" },
  { value: "Europe/Gibraltar", label: "(UTC+01:00) Gibraltar" },
  { value: "Europe/Luxembourg", label: "(UTC+01:00) Luxembourg" },
  { value: "Europe/Madrid", label: "(UTC+01:00) Madrid" },
  { value: "Europe/Malta", label: "(UTC+01:00) Malta" },
  { value: "Europe/Monaco", label: "(UTC+01:00) Monaco" },
  { value: "Europe/Oslo", label: "(UTC+01:00) Oslo" },
  { value: "Europe/Paris", label: "(UTC+01:00) Paris" },
  {
    value: "Europe/Prague",
    label: "(UTC+01:00) Prague",
  },
  { value: "Europe/Rome", label: "(UTC+01:00) Rome" },
  { value: "Europe/Stockholm", label: "(UTC+01:00) Stockholm" },
  { value: "Europe/Tirane", label: "(UTC+01:00) Tirane" },
  { value: "Europe/Vienna", label: "(UTC+01:00) Vienna" },
  { value: "Europe/Warsaw", label: "(UTC+01:00) Warsaw" },
  { value: "Europe/Zurich", label: "(UTC+01:00) Zurich" },
  { value: "Africa/Cairo", label: "(UTC+02:00) Cairo" },
  { value: "Africa/Johannesburg", label: "(UTC+02:00) Johannesburg" },
  { value: "Africa/Maputo", label: "(UTC+02:00) Maputo" },
  { value: "Africa/Tripoli", label: "(UTC+02:00) Tripoli" },
  { value: "Asia/Amman", label: "(UTC+02:00) Amman" },
  { value: "Asia/Beirut", label: "(UTC+02:00) Beirut" },
  { value: "Asia/Damascus", label: "(UTC+02:00) Damascus" },
  { value: "Asia/Gaza", label: "(UTC+02:00) Gaza" },
  { value: "Asia/Jerusalem", label: "(UTC+02:00) Jerusalem" },
  { value: "Asia/Nicosia", label: "(UTC+02:00) Nicosia" },
  { value: "Europe/Athens", label: "(UTC+02:00) Athens" },
  { value: "Europe/Bucharest", label: "(UTC+02:00) Bucharest" },
  { value: "Europe/Chisinau", label: "(UTC+02:00) Chisinau" },
  { value: "Europe/Helsinki", label: "(UTC+02:00) Helsinki" },
  { value: "Europe/Istanbul", label: "(UTC+02:00) Istanbul" },
  { value: "Europe/Kaliningrad", label: "(UTC+02:00) Kaliningrad" },
  { value: "Europe/Kiev", label: "(UTC+02:00) Kiev" },
  { value: "Europe/Riga", label: "(UTC+02:00) Riga" },
  { value: "Europe/Sofia", label: "(UTC+02:00) Sofia" },
  { value: "Europe/Tallinn", label: "(UTC+02:00) Tallinn" },
  { value: "Europe/Vilnius", label: "(UTC+02:00) Vilnius" },
  { value: "Africa/Khartoum", label: "(UTC+03:00) Khartoum" },
  { value: "Africa/Nairobi", label: "(UTC+03:00) Nairobi" },
  { value: "Antarctica/Syowa", label: "(UTC+03:00) Syowa" },
  { value: "Asia/Baghdad", label: "(UTC+03:00) Baghdad" },
  { value: "Asia/Qatar", label: "(UTC+03:00) Qatar" },
  { value: "Asia/Riyadh", label: "(UTC+03:00) Riyadh" },
  { value: "Europe/Minsk", label: "(UTC+03:00) Minsk" },
  { value: "Europe/Moscow", label: "(UTC+03:00) Moscow" },
  { value: "Asia/Tehran", label: "(UTC+03:30) Tehran" },
  { value: "Asia/Baku", label: "(UTC+04:00) Baku" },
  { value: "Asia/Dubai", label: "(UTC+04:00) Dubai" },
  { value: "Asia/Tbilisi", label: "(UTC+04:00) Tbilisi" },
  { value: "Asia/Yerevan", label: "(UTC+04:00) Yerevan" },
  { value: "Europe/Samara", label: "(UTC+04:00) Samara" },
  { value: "Indian/Mahe", label: "(UTC+04:00) Mahe" },
  { value: "Indian/Mauritius", label: "(UTC+04:00) Mauritius" },
  { value: "Indian/Reunion", label: "(UTC+04:00) Reunion" },
  { value: "Asia/Kabul", label: "(UTC+04:30) Kabul" },
  { value: "Antarctica/Mawson", label: "(UTC+05:00) Mawson" },
  { value: "Asia/Aqtau", label: "(UTC+05:00) Aqtau" },
  { value: "Asia/Aqtobe", label: "(UTC+05:00) Aqtobe" },
  { value: "Asia/Ashgabat", label: "(UTC+05:00) Ashgabat" },
  { value: "Asia/Dushanbe", label: "(UTC+05:00) Dushanbe" },
  { value: "Asia/Karachi", label: "(UTC+05:00) Karachi, Islamabad" },
  { value: "Asia/Tashkent", label: "(UTC+05:00) Tashkent" },
  {
    value: "Asia/Yekaterinburg",
    label: "(UTC+05:00) Yekaterinburg",
  },
  { value: "Indian/Kerguelen", label: "(UTC+05:00) Kerguelen" },
  { value: "Indian/Maldives", label: "(UTC+05:00) Maldives" },
  {
    value: "Asia/Kolkata",
    label: "(UTC+05:30) Kolkata, Chennai, Mumbai, New Delhi",
  },
  { value: "Asia/Colombo", label: "(UTC+05:30) Colombo" },
  { value: "Asia/Katmandu", label: "(UTC+05:45) Katmandu" },
  { value: "Antarctica/Vostok", label: "(UTC+06:00) Vostok" },
  { value: "Asia/Almaty", label: "(UTC+06:00) Almaty" },
  { value: "Asia/Bishkek", label: "(UTC+06:00) Bishkek" },
  { value: "Asia/Dhaka", label: "(UTC+06:00) Dhaka" },
  { value: "Asia/Omsk", label: "(UTC+06:00) Omsk, Novosibirsk" },
  { value: "Asia/Thimphu", label: "(UTC+06:00) Thimphu" },
  { value: "Indian/Chagos", label: "(UTC+06:00) Chagos" },
  { value: "Asia/Rangoon", label: "(UTC+06:30) Rangoon" },
  { value: "Indian/Cocos", label: "(UTC+06:30) Cocos" },
  { value: "Antarctica/Davis", label: "(UTC+07:00) Davis" },
  { value: "Asia/Bangkok", label: "(UTC+07:00) Bangkok" },
  { value: "Asia/Hovd", label: "(UTC+07:00) Hovd" },
  { value: "Asia/Jakarta", label: "(UTC+07:00) Jakarta" },
  { value: "Asia/Krasnoyarsk", label: "(UTC+07:00) Krasnoyarsk" },
  { value: "Asia/Saigon", label: "(UTC+07:00) Hanoi" },
  { value: "Asia/Ho_Chi_Minh", label: "(UTC+07:00) Ho Chi Minh" },
  { value: "Indian/Christmas", label: "(UTC+07:00) Christmas" },
  { value: "Antarctica/Casey", label: "(UTC+08:00) Casey" },
  { value: "Asia/Brunei", label: "(UTC+08:00) Brunei" },
  { value: "Asia/Choibalsan", label: "(UTC+08:00) Choibalsan" },
  { value: "Asia/Hong_Kong", label: "(UTC+08:00) Hong Kong" },
  { value: "Asia/Irkutsk", label: "(UTC+08:00) Irkutsk" },
  { value: "Asia/Kuala_Lumpur", label: "(UTC+08:00) Kuala Lumpur" },
  { value: "Asia/Macau", label: "(UTC+08:00) Macau" },
  { value: "Asia/Makassar", label: "(UTC+08:00) Makassar" },
  { value: "Asia/Manila", label: "(UTC+08:00) Manila" },
  { value: "Asia/Shanghai", label: "(UTC+08:00) Beijing, Shanghai" },
  { value: "Asia/Singapore", label: "(UTC+08:00) Singapore" },
  { value: "Asia/Taipei", label: "(UTC+08:00) Taipei" },
  { value: "Asia/Ulaanbaatar", label: "(UTC+08:00) Ulaanbaatar" },
  { value: "Australia/Perth", label: "(UTC+08:00) Western Time - Perth" },
  { value: "Asia/Pyongyang", label: "(UTC+08:30) Pyongyang" },
  { value: "Asia/Dili", label: "(UTC+09:00) Dili" },
  { value: "Asia/Jayapura", label: "(UTC+09:00) Jayapura" },
  { value: "Asia/Seoul", label: "(UTC+09:00) Seoul" },
  { value: "Asia/Tokyo", label: "(UTC+09:00) Tokyo" },
  { value: "Asia/Yakutsk", label: "(UTC+09:00) Moscow+06 - Yakutsk" },
  { value: "Pacific/Palau", label: "(UTC+09:00) Palau" },
  { value: "Australia/Adelaide", label: "(UTC+10:30) Adelaide" },
  { value: "Australia/Darwin", label: "(UTC+09:30) Darwin" },
  { value: "Antarctica/DumontDUrville", label: "(UTC+10:00) Dumont D'Urville" },
  { value: "Asia/Magadan", label: "(UTC+10:00) Moscow+07 - Magadan" },
  {
    value: "Asia/Vladivostok",
    label: "(UTC+10:00) Moscow+07 - Yuzhno-Sakhalinsk",
  },
  { value: "Australia/Brisbane", label: "(UTC+10:00) Brisbane" },
  { value: "Australia/Hobart", label: "(UTC+11:00) Hobart" },
  {
    value: "Australia/Sydney",
    label: "(UTC+11:00) Melbourne, Sydney",
  },
  { value: "Pacific/Chuuk", label: "(UTC+10:00) Truk" },
  { value: "Pacific/Guam", label: "(UTC+10:00) Guam" },
  { value: "Pacific/Port_Moresby", label: "(UTC+10:00) Port Moresby" },
  { value: "Pacific/Efate", label: "(UTC+11:00) Efate" },
  { value: "Pacific/Guadalcanal", label: "(UTC+11:00) Guadalcanal" },
  { value: "Pacific/Kosrae", label: "(UTC+11:00) Kosrae" },
  { value: "Pacific/Norfolk", label: "(UTC+11:00) Norfolk" },
  { value: "Pacific/Noumea", label: "(UTC+11:00) Noumea" },
  { value: "Pacific/Pohnpei", label: "(UTC+11:00) Ponape" },
  {
    value: "Asia/Kamchatka",
    label: "(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  },
  { value: "Pacific/Auckland", label: "(UTC+13:00) Auckland" },
  { value: "Pacific/Fiji", label: "(UTC+13:00) Fiji" },
  { value: "Pacific/Funafuti", label: "(UTC+12:00) Funafuti" },
  { value: "Pacific/Kwajalein", label: "(UTC+12:00) Kwajalein" },
  { value: "Pacific/Majuro", label: "(UTC+12:00) Majuro" },
  { value: "Pacific/Nauru", label: "(UTC+12:00) Nauru" },
  { value: "Pacific/Tarawa", label: "(UTC+12:00) Tarawa" },
  { value: "Pacific/Wake", label: "(UTC+12:00) Wake" },
  { value: "Pacific/Wallis", label: "(UTC+12:00) Wallis" },
  { value: "Pacific/Apia", label: "(UTC+14:00) Apia" },
  { value: "Pacific/Enderbury", label: "(UTC+13:00) Enderbury" },
  { value: "Pacific/Fakaofo", label: "(UTC+13:00) Fakaofo" },
  { value: "Pacific/Tongatapu", label: "(UTC+13:00) Tongatapu" },
  { value: "Pacific/Kiritimati", label: "(UTC+14:00) Kiritimati" },
];

const STATUS_COLOR_CODE_MAP = {
  warning: orange[500],
  error: error[500],
  prior: yellow[500],
  success: success[500],
  suppressed: purple[500],
  inactive: lightGrey[600],
};

const suppressedUntilFormatDate = "DD-MMM-yyyy, HH:mm:ss";
export {
  breakPoints,
  AuthorizedEquipmentTypes,
  limitDelayMappings,
  Languages,
  notAllowedRoles,
  UTCTimeZones,
  tabStyles,
  STATUS_COLOR_CODE_MAP,
  suppressedUntilFormatDate,
};
