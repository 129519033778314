import React, { useState } from "react";
import CustomMenu from "@Components/BaseComponents/Menu";
import { Box } from "@mui/material";
import "./style.css";
import MenuItemComponent from "../MenuItem";

const DownloadDropdown = (props) => {
  const { handleDownloadCSV, handlePrintPDF } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = (id) => {
    setAnchorEl(null);
  };
  const handleEquipmentContextMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePrintPDFAndCloseMenu = (event) => {
    handlePrintPDF();
    handleMenuClose();
  };
  const handlePrintCSVAndCloseMenu = (event) => {
    handleDownloadCSV();
    handleMenuClose();
  };

  const menuId = "download-context-menu";

  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2))",
          mt: 0.7,
          width: "120px",
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disablePadding={true}
    >
      <Box>
        <div
          style={{
            height: "fit-content",
            maxHeight: "86px",
            borderRadius: "0px 0px 4px 4px",
          }}
        >
          <MenuItemComponent
            handleChange={() => handlePrintCSVAndCloseMenu()}
            paddingTop="0px"
            paddingBottom="0px"
            Children={
              <div
                className="menuItem"
                style={{
                  color: "#000",
                }}
              >
                <section
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}
                >
                  <i
                    class="fa-light fa-file-csv fa-lg"
                    style={{
                      width: "24px",
                    }}
                  ></i>{" "}
                  <p
                    style={{
                      textAlign: "left",
                      font: "normal normal normal 14px/16px Roboto",
                      letterSpacing: "0px",
                      color: "#222934",
                      opacity: "1",
                    }}
                  >
                    CSV
                  </p>
                </section>
              </div>
            }
          />
          <MenuItemComponent
            handleChange={() => handlePrintPDFAndCloseMenu()}
            paddingTop="0px"
            paddingBottom="0px"
            Children={
              <div
                className="menuItem"
                style={{
                  color: "#000",
                }}
              >
                <section
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}
                >
                  <i
                    class="fa-light fa-file-pdf fa-lg"
                    style={{
                      width: "24px",
                    }}
                  ></i>{" "}
                  <p
                    style={{
                      textAlign: "left",
                      font: "normal normal normal 14px/16px Roboto",
                      letterSpacing: "0px",
                      color: "#222934",
                      opacity: "1",
                    }}
                  >
                    PDF
                  </p>
                </section>
              </div>
            }
          />
        </div>
      </Box>
    </CustomMenu>
  );

  return (
    <>
      <i
        onClick={handleEquipmentContextMenu}
        class="fa-light fa-download"
        style={{
          fontSize: "1.5em",
          color: "#9CA1A6",
          font: "normal normal 300 20px/25px Font Awesome 6 Pro",
        }}
      />
      {renderMenu}
    </>
  );
};
export default DownloadDropdown;
