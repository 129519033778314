import React, { useEffect, useState } from "react";

//COMPONENTS IMPORT
import TenantDrawer from "@Components/RightDrawer/TenantDrawer";
import Loader from "@Components/LoadingSpinner";
import Toast from "@Components/BaseComponents/Toast";

import {
  useAddCustomerMutation,
  useEditCustomerMutation,
  useFindCustomersQuery,
} from "../../../Store/Slices/customersSlice";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import { useSelector } from "react-redux";
import TenantTable from "../../../Components/Tables/TenantTable";
import { useFindUsersQuery } from "../../../Store/Slices/usersSlice";
import { filterObjectByValue, validateUIState } from "../../../Utils/Tools";
import { Box } from "@mui/material";

const Tenant = () => {
  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customerSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();

  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: userSuccess,
    isError: usersIsError,
    error: usersError,
  } = useFindUsersQuery();

  const [
    addCustomer,
    {
      isSuccess: addCustomerSuccess,
      isError: addCustomerIsError,
      isLoading: addCustomerLoading,
      error: addCustomerError,
    },
  ] = useAddCustomerMutation();

  const [editCustomer, { error, isSuccess: customerEditSuccess, isError: customerEditIsError }] =
    useEditCustomerMutation();

  const auth = useSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "tenant", "");

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [tenantUpdated, setTenantUpdated] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [edit, setEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [msg, setMsg] = useState("");

  const [tenant, setTenant] = useState({
    TenantName: "",
    OwnerEmail: "",
    RequireActionReason: false,
    RequireAlarmReason: false,
    RequireAuthentication: false,
  });

  const validateEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const buttonValidation = !(
    tenant.TenantName !== "" &&
    tenant.OwnerEmail.length !== 0 &&
    validateEmail(tenant.OwnerEmail) == true
  );

  const loadCustomers = () => {
    if (customerSuccess) {
      setTenants(customersData);
    }
    if (customersIsError) console.log(customersError);
  };

  const loadUsers = () => {
    if (userSuccess) {
      if (!isAdmin) setUsers(filterObjectByValue(usersData, "CustomerID", auth.CustomerID));
      else {
        if (auth.SelectedCustomer == "") setUsers(usersData);
        else setUsers(filterObjectByValue(usersData, "CustomerID", auth.SelectedCustomer));
      }
    }
    if (usersIsError) console.log(usersError);
  };

  useEffect(() => {
    loadCustomers();
  }, [customersData]);

  useEffect(() => {
    loadUsers();
  }, [usersData, auth]);

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (open === false) {
      clearTenant();
      setEdit(false);
      setTenantUpdated(false);
    }
  };

  const handleEdit = (Tenant) => {
    setTenant({
      value: Tenant.CustomerID,
      TenantName: Tenant.CustomerName,
      OwnerEmail: Tenant.ContactEmail,
      RequireActionReason: Tenant.RequireActionReason,
      RequireAlarmReason: Tenant.RequireAlarmReason,
      RequireAuthentication: Tenant.RequireAuthentication,
    });
    toggleDrawer(true);
    setEdit(true);
  };

  const handleCreate = async (reason) => {
    const tempTenant = {
      CustomerName: tenant.TenantName,
      Address: "",
      City: "",
      StateProvince: "",
      Country: "",
      PostalCode: "",
      ContactName: "",
      ContactPhone: "",
      ContactEmail: tenant.OwnerEmail,
      RequireActionReason: tenant.RequireActionReason,
      RequireAlarmReason: tenant.RequireAlarmReason,
      RequireAuthentication: tenant.RequireAuthentication,
      reason: reason,
    };
    let res = await addCustomer(tempTenant);
    if (!res.error) {
      clearTenant();
      toggleDrawer(false);
      confirmationDrawer(false);
    } else if (res.error) setMsg(res.error.data.error.message);
  };
  const handleEditSave = async (reason) => {
    const id = tenant.value;
    const data = {
      CustomerName: tenant.TenantName,
      Address: "",
      City: "",
      StateProvince: "",
      Country: "",
      PostalCode: "",
      ContactName: "",
      ContactPhone: "",
      ContactEmail: tenant.OwnerEmail,
      RequireActionReason: tenant.RequireActionReason,
      RequireAlarmReason: tenant.RequireAlarmReason,
      RequireAuthentication: tenant.RequireAuthentication,
      reason: reason,
    };
    let res = await editCustomer({ id, data });
    if (!res.error) {
      clearTenant();
      toggleDrawer(false);
      confirmationDrawer(false);
    }
  };

  const clearTenant = () => {
    setTenant({
      TenantName: "",
      OwnerEmail: "",
      RequireActionReason: false,
      RequireAlarmReason: false,
      RequireAuthentication: false,
    });
  };

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  const tenantConfirmationDrawer = (open) => {
    if (tenantUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };

  const handleClick = (reason) => {
    edit ? handleEditSave(reason) : handleCreate(reason);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {addCustomerSuccess && (
        <Toast
          message="Tenant successfully added"
          severity="success"
        />
      )}
      {customerEditIsError && (
        <Toast
          message="Tenant name is not unique"
          severity="error"
        />
      )}
      {addCustomerIsError && (
        <Toast
          message={msg}
          severity="error"
        />
      )}
      {customerEditSuccess && (
        <Toast
          message="Tenant successfully updated"
          severity="success"
        />
      )}

      {customersLoading ? (
        <SpinnerContainer>
          <Loader />
        </SpinnerContainer>
      ) : (
        <TenantTable
          tenants={tenants}
          toggleDrawer={toggleDrawer}
          handleEdit={handleEdit}
          auth={auth}
          users={users}
          allowedEdit={allowedEdit}
        />
      )}
      <TenantDrawer
        toggleDrawer={tenantConfirmationDrawer}
        tenantToggleDrawer={toggleDrawer}
        drawer={drawer}
        edit={edit}
        tenant={tenant}
        setTenant={setTenant}
        handleClick={handleClick}
        buttonValidation={buttonValidation}
        clearTenant={clearTenant}
        setTenantUpdated={setTenantUpdated}
        discardSettingsDrawer={discardSettingsDrawer}
        confirmationDrawer={confirmationDrawer}
        validateEmail={validateEmail}
        tenantUpdated={tenantUpdated}
      />
    </Box>
  );
};

export default Tenant;
