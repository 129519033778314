import { apiSlice } from "../API";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.mutation({
      query: () => ({ url: "/auth/success", method: "GET", providesTags: ["UserInfo"] }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: (credentials) => ({
        url: "/auth/logout",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updatePass: builder.mutation({
      query: (data) => ({
        url: "/userPassword/update",
        method: "POST",
        body: data,
      }),
    }),
    updateUserPass: builder.mutation({
      query: (data) => ({
        url: "/userPassword/updateUserPassword",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useUpdatePassMutation,
  useUpdateUserPassMutation,
  useGetUserInfoMutation,
} = authApiSlice;
