/**
 * APP ROUTES
 *
 * Component import
 */

export const mainRoutes = [
  {
    path: "/statusview",
    sidebarName: "Status View",
    icon: "fa-light fa-display-medical fa-xl",
    pageName: "statusView",
  },
  // {
  //   path: '/overview',
  //   sidebarName: 'Overview',
  //   icon: "fa-light fa-chart-tree-map fa-xl",
  //   access: ["admin"]
  // },
  // {
  //   path: '/reports',
  //   sidebarName: 'Reports',
  //   icon: "fa-light fa-chart-line fa-xl",
  //   access: ["admin"]
  // },
  {
    path: "/audittrail",
    sidebarName: "Audit Trail",
    icon: "fa-light fa-memo fa-xl",
    pageName: "audittrail",
  },

  {
    path: "/tech",
    sidebarName: "Technician",
    icon: "fa-light fa-toolbox fa-xl",
    pageName: "tech",
  },
  {
    path: "/fakeSensorOOB",
    sidebarName: "FakeSensorOOB",
    icon: "fa-light fa-tachograph-digital fa-xl",
    pageName: "fakeSensorOOB",
  },
  {
    path: "/tenant",
    sidebarName: "Tenant",
    icon: "fa-light fa-people-group fa-xl",
    pageName: "tenant",
  },
];

export const setupRoutes = [
  {
    path: "/setup",
    sidebarName: "Setup",
    icon: "fa-light fa-cog fa-xl",
    pageName: "setup",
  },
];

export const supportRoutes = [
  {
    path: "/support",
    sidebarName: "Support",
    icon: "fa-light fa-question-circle fa-xl",
  },
];

// const AppBarRoutes = [
//     {
//         route:"/status",
//         icon: '',
//         name: "Status Overview",
//         component: Status
//     }
// ]
