import React, { useContext, useEffect, createRef, useState } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";
import { DrawerContext } from "@Context/DrawerContext";

import StatusViewTable from "../Tables/StatusViewTable";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SensorPane = (props) => {
  const {
    equipments,
    equipmentLive,
    locations,
    setLocation,
    tab,
    setSelectedLocationId,
    selectedLocationId,
    locationsHierarchy,
    setPaneWidth,
    paneWidth,
    setTab,
    setEquipment,
    equipment,
    mappedEquipments,
    auth,
    currentBreakpoint,
    sensorTypes,
    setAllSelectedSensors,
    setSelectedAction,
    setUnSuppressAction,
    handleAlertSuppression,
    handleAlertAcknowledge,
    handleEquipmentSupressAlarms,
    handleEquipmentAcknowledgeAlarms,
  } = props;
  const [loading, setLoading] = useState(false);

  const { open } = useContext(DrawerContext);

  const SensorRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const windowResize = open
    ? `${windowDimensions.width - 240}px`
    : `${windowDimensions.width - 80}px`;

  useEffect(() => {
    if (!clientWidth && paneWidth !== 0) {
      let client = SensorRef.current.clientWidth / 1;
      setClientWidth(client);
      return;
    }

    if (clientWidth && paneWidth !== 0) {
      SensorRef.current.style.minWidth = clientWidth + "px";
      SensorRef.current.style.maxWidth = clientWidth + "px";
    }
  }, [clientWidth, paneWidth]);

  const handleToggleTab = () => {
    setTab((tab) => {
      if (tab === 0) {
        return 1;
      } else if (tab === 1) {
        return 2;
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    open
      ? (SensorRef.current.style.transition = "400ms")
      : (SensorRef.current.style.transition = "270ms");
    SensorRef.current.style.maxWidth = windowResize / 1.5;
  }, [windowDimensions.width, open]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="split-pane-left"
      ref={SensorRef}
    >
      <StatusViewTable
        auth={auth}
        equipments={equipments}
        equipmentLive={equipmentLive}
        locations={locations}
        onToggleTab={handleToggleTab}
        setLocation={setLocation}
        setSelectedLocationId={setSelectedLocationId}
        selectedLocationId={selectedLocationId}
        locationsHierarchy={locationsHierarchy}
        setEquipment={setEquipment}
        equipment={equipment}
        mappedEquipments={mappedEquipments}
        currentBreakpoint={currentBreakpoint}
        sensorTypes={sensorTypes}
        setAllSelectedSensors={setAllSelectedSensors}
        setSelectedAction={setSelectedAction}
        setUnSuppressAction={setUnSuppressAction}
        handleAlertSuppression={handleAlertSuppression}
        handleAlertAcknowledge={handleAlertAcknowledge}
        handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
        handleEquipmentAcknowledgeAlarms={handleEquipmentAcknowledgeAlarms}
      />
    </div>
  );
};

export default SensorPane;
