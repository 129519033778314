import { Box, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React from "react";
import CheckBoxComponent from "../Checkbox";

const StatusFilter = (props) => {
  const { title, data, selectedStatus, handleOnChange } = props;

  return (
    <>
      <div>
        <p
          style={{
            textAlign: "left",
            font: "normal normal normal 12px/16px Roboto",
            color: "#9CA1A6",
          }}
        >
          {title}
        </p>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <FormControl
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Grid
                item
                columns={2}
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  display: "grid",
                  width: "100%",
                }}
              >
                {data.map((status, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CheckBoxComponent
                            size="small"
                            checked={selectedStatus.findIndex((item) => item == status) > -1}
                            onChange={handleOnChange}
                            value={status}
                            name={status}
                            Label={status}
                          />
                        }
                      />
                    </Box>
                  );
                })}
              </Grid>
            </Box>
          </FormGroup>
        </FormControl>{" "}
      </div>
    </>
  );
};

export default StatusFilter;
