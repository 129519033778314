import React from "react";

const PowerIcon = ({ PowerType, BatteryLevel, PowerState }) => {
  let iconClass = "";

  switch (PowerType) {
    case "USB-C":
      iconClass =
        PowerState === "NoSignal"
          ? "fa-usb-c-slash"
          : PowerState === "OnSignal"
          ? "fa-usb-c"
          : null;
      break;
    case "POE":
      iconClass =
        PowerState === "NoSignal"
          ? "fa-ethernet-slash"
          : PowerState === "OnSignal"
          ? "fa-ethernet"
          : null;
      break;
    case "Battery":
      iconClass =
        BatteryLevel === 100
          ? "fa-battery-full"
          : BatteryLevel >= 50 && BatteryLevel < 100
          ? "fa-battery-half"
          : BatteryLevel > 0 && BatteryLevel < 50
          ? "fa-battery-low"
          : BatteryLevel === 0
          ? "fa-battery-slash"
          : null;
      break;
    default:
      return null;
  }

  return (
    <>
      <i
        className={`fa-regular ${iconClass}`}
        style={{
          fontSize: "20px",
          lineHeight: "25px",
          color: "#222934",
          textAlign: "center",
        }}
      ></i>
      {PowerType === "Battery" && BatteryLevel ? (
        <p
          style={{
            marginLeft: "2px",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#222934",
            textAlign: "left",
          }}
        >
          {BatteryLevel}%
        </p>
      ) : null}
    </>
  );
};

export default PowerIcon;
