import React, { useContext, useEffect, createRef, useState } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";
import { DrawerContext } from "@Context/DrawerContext";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const MainPane = (props) => {
  const { open } = useContext(DrawerContext);
  const topRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const windowResize = open
    ? `${windowDimensions.width - 240}px`
    : `${windowDimensions.width - 65}px`;

  useEffect(() => {
    if (!clientWidth) {
      setClientWidth(topRef.current.clientWidth);
      if (props.partition === "split-data") {
        setClientWidth(topRef.current.clientWidth / 2);
      }
      return;
    }
    topRef.current.style.minWidth = clientWidth + "px";
    topRef.current.style.maxWidth = clientWidth + "px";
  }, [clientWidth]);

  useEffect(() => {
    open
      ? (topRef.current.style.transition = "400ms")
      : (topRef.current.style.transition = "270ms");
    topRef.current.style.maxWidth = windowResize;
  }, [windowDimensions.width, open]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      {...props}
      className="split-pane-left"
      ref={topRef}
    />
  );
};

export default MainPane;
