import React, { useState, useEffect } from "react";
import {
  useAddSubstationMutation,
  useFindActiveSubstationsQuery,
  useEditSubstationMutation,
} from "../../../Store/Slices/substationSlice";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Box } from "@mui/material";
import Toast from "@Components/BaseComponents/Toast";
import SubstationsTable from "@Components/Tables/SubstationsTable";
import { useFindLocationsQuery } from "../../../Store/Slices/locationsSlice";
import { useCustomerLocations } from "../../../Hooks/useCustomerLocation";
import {
  findObjectByValue,
  filterObjectByValue,
  locationsString,
  validateUIState,
} from "../../../Utils/Tools";
import { SubstationDrawer } from "../../../Components/RightDrawer/SubstationDrawer";
import { useSelector } from "react-redux";
import { useFindSensorTypeQuery } from "../../../Store/Slices/sensorTypeSlice";
import { useAddArchiveItemsMutation } from "../../../Store/Slices/archiveSlice";
import {
  useAddSensorConfigMutation,
  useAddSensorMutation,
  useEditSensorConfigMutation,
  useEditSensorMutation,
  useFindSensorsWithOutStatusQuery,
} from "../../../Store/Slices/sensorSlice";
import { useFindSensorLimitTemplateQuery } from "../../../Store/Slices/sensorLimitTemplateSlice";
import { useFindEquipmentTypeQuery } from "../../../Store/Slices/equipmentTypeSlice";
import {
  useFindEquipmentSensorTypesQuery,
  useLiveEquipmentQuery,
} from "../../../Store/Slices/equipmentSlice";
import SensorDrawer from "../../../Components/RightDrawer/SensorDrawer";
import SubstationAlarmSuppressDrawer from "../../../Components/RightDrawer/SubstationAlarmSuppressDrawer";

import {
  useFindMappedSubstationsQuery,
  useDeleteMappedSubstationMutation,
} from "../../../Store/Slices/mappedSubstationSlice";

import moment from "moment";
import SensorSuppressDrawer from "../../../Components/RightDrawer/SensorSuppressDrawer";

const SubstationSetup = () => {
  const menuItems = {
    1: "suppress",
    2: "suppress",
    3: "unsuppress",
  };

  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();
  const {
    data: substationsData,
    isSuccess: substationsSuccess,
    isLoading: substationsLoading,
    isError: isSubstationError,
    error: substationError,
    refetch: substationsDataRefetch,
  } = useFindActiveSubstationsQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: sensorsData,
    isLoading: sensorsLoading,
    isSuccess: sensorsSuccess,
    isError: sensorIsError,
    error: sensorError,
    refetch: sensorsDataRefetch,
  } = useFindSensorsWithOutStatusQuery();

  const {
    refetch,
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useFindEquipmentSensorTypesQuery();

  const { data: equipmentLiveData, isSuccess: equipmentLiveDataSuccess } = useLiveEquipmentQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: equipmentTypesData,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: mappedSubstationData,
    isFetching: mappedSubstationLoading,
    isSuccess: mappedSubstationSuccess,
    isError: mappedSubstationIsError,
    error: mappedSubstationError,
  } = useFindMappedSubstationsQuery();

  const [editSubstation, { isSuccess: substationEditSuccess, isLoading: isSubsLoading }] =
    useEditSubstationMutation();

  const [deleteMappedSubstation, { isSuccess: deleteMappedSubstationSuccess }] =
    useDeleteMappedSubstationMutation();

  const [
    addSensor,
    { isSuccess: isSensorSuccess, error: AddSensorError, isLoading: isAddSensoLoading },
  ] = useAddSensorMutation();

  const [addSensorConfig, { isLoading: isAddingSensorConfigLoading }] =
    useAddSensorConfigMutation();

  const [editSensor, { isSuccess: sensorEditSuccess, isError: editSensorError }] =
    useEditSensorMutation();

  const [
    editSensorConfig,
    { isSuccess: sensorConfigEditSuccess, isError: isSensorConfigEditError },
  ] = useEditSensorConfigMutation();

  const [locations, setLocations] = useState([]);

  const [
    addSubstation,
    {
      isSuccess: addSubstationisSuccess,
      isError: AddSubstationIsError,
      isLoading: isAddingSubstationLoading,
    },
  ] = useAddSubstationMutation();

  const [
    addArchive,
    { isSuccess: addArchiveSuccess, isError: addArchiveIsError, error: addArchiveError },
  ] = useAddArchiveItemsMutation();
  const [
    addSensorArchive,
    {
      isSuccess: addSensorArchiveSuccess,
      isError: addSensorArchiveIsError,
      error: addSensorArchiveError,
    },
  ] = useAddArchiveItemsMutation();

  const allChangesLoading =
    isSubsLoading || isAddingSensorConfigLoading || isAddingSubstationLoading || isAddSensoLoading;

  const auth = useSelector((state) => state.auth);

  const isAdmin = auth.isAdmin;

  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Substations");
  let data = {};

  if (!isAdmin) {
    data = { value: auth.CustomerID };
  } else {
    data = { value: auth.SelectedCustomer };
  }

  const [loading, setLoading] = useState(false);
  const [filterLocations, locationsEquipmentLoading] = useCustomerLocations(data.value);
  const [substations, setSubstations] = useState([]);
  const [msg, setMsg] = useState("");
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [substationUpdated, setSubstationUpdated] = useState(false);
  const [substationEdit, setSubstationEdit] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [suppressDrawer, setSuppressDrawer] = useState(false);
  const [sensorDrawer, setSensorDrawer] = useState(false);
  const [locationsHierarchy, setLocationsHierarchy] = useState({});
  const [substationSensorsData, setSubstationSensorsData] = useState({});
  const [sensors, setSensors] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [sensorUpdated, setSensorUpdated] = useState(false);
  const [sensorEdit, setSensorEdit] = useState(false);
  const [selectedSensorLimitTemplate, setSelectedSensorLimitTemplate] = useState({});
  const [substationData, setSubstationData] = useState({});
  const [tempSensor, setTempSensor] = useState({});
  const [tempSubstation, setTempSubstation] = useState({});
  const [delSensor, setDelSensor] = useState("");
  const [substationAlarmSuppress, setSubstationAlarmSuppress] = useState({
    Sensors: [],
    SelectedRow: {},
  });
  const [substationAlarmSuppressDrawer, setSubstationAlarmSuppressDrawer] = useState(false);
  const [suppressSensorTypes, setSuppressSensorTypes] = useState([]);
  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };
  const [mappedSubstations, setMappedSubstations] = useState([]);

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const [sensor, setSensor] = useState({
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{Schema}",
  });

  const [substation, setSubstation] = useState({
    Label: "",
    SerialNumber: "",
    LocationID: "",
    CustomerID: defaultCustomerID,
    MeasurementInterval: "15",
  });

  const handleClick = async (reason) => {
    substationEdit
      ? await handleEditSubstation({ ...substation, reason })
      : await handleCreate({ ...substation, reason });
  };

  const clearSubstation = () => {
    setSubstation({
      Label: "",
      SerialNumber: "",
      LocationID: "",
      MeasurementInterval: "15",
      CustomerID: defaultCustomerID,
    });
    setSubstationEdit(false);
  };

  const clearSensor = () => {
    setSensor({
      SensorLabel: "",
      CustomerID: defaultCustomerID,
      SensorTypeID: "",
      LocationID: "",
      EquipmentID: "",
      SensorLimitTemplateID: "",
      Port: "",
      SubstationSerial: "",
      SensorTypeLabel: "",
      SchemaJSON: "{Schema}",
    });
    setSensorEdit(false);
    setSelectedSensorLimitTemplate({});
  };

  const sensorButtonValidation = !(
    sensor.SensorLabel !== "" &&
    sensor.SensorTypeID !== "" &&
    sensor.EquipmentID !== "" &&
    sensor.SensorLimitTemplateID !== "" &&
    sensor.Port !== "" &&
    sensor.SubstationSerial !== "" &&
    sensor.CustomerID !== ""
  );

  const loadSensorTypes = () => {
    if (sensorTypeLoading) setLoading(true);
    if (sensorTypeSuccess)
      setSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    setSuppressSensorTypes(
      sensorTypeData.success.data.map((e) => {
        return {
          value: e.SensorTypeID,
          label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
          sensorType: e.ConfigType,
          Unit: e.Unit,
        };
      })
    );
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  };

  const loadLocations = () => {
    if (locationsLoading) setLoading(true);
    if (locationsSuccess) {
      let locationsInfo = {};
      const temp = locationsData.map((c) => {
        return {
          value: c.LocationID,
          label: c.LocationName,
          CustomerID: c.CustomerID,
        };
      });
      if (!isAdmin) {
        setLocations(filterLocations);
      } else {
        if (auth.SelectedCustomer == "") {
          setLocations(temp);
        } else {
          setLocations(filterLocations);
        }
      }
      for (const location of locationsData) {
        locationsInfo[location.LocationID] = locationsString(location, locationsData);
      }
      setLocationsHierarchy(locationsInfo);
    }
    if (locationsIsError) console.log(locationsError);
    setLoading(false);
  };

  const loadmappedSubstations = () => {
    if (mappedSubstationLoading) setLoading(true);
    if (mappedSubstationSuccess) setMappedSubstations(mappedSubstationData.success.data);
    if (mappedSubstationIsError) console.log(mappedSubstationError);
    setLoading(false);
  };

  const loadEquipment = () => {
    if (equipmentLoading) setLoading(true);
    if (equipmentSuccess && equipmentLiveDataSuccess) {
      const tempEquipments = equipmentData.map((val) => {
        const liveSensors = findObjectByValue(
          equipmentLiveData.success.data,
          "EquipmentID",
          val.EquipmentID
        );
        return {
          value: val.EquipmentID,
          label: val.EquipmentName,
          EquipmentID: val.EquipmentID,
          EquipmentName: val.EquipmentName,
          EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
          EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
          LocationName: val.Location?.LocationName,
          LocationID: val.LocationID,
          CustomerID: val.Location?.Customer.CustomerID,
          CustomerName: val.Location?.Customer.CustomerName,
          updatedAt: val.updatedAt,
          Sensors: liveSensors?.Sensors || [],
          equipmentSensorTypes: val.equipmentSensorTypes,
        };
      });
      if (!isAdmin) {
        setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.CustomerID));
      } else {
        if (auth.SelectedCustomer == "") setEquipments(tempEquipments);
        else {
          setEquipments(filterObjectByValue(tempEquipments, "CustomerID", auth.SelectedCustomer));
        }
      }
    }
    if (equipmentIsError) console.log(equipmentError);
    setLoading(false);
  };

  const loadSensors = async () => {
    if (sensorsSuccess && substationsSuccess) {
      const tempSensors = [];
      const length = sensorsData.length;
      const data = sensorsData;
      for (let i = 0; i != length; i++) {
        tempSensors.push({
          ...data[i],
        });
      }
      let substationSensors = {};
      for (const substation of substationsData) {
        substationSensors[substation.SubstationID] = tempSensors.filter((sensor) => {
          const [_port, ...extractedSerial] = sensor.PortSerial.split("-");
          const serialNumber = extractedSerial.join("-");
          return serialNumber === substation.SerialNumber && sensor;
        });
      }
      setSubstationSensorsData(substationSensors);
      setSensors(tempSensors);
      setLoading(false);
    }

    if (sensorIsError) console.log(sensorError);
  };

  const loadSubstations = () => {
    if (substationsSuccess) {
      const tempSubstations = substationsData?.filter(val => !val.IsArchive).map((substation) => {
        const tempSensors = [];
        const length = sensorsData.length;
        const data = sensorsData;
        for (let i = 0; i != length; i++) {
          tempSensors.push({
            ...data[i],
          });
        }
        let sensors = tempSensors.filter((sensor) => {
          const [port, ...extractedSerial] = sensor.PortSerial.split("-");
          const serialNumber = extractedSerial.join("-");
          return serialNumber === substation.SerialNumber && sensor;
        });
        let filteredSensors = sensors
          .filter((sensor) => sensor.liveData && sensor.IsActive && sensor.liveData.State)
          .sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf());

        let sensorStates = Array.from(
          new Set(
            filteredSensors.map((sensor) =>
              sensor.liveData.Suppressed ? 3 : sensor.liveData.State
            )
          )
        );
        return {
          ...substation,
          value: substation.SubstationID,
          label: substation.Label,
          SerialNumber: substation.SerialNumber,
          MeasurementInterval: substation.MeasurementInterval,
          MenuOptions: sensorStates.map((state) => menuItems[state]),
          Sensors: filteredSensors,
        };
      });
      if (auth.SelectedCustomer == "") {
        setSubstations(tempSubstations);
      } else {
        setSubstations(filterObjectByValue(tempSubstations, "CustomerID", auth.SelectedCustomer));
      }
      setLoading(false);
    }
    if (isSubstationError) console.log(substationError);
  };

  const handleCreate = async (substation) => {
    let sensorConfigs = {
      SerialNumber: substation.SerialNumber,
      data: {
        t: date,
        i: JSON.parse(substation.MeasurementInterval),
      },
    };
    let res = await addSubstation(substation);
    if (!res.error) await addSensorConfig(sensorConfigs);
    if (res.error) setMsg(res.error.data.error.message);
    clearSubstation();
    toggleDrawer(false);
    substationsDataRefetch();
  };

  const handleEdit = (substation) => {
    toggleDrawer(true);
    setSubstationEdit(true);
    setSubstation(substation);
    setTempSubstation(substation);
  };

  const handleEditSubstation = async (substation) => {
    const substationID = substation.SubstationID;
    const res = await editSubstation({
      id: substationID,
      substation,
    });
    let sensorConfigs =
      substation.ConfigData !== "-" ? JSON.parse(substation.ConfigData) : substation.ConfigData;
    if (sensorConfigs !== "-" && !res.error) {
      let id = substation.SerialNumber;
      sensorConfigs.data.i =
        substation.MeasurementInterval !== "-"
          ? JSON.parse(substation.MeasurementInterval)
          : substation.MeasurementInterval;
      sensorConfigs.reason = substation.reason;
      await editSensorConfig({ id, sensorConfig: sensorConfigs });
    }
    if (res.error) setMsg(res.error.data.error.message);
    clearSubstation();
    toggleDrawer(false);
    confirmationDrawer(false);
    substationsDataRefetch();
  };

  const handleAlertSuppression = (sensor, action) => {
    handleSensorEdit(sensor);
    toggleSuppressDrawer(action);
    setSensorDrawer(!action);
    setDrawer(!action);
  };

  const handleSuppressDrawerEdit = (action) => {
    toggleSuppressDrawer(!action);
    setSensorDrawer(action);
  };

  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateLoading) setLoading(true);
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
    setLoading(false);
  };

  const loadEquipmentTypes = async () => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
    if (equipmentTypesIsError) console.log(equipmentTypesError);
  };

  const handleSubstationSupressAlarms = ({ Sensors, SelectedRow }) => {
    setSubstationAlarmSuppress({
      Sensors: Sensors,
      SelectedRow: SelectedRow,
    });
    toggleAlarmSuppressDrawer(true);
  };
  const toggleAlarmSuppressDrawer = (action) => {
    setSubstationAlarmSuppressDrawer(action);
  };
  const [substationArchiveDrawer, setSubstationArchiveDrawer] = useState(false);

  const customSubstationArchiveDrawer = (open) => {
    setSubstationArchiveDrawer(open);
  };

  const [sensorDeleteDrawer, setSensorDeleteDrawer] = useState(false);

  const customToggleSensorDeleteDrawer = (open) => {
    setSensorDeleteDrawer(open);
  };

  const [discardSensorSettingsDrawer, setDiscardSensorSettingsDrawer] = useState(false);

  const confirmationSensorDrawer = (open) => {
    setDiscardSensorSettingsDrawer(open);
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (drawer === false) {
      clearSubstation();
      setSubstationEdit(false);
    }
  };

  const toggleSuppressDrawer = (action) => {
    setSuppressDrawer(action);
  };

  const substationConfirmationDrawer = (open) => {
    if (substationUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(open);
    }
  };

  const buttonValidation =
    !allChangesLoading &&
    !(
      substation.Label !== "" &&
      substation.SerialNumber !== "" &&
      substation.LocationID !== "" &&
      substation.MeasurementInterval !== ""
    );

  useEffect(() => {
    loadLocations();
  }, [locationsData, auth, filterLocations]);

  useEffect(() => {
    loadEquipment();
  }, [equipmentData, auth, equipmentLiveData]);

  useEffect(() => {
    loadEquipmentTypes();
  }, [equipmentTypesData]);

  useEffect(() => {
    loadmappedSubstations();
  }, [mappedSubstationData]);

  useEffect(() => {
    if (substationData && sensorsSuccess) loadSubstations();
  }, [substationsData, auth, addSubstationisSuccess, substationEditSuccess, sensorsSuccess]);

  useEffect(() => {
    loadSensors();
  }, [sensorsData, substationsData]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  useEffect(() => {
    loadSensorTypes();
  }, [sensorTypeData]);

  const handleSensorClick = (reason) => {
    sensorEdit ? handleEditSave(reason) : handleSaveAll(reason);
  };

  const sensorToggleDrawer = (open) => {
    setSensorDrawer(open);
    if (substationEdit) {
      setDrawer(!open);
    }
    if (open === false) {
      clearSensor();
      setSensorEdit(false);
    }
  };

  const sensorConfirmationDrawer = (open) => {
    if (sensorUpdated) {
      confirmationSensorDrawer(true);
    } else {
      sensorToggleDrawer(open);
    }
  };

  const handleLimitChange = (e, Sensor, edit) => {
    if (e != "111a11b1-a111-4111-aa11-1eb1dbd1a11a") {
      const temp = findObjectByValue(sensorLimitTemplates, "value", e);
      setSelectedSensorLimitTemplate({
        ...temp,
        LimitsJSON: JSON.parse(temp.LimitsJSON),
      });
    } else {
      if (!edit)
        if (sensor.SensorTypeLabel == "Analog")
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              LowLimitWarning: "",
              HighLimitWarning: "",
              LowDelayWarning: "",
              HighDelayWarning: "",
              LowLimitAlarm: "",
              HighLimitAlarm: "",
              LowDelayAlarm: "",
              HighDelayAlarm: "",
            },
          });
        else {
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              InputState: "",
              Delay: "",
            },
          });
        }
      else {
        const tempLimits = JSON.parse(sensor?.SchemaJSON);
        if (Sensor?.SensorTypeLabel == "Analog")
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              LowLimitWarning: tempLimits.llw,
              HighLimitWarning: tempLimits.ulw,
              LowDelayWarning: tempLimits.ldw,
              HighDelayWarning: tempLimits.hdw,
              LowLimitAlarm: tempLimits.lln,
              HighLimitAlarm: tempLimits.uln,
              LowDelayAlarm: tempLimits.lda,
              HighDelayAlarm: tempLimits.hda,
            },
          });
        else {
          setSelectedSensorLimitTemplate({
            LimitsJSON: {
              InputState: tempLimits.s,
              Delay: tempLimits.d,
            },
          });
        }
      }
    }
  };

  const handleSensorEdit = (Sensor) => {
    sensorToggleDrawer(true);
    setSensorEdit(true);
    const EquipmentID = Sensor.equipmentSensors[0]?.EquipmentID || "";
    const [port, ...extractedSerial] = Sensor.PortSerial.split("-");
    const SubstationSerial = extractedSerial.join("-");
    handleLimitChange(Sensor.SensorLimitTemplateID, Sensor, true);
    setTempSensor({
      ...Sensor,
      EquipmentID: EquipmentID || "",
      Port: port,
      SubstationSerial: SubstationSerial || "",
    });
    setSensor({
      ...Sensor,
      EquipmentID: EquipmentID || "",
      Port: port,
      SubstationSerial: SubstationSerial || "",
    });
  };

  const handleDelete = async (Sensor, reason) => {
    await addSensorArchive({
      data: { Sensor: [Sensor.SensorID] },
      reason: reason,
    });
    setSensorEdit(false);
    customToggleSensorDeleteDrawer(false);
    sensorToggleDrawer(false);
    setDrawer(false);
  };

  let date = Date.now();

  const handleSaveAll = async (reason) => {
    let temp_config = {};
    let sensorConfig = {};
    if (sensor.SensorTypeLabel === "Analog") {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        hdw: selectedSensorLimitTemplate.LimitsJSON.HighDelayWarning,
        hda: selectedSensorLimitTemplate.LimitsJSON.HighDelayAlarm,
        lda: selectedSensorLimitTemplate.LimitsJSON.LowDelayAlarm,
        ldw: selectedSensorLimitTemplate.LimitsJSON.LowDelayWarning,
        uln: selectedSensorLimitTemplate.LimitsJSON.HighLimitAlarm,
        lln: selectedSensorLimitTemplate.LimitsJSON.LowLimitAlarm,
        ulw: selectedSensorLimitTemplate.LimitsJSON.HighLimitWarning,
        llw: selectedSensorLimitTemplate.LimitsJSON.LowLimitWarning,
        scs: [],
        sca: [],
        pcs: [],
        pca: [],
      };

      temp_config[`a${sensor.Port}`] = {
        ...sensorConfig,
        t: sensor.SensorTypeID,
      };
    } else {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        d: selectedSensorLimitTemplate.LimitsJSON.Delay,
        s: selectedSensorLimitTemplate.LimitsJSON.InputState,
      };
      temp_config[`d${sensor.Port}`] = {
        ...sensorConfig,
      };
    }

    let sensorConfigs = {
      SerialNumber: sensor.SubstationSerial,
      data: {
        ...substationData?.data,
        ...temp_config,
        t: date,
        i: 0,
      },
    };

    sensor.SchemaJSON = JSON.stringify(sensorConfig);

    try {
      console.log("🚀 ~ file: index.js ~ line 357 ~ handleSaveAll ~ sensorConfig", sensorConfigs);
      const sensorCheck = findObjectByValue(sensors, "SensorLabel", sensor.SensorLabel);
      if (sensorCheck == undefined) await addSensorConfig(sensorConfigs);
      const data = {
        sensor: sensor,
        reason: reason,
      };
      let res = await addSensor(data);
      if (!res.error) {
        sensorToggleDrawer(false);
        confirmationDrawer(false);
        sensorsDataRefetch();
        substationsDataRefetch();
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 272 ~ handleSaveAll ~ error", error);
    }
  };

  useEffect(() => {
    if (delSensor === "") return;
    const timeout = setTimeout(() => {
      setDelSensor(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [delSensor]);

  const handleEditSave = async (reason) => {
    let temp_config = {};
    let sensorConfig = {};
    if (sensor.SensorTypeLabel === "Analog") {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        hdw: selectedSensorLimitTemplate.LimitsJSON.HighDelayWarning,
        hda: selectedSensorLimitTemplate.LimitsJSON.HighDelayAlarm,
        lda: selectedSensorLimitTemplate.LimitsJSON.LowDelayAlarm,
        ldw: selectedSensorLimitTemplate.LimitsJSON.LowDelayWarning,
        uln: selectedSensorLimitTemplate.LimitsJSON.HighLimitAlarm,
        lln: selectedSensorLimitTemplate.LimitsJSON.LowLimitAlarm,
        ulw: selectedSensorLimitTemplate.LimitsJSON.HighLimitWarning,
        llw: selectedSensorLimitTemplate.LimitsJSON.LowLimitWarning,
        scs: [],
        sca: [],
        pcs: [],
        pca: [],
      };

      temp_config[`a${sensor.Port}`] = {
        ...sensorConfig,
        t: sensor.SensorTypeID,
      };
    } else {
      sensorConfig = {
        t: "",
        f: "",
        fp: [],
        d: selectedSensorLimitTemplate.LimitsJSON.Delay,
        s: selectedSensorLimitTemplate.LimitsJSON.InputState,
      };
      temp_config[`d${sensor.Port}`] = {
        ...sensorConfig,
      };
    }

    let sensorConfigs = {
      SerialNumber: sensor.SubstationSerial,
      data: {
        ...substationData?.data,
        ...temp_config,
        t: date,
        i: 0,
      },
    };

    sensor.SchemaJSON = JSON.stringify(sensorConfig);
    let id = sensor.SubstationSerial;
    let SensorID = sensor.SensorID;
    let new_sensor = { ...sensor };
    delete new_sensor["SensorID"];
    delete new_sensor["createdAt"];
    delete new_sensor["updatedAt"];

    let data = {
      sensorData: new_sensor,
      configs: sensorConfigs,
      reason: reason,
    };
    const res = await editSensor({ id: SensorID, data });
    if (!res.error) await editSensorConfig({ id, sensorConfig: sensorConfigs });
    setSensorEdit(false);
    sensorToggleDrawer(false);
    sensorsDataRefetch();
    substationsDataRefetch();
  };

  const handleAddSensor = (Label, SerialNumber, Port, action) => {
    setSensor({
      ...sensor,
      SensorTypeLabel: Label,
      SubstationSerial: SerialNumber,
      Port: Port,
    });
    sensorToggleDrawer(action);
  };

  const handleSubstationDelete = async (substation, reason) => {
    const mappedSubstation = mappedSubstations?.find((eq) => {
      return eq.SubstationID === substation.SubstationID;
    });

    await addArchive({
      data: { Substation: [substation.SubstationID] },
      reason: reason,
    });

    if (mappedSubstation) {
      await deleteMappedSubstation({
        id: mappedSubstation.MappedSubstationID,
        data: { reason: reason },
      });
    }

    toggleDrawer(false);
    customSubstationArchiveDrawer(false);
  };
  return (
    <>
      {addArchiveSuccess && (
        <Toast
          message="Substation successfully archived"
          severity="success"
        />
      )}
      {addSubstationisSuccess && (
        <Toast
          message="Substation successfully added"
          severity="success"
        />
      )}
      {substationEditSuccess && (
        <Toast
          message="Substation successfully updated"
          severity="success"
        />
      )}
      {sensorConfigEditSuccess && (
        <Toast
          message="Substation successfully updated"
          severity="success"
        />
      )}
      {isSensorSuccess && (
        <Toast
          message="Sensor successfully added"
          severity="success"
        />
      )}
      {sensorEditSuccess && (
        <Toast
          message={`Sensor successfully ${delSensor ? "archived" : " updated"}`}
          severity="success"
        />
      )}
      {/* {sensorEditSuccess && delSensor && (
        <Toast message="Sensor successfully deactivated" severity="success" />
      )} */}
      {AddSensorError && (
        <Toast
          message="Sensor name is not unique"
          severity="error"
        />
      )}
      {addSensorArchiveSuccess && (
        <Toast
          message="Sensor successfully archived"
          severity="success"
        />
      )}
      {addSensorArchiveError && (
        <Toast
          message="Error while archiving sensor"
          severity="error"
        />
      )}

      {AddSubstationIsError && (
        <Toast
          message={msg}
          severity="error"
        />
      )}
      {isSensorConfigEditError && (
        <Toast
          message={msg}
          severity="error"
        />
      )}
      {substationsLoading ||
      sensorsLoading ||
      locationsLoading ||
      locationsEquipmentLoading ||
      loading ? (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      ) : (
        <>
          <SubstationsTable
            substations={substations}
            toggleDrawer={toggleDrawer}
            handleEdit={handleEdit}
            handleSensorEdit={handleSensorEdit}
            locationsHierarchy={locationsHierarchy}
            substationSensorsData={substationSensorsData}
            sensorLimitTemplates={sensorLimitTemplates}
            sensorTypes={sensorTypes}
            locations={locations}
            equipmentTypes={equipmentTypes}
            equipments={equipments}
            sensorToggleDrawer={sensorToggleDrawer}
            sensor={sensor}
            setSensor={setSensor}
            handleAddSensor={handleAddSensor}
            allowedEdit={allowedEdit}
            auth={auth}
            mappedSubstations={mappedSubstations}
            handleAlertSuppression={handleAlertSuppression}
            handleSubstationSupressAlarms={handleSubstationSupressAlarms}
          />
          <Box>
            <SubstationDrawer
              tempSubstation={tempSubstation}
              drawer={drawer}
              toggleDrawer={toggleDrawer}
              substationConfirmationDrawer={substationConfirmationDrawer}
              handleClick={handleClick}
              buttonValidation={buttonValidation}
              substation={substation}
              setSubstation={setSubstation}
              setSubstationUpdated={setSubstationUpdated}
              substationUpdated={substationUpdated}
              substationSensorsData={substationSensorsData}
              clearSubstation={clearSubstation}
              edit={substationEdit}
              locations={locations}
              handleSensorEdit={handleSensorEdit}
              sensorTypes={sensorTypes}
              sensorToggleDrawer={sensorConfirmationDrawer}
              handleAddSensor={handleAddSensor}
              customSubstationArchiveDrawer={customSubstationArchiveDrawer}
              archiveDrawer={substationArchiveDrawer}
              discardSettingsDrawer={discardSettingsDrawer}
              confirmationDrawer={confirmationDrawer}
              handleDelete={handleSubstationDelete}
              allChangesLoading={allChangesLoading}
            />
          </Box>
        </>
      )}
      <SensorDrawer
        substationcheck={true}
        sensorEdit={sensorEdit}
        clearSensor={clearSensor}
        tempSensor={tempSensor}
        handleClick={handleSensorClick}
        customerDrawer={sensorDrawer}
        sensorToggleDrawer={sensorToggleDrawer}
        customToggleDrawer={sensorConfirmationDrawer}
        deleteDrawer={sensorDeleteDrawer}
        customToggleSensorDeleteDrawer={customToggleSensorDeleteDrawer}
        handleDelete={handleDelete}
        sensorUpdated={sensorUpdated}
        setSensorUpdated={setSensorUpdated}
        confirmationDrawer={confirmationSensorDrawer}
        discardSettingsDrawer={discardSensorSettingsDrawer}
        sensor={sensor}
        setSensor={setSensor}
        sensorTypes={sensorTypes}
        sensorLimitTemplates={sensorLimitTemplates}
        substations={substations}
        equipments={equipments}
        selectedSensorLimitTemplate={selectedSensorLimitTemplate}
        setSelectedSensorLimitTemplate={setSelectedSensorLimitTemplate}
        setSubstationData={setSubstationData}
        handleLimitChange={handleLimitChange}
        sensorButtonValidation={sensorButtonValidation}
        allChangesLoading={allChangesLoading}
      />
      <SensorSuppressDrawer
        suppressDrawer={suppressDrawer}
        toggleSuppressDrawer={toggleSuppressDrawer}
        sensor={sensor}
        sensorTypes={suppressSensorTypes}
        sensorLimitTemplates={sensorLimitTemplates}
        clearSensor={clearSensor}
        handleSuppressDrawerEdit={handleSuppressDrawerEdit}
      />
      <SubstationAlarmSuppressDrawer
        substationAlarmSuppress={substationAlarmSuppress}
        substationAlarmSuppressDrawer={substationAlarmSuppressDrawer}
        toggleAlarmSuppressDrawer={toggleAlarmSuppressDrawer}
        sensor={sensor}
        sensorTypes={suppressSensorTypes}
        sensorLimitTemplates={sensorLimitTemplates}
        clearSensor={clearSensor}
        handleSuppressDrawerEdit={handleSuppressDrawerEdit}
        substationSensorsData={
          substationSensorsData[substationAlarmSuppress.SelectedRow?.SubstationID]
        }
      />
    </>
  );
};

export default SubstationSetup;
