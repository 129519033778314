import React from "react";
import { Box, Typography } from "@mui/material";
import ToolTipComponent from "../../BaseComponents/Tooltip";
import { findObjectByValue } from "../../../Utils/Tools";
import { useNavigate } from "react-router-dom";

export const EquipmentLocationLabel = ({
  row,
  mappedEquipments,
  permissionAllowed,
  locationsHierarchy,
}) => {
  const mappedEquipment = findObjectByValue(mappedEquipments, "EquipmentID", row?.EquipmentID);

  const tooltipTitle = mappedEquipment
    ? locationsHierarchy[row.LocationID]?.split(",").join("\n")
    : "Device not on map";

  const color = mappedEquipment && permissionAllowed ? "#00AEEF" : "rgba(0, 0, 0, 0.87)";

  const pointer = mappedEquipment && permissionAllowed && "pointer";

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (mappedEquipment && permissionAllowed) {
      navigate("/statusview", {
        state: { LocationID: row?.LocationID, EquipmentID: row?.EquipmentID },
      });
    }
  };

  return (
    <ToolTipComponent
      placement="bottom-start"
      title={tooltipTitle}
      Component={
        <Box sx={{ width: "fit-content" }}>
          <div onClick={() => handleNavigate(row)}>
            <Typography
              sx={{
                color: color,
                fontSize: "14px",
                lineHeight: "16px",
                textAlign: "left",
                cursor: pointer,
              }}
            >
              {locationsHierarchy[row?.LocationID]}
            </Typography>
          </div>
        </Box>
      }
    />
  );
};
