import React from "react";
import IconButtonComponent from "../BaseComponents/IconButton";
import ToolTipComponent from "../BaseComponents/Tooltip";

const DownloadIcon = () => {
  return (
    <ToolTipComponent
      placement="bottom-end"
      title="Edit user"
      Component={
        <IconButtonComponent
          sx={{
            marginRight: "20px",
            marginBottom: "0.6px",
          }}
          Component={
            <i
              class="fa-light fa-download"
              style={{
                "font-size": "1.2em",
                font: "normal normal 300 20px/25px Font Awesome 6 Pro",
              }}
            ></i>
          }
        ></IconButtonComponent>
      }
    />
  );
};

export default DownloadIcon;
