import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import ToolTipComponent from "../Tooltip";
import PillComponent from "../Pills";
import FilterFooter from "./FilterFooter";
import IconButtonComponent from "../IconButton";
import SensorTypeFilterField from "./SensorTypeFilterField";
import EquipmentTypeFilterField from "./EquipmentTypeFilterField";
import LocationsFilterField from "./LocationsFilterField";
import { AlarmStateFilter } from "./AlarmStateFilter";
import LabeledDateField from "./LabeledDateField";

export default function AlarmPaneFilter(props) {
  const { selectedAlarm, handleAlarmSelect } = props;
  const {
    handleSelectEquipmentType,
    selectedEquipmentTypes,
    equipments,
    handleSelectSensorType,
    sensorTypes,
    selectedSensors,
    handleSelectLocation,
    selectedLocations,
    locations,
    handleStartDateChange,
    handleEndDateChange,
    startDate,
    endDate,
  } = props;

  const {
    equipmentFilterCount,
    sensorTypeFilterCount,
    locationFilterCount,
    alarmFilterCount,
    clearFilter,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const localClearFilter = () => {
    clearFilter();
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filtersCount = () => {
    return equipmentFilterCount + sensorTypeFilterCount + locationFilterCount + alarmFilterCount;
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <ToolTipComponent
          placement="bottom-start"
          title={` ${
            filtersCount() > 0 ? "Filters (" + String(filtersCount()) + " applied)" : "Filter"
          } `}
          value="Filter"
          Component={
            <Box>
              <IconButtonComponent
                onClick={handleClick}
                size="small"
                aria-controls={open ? "equipment-filter" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                Component={
                  <>
                    <i
                      style={{ "font-size": "16px" }}
                      className="fa-light fa-sliders"
                    ></i>
                    {filtersCount() > 0 && (
                      <PillComponent
                        divStyle={{
                          position: "absolute",
                          top: "-7px",
                          right: "0",
                        }}
                        label={filtersCount()}
                        size="small"
                        blueAction={true}
                        style={{
                          padding: "4px !important",
                          fontSize: "10px !important",
                          height: "15px !important",
                          width: "15px !important",
                        }}
                      />
                    )}
                  </>
                }
              />
            </Box>
          }
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="equipment-filter"
        open={open}
        onClose={handleClose}
        // keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiList-root": {
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 12,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{
            maxHeight: "500px",
            overflow: "auto",
            padding: "16px",
            width: "552px",
          }}
        >
          <div>
            {/* <FormControlLabel
              control={
                <CheckBoxComponent
                  size="small"
                  checked={
                    // selectedAlarm.findIndex((item) => item == "1") >
                    // -1
                    true
                  }
                  onChange={null}
                  value="1"
                  name="technical-alarms"
                  Label="Show Technical Alarms"
                />
              }
            /> */}
            <AlarmStateFilter
              data={["Warning", "Alarm"]}
              selectedAlarm={selectedAlarm}
              handleAlarmSelect={handleAlarmSelect}
            />
            <EquipmentTypeFilterField
              handleSelectEquipmentType={handleSelectEquipmentType}
              selectedEquipmentTypes={selectedEquipmentTypes}
              equipments={equipments}
            />
          </div>

          <SensorTypeFilterField
            handleSelectSensorType={handleSelectSensorType}
            sensorTypes={sensorTypes}
            selectedSensors={selectedSensors}
          />

          <LocationsFilterField
            handleSelectLocation={handleSelectLocation}
            selectedLocations={selectedLocations}
            locations={locations}
          />

          <Box
            sx={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "space-between",
            }}
          >
            <LabeledDateField
              Width="100%"
              date={startDate}
              handleDateChange={handleStartDateChange}
              isDateFieldsEnabled={false}
              Label="Updated after"
            ></LabeledDateField>

            <LabeledDateField
              Width="100%"
              date={endDate}
              handleDateChange={handleEndDateChange}
              isDateFieldsEnabled={false}
              Label="Updated before"
            ></LabeledDateField>
          </Box>
        </div>
        <FilterFooter clearFilter={localClearFilter} />
      </Menu>
    </React.Fragment>
  );
}
