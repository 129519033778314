import { apiSlice } from "../API";

export const userGroupUsersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserGroupUsers: builder.query({
      query: () => "/userGroupUser",
      providesTags: ["UserGroupUsers"],
    }),
    addUserGroupUsers: builder.mutation({
      query(user) {
        console.log("🚀 ~ file: userGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice", user);
        return {
          url: "/userGroupUser",
          method: "POST",
          body: user,
        };
      },
      invalidatesTags: ["UserGroupUsers"],
    }),
    getUserGroupUsers: builder.query({
      query: (id) => ({
        url: `/userGroupUser/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["UserGroupUsers"],
    }),
    deleteUserGroupUsers: builder.mutation({
      query: (id) => ({
        url: `/userGroupUser/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserGroupUsers"],
    }),
  }),
});

export const {
  useFindUserGroupUsersQuery,
  useAddUserGroupUsersMutation,
  useGetUserGroupUsersQuery,
  useDeleteUserGroupUsersMutation,
} = userGroupUsersSlice;
