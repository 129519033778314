import React, { useEffect } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
// IMAGE LOADER TO CANVAS COMPONENT MIGHT EXTRACT TO SEPERATE COMPONENT FILE
const BackgroundImage = (props) => {
  const [image, status] = useImage(props.src);
  useEffect(() => {
    props.setLoading(status);
  }, [image]);

  useEffect(() => {
    let timeout;
    if (image == undefined) {
      timeout = setTimeout(() => {
        props.setLoading("failed");
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [image]);

  return (
    <Image
      image={image}
      width={image?.naturalWidth}
      height={image?.naturalHeight}
      draggable={false}
    />
  );
};

export default BackgroundImage;
