import { apiSlice } from "../API";

export const substationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSubstations: builder.query({
      query: () => "/substation",
      providesTags: ["Substation", "Sensor"],
      transformResponse: (response, meta, arg) => {
        const customerID = localStorage.getItem("customerID");
        return response.success.data.filter(
          (item) => item.IsArchive === false && item.CustomerID === customerID
        );
      },
    }),
    findActiveSubstations: builder.query({
      query: () => "/substation",
      providesTags: ["Substation"],
      transformResponse: (response, meta, arg) => {
        const customerID = localStorage.getItem("customerID");
        return response.success.data.filter(
          (item) => item.IsActive && item.CustomerID === customerID
        );
      },
    }),
    addSubstation: builder.mutation({
      query: (substation) => ({
        url: "/substation",
        method: "POST",
        body: substation,
      }),
      invalidatesTags: ["Substation"],
    }),
    editSubstation: builder.mutation({
      query: ({ id, substation }) => ({
        url: `/substation/${id}`,
        method: "PUT",
        body: substation,
      }),
      invalidatesTags: ["Substation", "Sensor"],
    }),
    //DELETE ENDPOINT USE NOT RECOMMENDED
    deleteSubstaion: builder.mutation({
      query: (id) => ({
        url: `/substation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Substation"],
    }),
  }),
});

export const {
  useFindSubstationsQuery,
  useFindActiveSubstationsQuery,
  useAddSubstationMutation,
  useEditSubstationMutation,
} = substationsSlice;
