import moment from "moment";
import React from "react";
import { formatDate } from "../../../Utils/Tools";
import ToolTipComponent from "../Tooltip";
import { Box } from "@mui/material";

const ConnectionIcon = ({ ConnectionType, ConnectionState, time }) => {
  let tooltipLabel = null;
  let iconClass = "";
  switch (ConnectionType) {
    case "Wifi":
      iconClass =
        ConnectionState === "NoSignal"
          ? "fa-xmark fa-lg"
          : ConnectionState === "WeekSignal"
          ? "fa-wifi-strong"
          : ConnectionState === "FairSignal"
          ? "fa-wifi-strong"
          : ConnectionState === "StrongSignal"
          ? "fa-wifi-strong"
          : null;
      break;
    case "Cellular":
      iconClass =
        ConnectionState === "NoSignal"
          ? "fa-signal-slash"
          : ConnectionState === "WeekSignal"
          ? "fa-signal-weak"
          : ConnectionState === "FairSignal"
          ? "fa-signal-fair"
          : ConnectionState === "GoodSignal"
          ? "fa-signal-good"
          : ConnectionState === "StrongSignal"
          ? "fa-signal-strong"
          : null;
      break;
    case "Ethernet":
      iconClass =
        ConnectionState === "NoSignal"
          ? "fa-ethernet-slash"
          : ConnectionState === "OnSignal"
          ? "fa-ethernet"
          : null;
      break;
    default:
      return null;
  }

  if (iconClass === "fa-xmark fa-lg") {
    if (time) {
      const currentDate = new Date();
      const subtractedDate = new Date(currentDate);
      const lastConnection = new Date(moment(time).utc().valueOf());
      subtractedDate.setMinutes(currentDate.getMinutes() - 30);

      if (lastConnection < subtractedDate) {
        tooltipLabel = `Last connected ${formatDate(lastConnection, "dd-MMM-yyyy, HH:mm")} UTC`;
      }
    }
  }

  return (
    <>
      {tooltipLabel ? (
        <ToolTipComponent
          title={tooltipLabel}
          placement="bottom-start"
          styles={{
            height: "59px",
            maxWidth: "305px !important",
            paddingTop: "19px !important",
          }}
          Component={
            <Box sx={{ marginRight: "10px" }}>
              <i
                className={`fa-regular ${iconClass}`}
                style={{
                  fontSize: "20px",
                  lineHeight: "25px",
                  color: "#222934",
                  textAlign: "center",
                }}
              />
            </Box>
          }
        />
      ) : (
        <i
          className={`fa-regular ${iconClass}`}
          style={{
            fontSize: "20px",
            lineHeight: "25px",
            color: "#222934",
            textAlign: "center",
          }}
        />
      )}
    </>
  );
};

export default ConnectionIcon;
