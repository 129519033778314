import { limitDelayMappings, suppressedUntilFormatDate } from "./constants";
import moment from "moment";

const filterObjectByValue = (array, key, value) => {
  return array.filter((object) => {
    return object[key] === value;
  });
};
const filterProgramStepsByProgramId = (array, key) => {
  return array.filter((val) => val.ProgramID === key)[0].ProgramSteps;
};

const findObjectByValue = (array, key, value, debug = null) => {
  if (array) {
    let res = array.find((object) => object[key] === value);
    if (debug === true) console.log("FROM TOOLS", res);
    return res;
  }
};

const checkHasAuthPermissionsByEntity = (
  entities,
  targetEntity,
  permissions = ["ReadOnly", "Edit"] // by default we check read/write
) => {
  const target = entities.find((element) => element.element === targetEntity);

  if (!target) {
    return null;
  }

  return permissions.includes(target.state);
};

const isNumeric = (str) => {
  return /^\s*[\d\+\.\-\(\)]*\s*$/.test(str);
};

/**
 * Determines the color coding status based on the given sensors and isActive flag.
 *
 * @param {Array} sensors - The array of sensors.
 * @param {boolean} isActive - Flag indicating if the sensors are active. Default is true.
 * @returns {string} The color coding status: "inactive", "none", "success", "suppressed", "error", "warning", or "unacknowledged".
 */
const getColorCodingStatus = (sensors, isActive = true) => {
  if (!isActive) return "inactive";
  const noSensors = !sensors?.length;
  const hasSuccess = sensors?.every(
    (sensor) =>
      sensor?.LiveData?.State === 0 ||
      (sensor?.LiveData?.State === 4 && sensor?.LiveData?.Acknowledged)
  );
  const hasAlarm = sensors?.some(
    (sensor) => sensor?.LiveData?.State === 2 && !sensor?.LiveData?.Suppressed
  );
  const hasWarning = sensors?.some(
    (sensor) => sensor?.LiveData?.State === 1 && !sensor?.LiveData?.Suppressed
  );
  const hasOneActiveAndNoAlarm = sensors?.some(
    (sensor) =>
      sensor?.LiveData?.State === 0 ||
      (sensor?.LiveData?.State === 4 && sensor?.LiveData?.Acknowledged)
  );
  const unacknowledgedPriorAlarm = sensors?.some(
    (sensor) =>
      sensor?.LiveData?.State === 4 &&
      sensor?.LiveData?.Acknowledged === null &&
      !sensor?.LiveData?.Suppressed
  );
  const hasOneSuppressed = sensors?.some((sensor) => sensor?.LiveData?.Suppressed);
  const areInactive = sensors?.every(
    (sensor) =>
      sensor?.IsActive === false ||
      !Object.keys(sensor).includes("LiveData") ||
      (!Object.keys(sensor?.LiveData).includes("State") && sensor?.LiveData.Suppressed === null)
  );

  if (noSensors) return "none";
  if (areInactive) return "inactive";
  if (hasAlarm) return "error";
  if (!hasAlarm && hasWarning) return "warning";
  if (!hasAlarm && !hasWarning && unacknowledgedPriorAlarm) return "prior";
  if (!hasAlarm && !hasWarning && !unacknowledgedPriorAlarm && hasOneSuppressed)
    return "suppressed";
  if (hasSuccess && !hasOneSuppressed) return "success";
  if (
    hasOneActiveAndNoAlarm &&
    !hasAlarm &&
    !hasWarning &&
    !unacknowledgedPriorAlarm &&
    !hasOneSuppressed
  )
    return "success";

  /**
   * TODO:
   * For a substation, we also have to consider the substation’s “internal”sensors
   * (I.e. battery, connectivity) which cannot be deactivated. So for a substation, unless
   * the substation itself is deactivated, it’s bubble should reflect the hierarchy as per the above
   * https://chaione.atlassian.net/browse/XILDEV-1162
   *
   * https://chaione.atlassian.net/browse/XILDEV-1162
   * must be done before applying the changes to substation view tab.
   */
};
/**
 * Determines the color coding status for a single sensor
 * @param {object} liveData the LiveData object that belongs to the sensor
 * @param {boolean} isActive - Flag indicating if the sensor is active.
 */
const getColorCodingStatusSingleSensor = (liveData = {}, isActive = true) => {
  if (!isActive || !Object.keys(liveData).length === 0 || !Object.keys(liveData).includes("State"))
    return "inactive";
  if (liveData.Suppressed) return "suppressed";
  if (liveData.State === 2) return "error";
  if (liveData.State === 1) return "warning";
  if (liveData.State === 4 && liveData?.Acknowledged === null) return "prior";
};
const isAlphabetic = (str) => {
  return /[a-zA-Z]/.test(str);
};

const isPhoneNumber = (str) => {
  return /^\+\d\(\d{3}\)\d{3}-\d{4}$/.test(str);
};

function formatDate(date, format, utc = true) {
  date = typeof date == "string" ? new Date(date) : date;
  var MMMM = [
    "\x00",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var MMM = [
    "\x01",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  function ii(i, len) {
    var s = i + "";
    len = len || 2;
    while (s.length < len) s = "0" + s;
    return s;
  }

  var y = utc ? date.getUTCFullYear() : date.getFullYear();
  format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
  format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
  format = format.replace(/(^|[^\\])y/g, "$1" + y);

  var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
  format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
  format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
  format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
  format = format.replace(/(^|[^\\])M/g, "$1" + M);

  var d = utc ? date.getUTCDate() : date.getDate();
  format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
  format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
  format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
  format = format.replace(/(^|[^\\])d/g, "$1" + d);

  var H = utc ? date.getUTCHours() : date.getHours();
  format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
  format = format.replace(/(^|[^\\])H/g, "$1" + H);

  var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
  format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
  format = format.replace(/(^|[^\\])h/g, "$1" + h);

  var m = utc ? date.getUTCMinutes() : date.getMinutes();
  format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
  format = format.replace(/(^|[^\\])m/g, "$1" + m);

  var s = utc ? date.getUTCSeconds() : date.getSeconds();
  format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
  format = format.replace(/(^|[^\\])s/g, "$1" + s);

  var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
  format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])f/g, "$1" + f);

  var T = H < 12 ? "AM" : "PM";
  format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
  format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

  var t = T.toLowerCase();
  format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
  format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

  var tz = -date.getTimezoneOffset();
  var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
  if (!utc) {
    tz = Math.abs(tz);
    var tzHrs = Math.floor(tz / 60);
    var tzMin = tz % 60;
    K += ii(tzHrs) + ":" + ii(tzMin);
  }
  format = format.replace(/(^|[^\\])K/g, "$1" + K);

  var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
  format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
  format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

  format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
  format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

  format = format.replace(/\\(.)/g, "$1");

  return format;
}

function locationsString(location, locations) {
  if (location) {
    if (!location.ParentID) {
      return location.LocationName;
    }
    let parentLocation = locations.find((l) => l.LocationID === location.ParentID);
    return location.LocationName + ", " + locationsString(parentLocation, locations);
  }
}

const checkMaps = (location, locations) => {
  if (location) {
    if (location.MapFile) {
      return location;
    }
    if (!location.ParentID) {
      return;
    }
    let parentLocation = locations.find((l) => l.LocationID === location.ParentID);
    return checkMaps(parentLocation, locations);
  }
};

function order(array) {
  if (Array.isArray(array)) {
    return array.sort((a, b) => a.label.localeCompare(b.label));
  } else {
    console.log(array);
  }
}
function createLocationTree(locations, parent = null) {
  return locations
    .filter((location) => location.ParentID === parent)
    .map((location) => ({
      ...location,
      childNodes: createLocationTree(locations, location.LocationID),
    }));
}

function createLocationCombinations(locations, ParentID = null) {
  let result = {};
  let children = locations.filter((location) => location.ParentID === ParentID);
  if (children.length === 0) {
    if (ParentID !== null) {
      result[ParentID] = [ParentID];
    }
    return result;
  }

  for (let child of children) {
    if (result[child.ParentID] == undefined) {
      result[child.ParentID] = [];
    }
    result[child.ParentID].push(child.LocationID);
    let childResult = createLocationCombinations(locations, child.LocationID);
    Object.keys(childResult).forEach((key) => {
      if (result[key]) {
        result[key] = result[key].concat(childResult[key]);
      } else {
        result[key] = childResult[key];
      }
    });
    if (result[child.ParentID]) {
      result[child.ParentID] = Array.from(
        new Set(result[child.ParentID].concat(childResult[child.LocationID] || []))
      );
    }
  }
  if (ParentID !== null) {
    result[ParentID].push(ParentID);
  }
  return result;
}

const generateTimeStampFormat = (updatedAt) => {
  let d = new Date(updatedAt);

  let ye = new Intl.DateTimeFormat("en", {
    year: "numeric",
  }).format(d);
  let mo = new Intl.DateTimeFormat("en", {
    month: "short",
  }).format(d);
  let da = new Intl.DateTimeFormat("en", {
    day: "2-digit",
  }).format(d);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  if (hours < 10) hours = String(hours).padStart(2, "0");

  if (minutes < 10) minutes = String(minutes).padStart(2, "0");
  return `${da}-${mo}-${ye}, ${hours}:${minutes}`;
};

const MapDragBoundFunc = (pos) => {
  const MAX = 350;
  const MIN = -350;

  let x = pos.x;
  let y = pos.y;
  if (x < MIN) {
    x = MIN;
  }
  if (x > MAX) {
    x = MAX;
  }
  if (y < MIN) {
    y = MIN;
  }
  if (y > MAX) {
    y = MAX;
  }
  return { x: x, y: y };
};

const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/[^\d]/g, "");

const deepCopy = (obj) => {
  var rv;

  switch (typeof obj) {
    case "object":
      if (obj === null) {
        // null => null
        rv = null;
      } else {
        switch (toString.call(obj)) {
          case "[object Array]":
            // It's an array, create a new array with
            // deep copies of the entries
            rv = obj.map(deepCopy);
            break;
          case "[object Date]":
            // Clone the date
            rv = new Date(obj);
            break;
          case "[object RegExp]":
            // Clone the RegExp
            rv = new RegExp(obj);
            break;
          // ...probably a few others
          default:
            // Some other kind of object, deep-copy its
            // properties into a new object
            rv = Object.keys(obj).reduce(function (prev, key) {
              prev[key] = deepCopy(obj[key]);
              return prev;
            }, {});
            break;
        }
      }
      break;
    default:
      // It's a primitive, copy via assignment
      rv = obj;
      break;
  }
  return rv;
};

function isSensorWarning(data) {
  return (
    data.every((sensor) => typeof sensor == "object" && sensor.LiveData?.State != 2) &&
    data.some(
      (sensor) =>
        typeof sensor == "object" && sensor.LiveData?.State == 1 && !sensor.LiveData.Suppressed
    )
  );
}

function isSensorAlarm(data) {
  return data.some(
    (sensor) =>
      typeof sensor == "object" && sensor.LiveData?.State == 2 && !sensor.LiveData.Suppressed
  );
}

function checkSensorState(data) {
  return data.some((sensor) => typeof sensor == "object" && sensor.liveData?.State != 0);
}

function checkStateByReports(data) {
  return data.some((sensor) => typeof sensor == "object" && sensor?.State == 2);
}

function checkWarningStateByReports(data) {
  return data.some((sensor) => typeof sensor == "object" && sensor?.State == 1);
}

const validateUIState = (Authorizations, PageName, ElementName) => {
  if (Object.keys(Authorizations).length > 0)
    if (PageName == "setup") {
      const pageNameAuthorizations = Authorizations[PageName];
      return pageNameAuthorizations
        .filter((a) => a.element == ElementName)
        .some((a) => a?.state == "Edit");
    } else {
      return Authorizations[PageName][0]?.state == "Edit" ? true : false;
    }
};

function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) return n;

  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}

function getMax(data) {
  if (data.length == 0) return 0;

  return Math.max(
    ...data.map((sensorConfig) => {
      return sensorConfig.Stats;
    })
  );
}

function getMin(data) {
  if (data.length == 0) return 0;

  return Math.min(
    ...data.map((sensorConfig) => {
      return sensorConfig.Stats;
    })
  );
}

function calculateMeanKineticTemperature(temperatureReadings, timeIntervals) {
  if (temperatureReadings.length !== timeIntervals.length) {
    throw new Error("The number of temperature readings and time intervals must be the same.");
  }

  const k = 273.15; // Kelvin conversion constant
  let sum = 0;

  for (let i = 0; i < temperatureReadings.length; i++) {
    const temperatureCelsius = temperatureReadings[i];
    const timeHours = timeIntervals[i];

    // Convert temperature to Kelvin
    const temperatureKelvin = temperatureCelsius + k;

    // Calculate the kinetic temperature for the time interval
    const kineticTemperature = temperatureKelvin * Math.exp((-0.693 * timeHours) / 8760);

    sum += kineticTemperature;
  }

  // Calculate the mean kinetic temperature
  const meanKineticTemperature = sum / temperatureReadings.length;

  // Convert mean kinetic temperature back to Celsius for the result
  const meanKineticTemperatureCelsius = meanKineticTemperature - k;

  return meanKineticTemperatureCelsius;
}
const NormalizeUnit = (unit) => {
  const specialCharactersRegex = /₍\s*[^₀₁₂₃₄₅₆₇₈₉\s]+\s*₎|[\u2080-\u2089]|%/g;

  const specialCharacterMap = {
    "%": "",
    // Add more special characters and their corresponding replacements here
  };

  return unit.replace(specialCharactersRegex, (match) => {
    if (match in specialCharacterMap) {
      return specialCharacterMap[match];
    } else {
      return match.replace(/[\u2080-\u2089]/g, (subMatch) =>
        String.fromCharCode(subMatch.charCodeAt(0) - 0x2080 + 0x30)
      );
    }
  });
};

/**
 * conditionally extracts left or right side of getLimitValue function output string
 */
const extractLimitTemplateValue = (inputString, selector) => {
  const regex = /([\s\S]+?) \| ([\s\S]+)/;
  const match = inputString.match(regex);
  if (!match) {
    return "Empty";
  }
  const [, leftSide, rightSide] = match;
  if (selector === "limit") {
    return leftSide.trim();
  }
  if (selector === "delay") {
    return rightSide.trim();
  }
};

const getLimitValue = (row, sensorLimitTemplate, sensorType, limitAttr1) => {
  try {
    const [limitAttr2, delayAttr1, delayAttr2] = limitDelayMappings[limitAttr1];
    const limitJson =
      sensorLimitTemplate != undefined && sensorLimitTemplate.label != "Custom"
        ? JSON.parse(sensorLimitTemplate?.LimitsJSON)
        : JSON.parse(row.SchemaJSON);
    const limitValue = limitJson[limitAttr1] || limitJson[limitAttr2];
    const result = `${limitValue} ${sensorType.Unit || ""} | ${
      limitJson[delayAttr1] || limitJson[delayAttr2]
    } min`;
    return limitValue ? result : "";
  } catch (error) {
    console.error("An error occurred while getting the limit value:", error);
    return "";
  }
};

const getSensorLabel = (sensor, sensorLimitTemplates, sensorTypes) => {
  const sensorLimitTemplate =
    sensor.SensorLimitTemplateID !== "111a11b1-a111-4111-aa11-1eb1dbd1a11a"
      ? findObjectByValue(sensorLimitTemplates, "value", sensor.SensorLimitTemplateID)
      : undefined;

  const sensorType = findObjectByValue(sensorTypes, "value", sensor.SensorTypeID);
  const lowLimit = extractLimitTemplateValue(
    getLimitValue(sensor, sensorLimitTemplate, sensorType, "LowLimitAlarm"),
    "limit"
  );

  const highLimit = extractLimitTemplateValue(
    getLimitValue(
      sensor,
      sensorLimitTemplate,
      sensorType,
      sensor.SensorTypeLabel === "Analog" ? "HighLimitAlarm" : "InputState"
    ),
    "limit"
  );
  return `${sensor.SensorLabel} | ${
    sensor?.LiveData?.CurrentState ||
    parseFloat(sensor?.LiveData?.Current)
      .toFixed(2)
      .concat(` ${sensorType?.Unit || "unit"}`) ||
    "test"
  } | Alarm Limits [${lowLimit} to ${highLimit}] \n`;
};
/**
 *
 * @param {Date} ms -The date to be converted
 * @param {boolean} until - Flag indicating if the date is returned in untilSuppresion format or other format
 * @returns
 */
function convertMsToReadableDate(ms, until) {
  const date = moment.utc(Number(ms));
  const format = until ? suppressedUntilFormatDate + " [UTC]" : "DD-MMM-yyyy, HH:mm:ss [UTC]";
  return date.format(format);
}
/**
 *
 * @param {Array} faultedEquipments -The faultedEquipments array
 * @returns {object} An object containing the quantity of equipments to be display
 */
const getFaultedEquipmentsLength = (faultedEquipments) => {
  const alarmEquipments = faultedEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) => sensor?.LiveData?.State === 2 && !sensor?.LiveData?.Suppressed
    )
  );
  const warningEquipments = faultedEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) => sensor?.LiveData?.State === 1 && !sensor?.LiveData?.Suppressed
    )
  );
  const UnAcknowlegedEquipments = faultedEquipments?.filter((equipment) =>
    equipment?.Sensors.some(
      (sensor) =>
        sensor?.LiveData?.State === 4 &&
        !sensor?.LiveData?.Acknowledged &&
        !sensor?.LiveData.Suppressed
    )
  );
  const suppressedEquipments = faultedEquipments?.filter((equipment) =>
    equipment?.Sensors.some((sensor) => sensor?.LiveData?.Suppressed)
  );
  if (alarmEquipments?.length) {
    return alarmEquipments?.length;
  }
  if (warningEquipments?.length) {
    return warningEquipments?.length;
  }
  if (UnAcknowlegedEquipments?.length) {
    return UnAcknowlegedEquipments?.length;
  }
  if (suppressedEquipments?.length) {
    return suppressedEquipments?.length;
  }
  return 0;
};
function getTimezoneAbbreviation(date) {
  const timezoneAbbreviation = moment(date).tz('UTC').format('z');
  return timezoneAbbreviation;
}
export {
  filterObjectByValue,
  filterProgramStepsByProgramId,
  findObjectByValue,
  formatDate,
  locationsString,
  order,
  createLocationTree,
  createLocationCombinations,
  generateTimeStampFormat,
  MapDragBoundFunc,
  deepCopy,
  isNumeric,
  isAlphabetic,
  isPhoneNumber,
  isSensorWarning,
  isSensorAlarm,
  checkSensorState,
  checkMaps,
  validateUIState,
  getStandardDeviation,
  checkStateByReports,
  checkWarningStateByReports,
  getMax,
  getMin,
  calculateMeanKineticTemperature,
  NormalizeUnit,
  cleanPhoneNumber,
  getLimitValue,
  extractLimitTemplateValue,
  getColorCodingStatus,
  checkHasAuthPermissionsByEntity,
  getSensorLabel,
  getColorCodingStatusSingleSensor,
  convertMsToReadableDate,
  getFaultedEquipmentsLength,
  getTimezoneAbbreviation,
};
