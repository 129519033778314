import { useRef, useEffect, useState } from "react";

/**
 * Custom hook for smooth scrolling to a table row
 */
export const useScrollToTableRow = () => {
  const rowRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleSelectRowIndex = (idx) => {
    setSelectedRowIndex(idx);
  };

  useEffect(() => {
    if (selectedRowIndex) {
      rowRef.current = document.querySelector(`[data-rowindex="${selectedRowIndex}"]`);
    }
  }, [selectedRowIndex]);

  useEffect(() => {
    if (rowRef?.current !== null && selectedRowIndex) {
      rowRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [rowRef, selectedRowIndex]);

  return {
    handleSelectRowIndex,
  };
};
