import { apiSlice } from "../API";

export const fakeSensorOOBSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findfakeSensorOOBs: builder.query({
      query: () => "/fakeSensorOOB",
      providesTags: ["fakeSensorOOB"],
    }),
    getfakeSensorOOB: builder.query({
      query: (id) => ({
        url: `/fakeSensorOOB/${id}`,
      }),
    }),
    addfakeSensorOOB: builder.mutation({
      query: (fakeSensorOOB) => ({
        url: "/fakeSensorOOB",
        method: "POST",
        body: fakeSensorOOB,
      }),
      invalidatesTags: ["fakeSensorOOB"],
    }),
    editfakeSensorOOB: builder.mutation({
      query: ({ id, data }) => ({
        url: `/fakeSensorOOB/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["fakeSensorOOB"],
    }),
    deletefakeSensorOOB: builder.mutation({
      query: (id) => ({
        url: `/fakeSensorOOB/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["fakeSensorOOB"],
    }),
  }),
});

export const {
  useFindfakeSensorOOBsQuery,
  useGetfakeSensorOOBQuery,
  useAddfakeSensorOOBMutation,
  useEditfakeSensorOOBMutation,
  useDeletefakeSensorOOBMutation,
} = fakeSensorOOBSlice;
