import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import DrawerComponent from "../Drawer";
import RequireActionDrawer from "../../RightDrawer/RequireActionDrawer";
import RequireAuthenticationDrawer from "../../RightDrawer/RequireAuthenticationDrawer";
import DeactivateDrawer from "../../RightDrawer/DeactivateDrawer";
import DiscardDrawer from "../../RightDrawer/DiscardDrawer";
import { useSelector } from "react-redux";
import LoadingSpinner from "@Components/LoadingSpinner";

const ResourceDrawerComponent = forwardRef((props, ref) => {
  const {
    title,
    submitButtonTitle,
    drawer,
    disableDelete,
    toggleDrawer,
    handleSubmit,
    buttonValidation,
    edit,
    displayFooter,
    deactivateToggleDrawer,
    closeToast,
    deactivateButtonTitle,
    discardButtonTitle,
    deactivateButtonColor,
    deleteDrawer,
    handleDiscard,
    handleDelete,
    loadItem,
    archiveButton,
    item,
    setUpdated,
    confirmationDrawer,
    discardSettingsDrawer,
    deleteTitle,
    heading,
    headingNote,
    requireActionDrawerCalled,
    itemButtonValidation,
    skipSequence,
    allChangesLoading,
    setArchiveDrawer,
    unArchiveSelectedItems,
    actionLabel,
  } = props;

  const auth = useSelector((state) => state.auth);

  const [requiredCredentials, setRequiredCredentials] = useState({
    RequireActionReason: auth.RequireActionReason,
    RequireAlarmReason: auth.RequireAlarmReason,
    RequireAuthentication: auth.RequireAuthentication,
  });

  const [action, setAction] = useState("");
  const [requireActionDrawer, setRequireActionDrawer] = useState(
    requireActionDrawerCalled || false
  );
  const [requireAuthenticationDrawer, setRequireAuthenticationDrawer] = useState(false);
  const [reason, setReason] = useState({ value: null, label: "" });
  const [customReason, setCustomReason] = useState("");
  const [discardSave, setDiscardSave] = useState("");

  const handleReason = (event) => {
    if (event.label !== "Custom Options") {
      setCustomReason("");
    }
    setReason(event);
  };

  const handleCustomReason = (event) => {
    setCustomReason(event.target.value);
  };

  useEffect(() => {
    if (!requireActionDrawer) {
      setReason({ value: null, label: "" });
    }
  }, [requireActionDrawer]);

  useEffect(() => {
    setRequireActionDrawer(requireActionDrawerCalled || false);
  }, [requireActionDrawerCalled]);

  const requireActionToggleDrawer = (open) => {
    if (requiredCredentials.RequireActionReason) {
      setRequireActionDrawer(open);
      setArchiveDrawer?.(open);
    }
  };

  const requireAuthenticationToggleDrawer = () => {
    if (requiredCredentials.RequireAuthentication) {
      setRequireAuthenticationDrawer(true);
    }
  };

  const clearActionDrawer = () => {
    setReason({ value: null, label: "" });
    setRequireActionDrawer(false);
  };

  const clearAuthenticationDrawer = () => {
    setRequireAuthenticationDrawer(false);
  };

  const handleSubmitClick = () => {
    setAction("Submit");
    if (skipSequence !== undefined && skipSequence()) {
      handleSubmit();
    } else if (requiredCredentials.RequireActionReason) {
      setRequireActionDrawer(true);
    } else {
      handleActionReasonClick("Submit");
    }
  };

  const handleDeleteClick = (type) => {
    setAction("Delete");
    if (requiredCredentials.RequireActionReason) {
      setRequireActionDrawer(true);
    } else {
      handleActionReasonClick("Delete");
    }
  };

  const handleActionReasonClick = (actionPassed) => {
    if (action === "Submit" || actionPassed === "Submit") {
      confirmationDrawer && confirmationDrawer(false);
      handleSubmit(customReason.length > 0 ? customReason : reason.label, discardSave);
      setDiscardSave("");
    }
    if (action === "Delete" || action === "Remove" || actionPassed === "Delete") {
      handleDelete(item, customReason.length > 0 ? customReason : reason.label);
    }

    if (actionPassed == "archive") {
      unArchiveSelectedItems(reason.label);
    }

    setRequireActionDrawer(false);
    setReason("");
  };

  useImperativeHandle(ref, () => ({
    handleSubmitClick: handleSubmitClick,
  }));
  return (
    <>
      {allChangesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <DrawerComponent
            archiveButton={archiveButton}
            toggleDrawer={toggleDrawer}
            drawer={drawer}
            title={title}
            disableDelete={disableDelete}
            buttonTitle={submitButtonTitle}
            displayFooter={displayFooter}
            edit={edit}
            handleClick={handleSubmitClick}
            buttonValidation={buttonValidation}
            closeToast={closeToast}
            deactivateToggleDrawer={deactivateToggleDrawer}
            deactivateButtonTitle={deactivateButtonTitle}
            deactivateButtonColor={deactivateButtonColor}
          >
            {props.children}
          </DrawerComponent>
          <DeactivateDrawer
            drawer={deleteDrawer}
            toggleDrawer={deactivateToggleDrawer}
            title={deleteTitle}
            heading={heading}
            headingNote={headingNote}
            edit={edit}
            item={item}
            handleDelete={handleDeleteClick}
            closeToast={deactivateToggleDrawer}
            deactivateButtonTitle={deactivateButtonTitle}
            deactivateButtonColor={deactivateButtonColor}
          />
          <DiscardDrawer
            discardSettingsDrawer={discardSettingsDrawer}
            confirmationDrawer={confirmationDrawer}
            edit={edit}
            loadItem={loadItem}
            setUpdated={setUpdated}
            handleClick={handleSubmitClick}
            handleDiscard={handleDiscard}
            buttonValidation={buttonValidation}
            itemButtonValidation={itemButtonValidation}
            discardButtonTitle={discardButtonTitle}
          />
          <RequireActionDrawer
            drawer={requireActionDrawer}
            toggleDrawer={requireActionToggleDrawer}
            closeToast={clearActionDrawer}
            handleClick={handleActionReasonClick}
            reason={reason}
            customReason={customReason}
            handleReason={handleReason}
            handleCustomReason={handleCustomReason}
            actionLabel={actionLabel}
          />
          <RequireAuthenticationDrawer
            drawer={requireAuthenticationDrawer}
            toggleDrawer={requireAuthenticationToggleDrawer}
            closeToast={clearAuthenticationDrawer}
          />
        </>
      )}
    </>
  );
});

export default ResourceDrawerComponent;
