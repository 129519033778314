import { apiSlice } from "../API";

export const userGroupAuthorizationGroupSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserGroupAuthorizationGroups: builder.query({
      query: () => "/userGroupAuthorizationGroup",
      providesTags: ["userGroupAuthorizationGroup"],
    }),
    addUserGroupAuthorizationGroup: builder.mutation({
      query(userGroupAuthorizationGroup) {
        console.log(
          "🚀 ~ file: userGroupAuthorizationGroupsSlice.js ~ line 11 ~ query ~ userGroup from slice",
          userGroupAuthorizationGroup
        );
        return {
          url: "/userGroupAuthorizationGroup",
          method: "POST",
          body: userGroupAuthorizationGroup,
        };
      },
      invalidatesTags: ["userGroupAuthorizationGroup"],
    }),
    getUserGroupAuthorizationGroup: builder.query({
      query: (id) => ({
        url: "userGroupAuthorizationGroup",
        method: `/userGroupAuthorizationGroup/${id}`,
      }),
      invalidatesTags: ["userGroupAuthorizationGroup"],
    }),
  }),
});

export const {
  useFindUserGroupAuthorizationGroupsQuery,
  useAddUserGroupAuthorizationGroupMutation,
  useGetUserGroupAuthorizationGroupQuery,
} = userGroupAuthorizationGroupSlice;
