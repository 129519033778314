import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";

const DatePickerComponent = (props) => {
  const { label, value, onChange, disabled } = props;

  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="dd-MMM-yyyy"
        defaultValue={null}
        value={value}
        onChange={onChange}
        disabled={disabled || false}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#222934",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                fontSize: "16px",
                border: "1px solid #9CA1A6",
                borderRadius: "4px",
                ":hover": { border: "1px solid #17455E", borderRadius: "4px" },
                height: props.height,
              },
              "&.Mui-focused": {
                boxShadow: "#00AEEF 0px 0px 0px 4px",
                border: "1px solid #17455E",
              },
              "& .MuiInputBase-root.Mui-disabled": {
                background: "#F1F1F1 0% 0% no-repeat padding-box",
                color: "#C0C3C7",
                border: "1px solid #C0C3C7 !important",
                borderRadius: "4px",
              },
            }}
            onClick={(e) => setOpen(true)}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder: "DD-Mon-YYYY",
            }}
          />
        )}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
