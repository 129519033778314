import React, { useState } from "react";
import { Box } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import DrawerComponent from "../BaseComponents/Drawer";
import TextFieldComponent from "../BaseComponents/TextField";
import DropDownComponent from "../BaseComponents/DropDown";

export default function RequireActionDrawer({ ...props }) {
  const {
    toggleDrawer,
    requireActiondrawer,
    edit,
    tenant,
    setTenant,
    handleClick,
    drawer,
    buttonValidation,
    clearRequireAction,
    closeToast,
    reason,
    customReason,
    handleReason,
    handleCustomReason,
    actionLabel,
  } = props;

  const [reasons, setReasons] = useState([
    { label: "QA / Testing", value: 0 },
    { label: "Maintenance", value: 1 },
    { label: "Calibration", value: 2 },
    { label: "Equipment failure", value: 3 },
    { label: "Configuration update needed", value: 4 },
    { label: "Custom Options", value: 5 },
  ]);
  const [selectedReason, setSelectedReason] = useState(2);
  const useStyles = makeStyles({
    itemPadding: {
      paddingLeft: "24px !important",
    },
    paddingTop: {
      paddingTop: "24px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });

  const classes = useStyles();

  return (
    <>
      <DrawerComponent
        toggleDrawer={toggleDrawer}
        drawer={drawer}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              class="fa-solid fa-chevron-left"
              style={{
                marginRight: "15px",
                width: "20px",
                height: "32px",
                color: "#9CA1A6",
                fontSize: "32px",
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(false)}
            ></i>
            Action reason
          </div>
        }
        buttonTitle={"Submit"}
        actionLabel={actionLabel}
        displayFooter={true}
        handleClick={handleClick}
        buttonValidation={
          reason.label?.length === 0 ||
          (reason.label === "Custom Options" && customReason.length === 0)
        }
        closeToast={closeToast}
      >
        <Box sx={{ padding: "24px" }}>
          <Box
            sx={{
              mt: "27px",
              fontWeight: "lighter",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "22px",
                color: "#222934",
                fontFamily: "Roboto",
              }}
            >
              An action reason is required to perform this action.
            </p>
          </Box>
          <DropDownComponent
            padding={true}
            label="Reason"
            options={reasons}
            handleChange={(e) => handleReason(e)}
            placeholder="Select a reason"
            value={reasons.find((el) => el.value === reason.value) || ""}
          />
          {reason.label === "Custom Options" && (
            <TextFieldComponent
              fullWidth={true}
              padding={true}
              placeholder="Enter your reason here"
              onChange={handleCustomReason}
              value={customReason || ""}
              name="customReason"
              variant="outlined"
              Label="Custom reason"
              multiline={true}
              height="64px"
              maxLength="255"
              customError={customReason.length >= 255 ? `Limit character is 255` : ""}
            />
          )}
        </Box>
      </DrawerComponent>
    </>
  );
}
