import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FormControl, Grid } from "@mui/material";
//COMPONENTS IMPORT
import ThemeButton from "@Components/Buttons";
import Label from "@Components/BaseComponents/Label";
import { UTCTimeZones } from "../../Utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroupComponent from "../../Components/BaseComponents/RadioGroup";
import RadioComponent from "../../Components/BaseComponents/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToolTipComponent from "../BaseComponents/Tooltip";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import Toast from "@Components/BaseComponents/Toast";
import { usePasswordValidation } from "../../Hooks/usePasswordValidation";
import InputAdornment from "@mui/material/InputAdornment";
import { useUpdatePassMutation } from "../../Store/Slices/authApiSlice";
import TextFieldComponent from "../BaseComponents/TextField";
import PhoneInputField from "../BaseComponents/PhoneInputField";
import DropDownComponent from "../BaseComponents/DropDown";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import { matchIsValidTel } from "mui-tel-input";

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const useStyles = makeStyles({
  userItemPadding: {
    paddingTop: "24px !important",
  },
  root: {
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#00AEEF",
        borderWidth: "3px",
        width: "520px",
      },
    },
  },
});

export default function RightDrawer({ ...props }) {
  const {
    toggleDrawer,
    errors,
    drawer,
    handleChange,
    onChangePhoneInput,
    user,
    handleCreate,
    setUser,
    edit,
    handleEditSave,
    userGroups,
    setUserGroupIDs,
    UserGroupIDs,
    authorizationGroups,
    editUser,
    addArchive,
    removeArchive,
    confirmationDrawer,
    userUpdated,
    loadCustomers,
    setUserUpdated,
    setToastData,
    discardSettingsDrawer,
    AlertUserPasswordChange,
  } = props;

  useEffect(() => {
    handleChange({
      target: {
        name: "UserName",
        value: user.SNSEMail.split("@")[0],
      },
    });
  }, [user.SNSEMail]);

  const [customerDrawer, setCustomDrawer] = useState(false);
  const [resetPasswordDrawer, setResetPasswordDrawer] = useState(false);
  const [passwordReseted, setPasswordReseted] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState({});

  const [password, setPassword] = useState({
    NewPassword: "",
    ConfirmNewPassword: "",
  });

  const [updatePass, { isSuccess: passChangeSuccess }] = useUpdatePassMutation();

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] = usePasswordValidation({
    firstPassword: password.NewPassword,
    secondPassword: password.ConfirmNewPassword,
  });

  const handleClick = (reason) => {
    edit ? handleEditSave(user, reason) : handleCreate(user, reason);
  };

  const buttonValidation = !Object.entries(user).every(
    ([key, value]) => key === "AdditionalInformation" || value !== ""
  );

  useEffect(() => {
    setUser({ ...user, GMTAdjustment: selectedTimezone.value });
  }, [selectedTimezone]);

  const classes = useStyles();

  const updateRoleID = (e) => {
    setUser({ ...user, RoleID: e });
    setUserUpdated(true);
  };

  const customToggleDrawer = (open) => {
    setCustomDrawer(open);
  };

  const resetPassword = (open) => {
    setResetPasswordDrawer(open);
  };

  const handleDelete = async (user, reason) => {
    let data = { IsActive: false, reason: reason };
    let res = await addArchive({
      data: { User: [user.UserID] },
      reason: reason,
    });
    if (res) setToastData(data);
    customToggleDrawer(false);
    toggleDrawer(false);
  };

  const handleActivate = async (user, reason) => {
    let data = { IsActive: true, reason: reason };
    let res = await removeArchive({ User: [user.UserID] });
    if (res) setToastData(data);
    customToggleDrawer(false);
    toggleDrawer(false);
  };

  const setNewPassword = (event) => {
    setPassword({ ...password, NewPassword: event.target.value });
  };
  const setConfirmNewPassword = (event) => {
    setPassword({ ...password, ConfirmNewPassword: event.target.value });
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  // we need to concatenate the country code "+" symbol for validation logic to be ran correctly
  const phoneValueWithSymbol = !user?.SNSPhone.includes("+")
    ? `+${user?.SNSPhone}`
    : user?.SNSPhone;

  const isInvalidPhoneNumber = !matchIsValidTel(phoneValueWithSymbol);

  const getPhoneErrorHelperText = () => {
    if (!isEmailInValid(user.SNSEMail)) {
      if (!user?.SNSPhone) {
        return "Phone number cannot be empty";
      }

      if (isInvalidPhoneNumber) {
        return "Invalid international phone number";
      }
    }
  };

  const handleUpdatePassword = async (reason) => {
    let data = {
      UserID: user.UserID,
      Password: password.ConfirmNewPassword,
      reason: reason,
    };
    await updatePass(data);
    resetPassword(false);
    toggleDrawer(false);
    AlertUserPasswordChange(user.LastName, passwordReseted);
  };
  const handleEmailChange = () => {
    setUser({ ...user, PreferEmail: !user.PreferEmail });
    setUserUpdated(true);
  };

  const handleSMSChange = () => {
    setUser({ ...user, PreferSMS: !user.PreferSMS });
    setUserUpdated(true);
  };

  const handleTimeZoneChange = (e) => {
    setUser({ ...user, GMTAdjustment: e.value });
    setUserUpdated(true);
  };

  const handleUserGroupSelect = (e) => {
    setUserGroupIDs(e);
    handleChange({ target: { name: "UserGroupIDs", value: e } });
    setUserUpdated(true);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    setUserUpdated(false);
    loadCustomers();
    toggleDrawer(e);
  };

  const isEmailInValid = (email) => {
    return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };

  const isPhoneError =
    (!isEmailInValid(user?.SNSEMail) && !user?.SNSPhone) ||
    (!isEmailInValid(user?.SNSEMail) && isInvalidPhoneNumber);

  return (
    <>
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={toggleDrawer}
        deactivateToggleDrawer={customToggleDrawer}
        title={edit ? "Edit user" : "New user"}
        submitButtonTitle={edit ? "Update user" : "Save user"}
        handleSubmit={handleClick}
        displayFooter={true}
        edit={edit}
        closeToast={() => toggleDrawer(false)}
        buttonValidation={
          buttonValidation || !userUpdated || isPhoneError || isEmailInValid(user.SNSEMail)
        }
        deactivateButtonTitle={!user.IsActive ? "RESTORE" : "ARCHIVE"}
        deactivateButtonColor={!user.IsActive ? "primary" : "error"}
        deleteDrawer={customerDrawer}
        loadItem={loadCustomers}
        item={user}
        setUpdated={setUserUpdated}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
        handleDelete={(item, reason) =>
          !user.IsActive ? handleActivate(user, reason) : handleDelete(user, reason)
        }
        deleteTitle={!user.IsActive ? "Restore user" : "Archive user"}
        heading={`Are you sure you want to${" "}
        ${!user.IsActive ? "Restore" : "Archive"} ~${user.UserName}?`}
        headingNote="Archiving this User will remove it from all Alert Programs"
      >
        {passwordReseted && (
          <Toast
            message="Notifications added successfully"
            severity="success"
          />
        )}
        <Box sx={{ padding: "24px" }}>
          <FormControl margin="none">
            <Grid
              container
              spacing={1}
            >
              <TextFieldComponent
                fullWidth={true}
                padding={false}
                placeholder="Enter first name"
                onChange={handleChange}
                value={user.FirstName || ""}
                name="FirstName"
                error={errors.FirstName ? true : false}
                helperText={errors.FirstName}
                variant="outlined"
                Label="First name"
              />

              <TextFieldComponent
                fullWidth={true}
                padding={true}
                placeholder="Enter last name"
                onChange={handleChange}
                value={user.LastName || ""}
                name="LastName"
                error={errors.LastName ? true : false}
                helperText={errors.LastName}
                variant="outlined"
                Label="Last name"
                disabled={user.FirstName == "" ? true : false}
              />
              <TextFieldComponent
                fullWidth={true}
                padding={true}
                placeholder="email@domain.com"
                onChange={handleChange}
                value={user.SNSEMail || ""}
                error={errors.SNSEMail ? true : false}
                helperText={errors.SNSEMail}
                customError={
                  isEmailInValid(user.SNSEMail) ? "Email format is not correct" : undefined
                }
                name="SNSEMail"
                variant="outlined"
                Label="Email"
                disabled={!edit ? user.LastName === "" : true}
              />

              <TextFieldComponent
                fullWidth={true}
                padding={true}
                placeholder="UserName"
                value={user.UserName || ""}
                name="UserName"
                disabled={true}
                variant="outlined"
                Label="Username"
                error={errors.UserName ? true : false}
                helperText={errors.UserName}
              />
              <PhoneInputField
                placeholder="+1 555 000 0000"
                onChange={onChangePhoneInput}
                value={phoneValueWithSymbol}
                error={isPhoneError}
                helperText={getPhoneErrorHelperText()}
                name="SNSPhone"
                variant="outlined"
                padding
                fullWidth
                label="Phone"
                disabled={!user.SNSEMail || isEmailInValid(user.SNSEMail)}
              />
              {edit && (
                <Grid
                  item
                  xs={12}
                >
                  <div style={{ paddingTop: "24px" }}>
                    <Label
                      sx={{
                        color: "#9CA1A6",
                      }}
                    >
                      Password
                    </Label>
                    <div
                      style={{
                        paddingTop: "10px",
                      }}
                    >
                      <ThemeButton
                        variant="contained"
                        color="primary"
                        size="small"
                        width="248px"
                        onClick={() => {
                          resetPassword(true);
                          setPasswordReseted(false);
                        }}
                      >
                        RESET USER'S PASSWORD{" "}
                      </ThemeButton>
                    </div>
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                columns={2}
                spacing={2}
              >
                <div style={{ paddingTop: "24px" }}>
                  <Label
                    sx={{
                      color: "#9CA1A6",
                    }}
                  >
                    Permissions role
                  </Label>

                  <RadioGroupComponent
                    row
                    name="row-radio-buttons-group"
                    value={user.RoleID || ""}
                    callBack={updateRoleID}
                    FormControlLabels={
                      <Grid
                        item
                        columns={2}
                        spacing={2}
                        sx={{
                          gridTemplateColumns: "repeat(2, 1fr)",
                          display: "grid",
                          width: "100%",
                        }}
                      >
                        {authorizationGroups.map((group) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  fontSize: "13px !important",
                                  marginRight: "5px",
                                }}
                                value={group.value}
                                control={
                                  <RadioComponent disabled={user.SNSPhone == "" ? true : false} />
                                }
                                label={group.label}
                              />
                              <ToolTipComponent
                                placement="bottom"
                                title={group.label}
                                Component={
                                  <Box>
                                    <i
                                      class="fa-regular fa-circle-info"
                                      style={{ color: "#9CA1A6" }}
                                    ></i>
                                  </Box>
                                }
                              />
                            </Box>
                          );
                        })}
                      </Grid>
                    }
                  />
                </div>
              </Grid>

              <Grid
                className={classes.userItemPadding}
                item
                xs={12}
              >
                <Label
                  sx={{
                    color: "#9CA1A6",
                  }}
                >
                  Communication preference
                </Label>
                <Box
                  sx={{
                    display: "flex",
                    paddingTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    {" "}
                    <CheckBoxComponent
                      disabled={user.RoleID == "" ? true : false}
                      checked={user.PreferEmail}
                      onChange={() => handleEmailChange()}
                      Label="Email"
                    />{" "}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckBoxComponent
                      disabled={user.RoleID == "" ? true : false}
                      checked={user.PreferSMS}
                      onChange={() => handleSMSChange()}
                      Label="SMS"
                    />{" "}
                  </Box>
                </Box>
              </Grid>
              <DropDownComponent
                padding={true}
                isDisabled={user.SNSPhone == "" ? true : false}
                placeholder="Select a timezone"
                label="Timezone"
                options={UTCTimeZones}
                handleChange={(e) => handleTimeZoneChange(e)}
                value={UTCTimeZones.find((el) => el.value === user.GMTAdjustment) || ""}
              />

              <DropDownComponent
                isDisabled={user.GMTAdjustment == "" ? true : false}
                label=" User group(s)"
                padding={true}
                placeholder="Select user group(s)"
                options={userGroups}
                styles={selectStyles}
                handleChange={(e) => handleUserGroupSelect(e)}
                value={edit ? UserGroupIDs : user.UserGroupIDs || []}
                isMulti={true}
              />

              <TextFieldComponent
                fullWidth={true}
                padding={true}
                placeholder="Enter additional information about this user"
                onChange={handleChange}
                value={user.AdditionalInformation || ""}
                name="AdditionalInformation"
                error={errors.AdditionalInformation ? true : false}
                helperText={errors.AdditionalInformation}
                variant="outlined"
                Label="Additional information"
                multiline={true}
                disabled={user.GMTAdjustment == "" ? true : false}
                height="64px"
              />
            </Grid>
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
      <ResourceDrawerComponent
        drawer={resetPasswordDrawer}
        toggleDrawer={resetPassword}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              class="fa-solid fa-chevron-left"
              style={{
                marginRight: "15px",
                width: "20px",
                height: "32px",
                color: "#9CA1A6",
                fontSize: "32px",
                cursor: "pointer",
              }}
              onClick={() => resetPassword(false)}
            ></i>
            {`Reset ${user.FirstName}'s password`}
          </div>
        }
        buttonValidation={
          !(validLength && hasNumber && upperCase && lowerCase && specialChar && match)
        }
        edit={false}
        submitButtonTitle={"RESET PASSWORD"}
        displayFooter={true}
        handleSubmit={handleUpdatePassword}
        closeToast={resetPassword}
      >
        <Box sx={{ padding: "24px" }}>
          <FormControl
            margin="none"
            fullWidth
          >
            <TextFieldComponent
              padding={false}
              fullWidth={true}
              Label="New password"
              placeholder="Enter your new password"
              type={showNewPassword ? "text" : "password"}
              onChange={setNewPassword}
              value={password.NewPassword}
              name="NewPassword"
              variant="outlined"
              autoComplete="new-password"
              InputProps={{
                autocomplete: "new-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <i
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      class="fa-light fa-eye"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              padding={2}
              sx={{
                marginTop: "24px",
                background: "#F1F1F1",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              <Typography variant="caption">
                <div className={validLength ? "valid-message" : "error-message"}>
                  {" "}
                  <span
                    style={{
                      margin: "5px",
                    }}
                  >
                    <li className={validLength ? "fa fa-check" : "fa-regular fa-xmark"}></li>
                  </span>
                  Must contain at least 8 characters
                  <br />
                </div>
                <div className={lowerCase ? "valid-message" : "error-message"}>
                  {" "}
                  <span
                    style={{
                      margin: "5px",
                    }}
                  >
                    <li className={lowerCase ? "fa fa-check" : "fa-regular fa-xmark"}></li>
                  </span>
                  Must include at least one lowercase letter
                  <br />
                </div>

                <div className={hasNumber ? "valid-message" : "error-message"}>
                  {" "}
                  <span
                    style={{
                      margin: "5px",
                    }}
                  >
                    <li className={hasNumber ? "fa fa-check" : "fa-regular fa-xmark"}></li>
                  </span>
                  Must include at least one number
                  <br />
                </div>

                <div className={upperCase ? "valid-message" : "error-message"}>
                  {" "}
                  <span
                    style={{
                      margin: "5px",
                    }}
                  >
                    <li className={upperCase ? "fa fa-check" : "fa-regular fa-xmark"}></li>
                  </span>
                  Must include at least one uppercase letter
                  <br />
                </div>

                <div className={specialChar ? "valid-message" : "error-message"}>
                  {" "}
                  <span
                    style={{
                      margin: "5px",
                    }}
                  >
                    <li className={specialChar ? "fa fa-check" : "fa-regular fa-xmark"}></li>
                  </span>
                  Must include at least one special character
                  <br />
                </div>
              </Typography>
            </Box>

            <TextFieldComponent
              padding={true}
              Label="Confirm new password"
              fullWidth={true}
              placeholder="Re-enter your new password"
              type={showConfirmNewPassword ? "text" : "password"}
              onChange={setConfirmNewPassword}
              value={password.ConfirmNewPassword}
              name="ConfirmNewPassword"
              variant="outlined"
              error={!match && validLength && hasNumber && upperCase && lowerCase && specialChar}
              helperText={"Passwords do not match"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <i
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      class="fa-light fa-eye"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
}
