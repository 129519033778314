import { Box } from "@mui/material";
import LabelWithTitle from "../LabelWithTitle";

const ReportDataHeader = (props) => {
  const { sensorData, sensorType } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <LabelWithTitle
        label={
          sensorData?.Min != undefined ? (
            <>
              {sensorData?.Min}
              {sensorType.Unit}
            </>
          ) : (
            "-"
          )
        }
        title="Minimum value"
        state="primary"
      />
      <LabelWithTitle
        label={
          sensorData?.Max != undefined ? (
            <>
              {sensorData?.Max}
              {sensorType.Unit}
            </>
          ) : (
            "-"
          )
        }
        title="Maximum value"
        state="primary"
      />
      <LabelWithTitle
        label={
          sensorData?.Mean != undefined ? (
            <>
              {sensorData?.Mean}
              {sensorType.Unit}
            </>
          ) : (
            "-"
          )
        }
        title="Mean value"
        state="primary"
      />
      <LabelWithTitle
        label={
          sensorData?.SD != undefined ? (
            <>
              {sensorData?.SD}
              {sensorType.Unit}
            </>
          ) : (
            "-"
          )
        }
        // label={`${sensorData?.SD!=undefined || "-"} ${sensorType.Unit}`}
        title="Standard deviation"
        state="primary"
      />
      <LabelWithTitle
        label={sensorData.AlarmCount != undefined ? sensorData.AlarmCount : "-"}
        title="Alarm count"
        state="primary"
      />
      <LabelWithTitle
        label={sensorData.MKT != undefined ? sensorData.MKT : "-"}
        title="Mean kinetic temperature"
        state="primary"
      />
    </Box>
  );
};

export default ReportDataHeader;
