import LabelComponent from "../LabelComponent";
import { Box } from "@mui/material";

const LabelWithTitle = (props) => {
  const { marginBottom, label, state, title } = props;
  return (
    <Box>
      <p
        style={{
          letterSpacing: "0px",
          color: "#9CA1A6",
          opacity: "1",
          textAlign: "left",
          font: "normal normal normal 12px/14px Roboto",
          marginBottom: "10px",
        }}
      >
        {title}
      </p>
      <LabelComponent
        label={label}
        state={state}
      />
    </Box>
  );
};

export default LabelWithTitle;
