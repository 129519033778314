import React from "react";

const AlertScreen = () => {
  return (
    <div style={{ flexDirection: "column", margin: "1rem" }}>
      <h2 className="fa-3x">Admin</h2>
      <h3 className="fa-2x">Applying Sensors to Equipment</h3>
      <h4>Step One - Create a Substation (if desired)</h4>
      You will have the option to assign a specific Sensor Type to a Substation when you add a
      Sensor (Type) to a piece of Equipment
      <h4>Step Two - Create the Equipment (if necessary)</h4>
      Under the Equipment tab, you have the option to create a piece of Equipment if it doesn't
      already exist
      <h4>Step Three - Add the Sensor (Type) to the Equipment</h4>
      Select the Location, Equipment and Sensor Type that needs to be applied to the Equipment and
      SAVE. The Tech will see this information for installation.
      <h2 className="fa-3x">Tech</h2>
      <h3 className="fa-2x">Installing Sensors on Equipment</h3>
      <ol>
        <li>
          Select the Location, Equipment and Sensor Type, if you have previously assigned a Sensor
          Type for that Equipment then that Type will not show
        </li>
        <li>Select or Create a Substation</li>
        <li>Select the Port, if you have previously assigned a Port then it will not show</li>
        <li>Add a meaningful label</li>
        <li>Select the Interface Type</li>
        <li>Assign configuration to determine settings and OOB values</li>
        <li>
          SAVE<sup>*</sup>
        </li>
      </ol>
      <sup>*</sup> - During TESTING phase only this action will create fake historical data. This
      data is created to match the format of the actual IoT data inbound from SODAQ hardware. The
      data values created during the process are 100% nominal values using the configuration data
      entered as bounds. On click, a large number of records are created and sent to the IoT Core
      endpoint where those records are processed and stored in AWS S3 in preparation for display
      exactly as it would be if it were live data.
      <h2 className="fa-3x">Customer</h2>
      <h3 className="fa-2x">Viewing Sensor Data</h3>
      Customers can view Sensor data in a list, on a panel or on a map. Filters can be applied to
      all views. Details for that Sensor data can be seen from the panel view by clicking the
      desired tile and from the map and list by clicking the desired equipment icon
      <br />
      <br /> To create the ability for a User to see output from a specific Sensor:
      <ol>
        <li>Go to User Groups</li>
        <li>Create a User Group</li>
        <li>Add any desired User to the Group</li>
        <li>Go to Sensor Groups</li>
        <li>Create a Sensor Group</li>
        <li>Add any desired Sensor to the Group</li>
        <li>Add any desired User Group to the Sensor Group</li>
      </ol>
      All Users within all User Groups that have the ability to view a Sensor Group containing a
      specific Sensor will be able to view that specific Sensor data
    </div>
  );
};

export default AlertScreen;
