import Radio from "@mui/material/Radio";

const RadioComponent = (props) => {
  return (
    <Radio
      disabled={props.disabled}
      size={props.size}
      sx={{
        paddingRight: "5px",
        paddingLeft: props.paddingLeft ? props.paddingLeft : "none",
        color: "#9CA1A6",
        "&.Mui-focused": {
          backgroundColor: "red",
          border: "2px solid #11364C",
        },
        "& .Mui-focusVisible": {
          backgroundColor: "red",
          background: "#FFFFFF",
          border: "0.2px solid #11364C",
        },
        "&:hover": {
          background: "transparent",
          color: "#17455E",
        },
      }}
      {...props}
    />
  );
};
export default RadioComponent;
