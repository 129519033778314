import { useState } from "react";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";
import Toast from "@Components/BaseComponents/Toast";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { STATUS_COLOR_CODE_MAP } from "../../Utils/constants";
import { findObjectByValue, formatDate, getColorCodingStatus, getSensorLabel } from "../../Utils/Tools";
import { lightGrey } from "../../Styles/theme/colors";
import ToolTipComponent from "@Components/BaseComponents/Tooltip";
import SensorLabel from "@Components/BaseComponents/SensorLabel";
import LabelComponent from "../BaseComponents/LabelComponent";
import { Divider } from "@material-ui/core";
import Label from "../BaseComponents/Label";
import moment from "moment";
import "./style.css";
import { useAcknowledgeSensorsMutation } from "../../Store/Slices/sensorAcknowledgeSlice";

const timeDurations = [
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
  { label: "2 hours", value: 120 },
  { label: "6 hours", value: 360 },
  { label: "12 hours", value: 720 },
];

const durationTypes = [
  { label: "Time duration", value: 0 },
  { label: "Future date", value: 1 },
  { label: "Indefinitely", value: 2 },
];

function convertMsToReadableDate(ms) {
  const date = moment(ms);
  return date.format("YYYY-MM-DD HH:mm:ss");
}
function getTimezoneAbbreviation(date) {
  const timezoneAbbreviation = moment(date).tz('UTC').format('z');
  return timezoneAbbreviation;
}
const EquipmentAlarmAcknowledgeDrawer = (props) => {
  const {
    toggleAlarmAcknowledgeDrawer,
    equipmentAlarmAcknowledgeDrawer,
    sensor,
    sensorTypes,
    sensorLimitTemplates,
    clearSensor,
    equipmentAlarmAcknowledge,
  } = props;
  const [
    acknowledgeSensor,
    { isSuccess: acknowledgeSensorsSuccess, isError: acknowledgeSensorsError },
  ] = useAcknowledgeSensorsMutation();

  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);

  const confirmationDrawer = (action) => {
    setDiscardSettingsDrawer(action);
  };

  const handleDiscard = (e) => {
    confirmationDrawer(e);
    toggleAlarmAcknowledgeDrawer(e);
  };

  const handleAcknowledge = async () => {
    const sensorIDs = equipmentAlarmAcknowledge.Sensors.map((item) => item.SensorID);
    let res = await acknowledgeSensor({
      SensorIDs: sensorIDs,
    });
    if (res?.data?.success) {
      toggleAlarmAcknowledgeDrawer(false);
    }
  };

  const status = getColorCodingStatus(equipmentAlarmAcknowledge?.equipmentAllSensors);
  const first =
    equipmentAlarmAcknowledge?.Sensors.length > 3 && equipmentAlarmAcknowledge?.Sensors.slice(0, 2);
  const second =
    equipmentAlarmAcknowledge?.Sensors.length > 3 && equipmentAlarmAcknowledge?.Sensors.slice(2);
  return (
    <>
      {acknowledgeSensorsSuccess && (
        <Toast
          message={`${sensor?.SensorLabel} alarm successfully Acknowledged `}
          severity="success"
          autoHideDuration={5000}
        />
      )}
      <ResourceDrawerComponent
        drawer={equipmentAlarmAcknowledgeDrawer}
        toggleDrawer={toggleAlarmAcknowledgeDrawer}
        title={"Acknowledge Equipment Alarms"}
        submitButtonTitle={"ACKNOWLEDGE"}
        handleSubmit={handleAcknowledge}
        displayFooter={true}
        closeToast={clearSensor}
        item={sensor}
        confirmationDrawer={confirmationDrawer}
        handleDiscard={handleDiscard}
        discardSettingsDrawer={discardSettingsDrawer}
      >
        <Box className="drawerPadding">
          <FormControl
            margin="none"
            className="formControl"
          >
            <Grid
              container
              spacing={1}
            >
              <Box className="suppressDrawerHeader">
                <span className="mainSpan">
                  <span style={{ position: "relative" }}>
                    {status !== "none" && (
                      <Box
                        className="equipmentDotRelaxed"
                        sx={{
                          background: STATUS_COLOR_CODE_MAP[status],
                          borderRadius: "50%",
                          position: "absolute",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                    )}
                    <i
                      className="fa-solid fa-flux-capacitor tableRow"
                      style={{
                        fontSize: "32px",
                        background: "#ECEDEE",
                        color: " #9CA1A6",
                        padding: "10px",
                        borderRadius: "5px",
                        fontWeight: "300",
                      }}
                    ></i>
                  </span>
                  <div className="typographyPadding">
                    <Typography
                      variant="caption"
                      className="typographyLabelType"
                    >
                      {equipmentAlarmAcknowledge?.EquipmentTypeName}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="typographyLabel"
                    >
                      {equipmentAlarmAcknowledge?.EquipmentName}
                    </Typography>
                  </div>
                </span>

                <span style={{ display: "grid" }}>
                  <Typography
                    variant="caption"
                    sx={{ color: lightGrey["900"] }}
                  >
                    Monitoring
                  </Typography>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {equipmentAlarmAcknowledge?.Sensors.length > 0 ? (
                      equipmentAlarmAcknowledge?.Sensors.length > 3 ? (
                        <>
                          {first.map((sensor) => {
                            const sensorType = findObjectByValue(
                              sensorTypes,
                              "value",
                              sensor.SensorTypeID
                            );
                            const sensorLabel = getSensorLabel(
                              sensor,
                              sensorLimitTemplates,
                              sensorTypes
                            );
                            return (
                              <SensorLabel
                                LiveData={sensor.LiveData}
                                sensorType={sensorType}
                                title={sensorLabel}
                              />
                            );
                          })}
                          <ToolTipComponent
                            title={second.map((sensor) => {
                              if (sensorLimitTemplates) {
                                const sensorLabel = getSensorLabel(
                                  sensor,
                                  sensorLimitTemplates,
                                  sensorTypes
                                );
                                return sensorLabel;
                              }
                            })}
                            Component={
                              <Box
                                sx={{
                                  width: "fit-content",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <LabelComponent
                                  label={`${second?.length} more`}
                                  state={"success"}
                                />
                              </Box>
                            }
                          />
                        </>
                      ) : (
                        equipmentAlarmAcknowledge?.Sensors.map((sensor) => {
                          const sensorType = findObjectByValue(
                            sensorTypes,
                            "value",
                            sensor.SensorTypeID
                          );
                          const sensorLabel = getSensorLabel(
                            sensor,
                            sensorLimitTemplates,
                            sensorTypes
                          );
                          return (
                            <SensorLabel
                              LiveData={sensor.LiveData}
                              sensorType={sensorType}
                              title={sensorLabel}
                            />
                          );
                        })
                      )
                    ) : (
                      <ToolTipComponent
                        title="There are no sensors attached to this equipment"
                        placement="bottom-start"
                        Component={
                          <Box
                            sx={{
                              width: "fit-content",
                              cursor: "pointer",
                            }}
                          >
                            <LabelComponent
                              label="No sensors"
                              state="none"
                            />
                          </Box>
                        }
                      />
                    )}
                  </span>
                </span>
              </Box>
            </Grid>
            <Box className="dividerPadding">
              <Divider />
            </Box>
            <Box className="drawerHeaderBox">
              <Grid
                container
                direction="column"
              >
                <Label
                  sx={{
                    color: "#9CA1A6",
                  }}
                >
                  Prior alarms ({equipmentAlarmAcknowledge?.Sensors.length})
                </Label>
                <Grid
                  item
                  xs={12}
                  sx={{ marginLeft: "10px" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    {equipmentAlarmAcknowledge?.Sensors.length > 0 ? (
                      <Box>
                        {equipmentAlarmAcknowledge?.Sensors.map((sensor) => {
                          const sensorType = findObjectByValue(
                            sensorTypes,
                            "value",
                            sensor.SensorTypeID
                          );
                          const sensorLabel = getSensorLabel(
                            sensor,
                            sensorLimitTemplates,
                            sensorTypes
                          );
                          return (
                            <>
                              <Box
                                key={sensor.SensorID}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginTop: "5px",
                                }}
                              >
                                <Box
                                  sx={{
                                    maxWidth: "80%",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: lightGrey["900"],
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                    }}
                                  >
                                    {sensorType?.label.substr(0, sensorType?.label.indexOf(" "))}
                                  </Typography>
                                  <Box
                                    component="div"
                                    sx={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{
                                        fontWeight: 500,
                                        fontSize: "18px",
                                        lineHeight: "16px",
                                      }}
                                    >
                                      {sensorLabel}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Typography
                                variant="caption"
                                className="typographyLabelType"
                              >
                               {`${formatDate(new Date(sensor?.LiveData?.OldTime), "dd-MMM-yyyy, HH:mm:ss")} ${getTimezoneAbbreviation(sensor?.LiveData?.OldTime)}`}
                              </Typography>
                            </>
                          );
                        })}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Divider />
          </FormControl>
        </Box>
      </ResourceDrawerComponent>
    </>
  );
};

export default EquipmentAlarmAcknowledgeDrawer;
