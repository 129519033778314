import React, { createContext, useState } from "react";

export const AlarmContext = createContext();

const AlarmProvider = (props) => {
  const [alarmPanelToggle, setAlarmPanelToggle] = useState(false);

  return (
    <AlarmContext.Provider value={{ alarmPanelToggle, setAlarmPanelToggle }}>
      {props.children}
    </AlarmContext.Provider>
  );
};

export default AlarmProvider;
