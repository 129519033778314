import React, { Fragment, useContext } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";

const Divider = (props) => {
  const { onMouseHoldDown, clientHeight } = useContext(SplitPaneContext);
  const iconDirection = props.className === "separator-row" ? "" : "-vertical";
  const orientation =
    props.className === "separator-row" ? `center-element-row` : `center-element-col`;

  return (
    <Fragment>
      <div
        {...props}
        onMouseDown={onMouseHoldDown}
      >
        <div className={orientation}>
          <i className={` fa-2x fa-light fa-grip-lines${iconDirection}`}></i>
        </div>
      </div>
    </Fragment>
  );
};

export default Divider;
