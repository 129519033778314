import React, { useContext, useEffect, createRef, useState } from "react";
import SplitPaneContext from "@Context/SplitPaneContext";
import EquipmentAlarmCard from "../BaseComponents/EquipmentAlarmCard";
import { Box, FormControlLabel, MenuItem } from "@mui/material";
import { useLiveEquipmentQuery } from "../../Store/Slices/equipmentSlice";
import { useSelector } from "react-redux";
import {
  filterObjectByValue,
  getColorCodingStatus,
  getFaultedEquipmentsLength,
} from "../../Utils/Tools";
import { AppBar } from "@Components/AppBar/index.js";
import { useFindEquipmentTypeQuery } from "../../Store/Slices/equipmentTypeSlice";
import { useFindSensorTypeQuery } from "../../Store/Slices/sensorTypeSlice";
import { useFindLocationsQuery } from "../../Store/Slices/locationsSlice";
import AlarmPaneFilter from "../BaseComponents/Filter/AlarmPaneFilter";
import moment from "moment";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import {
  useSuppressSensorsMutation,
  useUnSuppressSensorsMutation,
} from "../../Store/Slices/sensorSuppressSlice";
import CustomMenu from "@Components/BaseComponents/Menu";
import { useEditSensorMutation } from "../../Store/Slices/sensorSlice";
import SetupSpinnerContainer from "../../Containers/SetupSpinnerContainer";
import Loader from "../LoadingSpinner";
import SensorSuppressDrawer from "../RightDrawer/SensorSuppressDrawer";
import EquipmentAlarmSupressDrawer from "../../Components/RightDrawer/EquipmentAlarmSupressDrawer";
import { useFindSensorLimitTemplateQuery } from "../../Store/Slices/sensorLimitTemplateSlice";
import Toast from "@Components/BaseComponents/Toast";
import SensorAcknowledegeDrawer from "../RightDrawer/SensorAcknowledgeDrawer";
import EquipmentAlarmAcknowledgeDrawer from "../RightDrawer/EquipmentAlarmAcknowledgeDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakPoints } from "@Utils/constants";
import { useFindCustomersQuery } from "../../Store/Slices/customersSlice";
import { findObjectByValue } from "../../Utils/Tools";

const menuItems = {
  1: "warning",
  2: "alarm",
  3: "suppressed",
};

const AlarmPane = (props) => {
  const { setAlarmPanelToggle } = props;
  const maxWidthSm = useMediaQuery(`(max-width:${breakPoints.sm}px)`);
  const topRef = createRef();
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext);
  const [faultedEquipments, setFaultedEquipments] = useState([]);
  const [allFaultedEquipments, setAllFaultedEquipments] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState([]);
  const [sensorLimitTemplates, setSensorLimitTemplates] = useState([]);
  const [allSelectedSensors, setAllSelectedSensors] = useState([]);
  const [selectedSensorIds, setSelectedSensorIds] = useState([]);
  const [alarmFilterCount, setAlarmFilterCount] = useState(0);
  const [equipmentFilterCount, setEquipmentFilterCount] = useState(0);
  const [sensorTypeFilterCount, setSensorTypeFilterCount] = useState(0);
  const [locationFilterCount, setLocationFilterCount] = useState(0);
  const [selectedAction, setSelectedAction] = useState("");
  const [unSuppressAction, setUnSuppressAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sensorStates, setSensorStates] = useState([]);
  const [isBulkActionEnabled, setIsBulkActionEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppressDrawer, setSuppressDrawer] = useState(false);
  const [equipmentAlarmSuppress, setEquipmentAlarmSuppress] = useState({ Sensors: [] });
  const [equipmentAlarmAcknowledge, setEquipmentAlarmAcknowledge] = useState({ Sensors: [] });
  const [equipmentAlarmSuppressDrawer, setEquipmentAlarmSuppressDrawer] = useState(false);
  const [equipmentAlarmAcknowledgeDrawer, setEquipmentAlarmAcknowledgeDrawer] = useState(false);
  const [suppressSensorTypes, setSuppressSensorTypes] = useState([]);
  const [AcknoweldgeSensorTypes, setAcknoweldgeSensorTypes] = useState([]);
  const [acknowledgeDrawer, setAcknowledgeDrawer] = useState(false);

  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customersSuccess,
    isError: customersIsError,
    error: customersError,
  } = useFindCustomersQuery();
  const {
    data: equipmentTypesData,
    isLoading: equipmentTypesLoading,
    isSuccess: equipmentTypesSuccess,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
  } = useFindEquipmentTypeQuery();

  const {
    data: sensorTypeData,
    isLoading: sensorTypeLoading,
    isSuccess: sensorTypeSuccess,
    isError: sensorTypeIsError,
    error: sensorTypeError,
  } = useFindSensorTypeQuery();

  const {
    data: locationsData,
    isFetching: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  const {
    data: sensorLimitTemplateData,
    isLoading: sensorLimitTemplateLoading,
    isSuccess: sensorLimitTemplateSuccess,
    isError: sensorLimitTemplateIsError,
    error: sensorLimitTemplateError,
  } = useFindSensorLimitTemplateQuery();

  const {
    data: equipmentData,
    isLoading: equipmentLoading,
    isSuccess: equipmentSuccess,
    isError: equipmentIsError,
    error: equipmentError,
  } = useLiveEquipmentQuery();
  const [editSensor, { isSuccess: isSensorEditSuccess }] = useEditSensorMutation();
  const [suppressSensors, { isSuccess: suppressSensorsSuccess, isError: suppressSensorsError }] =
    useSuppressSensorsMutation();

  const [
    unSuppressSensors,
    { isSuccess: unSuppressSensorsSuccess, isError: unSuppressSensorsError },
  ] = useUnSuppressSensorsMutation();

  const auth = useSelector((state) => state.auth);
  const menuId = "equipment-context-menu";
  const isAdmin = auth.isAdmin;

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const initialSensor = {
    SensorLabel: "",
    CustomerID: defaultCustomerID,
    SensorTypeID: "",
    LocationID: "",
    EquipmentID: "",
    SensorLimitTemplateID: "",
    Port: "",
    SubstationSerial: "",
    SensorTypeLabel: "",
    SchemaJSON: "{}",
  };

  const [sensor, setSensor] = useState(initialSensor);
  const [SelectedAcknowlegedSensors, setSelectedAcknowlegedSensors] = useState([]);
  const [AcknowlegedSensor, setAcknowlegedSensor] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState([]);
  const [isBulk, setIsBulk] = useState([]);
  const [faultedEquipmentsLength, setFaultedEquipmentsLength] = useState(0);

  useEffect(() => {
    if (maxWidthSm && customersSuccess) {
      const customers = customersData.map((c) => {
        return {
          value: c.CustomerID,
          label: c.CustomerName,
        };
      });
      const customerID = findObjectByValue(customers, "value", auth.SelectedCustomer)?.value;
      if (customerID) {
        localStorage.setItem("customerID", customerID);
      }
    }
  }, [customersSuccess]);
  useEffect(() => {
    if (allSelectedSensors.length > 0) {
      setIsBulkActionEnabled(true);
    } else {
      setIsBulkActionEnabled(false);
    }
  }, [allSelectedSensors]);

  useEffect(() => {
    if (equipmentTypesSuccess)
      setEquipmentTypes(
        equipmentTypesData.success.data.map((e) => {
          return { value: e.EquipmentTypeID, label: e.EquipmentTypeName };
        })
      );
  }, [equipmentTypesData]);

  useEffect(() => {
    if (sensorTypeSuccess) {
      setSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
      setSuppressSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
      setAcknoweldgeSensorTypes(
        sensorTypeData.success.data.map((e) => {
          return {
            value: e.SensorTypeID,
            label: `${e.SensorTypeName} | ${e.Unit || "unit"}`,
            sensorType: e.ConfigType,
            Unit: e.Unit,
          };
        })
      );
    }
    if (sensorTypeIsError) console.log(sensorTypeError);
    setLoading(false);
  }, [sensorTypeData]);

  useEffect(() => {
    if (locationsSuccess)
      setAllLocations(
        locationsData.map((e) => {
          return { value: e.LocationID, label: e.LocationName };
        })
      );
  }, [locationsData]);

  useEffect(async () => {
    if (selectedAction.length > 0) {
      setLoading(true);
      let res = await suppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setSelectedAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [selectedAction]);

  useEffect(async () => {
    if (unSuppressAction.length > 0) {
      setLoading(true);
      let res = await unSuppressSensors({ SensorIDs: allSelectedSensors });

      if (res?.data?.success) {
        setUnSuppressAction("");
        setSelectedSensorIds([]);
      }
    }
  }, [unSuppressAction]);

  useEffect(() => {
    if (
      allFaultedEquipments.length ||
      selectedEquipmentTypes.length ||
      selectedSensors.length ||
      selectedLocations.length ||
      selectedAlarm.length ||
      startDate ||
      endDate
    ) {
      let [filter1, filter2, filter3, filter4, filter5, filter6] = [
        true,
        true,
        true,
        true,
        true,
        true,
      ];
      let tempEquipments = allFaultedEquipments;
      let filteredData = tempEquipments.filter((equipment) => {
        if (selectedEquipmentTypes.length) {
          filter1 = selectedEquipmentTypes.some((item) => item == equipment.EquipmentTypeID);
        }

        if (selectedSensors.length) {
          filter2 =
            equipment.Sensors.filter((sensor) =>
              selectedSensors.some((item) => item == sensor.SensorTypeID)
            ).length > 0;
        }

        if (selectedLocations.length) {
          filter3 = selectedLocations.some((item) => item == equipment.LocationID);
        }
        if (selectedAlarm.length) {
          let equipmentSensors = equipment.Sensors;

          filter4 =
            equipmentSensors.length == 0
              ? false
              : equipment.Sensors.filter((sensor) => {
                  return selectedAlarm.some((item) => {
                    let liveData = sensor?.LiveData;

                    return (
                      (item == "Warning" && liveData && liveData?.State == 1) ||
                      (item == "Alarm" && liveData && liveData?.State == 2)
                    );
                  });
                }).length > 0;
        }

        if (startDate) {
          filter5 =
            equipment.Sensors.filter((sensor) => moment(sensor.updatedAt) > startDate).length > 0;
        }

        if (endDate) {
          filter6 =
            equipment.Sensors.filter((sensor) => moment(sensor.updatedAt) < endDate).length > 0;
        }

        return filter1 && filter2 && filter3 && filter4 && filter5 && filter6;
      });
      setFaultedEquipments(filteredData);
    } else if (equipmentSuccess) {
      loadFaultedEquipments();
    }
  }, [
    selectedEquipmentTypes,
    selectedSensors,
    selectedLocations,
    selectedAlarm,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(topRef.current.clientHeight);
      return;
    }

    if (clientHeight > 650) {
      setClientHeight(topRef.current.clientHeight);
    }

    topRef.current.style.minHeight = clientHeight + "px";
    topRef.current.style.maxHeight = clientHeight + "px";
  }, [clientHeight]);

  useEffect(() => {
    loadSensorLimitTemplates();
  }, [sensorLimitTemplateData]);

  useEffect(() => {
    if (equipmentSuccess) {
      loadFaultedEquipments();
    }
  }, [equipmentData, equipmentSuccess]);

  useEffect(() => {
    if (faultedEquipments) {
      const faultedEquipmentsLength = getFaultedEquipmentsLength(faultedEquipments);
      setFaultedEquipmentsLength(faultedEquipmentsLength);
    }
  }, [faultedEquipments]);

  const isMenuOpen = Boolean(anchorEl);

  const handleUnSuppressAction = (action) => {
    setUnSuppressAction(action);
    setAnchorEl(null);
  };
  const FaultedSensorDataList = () => {
    let filtredList = [];
    filtredList = faultedEquipments.flatMap((equipment) =>
      equipment.Sensors.filter((s) => s?.LiveData.State == 4 && !s?.LiveData.Acknowledged)
    );
    return filtredList;
  };
  const handleAllSelectSensors = (e) => {
    if (e.target.checked) {
      setSelectedAcknowlegedSensors(
        faultedEquipments.flatMap((equipment) =>
          equipment.Sensors.filter((s) => s?.LiveData.State == 4 && !s?.LiveData.Acknowledged)
        )
      );
      setAllSelectedSensors(
        faultedEquipments.flatMap((equipment) => equipment.Sensors?.map((s) => s.SensorID))
      );
    } else {
      setAllSelectedSensors([]);
      setSelectedAcknowlegedSensors([]);
    }
  };
  const handleSensorSelect = (e) => {
    let value = JSON.parse(e.target.value);
    if (e.target.checked) {
      setAllSelectedSensors(Array.from(new Set([...allSelectedSensors, value.SensorID])));
      setSelectedAcknowlegedSensors(Array.from(new Set([...SelectedAcknowlegedSensors, value])));
    } else {
      setAllSelectedSensors(allSelectedSensors.filter((sensorId) => sensorId != value.SensorID));
      setSelectedAcknowlegedSensors(allSelectedSensors.filter((sensorId) => sensorId != value));
    }
  };

  const toggleSuppressDrawer = (action, isBulk) => {
    setSuppressDrawer(action);
  };
  const toggleAcknowledgeDrawer = (action) => {
    setAcknowledgeDrawer(action);
  };
  const toggleIsBulkAcknowledgeDrawer = (action) => {
    setIsBulk(true);
    setAcknowledgeDrawer(action);
  };

  const handleAlertSuppression = (sensor, action) => {
    toggleSuppressDrawer(action);
    setSensor(sensor);
  };
  const handleAlertAcknowledge = (sensor, action) => {
    setIsBulk(false);
    toggleAcknowledgeDrawer(action);
    setAcknowlegedSensor([sensor]);
  };

  const handleSuppressDrawerEdit = (action) => {
    toggleSuppressDrawer(!action);
  };
  const handleAcknowledgeDrawerEdit = (action) => {
    toggleAcknowledgeDrawer(!action);
  };

  const clearFilter = () => {
    setEquipmentFilterCount(0);
    setStartDate("");
    setEndDate("");
    setAlarmFilterCount(0);
    setLocationFilterCount(0);
    setSensorTypeFilterCount(0);
    setEquipmentFilterCount(0);
    loadFaultedEquipments();
    setSelectedEquipmentTypes([]);
    setSelectedLocations([]);
    setSelectedSensors([]);
    loadFaultedEquipments();
  };

  const handleAlarmSelect = (event) => {
    let updatedList = [...selectedAlarm];
    if (event.target.checked) {
      updatedList = [...selectedAlarm, event.target.value];
      setAlarmFilterCount(alarmFilterCount + 1);
    } else {
      updatedList.splice(selectedAlarm.indexOf(event.target.value), 1);
      setAlarmFilterCount(alarmFilterCount - 1);
    }
    setSelectedAlarm(updatedList);
  };

  const handleSelectEquipmentType = (event) => {
    const {
      target: { value },
    } = event;
    setEquipmentFilterCount(event.target.value.length);
    setSelectedEquipmentTypes(typeof value === "string" ? value.split(",") : value);
  };

  const clearSensor = () => {
    setSensor(initialSensor);
  };

  const handleStartDateChange = (newValue) => {
    console.log(moment(newValue).valueOf());
    setStartDate(moment(newValue).valueOf());
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(moment(newValue).valueOf());
  };

  const handleSelectedAction = (newValue) => {
    setSelectedAction(newValue);
  };

  const handleSelectSensorType = (event) => {
    const {
      target: { value },
    } = event;
    setSensorTypeFilterCount(event.target.value.length);
    setSelectedSensors(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocationFilterCount(event.target.value.length);
    setSelectedLocations(typeof value === "string" ? value?.split(",") : value);
  };

  const loadSensorLimitTemplates = () => {
    if (sensorLimitTemplateLoading) setLoading(true);
    if (sensorLimitTemplateSuccess) {
      const tempData = sensorLimitTemplateData.success.data.map((e) => {
        return {
          value: e.SensorLimitTemplateID,
          label: e.SensorLimitTemplateName,
          sensorTypeValue: e.SensorTypeID,
          LimitsJSON: e.LimitsJSON,
        };
      });
      tempData.push({
        value: "111a11b1-a111-4111-aa11-1eb1dbd1a11a",
        label: "Custom Configurations",
        sensorTypeValue: "",
        LimitsJSON: {},
      });
      setSensorLimitTemplates(tempData);
    }
    if (sensorLimitTemplateIsError) console.log(sensorLimitTemplateError);
    setLoading(false);
  };

  function loadFaultedEquipments() {
    let filterMenuItems = [];
    const tempEquipments = equipmentData?.success?.data
      .map((val) => {
        let filteredSensors = val.Sensors.filter((sensor) =>
          maxWidthSm
            ? sensor?.LiveData?.State === 2 && !sensor?.LiveData?.Suppressed
            : (sensor.LiveData && sensor.IsActive && sensor.LiveData.State) ||
              sensor.LiveData?.Suppressed
        ).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf());
        let sensorStates = Array.from(
          new Set(
            filteredSensors.map((sensor) =>
              sensor.LiveData?.Suppressed ? 3 : sensor.LiveData.State
            )
          )
        );
        filterMenuItems = filterMenuItems.concat(sensorStates);

        return {
          EquipmentID: val.EquipmentID,
          EquipmentName: val.EquipmentName,
          EquipmentTypeName: val.EquipmentType.EquipmentTypeName,
          EquipmentTypeID: val.EquipmentType.EquipmentTypeID,
          LocationName: val.Location?.LocationName,
          LocationID: val.LocationID,
          CustomerID: val.Location?.Customer.CustomerID,
          CustomerName: val.Location?.Customer.CustomerName,
          updatedAt: val.updatedAt,
          Sensors: filteredSensors,
          MenuOptions: sensorStates.map((state) => menuItems[state]),
          equipmentSensorTypes: val.equipmentSensorTypes,
        };
      })
      .filter((equipmentData) => equipmentData.Sensors.length > 0)
      .sort(
        (a, b) =>
          Math.max.apply(
            null,
            b.Sensors.map((sensor) => moment(sensor.updatedAt).valueOf())
          ) -
          Math.max.apply(
            null,
            a.Sensors.map((sensor) => moment(sensor.updatedAt).valueOf())
          )
      );
    if (auth.SelectedCustomer == "") {
      const mapedEquipment = tempEquipments.filter((e) => {
        if (e.Sensors?.length == 0) return true;
        if (e.Sensors.length > 0) return true;
      });
      setFaultedEquipments(mapedEquipment);
    } else {
      let mapedEquipment = filterObjectByValue(
        tempEquipments,
        "CustomerID",
        auth.SelectedCustomer
      ).filter((e) => {
        var faulted = false;
        if (e.Sensors.length > 0) faulted = checkState(e?.Sensors);
        return faulted == true;
      });
      const mapedEquipmentsWithNoAllAcknowledgedSensors = mapedEquipment.filter(
        (e) => !e.Sensors.every((s) => s.LiveData.State === 4 && s.LiveData.Acknowledged)
      );
      setSensorStates(Array.from(new Set(filterMenuItems)));
      setFaultedEquipments(mapedEquipmentsWithNoAllAcknowledgedSensors);
      setAllFaultedEquipments(mapedEquipmentsWithNoAllAcknowledgedSensors);
      setSelectedSensorIds(
        mapedEquipmentsWithNoAllAcknowledgedSensors
          .map((eq) => eq.Sensors.map((sensor) => sensor.SensorID))
          .flat()
      );
      setSelectedSensor(
        mapedEquipmentsWithNoAllAcknowledgedSensors.map((eq) => eq.Sensors.map((sensor) => sensor))
      );
    }
    setLoading(false);
  }

  function checkState(data) {
    return maxWidthSm
      ? data.some((sensor) => typeof sensor == "object" && sensor.LiveData.State === 2)
      : data.some((sensor) => typeof sensor == "object");
  }

  const handleEquipmentContextMenu = (event) => {
    if(SelectedAcknowlegedSensors?.filter(val => val?.LiveData?.State == 4)?.length)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e) => {
    setAnchorEl(null);
  };
  const renderMenu = (
    <CustomMenu
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2))",
          mt: 0.1,
          width: "230px",
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 1,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      disablePadding={true}
    >
      <MenuItem onClick={toggleIsBulkAcknowledgeDrawer}>Acknowledge alarms</MenuItem>
    </CustomMenu>
  );
  const handleEquipmentSupressAlarms = (equipment) => {
    const allEquipmentSensors = equipmentData.success.data.filter(
      (e) => e.EquipmentID === equipment.EquipmentID
    );
    setEquipmentAlarmSuppress(allEquipmentSensors[0]);
    toggleAlarmSuppressDrawer(true);
  };
  const toggleAlarmSuppressDrawer = (action) => {
    setEquipmentAlarmSuppressDrawer(action);
  };

  const handleEquipmentAcknowledgeAlarms = (equipment) => {
    setEquipmentAlarmAcknowledge(equipment);
    toggleAlarmAcknowledgeDrawer(true);
  };
  const toggleAlarmAcknowledgeDrawer = (action) => {
    setEquipmentAlarmAcknowledgeDrawer(action);
  };

  return (
    <>
      {isSensorEditSuccess && (
        <Toast
          message="Sensor successfully updated"
          severity="success"
        />
      )}
      <Box
        {...props}
        className="split-pane-top"
        ref={topRef}
        sx={{
          position: "absolute",
          width: maxWidthSm ? "100%" : window.screen.width - 65,
          height: maxWidthSm ? "100vh" : "inherit",
          zIndex: 999,
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
          overflowY: maxWidthSm ? "auto" : "inherit",
        }}
      >
        <AppBar />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "26px 16px",
              borderBottom: "1px solid #E0E1E3",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                lineHeight: "34px",
                color: "#222934",
                marginRight: "27px",
                width: "fit-content",
                fontWeight: "500",
              }}
            >
              <b>
                {faultedEquipments.length > 1
                  ? `${faultedEquipmentsLength} alarms`
                  : `${faultedEquipments.length} alarm`}
              </b>
            </p>
            {!maxWidthSm && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <Box>
                  <AlarmPaneFilter
                    equipmentFilterCount={equipmentFilterCount}
                    sensorTypeFilterCount={sensorTypeFilterCount}
                    locationFilterCount={locationFilterCount}
                    clearFilter={clearFilter}
                    handleSelectEquipmentType={handleSelectEquipmentType}
                    selectedEquipmentTypes={selectedEquipmentTypes}
                    equipments={equipmentTypes}
                    sensorTypes={sensorTypes}
                    selectedSensors={selectedSensors}
                    handleSelectSensorType={handleSelectSensorType}
                    handleSelectLocation={handleSelectLocation}
                    locations={allLocations}
                    selectedLocations={selectedLocations}
                    selectedAlarm={selectedAlarm}
                    handleAlarmSelect={handleAlarmSelect}
                    alarmFilterCount={alarmFilterCount}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    isDateFieldsEnabled={true}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-start",
                    alignItems: "center",
                    minWidth: "21%",
                    maxWidth: "23%",
                  }}
                >
                  <FormControlLabel
                    control={
                      <CheckBoxComponent
                        size="small"
                        onChange={handleAllSelectSensors}
                        name="bulk-action"
                        Label="Select All"
                        checked={allSelectedSensors.length == selectedSensorIds.length}
                        disabled={FaultedSensorDataList.length}
                      />
                    }
                  />

                  <Box
                    onClick={(e) => isBulkActionEnabled && handleEquipmentContextMenu(e)}
                    sx={{
                      height: "32px",
                      width: "155px",
                      textAlign: "left",
                      font: "normal normal medium 16px/19px Roboto  !important",
                      letterSpacing: "0.64px",
                      color: "#17455E",
                      textTransform: "uppercase",
                      opacity: isBulkActionEnabled ? 1 : 0.6,
                      display: "flex",
                      marginLeft: "16px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "3px solid #17455E",
                      borderRadius: "4px",
                      padding: "10px 5px 10px 5px",
                      "&:hover":
                        !isBulkActionEnabled && FaultedSensorDataList.length
                          ? {}
                          : {
                              backgroundColor: "#11364C",
                              border: "3px solid #00AEEF80",
                              color: "#F7F8F8",
                              opacity: 100,
                            },
                    }}
                  >
                    <p>Bulk Actions</p>
                    <i class="fa-light fa-chevron-down"></i>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {loading ? (
          <SetupSpinnerContainer>
            <Loader loading={loading} />
          </SetupSpinnerContainer>
        ) : (
          <>
            <Box
              sx={{
                overflowY: maxWidthSm ? "unset" : "auto",
                overflowX: maxWidthSm ? "unset" : "auto",
                padding: maxWidthSm ? "unset" : "16px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: maxWidthSm ? "center" : "left",
                height: maxWidthSm ? "100%" : clientHeight - 100,
                rowGap: "16px",
                columnGap: "16px",
                borderRadius: "4px",
                alignContent: maxWidthSm ? "flex-start" : "unset",
              }}
            >
              {faultedEquipments.length > 0
                ? faultedEquipments.map((eq) => {
                    const status = getColorCodingStatus(eq.Sensors);
                    return (
                      <EquipmentAlarmCard
                        state={status}
                        equipment={eq}
                        selectedSensors={allSelectedSensors}
                        handleSensorSelect={handleSensorSelect}
                        setAlarmPanelToggle={setAlarmPanelToggle}
                        handleSelectedAction={handleSelectedAction}
                        setSelectedAction={setSelectedAction}
                        setUnSuppressAction={setUnSuppressAction}
                        setSelectedSensors={setSelectedSensors}
                        suppressSensors={suppressSensors}
                        setAllSelectedSensors={setAllSelectedSensors}
                        setPanAnchorEl={setAnchorEl}
                        handleAlertSuppression={handleAlertSuppression}
                        handleEquipmentSupressAlarms={handleEquipmentSupressAlarms}
                        handleEquipmentAcknowledgeAlarms={handleEquipmentAcknowledgeAlarms}
                        handleAlertAcknowledge={handleAlertAcknowledge}
                      />
                    );
                  })
                : "No  Alarms"}
            </Box>
            <SensorSuppressDrawer
              suppressDrawer={suppressDrawer}
              toggleSuppressDrawer={toggleSuppressDrawer}
              sensor={sensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleSuppressDrawerEdit={handleSuppressDrawerEdit}
              editSensor={true}
            />
            <SensorAcknowledegeDrawer
              suppressDrawer={acknowledgeDrawer}
              toggleAcknowledgeDrawer={toggleAcknowledgeDrawer}
              sensors={isBulkActionEnabled ? SelectedAcknowlegedSensors : AcknowlegedSensor}
              sensorTypes={sensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleAcknowledgeDrawerEdit={handleAcknowledgeDrawerEdit}
              editSensor={true}
              isBulk={isBulk}
            />
            <EquipmentAlarmSupressDrawer
              equipmentAlarmSuppress={equipmentAlarmSuppress}
              equipmentAlarmSuppressDrawer={equipmentAlarmSuppressDrawer}
              toggleAlarmSuppressDrawer={toggleAlarmSuppressDrawer}
              sensor={sensor}
              sensorTypes={suppressSensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleSuppressDrawerEdit={handleSuppressDrawerEdit}
            />
            <EquipmentAlarmAcknowledgeDrawer
              equipmentAlarmAcknowledge={equipmentAlarmAcknowledge}
              equipmentAlarmAcknowledgeDrawer={equipmentAlarmAcknowledgeDrawer}
              toggleAlarmAcknowledgeDrawer={toggleAlarmAcknowledgeDrawer}
              sensor={sensor}
              sensorTypes={AcknoweldgeSensorTypes}
              sensorLimitTemplates={sensorLimitTemplates}
              clearSensor={clearSensor}
              handleAcknowledgeDrawerEdit={handleAcknowledgeDrawerEdit}
            />
            {!acknowledgeDrawer && renderMenu}
          </>
        )}
      </Box>
    </>
  );
};

export default AlarmPane;
