import React from "react";
import TableComponent from "../BaseComponents/Table";
import Box from "@mui/material/Box";
import SingleSelect from "../BaseComponents/SingleSelect";
import moment from "moment";
const ArchivedTable = (props) => {
  let {
    archivedItems,
    setSelectedRows,
    setRowsSelected,
    rowsSelected,
    selectedItemType,
    handleSelectedItemType,
  } = props;

  let mappedData = archivedItems.map((item) => [
    item.entityID,
    item.entityType,
    item.itemName,
    item.itemType,
    item.archivedBy,
    moment(item.archivedOn).utc().format("DD-MMM-YYYY, HH:mm [UTC]"),
  ]);
  const dropdownOptions = [
    { label: "Equipment", value: "Equipment" },
    { label: "Substation", value: "Substation" },
    { label: "Sensor", value: "Sensor" },
    { label: "User", value: "User" },
    { label: "Location", value: "Location" },
  ];

  const columns = [
    {
      name: "EntityID",
      label: "Entity ID",
      options: {
        display: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "EntityType",
      label: "Entity Type",
      options: {
        display: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "ItemName",
      label: "Item Name",
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "ItemType",
      label: "Item Type",
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "ArchivedBy",
      label: "Archived By",
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
    {
      name: "ArchivedOn",
      label: "Archived On",
      options: {
        filter: false,
        searchable: false,
        sort: true,
      },
    },
  ];

  return (
    <>
      <TableComponent
        tableLook="default"
        customOptions={{
          download: false,
          selectableRowsHeader: true,
          rowsSelected: rowsSelected,
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedRows(rowsSelected.map((i) => mappedData[i]));
            setRowsSelected(rowsSelected);
          },
        }}
        downloadable={false}
        columns={columns}
        data={mappedData}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "#222934",
                width: "150px",
                marginRight: "13.2px",
                letterSpacing: "0px",
              }}
            >
              <b style={{ fontWeight: "500" }}>Archived ({archivedItems.length})</b>
            </p>
            <SingleSelect
              padding={true}
              label=""
              placeholder="Please select entity"
              marginTop="0px"
              isDisabled={false}
              options={dropdownOptions}
              handleChange={(e) => handleSelectedItemType(e)}
              value={selectedItemType}
            />
          </Box>
        }
      />
    </>
  );
};

export default ArchivedTable;
