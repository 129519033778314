import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import IconButtonComponent from "../IconButton";
import {
  purple,
  orange,
  info,
  success,
  error,
  lightGrey,
  darkGrey,
  primary,
  yellow,
  warning,
} from "../../../Styles/theme/colors";

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: "19px",
    fontWeight: "500",
    width: "fit-content",
    whiteSpace: "nowrap",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 16px 6px 16px",
    fontSize: `16px`,
    height: "32px",
  },
  statusView: {
    lineHeight: "19px",
    fontWeight: "400",
    width: "fit-content",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 10px 6px 10px",
    fontSize: `11px`,
    height: "16px",
  },
  countBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    marginLeft: "5px",
    fontSize: "11px",
    lineHeight: "24px",
    fontWeight: "500",
  },
}));

const TEXT_COLORS = {
  primary: primary[950],
  neutral: darkGrey[300],
  inactive: darkGrey[300],
  error: error[950],
  info: info[950],
  success: success[950],
  suppressed: purple[950],
  prior: yellow[950],
  warning: orange[950],
  none: "#838588",
};

const BG_COLORS = {
  primary: primary[50],
  neutral: lightGrey[100],
  inactive: lightGrey[100],
  error: error[50],
  info: info[50],
  success: success[50],
  suppressed: purple[50],
  prior: yellow[50],
  warning: warning[50],
  none: "#e8e8e7",
};

const LabelComponent = (props) => {
  const {
    state,
    label,
    count,
    leadingIcon,
    icon,
    StatusView,
    marginRight,
    marginTop,
    marginBottom,
  } = props;

  const classes = useStyles();

  return (
    <Box
      sx={{
        color: TEXT_COLORS[state],
        bgcolor: BG_COLORS[state],
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
      className={StatusView ? classes.statusView : classes.root}
    >
      {leadingIcon && (
        <IconButtonComponent
          size="small"
          aria-label="close"
          Component={{ icon }}
        />
      )}
      {label}
      {count > 0 && (
        <Box
          sx={{
            bgcolor: BG_COLORS[state],
            color: TEXT_COLORS[state],
          }}
          className={classes.countBox}
        >
          {count}
        </Box>
      )}
    </Box>
  );
};

export default LabelComponent;
