import RadioGroup from "@mui/material/RadioGroup";

const RadioGroupComponent = (props) => {
  const { FormControlLabels, callBack, value, name, sx } = props;
  return (
    <RadioGroup
      row
      sx={{ sx }}
      aria-labelledby="demo-row-radio-buttons-group-label"
      value={value}
      defaultValue={""}
      name={name}
      onChange={(event) => callBack(event.target.value)}
    >
      {FormControlLabels}
    </RadioGroup>
  );
};
export default RadioGroupComponent;
