import React, { Fragment, useEffect, useState } from "react";

//COMPONENTS IMPORT
import RolesDrawer from "@Components/RightDrawer/RolesDrawer";
import Loader from "@Components/LoadingSpinner";
import Toast from "@Components/BaseComponents/Toast";
import { useFindUsersQuery } from "../../../Store/Slices/usersSlice";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import { useSelector } from "react-redux";
import { filterObjectByValue, findObjectByValue, validateUIState } from "../../../Utils/Tools";
import {
  useAddAuthorizationGroupMutation,
  useEditAuthorizationGroupMutation,
  useFindAuthorizationGroupsQuery,
} from "../../../Store/Slices/authorizationGroupsSlice";
import RolesTable from "../../../Components/Tables/RolesTable";
import { useFindAuthorizationsQuery } from "../../../Store/Slices/authorizationSlice";
import { useFindAuthorizationGroupAuthorizationsQuery } from "../../../Store/Slices/authorizationGroupAuthorizationsSlice";
import { useNavigate } from "react-router-dom";

const Roles = () => {
  const {
    data: usersData,
    isLoading: usersLoading,
    isSuccess: userSuccess,
    isError: usersIsError,
    error: usersError,
  } = useFindUsersQuery();

  const {
    data: authorizationGroupsData,
    isLoading: authorizationGroupsLoading,
    isSuccess: authorizationGroupsSuccess,
    isError: authorizationGroupsIsError,
    error: authorizationGroupsError,
  } = useFindAuthorizationGroupsQuery();

  const {
    data: authorizationGroupsAuthorizationsData,
    isLoading: authorizationGroupsAuthorizationsLoading,
    isSuccess: authorizationGroupsAuthorizationsSuccess,
    isError: authorizationGroupsAuthorizationsIsError,
    error: authorizationGroupsAuthorizationsError,
  } = useFindAuthorizationGroupAuthorizationsQuery();

  const {
    data: AuthorizationsData,
    isLoading: AuthorizationsLoading,
    isSuccess: AuthorizationsSuccess,
    isError: AuthorizationIsError,
    error: AuthorizationsError,
  } = useFindAuthorizationsQuery();

  const [
    addAuthorizationGroup,
    {
      isSuccess: addAuthorizationGroupSuccess,
      isError: addAuthorizationGroupsIsError,
      isLoading: addAuthorizationGroupLoading,
      error: addAuthorizationGroupError,
    },
  ] = useAddAuthorizationGroupMutation();

  const [
    editAuthorizationGroup,
    {
      isSuccess: editAuthorizationGroupSuccess,
      isError: editAuthorizationGroupsIsError,
      isLoading: editAuthorizationGroupLoading,
      error: editAuthorizationGroupError,
    },
  ] = useEditAuthorizationGroupMutation();

  const auth = useSelector((state) => state.auth);
  const isAdmin = auth.isAdmin;
  const allowedEdit = validateUIState(auth.Authorizations, "setup", "Roles");

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userRoleCount, setUserRoleCount] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [authorizations, setAuthorizations] = useState([]);
  const [authorizationGroups, setAuthorizationGroups] = useState([]);
  const [authorizationGroupAuthorizations, setauthorizationGroupAuthorizations] = useState([]);
  const [discardSettingsDrawer, setDiscardSettingsDrawer] = useState(false);
  const [userRoleUpdated, setUserRoleUpdated] = useState(false);

  const defaultCustomerID = isAdmin
    ? auth.SelectedCustomer == ""
      ? ""
      : auth.SelectedCustomer
    : auth.CustomerID;

  const [permissions, setPermissions] = useState([
    {
      label: "Status View",
      Read: true,
      Edit: false,
      PageName: "statusView",
      ElementName: "statusView",
      UIState: "",
    },
    {
      label: "Report",
      Read: false,
      Edit: false,
      PageName: "report",
      ElementName: "report",
      UIState: "",
    },
    {
      label: "Audit Trail",
      Read: false,
      Edit: false,
      PageName: "audittrail",
      ElementName: "AuditTrail",
      UIState: "",
    },
    {
      label: "Equipment",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Equipment",
      UIState: "",
    },
    {
      label: "Substations",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Substations",
      UIState: "",
    },
    {
      label: "Sensors",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Sensors",
      UIState: "",
    },
    {
      label: "Locations",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Locations",
      UIState: "",
    },
    {
      label: "Maps",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Maps",
      UIState: "",
    },
    {
      label: "Alert Programs",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "AlertPrograms",
      UIState: "",
    },
    {
      label: "Sensor Groups",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "SensorGroups",
      UIState: "",
    },
    {
      label: "Users",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Users",
      UIState: "",
    },
    {
      label: "Roles",
      Read: false,
      Edit: false,
      PageName: "setup",
      ElementName: "Roles",
      UIState: "",
    },
    {
      label: "Tenants",
      Read: false,
      Edit: false,
      PageName: "tenant",
      ElementName: "tenant",
      UIState: "",
    },
  ]);

  const [userRole, setUserRole] = useState({
    AuthorizationGroupName: "",
    CustomerID: defaultCustomerID,
    Authorizations: [
      {
        PageName: "statusView",
        ElementName: "statusView",
        UIState: "ReadOnly",
      },
    ],
  });

  const [initialUserRole, setInitialUserRole] = useState({});

  const authorizationLabels = {
    statusView: "Status View",
    tenant: "Tenants",
    AuditTrail: "Audit Trail",
    AlertPrograms: "Alert Programs",
    report: "Report",
    Equipment: "Equipment",
    Substations: "Substations",
    Sensors: "Sensors",
    Locations: "Locations",
    Maps: "Maps",
    Users: "Users",
    Roles: "Roles",
  };

  const defaultUserRoles = [
    "213d6308-f2d4-4153-bea6-317f3ddd99b9",
    "213d6308-f2d4-4153-bea6-317f3ddd78b9",
    "213d6308-f2d4-4153-bea6-317f3ddd77b9",
  ];

  const buttonValidation = !(
    userRole.AuthorizationGroupName !== "" &&
    userRole.CustomerID !== "" &&
    userRole.Authorizations.length !== 0
  );

  const loadUsers = () => {
    if (userSuccess) {
      if (!isAdmin) setUsers(filterObjectByValue(usersData, "CustomerID", auth.CustomerID));
      else {
        if (auth.SelectedCustomer == "") setUsers(usersData);
        else setUsers(filterObjectByValue(usersData, "CustomerID", auth.SelectedCustomer));
      }
    }
    if (usersIsError) console.log(usersError);
  };

  const loadAuthorizationGroups = () => {
    if (authorizationGroupsSuccess) {
      const AGS = authorizationGroupsData.success.data.map((u) => {
        return {
          value: u.AuthorizationGroupID,
          label: u.AuthorizationGroupName,
          CustomerID: u.CustomerID,
        };
      });
      const defaultRoles = filterObjectByValue(AGS, "CustomerID", null);
      if (!isAdmin)
        setAuthorizationGroups([
          ...filterObjectByValue(AGS, "CustomerID", auth.CustomerID),
          ...defaultRoles,
        ]);
      else {
        if (auth.SelectedCustomer == "") setAuthorizationGroups(AGS);
        else
          setAuthorizationGroups([
            ...filterObjectByValue(AGS, "CustomerID", auth.SelectedCustomer),
            ...defaultRoles,
          ]);
      }
    }
    if (authorizationGroupsIsError) console.log(authorizationGroupsError);
  };

  const loadauthorizationGroupsAuthorizations = () => {
    if (authorizationGroupsAuthorizationsSuccess)
      setauthorizationGroupAuthorizations(authorizationGroupsAuthorizationsData.success.data);
    if (authorizationGroupsAuthorizationsIsError)
      console.log(authorizationGroupsAuthorizationsError);
  };

  const loadAuthorizations = () => {
    if (AuthorizationsSuccess)
      setAuthorizations(
        AuthorizationsData.success.data.map((u) => {
          return {
            value: u.AuthorizationID,
            PageName: u.PageName,
            NavigationName: u.NavigationName,
            ElementName: u.ElementName,
            UIState: u.UIState,
          };
        })
      );

    if (AuthorizationIsError) console.log(AuthorizationsError);
  };

  useEffect(() => {
    loadUsers();
  }, [usersData]);

  useEffect(() => {
    loadAuthorizationGroups();
  }, [authorizationGroupsData, auth]);

  useEffect(() => {
    loadAuthorizations();
  }, [AuthorizationsData]);

  useEffect(() => {
    loadauthorizationGroupsAuthorizations();
  }, [authorizationGroupsAuthorizationsData]);

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (open === false) {
      setErrors({});
      clearUserRole();
      setEdit(false);
    }
  };

  const handleEdit = (UserRole) => {
    setUserRole({
      AuthorizationGroupName: UserRole.label,
      CustomerID: UserRole.CustomerID,
      value: UserRole.value,
      Authorizations: handleEditPermissions(UserRole.value),
    });
    setInitialUserRole({
      AuthorizationGroupName: UserRole.label,
      CustomerID: UserRole.CustomerID,
      value: UserRole.value,
      Authorizations: handleEditPermissions(UserRole.value),
    });
    setUserRoleCount(filterObjectByValue(users, "RoleID", UserRole.value)?.length);

    setEdit(true);
    toggleDrawer(true);
  };

  const handleEditPermissions = (UserRoleID) => {
    const authorizationsData = filterObjectByValue(
      authorizationGroupAuthorizations,
      "AuthorizationGroupID",
      UserRoleID
    )?.map((authorizationGroup) => {
      return findObjectByValue(authorizations, "value", authorizationGroup.AuthorizationID);
    });
    let tempPermissions = [...permissions];
    let tempAuthorizations = [];
    authorizationsData.map((authorization) => {
      const index = tempPermissions.findIndex(
        (permission) => permission.label === authorizationLabels[authorization.ElementName]
      );
      if (index >= 0)
        if (authorization?.UIState == "Edit") tempPermissions[index]["Edit"] = true;
        else tempPermissions[index]["Read"] = true;
      tempAuthorizations.push(authorization);
    });
    setPermissions(tempPermissions);
    return tempAuthorizations;
  };

  const handleClick = (reason) => {
    edit ? handleEditSave(reason) : handleCreate(reason);
  };

  const handleCreate = async (reason) => {
    let res = await addAuthorizationGroup({ ...userRole, reason: reason });
    clearUserRole();
    toggleDrawer(false);
  };

  const handleEditSave = async (reason) => {
    let rolesDiff = [];
    if (initialUserRole.Authorizations.length >= userRole.Authorizations.length) {
      initialUserRole.Authorizations.forEach((item) => {
        const elementName = item.ElementName;
        const UIState = item.UIState;
        const matchingItem = userRole.Authorizations.find(
          (newItem) => newItem.ElementName === elementName
        );
        if (matchingItem) {
          if (matchingItem.UIState !== UIState) {
            let diffHash = {};
            diffHash[elementName] = [UIState, matchingItem.UIState];
            rolesDiff.push(diffHash);
          }
        }
        if (!matchingItem) {
          let diffHash = {};
          diffHash[elementName] = [UIState, "None"];
          rolesDiff.push(diffHash);
        }
      });
    } else {
      userRole.Authorizations.forEach((item) => {
        const elementName = item.ElementName;
        const UIState = item.UIState;
        const matchingItem = initialUserRole.Authorizations.find(
          (newItem) => newItem.ElementName === elementName
        );
        if (matchingItem) {
          if (matchingItem.UIState !== UIState) {
            let diffHash = {};
            diffHash[elementName] = [UIState, matchingItem.UIState];
            rolesDiff.push(diffHash);
          }
        }
        if (!matchingItem) {
          let diffHash = {};
          diffHash[elementName] = ["None", UIState];
          rolesDiff.push(diffHash);
        }
      });
    }

    console.log(rolesDiff);
    let res = await editAuthorizationGroup({
      id: userRole.value,
      data: { ...userRole, rolesDiff, reason: reason },
    });
    clearUserRole();
    toggleDrawer(false);
  };

  const clearUserRole = () => {
    setUserRole({
      AuthorizationGroupName: "",
      CustomerID: defaultCustomerID,
      Authorizations: [
        {
          PageName: "statusView",
          ElementName: "statusView",
          UIState: "ReadOnly",
          report: "Report",
        },
      ],
    });
    setPermissions([
      {
        label: "Status View",
        Read: true,
        Edit: false,
        PageName: "statusView",
        ElementName: "statusView",
        UIState: "",
      },
      {
        label: "Report",
        Read: false,
        Edit: false,
        PageName: "report",
        ElementName: "report",
        UIState: "",
      },
      {
        label: "Audit Trail",
        Read: false,
        Edit: false,
        PageName: "auditTrail",
        ElementName: "AuditTrail",
        UIState: "",
      },
      {
        label: "Equipment",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Equipment",
        UIState: "",
      },
      {
        label: "Substations",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Substations",
        UIState: "",
      },
      {
        label: "Sensor Groups",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "SensorGroups",
        UIState: "",
      },
      {
        label: "Sensors",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Sensors",
        UIState: "",
      },
      {
        label: "Locations",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Locations",
        UIState: "",
      },
      {
        label: "Maps",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Maps",
        UIState: "",
      },
      {
        label: "Alert Programs",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "AlertPrograms",
        UIState: "",
      },
      {
        label: "Users",
        Read: false,
        PageName: "setup",
        ElementName: "Users",
        UIState: "",
      },
      {
        label: "Roles",
        Read: false,
        Edit: false,
        PageName: "setup",
        ElementName: "Roles",
        UIState: "",
      },
      {
        label: "Tenants",
        Read: false,
        Edit: false,
        PageName: "tenant",
        ElementName: "tenant",
        UIState: "",
      },
    ]);
  };

  const handleNavigate = (authorization) => {
    navigate("/setup/users", {
      state: {
        RoleID: authorization.value,
      },
    });
  };

  const confirmationDrawer = (open) => {
    setDiscardSettingsDrawer(open);
  };

  const UserRoleConfirmationDrawer = (open) => {
    if (userRoleUpdated) {
      confirmationDrawer(true);
    } else {
      toggleDrawer(false);
    }
  };

  return (
    <Fragment>
      {addAuthorizationGroupSuccess && (
        <Toast
          message="User role successfully added"
          severity="success"
        />
      )}

      {addAuthorizationGroupsIsError && (
        <Toast
          message="User role name is not unique"
          severity="error"
        />
      )}

      {editAuthorizationGroupsIsError && (
        <Toast
          message="User role name is not unique"
          severity="error"
        />
      )}
      {editAuthorizationGroupSuccess && (
        <Toast
          message="User role successfully updated"
          severity="success"
        />
      )}
      {usersLoading ||
      authorizationGroupsLoading ||
      AuthorizationsLoading ||
      authorizationGroupsAuthorizationsLoading ? (
        <SpinnerContainer>
          <Loader />
        </SpinnerContainer>
      ) : (
        <RolesTable
          users={users}
          toggleDrawer={toggleDrawer}
          handleEdit={handleEdit}
          auth={auth}
          authorizations={authorizations}
          authorizationGroups={authorizationGroups}
          authorizationGroupAuthorizations={authorizationGroupAuthorizations}
          defaultUserRoles={defaultUserRoles}
          handleNavigate={handleNavigate}
          authorizationLabels={authorizationLabels}
          allowedEdit={allowedEdit}
        />
      )}
      <RolesDrawer
        toggleDrawer={toggleDrawer}
        UserRoleConfirmationDrawer={UserRoleConfirmationDrawer}
        drawer={drawer}
        edit={edit}
        auth={auth}
        userRole={userRole}
        setUserRole={setUserRole}
        handleClick={handleClick}
        buttonValidation={buttonValidation}
        clearUserRole={clearUserRole}
        userRoleCount={userRoleCount}
        handleNavigate={handleNavigate}
        permissions={permissions}
        setPermissions={setPermissions}
        discardSettingsDrawer={discardSettingsDrawer}
        confirmationDrawer={confirmationDrawer}
        userRoleUpdated={userRoleUpdated}
        setUserRoleUpdated={setUserRoleUpdated}
      />
    </Fragment>
  );
};

export default Roles;
