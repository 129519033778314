import React, { Fragment, useEffect, useState } from "react";

//COMPONENTS IMPORT
import CustomersTable from "./CustomersTable";
import Grid from "@mui/material/Grid";
import CustomersDrawer from "@Components/RightDrawer/CustomersDrawer";
import Loader from "@Components/LoadingSpinner";
import Toast from "@Components/BaseComponents/Toast";
import {
  useFindCustomersQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
} from "../../../Store/Slices/customersSlice";
import SetupContainer from "../../../Containers/SetupContainer";
import { default as SpinnerContainer } from "../../../Containers/SetupSpinnerContainer";
import postalCodes from "postal-codes-js";
//import {zips} from '../../../Constants/ZipCodes'

const Customer = () => {
  const [addCustomer, { isSuccess: addCustomerSuccess }] = useAddCustomerMutation();
  const [editCustomer, { isSuccess: successEditCustomer, error: editError }] =
    useEditCustomerMutation();
  const [customers, setCustomers] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [msg, setMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [customer, setCustomer] = useState({
    CustomerName: "",
    Address: "",
    City: "",
    StateProvince: "",
    Country: "",
    PostalCode: "",
    ContactName: "",
    ContactPhone: "",
    ContactEmail: "",
  });

  const clearCustomer = () => {
    setCustomer({
      CustomerName: "",
      Address: "",
      City: "",
      StateProvince: "",
      Country: "",
      PostalCode: "",
      ContactName: "",
      ContactPhone: "",
      ContactEmail: "",
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const handleCreate = async () => {
    console.log("entering create");
    if (validate(customer)) {
      try {
        let res = await addCustomer(customer);
        console.log("🚀 ~ file: CustomersSetup.js ~ line 70 ~ handleCreate ~ res", res);
        if (res.error) setMsg(res.error.data.error.message);
      } catch (err) {
        console.log("ERROR", err);
      }
      toggleDrawer(false);
    }
  };

  const handleEdit = (customer) => {
    toggleDrawer(true);
    setEdit(true);
    setCustomer(customer);
  };

  const buttonValidation = (customer) => {
    let new_customer = { ...customer };
    delete new_customer["IsActive"];
    return !Object.values(new_customer).every((el) => el != "");
  };
  const handleUpdate = async (customerEdit) => {
    console.log(
      "🚀 ~ file: CustomersSetup.js ~ line 87 ~ handleUpdate ~ customerEdit",
      customerEdit
    );
    delete customerEdit["isActive"];
    let { CustomerID } = customerEdit;
    let id = CustomerID;
    let data = customerEdit;
    if (validate(data)) {
      try {
        let res = await editCustomer({ id, data });
        toggleDrawer(false);
        setEdit(false);
        clearCustomer();
      } catch (err) {
        console.log(editError);
        console.log("ERROR:", err);
      }
    }
  };

  const toggleDrawer = (open) => {
    setDrawer(open);
    if (drawer === false) {
      clearCustomer();
      setErrors({});
      setEdit(false);
    }
  };

  const zipValidator = (countryCode, userZipCode) => {
    console.log(
      "🚀 ~ file: CustomersSetup.js ~ line 127 ~ zipValidator ~ countryCode",
      countryCode,
      "zip:",
      userZipCode
    );
    return postalCodes.validate(countryCode, userZipCode);
  };

  const validate = (fieldValues) => {
    let zipValidationResult = zipValidator(customer.Country, customer.PostalCode);
    let temp = { ...errors };
    if ("CustomerName" in fieldValues)
      temp.CustomerName = fieldValues.CustomerName ? "" : "This field is required.";
    if ("ContactEmail" in fieldValues)
      temp.ContactEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          fieldValues.ContactEmail
        )
          ? ""
          : "Email is not valid.";
    if ("ContactPhone" in fieldValues)
      temp.ContactPhone =
        /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g.test(
          fieldValues.ContactPhone
        )
          ? ""
          : "Not a valid phone number.";
    if ("PostalCode" in fieldValues)
      temp.PostalCode = zipValidationResult == true ? "" : zipValidationResult;
    //zipValidator(customer.Country, customer.PostalCode)
    setErrors({
      ...temp,
    });
    if (fieldValues) return Object.values(temp).every((x) => x == "");
  };

  const {
    data: customersData,
    isLoading: customersLoading,
    isSuccess: customerSuccess,
    isError: customersIsError,
    error: customerError,
  } = useFindCustomersQuery();

  useEffect(() => {
    let content;
    if (customersLoading) {
      content = <p>Loading...</p>;
    } else if (customerSuccess) {
      setCustomers(customersData);
    } else if (customersIsError) {
      console.log(customerError);
    }
  }, [customersData]);

  useEffect(() => {
    let new_errors = { ...errors };
    new_errors.ContactEmail = "";
    setErrors(new_errors);
  }, [customer.ContactEmail]);

  useEffect(() => {
    let new_errors = { ...errors };
    new_errors.ContactPhone = "";
    setErrors(new_errors);
  }, [customer.ContactPhone]);

  useEffect(() => {
    let new_errors = { ...errors };
    new_errors.PostalCode = "";
    setErrors(new_errors);
  }, [customer.PostalCode]);

  useEffect(() => {
    setValid(buttonValidation(customer));
  }, [customer]);

  useEffect(() => {
    setCustomer({ ...customer, StateProvince: "" });
  }, [customer.Country]);

  useEffect(() => {
    setCustomer({ ...customer, City: "" });
  }, [customer.StateProvince]);

  useEffect(() => {
    if (msg === "") return;
    const timeout = setTimeout(() => {
      setMsg("");
    }, 2000);

    return () => clearTimeout(timeout);
  }, [msg]);

  return (
    <Fragment>
      <SetupContainer>
        {addCustomerSuccess && (
          <Toast
            message="Customer added successfully."
            severity="success"
            autoHideDuration={6000}
          />
        )}

        {successEditCustomer && (
          <Toast
            message="Customer edited successfully"
            severity="success"
            autoHideDuration={6000}
          />
        )}

        {msg != "" && (
          <Toast
            message={msg}
            severity="error"
            autoHideDuration={6000}
          />
        )}
        {customersLoading ? (
          <SpinnerContainer>
            <Loader />
          </SpinnerContainer>
        ) : (
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
            >
              <CustomersTable
                customers={customers}
                toggleDrawer={toggleDrawer}
                handleEdit={handleEdit}
                errors={errors}
              />
            </Grid>
          </Grid>
        )}
        <CustomersDrawer
          toggleDrawer={toggleDrawer}
          handleChange={handleChange}
          handleCreate={handleCreate}
          customer={customer}
          drawer={drawer}
          checked={checked}
          setDrawer={setDrawer}
          setCustomer={setCustomer}
          errors={errors}
          edit={edit}
          handleUpdate={handleUpdate}
          valid={valid}
        />
      </SetupContainer>
    </Fragment>
  );
};

export default Customer;
