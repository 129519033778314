import { apiSlice } from "../API";

export const sensorAcknowledgeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    acknowledgeSensors: builder.mutation({
      query: (data) => ({
        url: `/sensorAcknowledge/cache`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sensor"],
    }),

  }),
});

export const {
  useAcknowledgeSensorsMutation
} = sensorAcknowledgeSlice;