import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import MapContainer from "./map";
import { useFindLocationsQuery, useEditLocationMutation } from "../../Store/Slices/locationsSlice";
import { useUploadImageMutation } from "../../Store/Slices/imageSlice";
import { useLiveEquipmentQuery, useEditEquipmentMutation } from "../../Store/Slices/equipmentSlice";
import { filterObjectByValue, findObjectByValue } from "../../Utils/Tools";
import Toast from "@Components/BaseComponents/Toast";
import IconButton from "@mui/material/IconButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Tooltip from "@mui/material/Tooltip";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import { Divider } from "@mui/material";
import LocationsDropdown from "../BaseComponents/LocationsDropdown";
import ThemeButton from "@Components/Buttons";
import TextButton from "@Components/Buttons/TextButton";

import { useTheme } from "@mui/material/styles";

const MapMainContainer = (props) => {
  const theme = useTheme();
  const {
    edit,
    setEdit,
    toggleClearMapDrawer,
    handleSave,
    toggleChangesDrawer,
    equipmentLive,
    mappedEquipmentLoading,
    mappedSubstationLoading,
    updatedMappedEquipments,
    setUpdatedMappedEquipments,
    selectedLocationId,
    setEditedMappedEquipments,
    mappedSubstations,
    updatedMappedSubstations,
    editedMappedSubstations,
    editedMappedEquipments,
    setUpdatedMappedSubstations,
    setEditedMappedSubstations,
    paneWidth,
    allowedEdit,
  } = props;

  const [uploadImage, { isSuccess: imageUploadSuccess }] = useUploadImageMutation();
  const { data: equipmentData, isSuccess: equipmentDataSuccess } = useLiveEquipmentQuery();
  const [editEquipment, { isSuccess: editEquipmentSucces }] = useEditEquipmentMutation();

  const [editLocation, { isSuccess: editLocationSucces, error: editLocationError }] =
    useEditLocationMutation();

  const [selectedId, selectShape] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [newEquipment, setNewEquipment] = useState(false);
  const [items, setItems] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    data: locationsData,
    isLoading: locationsLoading,
    isSuccess: locationsSuccess,
    isError: locationsIsError,
    error: locationsError,
  } = useFindLocationsQuery();

  // UPLOAD IMAGE FUNCTION
  const onSelectFile = async (event) => {
    let formData = new FormData();
    console.log(event, "File");
    formData.append("myFile", event, event.name);
    console.log(formData, "FormData");
    var res = await uploadImage(formData);
    setResponse(res.data.success.data.fileId);
    console.log("🚀 ~ file: index.js ~ line 105 ~ onSelectFile ~ res", res);
  };

  const getAllequipments = () => {
    let filterEquipment = [];
    if (equipmentDataSuccess) {
      setEquipment(equipmentData.success.data);
      setItems(
        equipmentData.success.data.map((eq) => ({
          ...eq,
          isDragging: false,
          id: eq.EquipmentID,
        }))
      );
    }
    setNewEquipment(false);
  };

  useEffect(() => {
    const ME = updatedMappedEquipments.map((equipment) => {
      const equipmentObj = findObjectByValue(equipmentLive, "EquipmentID", equipment.EquipmentID);

      return {
        Sensors: equipmentObj ? equipmentObj.Sensors : [],
        ...equipment,
        isDragging: false,
        id: equipment.EquipmentID,
      };
    });
    const MS = updatedMappedSubstations.map((substation) => {
      return {
        ...substation,
        isDragging: false,
        id: substation.SubstationID,
      };
    });
    const temp = [...ME, ...MS];
    setItems(temp);
  }, [props.LocationID, props.locationObject, updatedMappedEquipments, updatedMappedSubstations]);

  useEffect(() => {
    if (response !== "") {
      const data = { MapFile: response };
      let res = editLocation({ id: props.LocationID, data });
    }
  }, [response]);

  useEffect(() => {
    setLocationItems(filterObjectByValue(items, "LocationID", props.LocationID));
  }, [items, props.LocationID, updatedMappedEquipments, updatedMappedSubstations]);

  const validator = props.CustomerID === "" && props.LocationID == "";

  return (
    <Box
      component="span"
      sx={{ bgcolor: "#fff" }}
    >
      {" "}
      {imageUploadSuccess && (
        <Toast
          message="Image was uploaded successfully."
          severity="success"
          autoHideDuration={3000}
        />
      )}
      {editEquipmentSucces && (
        <Toast
          message="Equipment was added successfully."
          severity="success"
          autoHideDuration={3000}
        />
      )}
      {validator && (
        <Toast
          message="Please Select Customer and Location"
          severity="error"
          autoHideDuration={3000}
        />
      )}
      {props.LocationID !== "" && (
        <>
          {mappedEquipmentLoading && mappedSubstationLoading ? (
            <SpinnerContainer>
              <Loader loading={loading} />
            </SpinnerContainer>
          ) : (
            <div
              id="mapWrapper"
              style={{
                display: "flex",
                justifyContent: edit && "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  height: "64px",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#222934",
                    padding: "0.8em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <b> {props.locationObject?.LocationName}</b>
                  <LocationsDropdown
                    setSelectedLocationId={props.setSelectedLocationId}
                    selectedLocationId={props.LocationID}
                    substations={true}
                  />
                </p>

                <Divider />
                {locationItems.length == 0 && (
                  <p
                    style={{
                      background: "#E8F1FE 0% 0% no-repeat padding-box",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#014361",
                      padding: "0.6em",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    <i
                      className="fa-solid fa-info-circle fa-xl"
                      style={{
                        color: `${theme.palette["info"][900]}`,
                        marginRight: "5px",
                      }}
                    ></i>
                    There is no equipment or substations placed on this map. Add an item to the map
                    from the list to the right.
                  </p>
                )}
              </div>

              <div style={{ marginTop: !edit && "16px" }}>
                {props.locationObject?.MapFile !== "" ? (
                  <MapContainer
                    selectedId={selectedId}
                    selectShape={selectShape}
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    equipment={equipment}
                    getAllequipments={getAllequipments}
                    items={locationItems}
                    setItems={setItems}
                    editEquipment={editEquipment}
                    setNewEquipment={setNewEquipment}
                    locationMap={props.locationObject?.MapFile}
                    renderMap={true}
                    handleEquipmentSelect={props.handleEquipmentSelect}
                    SelectedEquipment={props.SelectedEquipment}
                    setSelectedEquipment={props.setSelectedEquipment}
                    equipmentLive={equipmentLive}
                    setUpdatedMappedEquipments={setUpdatedMappedEquipments}
                    selectedLocationId={selectedLocationId}
                    setEditedMappedEquipments={setEditedMappedEquipments}
                    setEdit={setEdit}
                    updatedMappedEquipments={updatedMappedEquipments}
                    mappedSubstations={mappedSubstations}
                    updatedMappedSubstations={updatedMappedSubstations}
                    editedMappedSubstations={editedMappedSubstations}
                    editedMappedEquipments={editedMappedEquipments}
                    setUpdatedMappedSubstations={setUpdatedMappedSubstations}
                    setEditedMappedSubstations={setEditedMappedSubstations}
                    paneWidth={paneWidth}
                  />
                ) : (
                  <>
                    <MapContainer
                      selectedId={selectedId}
                      selectShape={selectShape}
                      openDialog={openDialog}
                      setOpenDialog={setOpenDialog}
                      equipment={equipment}
                      getAllequipments={getAllequipments}
                      items={items}
                      setItems={setItems}
                      editEquipment={editEquipment}
                      setNewEquipment={setNewEquipment}
                      renderMap={false}
                      SelectedEquipment={props.SelectedEquipment}
                      setSelectedEquipment={props.setSelectedEquipment}
                      equipmentLive={equipmentLive}
                      setUpdatedMappedEquipments={setUpdatedMappedEquipments}
                      updatedMappedEquipments={updatedMappedEquipments}
                      selectedLocationId={selectedLocationId}
                      setEditedMappedEquipments={setEditedMappedEquipments}
                      setEdit={setEdit}
                      mappedSubstations={mappedSubstations}
                      updatedMappedSubstations={updatedMappedSubstations}
                      editedMappedSubstations={editedMappedSubstations}
                      editedMappedEquipments={editedMappedEquipments}
                      setUpdatedMappedSubstations={setUpdatedMappedSubstations}
                      setEditedMappedSubstations={setEditedMappedSubstations}
                      paneWidth={paneWidth}
                    />

                    <div style={{ margin: "1em" }}>
                      No Map found for this location! Upload New One?
                      <Tooltip title="Upload Map">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            type="file"
                            hidden
                            onChange={(event) => onSelectFile(event.target.files[0])}
                          />
                          <UploadFileIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
              {locationItems.length != 0 && (
                <div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: edit ? "flex-end" : "flex-start",
                      alignItems: "center",
                      height: "64px",
                    }}
                  >
                    {!edit && allowedEdit && (
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#17455E",
                          padding: "1em",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "fit-content",
                          height: "64px",
                        }}
                        onClick={() => toggleClearMapDrawer(true)}
                      >
                        <b> CLEAR MAP</b>
                      </p>
                    )}
                    {edit && (
                      <div
                        style={{
                          display: "flex",
                          padding: "1em",
                        }}
                      >
                        <TextButton
                          width="80px"
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={() => toggleChangesDrawer(true)}
                        >
                          Cancel
                        </TextButton>
                        <div style={{ display: "inline", marginLeft: "5px" }}>
                          <ThemeButton
                            variant="contained"
                            color="primary"
                            size="small"
                            width="0px"
                            padding="5px 0px 6px 0px"
                            height="32px"
                            minWidth="58px"
                            lineHeight="21"
                            onClick={handleSave}
                          >
                            Save
                          </ThemeButton>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default MapMainContainer;
