import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks(props) {
  const { placeholder, personName, onChange, disabled, children, data, marginTop } = props;

  const [inFocus, setInFocus] = React.useState(false);

  const DropdownIndicator = () => {
    if (inFocus)
      return (
        <i
          class="fa-light fa-chevron-up"
          style={{ fontSize: "16px", marginRight: "12px", color: "#9CA1A6" }}
        ></i>
      );
    else
      return (
        <i
          class="fa-light fa-chevron-down"
          style={{ fontSize: "16px", marginRight: "12px", color: "#9CA1A6" }}
        ></i>
      );
  };
  return (
    <div>
      <FormControl
        variant="outlined"
        size="small"
        margin="dense"
        disabled={disabled ? disabled : false}
        sx={{ height: 40, width: "520px", marginTop: "0px" }}
      >
        <Select
          sx={{
            width: "100%",
            marginTop: marginTop ? marginTop : "10px",
            border: "1px solid #9CA1A6",
            "& .css-tbxe8u-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
              background: "#F1F1F1 0% 0% no-repeat padding-box",
              color: "#C0C3C7",
              border: "1px solid #C0C3C7",
              borderRadius: "4px",
              width: "100%",
            },

            "&:hover": {
              border: "1px solid #17455E",
              borderRadius: "4px",
            },
            "&.Mui-focused fieldset": {
              boxShadow: "#00AEEF 0px 0px 0px 3px",
            },
          }}
          multiple
          displayEmpty
          value={personName}
          onChange={onChange}
          input={<OutlinedInput />}
          IconComponent={DropdownIndicator}
          onClose={() => setInFocus(false)}
          onOpen={() => setInFocus(true)}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <p
                  style={{
                    font: "16px/24px Roboto",
                    color: "#9CA1A6",
                  }}
                >
                  {placeholder}
                </p>
              );
            }

            return selected
              .map((item) => {
                return data.find((obj) => obj.value === item)?.label;
              })
              ?.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
}
