import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ThemeButton from "@Components/Buttons";
import Toast from "@Components/BaseComponents/Toast";
import Box from "@mui/material/Box";
import { Select, Tooltip, Typography, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";
import { useFindUserSensorAlertCalendarExceptionTypeQuery } from "../../Store/Slices/userSensorAlertCalendarExceptionTypeSlice";
import { useGetUserQuery } from "../../Store/Slices/usersSlice";
import { useGetSensorAlertCalendarTypeQuery } from "../../Store/Slices/sensorAlertCalendarTypeSlice";
import {
  useAddUserSensorAlertCalendarMutation,
  useFindUserSensorAlertCalendarQuery,
  useDeleteUserSensorAlertCalendarMutation,
} from "../../Store/Slices/userSensorAlertCalendarSlice";
import {
  useAddUserSensorAlertCalendarExceptionsMutation,
  useFindUserSensorAlertCalendarExceptionsQuery,
  useDeleteUserSensorAlertCalendarExceptionsMutation,
} from "../../Store/Slices/userSensorAlertCalendarExceptionsSlice";
import { useLocation } from "react-router-dom";
import "./style.css";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import Loader from "@Components/LoadingSpinner";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { filterObjectByValue, findObjectByValue, formatDate } from "../../Utils/Tools";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroupComponent from "../../Components/BaseComponents/RadioGroup";
import RadioComponent from "../../Components/BaseComponents/Radio";
import DateTimePickerComponent from "../BaseComponents/DateTimePicker";

function AlertView() {
  const date = new Date();
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const location = useLocation();
  const [fromDate, setFromDate] = useState(date);
  const [toDate, setToDate] = useState(tomorrow);
  const [exceptionTypes, setExceptionTypes] = useState([]);
  const [userAlertCalendar, setUserAlertCalendar] = useState([]);
  const [sensorAlertTypes, setSensorAlertTypes] = useState([]);
  const [userAlertCalendarExceptions, setUserAlertCalendarExceptions] = useState([]);
  const [alertTypeID, setAlertTypeID] = useState("");
  const [selectedExceptionType, setSelectedExceptionType] = useState("");
  const auth = useSelector((state) => state.auth);
  const [UserID, setUserID] = useState(location.state?.UserID || auth.UserID);
  const [dateError, setDateError] = useState(false);
  const [contactType, setContactType] = useState(0);
  const [contactDetail, setContactDetail] = useState("");
  const [errors, setErrors] = useState({});
  const [detailsCheck, setDetialsCheck] = useState(false);
  const { data: userData, isSuccess: UserSuccess } = useGetUserQuery(UserID);

  const {
    data: sensorAlertCalendarType,
    isSuccess: sensorAlertCalendarTypeSuccess,
    isLoading: sensorAlertCalendarTypeisLoading,
  } = useGetSensorAlertCalendarTypeQuery(alertTypeID);

  const {
    data: SensorAlertCalendarTypes,
    isLoading: SensorAlertCalendarTypeLoading,
    isSuccess: SensorAlertCalendarTypeSuccess,
    isError: SensorAlertCalendarTypeIsError,
    error: SensorAlertCalendarTypeError,
  } = useFindUserSensorAlertCalendarExceptionTypeQuery();

  const [userSensorAlertExceptions, setUserSensorAlertExceptions] = useState({
    UserID: UserID,
    ExceptionType: "",
    StartDay: `${fromDate.getMonth() + 1}-${fromDate.getDate()}-${fromDate.getFullYear()}`,
    EndDay: `${toDate.getMonth() + 1}-${toDate.getDate()}-${toDate.getFullYear()}`,
    StartTime: `${fromDate.getHours()}:${fromDate.getMinutes()}`,
    EndTime: `${toDate.getHours()}:${toDate.getMinutes()}`,
    Details: "",
  });

  const [userSensorAlertCalendar, setUserSensorAlertCalendar] = useState({
    UserID: UserID,
    StartDay: `${fromDate.getMonth() + 1}-${fromDate.getDate()}-${fromDate.getFullYear()}`,
    EndDay: `${toDate.getMonth() + 1}-${toDate.getDate()}-${toDate.getFullYear()}`,
    StartTime: `${fromDate.getHours()}:${fromDate.getMinutes()}`,
    EndTime: `${toDate.getHours()}:${toDate.getMinutes()}`,
  });

  const {
    data: ExceptionTypeData,
    isLoading: ExceptionTypeLoading,
    isSuccess: ExceptionTypeSuccess,
    isError: ExceptionTypeIsError,
    error: ExceptionTypeError,
  } = useFindUserSensorAlertCalendarExceptionTypeQuery();

  const {
    data: userSensorAlertCalendarData,
    isLoading: userSensorAlertCalendarLoading,
    isSuccess: userSensorAlertCalendarSuccess,
    isError: userSensorAlertCalendarIsError,
    error: userSensorAlertCalendarError,
  } = useFindUserSensorAlertCalendarQuery();

  const {
    data: userSensorAlertCalendarExceptionsData,
    isLoading: userSensorAlertCalendarExceptionsLoading,
    isSuccess: userSensorAlertCalendarExceptionsSuccess,
    isError: userSensorAlertCalendarExceptionsIsError,
    error: userSensorAlertCalendarExceptionsError,
  } = useFindUserSensorAlertCalendarExceptionsQuery();

  const [
    deleteUserSensorAlertCalendarExceptions,
    {
      isSuccess: deleteUserSensorAlertCalendarExceptionsSuccess,
      isError: deleteUserSensorAlertCalendarExceptionsIsError,
      isLoading: deleteUserSensorAlertCalendarExceptionsLoading,
      error: deleteUserSensorAlertCalendarExceptionsError,
    },
  ] = useDeleteUserSensorAlertCalendarExceptionsMutation();

  const [
    deleteUserSensorAlertCalendar,
    {
      isSuccess: deleteUserSensorAlertCalendarSuccess,
      isError: deleteUserSensorAlertCalendarIsError,
      isLoading: deleteUserSensorAlertCalendarLoading,
      error: deleteUserSensorAlertCalendarError,
    },
  ] = useDeleteUserSensorAlertCalendarMutation();

  const loadExceptionTypes = () => {
    if (ExceptionTypeSuccess) setExceptionTypes(ExceptionTypeData.success.data);
    if (ExceptionTypeIsError) console.log(ExceptionTypeError);
  };

  const loadUserAlertCalendar = () => {
    if (userSensorAlertCalendarSuccess)
      setUserAlertCalendar(
        filterObjectByValue(userSensorAlertCalendarData?.success.data, "UserID", UserID)
      );
    if (userSensorAlertCalendarIsError) console.log(userSensorAlertCalendarError);
  };
  const loadUserAlertCalendarExceptions = () => {
    if (userSensorAlertCalendarExceptionsSuccess)
      setUserAlertCalendarExceptions(
        filterObjectByValue(userSensorAlertCalendarExceptionsData?.success.data, "UserID", UserID)
      );

    if (userSensorAlertCalendarExceptionsIsError)
      console.log(userSensorAlertCalendarExceptionsError);
  };

  useEffect(() => loadExceptionTypes(), [ExceptionTypeData]);
  useEffect(() => loadUserAlertCalendar(), [userSensorAlertCalendarData]);
  useEffect(() => loadUserAlertCalendarExceptions(), [userSensorAlertCalendarExceptionsData]);

  useEffect(() => {
    if (UserSuccess) {
      setAlertTypeID(userData.success.data.SensorAlertCalendarType);
    }
  }, [userData]);

  const loadSensorAlertTypes = () => {
    if (SensorAlertCalendarTypeSuccess)
      setSensorAlertTypes(
        SensorAlertCalendarTypes.success.data.map((alertType) => {
          return {
            value: alertType.ID,
            label: alertType.Name,
          };
        })
      );
    if (SensorAlertCalendarTypeIsError) console.log(SensorAlertCalendarTypeError);
  };
  useEffect(() => {
    loadSensorAlertTypes();
  }, [SensorAlertCalendarTypes]);

  const SubmitTimeValidation = !(selectedExceptionType != "");

  const [
    addUserSensorAlertCalendar,
    { isSuccess: addUserSensorAlertSuccess, isError: addUserSensorAlertError },
  ] = useAddUserSensorAlertCalendarMutation();
  const [
    addUserSensorAlertCalendarException,
    { isSuccess: addUserSensorAlertExceptionSuccess, isError: addUserSensorAlertExceptionError },
  ] = useAddUserSensorAlertCalendarExceptionsMutation();

  const handleChangeSecond = (newValue) => {
    setDateError(false);
    setUserSensorAlertCalendar({
      ...userSensorAlertCalendar,
      StartDay: `${newValue.getMonth() + 1}-${newValue.getDate()}-${newValue.getFullYear()}`,
      StartTime: `${newValue.getHours()}:${newValue.getMinutes()}`,
    });
    setFromDate(newValue);
  };
  const handleChange = (newValue) => {
    setDateError(false);
    var varDate = new Date(newValue);
    var today = new Date();

    setUserSensorAlertCalendar({
      ...userSensorAlertCalendar,
      EndDay: `${newValue.getMonth() + 1}-${newValue.getDate()}-${newValue.getFullYear()}`,
      EndTime: `${newValue.getHours()}:${newValue.getMinutes()}`,
    });
    setToDate(newValue);
  };

  const handleChangeStartTime = (newValue) => {
    setUserSensorAlertExceptions({
      ...userSensorAlertExceptions,
      StartDay: `${newValue.getMonth() + 1}-${newValue.getDate()}-${newValue.getFullYear()}`,
      StartTime: `${newValue.getHours()}:${newValue.getMinutes()}`,
    });
    setFromDate(newValue);
  };
  const handleChangeEndTime = (newValue) => {
    setDateError(false);
    var varDate = new Date(newValue);
    var today = new Date();

    setUserSensorAlertExceptions({
      ...userSensorAlertExceptions,
      EndDay: `${newValue.getMonth() + 1}-${newValue.getDate()}-${newValue.getFullYear()}`,
      EndTime: `${newValue.getHours()}:${newValue.getMinutes()}`,
    });
    setToDate(newValue);
  };

  const handleClick = async () => {
    var StartDay = new Date(fromDate);
    var EndDay = new Date(toDate);
    console.log(StartDay, EndDay);
    if (StartDay.getTime() <= EndDay.getTime()) {
      const res = await addUserSensorAlertCalendar(userSensorAlertCalendar);
    } else {
      setDateError(true);
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if (fieldValues.contactType == 0) {
      if ("contactDetail" in fieldValues)
        temp.contactDetail =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            fieldValues.contactDetail
          )
            ? ""
            : "Email is not valid.";
    } else if (fieldValues.contactType == 1) {
      if ("contactDetail" in fieldValues)
        temp.contactDetail =
          /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g.test(
            fieldValues.contactDetail
          )
            ? ""
            : "Not a valid phone number.";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues) return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleClickSecond = async () => {
    var StartDay = new Date(fromDate);
    var EndDay = new Date(toDate);

    if (StartDay.getTime() <= EndDay.getTime()) {
      const data = { contactType: contactType, contactDetail: contactDetail };
      if (detailsCheck) {
        if (validate(data)) {
          const res = await addUserSensorAlertCalendarException(userSensorAlertExceptions);
        }
      } else {
        const res = await addUserSensorAlertCalendarException(userSensorAlertExceptions);
      }
    } else {
      setDateError(true);
    }
  };

  useEffect(() => {
    setSelectedExceptionType("");
    setDetialsCheck(false);
    setUserSensorAlertExceptions({
      UserID: UserID,
      ExceptionType: "",
      StartDay: `${fromDate.getMonth() + 1}-${fromDate.getDate()}-${fromDate.getFullYear()}`,
      EndDay: `${toDate.getMonth() + 1}-${toDate.getDate()}-${toDate.getFullYear()}`,
      StartTime: `${fromDate.getHours()}:${fromDate.getMinutes()}`,
      EndTime: `${toDate.getHours()}:${toDate.getMinutes()}`,
      Details: "",
    });
  }, [addUserSensorAlertExceptionSuccess]);
  const timeConverter = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "\tAM" : "\tPM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  const handleDeleteExceptions = async (id) => {
    const res = await deleteUserSensorAlertCalendarExceptions(id);
    console.log(res);
  };

  const handleDelete = async (id) => {
    const res = await deleteUserSensorAlertCalendar(id);
    console.log(res);
  };

  useEffect(() => {
    if (contactType == 0 && contactDetail !== "") {
      setUserSensorAlertExceptions({
        ...userSensorAlertExceptions,
        Details: `Email - ${contactDetail}`,
      });
      setDetialsCheck(true);
    }
    if (contactType == 1 && contactDetail !== "") {
      setUserSensorAlertExceptions({
        ...userSensorAlertExceptions,
        Details: `Phone - ${contactDetail}`,
      });
      setDetialsCheck(true);
    }
  }, [contactDetail]);

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: "#fff", width: "100%" }}>
      {sensorAlertCalendarTypeisLoading ? (
        <SpinnerContainer>
          <Loader loading={false} />
        </SpinnerContainer>
      ) : (
        <>
          {addUserSensorAlertSuccess && (
            <Toast
              message="Notifications added successfully"
              severity="success"
            />
          )}
          {addUserSensorAlertExceptionSuccess && (
            <Toast
              message="Notifications added successfully"
              severity="success"
            />
          )}
          {addUserSensorAlertExceptionError && (
            <Toast
              message="Calendar Record Already Exists"
              severity="error"
            />
          )}
          {addUserSensorAlertError && (
            <Toast
              message="Calendar Record Already Exists"
              severity="error"
            />
          )}

          {dateError && (
            <Toast
              message="From Date cannot be greater than To Date"
              severity="error"
            />
          )}

          {deleteUserSensorAlertCalendarExceptionsSuccess && (
            <Toast
              message="Record Deleted Successfully"
              severity="success"
            />
          )}
          {deleteUserSensorAlertCalendarSuccess && (
            <Toast
              message="Record Deleted Successfully"
              severity="success"
            />
          )}

          {sensorAlertCalendarTypeSuccess ? (
            <div style={{ width: "100%" }}>
              {sensorAlertCalendarType.success.data.CalendarName === "Custom" ? (
                <div className="sectionOne">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0em 5em",
                    }}
                  >
                    <div style={{ display: "flex", height: "fit-content" }}>
                      <DateTimePickerComponent
                        label="From Date"
                        value={fromDate}
                        onChange={handleChangeSecond}
                      />
                    </div>
                    <br />
                    <div style={{ display: "flex", height: "fit-content" }}>
                      <DateTimePickerComponent
                        label="To Date"
                        value={toDate}
                        onChange={handleChange}
                      />
                    </div>
                    <br />
                    <div>
                      <ThemeButton
                        fullWidth
                        color="primary"
                        size="small"
                        onClick={handleClick}
                      >
                        {" "}
                        Submit
                      </ThemeButton>
                    </div>
                  </div>
                  <br />
                  <div className="notificationTable">
                    <h1> Notification Calendar Details</h1>
                    <TableContainer style={{ height: "80vh" }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}> SrNo.</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> StartDate</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> EndDate</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> Action</TableCell>
                          </TableRow>
                          {userAlertCalendar.length > 0 ? (
                            userAlertCalendar.map((calendar, idx) => {
                              let startDate = `${calendar.StartDay}T${calendar.StartTime}:00`.split(
                                "T"
                              );
                              let dateAttributes = startDate[0].split("-");
                              let timeAttributes = startDate[1].split(":");
                              startDate = new Date(
                                +dateAttributes[2],
                                dateAttributes[0] - 1,
                                +dateAttributes[1],
                                timeAttributes[0],
                                timeAttributes[1],
                                timeAttributes[2]
                              );

                              let endDate = `${calendar.EndDay}T${calendar.EndTime}:00`.split("T");
                              dateAttributes = endDate[0].split("-");
                              timeAttributes = endDate[1].split(":");
                              endDate = new Date(
                                +dateAttributes[2],
                                dateAttributes[0] - 1,
                                +dateAttributes[1],
                                timeAttributes[0],
                                timeAttributes[1],
                                timeAttributes[2]
                              );

                              return (
                                <TableRow>
                                  <TableCell> {idx}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {formatDate(startDate, "MM/dd/yyyy - HH:mm:ss")}
                                  </TableCell>

                                  <TableCell>
                                    {formatDate(endDate, "MM/dd/yyyy - HH:mm:ss")}
                                  </TableCell>
                                  <Tooltip
                                    title="delete"
                                    placement="right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <TableCell onClick={() => handleDelete(calendar.ID)}>
                                      <i class="fa-solid fa-trash-can"></i>{" "}
                                    </TableCell>
                                  </Tooltip>
                                </TableRow>
                              );
                            })
                          ) : (
                            <div>No Calendar Details found for this User.</div>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : (
                <div className="sectionOne">
                  <div style={{ display: "grid", marginRight: "3em" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "1em",
                        }}
                      >
                        <div>
                          <InputLabel id="demo-simple-select-label">Exception Types</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedExceptionType}
                            displayEmpty
                            style={{ width: "100%" }}
                            label={"Pages"}
                            onChange={(event) => [
                              setUserSensorAlertExceptions({
                                ...userSensorAlertExceptions,
                                ExceptionType: event.target.value,
                              }),
                              setSelectedExceptionType(event.target.value),
                              setDateError(false),
                            ]}
                          >
                            {selectedExceptionType === "" && (
                              <MenuItem
                                value=""
                                disabled
                              >
                                Select Exception Type
                              </MenuItem>
                            )}
                            {exceptionTypes.map((type, idx) => {
                              return (
                                <MenuItem
                                  value={type.ID}
                                  key={idx}
                                >
                                  {type.Name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                        <br />

                        <DateTimePickerComponent
                          label="From Date"
                          value={fromDate}
                          onChange={handleChangeStartTime}
                          disabled={selectedExceptionType == "" ? true : false}
                        />
                        <br />
                        <DateTimePickerComponent
                          label="To Date"
                          value={toDate || ""}
                          onChange={handleChangeEndTime}
                          disabled={selectedExceptionType == "" ? true : false}
                        />
                        {selectedExceptionType === "80051a15-f63a-4f71-83de-32cd362945e7" && (
                          <div style={{ marginTop: "5px" }}>
                            <Grid
                              item
                              sm={12}
                            >
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                style={{ fontSize: "1em" }}
                              >
                                Contact Type
                              </FormLabel>
                              <RadioGroupComponent
                                row
                                value={1}
                                defaultValue={1}
                                name="row-radio-buttons-group"
                                callBack={setContactType}
                                FormControlLabels={
                                  <>
                                    <FormControlLabel
                                      value={0}
                                      control={<RadioComponent />}
                                      label="Email"
                                    />
                                    <FormControlLabel
                                      value={1}
                                      control={<RadioComponent />}
                                      label="Phone"
                                    />
                                  </>
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              sm={12}
                            >
                              <TextField
                                onChange={(event) => setContactDetail(event.target.value)}
                                name="Contact Detail"
                                label="Contact Detail"
                                value={contactDetail || ""}
                                variant="outlined"
                                fullWidth
                                size="small"
                                error={errors.contactDetail ? true : false}
                                helperText={errors.contactDetail ? errors.contactDetail : ""}
                                className={classes.root}
                              />
                            </Grid>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <ThemeButton
                        fullWidth
                        color="primary"
                        size="small"
                        disabled={SubmitTimeValidation}
                        onClick={handleClickSecond}
                      >
                        {" "}
                        Submit
                      </ThemeButton>
                    </div>
                  </div>
                  <br />

                  <div className="notificationTable">
                    <h1>Notification Calendar Details</h1>
                    <TableContainer style={{ height: "80vh" }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}> SrNo.</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> ExceptionType.</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> StartDate</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> EndDate</TableCell>

                            <TableCell style={{ fontWeight: "bold" }}> Details</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}> Action</TableCell>
                          </TableRow>
                          {userAlertCalendarExceptions.length > 0 ? (
                            userAlertCalendarExceptions.map((calendar, idx) => {
                              const type = findObjectByValue(
                                sensorAlertTypes,
                                "value",
                                calendar.ExceptionType
                              );
                              let startDate = `${calendar.StartDay}T${calendar.StartTime}:00`.split(
                                "T"
                              );
                              let dateAttributes = startDate[0].split("-");
                              let timeAttributes = startDate[1].split(":");
                              startDate = new Date(
                                +dateAttributes[2],
                                dateAttributes[0] - 1,
                                +dateAttributes[1],
                                timeAttributes[0],
                                timeAttributes[1],
                                timeAttributes[2]
                              );

                              let endDate = `${calendar.EndDay}T${calendar.EndTime}:00`.split("T");
                              dateAttributes = endDate[0].split("-");
                              timeAttributes = endDate[1].split(":");
                              endDate = new Date(
                                +dateAttributes[2],
                                dateAttributes[0] - 1,
                                +dateAttributes[1],
                                timeAttributes[0],
                                timeAttributes[1],
                                timeAttributes[2]
                              );

                              return (
                                <TableRow>
                                  <TableCell> {idx}</TableCell>
                                  <TableCell> {type?.label}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {formatDate(startDate, "MM/dd/yyyy - HH:mm:ss")}
                                  </TableCell>

                                  <TableCell>
                                    {formatDate(endDate, "MM/dd/yyyy - HH:mm:ss")}
                                  </TableCell>
                                  <TableCell> {calendar?.Details}</TableCell>
                                  <Tooltip
                                    title="delete"
                                    placement="right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <TableCell
                                      onClick={() =>
                                        handleDeleteExceptions(
                                          calendar.UserSensorAlertCalendarExceptionID
                                        )
                                      }
                                    >
                                      <i class="fa-solid fa-trash-can"></i>{" "}
                                    </TableCell>
                                  </Tooltip>
                                </TableRow>
                              );
                            })
                          ) : (
                            <div>No Calendar Details found for this User.</div>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%", margin: "1em" }}
              variant="h6"
              id="tableTitle"
              component="div"
              className="sensorCount"
            >
              Please add SensorAlertCalendarType for the user first.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export default AlertView;
