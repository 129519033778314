import React, { useEffect, useContext, useState } from "react";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip } from "recharts";
import Box from "@mui/material/Box";
import { formatDate } from "../../Utils/Tools";
import MUIDataTable from "mui-datatables";
//DRAWER CONTEXT
import { DrawerContext } from "../../Context/DrawerContext";
import { createTheme, ThemeProvider, styled, alpha } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal } from "@mui/material";
import Loader from "../LoadingSpinner";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "50%",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1),
    width: "auto",
  },
  display: "inline",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#9CA1A6",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIconx
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: 1,
    borderStyle: "solid",
    borderColor: "#9CA1A6",
    borderRadius: "4px",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

export default function Chart({ sensors, loading }) {
  const digitalSensorConfigs = ["Desired Delay time (secound)", "Desired Input State"];
  const analogSensorConfigs = [
    "Sensor Type",
    "Evaluation Formula",
    "Evaluation Formula Parameters",
    "Desired Delay time",
    "Uper limit of normal state",
    "Lower Limit of normal state",
    "Uper limit Warning of Warning state",
    "Lower Limit Warning of Warning state",
    "Sensor Collaboration SetPoints",
    "Sensor calibration offsets",
    "AFE calibration setpoints",
    "AFE calibration offsets",
  ];
  const Configs = ["time", "interval"];
  const [mappedSensors, setMappedSensors] = useState([]);
  useEffect(() => {
    setMappedSensors(
      sensors.map((sensor) => {
        const newSensor = {
          Label: sensor.SensorLabel,
          Port: sensor.PortSerial.split("-")[0],
        };
        if (sensor.data.length > 0) {
          if (sensor.data[0].CurrentState) {
            newSensor.Type = "digital";
          } else {
            newSensor.Type = "analog";
          }
          newSensor.data = [];
          const oldData = [...sensor.data];
          oldData
            .sort((s1, s2) => s1.Time - s2.Time)
            .forEach((dataPoint) => {
              const dataObject = {};
              if (dataPoint.CurrentState) {
                dataObject.Stats = dataPoint.State == 0 ? 0 : 1;
                dataObject.State = dataPoint.CurrentState;
                dataObject.Time = new Date();
                dataObject.Time.setTime(dataPoint.Time);
              } else if (dataPoint.Current) {
                dataObject.Stats = dataPoint.Current;
                dataObject.Min = dataPoint.Min;
                dataObject.Max = dataPoint.Max;
                dataObject.Time = new Date();
                dataObject.Time.setTime(dataPoint.Time);
              }
              if (dataObject.Time) {
                newSensor.data.push(dataObject);
              }
            });
        }
        newSensor.configData = JSON.parse(sensor?.ConfigData);
        return newSensor;
      })
    );
  }, [sensors]);

  const { open } = useContext(DrawerContext);
  const [searchText, setSearchText] = useState("");
  const [aggregateSearchText, setAggregateSearchText] = useState("");
  const [zebra, setZebra] = useState(true);
  const [tableLook, setTableLook] = useState("dense");

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            actions: {
              display: "flex",
              flexDirection: "row",
              flex: "initial",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            data: {
              fontWeight: "bold",
              color: "#9CA1A6",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: zebra === true ? "#eee" : null,
              },
            },
          },
        },

        MuiIconButton: {
          styleOverrides: {
            root: {
              borderRadius: "4px",
              color: "#9CA1A6",
              "&:hover": {
                color: "#00AEEF",
                backgroundColor: "#E0F5FD",
              },
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "Time",
      label: "Time",
    },
    {
      name: "Value",
      label: "Value",
    },

    {
      name: "pValue",
      label: "Value",
    },
    {
      name: "Min",
      label: "Min",
    },
    {
      name: "Max",
      label: "Max",
    },
  ];
  const aggregateColumns = [
    {
      name: "Average",
      label: "Average",
    },
    {
      name: "Minimum",
      label: "Minimum",
    },

    {
      name: "Maximum",
      label: "Maximum",
    },
    {
      name: "Standard Deviation",
      label: "Standard Deviation",
    },
    {
      name: "Mean Kinetic Temperature",
      label: "Mean Kinetic Temperature",
    },
  ];

  const options = {
    selectableRows: "none",
    fixedHeader: true,
    responsive: "simple",
    filter: false,
    search: false,
    fixedHeader: true,
    sort: true,
    tableBodyHeight: "10em",
    searchText: searchText,
    customSearchRender: () => null,
    rowsPerPage: tableLook === "dense" ? 50 : 20,
    rowsPerPageOptions: [20, 50, 100, 500],
    sortOrder: {
      name: "LocationName",
      direction: "asc",
    },
    setTableProps: () => ({
      padding: tableLook === "dense" ? "none" : "default",
      size: tableLook === "dense" ? "small" : "medium",
    }),
  };

  const buttonTheme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "10px",
          },
        },
      },
    },
  });

  function getStandardDeviation(array) {
    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
  }

  const useStyles = makeStyles({
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        paddingLeft: "5px",
      },
    },
  });

  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: "#fff", width: "100%", p: 1 }}>
      <Modal open={loading}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </div>
      </Modal>
      <div className="SubData">
        {mappedSensors.length === 0 ? (
          <p> No sensor attached</p>
        ) : (
          mappedSensors.map((sensor, idx) => {
            const configData = [],
              valueArray = [],
              data = [];
            let configMin = 0,
              configMax = 0,
              sum = 0;

            if (sensor.data != undefined && sensor.data.length > 0) {
              for (let i = 0; i < sensor.data.length; i++) {
                valueArray.push(sensor.data[i].Stats);
              }
              configMin = Math.min(...valueArray);
              configMax = Math.max(...valueArray);
              for (var number of valueArray) {
                sum += number;
              }
              const average = sum / valueArray.length;
              data.push({
                Stats: average,
                Min: configMin,
                Max: configMax,
                SD: getStandardDeviation(valueArray),
              });
            }
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <p>{sensor.Label}</p>
                  <p>Port - {sensor?.Port}</p>

                  {sensor?.configData ? (
                    sensor.Type == "digital" ? (
                      <div>
                        {Object.keys(sensor.configData.data).map((item, index) => {
                          if (typeof sensor.configData.data[item] === "object") {
                            if (item.charAt(item.length - 1) == sensor.Port)
                              return (
                                <div style={{ display: "flex" }}>
                                  {Object.keys(sensor.configData.data[item]).map((i, idx) => {
                                    return (
                                      <p
                                        style={{
                                          display: "flex",
                                          fontSize: "13px",
                                          marginRight: "5px",
                                        }}
                                      >
                                        {" "}
                                        <section
                                          style={{
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {digitalSensorConfigs[idx]}:{`\u00a0`}
                                        </section>
                                        {sensor?.configData.data[item][i]}
                                      </p>
                                    );
                                  })}
                                </div>
                              );
                          } else {
                            return (
                              <p style={{ display: "flex", fontSize: "13px" }}>
                                {" "}
                                <section
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item == "t" ? Configs[0] : Configs[1]}:{"  "}
                                  {"  "}
                                </section>
                                {item == "t"
                                  ? formatDate(
                                      new Date(sensor?.configData.data[item]),
                                      "MM/dd/yyyy - HH:mm:ss"
                                    )
                                  : sensor.configData.data[item]}
                              </p>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <div>
                        {sensor?.configData.data && (
                          <div>
                            {Object.keys(sensor?.configData.data).map((item, index) => {
                              if (typeof sensor?.configData.data[item] === "object") {
                                if (item.charAt(item.length - 1) == sensor.Port)
                                  return (
                                    <div style={{ display: "flex" }}>
                                      {Object.keys(sensor?.configData.data[item]).map((i, idx) => {
                                        return (
                                          <p
                                            style={{
                                              display: "flex",
                                              fontSize: "13px",
                                              marginRight: "5px",
                                            }}
                                          >
                                            {" "}
                                            <section
                                              style={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {analogSensorConfigs[idx]}:{`\u00a0`}
                                            </section>
                                            {sensor?.configData.data[item][i]}
                                            {"\n"}
                                          </p>
                                        );
                                      })}
                                    </div>
                                  );
                              } else {
                                return (
                                  <p
                                    style={{
                                      display: "flex",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {" "}
                                    <section
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item == "t" ? Configs[0] : Configs[1]}:{"  "}
                                    </section>
                                    {item == "t"
                                      ? formatDate(
                                          new Date(sensor?.configData.data[item]),
                                          "MM/dd/yyyy - HH:mm:ss"
                                        )
                                      : sensor?.configData.data[item]}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <div>
                    {!sensor.data ? (
                      <p> No Data Found for this sensor</p>
                    ) : sensor.Type == "digital" ? (
                      <div>
                        <p>Desired State - 0 | Non Desired State - 1</p>
                        <LineChart
                          width={400}
                          height={300}
                          data={sensor.data}
                          margin={{
                            top: 5,
                            right: 30,

                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <Line
                            dataKey="Stats"
                            dot={false}
                          />
                          <YAxis
                            dataKey="Stats"
                            ticks={[-1, 0, 1]}
                          />
                          <XAxis
                            dataKey="Time"
                            interval={"preserveStartEnd"}
                          />
                          <Tooltip />
                        </LineChart>
                      </div>
                    ) : (
                      <div>
                        <LineChart
                          width={450}
                          height={300}
                          data={sensor.data}
                          margin={{
                            top: 5,
                            right: 30,
                            bottom: 5,
                          }}
                          style={{ marginLeft: "-40px" }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <Line
                            dataKey="Stats"
                            dot={false}
                          />
                          <YAxis
                            dataKey="Stats"
                            ticks={[
                              parseInt(configMin) - parseInt(configMin) * 0.2,
                              parseInt(configMax) + parseInt(configMax) * 0.2,
                            ]}
                          />
                          <XAxis
                            dataKey="Time"
                            interval={"preserveStartEnd"}
                          />
                          <Tooltip />
                        </LineChart>
                        <div style={{ display: "flex", marginTop: "1em" }}>
                          <div style={{ width: "35%", marginRight: "10px" }}>
                            <ThemeProvider theme={getMuiTheme()}>
                              <MUIDataTable
                                classes={{ root: classes.customTable }}
                                title={
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Search>
                                        <SearchIconWrapper>
                                          <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                          variant="outlined"
                                          placeholder="Search…"
                                          inputProps={{
                                            "aria-label": "search",
                                          }}
                                          value={searchText}
                                          onChange={(e) => setSearchText(e.target.value)}
                                        />
                                      </Search>
                                    </Box>
                                  </>
                                }
                                columns={columns}
                                options={options}
                                data={sensor.data?.map((row) => {
                                  return [
                                    formatDate(row.Time, "MM/dd/yyyy - HH:mm:ss"),
                                    parseFloat(row.Stats).toFixed(4),
                                    parseFloat(row.Stats).toFixed(2),
                                    row.Min,
                                    row.Max,
                                  ];
                                })}
                              />
                            </ThemeProvider>
                          </div>
                          <div style={{ width: "35%" }}>
                            <ThemeProvider theme={getMuiTheme()}>
                              <MUIDataTable
                                classes={{ root: classes.customTable }}
                                title={
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Search>
                                        <SearchIconWrapper>
                                          <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                          variant="outlined"
                                          placeholder="Search…"
                                          inputProps={{
                                            "aria-label": "search",
                                          }}
                                          value={aggregateSearchText}
                                          onChange={(e) => setAggregateSearchText(e.target.value)}
                                        />
                                      </Search>
                                    </Box>
                                  </>
                                }
                                columns={aggregateColumns}
                                options={options}
                                data={data?.map((data) => {
                                  return [
                                    parseFloat(data.Stats).toFixed(2),
                                    data.Min.toFixed(2),
                                    data.Max.toFixed(2),
                                    data.SD.toFixed(2),
                                  ];
                                })}
                              />
                            </ThemeProvider>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Box>
  );
}
