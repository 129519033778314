import React, { useEffect } from "react";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const Breadcrumbs = (props) => {
  const auth = useSelector((state) => state.auth.Authorizations);
  let theme = useTheme();
  let location = useLocation();
  let navigate = useNavigate();
  let pathname = location.pathname;
  const pathnames = pathname?.split("/").filter((x) => x);
  let userRoutes = Object.keys(auth).reverse();

  let home = "";

  const styles = {
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.blueAction["700"],
      cursor: "pointer",
      textDecoration: "none",
    },
  };

  function routeChecker() {
    if (auth?.setup?.length > 3) {
      auth.setup.length < 3 ? (home = "/statusview") : (home = "/setup/users");
    } else if (Object.keys(auth)[0] == "tech") {
      home = "/tech";
    } else {
      home = "/statusview";
    }
    return home;
  }

  function capitalizeFirstLetter(string) {
    if (string == "alertprograms") {
      return "Alert programs";
    } else if (string === "statusview") {
      return "Status view";
    } else if (string === "audittrail") {
      return "Audit Trail";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function addSpace(string) {
    if (string.includes("%20")) {
      string = string.replace("%20", " ");
    }
    return string;
  }

  useEffect(() => {
    routeChecker();
  }, []);

  return (
    <>
      <MuiBreadcrumbs
        sx={{ ml: "30px", color: "#9CA1A6" }}
        separator={<NavigateBeforeIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {pathnames.length > 0 ? (
          <Link
            color={theme.palette.blueAction["500"]}
            sx={styles}
            onClick={() => navigate("/statusview")}
            marginLeft={2}
          >
            Home
          </Link>
        ) : (
          <Typography> Home </Typography>
        )}
        {pathnames.map((name, index) => {
          const isLast = index === pathnames.length - 1;
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          return isLast ? (
            <Typography
              key={name}
              sx={{ color: "#9CA1A6" }}
            >
              {capitalizeFirstLetter(addSpace(name))}
            </Typography>
          ) : (
            <Link
              key={name}
              onClick={() => navigate(routeTo)}
              color={theme.palette.blueAction["500"]}
              sx={styles}
            >
              {capitalizeFirstLetter(name)}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </>
  );
};

export default Breadcrumbs;
