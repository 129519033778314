import { findObjectByValue } from "../../../Utils/Tools";

const DEFAULT_ANALOG_LIMITS = {
  LimitsJSON: {
    LowLimitWarning: null,
    HighLimitWarning: null,
    LowDelayWarning: 0.0,
    HighDelayWarning: 0.0,
    LowLimitAlarm: null,
    HighLimitAlarm: null,
    LowDelayAlarm: 0.0,
    HighDelayAlarm: 0.0,
  },
};

const DEFAULT_OTHER_LIMITS = {
  LimitsJSON: {
    InputState: "",
    Delay: 0.0,
  },
};

const getAnalogSensorLimits = (tempLimits) => ({
  LimitsJSON: {
    LowLimitWarning: tempLimits.llw,
    HighLimitWarning: tempLimits.ulw,
    LowDelayWarning: tempLimits.ldw,
    HighDelayWarning: tempLimits.hdw,
    LowLimitAlarm: tempLimits.lln,
    HighLimitAlarm: tempLimits.uln,
    LowDelayAlarm: tempLimits.lda,
    HighDelayAlarm: tempLimits.hda,
  },
});

const getOtherSensorLimits = (tempLimits) => ({
  LimitsJSON: {
    InputState: tempLimits.s,
    Delay: tempLimits.d,
  },
});

const createSensorConfig = (sensor, selectedSensorLimitTemplate) => {
  let temp_config = {};
  let sensorConfig = {};
  if (sensor.SensorTypeLabel === "Analog") {
    sensorConfig = {
      t: "",
      f: "",
      fp: [],
      hdw: selectedSensorLimitTemplate.LimitsJSON.HighDelayWarning,
      hda: selectedSensorLimitTemplate.LimitsJSON.HighDelayAlarm,
      uln: selectedSensorLimitTemplate.LimitsJSON.HighLimitAlarm,
      lln: selectedSensorLimitTemplate.LimitsJSON.LowLimitAlarm,
      ulw: selectedSensorLimitTemplate.LimitsJSON.HighLimitWarning,
      llw: selectedSensorLimitTemplate.LimitsJSON.LowLimitWarning,
      ldw: selectedSensorLimitTemplate.LimitsJSON.LowDelayWarning,
      lda: selectedSensorLimitTemplate.LimitsJSON.LowDelayAlarm,
      scs: [],
      sca: [],
      pcs: [],
      pca: [],
    };

    temp_config[`a${sensor.Port}`] = {
      ...sensorConfig,
      t: sensor.SensorTypeID,
    };
  } else {
    sensorConfig = {
      t: "",
      f: "",
      fp: [],
      d: selectedSensorLimitTemplate.LimitsJSON.Delay,
      s: selectedSensorLimitTemplate.LimitsJSON.InputState,
    };
    temp_config[`d${sensor.Port}`] = {
      ...sensorConfig,
    };
  }
  return { temp_config, sensorConfig };
};

const handleSensorSaveOrEdit = async (
  sensor,
  sensors,
  selectedSensorLimitTemplate,
  mutation,
  configMutation,
  postExecution,
  edit,
  substationData,
  reason,
  tempEquipmentID
) => {
  let date = new Date();

  const { temp_config, sensorConfig } = createSensorConfig(sensor, selectedSensorLimitTemplate);
  let sensorConfigs = {
    SerialNumber: sensor.SubstationSerial,
    data: {
      ...substationData?.data,
      ...temp_config,
      t: date,
    },
  };

  sensor.SchemaJSON = JSON.stringify(sensorConfig);
  let SensorID = sensor.SensorID;
  let new_sensor = { ...sensor };
  delete new_sensor["SensorID"];
  delete new_sensor["createdAt"];
  delete new_sensor["updatedAt"];

  tempEquipmentID
    ? (new_sensor.EquipmentID = tempEquipmentID)
    : (new_sensor.EquipmentID = sensor.EquipmentID);
  const data = edit
    ? {
        sensorData: new_sensor,
        configs: sensorConfigs,
        reason: reason,
      }
    : {
        sensor: new_sensor,
        reason: reason,
      };

  if (!edit && !findObjectByValue(sensors, "SensorLabel", sensor.SensorLabel)) {
    let result = await configMutation(sensorConfigs);
  }

  const mutationData = edit ? { id: SensorID, data } : data;
  console.log('is edit', edit)
  try {
    const res = await mutation(mutationData);
    if (!res.error && edit)
      await configMutation({ id: sensor.SubstationSerial, sensorConfig: sensorConfigs });
    postExecution();
  } catch (error) {
    console.error("Error while mutating sensor data: ", error);
  }
};

const handleLimitChange = (
  e,
  Sensor,
  edit,
  sensor,
  sensorLimitTemplates,
  setSelectedSensorLimitTemplate
) => {
  if (e !== "111a11b1-a111-4111-aa11-1eb1dbd1a11a") {
    const temp = findObjectByValue(sensorLimitTemplates, "value", e);
    setSelectedSensorLimitTemplate({
      ...temp,
      LimitsJSON: JSON.parse(temp.LimitsJSON),
    });
    return;
  }

  if (!edit) {
    const template =
      sensor.SensorTypeLabel === "Analog" ? DEFAULT_ANALOG_LIMITS : DEFAULT_OTHER_LIMITS;
    setSelectedSensorLimitTemplate(template);
  } else {
    const tempLimits = JSON.parse(Sensor?.SchemaJSON || sensor.SchemaJSON);
    const template =
      Sensor?.SensorTypeLabel === "Analog"
        ? getAnalogSensorLimits(tempLimits)
        : getOtherSensorLimits(tempLimits);
    setSelectedSensorLimitTemplate(template);
  }
};

function useSensorEdit(
  sensorToggleDrawer,
  setSensorEdit,
  handleLimitChange,
  sensor,
  sensorLimitTemplates,
  setSelectedSensorLimitTemplate,
  setTempSensor,
  setSensor
) {
  const handleSensorEdit = (Sensor) => {
    sensorToggleDrawer(true);
    setSensorEdit(true);
    const EquipmentID =
      Sensor?.EquipmentSensor?.EquipmentID || Sensor?.equipmentSensors[0]?.EquipmentID;
    const [port, ...extractedSerial] = Sensor.PortSerial.split("-");
    const SubstationSerial = extractedSerial.join("-");
    handleLimitChange(
      Sensor.SensorLimitTemplateID,
      Sensor,
      true,
      sensor,
      sensorLimitTemplates,
      setSelectedSensorLimitTemplate
    );
    setTempSensor({
      ...Sensor,
      EquipmentID: EquipmentID || "",
      Port: port,
      SubstationSerial: SubstationSerial || "",
    });
    setSensor({
      ...Sensor,
      EquipmentID: EquipmentID || "",
      Port: port,
      SubstationSerial: SubstationSerial || "",
    });
  };

  return handleSensorEdit;
}

export { createSensorConfig, handleSensorSaveOrEdit, handleLimitChange, useSensorEdit };
