import React, { useContext, useEffect, useState } from "react";
import { Grid, FormControl, Box } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import { DrawerContext } from "../../Context/DrawerContext";
import { useEditUserMutation } from "../../Store/Slices/usersSlice";
import ThemeButton from "@Components/Buttons";
import { useUploadImageMutation } from "../../Store/Slices/imageSlice";
import { useDispatch } from "react-redux";
import { UTCTimeZones } from "../../Utils/constants";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from "../../Store/Slices/authSlice";
import CustomAvatar from "../Avatar";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxComponent from "../BaseComponents/Checkbox";
import TextFieldComponent from "../BaseComponents/TextField";
import PhoneInputField from "../BaseComponents/PhoneInputField";
import DropDownComponent from "../BaseComponents/DropDown";
import { matchIsValidTel } from "mui-tel-input";

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const Profile = (props) => {
  const { user, setUser, handleChange, onChangePhoneInput } = props;
  const navigate = useNavigate();

  const [uploadImage, { isSuccess: imageUploadSuccess }] = useUploadImageMutation();

  const [editUser, { isSuccess: editUserSuccess }] = useEditUserMutation();
  const { open } = useContext(DrawerContext);
  const tableWidth = open ? "calc(100vw - 260px)" : "calc(100vw - 80px)";
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [imageUpload, setImageUpload] = useState("");

  const onSelectFile = async (event) => {
    setLoading(true);
    let formData = new FormData();
    console.log(event, "File");
    formData.append("myFile", event, event.name);
    console.log(formData, "FormData");
    var res = await uploadImage(formData);
    setImageUpload(res.data.success.data.fileId);
    console.log("🚀 ~ file: index.js ~ line 105 ~ onSelectFile ~ res", res);
  };

  useEffect(() => {
    if (imageUpload !== "") {
      const data = { ProfileImage: imageUpload };
      let res = editUser({ id: user.UserID, data });
      dispatch(setCurrentUser(data));
      setLoading(false);
    }
  }, [imageUpload]);

  const removeProfilePicture = () => {
    const data = { ProfileImage: "" };
    let res = editUser({ id: user.UserID, data });
    dispatch(setCurrentUser(data));
  };

  let FirstName_LastName = `${user.FirstName} ${user.LastName}`;

  // we need to concatenate the country code "+" symbol for validation logic to be ran correctly
  const phoneValueWithSymbol = !user?.SNSPhone.includes("+")
    ? `+${user?.SNSPhone}`
    : user?.SNSPhone;

  const isInvalidPhoneNumber = !matchIsValidTel(phoneValueWithSymbol);

  const isPhoneError = !user?.SNSPhone || isInvalidPhoneNumber;

  const getPhoneErrorHelperText = () => {
    if (!user?.SNSPhone) {
      return "Phone number cannot be empty";
    }

    if (isInvalidPhoneNumber) {
      return "Invalid international phone number";
    }
  };

  const useStyles = makeStyles({
    userItemPadding: {
      paddingTop: "16px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CustomAvatar
          sx={{
            height: "88px",
            width: "88px",
            bgcolor: "#7151A1",
            fontSize: "40px",
          }}
          name={FirstName_LastName}
          src={
            user.ProfileImage
              ? `${process.env.REACT_APP_URL}/asset/download/${user.ProfileImage}`
              : null
          }
        />
        <div>
          <FormControl
            sx={{ p: 2, marginY: "10px" }}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="update-profile-input"
              type="file"
              onChange={(event) => onSelectFile(event.target.files[0])}
            />
            <label htmlFor="update-profile-input">
              <ThemeButton
                height="30px"
                color="primary"
                component="span"
              >
                {user.ProfileImage ? "CHANGE" : "UPLOAD"}
              </ThemeButton>
            </label>
          </FormControl>
        </div>
        {user.ProfileImage && (
          <OutlinedButton
            height="30px"
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => removeProfilePicture()}
            width="80px"
          >
            REMOVE
          </OutlinedButton>
        )}
      </div>
      <FormControl margin="none">
        <Grid
          container
          spacing={1}
        >
          <TextFieldComponent
            padding={true}
            fullWidth={true}
            Label=" First name"
            placeholder="Enter first name"
            onChange={handleChange}
            value={user.FirstName || ""}
            name="FirstName"
            error={user.FirstName == "" ? true : false}
          />

          <TextFieldComponent
            Label="Last name"
            padding={true}
            fullWidth={true}
            placeholder="Enter last name"
            onChange={handleChange}
            value={user.LastName || ""}
            name="LastName"
            error={user.LastName == "" ? true : false}
            variant="outlined"
          />
          <TextFieldComponent
            Label="Email"
            padding={true}
            fullWidth={true}
            placeholder="email@domain.com"
            onChange={handleChange}
            value={user.SNSEMail || ""}
            error={user.SNSEMail == "" ? true : false}
            name="SNSEMail"
            variant="outlined"
            disabled
          />
          <PhoneInputField
            placeholder="+1 555 000 0000"
            onChange={onChangePhoneInput}
            value={phoneValueWithSymbol}
            error={isPhoneError}
            helperText={getPhoneErrorHelperText()}
            name="SNSPhone"
            variant="outlined"
            padding
            fullWidth
            label="Phone"
          />
          <Grid
            className={classes.userItemPadding}
            item
            xs={12}
          >
            <Label
              sx={{
                color: "#9CA1A6",
              }}
            >
              Communication preference
            </Label>
            <Box
              sx={{
                paddingTop: "10px",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                {" "}
                <CheckBoxComponent
                  checked={user.PreferEmail}
                  onChange={() => setUser({ ...user, PreferEmail: !user.PreferEmail })}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <Label
                  sx={{
                    color: "#222934",
                    font: "normal normal normal 16px/23px Roboto",
                    marginLeft: "5px",
                  }}
                >
                  Email{" "}
                </Label>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckBoxComponent
                  checked={user.PreferSMS}
                  onChange={() => setUser({ ...user, PreferSMS: !user.PreferSMS })}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <Label
                  sx={{
                    color: "#222934",
                    font: "normal normal normal 16px/23px Roboto",
                    marginLeft: "5px",
                  }}
                >
                  SMS{" "}
                </Label>
              </Box>
            </Box>
          </Grid>

          <DropDownComponent
            padding={true}
            placeholder="Select a timezone"
            label="Timezone"
            options={UTCTimeZones}
            handleChange={(e) => setUser({ ...user, GMTAdjustment: e.value })}
            value={UTCTimeZones.find((el) => el.value === user.GMTAdjustment) || ""}
          />
        </Grid>
      </FormControl>
    </>
  );
};

export default Profile;
