import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedButton from "@Components/Buttons/OutlinedButton";
import ThemeButton from "@Components/Buttons";
import Toast from "@Components/BaseComponents/Toast";

import DrawerComponent from "../BaseComponents/Drawer";
import TextFieldComponent from "../BaseComponents/TextField";
import DropDownComponent from "../BaseComponents/DropDown";
import { filterObjectByValue, findObjectByValue } from "../../Utils/Tools";
import {
  useEditSensorLimitTemplateMutation,
  useAddSensorLimitTemplateMutation,
} from "../../Store/Slices/sensorLimitTemplateSlice";
import CreatableSelectComponent from "../BaseComponents/CreatableSelect";
import InputAdornment from "@mui/material/InputAdornment";
import ResourceDrawerComponent from "../BaseComponents/Drawer/ResourceDrawer";

export default function LimitTemplatesDrawer({
  edit,
  sensorEdit,
  setSensorEdit,
  sensorTypes,
  sensorLimitTemplates,
  drawer,
  toggleDrawer,
  sensorDeleteDrawer,
  customToggleSensorDeleteDrawer,
  handleDelete,
  sensors,
}) {
  const theme = useTheme();
  const [selectedSensorType, setSelectedSensorType] = useState({});
  const [confirmationDrawer, setConfirmationDrawer] = useState("");
  const [discardDrawer, setDiscardDrawer] = useState(false);
  const [addGroup, setAddGroup] = useState("");
  const [reason, setReason] = useState("");

  const [limitUpdated, setLimitUpdated] = useState(false);
  const [selectedSensorLimitTemplate, setSelectedSensorLimitTemplate] = useState("");
  const [isEditLimitTemplate, setIsEditLimitTemplate] = useState(true);

  const [sensorLimitTemplate, setSensorLimitTemplate] = useState({});
  const clearLimitTemplate = () => {
    setSensorLimitTemplate({});
  };

  const [analogSensorLimitTemplate, setAnalogSensorLimitTemplate] = useState({
    LowLimitWarning: 0.0,
    HighLimitWarning: 0.0,
    LowDelayWarning: 0.0,
    HighDelayWarning: 0.0,
    LowLimitAlarm: 0.0,
    HighLimitAlarm: 0.0,
    LowDelayAlarm: 0.0,
    HighDelayAlarm: 0.0,
  });
  const clearAnalogLimitTemplate = () => {
    setAnalogSensorLimitTemplate({
      LowLimitWarning: 0.0,
      HighLimitWarning: 0.0,
      LowDelayWarning: 0.0,
      HighDelayWarning: 0.0,
      LowLimitAlarm: 0.0,
      HighLimitAlarm: 0.0,
      LowDelayAlarm: 0.0,
      HighDelayAlarm: 0.0,
    });
  };

  const buttonValidation =
    !Object.keys(selectedSensorType).length == 0 && !selectedSensorLimitTemplate == "";

  const [digitalSensorLimitTemplate, setDigitalSensorLimitTemplate] = useState({
    InputState: "",
    Delay: 0.0,
  });
  const clearDigitalSensorLimitTemplate = () => {
    setDigitalSensorLimitTemplate({
      InputState: "",
      Delay: 0.0,
    });
  };

  const [tempSensorLimitTemplate, setTempSensorLimitTemplate] = useState({});

  const [editSensorLimitTemplate, { isSuccess: editSensorLimitTemplateSuccess }] =
    useEditSensorLimitTemplateMutation();

  const [
    addSensorLimitTemplate,
    {
      isSuccess: addSensorLimitTemplateSuccess,
      isError: addSensorLimitTemplateIsError,
      isLoading: addSensorLimitTemplateLoading,
      error: addSensorLimitTemplateError,
    },
  ] = useAddSensorLimitTemplateMutation();

  const customConfirmationDrawer = (open) => {
    setConfirmationDrawer(open);
  };

  const limitConfirmationDrawer = (open) => {
    if (limitUpdated) {
      setDiscardDrawer(true);
    } else {
      toggleDrawer(open);
    }
  };

  function order(array) {
    if (Array.isArray(array)) {
      return array.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      console.log(array);
    }
  }

  const inputStateData = [
    { value: "0", Label: "Close" },
    { value: "1", Label: "Open" },
  ];
  const handleChange = (e) => {
    setSelectedSensorLimitTemplate(e.value);
    if (selectedSensorType.sensorType == "Analog") {
      setAnalogSensorLimitTemplate(JSON.parse(e.LimitsJSON));
    } else {
      setDigitalSensorLimitTemplate(JSON.parse(e.LimitsJSON));
    }
    setSensorLimitTemplate(e);
    setTempSensorLimitTemplate(e);
  };

  const handleUpdate = (e) => {
    setLimitUpdated(true);
    let { name, value } = e.target;
    selectedSensorType.sensorType == "Analog"
      ? setAnalogSensorLimitTemplate({
          ...analogSensorLimitTemplate,
          [name]: value,
        })
      : setDigitalSensorLimitTemplate({
          ...digitalSensorLimitTemplate,
          [name]: value,
        });
  };

  const handleIncrementByIcon = (e) => {
    let { name, value } = e;
    setLimitUpdated(true);
    selectedSensorType.sensorType == "Analog"
      ? setAnalogSensorLimitTemplate({
          ...analogSensorLimitTemplate,
          [name]: ++value,
        })
      : setDigitalSensorLimitTemplate({
          ...digitalSensorLimitTemplate,
          [name]: ++value,
        });
  };

  const handleDecrementByIcon = (e) => {
    let { name, value } = e;
    setLimitUpdated(true);
    selectedSensorType.sensorType == "Analog"
      ? setAnalogSensorLimitTemplate({
          ...analogSensorLimitTemplate,
          [name]: --value,
        })
      : setDigitalSensorLimitTemplate({
          ...digitalSensorLimitTemplate,
          [name]: --value,
        });
  };

  const checkLowHighLimits = () => {
    if (selectedSensorType.sensorType === "Analog") {
      if (
        parseInt(analogSensorLimitTemplate.LowLimitWarning) >
        parseInt(analogSensorLimitTemplate.HighLimitWarning)
      )
        return true;
      if (
        parseInt(analogSensorLimitTemplate.LowLimitAlarm) >
        parseInt(analogSensorLimitTemplate.HighLimitAlarm)
      )
        return true;

      if (!(analogSensorLimitTemplate.LowLimitAlarm && analogSensorLimitTemplate.LowDelayAlarm))
        return true;
      if (!(analogSensorLimitTemplate.HighLimitAlarm && analogSensorLimitTemplate.HighDelayAlarm))
        return true;
      if (analogSensorLimitTemplate.LowLimitWarning && !analogSensorLimitTemplate.LowDelayWarning)
        return true;
      if (analogSensorLimitTemplate.HighLimitWarning && !analogSensorLimitTemplate.HighDelayWarning)
        return true;
    } else {
      if (
        !(digitalSensorLimitTemplate.InputState?.length && digitalSensorLimitTemplate.Delay?.length)
      ) {
        return true;
      }
    }

    return false;
  };

  const handleClick = async (reason) => {
    const id = sensorLimitTemplate.SensorLimitTemplateID || sensorLimitTemplate.value;
    let newSensorLimitTemplate = { ...sensorLimitTemplate };
    selectedSensorType.sensorType == "Analog"
      ? (newSensorLimitTemplate.LimitsJSON = JSON.stringify(analogSensorLimitTemplate))
      : (newSensorLimitTemplate.LimitsJSON = JSON.stringify(digitalSensorLimitTemplate));
    delete newSensorLimitTemplate["value"];
    delete newSensorLimitTemplate["label"];
    delete newSensorLimitTemplate["sensorTypeValue"];
    delete newSensorLimitTemplate["createdAt"];
    delete newSensorLimitTemplate["updatedAt"];
    let data = { ...newSensorLimitTemplate, reason: reason };
    let res = await editSensorLimitTemplate({ id, data });
    customConfirmationDrawer(false);
    toggleDrawer(false);
    setSelectedSensorType({});
    setSelectedSensorLimitTemplate("");
    clearLimitTemplate();
    setIsEditLimitTemplate(true);
    clearAnalogLimitTemplate();
    clearDigitalSensorLimitTemplate();
  };

  const handleInputChange = (inputValue, actionMeta) => {
    setAddGroup(inputValue);
  };

  const handleLimitTemplateCreate = async () => {
    let data = {
      SensorTypeID: selectedSensorType.value,
      SensorLimitTemplateName: addGroup,
      LimitsJSON:
        selectedSensorType.sensorType == "Analog"
          ? JSON.stringify({
              LowLimitWarning: 0.0,
              HighLimitWarning: 0.0,
              LowDelayWarning: 0.0,
              HighDelayWarning: 0.0,
              LowLimitAlarm: 0.0,
              HighLimitAlarm: 0.0,
              LowDelayAlarm: 0.0,
              HighDelayAlarm: 0.0,
            })
          : JSON.stringify({
              InputState: "",
              Delay: 0.0,
            }),
    };
    try {
      let res = await addSensorLimitTemplate(data);
      let { SensorLimitTemplateID } = res.data.success.data;
      handleChange(res.data.success.data);
      setIsEditLimitTemplate(false);
      setSelectedSensorLimitTemplate(SensorLimitTemplateID);
    } catch (error) {
      console.log("🚀 ~ file: index.js ~ line 60 ~ handleCreate ~ error", error);
    }
  };

  const loadTemplate = () => {
    setSensorLimitTemplate(tempSensorLimitTemplate);
  };

  const handleAllSensorsDrawer = () => {
    const sensorsWithType = filterObjectByValue(sensors, "SensorTypeID", selectedSensorType.value);
    if (sensorsWithType.length) {
      customConfirmationDrawer(true);
    } else {
      handleClick(reason);
    }
  };

  const handleDiscard = (e) => {
    setDiscardDrawer(e);
    setLimitUpdated(false);
    loadTemplate();
    toggleDrawer(false);
    setSelectedSensorType({});
    setSelectedSensorLimitTemplate("");
    clearLimitTemplate();
  };

  return (
    <>
      {editSensorLimitTemplateSuccess && (
        <Toast
          message="Sensor limit template successfully updated"
          severity="success"
          autoHideDuration={7000}
        />
      )}
      <ResourceDrawerComponent
        drawer={drawer}
        toggleDrawer={limitConfirmationDrawer}
        title={"Limit templates"}
        submitButtonTitle={"Update Template"}
        handleSubmit={(reason) => {
          handleAllSensorsDrawer();
          handleClick(reason);
        }}
        displayFooter={true}
        edit={sensorEdit}
        closeToast={() => setSelectedSensorType({})}
        buttonValidation={!buttonValidation || checkLowHighLimits()}
        deactivateButtonTitle="Update"
        loadItem={loadTemplate}
        setUpdated={setLimitUpdated}
        confirmationDrawer={setDiscardDrawer}
        discardSettingsDrawer={discardDrawer}
        handleDiscard={handleDiscard}
      >
        <Box sx={{ padding: "24px" }}>
          <Box>
            <DropDownComponent
              label="Sensor type"
              options={order(
                sensorTypes?.map((e) => {
                  return e;
                })
              )}
              handleChange={(e) => [
                setLimitUpdated(true),
                setSelectedSensorType(e),
                setSelectedSensorLimitTemplate(""),
              ]}
              placeholder="Select a sensor type"
              value={
                sensorTypes.find((el) => {
                  return el.value === selectedSensorType.value && el.value;
                }) || ""
              }
            />
            <CreatableSelectComponent
              padding={true}
              isDisabled={Object.keys(selectedSensorType).length == 0 ? true : false}
              isLoading={addSensorLimitTemplateLoading}
              placeholder="Select an existing limit template or enter a new template name"
              options={order(
                filterObjectByValue(
                  sensorLimitTemplates,
                  "sensorTypeValue",
                  selectedSensorType.value
                )?.map((e) => {
                  return e;
                })
              )}
              handleChange={(e) => [setLimitUpdated(true), handleChange(e)]}
              onInputChange={handleInputChange}
              onCreateOption={handleLimitTemplateCreate}
              value={
                sensorLimitTemplates.find((el) => el.value === selectedSensorLimitTemplate) || ""
              }
              label="Sensor limit template"
            />

            {selectedSensorLimitTemplate != "" &&
              (selectedSensorType.sensorType === "Analog" ? (
                <>
                  <Box sx={{ paddingTop: "24px" }}>
                    <p
                      style={{
                        color: "#222934",
                        fontSize: "18px",
                        lineHeight: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Warning parameters
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                      }}
                    >
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          sx={{ width: "248px" }}
                          padding={false}
                          Label="Low limit (unit)"
                          placeholder="value"
                          name="LowLimitWarning"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.LowLimitWarning || ""}
                          customError={
                            analogSensorLimitTemplate.LowLimitWarning &&
                            analogSensorLimitTemplate.HighLimitWarning
                              ? !(
                                  parseInt(analogSensorLimitTemplate.LowLimitWarning) <=
                                  parseInt(analogSensorLimitTemplate.HighLimitWarning)
                                )
                                ? "Low limit cannot exceed the high limit"
                                : undefined
                              : undefined
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "LowLimitWarning",
                                        value: analogSensorLimitTemplate.LowLimitWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "LowLimitWarning",
                                        value: analogSensorLimitTemplate.LowLimitWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={false}
                          Label="High limit (unit)"
                          placeholder="value"
                          name="HighLimitWarning"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.HighLimitWarning || ""}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "HighLimitWarning",
                                        value: analogSensorLimitTemplate.HighLimitWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "HighLimitWarning",
                                        value: analogSensorLimitTemplate.HighLimitWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={true}
                          Label="Low delay (minutes; 0 = no delay)"
                          placeholder="value"
                          name="LowDelayWarning"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.LowDelayWarning || ""}
                          customError={
                            analogSensorLimitTemplate.LowLimitWarning &&
                            !analogSensorLimitTemplate.LowDelayWarning
                              ? "Low Delay cannot be empty"
                              : undefined
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "LowDelayWarning",
                                        value: analogSensorLimitTemplate.LowDelayWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "LowDelayWarning",
                                        value: analogSensorLimitTemplate.LowDelayWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={true}
                          Label="High delay (minutes; 0 = no delay)"
                          placeholder="value"
                          name="HighDelayWarning"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.HighDelayWarning || ""}
                          customError={
                            analogSensorLimitTemplate.HighLimitWarning &&
                            !analogSensorLimitTemplate.HighDelayWarning
                              ? "High Delay cannot be empty"
                              : undefined
                          }
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "HighDelayWarning",
                                        value: analogSensorLimitTemplate.HighDelayWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "HighDelayWarning",
                                        value: analogSensorLimitTemplate.HighDelayWarning,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ paddingTop: "24px" }}>
                    <p
                      style={{
                        color: "#222934",
                        fontSize: "18px",
                        lineHeight: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Alarm parameters
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                      }}
                    >
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          sx={{ width: "248px" }}
                          padding={false}
                          Label="Low limit (unit)"
                          placeholder="value"
                          name="LowLimitAlarm"
                          onChange={handleUpdate}
                          type="number"
                          error={analogSensorLimitTemplate.LowLimitAlarm === ""}
                          customError={
                            analogSensorLimitTemplate.LowLimitAlarm !== "" &&
                            analogSensorLimitTemplate.HighLimitAlarm !== ""
                              ? !(
                                  parseInt(analogSensorLimitTemplate.LowLimitAlarm) <=
                                  parseInt(analogSensorLimitTemplate.HighLimitAlarm)
                                )
                                ? "Low limit cannot exceed the high limit"
                                : undefined
                              : undefined
                          }
                          value={analogSensorLimitTemplate.LowLimitAlarm || ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "LowLimitAlarm",
                                        value: analogSensorLimitTemplate.LowLimitAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "LowLimitAlarm",
                                        value: analogSensorLimitTemplate.LowLimitAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={false}
                          Label="High limit (unit)"
                          placeholder="value"
                          name="HighLimitAlarm"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.HighLimitAlarm || ""}
                          error={analogSensorLimitTemplate.HighLimitAlarm === ""}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "HighLimitAlarm",
                                        value: analogSensorLimitTemplate.HighLimitAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "HighLimitAlarm",
                                        value: analogSensorLimitTemplate.HighLimitAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={true}
                          Label="Low delay (minutes; 0 = no delay)"
                          placeholder="value"
                          name="LowDelayAlarm"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.LowDelayAlarm || ""}
                          type="number"
                          error={analogSensorLimitTemplate.LowDelayAlarm === ""}
                          customError={
                            !(
                              analogSensorLimitTemplate.LowLimitAlarm.length &&
                              analogSensorLimitTemplate.LowDelayAlarm?.length
                            )
                              ? "Delay field cannot remain empty"
                              : undefined
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "LowDelayAlarm",
                                        value: analogSensorLimitTemplate.LowDelayAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "LowDelayAlarm",
                                        value: analogSensorLimitTemplate.LowDelayAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box width={248}>
                        <TextFieldComponent
                          fullWidth={true}
                          padding={true}
                          Label="High delay (minutes; 0 = no delay)"
                          placeholder="value"
                          name="HighDelayAlarm"
                          onChange={handleUpdate}
                          value={analogSensorLimitTemplate.HighDelayAlarm || ""}
                          type="number"
                          error={analogSensorLimitTemplate.HighDelayAlarm === ""}
                          customError={
                            !(
                              analogSensorLimitTemplate.HighLimitAlarm.length &&
                              analogSensorLimitTemplate.HighDelayAlarm?.length
                            )
                              ? "Delay field cannot remain empty"
                              : undefined
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div style={{ display: "grid" }}>
                                  <i
                                    onClick={() =>
                                      handleIncrementByIcon({
                                        name: "HighDelayAlarm",
                                        value: analogSensorLimitTemplate.HighDelayAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-up"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                  <i
                                    onClick={() =>
                                      handleDecrementByIcon({
                                        name: "HighDelayAlarm",
                                        value: analogSensorLimitTemplate.HighDelayAlarm,
                                      })
                                    }
                                    class="fa-light fa-angle-down"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      color: "#9CA1A6",
                                      fontWeight: "light",
                                    }}
                                  ></i>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box sx={{ paddingTop: "24px" }}>
                  <p
                    style={{
                      color: "#222934",
                      fontSize: "18px",
                      lineHeight: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Alarm parameters
                  </p>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "10px",
                    }}
                  >
                    <Box width={248}>
                      <TextFieldComponent
                        fullWidth={true}
                        sx={{ width: "248px" }}
                        padding={false}
                        Label="Input state (units)"
                        placeholder="value"
                        name="InputState"
                        onChange={handleUpdate}
                        type="text"
                        value={digitalSensorLimitTemplate.InputState || ""}
                        error={digitalSensorLimitTemplate.InputState === ""}
                      />
                    </Box>
                    <Box width={248}>
                      <TextFieldComponent
                        fullWidth={true}
                        padding={false}
                        Label="Delay (minutes; 0 = no delay)"
                        placeholder="value"
                        name="Delay"
                        onChange={handleUpdate}
                        value={digitalSensorLimitTemplate.Delay || ""}
                        error={digitalSensorLimitTemplate.Delay === ""}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ display: "grid" }}>
                                <i
                                  onClick={() =>
                                    handleIncrementByIcon({
                                      name: "Delay",
                                      value: digitalSensorLimitTemplate.Delay,
                                    })
                                  }
                                  class="fa-light fa-angle-up"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    color: "#9CA1A6",
                                    fontWeight: "light",
                                  }}
                                ></i>
                                <i
                                  onClick={() =>
                                    handleDecrementByIcon({
                                      name: "Delay",
                                      value: digitalSensorLimitTemplate.Delay,
                                    })
                                  }
                                  class="fa-light fa-angle-down"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    color: "#9CA1A6",
                                    fontWeight: "light",
                                  }}
                                ></i>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </ResourceDrawerComponent>
      {isEditLimitTemplate && (
        <DrawerComponent
          drawer={confirmationDrawer}
          toggleDrawer={customConfirmationDrawer}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fa-solid fa-chevron-left"
                style={{
                  color: "#9CA1A6",
                  fontSize: "32px",
                  cursor: "pointer",
                  paddingLeft: "10px",
                  paddingRight: "18px",
                }}
                onClick={() => customConfirmationDrawer(false)}
              ></i>
              Update all sensors?
            </div>
          }
          displayFooter={false}
        >
          <Box sx={{ padding: "1.2em", position: "relative", top: "40%" }}>
            <Box
              sx={{
                width: "530px",
                textAlign: "left",
                marginBottom: "15px",
                font: "normal normal normal 20px/24px Roboto",
              }}
            >
              Are you sure you want to update all{" "}
              {filterObjectByValue(sensors, "SensorLimitTemplateID", selectedSensorLimitTemplate)
                .length > 0 &&
                filterObjectByValue(sensors, "SensorLimitTemplateID", selectedSensorLimitTemplate)
                  .length}{" "}
              sensors supported by the{" "}
              {findObjectByValue(sensorLimitTemplates, "value", selectedSensorLimitTemplate)?.label}{" "}
              template?
            </Box>
            <Box
              sx={{
                width: "530px",
                textAlign: "left",
                marginBottom: "15px",
                font: "normal normal normal 16px/24px Roboto",
                color: "#222934",
              }}
            >
              Note: This will impact reporting, and alarm parameters for these sensors.
            </Box>
            <Box sx={{ display: "flex" }}>
              <div
                style={{ display: "inline", marginRight: "25px" }}
                onClick={() => customConfirmationDrawer(false)}
              >
                <OutlinedButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  width="248px"
                >
                  Cancel
                </OutlinedButton>
              </div>

              <ThemeButton
                variant="contained"
                color="primary"
                size="small"
                width="248px"
                onClick={() => {
                  handleClick(reason);
                }}
              >
                Update{" "}
              </ThemeButton>
            </Box>
          </Box>
        </DrawerComponent>
      )}
    </>
  );
}
