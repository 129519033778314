import { apiSlice } from "../API";

export const sensorGroupUserGroupSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findSensorGroupUserGroup: builder.query({
      query: () => "/sensorGroupUserGroup",
      providesTags: ["SensorGroupUserGroup"],
    }),
    addSensorGroupUserGroup: builder.mutation({
      query(sensorGroupUserGroup) {
        console.log(
          "🚀 ~ file: sensorGroupUserGroupSlice.js ~ line 11 ~ query ~ sensorGroupUserGroup",
          sensorGroupUserGroup
        );
        return {
          url: "/sensorGroupUserGroup",
          method: "POST",
          body: sensorGroupUserGroup,
        };
      },
      invalidatesTags: ["SensorGroupUserGroup"],
    }),
    editSensorGroupUserGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `/sensorGroupUserGroup/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SensorGroupUserGroup"],
    }),
    getSensorGroupUserGroup: builder.query({
      query: (id) => ({
        url: `/sensorGroupUserGroup/${id}`,
        method: "GET",
      }),
    }),
    deleteSensorGroupUserGroup: builder.mutation({
      query: (id) => ({
        url: `/sensorGroupUserGroup/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SensSensorGroupUserGrouporType"],
    }),
  }),
});

export const {
  useFindSensorGroupUserGroupQuery,
  useAddSensorGroupUserGroupMutation,
  useEditSensorGroupUserGroupMutation,
  useGetSensorGroupUserGroupQuery,
  useDeleteSensorGroupUserGroupMutation,
} = sensorGroupUserGroupSlice;
