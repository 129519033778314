import { apiSlice } from "../API";

export const userSensorAlertCalendarExceptionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserSensorAlertCalendarExceptions: builder.query({
      query: () => "/userSensorAlertCalendarExceptions",
      providesTags: ["userSensorAlertCalendarExceptions"],
    }),
    getUserSensorAlertCalendarExceptions: builder.query({
      query: (id) => `/userSensorAlertCalendarExceptions/${id}`,
    }),
    addUserSensorAlertCalendarExceptions: builder.mutation({
      query: (userSensorAlertCalendarExceptions) => ({
        url: "/userSensorAlertCalendarExceptions",
        method: "POST",
        body: userSensorAlertCalendarExceptions,
      }),
      invalidatesTags: ["userSensorAlertCalendarExceptions"],
    }),
    editUserSensorAlertCalendarExceptions: builder.mutation({
      query: ({ id, userSensorAlertCalendarExceptions }) => ({
        url: "/userSensorAlertCalendarExceptions",
        method: "PUT",
        body: userSensorAlertCalendarExceptions,
      }),
      invalidatesTags: ["userSensorAlertCalendarExceptions"],
    }),
    deleteUserSensorAlertCalendarExceptions: builder.mutation({
      query: (id) => ({
        url: `/userSensorAlertCalendarExceptions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["userSensorAlertCalendarExceptions"],
    }),
  }),
});

export const {
  useFindUserSensorAlertCalendarExceptionsQuery,
  useGetUserSensorAlertCalendarExceptionsQuery,
  useAddUserSensorAlertCalendarExceptionsMutation,
  useEditUserSensorAlertCalendarExceptionsMutation,
  useDeleteUserSensorAlertCalendarExceptionsMutation,
} = userSensorAlertCalendarExceptionsSlice;
