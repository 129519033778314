import { apiSlice } from "../API";

export const authorizationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findAuthorizations: builder.query({
      query: () => "/authorization",
      providesTags: ["authorization", "AuthorizationGroup"],
    }),
    getAuthorizationData: builder.query({
      query: (id) => ({
        url: `/authorization/${id}`,
      }),
      invalidatesTags: ["authorization"],
    }),
    addAuthorization: builder.mutation({
      query: (authorization) => ({
        url: "/authorization",
        method: "POST",
        body: authorization,
      }),
      invalidatesTags: ["authorization"],
    }),
    deleteAuthorization: builder.mutation({
      query: ({ id }) => ({
        url: `/authorization/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["authorization"],
    }),
  }),
});

export const {
  useFindAuthorizationsQuery,
  useGetAuthorizationDataQuery,
  useAddAuthorizationMutation,
  useDeleteAuthorizationMutation,
} = authorizationSlice;
