import MenuItemComponent from "../MenuItem";

export default function ContextMenuItem(props) {
  const { handleChange, className, margin, Label, to, ID } = props;
  return (
    <MenuItemComponent
      to={to}
      handleChange={handleChange}
      ID={ID}
      Children={
        <>
          <i
            className={className}
            style={{ width: "24px", color: "#9CA1A6", margin: margin || null }}
          ></i>{" "}
          {Label}
          <div>&nbsp;&nbsp;</div>
        </>
      }
    />
  );
}
