import React, { useContext, useState } from "react";
import { Grid, FormControl, Box, Typography } from "@mui/material";
import Label from "@Components/BaseComponents/Label";
import { DrawerContext } from "../../../Context/DrawerContext";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { usePasswordValidation } from "../../../Hooks/usePasswordValidation";
import TextFieldComponent from "../../BaseComponents/TextField";

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

const Security = (props) => {
  const { password, setPassword, setSecurityCheck, setUserUpdated, setSecurityButtonValidation } =
    props;
  const navigate = useNavigate();

  const { open } = useContext(DrawerContext);
  const tableWidth = open ? "calc(100vw - 260px)" : "calc(100vw - 80px)";
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] = usePasswordValidation({
    firstPassword: password.NewPassword,
    secondPassword: password.ConfirmNewPassword,
  });

  const setCurrentPassword = (event) => {
    setPassword({ ...password, CurrentPassword: event.target.value });
    setUserUpdated(true);
  };
  const setNewPassword = (event) => {
    setPassword({
      ...password,
      NewPassword: event.target.value,
      ConfirmNewPassword: "",
    });
  };
  const setConfirmNewPassword = (event) => {
    setPassword({ ...password, ConfirmNewPassword: event.target.value });

    if (password.NewPassword === event.target.value) setSecurityCheck(true);
  };

  setSecurityButtonValidation(
    !validLength || !hasNumber || !upperCase || !lowerCase || !specialChar || !match
  );

  const useStyles = makeStyles({
    userItemPadding: {
      paddingTop: "16px !important",
    },
    root: {
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#00AEEF",
          borderWidth: "3px",
          width: "520px",
        },
      },
    },
  });
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <FormControl
        margin="none"
        fullWidth
      >
        {/* 
        // Commented because current password not requried to update the password of a user in propel auth.
        <TextFieldComponent
          Label=" Current password"
          fullWidth={true}
          placeholder="Enter your current password"
          type={showPassword ? "text" : "password"}
          onChange={setCurrentPassword}
          value={password.CurrentPassword}
          name="CurrentPassword"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  class="fa-light fa-eye"
                  style={{ cursor: "pointer" }}
                ></i>
              </InputAdornment>
            ),
          }}
        /> */}
        <Grid
          className={classes.userItemPadding}
          item
          xs={12}
        >
          <Label
            sx={{
              color: "#9CA1A6",
            }}
          >
            New password
          </Label>
          <TextFieldComponent
            fullWidth={true}
            // disabled={password.CurrentPassword == "" && true}
            placeholder="Enter your new password"
            type={showNewPassword ? "text" : "password"}
            onChange={setNewPassword}
            value={password.NewPassword}
            name="NewPassword"
            variant="outlined"
            error={password.CurrentPassword != "" && password.NewPassword == "" ? true : false}
            helperText={
              password.CurrentPassword != "" &&
              password.NewPassword == "" &&
              "New Password cannot be empty"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <i
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    class="fa-light fa-eye"
                    style={{ cursor: "pointer" }}
                  ></i>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Box
          marginY={3}
          padding={2}
          sx={{ background: "#F1F1F1", borderRadius: "4px", width: "100%" }}
        >
          <Typography variant="caption">
            <div className={validLength ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={validLength ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must contain at least 8 characters
              <br />
            </div>
            <div className={lowerCase ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={lowerCase ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must include at least one lowercase letter
              <br />
            </div>

            <div className={hasNumber ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={hasNumber ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must include at least one number
              <br />
            </div>

            <div className={upperCase ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={upperCase ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must include at least one uppercase letter
              <br />
            </div>

            <div className={specialChar ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={specialChar ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must include at least one special character
              <br />
            </div>
            <div className={match ? "valid-message" : "error-message"}>
              {" "}
              <span
                style={{
                  margin: "5px",
                }}
              >
                <li className={match ? "fa fa-check" : "fa-regular fa-xmark"}></li>
              </span>
              Must match with confirm password
              <br />
            </div>
          </Typography>
        </Box>

        <TextFieldComponent
          Label="Confirm new password"
          fullWidth={true}
          placeholder="Re-enter your new password"
          type={showConfirmNewPassword ? "text" : "password"}
          onChange={setConfirmNewPassword}
          value={password.ConfirmNewPassword}
          name="ConfirmNewPassword"
          variant="outlined"
          disabled={!validLength && !hasNumber && !upperCase && !lowerCase && !specialChar}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i
                  onClick={handleClickShowConfirmNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  class="fa-light fa-eye"
                  style={{ cursor: "pointer" }}
                ></i>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default Security;
