import React, { useEffect, useState } from "react";

//DRAWER CONTEXT
import { Box, TableCell, TableRow } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ToolTipComponent from "../BaseComponents/Tooltip";
import SearchComponent from "../BaseComponents/Search";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@mui/material/Paper";
import EditLocation from "../../Routes/Setup/Location/EditLocation";
import { filterObjectByValue } from "../../Utils/Tools";
import IconButtonComponent from "../BaseComponents/IconButton";
import PillComponent from "../BaseComponents/Pills";
import { useNavigate } from "react-router-dom";
import TableHeaderAddButton from "../BaseComponents/TableHeaderAddButton";

const classes = {
  bold: {
    fontWeight: 600,
  },
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  subLocationRow: {
    marginLeft: theme.spacing(2),
  },
}));

const LocationTable = (props) => {
  const theme = useTheme();
  const {
    toggleDrawer,
    handleEdit,
    locations,
    location,
    mappedEquipments,
    allowedEdit,
    mappedSubstations,
  } = props;

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate("/setup/maps", { state: { ID: id } });
  };

  const [searchText, setSearchText] = useState("");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useState([]);
  const [tempLocations, setTempLocations] = useState(locations);
  const [sortKey, setSortKey] = useState("");
  const [nameSortState, setNameSortState] = useState(0);
  const [updatedSortState, setUpdatedSortState] = useState(0);

  const sortLocations = (locations, flag, key) => {
    if (flag === 0) {
      return locations.sort((a, b) => a[key].localeCompare(b[key]));
    } else if (flag === 1) {
      return locations.sort((a, b) => b[key].localeCompare(a[key]));
    }
    return locations;
  };

  useEffect(() => {
    setTempLocations(sortLocations(locations, 0, "LocationName"));
    setSortKey("LocationName");
    setNameSortState(0);
    setUpdatedSortState(0);
  }, [locations]);

  useEffect(() => {
    collapseAllLocations();
    if (searchText != "") {
      setTempLocations(locations.filter(locationSearch));
    } else {
      setTempLocations(locations);
    }
  }, [searchText]);

  const locationSearch = (location) => {
    let locationMatched = false;
    console.log({ searchText, location });
    if (
      location?.LocationName.toLowerCase().includes(searchText.toLowerCase()) ||
      location?.Address?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      locationMatched = true;
    }
    if (location.childNodes.some(locationSearch)) {
      setExpanded([...expanded, location]);
      locationMatched = true;
    }
    return locationMatched;
  };

  const classes = useStyles();

  const handleRowClick = (location) => {
    if (expanded.includes(location)) {
      setExpanded(expanded.filter((loc) => loc !== location));
    } else {
      setExpanded([...expanded, location]);
    }
  };

  const renderLocations = (allLocations, level = 0) => {
    return allLocations.map((location, index) => {
      return (
        <React.Fragment key={index}>
          <TableRow
            hover
            style={{ cursor: "pointer" }}
          >
            <TableCell
              style={{
                paddingLeft: (level + 1) * 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              {location.childNodes.length > 0 && (
                <i
                  class={`fa-light ${
                    expanded.includes(location) ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                  style={{
                    color: "#9CA1A6",
                    lineHeight: "25px",
                    fontSize: "20px",
                    marginRight: "15px",
                  }}
                  onClick={() => handleRowClick(location)}
                ></i>
              )}
              <Box
                sx={{
                  marginLeft: !location.childNodes.length > 0 && "33px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    marginRight: "20px",
                    backgroundColor: "#ECEDEE",
                    height: "48px",
                    width: "48px",
                    textAlign: "center",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    class="fa-light fa-location-dot"
                    style={{
                      fontSize: "30px",
                      lineHeight: "36px",
                      color: "#9CA1A6",
                    }}
                  ></i>
                </Box>
                <p
                  style={{
                    color: "#222934",
                    fontSize: "18px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {location.LocationName}
                </p>

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  {filterObjectByValue(mappedEquipments, "LocationID", location.LocationID).length >
                    0 && (
                    <ToolTipComponent
                      title={`${
                        filterObjectByValue(mappedEquipments, "LocationID", location.LocationID)
                          .length
                      } equipment`}
                      placement="bottom-start"
                      Component={
                        <Box sx={{ position: "relative", width: "fit-content" }}>
                          <i
                            style={{
                              fontSize: "20px",
                              color: "#9CA1A6",
                            }}
                            className="fa-light fa-flux-capacitor tableRow"
                          ></i>
                          <PillComponent
                            divStyle={{
                              position: "absolute",
                              top: "-13px",
                              left: "12px",
                            }}
                            label={
                              filterObjectByValue(
                                mappedEquipments,
                                "LocationID",
                                location.LocationID
                              ).length
                            }
                            size="small"
                            style={{
                              padding: "4px !important",
                              fontSize: "10px !important",
                              height: "15px !important",
                              width: "15px !important",
                            }}
                          />
                        </Box>
                      }
                    />
                  )}
                  {filterObjectByValue(mappedSubstations, "LocationID", location.LocationID)
                    .length > 0 && (
                    <ToolTipComponent
                      title={`Substations (${
                        filterObjectByValue(mappedSubstations, "LocationID", location.LocationID)
                          .length
                      })`}
                      placement="bottom-start"
                      Component={
                        <Box
                          sx={{
                            position: "relative",
                            marginLeft: "15px",
                            width: "fit-content",
                          }}
                        >
                          <i
                            style={{
                              fontSize: "20px",
                              color: "#9CA1A6",
                            }}
                            className="fa-light fa-router tableRow"
                          ></i>
                          <PillComponent
                            divStyle={{
                              position: "absolute",
                              top: "-13px",
                              left: "15px",
                            }}
                            label={
                              filterObjectByValue(
                                mappedSubstations,
                                "LocationID",
                                location.LocationID
                              ).length
                            }
                            size="small"
                            style={{
                              padding: "4px !important",
                              fontSize: "10px !important",
                              height: "15px !important",
                              width: "15px !important",
                            }}
                          />
                        </Box>
                      }
                    />
                  )}
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {location.MapFile && (
                  <ToolTipComponent
                    title="Manage map"
                    placement="bottom-end"
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                          mr: 5,
                        }}
                      >
                        <IconButtonComponent
                          onClick={() => handleNavigate(location.LocationID)}
                          Component={<i className="fa-light fa-map-location-dot"></i>}
                        />
                      </Box>
                    }
                  />
                )}
                {allowedEdit && (
                  <ToolTipComponent
                    title="Edit location"
                    placement="bottom-end"
                    Component={
                      <Box
                        sx={{
                          width: "fit-content",
                          mr: 3,
                        }}
                      >
                        <EditLocation
                          handleEdit={handleEdit}
                          location={location}
                        />
                      </Box>
                    }
                  />
                )}
              </Box>
            </TableCell>
          </TableRow>
          {location.childNodes.length > 0 &&
            isExpanded(location) &&
            renderLocations(location.childNodes, level + 1)}
        </React.Fragment>
      );
    });
  };

  const collapseAllLocations = () => {
    setExpanded([]);
  };

  const expandAllLocations = (locations, setExpanded) => {
    locations.forEach((location) => {
      if (!expanded.includes(location)) {
        setExpanded((expanded) => [...expanded, location]);
      }
      if (location.childNodes.length > 0) {
        expandAllLocations(location.childNodes, setExpanded);
      }
    });
  };
  const sortLocationsByName = (flag) => {
    setUpdatedSortState(0);
    setSortKey("LocationName");
    setNameSortState(flag);
    setTempLocations(sortLocations(tempLocations, flag, "LocationName"));
  };

  const isExpanded = (location) => expanded.includes(location);

  return (
    <Box sx={{ mt: 6 }}>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "15px",
          borderBottom: "1px solid #e0e1e3",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              lineHeight: "28px",
              color: "#222934",
              paddingRight: "10px",
              width: "130px",
            }}
          >
            <b> {tempLocations.length} locations</b>
          </p>
          <ToolTipComponent
            title="Collapse all"
            placement="bottom-start"
            value="relax"
            Component={
              <Box>
                <IconButtonComponent
                  onClick={collapseAllLocations}
                  Component={
                    <i
                      class="fa-light fa-arrow-up-to-line"
                      style={{ color: "#9CA1A6" }}
                    ></i>
                  }
                />
              </Box>
            }
          />
          <Box sx={{ marginRight: "16px" }}>
            <ToolTipComponent
              title="Expand all"
              placement="bottom-start"
              Component={
                <Box>
                  <IconButtonComponent
                    onClick={() => expandAllLocations(tempLocations, setExpanded)}
                    Component={
                      <i
                        class="fa-light fa-arrow-down-from-line"
                        style={{ color: "#9CA1A6" }}
                      ></i>
                    }
                  />
                </Box>
              }
            />
          </Box>

          <SearchComponent
            width="320px"
            placeholder="Search locations...."
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Box>
        {allowedEdit && (
          <TableHeaderAddButton
            onClick={() => toggleDrawer(true)}
            label="Location"
            width="134px"
          />
        )}
      </Box>
      <TableContainer
        component={Paper}
        style={{ maxHeight: "75vh", overflowY: "auto" }}
      >
        <Table
          className={classes.table}
          aria-label="location table"
        >
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <TableRow>
              <TableCell>
                <div
                  style={{
                    color: "#9CA1A6",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "90px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    nameSortState === 1 && sortKey === "LocationName"
                      ? sortLocationsByName(0)
                      : sortKey !== "LocationName"
                      ? sortLocationsByName(0)
                      : sortLocationsByName(1)
                  }
                >
                  <p style={{ marginRight: "5px" }}>Location name</p>
                  <i
                    className={`fa-solid ${
                      nameSortState === 1 && sortKey === "LocationName"
                        ? "fa-sort-down"
                        : sortKey !== "LocationName"
                        ? "fa-sort"
                        : "fa-sort-up"
                    }`}
                    style={{
                      color: "#9CA1A6",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderLocations(tempLocations, 0)}</TableBody>
        </Table>
      </TableContainer>{" "}
    </Box>
  );
};

export default LocationTable;
