import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import IconButtonComponent from "../IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: "24px",
    fontWeight: "normal",
    width: "fit-content",
    whiteSpace: "nowrap",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 4px 1px 4px",
    fontSize: "11px",
    height: "16px",
    marginBottom: "5px",
  },
  statusView: {
    lineHeight: "19px",
    fontWeight: "400",
    width: "fit-content",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 10px 6px 10px",
    fontSize: `11px`,
    height: "16px",
  },
  countBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    marginLeft: "5px",
    fontSize: "11px",
    lineHeight: "24px",
    fontWeight: "500",
  },
}));
function ReportLabelComponent(props) {
  const {
    state,
    label,
    count,
    leadingIcon,
    icon,
    height,
    StatusView,
    marginRight,
    marginTop,
    marginBottom,
  } = props;

  let theme = useTheme();

  const textColors = {
    neutral: "#838588",
    primary: "#003E51",
    success: "#1E4620",
    error: "#5F2120",
    info: "#014361",
    warning: "#663C00",
  };
  const bgColors = {
    neutral: "#E8E8E7",
    primary: "#E0E8EA",
    error: "#FDE9E7",
    success: "#E7F5EA",
    info: "#E8F1FE",
    warning: "#FEF7E1",
  };

  const classes = useStyles();

  return (
    <Box
      sx={{
        color: "#081E6C",
        bgcolor: bgColors[state],
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
      className={StatusView ? classes.statusView : classes.root}
    >
      {leadingIcon && (
        <IconButtonComponent
          size="small"
          aria-label="close"
          Component={{ icon }}
        />
      )}
      {label}
      {count > 0 && (
        <Box
          sx={{
            bgcolor: bgColors[state],
            color: textColors[state],
          }}
          className={classes.countBox}
        >
          {count}
        </Box>
      )}
    </Box>
  );
}

export default ReportLabelComponent;
