import React from "react";
import Label from "@Components/BaseComponents/Label";
import { Box, Grid } from "@mui/material";
import Select, { components } from "react-select";

const selectStyles = {
  placeholder: (base) => ({
    ...base,
    color: "rgb(156, 161, 166)",
  }),
  control: (base, { data, isDisabled, isFocused, isSelected }) => ({
    height: "40px",
    ...base,
    "&:hover": {
      border: "1px solid #17455E",
      borderRadius: "4px",
    },
    boxShadow: isFocused ? "#00AEEF80 0px 0px 0px 4px" : "none",
    background: isDisabled
      ? "#F1F1F1 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    borderColor: isFocused ? "black" : "#9CA1A6",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    "&:hover": {
      backgroundColor: "#E3E9EC !important",
      color: "#17455E !important",
    },
    "&:active": {
      color: "#FFFFFF !important",
      backgroundColor: "#11364C !important",
    },
    backgroundColor: isDisabled
      ? "#F1F1F1 !important"
      : isSelected
      ? "#3A6176 !important"
      : undefined,
    color: isDisabled ? "#C0C3C7 !important" : isSelected ? "#fff !important" : undefined,
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 1000,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const DropDownComponent = (props) => {
  const {
    placeholder,
    options,
    handleChange,
    menuPlacement,
    value,
    label,
    isDisabled,
    width,
    isMulti,
  } = props;

  const DropdownIndicator = (props) => {
    if (props.isFocused)
      return (
        <components.DropdownIndicator {...props}>
          <i
            class="fa-light fa-chevron-up"
            style={{ fontSize: "16px", color: "#9CA1A6" }}
          ></i>
        </components.DropdownIndicator>
      );
    else
      return (
        <components.DropdownIndicator {...props}>
          <i
            class="fa-light fa-chevron-down"
            style={{ fontSize: "16px", color: "#9CA1A6" }}
          ></i>
        </components.DropdownIndicator>
      );
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ paddingTop: props.padding ? "24px !important" : "0px !important" }}
    >
      <Label
        sx={{
          color: "#9CA1A6",
          paddingBottom: "10px",
        }}
      >
        {label}
      </Label>
      <Box sx={{ width: width || "100%" }}>
        <Select
          isMulti={isMulti}
          components={{
            DropdownIndicator,
          }}
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={options}
          styles={selectStyles}
          onChange={handleChange}
          value={value}
          menuPlacement={menuPlacement || "bottom"}
          menuPortalTarget={document.body}
        />
      </Box>
    </Grid>
  );
};

export default DropDownComponent;
