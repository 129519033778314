import Menu from "@mui/material/Menu";

function CustomMenu(props) {
  const {
    PaperProps,
    anchorOrigin,
    anchorEl,
    id,
    open,
    onClose,
    keepMounted,
    transformOrigin,
    sx,
    disablePadding,
  } = props;
  return (
    <>
      <Menu
        PaperProps={PaperProps}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        keepMounted={keepMounted}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        MenuListProps={{
          disablePadding: disablePadding,
        }}
        sx={sx}
      >
        {props.children}
      </Menu>
    </>
  );
}
export default CustomMenu;
