import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

//COMPONENTS
import CustomersSetup from "@Routes/Setup/Customers/CustomersSetup";
import EquipmentSetup from "@Routes/Setup/Equipment/EquipmentSetup";
import SubstationSetup from "../../Routes/Setup/Substations";
import Users from "@Routes/Setup/Users/UsersSetup";
import SensorGroups from "@Routes/Setup/SensorGroupSetup";
import { createTheme } from "@mui/system";
import Map from "../../Routes/Setup/Maps/MapSetup";
import LocationSetup from "../../Routes/Setup/Location/LocationSetup";
import SensorSetup from "../../Routes/Setup/Sensor/SensorSetup";
import AlertProgramSetup from "../../Routes/Setup/AlertProgram/AlertProgramSetup";
import Roles from "../../Routes/Setup/Roles/RolesSetup";
const pageName = "setup";
//pageName/ElementName UI
const TABS = [
  {
    value: `/${pageName}/customers`,
    label: "Customers",
    component: <CustomersSetup />,
  },
  {
    value: `/${pageName}/locations`,
    label: "Locations",
    component: <LocationSetup />,
  },
  {
    value: `/${pageName}/maps`,
    label: "Maps",
    component: <Map />,
  },
  {
    value: `/${pageName}/equipment`,
    label: "Equipment",
    component: <EquipmentSetup />,
  },
  {
    value: `/${pageName}/substations`,
    label: "Substations",
    component: <SubstationSetup />,
  },
  {
    value: `/${pageName}/sensors`,
    label: "Sensors",
    component: <SensorSetup />,
  },
  {
    value: `/${pageName}/alertprograms`,
    label: "Alert Programs",
    component: <AlertProgramSetup />,
  },

  {
    value: `/${pageName}/archived`,
    label: "Archived",
    component: <EquipmentSetup />,
  },

  { value: `/${pageName}/users`, label: "Users", component: <Users /> },
  { value: `/${pageName}/roles`, label: "Roles", component: <Roles /> },

  {
    value: `/${pageName}/sensorgroups`,
    label: "Sensor Groups",
    component: <SensorGroups />,
  },
  // {
  //   value: `/${pageName}/sensoralerts`,
  //   label: "SensorAlerts",
  //   component: <SensorAlerts />,
  // },
];

//if(aut.page==tabs.value.split("/"))

function TabPanel(props) {
  const { children, value, index, val, currentPanel, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentPanel !== value}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {currentPanel === value && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function useRouteMatch(patterns) {
  const { pathname } = useLocation();
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const theme = createTheme({
  components: {
    MuiTab: {
      stylesOverrides: {
        root: {
          "&.Mui-selected": {},
        },
      },
    },
  },
});

export default function BasicTabs() {
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(TABS.map((t) => t.value));
  const [currentTab, setCurrentTab] = React.useState(routeMatch?.pattern?.path);
  const [tabs, setTabs] = useState([]);

  // const [selectedTab, setSelectedTab] = React.useState(currentTab);
  const auth = useSelector((state) => state.auth);
  const Authorizations = auth.Authorizations;

  const handleChange = (event, newValue) => {
    // setSelectedTab(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    setTabs(checkAuthorization());
  }, [auth]);

  useEffect(() => {
    if (tabs.length > 0 && !currentTab) {
      setCurrentTab(tabs[0].value);
    }
    if (routeMatch?.pattern?.path) {
      setCurrentTab(routeMatch?.pattern?.path);
    }
  }, [tabs, routeMatch]);

  const checkAuthorization = () => {
    if (typeof Authorizations !== "undefined") {
      return TABS.filter((tab) => {
        return Authorizations[pageName]?.some(
          (authorization) =>
            authorization.element.replace(/\s+/g, "") === tab.label.replace(/\s+/g, "")
        );
      });
    }
    return [];
  };

  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: "divider",
        bgcolor: "background.paper",
      }}
    >
      {currentTab && (
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="Setup"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, key) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              {...a11yProps(key)}
              sx={{
                color: "#9CA1A6",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": { bgcolor: "#E3E9EC", color: "#17455E" },
                "&:focus": {
                  border: "2px solid #00AEEF",
                  bgcolor: "#E3E9EC",
                  color: "#17455E",
                },
                "&:active": { backgroundColor: "#E3E9EC", color: "#17455E" },
                "&.Mui-selected": {
                  color: "#17455E",
                },
              }}
              disableRipple
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
}
