import React, { useState, useRef } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

//DRAWER CONTEXT
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import ColumnHeaderComponent from "../ColumnHeader";
import "./style.css";
import CheckBoxComponent from "../Checkbox";
import ExpandButton from "../IconButton/ExpandButton";
import DownloadIcon from "../../Tables/DownloadIcon";
import DownloadDropdown from "../DownloadDropdown";
import CustomFooter from "../CustomFooter";

const TableComponent = (props) => {
  const tableRef = useRef(null);
  let {
    customToolbar,
    columns,
    data,
    components,
    title,
    tableLook,
    customOptions,
    zebraStyle,
    tableBodyHeight,
    tableBodyMaxHeight,
    boxShadow,
    hideToolbar,
    searchText,
    header,
    rowheight,
    mt,
    paddingLeft,
    height,
    backgroundColor,
    cellPaddingLeft,
    cellPaddingRight,
    marginBottom,
    marginLeft,
    marginRight,
    textAlign,
    background,
    borderRadius,
    rowsPerPage,
    rowsPerPageOptions,
    zeroHeight,
    downloadable,
    getReportColumns,
    getReportData,
    pagination,
  } = props;

  const [zebra, setZebra] = useState(zebraStyle);
  const [downloadableData, setDownloadAbleData] = useState(data);
  columns.map((col) => {
    if (!col.hasOwnProperty("options")) col["options"] = {};
    if (!col.options.sort)
      col.options.sortCompare =
        (order) =>
        ({ data: userListOne }, { data: userListTwo }) => {
          return null;
        };

    col.options.customHeadRender = (columnMeta, handleToggleColumn, sortOrder) => {
      if (columnMeta.name != "") {
        return (
          <ColumnHeaderComponent
            columnMeta={columnMeta}
            columnWidth={col.options.width}
            key={columnMeta.index}
            handleToggleColumn={handleToggleColumn}
            sortOrder={sortOrder}
            sort={col.options.sort}
            customPaddingLeft={col.options.paddingLeft}
            customStyles={{
              paddingLeft:
                columnMeta.index === 0
                  ? paddingLeft
                    ? paddingLeft
                    : "24px"
                  : col.options.paddingLeft
                  ? col.options.paddingLeft
                  : "0px",
              backgroundColor: backgroundColor ? backgroundColor : "rgb(255, 255, 255)",
              whiteSpace: customOptions.whiteSpace ? customOptions.whiteSpace : "nowrap",
            }}
          />
        );
      } else {
        return (
          <th
            className="columnHeader"
            style={{
              backgroundColor: backgroundColor ? backgroundColor : "rgb(255, 255, 255)",
            }}
          ></th>
        );
      }
    };
  });

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: "100px !important",
          },
        },
      },
      components: {
        MUIDataTableToolbar: {
          styleOverrides: {
            actions: {
              display: "flex",
              flexDirection: "row",
              flex: "initial",
            },
          },
        },

        MUIDataTableHeadCell: {
          styleOverrides: {
            data: {
              fontWeight: "bold",
              color: "#9CA1A6",
              boxShadow: "none",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              paddingLeft: "0px",
              "&:first-child": {
                textAlign: textAlign && "right",
                paddingLeft: cellPaddingLeft ? cellPaddingLeft : "0px",
              },
              "&:last-child": {
                paddingRight: cellPaddingRight ? cellPaddingRight : "none",
              },
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              paddingLeft: "24px",
              height: tableLook === "default" ? rowheight : tableLook === "dense" ? "38px" : "64px",
              "&:nth-of-type(even)": {
                backgroundColor: zebra === true ? "#F7F8F8" : null,
              },
            },
          },
        },

        MuiIconButton: {
          styleOverrides: {
            root: {
              borderRadius: borderRadius ? borderRadius : "4px",
              color: "#9CA1A6",
              "&:hover": {
                color: "#00AEEF",
                backgroundColor: "#E0F5FD",
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: "#9CA1A6 !important",
              "&:hover": {
                color: "#17455E !important",
                backgroundColor: "#E0F5FD !important",
              },
              "&.Mui-checked": {
                color: "#17455E !important",
              },
              "&.Mui-checked:hover": {
                color: "#11364C !important",
              },
            },
          },
        },
      },
    });

  const useStyles = makeStyles({
    customTable: {
      "& .MuiPaper-root": {
        boxShadow: "none",
      },
      "& .MuiToolbar-root": {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e0e1e3",
      },
    },
    withOutToolbarTable: {
      "& .MuiToolbar-root": {
        display: "none",
      },
      "& .MuiPaper-root": {
        height: `${height} !important`,
      },
    },
    zeroHeightToolbar: {
      "& .MuiPaper-root": {
        boxShadow: "none",
      },
      "& .MuiToolbar-root": {
        minHeight: "0px !important",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e0e1e3",
      },
    },
  });

  const classes = useStyles();

  const getCsvStringFromArrayOfStrings = (columns, data) => {
    const csvHeader = columns.map((column) => `"${column}"`).join();
    const csvBody = data
      .map((row) => row.map((cell) => (cell ? `"${cell}"` : '""')).join())
      .join("\n");
    return `${csvHeader}\n${csvBody}`;
  };

  const saveCsvStringAsFile = (csvString, fileName) => {
    const url = window.URL.createObjectURL(new Blob([csvString]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName?.replace(" ", "")}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const getPaginatedData = () => {
    const reportData = getReportData();

    if (downloadable) {
      const { page, rowsPerPage } = tableRef.current.state;
      const startIdx = page * rowsPerPage;
      const endIdx = startIdx + rowsPerPage;

      return reportData.slice(startIdx, endIdx);
    }

    return reportData;
  };

  const handleDownloadCSV = () => {
    const csvString = getCsvStringFromArrayOfStrings(getReportColumns(), getPaginatedData());
    saveCsvStringAsFile(csvString, customOptions.downloadAbleFileName);
  };

  const handlePrintPDF = () => {
    const doc = new jsPDF();
    doc.text(customOptions.downloadAbleFileName, 90, 10); // params (text, x, y)

    autoTable(doc, {
      styles: {
        cellPadding: "auto",
        fontSize: customOptions.pdfFontSize || 7,
        lineColor: 10,
        tableWidth: 120,
        minCellWidth: 15,
        overflow: "linebreak",
        cellPadding: 1,
      },
      startX: 0,
      showHead: "firstPage",
      margin: { left: 1, right: 1 },
      tableWidth: "auto",
      head: [getReportColumns()],
      body: getPaginatedData(),
    });
    doc.save(customOptions.downloadAbleFileName);
  };

  const options = {
    ...customOptions,
    print: false,
    rowsPerPage: rowsPerPage || 100,
    rowsPerPageOptions: rowsPerPageOptions || [100, 150, 250],
    viewColumns: false,
    fixedHeader: true,
    overflow: "scroll",
    selectToolbarPlacement: "none",
    filter: false,
    search: false,
    sort: true,
    searchText: searchText,
    selectableRowsHeader: true,
    tableBodyHeight: { tableBodyHeight },
    tableBodyMaxHeight: tableBodyMaxHeight ? tableBodyMaxHeight : "calc(100vh - 220px)",
    customSearchRender: () => null,
    setTableProps: () => ({
      padding: tableLook === "dense" ? "none" : "default",
      size: tableLook === "dense" ? "small" : "medium",
    }),
    customToolbar: () => (
      <>
        {downloadable && (
          <DownloadDropdown
            handleDownloadCSV={handleDownloadCSV}
            handlePrintPDF={handlePrintPDF}
          />
        )}
        {customToolbar}
      </>
    ),
    customFooter: (
      count,
      page,
      rowsPerPage = rowsPerPage,
      changeRowsPerPage,
      changePage
      // rowsPerPageOptions
    ) => {
      return (
        pagination && (
          <CustomFooter
            changePage={changePage}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage || 250}
            changeRowsPerPage={changeRowsPerPage}
            rowsPerPageOptions={
              rowsPerPageOptions || [
                { label: "1000", value: 0 },
                { label: "500", value: 1 },
                { label: "250", value: 2 },
              ]
            }
          />
        )
      );
    },
  };

  let styleClass = zeroHeight
    ? classes.zeroHeightToolbar
    : !hideToolbar
    ? classes.customTable
    : classes.withOutToolbarTable;

  const customComponents = {
    Checkbox: CheckBoxComponent,
    ExpandButton: function (props) {
      return <ExpandButton {...props} />;
    },
    icons: {
      DownloadIcon: DownloadIcon,
    },
  };
  return (
    <Box
      sx={{
        mt: mt ? mt : 6.5,
        background: background && background,
        marginBottom: marginBottom ? marginBottom : "8px",
        height: height ? height : "84vh",
        boxShadow: boxShadow ? boxShadow : "0 3px 6px rgba(0, 0, 0, 0.16)",
        marginLeft: marginLeft ? marginLeft : 0,
        marginRight: marginRight ? marginRight : 0,
        width: "100%",
        borderRadius: borderRadius && borderRadius,
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          classes={{ root: styleClass }}
          title={title}
          columns={columns}
          options={options}
          components={{ ...components, ...customComponents }}
          data={data}
          ref={tableRef}
        />
      </ThemeProvider>
    </Box>
  );
};

export default TableComponent;
