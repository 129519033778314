import { apiSlice } from "../API";

export const mappedEquipmentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findMappedEquipments: builder.query({
      query: () => "/mappedEquipment",
      providesTags: ["MappedEquipment"],
    }),
    getMappedEquipment: builder.query({
      query: (id) => `/mappedEquipment/${id}`,
      providesTags: ["MappedEquipment"],
    }),
    addMappedEquipment: builder.mutation({
      query: (MappedEquipment) => ({
        url: "/mappedEquipment",
        method: "POST",
        body: MappedEquipment,
      }),
      invalidatesTags: ["MappedEquipment"],
    }),
    editMappedEquipment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedEquipment/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["MappedEquipment"],
    }),
    editLocationMappedEquipment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedEquipment/updateMappedEquipments/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["MappedEquipment", "MappedSubstation"],
    }),
    deleteMappedEquipment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/mappedEquipment/${id}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["MappedEquipment"],
    }),
  }),
});

export const {
  useFindMappedEquipmentsQuery,
  useGetMappedEquipmentQuery,
  useAddMappedEquipmentMutation,
  useEditMappedEquipmentMutation,
  useEditLocationMappedEquipmentMutation,
  useDeleteMappedEquipmentMutation,
} = mappedEquipmentSlice;
