import React, { Fragment, useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import { primary, blueAction, lightGrey } from "@Styles/theme/colors.js";
import { styled } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { DrawerContext } from "@Context/DrawerContext";
import { createTheme, ThemeProvider } from "@mui/material";

import { useLocation } from "react-router-dom";
import { green } from "@mui/material/colors";

const NavButton = (props) => {
  const { open, setOpen } = useContext(DrawerContext);
  const { route } = props;
  const BoxMargin = open ? 3 : 0;
  const opacity = open ? 1 : 0; //hides text
  const display = open ? "auto" : "none"; //also used for text
  const location = useLocation();
  let activeRoute = route?.path === location.pathname;
  // const StyledBox = styled(Box)(({ theme }) => ({
  //     minWidth: 0,
  //     mr: BoxMargin,
  //     justifyContent: 'center',
  // }))

  const NavButtonStyled = styled(ListItemButton)(({ theme }) => ({
    minHeight: 50,
    minWidth: 48,
    color: primary["100"],
    justifyContent: open ? "initial" : "center",
    borderRadius: "4px",
    zIndex: theme.zIndex.drawer + 3,
    "&:hover": {
      color: lightGrey["50"],
      backgroundColor: primary["600"],
      boxShadow: "none",
      opacity: 1,
      borderRadius: "4px",
    },
    "&:active": {
      backgroundColor: blueAction["400"],
      color: lightGrey["50"],
      borderStyle: "none",
      borderRadius: "4px",
    },
    "&:focus": {
      color: lightGrey["50"],
      backgroundColor: primary["600"],
      //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      borderColor: "#00AEEF80",
      borderStyle: "solid",
      borderWidth: "3px",
      borderOpacity: 0.5,
    },
  }));

  return (
    <Fragment>
      {route === undefined ? (
        <ThemeProvider
          theme={createTheme({
            palette: { primary: { main: "#d1005e" } },
            components: {
              MuiListItemButton: {
                styleOverrides: {
                  root: {
                    "&.Mui-selected": {
                      backgroundColor: primary["400"],
                      color: "#000",
                      borderStyle: "none",
                      borderRadius: "4px",
                      "&.Mui-focusVisible": {
                        backgroundColor: primary["400"],
                        color: "#000",
                        borderStyle: "none",
                        borderRadius: "4px",
                      },
                      "&:hover": {
                        backgroundColor: primary["600"],
                        color: "#000",
                        borderStyle: "none",
                        borderRadius: "4px",
                      },
                    },
                  },
                },
              },
            },
          })}
        >
          <NavButtonStyled
            onClick={() => setOpen(!open)}
            disableTouchRipple={true}
            focusRipple={true}
            disableRipple
          >
            <Box
              sx={{
                minWidth: 0,
                mr: open ? 3 : 0,
                justifyContent: "center",
              }}
            >
              {open ? (
                <i className="fa-light fa-arrow-left-from-line fa-xl"></i>
              ) : (
                <i className="fa-light fa-arrow-right-from-line fa-xl"></i>
              )}
            </Box>
            <ListItemText
              m={1}
              sx={{
                opacity: open ? 1 : 0,
                textTransform: "none",
                display: open ? "auto" : "none",
              }}
            />
          </NavButtonStyled>
        </ThemeProvider>
      ) : (
        <ThemeProvider
          theme={createTheme({
            palette: { primary: { main: "#d1005e" } },
            components: {
              MuiListItemButton: {
                styleOverrides: {
                  root: {
                    "&.Mui-selected": {
                      backgroundColor: primary["400"],
                      color: lightGrey["50"],
                      borderStyle: "none",
                      borderRadius: "4px",
                      "&.Mui-focusVisible": {
                        backgroundColor: primary["400"],
                        color: lightGrey["50"],
                        borderStyle: "none",
                        borderRadius: "4px",
                      },
                      "&:hover": {
                        backgroundColor: primary["600"],
                        color: lightGrey["50"],
                        borderStyle: "none",
                        borderRadius: "4px",
                      },
                    },
                  },
                },
              },
            },
          })}
        >
          <NavButtonStyled
            to={route.path}
            component={Link}
            disableTouchRipple={true}
            focusRipple={true}
            disableRipple
            selected={activeRoute}
          >
            <Box
              sx={{
                minWidth: 0,
                mr: BoxMargin,
                justifyContent: "center",
                display: "flex",
                width: "1em",
              }}
            >
              <i className={route.icon}></i>
            </Box>
            <ListItemText
              m={1}
              primary={route.sidebarName}
              sx={{
                color: primary["100"],
                opacity: opacity,
                textTransform: "none",
                display: display,
                "&.active": {
                  color: green,
                  fontWeight: 600,
                },
                "&:focus": {
                  color: green,
                  fontWeight: 600,
                },
              }}
            />
          </NavButtonStyled>
        </ThemeProvider>
      )}
    </Fragment>
  );
};

export default NavButton;
