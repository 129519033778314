import { apiSlice } from "../API";

export const userSensorAlertCalendarSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findUserSensorAlertCalendar: builder.query({
      query: () => "/userSensorAlertCalendar",
      providesTags: ["userSensorAlertCalendar"],
    }),
    getUserSensorAlertCalendar: builder.query({
      query: (id) => `/userSensorAlertCalendar/${id}`,
    }),
    addUserSensorAlertCalendar: builder.mutation({
      query: (userSensorAlertCalendar) => ({
        url: "/userSensorAlertCalendar",
        method: "POST",
        body: userSensorAlertCalendar,
      }),
      invalidatesTags: ["userSensorAlertCalendar"],
    }),
    editUserSensorAlertCalendar: builder.mutation({
      query: ({ id, userSensorAlertCalendar }) => ({
        url: "/userSensorAlertCalendar",
        method: "PUT",
        body: userSensorAlertCalendar,
      }),
      invalidatesTags: ["userSensorAlertCalendar"],
    }),
    deleteUserSensorAlertCalendar: builder.mutation({
      query: (id) => ({
        url: `/userSensorAlertCalendar/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["userSensorAlertCalendar"],
    }),
  }),
});

export const {
  useFindUserSensorAlertCalendarQuery,
  useGetUserSensorAlertCalendarQuery,
  useAddUserSensorAlertCalendarMutation,
  useEditUserSensorAlertCalendarMutation,
  useDeleteUserSensorAlertCalendarMutation,
} = userSensorAlertCalendarSlice;
