import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

const ThemeButton = (props) => {
  const theme = useTheme();

  return (
    <Fragment>
      {props.variant === "outlined" ? (
        <Button
          variant={props.variant}
          component={props.component}
          disabled={props.disabled}
          onClick={props.onClick ? (e) => props.onClick(e) : null}
          href={props.href && props.href}
          sx={{
            height: props.height ? `${props.height}` : "40px",
            width: `${props.width}`,
            fontSize: "16px",
            fontWeight: "medium",
            borderColor: `${props.color}.500`,
            borderStyle: "solid",
            borderWidth: "1px",
            px: 1,
            py: 0.3,
            color: `${props.color}.500`,
            "&:hover": {
              backgroundColor: `${props.color}.700`,
              color: theme.palette.lightGrey["50"],
            },
            "&:active": {
              color: theme.palette.lightGrey["50"],
              backgroundColor: `${props.color}.700`,
              borderColor: theme.palette.warning["500"],
              borderStyle: "solid",
              borderWidth: 3,
            },
            "&:focus": {
              color: theme.palette.lightGrey["300"],
              backgroundColor: `${props.color}.700`,
              borderColor: theme.palette.blueAction["500"],
              borderStyle: "solid",
              borderWidth: "3px",
            },
            "&:disabled": {
              color: `${props.color}.500`,
              borderColor: `${props.color}.500`,
              borderStyle: "solid",
              borderWidth: "1px",
              opacity: 0.65,
            },
          }}
        >
          {props.icon && (
            <Box
              mt={0.5}
              ml={0.6}
              mr={0.8}
            >
              <i className={props.icon} />
            </Box>
          )}
          {props.children}
        </Button>
      ) : (
        <Button
          variant={props.variant}
          disabled={props.disabled}
          onClick={
            props.onClick
              ? (e) => {
                  return props.onClick(e);
                }
              : null
          }
          fullWidth={props.fullWidth}
          component={props.component}
          sx={{
            height: `${props.height}`,
            width: `${props.width}`,
            fontSize: "16px",
            fontWeight: "medium",
            px: 1,
            py: 0.3,
            backgroundColor: `${props.color}.500`,
            color: theme.palette.lightGrey["50"],
            boxShadow: "none",
            borderColor: `${props.color}.500`,
            borderStyle: "solid",
            borderWidth: props.borderWidth ? props.borderWidth : "3px",
            padding: props.padding ? props.padding : "3px",
            minWidth: props.minWidth ? props.minWidth : "64px",
            lineHeight: props.lineHeight ? props.lineHeight : "1.75",
            "&:hover": {
              backgroundColor: `${props.color}.700`,
              borderColor: `${props.color}.700`,
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: `${props.color}.700`,
            },
            "&:focus": {
              backgroundColor: `${props.color}.700`,
              borderColor: theme.palette.blueAction["500"],
              borderStyle: "solid",
              borderWidth: "3px",
            },
            "&:disabled": {
              backgroundColor: `${props.color}.500`,
              opacity: 0.65,
              color: theme.palette.lightGrey["50"],
            },
          }}
        >
          {props.icon && (
            <Box
              mt={0.5}
              ml={0.6}
              mr={0.8}
            >
              <i className={props.icon} />
            </Box>
          )}
          {props.children}
        </Button>
      )}
    </Fragment>
  );
};

export default ThemeButton;
