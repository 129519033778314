import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Image, Group } from "react-konva";
import { filterObjectByValue, getColorCodingStatus, MapDragBoundFunc } from "../../Utils/Tools";
import useImage from "use-image";
import DropTarget from "../Drag/DropTarget";
import icon from "../../Assets/svg/icons.svg";
import equipmentSuccess from "../../Assets/svg/xil-icon-map_marker-equipment-success.png";
import equipmentSuccessFocused from "../../Assets/svg/xil-icon-map_marker-equipment-success-focused.png";
import equipmentInactive from "../../Assets/svg/xil-map_marker-equipment-inactive.png";
import equipmentNoSensors from "../../Assets/svg/xil-icon-map_marker-equipment-no-sensors.png";
import equipmentNoSensorsFocused from "../../Assets/svg/xil-icon-map_marker-equipment-no-sensors-focus.png";
import equipmentInactiveFocused from "../../Assets/svg/xil-map_marker-equipment-inactive-focus.png";
import equipmentFaulted from "../../Assets/svg/xil-icon-map_marker-equipment-danger.png";
import equipmentFaultedFocused from "../../Assets/svg/xil-map_marker-equipment-danger-focus.png";
import equipmentSuppressed from "../../Assets/svg/xil-map_marker-equipment-suppressed.png";
import equipmentSuppressedFocused from "../../Assets/svg/xil-map_marker-equipment-suppressed-focus.png";
import equipmentPrior from "../../Assets/svg/xil-map_marker-equipment-prior.png";
import equipmentPriorFocused from "../../Assets/svg/xil-map_marker-equipment-prior-focus.png";
import equipmentWarning from "../../Assets/svg/xil-map_marker-equipment-warning-focus.png";
import equipmentWarningFocused from "../../Assets/svg/xil-map_marker-equipment-warning-focus.png";
import substationSuccess from "../../Assets/svg/xil-map_marker-substation-success.png";
import substationSuccessFocus from "../../Assets/svg/xil-map_marker-substation-success-focus.png";
import substationFaulted from "../../Assets/svg/xil-map_marker-substation-danger.png";
import substationFaultedFocused from "../../Assets/svg/xil-map_marker-substation-danger-focus.png";
import substationInactive from "../../Assets/svg/xil-map_marker-substation-inactive.png";
import substationNoSensors from "../../Assets/svg/xil-map_marker-substation-no-sensors.png";
import substationNoSensorsFocused from "../../Assets/svg/xil-map_marker-substation-no-sensors-focus.png";
import substationInactiveFocused from "../../Assets/svg/xil-map_marker-substation-inactive-focus.png";
import substationPrior from "../../Assets/svg/xil-icon-map_marker-substation-prior.png";
import substationPriorFocused from "../../Assets/svg/xil-map_marker-substation-prior-focus.png";
import substationSupressed from "../../Assets/svg/xil-map_marker-substation-suppressed.png";
import substationSupressedFocused from "../../Assets/svg/xil-map_marker-substation-suppressed-focus.png";
import substationWarning from "../../Assets/svg/xil-map_marker-substation-warning.png";
import substationWarningFocused from "../../Assets/svg/xil-map_marker-substation-warning-focus.png";
import { default as SpinnerContainer } from "../../Containers/SetupSpinnerContainer";
import BackgroundImage from "../BaseComponents/BackgroundImage/BackgroundImage";
import { useFindSensorsQuery } from "../../Store/Slices/sensorSlice";
import { useFindSubstationsQuery } from "../../Store/Slices/substationSlice";
import Loader from "@Components/LoadingSpinner";
import MapToolTipComponent from "../BaseComponents/mapToolTip";
import "./style.css";

const MapContainer = ({
  getAllequipments,
  setNewEquipment,
  items,
  setItems,
  editEquipment,
  locationMap,
  equipmentLive,
  renderMap,
  SelectedEquipment,
  setSelectedEquipment,
  setUpdatedMappedEquipments,
  selectedLocationId,
  setEditedMappedEquipments,
  setEdit,
  editedMappedEquipments,
  editedMappedSubstations,
  setUpdatedMappedSubstations,
  setEditedMappedSubstations,
}) => {
  const [loading, setLoading] = useState(false);
  const [mapedItems, setMapedItems] = useState([]);
  const [itemsTemp, setItemsTemp] = React.useState([]);
  const [sizes, setSizes] = useState({
    height: null,
    width: null,
  });
  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const equipmentRef = useRef();
  const stageRef = useRef();
  const { data: sensorsData, isSuccess: sensorsSuccess } = useFindSensorsQuery();
  const { data: substationsData, isSuccess: substationSuccessQuery } = useFindSubstationsQuery();

  const [equipmentFaultedMarker] = useImage(equipmentFaulted);
  const [equipmentSuccessMarker] = useImage(equipmentSuccess);
  const [equipmentInactiveMarker] = useImage(equipmentInactive);
  const [equipmentNoSensorMarker] = useImage(equipmentNoSensors);
  const [equipmentNoSensorFocusedMarker] = useImage(equipmentNoSensorsFocused);
  const [equipmentSuccessFocusedMarker] = useImage(equipmentSuccessFocused);
  const [equipmentFaultedFocusedMarker] = useImage(equipmentFaultedFocused);
  const [equipmentInactiveFocusedMarker] = useImage(equipmentInactiveFocused);
  const [substationSuccessMarker] = useImage(substationSuccess);
  const [substationInactiveMarker] = useImage(substationInactive);
  const [substationNoSensorMarker] = useImage(substationNoSensors);
  const [substationNoSensorFocusedMarker] = useImage(substationNoSensorsFocused);
  const [substationFaultedMarker] = useImage(substationFaulted);
  const [substationInactiveFocusedMarker] = useImage(substationInactiveFocused);
  const [substationSuccessFocusedMarker] = useImage(substationSuccessFocus);
  const [substationFaultedFocusedMarker] = useImage(substationFaultedFocused);
  const [equipmentWarningMarker] = useImage(equipmentWarning);
const [equipmentPriorMarker] = useImage(equipmentPrior);
const [equipmentSuppressedMarker] = useImage(equipmentSuppressed);
const [equipmentWarningFocusedMarker] = useImage(equipmentWarningFocused);
const [equipmentPriorFocusedMarker] = useImage(equipmentPriorFocused);
const [equipmentSuppressedFocusedMarker] = useImage(equipmentSuppressedFocused);
const [substationWarningMarker] = useImage(substationWarning);
const [substationPriorMarker] = useImage(substationPrior);
const [substationSuppressedMarker] = useImage(substationSupressed);
const [substationWarningFocusedMarker] = useImage(substationWarningFocused);
const [substationPriorFocusedMarker] = useImage(substationPriorFocused);
const [substationSuppressedFocusedMarker] = useImage(substationSupressedFocused);
  const [imageHeight, setImageHeight] = useState("");

  const allSubstationsSensors = substationSuccessQuery
    ? substationsData.reduce((result, substation) => {
        const substationSerialNumber = substation.SerialNumber;
        const filteredSensors = sensorsSuccess
          ? sensorsData?.filter(
              (sensor) => sensor.PortSerial.split("-").slice(1).join("-") === substationSerialNumber
            )
          : [];
        return { ...result, [substation.SubstationID]: filteredSensors };
      }, {})
    : [];

  useEffect(() => {
    if (editedMappedEquipments.length || editedMappedSubstations.length) {
      setUpdatedMappedEquipments(editedMappedEquipments);
      setUpdatedMappedSubstations(editedMappedSubstations);
    }
  }, [editedMappedSubstations, editedMappedSubstations]);

  useEffect(() => {
    setMapedItems(items.filter((equipment) => equipment.LocationID == selectedLocationId));
  }, [items]);

  useEffect(() => {
    setStage({
      scale: 1,
      x: 0,
      y: 0,
    });
  }, [locationMap]);

  useEffect(() => {
    const wrapperDiv = document.getElementById("mapWrapper");
    if (wrapperDiv !== null) {
      const containerWidth = {
        width: wrapperDiv.offsetWidth,
        height: wrapperDiv.offsetHeight - 128,
      };
      setSizes(containerWidth);
    } else {
      setLoading(true);
    }
  }, []);
  const itemDropped = (item) => {
    setItemsTemp([...itemsTemp, item]);
  };

  const handleDragStart = (e) => {
    const id = e.target.id();
    setEdit(true);

    setItems(
      items.map((item) => {
        return {
          ...item,
          isDragging: item.id === id,
        };
      })
    );
  };

  const handleDragEnd = async (e) => {
    setNewEquipment(true);
    let id = e.target.attrs.id;
    let X = Number(e.target.attrs.x);
    let Y = Number(e.target.attrs.y);

    setItems(
      items.map((item) => {
        if (item.EquipmentID == id || item.SubstationID == id) {
          item.MapX = X + 20 / stage.scale;
          item.MapY = Y + 30 / stage.scale;
        }
        return {
          ...item,
          isDragging: false,
        };
      })
    );
    setEditedMappedEquipments(
      items.filter((item) => {
        return (
          item.EquipmentID && {
            ...item,
            isDragging: false,
          }
        );
      })
    );
    setEditedMappedSubstations(
      items.filter((item) => {
        return (
          item.SubstationID && {
            ...item,
            isDragging: false,
          }
        );
      })
    );
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.02;
    const stage = e.target.getStage();
    let oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale > 0.3 && newScale < 2)
      setStage({
        scale: newScale,
        x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
        y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
      });
  };
  const resetZoom = () => {
    setStage({
      scale: 1,
      x: 0,
      y: 0,
    });
  };
  const scaleRelativeToPoint = (point, increaseScale) => {
    const scaleBy = 1.5;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: point.x / oldScale - stage.x() / oldScale,
      y: point.y / oldScale - stage.y() / oldScale,
    };

    let newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy;

    newScale = newScale < 0.3 ? 0.3 : newScale > 2 ? 2 : newScale;
    setStage({
      scale: newScale,
      x: (point.x / newScale - mousePointTo.x) * newScale,
      y: (point.y / newScale - mousePointTo.y) * newScale,
    });
  };
  const setStageByHeight = () => {
    if (imageHeight > sizes.height) {
      const newScale = sizes.height / imageHeight;
      setStage({
        scale: newScale,
        x: sizes.width * 0.2,
        y: 0,
      });
    } else if (imageHeight < sizes.height) {
      const newScale = imageHeight / sizes.height;
      setStage({
        scale: newScale,
        x: sizes.width * 0.2,
        y: sizes.height * 0.2,
      });
    }
  };
  return (
    <>
      {loading == "loading" && (
        <SpinnerContainer>
          <Loader loading={loading} />
        </SpinnerContainer>
      )}
      <div>
        {renderMap && (
          <DropTarget
            editEquipment={editEquipment}
            onItemDropped={itemDropped}
            getAllequipments={getAllequipments}
            dropEffect="link"
            setNewEquipment={setNewEquipment}
            items={items}
          >
            {
              <div style={{ display: "flex", position: "relative", justifyContent: "center" }}>
                <MapToolTipComponent
                  scaleRelativeToPoint={scaleRelativeToPoint}
                  sizes={sizes}
                  setStageByHeight={setStageByHeight}
                  resetZoom={resetZoom}
                />
                <Stage
                  draggable
                  ref={stageRef}
                  style={{
                    cursor: "grab",
                  }}
                  width={sizes.width}
                  height={sizes.height}
                  scaleX={stage.scale}
                  scaleY={stage.scale}
                  onWheel={handleWheel}
                  x={stage.x}
                  y={stage.y}
                  dragBoundFunc={(pos) => MapDragBoundFunc(pos)}
                >
                  <Layer>
                    <BackgroundImage
                      src={`${process.env.REACT_APP_URL}/asset/download/${locationMap}`}
                      width={sizes.width}
                      height={sizes.height}
                      setLoading={setLoading}
                    />

                    {mapedItems.map((item) => {
                      let x = Number(item?.MapX);
                      let y = Number(item?.MapY);
                      if (item.EquipmentID) {
                        const equipmentSensors = filterObjectByValue(
                          equipmentLive,
                          "EquipmentID",
                          item.EquipmentID
                        );

                        const status = getColorCodingStatus(equipmentSensors[0]?.Sensors);
                        let image;
                        switch (status) {
                          case 'error':
                            image = SelectedEquipment.EquipmentID === item?.id
                              ? equipmentFaultedFocusedMarker
                              : equipmentFaultedMarker;
                            break;
                          case 'success':
                            image = SelectedEquipment.EquipmentID === item?.id
                              ? equipmentSuccessFocusedMarker
                              : equipmentSuccessMarker;
                            break;
                          case 'warning':
                            image = SelectedEquipment.EquipmentID === item?.id
                              ? equipmentWarningFocusedMarker
                              : equipmentWarningMarker;
                            break;
                          case 'prior':
                            image = SelectedEquipment.EquipmentID === item?.id
                              ? equipmentPriorFocusedMarker
                              : equipmentPriorMarker;
                            break;
                          case 'suppressed':
                            image = SelectedEquipment.EquipmentID === item?.id
                              ? equipmentSuppressedFocusedMarker
                              : equipmentSuppressedMarker;
                            break;
                            case 'inactive':
                              image = SelectedEquipment.EquipmentID === item?.id
                                ? equipmentInactiveFocusedMarker
                                : equipmentInactiveMarker;
                              break;
                              case 'none':
                                image = SelectedEquipment.EquipmentID === item?.id
                                  ? equipmentNoSensorFocusedMarker
                                  : equipmentNoSensorMarker;
                                break;
                          default:
                            image = null;
                        }
                        
                        return (
                          <Group
                            scaleX={1 / stage.scale}
                            scaleY={1 / stage.scale}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            x={x - 20 / stage.scale}
                            y={y - 30 / stage.scale}
                            key={item?.id}
                            id={item?.id}
                            equipmentRef={equipmentRef}
                            draggable
                            onClick={() => setSelectedEquipment(item)}
                          >
                            <Image
                              image={image}
                              src={icon}
                              key={item.EquipmentID}
                              width={50}
                              height={50}
                            />
                          </Group>
                        );
                      } else {
                        const substationSensors = allSubstationsSensors[item.SubstationID];

                        const status = getColorCodingStatus(substationSensors);

                        let image;
                        switch (status) {
                          case 'error':
                            image = SelectedEquipment.SubstationID === item?.id
                              ? substationFaultedFocusedMarker
                              : substationFaultedMarker;
                            break;
                          case 'success':
                            image = SelectedEquipment.SubstationID === item?.id
                              ? substationSuccessFocusedMarker
                              : substationSuccessMarker;
                            break;
                          case 'warning':
                            image = SelectedEquipment.SubstationID === item?.id
                              ? substationWarningFocusedMarker
                              : substationWarningMarker;
                            break;
                          case 'prior':
                            image = SelectedEquipment.SubstationID === item?.id
                              ? substationPriorFocusedMarker
                              : substationPriorMarker;
                            break;
                          case 'suppressed':
                            image = SelectedEquipment.SubstationID === item?.id
                              ? substationSuppressedFocusedMarker
                              : substationSuppressedMarker;
                            break;
                            case 'inactive':
                              image = SelectedEquipment.EquipmentID === item?.id
                                ? substationInactiveFocusedMarker
                                : substationInactiveMarker;
                              break;
                              case 'none':
                                image = SelectedEquipment.EquipmentID === item?.id
                                  ? substationNoSensorFocusedMarker
                                  : substationNoSensorMarker;
                                break;
                          default:
                            image = null;
                        }

                        return (
                          <Group
                            scaleX={1 / stage.scale}
                            scaleY={1 / stage.scale}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            x={x - 20 / stage.scale}
                            y={y - 30 / stage.scale}
                            key={item.SubstationID}
                            id={item.SubstationID}
                            equipmentRef={equipmentRef}
                            onClick={() => setSelectedEquipment(item)}
                            draggable
                          >
                            <Image
                              image={image}
                              src={icon}
                              key={item.SubstationID}
                              width={50}
                              height={50}
                            />
                          </Group>
                        );
                      }
                    })}
                  </Layer>
                </Stage>
              </div>
            }
          </DropTarget>
        )}
      </div>
    </>
  );
};

export default MapContainer;
