import React from "react";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";

const ExpandButton = ({
  areAllRowsExpanded,
  buttonClass,
  expandableRowsHeader,
  expandedRows,
  iconClass,
  iconIndeterminateClass,
  isHeaderCell,
  onExpand,
}) => {
  return (
    <Box
      sx={{
        ml: 1,
      }}
    >
      {isHeaderCell &&
      !areAllRowsExpanded() &&
      areAllRowsExpanded &&
      expandedRows.data.length > 0 ? (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}
        >
          <RemoveIcon
            id="expandable-button"
            className={iconIndeterminateClass}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={onExpand}
          style={{ padding: 0 }}
          disabled={expandableRowsHeader === false}
          className={buttonClass}
        >
          <Box
            width={32}
            height={32}
            marginRight={"20px"}
          >
            <i
              id="expandable-button"
              class={`fa-light fa-chevron-down ${iconClass}`}
              style={{
                color: "#9CA1A6",
                lineHeight: "25px",
                fontSize: "20px",
              }}
            ></i>
          </Box>
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandButton;
