import React from "react";
import Checkbox from "@mui/material/Checkbox";
import "./style.css";

function CheckBoxComponent(props) {
  const { onChange, checked, indeterminate, inputProps, disabled, name, value, Label, styles } =
    props;
  return (
    <>
      <Checkbox
        name={name}
        value={value}
        size={"small"}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        indeterminate={indeterminate}
        inputProps={inputProps}
        sx={{
          ...styles,
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
            borderRadius: "4px",
            outline: "none",
            "&:focus": {
              boxShadow: "#00AEEF 0px 0px 0px 3px",
            },
          },
          color: "#9CA1A6",
          width: "24px",
          height: "24px",
          outline: "none",
          borderRadius: "4px",
          "&:hover": {
            color: "#17455E !important",
          },
          "&:focus-visible": {
            boxShadow: "#00AEEF 0px 0px 0px 3px",
          },
          "&.Mui-checked": {
            color: "#0D2E42",
          },
          "&.Mui-disabled": {
            "&.Mui-checked": {
              color: "#C0C3C7",
            },
          },
        }}
      />{" "}
      <p
        style={{
          color: disabled == true ? "#C0C3C7" : "#222934",
          fontSize: "16px",
          lineHeight: "23px",
          paddingLeft: "5px",
        }}
      >
        {Label}
      </p>
    </>
  );
}

export default CheckBoxComponent;
