import React, { Fragment } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

const OutlinedButton = (props) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Button
        variant={props.variant}
        disabled={props.disabled}
        onClick={props.onClick}
        sx={{
          height: props.height ? `${props.height}` : "40px",
          width: `${props.width}`,
          display: `${props.display}`,
          justifyContent: `${props.justifyContent}`,
          fontSize: "16px",
          fontWeight: "medium",
          borderColor: `${props.color}.500`,
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "0px 8px",
          marginRight: props.marginRight ? props.marginRight : "0px",
          color: `${props.color}.500`,
          "&:hover": {
            backgroundColor: `${props.color}.700`,
            color: theme.palette.lightGrey["50"],
            borderColor: `${props.color}.500`,
            borderStyle: "solid",
            borderWidth: 1,
          },
          "&:active": {
            color: theme.palette.lightGrey["50"],
            backgroundColor: `${props.color}.700`,
            borderStyle: "solid",
            borderWidth: 1,
          },
          "&:focus": {
            color: theme.palette.lightGrey["50"],
            backgroundColor: `${props.color}.700`,
            borderColor: theme.palette.blueAction["500"],
            borderStyle: "solid",
            borderWidth: "3px",
            width: `${props.width + 20}`,
          },
          "&:disabled": {
            color: `${props.color}.500`,
            borderColor: `${props.color}.500`,
            borderStyle: "solid",
            borderWidth: "1px",
            opacity: 0.65,
          },
        }}
      >
        {props.icon && (
          <Box
            mt={0.5}
            ml={0.6}
            mr={0.8}
          >
            <i className={props.icon} />
          </Box>
        )}
        {props.children}
      </Button>
    </Fragment>
  );
};

export default OutlinedButton;
