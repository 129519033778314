import { Grid, FormControl } from "@mui/material";
import TextFieldComponent from "../../BaseComponents/TextField";
import DropDownComponent from "../../BaseComponents/DropDown";

function order(array) {
  if (Array.isArray(array)) {
    return array.sort((a, b) => a.label.localeCompare(b.label));
  } else {
    console.log(array);
  }
}

const Detail = (props) => {
  const {
    locationCombinations,
    handleChange,
    location,
    edit,
    handleChangeSelect,
    handleParentLocation,
    validationErrorMessage,
  } = props;

  const emptyValue = [
    {
      value: null,
      label: "No parent location",
    },
  ];

  const parentLocationValue =
    locationCombinations.find((loc) => {
      return loc.value === location.ParentID && loc;
    }) || "";

  return (
    <FormControl
      margin="none"
      fullWidth
    >
      <Grid
        container
        spacing={1}
      >
        <TextFieldComponent
          fullWidth={true}
          Label="Location name"
          placeholder="Enter location name"
          onChange={handleChange}
          value={location.LocationName || ""}
          name="LocationName"
          customError={validationErrorMessage}
        />
        <DropDownComponent
          padding={true}
          label="Parent location"
          placeholder="Select location"
          isDisabled={edit}
          options={order(
            locationCombinations
              .filter((loc) => {
                if (edit)
                  return loc.value !== location.LocationID && loc.ParentID === location.ParentID;
                else return loc.value !== location.LocationID;
              })
              .concat(emptyValue)
          )}
          handleChange={(e) => [handleChangeSelect("ParentID", e), handleParentLocation(e.value)]}
          value={parentLocationValue}
        />
      </Grid>
    </FormControl>
  );
};

export default Detail;
