import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

const FormControlLabelComponent = (props) => {
  const { width, items, control, label } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: width,
      }}
    >
      {Array.isArray(items) &&
        items.map((item) => {
          return (
            <FormControlLabel
              key={item}
              value={item}
              control={control(item)}
              label={label(item)}
            />
          );
        })}
    </Box>
  );
};
export default FormControlLabelComponent;
