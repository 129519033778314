import React, { Fragment } from "react";
import Panel from "@Components/SplitPane";

const Logs = () => {
  return (
    <Fragment>
      <Panel />
    </Fragment>
  );
};

export default Logs;
