import React from "react";
import MultipleSelectCheckmarks from "../MultiSelect";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const StatusViewMultiSelectBox = (props) => {
  const { data, selectedItems, title, placeholder, onChangeHandler } = props;
  return (
    <div style={{ marginTop: "24px" }}>
      <p
        style={{
          textAlign: "left",
          font: "normal normal normal 12px/16px Roboto",
          color: "#9CA1A6",
          marginBottom: "4px",
        }}
      >
        {title}
      </p>

      <MultipleSelectCheckmarks
        onChange={onChangeHandler}
        placeholder={placeholder}
        personName={selectedItems}
        data={data}
      >
        {data.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              font: "normal normal medium 14px/20px Roboto",
              color: "#222934",
              "&:hover": {
                background: "#E3E9EC 0% 0% no-repeat padding-box",
                color: "#17455E",
              },
              "&$selected": {
                backgroundColor: "transparent",
              },
            }}
            className="menuItem"
          >
            <Checkbox
              className="checkBox"
              size="small"
              sx={{
                color: "#9CA1A6",
                width: "24px",
                height: "24px",
                "&:hover": {
                  color: "#17455E",
                },
                "&:focus-visible": {
                  outline: "2px auto #7fd3f2",
                },
                "&.Mui-checked": {
                  color: "#0D2E42",
                },
              }}
              checked={selectedItems.indexOf(item.value) > -1}
            />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </MultipleSelectCheckmarks>
    </div>
  );
};

export default StatusViewMultiSelectBox;
